

<div class="card">
  <div class="">

    <div class="row">

      <div class="col-12 text-primary   ">

        <div class=" inline-flex-left ">
               <div *ngIf="hasRoles([1,2,3,4]) == true" class="maxwh">
                  <mat-form-field class="maxwh" appearance="outline">
                    <button (click)="onIdbDynamicOrdersAction(-1)" matPrefix class="btn mp0 "><span class="material-icons btn-outline-danger md-32" matTooltip="Ordersatz löschen">remove</span></button>
                    <input type="number" matInput placeholder="kundennummer" class="center maxwh" [(ngModel)]="customerNumberInput" onfocus="this.select();">
                    <button (click)="onIdbDynamicOrdersAction(1)" matSuffix class="btn mp0 "><span class="material-icons btn-outline-primary md-32" matTooltip="Ordersatz hinzufügen">add</span></button>
                  </mat-form-field>
               </div>

        </div>

        <div class="btn-r">
          <button (click)="onPrintPdf('print')" class=" btn "><span class="  material-icons btn-outline-primary md-36" matTooltip="Drucken"> print </span></button>
          <button (click)="onPrintPdf('export')" class=" btn "><span class=" material-icons btn-outline-primary md-36" matTooltip="Exportieren"> publish</span> </button>
          <button (click)="setAllDetailExpandState()" class=" btn "><span *ngIf="panelOpenState == true" class=" material-icons btn-outline-primary md-36 " matTooltip="Auf/Zuklappen">unfold_less</span> <span *ngIf="panelOpenState == false" class="material-icons btn-outline-primary md-36 m2" matTooltip="Auf/Zuklappen">unfold_more</span> </button>

        </div>

      </div>
    </div>





  </div>

</div>

<!-- headertable min768 txtCenter -->

<div class="card alaska-table-container  " id="alaska-table-container">
<div class="sticky-top xscroll  ">

  <table class=" table-striped txtCenter mat-elevation-z8 headertable" mat-table [dataSource]=""   >

    <ng-container matColumnDef="Artikel_head">        <th mat-header-cell   *matHeaderCellDef >Artikel</th>  <td mat-cell class=""  *matCellDef="let element"   >     xxx     </td> </ng-container>
    <ng-container matColumnDef="ProcessCode_head">    <th mat-header-cell   *matHeaderCellDef >AC</th> <td mat-cell class=""  *matCellDef="let element"   >           xxx     </td> </ng-container>
    <ng-container matColumnDef="Date_head">           <th mat-header-cell   *matHeaderCellDef >Lieferdatum</th>  <td mat-cell class=""  *matCellDef="let element" >        </td> </ng-container>
    <ng-container matColumnDef="Name_head">           <th mat-header-cell   *matHeaderCellDef >Bezeichnung</th> <td mat-cell class=""  *matCellDef="let element" >         </td>  </ng-container>
    <ng-container matColumnDef="Info_head">           <th mat-header-cell   *matHeaderCellDef >Info.</th>  <td mat-cell class=""  *matCellDef="let element"   >            </td> </ng-container>
    <ng-container matColumnDef="Spec_head">           <th mat-header-cell   *matHeaderCellDef >Spez.</th>  <td mat-cell class=""  *matCellDef="let element"   >            </td> </ng-container>
    <ng-container matColumnDef="Picture_head">        <th mat-header-cell   *matHeaderCellDef >Bild</th>  <td mat-cell class=""  *matCellDef="let element"   >             </td>  </ng-container>
    <ng-container matColumnDef="Price_head">          <th mat-header-cell   *matHeaderCellDef >letzter Preis(€)</th> <td mat-cell class=""  *matCellDef="let element"   >  </td>  </ng-container>
    <ng-container matColumnDef="ListPrice_head">      <th mat-header-cell   *matHeaderCellDef >aktueller Preise(€)</th> <td mat-cell class=""  *matCellDef="let element" > </td> </ng-container>

    <ng-container matColumnDef="StockQuantity_head">  <th mat-header-cell   *matHeaderCellDef >Verfügbar</th> <td mat-cell class=""  *matCellDef="let element" >      <table class="stockTable min50"> <tr> <td></td></tr></table>     </td>  </ng-container>
    <ng-container matColumnDef="Quantity_head">       <th mat-header-cell   *matHeaderCellDef >letzte Menge</th>  <td mat-cell class=""  *matCellDef="let element" >              </td>   </ng-container>
    <ng-container matColumnDef="Unit_head">           <th mat-header-cell   *matHeaderCellDef >Einheit</th>  <td mat-cell class=""  *matCellDef="let element" >            </td> </ng-container>
    <ng-container matColumnDef="NextQuantity_head">   <th mat-header-cell   *matHeaderCellDef >Wunschmenge</th> <td mat-cell class=""  *matCellDef="let element"   >       </td>  </ng-container>
    <ng-container matColumnDef="Action_head">         <th mat-header-cell   *matHeaderCellDef >Aktion</th> <td mat-cell class=""  *matCellDef="let element"   >            </td> </ng-container>

    <tr class="" mat-header-row *matHeaderRowDef="displayedColumns_head" ></tr>
    <tr class="" mat-row *matRowDef="let row; let i = index; columns: displayedColumns_head;">  </tr>

  </table>

</div>

<!-- <div class="sticky-top">
  <table class=" headertable"    >

    <th     >Artikel</th>
     <th    >AC</th>
    <th    >Lieferdatum</th>
    <th    >Bezeichnung</th>
    <th    >Spez.</th>
    <th    >Bild</th>
     <th     >letzter Preis(€)</th>
    <th     >aktueller Preise(€)</th>

    <th    >Verfügbar</th>
     <th   >Menge</th>
     <th    >Einheit</th>
     <th   >Wunschmenge</th>
     <th     >Aktion</th>

  </table>
</div> -->

<table mat-table [dataSource]="getDatasourceHead(datasource.filteredData)" multiTemplateDataRows class=" " id="dorHeadTable">
  <!-- <ng-container><tr><th>new herad</th></tr> </ng-container> -->


<ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; ">

  <!-- id="OrderableRowInput-{{ getIndexByMaterialGroupsId( dorHeadElement.MaterialGroupId)}}" -->
  <th mat-header-cell *matHeaderCellDef> {{column}} </th>
<td  mat-cell *matCellDef="let dorHeadElement; let i=index;"  > <span  > {{ dorHeadElement.MaterialGroupId}} - {{ dorHeadElement.MaterialGroupName}} </span>

  <input onfocus="this.select();"
  id="OrderableRowInput-{{dorHeadElement.MaterialGroupId}}"
  name="OrderableRow"
  type="text"
  class="input-group-text hideInput"
  aria-label="Small"
  aria-describedby="inputGroup-sizing-sm"


  (keyup)="onOrderableKeyUpEvent(dorHeadElement,'OrderableRowInput', i , $event, getIndexByMaterialGroupsId( dorHeadElement.MaterialGroupId) )"
  />

</td>

(click)="onFocusClickedInput(dorHeadElement,'OrderableRowInput', i)"

</ng-container>


<ng-container matColumnDef="expandedDetail">
<td mat-cell *matCellDef="let dorHeadElement;"  [attr.colspan]="columnsToDisplay.length">

<div class="example-element-detail"    [@detailExpand]= "dorHeadElement.detailExpandState">

 <table class="table-striped txtCenter mat-elevation-z8" mat-table [dataSource]="getDynRecordsWithFirstIndex(dorHeadElement)"     >

          <ng-container matColumnDef="Artikel" >
            <th mat-header-cell   *matHeaderCellDef >A-Nr</th>

            <td mat-cell class=""  *matCellDef="let element"  >


              <span *ngIf=" getDORCount(element) > 0  "> <button (click)="onOpenDORDialog( element)" class="btn btn-sm btn-outline-primary" mat-button [matBadge]="getDORCount(element)" matBadgePosition="before" matBadgeColor="accent"  matBadgeSize="small" matBadgeOverlap="false">{{ element.Product.ProductNumber }}</button></span>
              <span *ngIf=" getDORCount(element) <= 0 "> {{ element.Product.ProductNumber }}</span>
              </td>
            </ng-container>

          <ng-container matColumnDef="ProcessCode">
            <th mat-header-cell   *matHeaderCellDef >AC</th>
            <td mat-cell class=""  *matCellDef="let element"   >{{ element.Product.ProcessCode }}</td>
          </ng-container>

          <ng-container matColumnDef="Date">
          <th mat-header-cell   *matHeaderCellDef >Lieferdatum</th>
          <td mat-cell class=""  *matCellDef="let element"   >{{ element.DeliveryDate  | date: 'dd.MM.yyyy' }}</td>
          </ng-container>
          <ng-container matColumnDef="Name" >
            <th mat-header-cell  *matHeaderCellDef >Bezeichnung</th>
          <td mat-cell class=""  *matCellDef="let element"   >{{element.Product.DescRow1}}  <br /> {{element.Product.DescRow2}}</td>
          </ng-container>

          <ng-container matColumnDef="Info" >
            <th mat-header-cell *matHeaderCellDef  mat-sort-header > Info </th>
            <td mat-cell *matCellDef="let element">  <span *ngIf="element.Product.ProductInfo != undefined && element.Product.ProductInfo  != ''" class="material-icons text-primary ptr md-36" (click)="onProductInfo(element.Product)" matTooltip="Zusatzinformationen">  info  </span></td>
          </ng-container>

          <ng-container matColumnDef="Spec" >
            <th mat-header-cell *matHeaderCellDef  > Spez. </th>
            <td mat-cell class="" *matCellDef="let element">  <span *ngIf="element.Product.SpecificationLink != undefined && element.Product.SpecificationLink != ''" class="material-icons text-primary ptr md-36" (click)="onSpecTab(element.Product.SpecificationLink)" matTooltip="Produktspezifikationen">  assignment  </span></td>
          </ng-container>

          <ng-container matColumnDef="Picture" class="mar-r">
            <th  mat-header-cell *matHeaderCellDef> Bild </th>
            <td mat-cell class="" *matCellDef="let element"> <a class="btn" (click)="onOpenImgDialogMessage(element)" > <i class="material-icons btn-outline-primary" matTooltip="Bild anzeigen">photo_camera</i> </a> </td>
          </ng-container>

          <ng-container matColumnDef="StockQuantity">

            <th  mat-header-cell *matHeaderCellDef > Verfügbarkeit </th>
            <td mat-cell class="" *matCellDef="let element">

              <table class="stockTable">
                <tr *ngIf="hasRoles([1,2,3,4]) == true" (click)="onOdataStockUpdate(element.Product.ProductNumber)"  >

                <td [ngClass]="getBg(element.Product.StockValue,'StockValue')" class=" qtyspan alignRight" > Ist:</td><td [ngClass]="getBg(element.Product.StockValue,'StockValue')" class=" qtyspan alignRight"> {{ element.Product.StockValue | number:'1.2-2':'de' }} </td>  <td  (click)="onOdataStockUpdate(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> Dispo: </td><td [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> {{ element.Product.DispositionStockValue | number:'1.2-2':'de' }} </td> </tr>

                <tr *ngIf="hasRoles([1,2,3,4]) == true"><td  (click)="onOdataCustomerOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight">K-Bestlg.: </td> <td (click)="onOdataCustomerOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight">{{ element.Product.OpenOrdersStockValue | number:'1.2-2':'de' }} </td> <td  (click)="onOdataSuplierOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')" class=" qtyspan alignRight"> L-Bestlg.: </td> <td (click)="onOdataSuplierOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')" class=" qtyspan alignRight">{{ element.Product.SupplierDeliveryStockValue | number:'1.2-2':'de' }} </td> </tr>

                <tr *ngIf="hasRoles([1,2,3,4]) == false"><td > <span [ngClass]="getIcon(element.Product.DispositionStockValue,'stockIconStyle')"  matTooltip="{{bigOrSmall(element.Product.DispositionStockValue)}}"  >  {{getIcon(element.Product.DispositionStockValue, 'stockAvailabilityIcon') }}</span>  </td> <td></td> </tr>
              </table>

            </td>

          </ng-container>

          <ng-container matColumnDef="Price">
            <th mat-header-cell  *matHeaderCellDef >letzter Preis(€)</th>
          <td mat-cell class=""  *matCellDef="let element" class="alignCenter"> {{element.Price | number:'1.2-2':'de' }}</td>
          </ng-container>

          <ng-container matColumnDef="ListPrice">
            <th mat-header-cell  *matHeaderCellDef >aktueller Preise(€)</th>
          <td mat-cell class=""  *matCellDef="let element">

            <p *ngIf="element.Product.ProcessCode == 98"> {{ 0.00 | number:'1.2-2':'de'}} </p>

            <p *ngIf=" element.Product.ProcessCode != 98 && element.Product != null &&  element.Product.PriceType == 'I' || element.Product.PriceType == 'S'" class="m-0 p-0 alignCenter" ><del>{{ element.Product.ListPrice  | number:'1.2-2':'de'}} </del></p>
            <p *ngIf=" element.Product.ProcessCode != 98 && element.Product != null &&  element.Product.PriceType == 'L' || element.Product.PriceType == 'B' " class="m-0 p-0 alignCenter">{{ element.Product.Price  | number:'1.2-2':'de'}} </p>
            <p *ngIf=" element.Product.ProcessCode != 98 && element.Product != null &&  element.Product.PriceType == 'S' " class="m-0 p-0 text-danger bold alignCenter"  matTooltip="Sonderaktion" >{{ element.Product.Price  | number:'1.2-2':'de'}} </p>
            <p *ngIf=" element.Product.ProcessCode != 98 && element.Product != null &&  element.Product.PriceType == 'I' " class="m-0 p-0 txt-pink alignCenter"  matTooltip="indiv. Preis">{{ element.Product.Price  | number:'1.2-2':'de'}} </p>

            <p *ngIf=" element.Product.ProcessCode != 98 && element.Product != null &&  element.Product.PriceType == 'I' " class="m-0 p-0 txt-pink txt-xsm">bis:{{ element.Product.CustomerproductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>
            <p *ngIf=" element.Product.ProcessCode != 98 && element.Product != null &&  element.Product.PriceType == 'S' " class="m-0 p-0 text-danger txt-xsm"> bis:{{ element.Product.ProductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>

          </td>
          </ng-container>

          <ng-container matColumnDef="Quantity">
            <th mat-header-cell   *matHeaderCellDef >letzte Menge</th>
          <td mat-cell class=""  *matCellDef="let element" class="alignCenter">{{ element.Quantity | number:'1.2-2':'de'}}</td>
          </ng-container>

          <ng-container matColumnDef="Unit">
            <th mat-header-cell   *matHeaderCellDef >Einheit</th>
          <td mat-cell class=""  *matCellDef="let element">{{ element.Product.QuantityUnitsShortDesc }}</td>
          </ng-container>

          <ng-container matColumnDef="NextQuantity">
            <th mat-header-cell  *matHeaderCellDef >Wunschmenge</th>
          <td mat-cell class="" *matCellDef="let element; let i=index;">
            <input *ngIf="element.Product.ProcessCode != 98" onfocus="this.select();"
            id="OrderableDetailQuantityInput-{{dorHeadElement.MaterialGroupId}}-{{i}}"
              type="number"
              min="-1000"
              lang="de"
              [ngClass]="getBg(element.Product.QuantityInput,'QuantityInput')"

              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              [(ngModel)]="element.Product.QuantityInput"
              class="input-group-text  inputbox "

              (keydown)="onPreventStep(element,$event)"
              (keyup)="onOrderableDetailKeyUp(element,dorHeadElement,'OrderableDetailQuantityInput',accordionId, $event, i)"

            />

            <span *ngIf="element.Product.ProcessCode == 98" class="material-icons btn-outline-warning md-36 " matTooltip="bitte wählen Sie einen Alternativprodukt">info</span>

          </td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell   *matHeaderCellDef >Aktion</th>
          <td mat-cell class=""  *matCellDef="let element">
            <button *ngIf="element.Product.ProcessCode != 98" (click)="onCopy2ShoppingCart(element)" class="mat-icon-button">
              <span *ngIf="element.Product.QuantityInput == 0" class="material-icons btn-outline-primary" matTooltip="In den aktuellen Warenkorb Übernehmen">shopping_cart</span>
              <span *ngIf="element.Product.QuantityInput != 0" class="material-icons btn-outline-success" matTooltip="In den aktuellen Warenkorb Übernehmen">shopping_cart</span>
            </button>
            <button *ngIf="changeSmallDeviceMode == true" (click)="onRemoveShoppingCartItemClick(element)" class="mat-icon-button ">  <i class="material-icons btn-outline-danger" matTooltip="löschen">remove_shopping_cart</i> </button>
          </td>
          </ng-container>

          <tr class="" mat-row *matRowDef="let row;  columns: displayedColumns;"  id="OrderableDetailRow-{{dorHeadElement.MaterialGroupId}}-{{y}}">  </tr>

        </table>

</div>
</td>
</ng-container>

<!-- <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr> -->

<tr mat-row *matRowDef="let row; columns: columnsToDisplay; let element;let y=index;" class="example-element-row" [class.example-expanded-row]=" element.detailExpandState == 'expanded'"  (click)="onToggleExpandTableRowsClick(element,y)" id="OrderableRow-{{ element.MaterialGroupId }}" [ngClass]="setBG(element.detailExpandState)" >
</tr>

<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


</table>


</div>


import { Component, OnInit, Inject} from '@angular/core';
import {MatDialogModule, MatDialog} from '@angular/material/dialog'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-imgDialog',
  templateUrl: './imgDialog.component.html',
  styleUrls: ['./imgDialog.component.scss']
})
export class ImgDialogComponent implements OnInit {

  datas: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) { }

  ngOnInit() {

this.dialog.getDialogById
  }

}

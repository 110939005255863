
import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit} from '@angular/core';
import {iOrders, iCustomers, iTitles, iGenders, iRegistrationstypes, iPositions, iInputOptions, iUsers, iOrderStates, iRoles, iShoppingcartitems, iShoppingcarts, iUserroles, iProducts, iTooltipDiscountDisplay, iProductstocks, iParameterBag} from '../models/AlaskaModels';
import { InputErrorStateMatcher} from '../models/InputErrorStateMatcher';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../Services/data.service';
import { DialogService } from '../Services/dialog.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ErrorStateMatcher} from '@angular/material/core';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import { IdbService } from '../Services/idb.service';
import { InprogressDialogComponent } from '../dialogs/inprogressDialog/inprogressDialog.component';
registerLocaleData(localeDe, 'de');

const ORDERS_DATA: Array<iOrders> = [];
const SHOPINGCARTITEMS_DATA: Array<iShoppingcartitems> = [];


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {


  loggedInUser: iUsers;
  loggedInRoleIds: number[] = [];
  selectedOrder: iOrders;
  selectedCustomer: iCustomers;
  activeProducts: iProducts[]= [];
  item: string = 'Auftrags-Sammler';
  sortOrder: string ='asc'

  order: iOrders;
  orders: iOrders[] = [];

  datasource = new MatTableDataSource(ORDERS_DATA);
  editDatasource = new MatTableDataSource(SHOPINGCARTITEMS_DATA);

  totalDetailRowsCount:number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  mode: string = 'list';
  formSubmited: boolean = false;
  ordersForm: FormGroup;
  pipe = new DatePipe('de-DE');

  tableColumns: string[] = ['OrdersId', 'Orderdate',  'ShoppingCartsId',  'PreparedBy',  'sOrderedBy',  'sOrderedFor',  'CustomersId',  'CustomerNumber', 'sOrderStatesId', 'OrderInfo', 'Comment', 'Deleted','Surcharge','TotalPrice', 'Selection', 'actions'];
  displayedColumns: string[] = ['OrdersId', 'Orderdate' , 'DeliveryDate', 'CustomerNumber',  'sOrderedBy',  'sOrderedFor', 'sOrderStatesId', 'OrderInfo', 'Comment', 'Surcharge', 'TotalPrice', 'Selection', 'actions', 'hiddenOrderInput'];

  displayedColumnsDetail:string[] = ['OrderDetail_ProductId','OrderDetail_DescRow1','OrderDetail_ProdInfo', 'OrderDetail_Spec','OrderDetail_Unit','OrderDetail_StockValue','OrderDetail_Quantity','OrderDetail_Price','OrderDetail_PosDiscount','OrderDetail_TotalDiscount', 'OrderDetail_Surcharge', 'OrderDetail_TotalPrice','OrderDetail_Info','OrderDetail_Action'];

  // displayedColumnsDetail:string[] = ['OrderDetail_ProductId','OrderDetail_DescRow1','OrderDetail_Unit','OrderDetail_StockValue','OrderDetail_QuantityInput','OrderDetail_Price','OrderDetail_PosDiscount','OrderDetail_TotalDiscount', 'OrderDetail_Surcharge', 'OrderDetail_TotalPrice','OrderDetail_Info','OrderDetail_Action'];

  filterOptions: string[] =  this.hasRoles([1,2,3,4]) == true ? [ 'nicht-freigegeben', 'freigegeben', 'gelöscht', 'Angebote','Vorlagen', 'alle'] : [ 'nicht-freigegeben', 'freigegeben', 'gelöscht','Vorlagen', 'alle'];

  editableCustomerId: number;
  inputOptions: iInputOptions;
  Titles: iTitles[] = [];
  Genders: iGenders[] = [];
  RegistrationsTypes: iRegistrationstypes[] = [];
  Users: iUsers[];
  Customers: iCustomers[] = [];
  Positions: iPositions[] = [];
  OrderStates: iOrderStates[];
  matcher = new InputErrorStateMatcher();
  searchTerm: string;
  columnsForm: FormControl;
  filterForm: FormControl;
  checkedOrdersArray: iOrders[] = [];
  selectedFilter:string = 'nicht-freigegeben';
  deliveryDate:Date;
  pageSize: number = 50;
  pageView: string = '';
  isLoading:boolean = false;
  private matPaginatorIntl: MatPaginatorIntl;
  inprogressWindowWidth = window.innerWidth > window.innerHeight ? '400px' :'90%';
  // parameterBagSubject: iParameterBag = {};

  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private idbService: IdbService,
    private inProgressDialogRef: MatDialogRef<OrdersComponent>,
    private dialog: MatDialog,

  ) {}


  ngOnInit() {
  // parameterBagSubject: iParameterBag;
  // this.dataService.parameterBagSubject$.subscribe(
  //   (bag) => {
  //         this.parameterBagSubject = bag;
  //         this.selectedCustomer = bag && bag.SelectedCustomer? bag.SelectedCustomer : this.selectedCustomer;
  //         this.deliveryDate = bag && bag.DeliveryDate? bag.DeliveryDate: this.deliveryDate;
  //   }, (err) => { console.log('error - parameterBagSubject-subscription: ', err);  });

    this.columnsForm = new FormControl( this.displayedColumns);

    this.inputOptions = this.setInputOptions();
    this.loggedInRoleIds = this.dataService.getLoggedInUserRoleIds();
    this.selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));
    this.deliveryDate = new Date(localStorage.getItem('deliveryDate'));
    this.activeProducts =  this.idbService.idbActiveProducts;

this.displayedColumnsDetail = this.dataService.hasRoles([1,2,3,4]) == true? this.displayedColumnsDetail: this.displayedColumnsDetail.filter(x =>  x != 'OrderDetail_PosDiscount');



  if(this.inputOptions){
      this.Titles = this.inputOptions['titles'];
      this.Genders = this.inputOptions['genders'];
      this.RegistrationsTypes = this.inputOptions['registrationstypes'];
      this.Positions = this.inputOptions['positions'];
      this.Customers = JSON.parse(localStorage.getItem('customers'));
      this.Users  = JSON.parse(localStorage.getItem('users'));
      this.OrderStates = this.inputOptions['orderstates'];
  }

  this.dataService.deliveryDateSubject$.subscribe(
    deliveryDate => {
     this.deliveryDate = deliveryDate;
   }
 );

  this.dataService.selectedCustomerSubject$.subscribe(
    (selectedCustomer) => {
      this.selectedCustomer = selectedCustomer;
    },
    (err) => {
      console.log('error - selectedCustomer-subscription: ', err);
    }
  );
  this.dataService.activeProductsSubject$.subscribe(
    (products) => {
      this.activeProducts = products;
    },
    (err) => {
      console.log('error - selectedproduct-subscription: ', err);
    }
  );

    this.createOrdersForm();

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;
      }, err => {
        console.log("error loggedin user: ", err);
      });

      this.dataService.msgSubject$.subscribe(
        msg => {
          if(msg.Name.toLowerCase() == "editablecustomerid"){
              this.editableCustomerId = +msg.Message
          }
          if(msg.Name.toLowerCase() == "orderadded"){
            this.populateData();
          }

          if (msg.Name.toLowerCase() == 'applyproductfilter') {
            let filterArray = msg.Message
              .trim()
              .toLowerCase()
              .split(/(\s+)/)
              .filter((e) => e.trim().length > 0);
            let filter0 =    filterArray && filterArray[0] != undefined ? filterArray[0] : '';
            let filter1 =    filterArray && filterArray[1] != undefined ? filterArray[1] : '';
            let filter2 =    filterArray && filterArray[2] != undefined ? filterArray[2] : '';

            this.datasource.filter = msg.Message.trim().toLowerCase();

            this.datasource.filterPredicate = (data: iOrders, filter: string) => {

              if (filter0 != '' && filter1 == '' && filter2 == '') {
                return JSON.stringify(data.ShoppingCarts.Shoppingcartitems.filter(x => x.Product.ProductsId +' '+ x.Product.ProductNumber +' '+ x.Product.DescRow1 +' '+ x.Product.DescRow2 +' '+ x.Product.Description)).toLowerCase().indexOf(filter0) != -1;
              }
              if (filter0 != '' && filter1 != '' && filter2 == '') {
                return (
                  JSON.stringify(data.ShoppingCarts.Shoppingcartitems.filter(x => x.Product.ProductsId +' '+ x.Product.ProductNumber +' '+ x.Product.DescRow1 +' '+ x.Product.DescRow2 +' '+ x.Product.Description)).toLowerCase().indexOf(filter0) != -1 &&
                  JSON.stringify(data.ShoppingCarts.Shoppingcartitems.filter(x => x.Product.ProductsId +' '+ x.Product.ProductNumber +' '+ x.Product.DescRow1 +' '+ x.Product.DescRow2 +' '+ x.Product.Description)).toLowerCase().indexOf(filter1) != -1
                );
              }
              if (filter0 != '' && filter1 != '' && filter2 != '') {
                return (
                  JSON.stringify(data.ShoppingCarts.Shoppingcartitems.filter(x => x.Product.ProductsId +' '+ x.Product.ProductNumber +' '+ x.Product.DescRow1 +' '+ x.Product.DescRow2 +' '+ x.Product.Description)).toLowerCase().indexOf(filter0) != -1 &&
                  JSON.stringify(data.ShoppingCarts.Shoppingcartitems.filter(x => x.Product.ProductsId +' '+ x.Product.ProductNumber +' '+ x.Product.DescRow1 +' '+ x.Product.DescRow2 +' '+ x.Product.Description)).toLowerCase().indexOf(filter1) != -1 &&
                  JSON.stringify(data.ShoppingCarts.Shoppingcartitems.filter(x => x.Product.ProductsId +' '+ x.Product.ProductNumber +' '+ x.Product.DescRow1 +' '+ x.Product.DescRow2 +' '+ x.Product.Description)).toLowerCase().indexOf(filter2) != -1
                );
              }

            };

            setTimeout(() => { if (  msg.Type.toLowerCase() == 'enter' ||  msg.Type.toLowerCase() == 'numpadenter' || msg.Action && msg.Action.toLowerCase() == 'clear' ) {
              this.goToNextRow('OrderDetail_row', 0, 0); } }, 0);


          }
        }, err => {
            console.log("error adding order: ", err);
          }
          );

          this.populateData();
          this.onSelectFilter('nicht-freigegeben');


  }


    applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filterPredicate = (data: iOrders, filter: string) => (JSON.stringify(data).toLowerCase().indexOf(filter)) != -1;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  onSelectColumns(event: {isUserInput: any;    source: { value: any; selected: any };  })
  {
   if (event.isUserInput) {
     if (event.source.selected === true) {
       this.displayedColumns.push(event.source.value);
     } else {
       this.displayedColumns = this.displayedColumns.filter(x => x !=  event.source.value)
     }
     if(!!this.displayedColumns.filter(x => x == 'actions')){
       this.displayedColumns = this.displayedColumns.filter(x => x !=  'actions');
       this.displayedColumns.push('actions');
     }
   }
 }

 onSelectFilter($event){
  if($event){
    if($event == "nicht-freigegeben"){
      this.selectedFilter = 'nicht-freigegeben';
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Canceled == 0 && data.Published == 0  && data.ShoppingCarts.ShoppingCartStateId != 7 && data.ShoppingCarts.ShoppingCartStateId != 8}
    }
    if($event == "freigegeben"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Canceled == 0 && data.Published == 1 && data.Imported == null && data.Exported == null  && data.ShoppingCarts.ShoppingCartStateId != 7 && data.ShoppingCarts.ShoppingCartStateId != 8}
      this.selectedFilter = 'freigegeben';
    }
    if($event == "gelöscht"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Canceled == 1 } // && data.ShoppingCarts.ShoppingCartStateId != 7 && data.ShoppingCarts.ShoppingCartStateId != 8
      this.selectedFilter = 'gelöscht';
    }
    if($event == "Angebote"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Canceled == 0  && data.ShoppingCarts.ShoppingCartStateId == 7 }
      this.selectedFilter = 'Angebote';
    }
    if($event == "Vorlagen"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Canceled == 0  && data.ShoppingCarts.ShoppingCartStateId == 8 }
      this.selectedFilter = 'Vorlagen';
    }
    if($event == "alle"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Canceled == 0 && data.OrdersId != 0 }
      this.selectedFilter = 'alle';
    }

    this.datasource.filter = '1';
    this.datasource.data = this.orders;

  }
 }

 getLoggedInUser():iUsers{
  let user: iUsers = JSON.parse(localStorage.getItem('loggedinUsers'))[0];
  return user;
 }
 getLoggedInUserRolles():number[]{
  let user: iUsers = JSON.parse(localStorage.getItem('loggedinUsers'))[0];
  let userroles: iUserroles[] = user.Userroles;
  let roles: number[] = userroles.map(a => a.RolesId);
  return roles;
 }


 populateData(){
    let endPoint: string;
    let id: number;
    this.loggedInUser = this.dataService.getLogedInUser();
    this.loggedInRoleIds = this.dataService.getLoggedInUserRoleIds();

    if(this.loggedInRoleIds != undefined && this.loggedInRoleIds.includes(4)){
      endPoint= 'getnestedordersbyuser'
      id = this.loggedInUser.UsersId
    }else if(this.selectedCustomer != undefined && this.loggedInRoleIds.includes(5)){
      endPoint= 'getnestedordersbycustomer'
      id = this.selectedCustomer.CustomersId
    }else if( (this.loggedInRoleIds.includes(1) || this.loggedInRoleIds.includes(2))){
      endPoint= 'getnestedorders'
      id = 0
    }

    if(endPoint != undefined && id != 0){     // id = 0, user is admin > get all orders
      this.dataService.getData('custom',endPoint, id).subscribe(
        data => {
          let order: iOrders;
          this.orders = [];

      for (const id in data) {
        if (data.hasOwnProperty(id)) {
          order = data[id].Orders;
          if(order){
            order.ShoppingCarts = data[id].Orders.ShoppingCarts[0];
            if(order.ShoppingCarts && order.ShoppingCarts){

            }
            this.orders.push(order);
          }
        }
      }

      this.copyShoppingcartItemPriceToProduct(this.orders);
      this.datasource.data = this.orders;

      this.onSelectFilter("nicht-freigegeben");

        }, err => {
          console.log('error retriving orders: ', err);
        });
    }else if(endPoint != undefined && id == 0){
      this.dataService.getData('custom',endPoint).subscribe(
        data => {
          let order: iOrders;
          let shoppingCartItems: iShoppingcartitems[] = [];
          let product: iProducts;
          this.orders = [];

      for (const id in data) {
        if (data.hasOwnProperty(id)) {
          order = data[id].Orders;
          if(order){
            order.ShoppingCarts = data[id].Orders.ShoppingCarts[0];
            shoppingCartItems = order.ShoppingCarts.Shoppingcartitems;
            this.orders.push(order);
          }
        }
      }
      this.copyShoppingcartItemPriceToProduct(this.orders);
      this.datasource.data = this.orders;

      this.onSelectFilter("nicht-freigegeben");

        }, err => {
          console.log('error retriving orders: ', err);
        });
    }else{
      console.log('api endpoint or id not provided')
    }
  }

  copyShoppingcartItemPriceToProduct(orders: iOrders[]){
    if(orders){
      orders.forEach(order => {
        let shoppingcartitems: iShoppingcartitems[] = order.ShoppingCarts.Shoppingcartitems;
        shoppingcartitems.forEach(shoppingcartitem => {
          shoppingcartitem.Product.Price = shoppingcartitem.Price;
          shoppingcartitem.Product.ListPrice = shoppingcartitem.ListPrice;
          shoppingcartitem.Product.PriceType = shoppingcartitem.PriceType;

          shoppingcartitem.Product.StockValue = shoppingcartitem.Productstocks.StockValue ;
          shoppingcartitem.Product.DispositionStockValue = shoppingcartitem.Productstocks.DispositionStockValue ;
        });
      });

    }
  }

  updateEditDataSource(orders: iOrders){
    this.selectedOrder = orders;

    this.editDatasource.data = orders.ShoppingCarts.Shoppingcartitems
    console.log("editDatasource: ", this.editDatasource.data);
    if(this.editDatasource.data){
      console.log("orders datasource2: ", this.editDatasource.data);
      this.editDatasource.sort = this.sort;
      this.editDatasource.paginator = this.paginator;
    }

  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }

  getLSCustomerById(customerId: number){
  let customer: iCustomers;
  customer = this.Customers? this.Customers.find(x => x.CustomersId == customerId): customer;
  console.log("order let customer: ", customer);
  return customer != undefined? '('+ customer.CustomersId +')'+ customer.Name1: customerId.toString();
  }
  getLSUserById(userId: number){
  let user: iUsers = this.Users.find(x => x.UsersId == userId);
  return user != undefined? '('+ user.UsersId +')'+ user.FirstName+ ' ' + user.LastName : userId.toString();
  }
  getLSOrderStateById(OrderStatesId: number):string{

    let OrderStates: string  = this.OrderStates.find(x => x.OrderStatesId  == OrderStatesId).Name;
    return OrderStates != undefined? OrderStates : OrderStatesId.toString();
  }

  createOrdersForm(order?: iOrders){
    this.ordersForm = this.formBuilder.group(
      {
        OrdersId:        		  [order != undefined && order.OrdersId != undefined? order.OrdersId : 0, []],
        ShoppingCartsId:      [order != undefined && order.ShoppingCartsId != undefined? order.ShoppingCartsId : 0, []],
        PreparedBy:        		[order != undefined && order.PreparedBy != undefined? order.PreparedBy : 0, []],
        OrderedBy:        		[order != undefined && order.OrderedBy != undefined? order.OrderedBy : 0, []],
        OrderedFor:        		[order != undefined && order.OrderedFor != undefined? order.OrderedFor : 0, []],
        DeliveryAddress:      [order != undefined && order.DeliveryAddress != undefined? order.DeliveryAddress : 0, []],
        InvoiceAddress:       [order != undefined && order.InvoiceAddress != undefined? order.InvoiceAddress : 0, []],
        OrderStatesId:        [order != undefined && order.OrderStatesId != undefined? order.OrderStatesId : 0, []],
        Comment:        		  [order != undefined && order.ShoppingCarts != undefined && order.ShoppingCarts.Comment != undefined? order.ShoppingCarts.Comment : '', []],
        OrderInfo:        		[order != undefined && order.ShoppingCarts != undefined && order.ShoppingCarts.OrderInfo != undefined? order.ShoppingCarts.OrderInfo : '', []]
        // Comment:        		  [order != undefined && order.Comment != undefined? order.Comment : '', []],
        // OrderInfo:        		[order != undefined && order.OrderInfo != undefined? order.OrderInfo : '', []]

      }
    );
  }


  onList(){
    this.mode = 'list';
    this.item = 'Bestellungen . . .';
    console.log("this mode: ", this.mode);
    this.populateData();
  }

  onDetail(){
    this.mode =  'detail';
    this.item = 'Bestellpositionen . . .';
  }

  onEdit(order: iOrders){
    this.mode = 'edit';
    this.order = order;

    this.item = 'Bestellung: '+ order.ShoppingCarts.CustomersName1 + ' ' + order.ShoppingCarts.CustomersName2 + ', (Lieferdatum:' + this.pipe.transform(order.ShoppingCarts.DeliveryDate,'dd.MM.yyyy')+')' ;
    this.setSelectedOrder(order);
    this.createOrdersForm(order);

    this.focusFirstOrderDetailRow(order.ShoppingCarts.Shoppingcartitems);
  }
  onCreate(){
    this.mode = 'create';
    this.item = 'Neu Bestellung erstellen';
    this.reSetSelectedOrder()
    this.createOrdersForm();
  }
  onDelete(order: iOrders){
    this.mode = 'delete';
    this.order = order;
    const curentOrder:string   = order != undefined && order.ShoppingCarts != undefined  ? order.ShoppingCarts.Name +', ' + new Date(this.pipe.transform(order.ShoppingCarts.DeliveryDate,'yyyy-MM-dd')) :'';
    this.item = 'Bestellung: '+ curentOrder +' löschen . . .';
    // this.onSubmit(order);
  }

  onPrintPdf(order: iOrders){
    if(order){
      let customer = this.hasRoles([1,2,3,4]) ? this.Customers.find(x => x.CustomersId == order.ShoppingCarts.CustomerId): this.selectedCustomer;
      this.dialogService.printDialog(customer, order, this.selectedFilter).afterClosed().subscribe(
        info => {
          console.log('info is ', info);
          if(info != undefined){
            order.ShoppingCarts.OrderInfo = info;
          }
        }, err => {
          console.log('error dialog ', err);
        }
      );
    }

  }

  getFormData(): iOrders{
    // let _order: iOrders;
    // _order.OrdersId =            +this.ordersForm.get('OrdersId').value;
    // _order.ShoppingCartsId =      +this.ordersForm.get('ShoppingCartsId').value;
    // _order.PreparedBy =            this.ordersForm.get('PreparedBy').value;
    // _order.OrderedBy =            this.ordersForm.get('OrderedBy').value;
    // _order.OrderedFor =           this.ordersForm.get('OrderedFor').value;
    // _order.DeliveryAddress =      this.ordersForm.get('DeliveryAddress').value;
    // _order.InvoiceAddress =     this.ordersForm.get('InvoiceAddress').value;
    // _order.OrderStatesId=       +this.ordersForm.get('OrderStatesId').value;
    // _order.ShoppingCarts.Comment =              this.ordersForm.get('Comment').value;
    // _order.ShoppingCarts.OrderInfo=            this.ordersForm.get('OrderInfo').value;

    let _order: iOrders =
    {
      OrdersId :            +this.ordersForm.get('OrdersId').value,
      ShoppingCartsId:      +this.ordersForm.get('ShoppingCartsId').value,
      PreparedBy:            this.ordersForm.get('PreparedBy').value,
      OrderedBy:             this.ordersForm.get('OrderedBy').value,
      OrderedFor:            this.ordersForm.get('OrderedFor').value,
      DeliveryAddress:      this.ordersForm.get('DeliveryAddress').value,
      InvoiceAddress :      this.ordersForm.get('InvoiceAddress').value,
      OrderStatesId:       +this.ordersForm.get('OrderStatesId').value

    }
    _order.ShoppingCarts =  this.selectedOrder.ShoppingCarts;
    _order.ShoppingCarts.Comment =  this.selectedOrder.ShoppingCarts.Comment;
    _order.ShoppingCarts.OrderInfo= this.selectedOrder.ShoppingCarts.OrderInfo;
    return _order;
    // _order.ShoppingCarts.Comment =              this.ordersForm.get('Comment').value;
    // _order.ShoppingCarts.OrderInfo=            this.ordersForm.get('OrderInfo').value;

  }


  setSelectedOrder(order: iOrders){
    if(order != undefined){
      this.selectedOrder = order;
      localStorage.setItem('selectedOrder', JSON.stringify(this.selectedOrder));
      this.updateEditDataSource(order);
    }
  }

  reSetSelectedOrder(){
      this.selectedOrder = undefined;
      localStorage.removeItem('selectedUser');
  }

  onReset(){
    this.mode = 'list';
  }

  isStaffUser(orderForm: FormGroup): boolean{
    if(!orderForm.get('RegistrationsTypeId')){ return false;}
    else{
      let staffOrders: number[]= [1,2,3,4]
      console.log("type: ", orderForm.get('RegistrationsTypeId').value);
      console.log("isstaff: ", !!staffOrders.find(x => x == +orderForm.get('RegistrationsTypeId').value));
      return !!(+orderForm.get('RegistrationsTypeId').value == 1);
    }
  }

  public setInputOptions(): iInputOptions{
    if(!!localStorage.getItem("inputOptions")){
      this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
      console.log("inputOptions0", this.inputOptions);
      return this.inputOptions;
    }
    else{
         this.dataService.getData("custom","getoptions").subscribe(
      data => {
        this.inputOptions = data;
        if(this.inputOptions){
          console.log("inputOptions1", this.inputOptions);
          localStorage.removeItem("inputOptions");
          localStorage.setItem("inputOptions", JSON.stringify(this.inputOptions));
          return this.inputOptions;
        }
        else{
          this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
          console.log("inputOptions2", this.inputOptions);
          return this.inputOptions;
        }
      },err =>{
        console.log("error get inputOptions in app-component", err);
      }
    );
    }

  }

  // ------------------------------------------------------------------------

  onOrderKeyUp(item: iOrders, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number) {
    // let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 80, 79, 87, 70, 65, 75, 76, 77, 78, 66, 68, 75];
    let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 73, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90, 83,    71, 81, 85, 82];
    if( $event && keyCodes.includes($event.keyCode) ){
        this.onOrderKeyUpEvent(item, selfPrefix, nextPrefix, $event,  rowIndex);
    }
  }

  onOrderKeyUpEvent(item?: iOrders, selfPrefix?:string, nextPrefix?:string, $event?: any,  rowIndex?: number ){

    let code = $event != undefined? $event.code: undefined;
    let focusItem: iOrders;
    let currentIndex = this.datasource.filteredData.findIndex(row => row === item);
    let nextIndex: number;

    if(code && code == 'Escape'){
      this.onList();
      return
    }else if(code && code == 'Enter'  || code == 'NumpadEnter'){
      this.onEdit(item);
      rowIndex = this.datasource.filteredData.length -1 > rowIndex + 1? rowIndex + 1:rowIndex ;

    }else if(code && code == 'ArrowDown'){

      this.goToNextInput('hiddenOrderInput', rowIndex, 1);
      this.goToNextRow('Order_row', rowIndex, 1);
    }
    else if(code && code == 'ArrowUp'){
      this.goToNextInput('hiddenOrderInput', rowIndex, -1);
      this.goToNextRow('Order_row', rowIndex, -1);
    }
    else if (code && code == 'ArrowLeft') {

      nextPrefix = selfPrefix == 'OrderDetail_PosDiscount'?'OrderDetail_Price':(selfPrefix == 'OrderDetail_Price'? 'OrderDetail_QuantityInput':'');
      focusItem = this.datasource.filteredData[currentIndex];
      nextIndex = currentIndex + 1;
    } else if(code && code == 'ArrowRight') {
      nextPrefix = selfPrefix == 'OrderDetail_QuantityInput'?'OrderDetail_Price':(selfPrefix == 'OrderDetail_Price'? 'OrderDetail_PosDiscount':'');
      focusItem = this.datasource.filteredData[currentIndex];
      nextIndex = currentIndex + 1;
    }
    if(focusItem){

      setTimeout(() => { this.onFocusOrderRow(focusItem, selfPrefix, nextPrefix, $event); }, 0);
    }

}
onOrderDetailKeyUp(item: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number) {
  // let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90,83];
  let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 73, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90, 83,     71, 81, 85, 82];
  if( $event && keyCodes.includes($event.keyCode) )
  {
      this.onOrderDetailKeyUpEvent(item, selfPrefix, nextPrefix, $event, rowIndex);
      this.onInputEdit(item, selfPrefix, nextPrefix, $event, rowIndex);
      this.dataService.onKeyCombination($event, item.Product);
  }
}

onInputEdit(shoppingcartitem: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number) {
  shoppingcartitem.Quantity = +shoppingcartitem.Quantity.toFixed(2);
  shoppingcartitem.PosDiscount = +shoppingcartitem.PosDiscount.toFixed(2);
  shoppingcartitem.Price = +shoppingcartitem.Price.toFixed(2);
  shoppingcartitem.Product.QuantityInput = +shoppingcartitem.Quantity.toFixed(2);
  shoppingcartitem =  this.dataService.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix+'-');

}

  onInputEdit_2(shoppingcartitem: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number) {


    console.log("edited shoppingcartitem: ",shoppingcartitem);

    shoppingcartitem.Quantity = +shoppingcartitem.Quantity.toFixed(2);
    shoppingcartitem.PosDiscount = +shoppingcartitem.PosDiscount.toFixed(2);
    shoppingcartitem.Price = +shoppingcartitem.Price.toFixed(2);
    shoppingcartitem.Product.QuantityInput = +shoppingcartitem.Quantity.toFixed(2);

    shoppingcartitem.Product= this.dataService.roundUpQuantity(shoppingcartitem.Product);
    this.totalRowNetDiscount(shoppingcartitem);
    shoppingcartitem = this.setBulkDiscount(shoppingcartitem);

    if(this.selectedCustomer){
      if(this.selectedOrder.ShoppingCarts && this.selectedOrder.ShoppingCarts.Shoppingcartitems){

        let index: number = this.selectedOrder.ShoppingCarts.Shoppingcartitems.findIndex(x => x.Product.ProductsId == shoppingcartitem.ProductId
                                            && x.ShoppingCartId == this.selectedOrder.ShoppingCarts.ShoppingcartsId);

        if(index !== -1){
          this.selectedOrder.ShoppingCarts.Shoppingcartitems[index] = shoppingcartitem;
          this.updateEditDataSource(this.selectedOrder);
        }

        localStorage.removeItem(this.selectedCustomer.CustomersId+'_selectedShoppingCart');
        localStorage.setItem(this.selectedCustomer.CustomersId+'_selectedShoppingCart', JSON.stringify(this.selectedOrder.ShoppingCarts));

      }else{
        console.log("no matching product found to update in shoppingcart: ", this.selectedOrder.ShoppingCarts.Shoppingcartitems);
      }

        console.log("this.shoppingCarts.ShoppingcartItems: ", this.selectedOrder.ShoppingCarts.Shoppingcartitems);
    }else{
      console.log("no customer selected");
    }

  }

    openInfoDialog(shoppingcartitems: iShoppingcartitems){
    this.dialogService.infoInputDialog(shoppingcartitems.PosInfo).afterClosed().subscribe(
      info => {
        console.log('info is ', info);
        if(info != undefined){
          shoppingcartitems.PosInfo = info;
        }
         this.onInputEdit(shoppingcartitems,'');
        console.log('iShoppingcartitems ', this.selectedOrder.ShoppingCarts.Shoppingcartitems);
      }, err => {
        console.log('error dialog ', err);
      }
    );
  }

  setBulkDiscount(shoppingcartitem: iShoppingcartitems):iShoppingcartitems{
    return this.dataService.setBulkDiscount(shoppingcartitem);
  }

  totalRowDiscount(shoppingcartitem: iShoppingcartitems): number{
    return shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.PosDiscount / 100;
  }

  totalOrderPrice(order: iOrders): string{
    let total: number = 0;
    let sumRowProPrice = 0;
    let sumRowProSurcharge = 0;
    let sumRowProDiscount = 0;
    let shopingCartItems: iShoppingcartitems[] = order.ShoppingCarts.Shoppingcartitems;
    if(shopingCartItems){
      shopingCartItems.forEach(shopingCartItem => {
        sumRowProPrice += this.totalRowPrice(shopingCartItem);
        sumRowProDiscount += this.totalRowDiscount(shopingCartItem);
        sumRowProSurcharge += this.totalRowSurcharge(shopingCartItem);
    });
    }

    return (sumRowProPrice - sumRowProDiscount).toFixed(2);
  }

  totalOrderSurcharge(order: iOrders): string{
    let totalOrderSurcharge = 0;
    let shopingCartItems: iShoppingcartitems[] = order.ShoppingCarts.Shoppingcartitems;
    if(shopingCartItems){
      shopingCartItems.forEach(shoppingcartitem => {
        totalOrderSurcharge += this.totalRowSurcharge(shoppingcartitem);
      });
    }
    return totalOrderSurcharge? totalOrderSurcharge.toFixed(2): '0.00';
  }

  totalRowPrice(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowPrice(shoppingcartitem);
  }

  totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowSurcharge(shoppingcartitem);

  }
  positionRowPartialPackageCharge(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.positionRowPartialPackageCharge(shoppingcartitem);
  }

  getTooltipDiscount(shoppingcartitem: iShoppingcartitems): iTooltipDiscountDisplay{
    return  this.dataService.getTooltipDiscount(shoppingcartitem);
  }

  totalRowNetDiscount(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowNetDiscount(shoppingcartitem);
  }

  saveLsOrders(order: iOrders){
    let orders: iOrders[] = [];
    if(!!localStorage.getItem(this.selectedCustomer.CustomersId+'_orders')){
      orders = JSON.parse(localStorage.getItem(this.selectedCustomer.CustomersId+'_orders'));
      orders = [order, ...orders];
      localStorage.removeItem(this.selectedCustomer.CustomersId+'_orders');
    }else{
      orders = [order];
    }

    localStorage.setItem(this.selectedCustomer.CustomersId+'_orders', JSON.stringify(orders));

  }
  removeOrderedShopingCard(shoppingCart: iShoppingcarts){

          localStorage.removeItem(this.selectedCustomer.CustomersId+'_selectedShoppingCart');
          localStorage.removeItem(this.selectedCustomer.CustomersId+'_shoppingCartItemsArray');

          let shoppingCartArray: iShoppingcarts[] = JSON.parse(localStorage.getItem(this.selectedCustomer.CustomersId+'_shoppingCartArray'));
          if(shoppingCartArray && shoppingCartArray.length > 1){
            shoppingCartArray = shoppingCartArray.filter(x => x.Name != shoppingCart.Name);
            localStorage.setItem(this.selectedCustomer.CustomersId+'_shoppingCartArray', JSON.stringify(shoppingCartArray));
          }else{
            localStorage.removeItem(this.selectedCustomer.CustomersId+'_shoppingCartArray');
          }
          this.populateData();
  }

  createOrder(shoppingcart: iShoppingcarts): iOrders{

    if(!shoppingcart){
      console.log('no shoppingcart to order: ',shoppingcart);
      return;
    }
    // let order: iOrders;
    // order.OrdersId = 0;
    // order.ShoppingCartsId = shoppingcart.ShoppingcartsId;
    // order.PreparedBy = shoppingcart.UserId;
    // order.OrderedBy = shoppingcart.UserId;
    // order.OrderedFor = shoppingcart.CustomerId;
    // order.DeliveryAddress = +this.ordersForm.get('DeliveryAddress') ?  this.ordersForm.get('DeliveryAddress').value : 0;
    // order.InvoiceAddress = +this.ordersForm.get('InvoiceAddress') ?  this.ordersForm.get('InvoiceAddress').value : 0;
    // order.OrderStatesId = 0;
    // order.ShoppingCarts.Comment = this.ordersForm.get('Comment') ?  this.ordersForm.get('Comment').value : '';
    // order.ShoppingCarts.OrderInfo = this.ordersForm.get('OrderInfo') ?  this.ordersForm.get('OrderInfo').value : '';
    // order.DeliveryDate = this.ordersForm.get('DeliveryDate') && this.ordersForm.get('DeliveryDate').value != ''?  this.ordersForm.get('DeliveryDate').value : new Date(Date.now());
    // order.CreateDate = new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    // order.ModifyDate = new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    // order.ShoppingCarts = shoppingcart;

    // return order;

    let _order: iOrders = {
      OrdersId: 0,
      ShoppingCartsId: shoppingcart.ShoppingcartsId,
      PreparedBy: shoppingcart.UserId,
      OrderedBy: shoppingcart.UserId,
      OrderedFor: shoppingcart.CustomerId,
      DeliveryAddress: +this.ordersForm.get('DeliveryAddress') ?  this.ordersForm.get('DeliveryAddress').value : 0,
      InvoiceAddress: +this.ordersForm.get('InvoiceAddress') ?  this.ordersForm.get('InvoiceAddress').value : 0,
      OrderStatesId: 0,
      // Comment: this.ordersForm.get('Comment') ?  this.ordersForm.get('Comment').value : '',
      // OrderInfo: this.ordersForm.get('OrderInfo') ?  this.ordersForm.get('OrderInfo').value : '',
      DeliveryDate: this.ordersForm.get('DeliveryDate') && this.ordersForm.get('DeliveryDate').value != ''?  this.ordersForm.get('DeliveryDate').value : new Date(Date.now()),
      CreateDate: new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
      ModifyDate: new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
      ShoppingCarts: shoppingcart
    }

    _order.ShoppingCarts =  this.selectedOrder.ShoppingCarts;
    _order.ShoppingCarts.Comment =  this.selectedOrder.ShoppingCarts.Comment
    _order.ShoppingCarts.OrderInfo= this.selectedOrder.ShoppingCarts.OrderInfo
  }


  onEditSave(order: iOrders){

    if(order != null){
      this.isLoading = true;
      let formdata: iOrders = this.getFormData();
      order.OrderStatesId = formdata != undefined && formdata.OrderStatesId != 0? +formdata.OrderStatesId : order.OrderStatesId ;
      order.ShoppingCarts.Comment = formdata != undefined && formdata.ShoppingCarts.Comment != undefined? formdata.ShoppingCarts.Comment : order.ShoppingCarts.Comment ;
      order.ShoppingCarts.OrderInfo = formdata != undefined && formdata.ShoppingCarts.OrderInfo != undefined? formdata.ShoppingCarts.OrderInfo : order.ShoppingCarts.OrderInfo ;

      this.dataService.PutData('custom',order,"putOrders").subscribe(
        data => {
          // this.order = data;
          this.isLoading = false;
          if(data != undefined)
          {
            this.datasource.data = this.orders;
            this.mode = 'list';
            this.snackbarService.snackbarAction('Data update erfolgreich ','ok');
            this.onList();
            setTimeout(() => { this.onFocusOrderRow(order, ''); }, 0);
          }
        }, err =>{
          this.isLoading = false;
          this.snackbarService.snackbarAction('Data update fehlgeschlagen ','ok');
          this.mode = 'list';
        }
      );
    }

  }

  onDeleteOrder(order: iOrders){
    if(order != null){

      if(order.Published == 1){
        this.snackbarService.snackbarAction('Die Auftrag wurde freigegeben, kann nicht gelöscht oder rückgängig gemacht werden werden ','ok');
        return
      }
      if(order.Canceled == 1){
        this.snackbarService.snackbarAction('Der Auftrag worde gelöscht, kann nicht freigegeben oder rückgängig gemacht werden werden ','ok');
        return
      }

      let w = window.innerWidth > window.innerHeight ? '400px' :'90%';
      // this.dialog.closeAll();
      this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'info', type:'warning', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: ''}});

          order.Canceled = 1;
          this.dataService.PutData('custom',order,"putOrders").subscribe(
            data => {
              // this.order = data;
              if(data != undefined)
              {
                this.dialog.closeAll();
                this.datasource.data = this.orders;
                this.mode = 'list';
                this.snackbarService.snackbarAction('Auftrag wurde erfolgreich storniert ','ok');
              }
            }, err =>{
              this.dialog.closeAll();
              this.snackbarService.snackbarAction('Auftrag stornieren fehlgeschlagen ','ok');
              this.mode = 'list';
            }
          );

    }
  }

  onDeletePosition(shoppingcartitem: iShoppingcartitems){
    if(this.selectedOrder.Published == 1 && this.selectedOrder.Imported != null){
      this.snackbarService.snackbarAction('Die Auftrag wurde freigegeben und importiert, Bestellposition kann nicht gelöscht oder rückgängig gemacht werden werden ','ok');
      return
    }
    if(shoppingcartitem != null){

      let w = window.innerWidth > window.innerHeight ? '400px' :'90%';
      // this.dialog.closeAll();
      this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'info', type:'warning', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: ''}});

      // this.isLoading = true;
          this.dataService.RemoveData('custom',shoppingcartitem.ShoppingcartitemsId,'deleteShoppingcartitems').subscribe(
            data => {
              // this.isLoading = false;
              this.dialog.closeAll();
              if(data != undefined && +data > 0)
              {
                this.selectedOrder.ShoppingCarts.Shoppingcartitems = this.selectedOrder.ShoppingCarts.Shoppingcartitems.filter(x => x.ShoppingcartitemsId != shoppingcartitem.ShoppingcartitemsId)
                this.datasource.data = this.orders;
                this.updateEditDataSource(this.selectedOrder);
                this.mode = 'edit';

                this.snackbarService.snackbarAction('Position erfolgreich gelöscht ','ok');

              }
              if(this.selectedOrder.ShoppingCarts.Shoppingcartitems.length == 0){
                this.onDeleteOrder(this.selectedOrder);
              }
            }, err =>{
              // this.isLoading = false;
              this.dialog.closeAll();
              this.snackbarService.snackbarAction('Data löschen fehlgeschlagen ','ok');
              this.mode = 'edit';
            }
          );


    }
  }
  onDisabledClick($event: any, order: iOrders):boolean{
    return false;

  }

  onOrderChecked($event: any, order: iOrders){
    if ($event.checked == true && order != undefined) {
      order.Published = 1;
      this.checkedOrdersArray.push(order);

    } else if($event.checked == false && order != undefined) {
      order.Published = 0;
      this.checkedOrdersArray = this.checkedOrdersArray.filter(x => x.OrdersId !=  order.OrdersId)
    }
}

inProgress = false;
onPublishOrders(order: iOrders){

  if( new Date(order.DeliveryDate)  < new Date(this.pipe.transform(new Date(), 'yyyy-MM-ddT00:00:00', 'UTC', 'de-DE'))  ){
    this.dialogService.okDialog("Error", "Lieferdatum liegt in der Vergangenheit." , "danger",'40%');
    return;
  }

  if(order == undefined){
   this.snackbarService.snackbarAction('Kein auftrag wurde ausgewehlt!');
  }else{

          let w = window.innerWidth > window.innerHeight ? '400px' :'90%';

          this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'info', type:'warning', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: ''}});

          // do not set order.Published = 1;
          order.Imported = null;

          this.dataService.getData('custom', 'pingodata').subscribe(
            odataPing => {
              console.log("odata ping result: ", odataPing);
              if(odataPing && odataPing != 200){
                // this.isLoading = false;
                this.dialog.closeAll();
                this.dialogService.okDialog("Error", "Odata ist nicht verfügbar, Im Moment kann Ihre Auftrag nicht freigegeben werden!!", "danger",'40%');
                return;
              }
              else{
                // ping is OK post the order
                this.dataService.PutData('custom',order,'importOrderToOdata').subscribe(
                  resultOrder => {
                    let postedOrder: iOrders = resultOrder;
                    // this.isLoading = false;
                    this.dialog.closeAll();
                    console.log("post result: ", resultOrder);

                    if(postedOrder == null || postedOrder.Imported == null){
                      this.datasource.data = this.orders;
                      this.mode = 'list';
                      this.onList();
                      this.dialogService.okDialog("Error", "Ihre Auftragsfreigabe ist fehlgeschlagen!! " , "danger",'40%');
                    }
                    else{

                      this.datasource.data = this.orders;
                      this.mode = 'list';
                      this.onList();
                      this.snackbarService.snackbarAction("Ihre Auftrag wurde erfolgreich gespeichert.");
                    }

                  }, err => {
                    // this.isLoading = false;
                    this.dialog.closeAll();
                    console.log('order publish error: ', err);
                    this.dialogService.okDialog("Error", "Auftrag absenden fehlgeschlagen!!" , "danger",'40%');
                  }
                );
                this.dialog.closeAll();
              }
            }, err => {
              // this.isLoading = false;
              this.dialog.closeAll();
              console.log('shoppingCart error: ', err);
              this.dialogService.okDialog("Error", "Auftrag absenden fehlgeschlagen!!" , "danger",'40%');
            }
          );

  }

 }

focustedOrderInputId: string = '';
focusedOrderRowId: string = '';
onFocusOrderRow(item: iOrders, selfPrefix:string, nextPrefix?:string, $event?: any ) {

  this.goToNextInput('OrderDetail_QuantityInput', 0, 0)

  }

  gettotalDetailRowsCount(): number{
    return this.order != undefined? this.order.ShoppingCarts.Shoppingcartitems.length : 0;
  }

  onFocusOrClickTableRow(item: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any,index?:number,totalDetailRowsCount?:number ) {

  var input = document.getElementById(selfPrefix+'-'+index);
  var row = document.getElementById(selfPrefix+'-'+index);
  if(input){ setTimeout(() => input.focus(), 0); }
  if(row){setTimeout(() => row.style.backgroundColor = '#ccffcc', 0);}

}

focusFirstOrderDetailRow(items: iShoppingcartitems[]){
  if(items != undefined && items.length > 0)
  {
    setTimeout(() => { this.goToNextInput('OrderDetail_QuantityInput', 0, 0)}, 0);
    setTimeout(() => { this.focusFirstOrderRow('OrderDetail_row', 0);}, 0);

  }
}

getBg(quantity: number, type: string): string {
  return this.dataService.getBg(quantity,type);
}
onPreventStep(item: iOrders, $event?: any ){
  if($event.keyCode == 38 || $event.keyCode == 40){
    $event.preventDefault();
    return;  }
}



 onOrderDetailKeyUpEvent(shoppingcartitem?: iShoppingcartitems, selfPrefix?:string, nextPrefix?:string, $event?: any, rowIndex?: number ){

        let code = $event != undefined? $event.code: undefined;

        let p:iProducts = this.idbService.idbAllProducts.find(x => x.ProductsId == shoppingcartitem.Product.ProductsId);
        if(p){shoppingcartitem.Product.AveragePurchasePrice = p.AveragePurchasePrice;  shoppingcartitem.Product.LastPurchasePrice = p.LastPurchasePrice}

        if($event.altKey == true && $event.keyCode == 77) // alt + m
        {

          this.snackbarService.snackbarAction("mEK: " + shoppingcartitem.Product.AveragePurchasePrice.toString() + ", lEK: " + shoppingcartitem.Product.LastPurchasePrice.toString(), '');
        }
        if($event.altKey == true && $event.keyCode == 75) // alt + k
        {
          this.snackbarService.snackbarAction("mEK: " + shoppingcartitem.Product.AveragePurchasePrice.toString() + ", lEK: " + shoppingcartitem.Product.LastPurchasePrice.toString(), '');
        }
        if( code && code == 'Escape'){
          this.mode=='list';
          this.onList();
          return
        }else if(code && code == 'Enter'  || code == 'NumpadEnter'){
          this.goToNextInput(selfPrefix, rowIndex, 1);
          this.goToNextRow(selfPrefix, rowIndex, 1);

          return
        }
        else if (code && code == 'ArrowLeft') {
           nextPrefix = selfPrefix == 'OrderDetail_Price'?'OrderDetail_QuantityInput':(selfPrefix == 'OrderDetail_PosDiscount'? 'OrderDetail_Price':'');
          this.goToNextInput(nextPrefix, rowIndex, 0);
        } else if(code && code == 'ArrowRight') {
           nextPrefix = selfPrefix == 'OrderDetail_QuantityInput'?'OrderDetail_Price':(selfPrefix == 'OrderDetail_Price'? 'OrderDetail_PosDiscount':'');
          this.goToNextInput(nextPrefix, rowIndex, 0);
        }
        else if(code == 'ArrowDown'){
          this.goToNextInput(selfPrefix, rowIndex, 1);
          this.goToNextRow('OrderDetail_row', rowIndex, 1);
        }
        else if(code == 'ArrowUp'){
          this.goToNextInput(selfPrefix, rowIndex, -1);
          this.goToNextRow('OrderDetail_row', rowIndex, -1);
        }


 }

  onFocusOutPrice(shoppingcartitem: iShoppingcartitems, $event:any){
    let offeredPrice =  shoppingcartitem.Price - (shoppingcartitem.Price * shoppingcartitem.PosDiscount / 100);
    let minSellPrice = shoppingcartitem.Product.AveragePurchasePrice * 1.17;

    if(offeredPrice < minSellPrice){
      this.snackbarService.snackbarAction("Mindest-VP liegt 17% über mEK!");
      shoppingcartitem.Price = 0;
    }
    return shoppingcartitem.Price.toFixed(2);
  }

  onTransferTemplateOrOfferAsShopingCards(order: iOrders){
    // only to transfer Angebote and Vorlage => (keep the original, and create a copy of WK)
    if(order == undefined || order.ShoppingCarts == undefined || ( !([7,8].includes(order.ShoppingCarts.ShoppingCartStateId)))){  return;  }

    let shoppingcart2Transfer: iShoppingcarts = order? order.ShoppingCarts : null;
    this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
    let shoppingCartStateId = order.ShoppingCarts.ShoppingCartStateId;
    let transferToCustomer: iCustomers;
    let item = 'Datensatz'; item = shoppingCartStateId == 8? 'Vorlage':item;  item = shoppingCartStateId == 7? 'Angebot':item;
    let message = ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten '+item+' trotzdem laden?'
    let priceWarningMessage:string = 'Diese Aktion wirkt sich auf Ihr aktives WK aus., Möchten Sie den WK Vertreterpreis beibehalten?';


    if(shoppingCartStateId== 7){ // Angebot
      let customersArray: iCustomers[] = JSON.parse(localStorage.getItem('customers'));
      transferToCustomer = customersArray.find(x => x.CustomerNumber == shoppingcart2Transfer.CustomersCustomerNumber);
    }
    else if(shoppingCartStateId== 8){ // vorlage
        transferToCustomer= this.selectedCustomer;
    }


    if(transferToCustomer){
      // Vorlage: if order is Vorlage (ShoppingcartStateId == 8), then then Shoppingcart is created for any currenlty active Customer in coolkauf
      shoppingcart2Transfer.ShoppingcartsId = +Date.now();
      shoppingcart2Transfer.Customer = transferToCustomer;
      shoppingcart2Transfer.CustomerId = transferToCustomer.CustomersId;
      shoppingcart2Transfer.CustomersCustomerNumber = transferToCustomer.CustomerNumber;
      shoppingcart2Transfer.CustomersName1 = transferToCustomer.Name1;
      shoppingcart2Transfer.CustomersName2 = transferToCustomer.Name2;
      shoppingcart2Transfer.ShoppingCartStateId = 0;
      shoppingcart2Transfer.Name = transferToCustomer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
      shoppingcart2Transfer.DeliveryDate = this.deliveryDate;
      shoppingcart2Transfer.UserId = this.loggedInUser? this.loggedInUser.UsersId : 0;
      shoppingcart2Transfer.UsersUserrole = this.dataService.getLoggedInUserRoleIds();

      shoppingcart2Transfer.Shoppingcartitems.forEach(Shoppingcartitem => {
        Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
        Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
      });



      this.dialogService.conformDialog(  'Warenkorb',  message,  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
        (data) => {
        if (data != 'ok') {
          return;
        }
        else{

        this.dialog.open(InprogressDialogComponent, {width:this.inprogressWindowWidth, maxHeight: '90%',  disableClose:true, data: {title: 'Info', type:'success', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: 'OK'}}).afterOpened().subscribe(
          (result) =>{
            let activeShoppingCard =  this.idbService.copyAndCreateCustomerShoppingCartFromTemplate(shoppingcart2Transfer);
            this.dataService.setMsgSourceSubject( 'shoppingcarttransfered',  'shoppingcarttransfered',  '',  '',  '',  "",  transferToCustomer,  0 );
            // this.idbService.getAllCusromerShoppingCarts(transferToCustomer);
            if(shoppingCartStateId == 8){
              this.dialog.closeAll();
              this.dataService.clickTab('Warenkorb');
            }
            if(shoppingCartStateId == 7){
              this.dataService.setSelectedCustomerSubject(transferToCustomer);
              this.dialog.closeAll();
              this.dataService.clickTab('Warenkorb');
              // setTimeout(() => { this.dataService.setSelectedCustomerSubject(transferToCustomer); }, 0);
            }
            if(activeShoppingCard){
              this.dialog.closeAll();
              this.idbService.setDeliveryDateOfActiveShoppingCartWithPriceConformation( this.selectedCustomer , this.deliveryDate, priceWarningMessage,activeShoppingCard, true );
            }
        // end of inprogress
            });

        // end of conformDialog
        }});
    }

  }

// onEditAsShoppingCart(order: iOrders){
//   // handle it as Transfered shoppingcart
//   this.onTransferShopingCards(order);
// }

onEditOrderOrOfferOrTemplateAsShoppingCart(order: iOrders){
  if(order == undefined || order.ShoppingCarts == undefined){  return;  }

  let shoppingcart2Transfer: iShoppingcarts = order? order.ShoppingCarts : null;
  this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
  let shoppingCartStateId = order.ShoppingCarts.ShoppingCartStateId;
  let transferToCustomer: iCustomers;
  let item = 'Auftrag'; item = shoppingCartStateId == 8? 'Vorlage':item;  item = shoppingCartStateId == 7? 'Angebot':item;
  let message = ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten '+item+' trotzdem laden und aus Coolkauf-Datenbank ersetzen?'
  let priceWarningMessage:string = 'Diese Aktion wirkt sich auf Ihr aktives WK aus., Möchten Sie den WK Vertreterpreis beibehalten?';

  if(shoppingCartStateId== 8){ // vorlage
    transferToCustomer= this.selectedCustomer;
  }
  else{ // Angebot and Auftrag
    let customersArray: iCustomers[] = JSON.parse(localStorage.getItem('customers'));
    transferToCustomer = customersArray.find(x => x.CustomerNumber == shoppingcart2Transfer.CustomersCustomerNumber);
  }


  if(transferToCustomer){
    // Vorlage: if order is Vorlage (ShoppingcartStateId == 8), then then Shoppingcart is created for any currenlty active Customer in coolkauf
    shoppingcart2Transfer.ShoppingcartsId = +Date.now();
    shoppingcart2Transfer.Customer = transferToCustomer;
    shoppingcart2Transfer.CustomerId = transferToCustomer.CustomersId;
    shoppingcart2Transfer.CustomersCustomerNumber = transferToCustomer.CustomerNumber;
    shoppingcart2Transfer.CustomersName1 = transferToCustomer.Name1;
    shoppingcart2Transfer.CustomersName2 = transferToCustomer.Name2;
    shoppingcart2Transfer.ShoppingCartStateId = 0;
    shoppingcart2Transfer.Name = transferToCustomer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
    shoppingcart2Transfer.DeliveryDate = this.deliveryDate;
    shoppingcart2Transfer.UserId = this.loggedInUser? this.loggedInUser.UsersId : 0;
    shoppingcart2Transfer.UsersUserrole = this.dataService.getLoggedInUserRoleIds();

    shoppingcart2Transfer.Shoppingcartitems.forEach(Shoppingcartitem => { // vorlage / Angebot / Auftrag
      Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
      Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
    });

    this.dialogService.conformDialog(  'Warenkorb',  message,  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
      (data) => {
      if (data != 'ok') {
        return;
      }
      else{
        this.dialog.open(InprogressDialogComponent, {width: this.inprogressWindowWidth, maxHeight: '90%',  disableClose:true, data: {title: 'Info', type:'success', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: 'OK'}}).afterOpened().subscribe(
          (result) =>{
              // set Order as delete and then load the Warenkorb
              this.dataService.RemoveData('custom',order.OrdersId,"deleteOrders").subscribe(
                data => {
                  if(data != undefined)
                  {
                    let activeShoppingCard = this.idbService.copyAndCreateCustomerShoppingCartFromTemplate(shoppingcart2Transfer);
                    this.dataService.setMsgSourceSubject( 'shoppingcarttransfered',  'shoppingcarttransfered',  '',  '',  '',  "",  transferToCustomer,  0 );
                    this.idbService.getAllCusromerShoppingCarts(transferToCustomer);

                    if(shoppingCartStateId == 8){
                      this.dialog.closeAll();
                      this.dataService.clickTab('Warenkorb');
                    }
                    else{
                      this.dataService.setSelectedCustomerSubject(transferToCustomer);
                      this.dialog.closeAll();
                      this.dataService.clickTab('Warenkorb');
                    }
                    if(activeShoppingCard){
                      this.dialog.closeAll();
                          this.idbService.setDeliveryDateOfActiveShoppingCartWithPriceConformation( this.selectedCustomer , this.deliveryDate, priceWarningMessage,activeShoppingCard, true );
                    }

                  }
                }, err =>{
                  this.snackbarService.snackbarAction(item + ' update fehlgeschlagen ','ok');
                  this.mode = 'list';
                }

              );
            // end of inprogress
          });
        // end of conformDialog
        }});

  }
}

// onTransferShopingCards_old(order: iOrders){
//   // if order is Angebot (ShoppingcartStateId == 7), then go to method onTransferAngebotBasketClick();
//   if(order == undefined){  return;  }

//   if(order && order.ShoppingCarts.ShoppingCartStateId == 7){
//     order.ShoppingCarts.Name = order.ShoppingCarts.CustomerId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
//     order.ShoppingCarts.DeliveryDate = this.deliveryDate;
//     order.ShoppingCarts.ShoppingcartsId = +Date.now();
//     order.ShoppingCarts.ShoppingCartStateId = 0;
//     order.ShoppingCarts.Shoppingcartitems.forEach(Shoppingcartitem => {
//       Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
//       Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
//     });
//     this.onLoadBasketFromOrderClick(order.ShoppingCarts);
//   }
//   else{
//     let shoppingcart2Transfer: iShoppingcarts = order? order.ShoppingCarts : null;
//     this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;

//       if(!shoppingcart2Transfer){
//         this.dialogService.okDialog("Error", "bitte WK auswählen. " , "danger",'40%');
//         return;
//       }else if(!this.selectedCustomer){
//         this.dialogService.okDialog("Error", "bitte ein Kunde auswählen. ", "danger",'40%');
//         return;
//       }else{
//         if(shoppingcart2Transfer){
//             this.dialogService.conformDialog(  'Warenkorb',  ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten Warenkorb trotzdem laden?',  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
//               (data) => {
//               if (data != 'ok') {
//                 return;
//               }
//               else{

//                 if(this.selectedCustomer && shoppingcart2Transfer && this.deliveryDate){

//                     shoppingcart2Transfer.ShoppingcartsId = +Date.now();
//                     shoppingcart2Transfer.Customer = this.selectedCustomer;
//                     shoppingcart2Transfer.CustomerId = this.selectedCustomer.CustomersId;
//                     shoppingcart2Transfer.CustomersCustomerNumber = this.selectedCustomer.CustomerNumber;
//                     shoppingcart2Transfer.CustomersName1 = this.selectedCustomer.Name1;
//                     shoppingcart2Transfer.CustomersName2 = this.selectedCustomer.Name2;
//                     shoppingcart2Transfer.ShoppingCartStateId = 0;
//                     shoppingcart2Transfer.Name = this.selectedCustomer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
//                     shoppingcart2Transfer.DeliveryDate = this.deliveryDate;
//                     shoppingcart2Transfer.UserId = this.loggedInUser? this.loggedInUser.UsersId : 0;
//                     shoppingcart2Transfer.UsersUserrole = this.dataService.getLoggedInUserRoleIds();

//                     shoppingcart2Transfer.Shoppingcartitems.forEach(Shoppingcartitem => {
//                       Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
//                       Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
//                     });
//                   this.idbService.copyAndCreateCustomerShoppingCartFromTemplate(shoppingcart2Transfer);


//               }
//             }
//           });
//         }
//       }
//   }


//   }
// transferShopingCardsFromOffer(order: iOrders){
//     // if order is Angebot (ShoppingcartStateId == 7), then Shoppingcart is created only for the same original Customer. // go to method onTransferAngebotBasketClick();
//     if(order == undefined){  return;  }

//       let shoppingcart2Transfer: iShoppingcarts = order? order.ShoppingCarts : null;
//       this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;

//     if(!shoppingcart2Transfer){
//         this.dialogService.okDialog("Error", "bitte WK auswählen. " , "danger",'40%');
//         return;
//     }
//     else if(shoppingcart2Transfer && shoppingcart2Transfer.ShoppingCartStateId == 7 && this.deliveryDate){

//       this.dialogService.conformDialog(  'Warenkorb',  ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten Warenkorb trotzdem laden?',  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
//         (data) => {
//           if (data != 'ok') {
//             return;
//         }
//         else{
//           let w = window.innerWidth > window.innerHeight ? '400px' :'90%';
//           this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'Info', type:'success', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: 'OK'}}).afterOpened().subscribe(
//             (result) =>{
//               let customersArray: iCustomers[] = JSON.parse(localStorage.getItem('customers'));
//               let transferToCustomer: iCustomers = customersArray.find(x => x.CustomerNumber == shoppingcart2Transfer.CustomersCustomerNumber);

//               shoppingcart2Transfer.Customer = transferToCustomer;
//               shoppingcart2Transfer.ShoppingcartsId = +Date.now();
//               shoppingcart2Transfer.ShoppingCartStateId = 0;
//               shoppingcart2Transfer.Name = shoppingcart2Transfer.CustomerId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
//               shoppingcart2Transfer.DeliveryDate = this.deliveryDate;

//               shoppingcart2Transfer.Shoppingcartitems.forEach(Shoppingcartitem => {
//               Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
//               Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
//               });

//               // this.idbService.addOrderedCustomerShoppingCartAndSetActive( shoppingcart2Transfer);
//                 this.idbService.copyAndCreateCustomerShoppingCartFromTemplate(shoppingcart2Transfer);
//                 this.dataService.setMsgSourceSubject( 'shoppingcarttransfered',  'shoppingcarttransfered',  '',  '',  '',  "",  transferToCustomer,  0 );
//                 this.dataService.setSelectedCustomerSubject(this.selectedCustomer);

//                 this.dialog.closeAll();
//                 this.dataService.clickTab('Warenkorb');

// // end of subscribe
//             });




//         }
//       });


//     }


//     }

// transferShopingCardsFromTemplate(order: iOrders){
//   // if order is Vorlage (ShoppingcartStateId == 8), then then Shoppingcart is created for any currenlty active Customer in coolkauf
//   if(order == undefined){  return;  }

//   let shoppingcart2Transfer: iShoppingcarts = order? order.ShoppingCarts : null;
//   this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;

//   if(!shoppingcart2Transfer){
//     this.dialogService.okDialog("Error", "bitte WK auswählen. " , "danger",'40%');
//       return;
//   }
//   else if(shoppingcart2Transfer && shoppingcart2Transfer.ShoppingCartStateId == 8 && this.deliveryDate){

//     if(!this.selectedCustomer){
//       this.dialogService.okDialog("Error", "bitte ein Kunde auswählen. ", "danger",'40%');
//          return;
//     }else{
//       if(shoppingcart2Transfer){
//         this.dialogService.conformDialog(  'Warenkorb',  ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten Warenkorb trotzdem laden?',  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
//           (data) => {
//           if(data != 'ok') {
//               return;
//           }
//           else{

//             let w = window.innerWidth > window.innerHeight ? '400px' :'90%';

//             this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'Info', type:'success', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: 'OK'}}).afterOpened().subscribe(

//               (result) =>{

//                 shoppingcart2Transfer.ShoppingcartsId = +Date.now();
//                 shoppingcart2Transfer.Customer = this.selectedCustomer;
//                 shoppingcart2Transfer.CustomerId = this.selectedCustomer.CustomersId;
//                 shoppingcart2Transfer.CustomersCustomerNumber = this.selectedCustomer.CustomerNumber;
//                 shoppingcart2Transfer.CustomersName1 = this.selectedCustomer.Name1;
//                 shoppingcart2Transfer.CustomersName2 = this.selectedCustomer.Name2;
//                 shoppingcart2Transfer.ShoppingCartStateId = 0;
//                 shoppingcart2Transfer.Name = this.selectedCustomer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
//                 shoppingcart2Transfer.DeliveryDate = this.deliveryDate;
//                 shoppingcart2Transfer.UserId = this.loggedInUser? this.loggedInUser.UsersId : 0;
//                 shoppingcart2Transfer.UsersUserrole = this.dataService.getLoggedInUserRoleIds();

//                 shoppingcart2Transfer.Shoppingcartitems.forEach(Shoppingcartitem => {
//                   Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
//                   Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
//                 });
//                 this.idbService.copyAndCreateCustomerShoppingCartFromTemplate(shoppingcart2Transfer);

//                 this.dataService.setMsgSourceSubject( 'shoppingcarttransfered',  'shoppingcarttransfered',  '',  '',  '',  "",  this.selectedCustomer,  0 );
//                 // this.idbService.setCustomerShoppingCartActiveByIndex(transferToCustomer, 0);
//                 // this.dataService.setSelectedCustomerSubject(this.selectedCustomer);

//                 // this.populateData(transferToCustomer);

//                   this.dialog.closeAll();
//                   this.dataService.clickTab('Warenkorb');

// //#################
// // end of subscribe
//               });
//           }
//         });
//       }
//     }
//   }


// }

// onLoadBasketFromOrderClick(shoppingcart2Transfer: iShoppingcarts){
// // Angebot should be only transfered in shopping cart with the original customer

// let transfer2CustomerNumber: string = shoppingcart2Transfer.CustomersCustomerNumber;
// // let transfer2Customer: iCustomers = shoppingcart2Transfer.Customer;

//       if(shoppingcart2Transfer == undefined){  return;    }

//       let w = window.innerWidth > window.innerHeight ? '400px' :'90%';

//       this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'Info', type:'success', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: 'OK'}}).afterOpened().subscribe(

//         result =>{

//           this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
//           this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

//           if(transfer2CustomerNumber && transfer2CustomerNumber != '' && shoppingcart2Transfer){

//             let customersArray: iCustomers[] = JSON.parse(localStorage.getItem('customers'));
//             let transferToCustomer: iCustomers = customersArray.find(x => x.CustomerNumber == transfer2CustomerNumber);
//             if(transferToCustomer && shoppingcart2Transfer){
//               shoppingcart2Transfer.Customer = transferToCustomer;
//               this.idbService.copyAndCreateCustomerShoppingCartFromTemplate( shoppingcart2Transfer);
//               this.dataService.setMsgSourceSubject( 'shoppingcarttransfered',  'shoppingcarttransfered',  '',  '',  '',  "",  transferToCustomer,  0 );
//               // this.idbService.setCustomerShoppingCartActiveByIndex(transferToCustomer, 0);
//               this.dataService.setSelectedCustomerSubject(transferToCustomer);

//               // this.populateData(transferToCustomer);

//                 this.dialog.closeAll();
//                 this.dataService.clickTab('Warenkorb');
//                 // this.onChangeMode(0);
//               }
//             }
//             else
//             {
//               this.dialog.closeAll();
//               this.dialogService.okDialog("Info", "bitte kundennummer eingeben!", "danger",'40%');
//             }
//       // #### end of subscribe
//           });


//     }

getItemsCount(order: iOrders):number{
  let count = 0;
  count = order? order.ShoppingCarts.Shoppingcartitems.length: 0;
  return count;
}

goToNextInput(prefix?: string, rowIndex?:number, nexOrPrevious?: number){
  let inputId = prefix+'-' + rowIndex.toString();
  let inputNextId = prefix+'-' + (rowIndex + nexOrPrevious).toString();


  let currentInput = document.getElementById(inputId);
  let nextInput = document.getElementById(inputNextId );

  if (nextInput) {
    setTimeout(() => nextInput.focus(), 0);
  }
}

goToNextRow(prefix?: string, rowIndex?:number, nexOrPrevious?: number){
  this.totalDetailRowsCount =  this.order != undefined? this.order.ShoppingCarts.Shoppingcartitems.length : 0;
  for (let index = 0; index < this.totalDetailRowsCount; index++) {
    var row = document.getElementById(prefix+'-'+index);
    if(row){setTimeout(() => row.style.backgroundColor = '', 0);}
  }

  let rowId = prefix+'-' + rowIndex.toString();
  let rowNextId = prefix+'-' + (rowIndex + nexOrPrevious).toString();
  let currentRow = document.getElementById(rowId);
  let nextRow = document.getElementById(rowNextId);

  if (nextRow) {
    setTimeout(() => (nextRow.style.backgroundColor = '#ccffcc'), 0);
    if(currentRow){  setTimeout(() => (currentRow.style.backgroundColor = ''), 0);  }
  }
}

focusFirstOrderRow(prefix?: string, rowIndex?:number){
  let rowId = prefix+'-' + rowIndex.toString();
  let firstRow = document.getElementById(rowId);;

  if (firstRow) {
    setTimeout(() => (firstRow.focus()), 0);
    setTimeout(() => (firstRow.style.backgroundColor = '#ccffcc'), 0);
  }
}

hasRoles(roleIds: number[]): boolean{
  return this.dataService.hasRoles(roleIds);
 }


 changeSort(column:string){
    if(column == 'OrdersId'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.OrdersId < b.OrdersId ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.OrdersId > b.OrdersId ? -1 : 1) ;}

    else if(column == 'ShoppingCartsId'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.ShoppingCartsId < b.ShoppingCartsId ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.ShoppingCartsId > b.ShoppingCartsId ? -1 : 1) ;}

    else if(column == 'CreateDate'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.CreateDate < b.CreateDate ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.CreateDate > b.CreateDate ? -1 : 1) ;}

    else if(column == 'DeliveryDate'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.DeliveryDate < b.DeliveryDate ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.DeliveryDate > b.DeliveryDate ? -1 : 1) ;}

    else if(column == 'PreparedBy'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.PreparedBy < b.PreparedBy ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.PreparedBy > b.PreparedBy ? -1 : 1) ;}

    else if(column == 'UsersFirstName'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.ShoppingCarts.UsersFirstName < b.ShoppingCarts.UsersFirstName ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.ShoppingCarts.UsersFirstName > b.ShoppingCarts.UsersFirstName ? -1 : 1) ;}

    else if(column == 'CustomerNumber'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.ShoppingCarts.CustomersCustomerNumber < b.ShoppingCarts.CustomersCustomerNumber ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.ShoppingCarts.CustomersCustomerNumber > b.ShoppingCarts.CustomersCustomerNumber ? -1 : 1) ;}


  this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
 }


 onPageSizeChange($event){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(+this.pageSize);
    this.pgnFirstPage();
}

pageVisit = '';
getPageVisits():string{
  let visits = '';
  if(this.mode!='edit'){
    try{
      let nextVisit =  this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize < this.paginator.length? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize : this.paginator.length : '';
      visits = this.paginator && nextVisit != 0 && +this.paginator.length != 0?  ((this.paginator.pageIndex * this.pageSize)+1).toString() +'-'+ nextVisit.toString()+'/'+this.paginator.length : '';
    }
    catch{  } // do nothing
  }else{
    visits = '';
  }

  return  visits
}

  pgnFirstPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.firstPage();
  }
  pgnBackPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.previousPage();
  }
  pgnNextPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.nextPage();

  }
  pgnLastPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.lastPage();
  }

  onDeliveryDateFromApp(newDeliveryDate: Date){
   if(newDeliveryDate){
      this.deliveryDate = newDeliveryDate;
   }
  }

  getIcon(quantity: number, type: string): string {
    return this.dataService.getIcon(quantity,type);
  }
  bigOrSmall(quantity:number):string{
    return this.dataService.bigOrSmall(quantity);
  }

isPublished(order: iOrders):boolean{
  return order.Published && order.Published == 1;
}
onProductInfo(product: iProducts){
  { this.dialogService.okDialog("Zusatzinformation", product.ProductInfo, "success",'40%');  }
}
onSpecTab(url: string){
  if(url && url != '')
  {
    window.open(url);
  }

}

onOdataStockUpdate(product: iProducts){
  let productNumber: string = product.ProductNumber;
  let productstock: iProductstocks;

  this.dataService.getDataByQuerystring('custom','ProductstockOdataByProductnumber', productNumber).subscribe(
    data => {
      productstock = data;
      let prod: iProducts = this.idbService.idbActiveProducts.find(x => x.ProductNumber == productstock.ProductNumber);
      if(prod){
        prod.StockValue  = productstock.StockValue;
        prod.DispositionStockValue  = productstock.DispositionStockValue;
        prod.OpenOrdersStockValue  = productstock.OpenOrdersStockValue;
        prod.SupplierDeliveryStockValue  = productstock.SupplierDeliveryStockValue;

        let idbActiveProductsIndex = this.idbService.idbActiveProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber) ;
        let idbAllProductsIndex = this.idbService.idbAllProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber);
        let datasourcedataIndex = this.editDatasource.data.findIndex(x => x.Product.ProductNumber == productstock.ProductNumber);

        this.idbService.idbActiveProducts[idbActiveProductsIndex]= prod;
        this.idbService.idbAllProducts[idbAllProductsIndex]= prod;
        this.editDatasource.data[datasourcedataIndex].Product= prod;
      }
    }
    ), err =>{
      console.log("Error onOdataStockUpdate", err);
    };
  }

onOdataCustomerOrderShow(productNumber: string){
  this.dataService.onOdataCustomerOrderShow(productNumber);
}
onOdataSuplierOrderShow(productNumber: string){
  this.dataService.onOdataSuplierOrderShow(productNumber);
}

// onEditAsShoppingCart_old(order: iOrders){
//   if(order == undefined){
//     return;
//   }
//   let shoppingcart2Load: iShoppingcarts = order? order.ShoppingCarts : null;
//   this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
//   let item: string = ''

//   if(order.ShoppingCarts.ShoppingCartStateId == 7){ item = 'Angebot'}else if(order.ShoppingCarts.ShoppingCartStateId == 8){ item = 'Vorlage'} else{ item = 'Auftrag'}

//     if(!shoppingcart2Load){
//       this.dialogService.okDialog("Error", "bitte WK auswählen. " , "danger",'40%');
//       return;
//     }else if(!this.selectedCustomer){
//       this.dialogService.okDialog("Error", "bitte ein Kunde auswählen. ", "danger",'40%');
//       return;
//     }else if(shoppingcart2Load){

//           this.dialogService.conformDialog(  'Warenkorb',  ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten '+item+' trotzdem laden und aus Coolkauf-Datenbank löschen?',  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
//             (data) => {
//             if (data != 'ok') {
//               return;
//             }
//             else{


//               if(shoppingcart2Load && this.deliveryDate){
//                   shoppingcart2Load.Name = shoppingcart2Load.CustomerId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
//                   shoppingcart2Load.DeliveryDate = this.deliveryDate;

//                   shoppingcart2Load.Shoppingcartitems.forEach(Shoppingcartitem => {
//                     // Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
//                     Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
//                   });

//                 // this.idbService.copyAndCreateCustomerShoppingCartFroTemplate(shoppingcart2Load);
//                 this.onLoadBasketFromOrderClick(shoppingcart2Load);

//                 // delete order
//                 order.Canceled = 1;

//                 this.dataService.PutData('custom',order,"putOrders").subscribe(
//                   data => {

//                     if(data != undefined)
//                     {
//                       this.datasource.data = this.orders;
//                       this.mode = 'list';
//                       this.snackbarService.snackbarAction(item + ' update erfolgreich ','ok');
//                       this.onList();
//                       setTimeout(() => { this.onFocusOrderRow(order, ''); }, 0);
//                     }
//                   }, err =>{

//                     this.snackbarService.snackbarAction(item + ' update fehlgeschlagen ','ok');
//                     this.mode = 'list';
//                   }
//                 );


//             }
//           }
//         });

//     }

//   }

// onEditAsShoppingCart_old(order: iOrders){
//   if(order == undefined){
//     return;
//   }
//   let shoppingcart2Load: iShoppingcarts = order? order.ShoppingCarts : null;
//   this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
//   let item: string = ''

//   if(order.ShoppingCarts.ShoppingCartStateId == 7){ item = 'Angebot'}else if(order.ShoppingCarts.ShoppingCartStateId == 8){ item = 'Vorlage'} else{ item = 'Auftrag'}

//     if(!shoppingcart2Load){
//       this.dialogService.okDialog("Error", "bitte WK auswählen. " , "danger",'40%');
//       return;
//     }else if(!this.selectedCustomer){
//       this.dialogService.okDialog("Error", "bitte ein Kunde auswählen. ", "danger",'40%');
//       return;
//     }else if(shoppingcart2Load){

//           this.dialogService.conformDialog(  'Warenkorb',  ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten '+item+' trotzdem laden und aus Coolkauf-Datenbank löschen?',  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
//             (data) => {
//             if (data != 'ok') {
//               return;
//             }
//             else{


//               if(this.selectedCustomer && shoppingcart2Load && this.deliveryDate){
//                   shoppingcart2Load.Customer = this.selectedCustomer;
//                   shoppingcart2Load.CustomerId = this.selectedCustomer.CustomersId;
//                   shoppingcart2Load.CustomersCustomerNumber = this.selectedCustomer.CustomerNumber;
//                   shoppingcart2Load.CustomersName1 = this.selectedCustomer.Name1;
//                   shoppingcart2Load.CustomersName2 = this.selectedCustomer.Name2;
//                   shoppingcart2Load.ShoppingCartStateId = 0;
//                   shoppingcart2Load.Name = this.selectedCustomer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
//                   shoppingcart2Load.DeliveryDate = this.deliveryDate;
//                   shoppingcart2Load.UserId = this.loggedInUser? this.loggedInUser.UsersId : 0;
//                   shoppingcart2Load.UsersUserrole = this.dataService.getLoggedInUserRoleIds();

//                   shoppingcart2Load.Shoppingcartitems.forEach(Shoppingcartitem => {
//                     Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
//                   });
//                 this.idbService.copyAndCreateCustomerShoppingCartFromTemplate(shoppingcart2Load);

//                 // delete order
//                 order.Canceled = 1;

//                 this.dataService.PutData('custom',order,"putOrders").subscribe(
//                   data => {
//                     this.isLoading = false;
//                     if(data != undefined)
//                     {
//                       this.datasource.data = this.orders;
//                       this.mode = 'list';
//                       this.snackbarService.snackbarAction(item + ' update erfolgreich ','ok');
//                       this.onList();
//                       setTimeout(() => { this.onFocusOrderRow(order, ''); }, 0);
//                     }
//                   }, err =>{
//                     this.isLoading = false;
//                     this.snackbarService.snackbarAction(item + ' update fehlgeschlagen ','ok');
//                     this.mode = 'list';
//                   }
//                 );


//             }
//           }
//         });

//     }

//   }



}








import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryProductsComponent } from './category-products/category-products.component';

import { UserRegisterComponent } from './reg/userRegister/userRegister.component';
import { UserLoginComponent } from './auth/UserLogin/UserLogin.component';
import { ChangePasswordComponent } from './auth/changePassword/changePassword.component';
import { ActivationComponent } from './auth/activation/activation.component';
import { ProductCategoryComponent } from './productCategory/productCategory.component';
import { ProductsComponent } from './products/products.component';
import { AccountsComponent } from './accounts/accounts.component';

import { ShopingCartComponent } from './shopingCart/shopingCart.component';
import { SearchCustomerComponent } from './searchCustomer/searchCustomer.component';

import { CustomerAddressComponent } from './reg/customerAddress/customerAddress.component';
import { ProfileComponent} from './profile/profile.component';
import { CustomerRegisterComponent} from './reg/customerRegister/customerRegister.component';
import { RegistrationComponent} from './reg/registration/registration.component';
import { AppComponent } from './app.component';
import { InvoicesComponent } from './invoices/invoices.component';

const routes: Routes = [
  { path: 'home', component: AppComponent },
  { path: 'searchcustomers', component: SearchCustomerComponent },
  { path: 'shoppingcarts', component: ShopingCartComponent },
  { path: 'categoryproducts', component: CategoryProductsComponent },
  { path: 'productcategories', component: ProductCategoryComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'konto', component: AccountsComponent },
  { path: 'invoice', component: InvoicesComponent },
  { path: 'user/register', component: UserRegisterComponent},
  { path: 'user/login', component: UserLoginComponent},
  { path: 'user/changepassword', component: ChangePasswordComponent },
  { path: 'user/activation/:id/:token', component: ActivationComponent },
  { path: 'customer/register', component: CustomerRegisterComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'customer/address', component: CustomerAddressComponent },
  { path: 'customer/profile', component: ProfileComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
   declarations: [

   ]
})
export class AppRoutingModule { }

<div>
  <mat-form-field  appearance="outline" class="w100 ">
    <mat-label>Schnellerfassung <span *ngIf="productUnit != ''"> <b>Einh: {{productUnit}}</b></span></mat-label>
    <div class="row ">
      <div class="col-6 "> <input  class="qckInput" onfocus="this.select();" (focus)="onInpuFocus()" (blur)="onInpuBlur()"  matInput type="text" (keyup)="onQuickBooking($event)" placeholder="P-Nummer" id="quickbookingproductnumber" [(ngModel)]="productnumberInput" /></div>
      <div class="col-6 "><input class="qckInput" onfocus="this.select(); " (focus)="onInpuFocus()" (blur)="onInpuBlur()"  matInput type="number" (keyup)="onQuickBooking($event)" (keydown)="onPreventStep($event)" placeholder="Menge" id="quickbookingquantity" [(ngModel)]="quantityInput"/></div>

    </div>
    </mat-form-field>
</div>



<div>
  <div class=row>
    <div class=col-12>
      <div class="mat-warn"> {{msg}}  </div>
    </div>
  </div>
  <div class=row>
    <div class="col-6"><button class="w100 btn-danger" mat-flat-button (click)="onCancel()" mat-button>abrechen</button></div>
    <div class="col-6"><button class="w100 btn-primary" mat-flat-button (click)="onSave()" mat-button >speichern</button></div>
  </div>
</div>

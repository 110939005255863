

<mat-card class = "card">
  <mat-card-header class="">
     <mat-card-title> <span class="material-icons btn-outline-primary">help_center</span> <span class="text-primary">Impressum</span>  </mat-card-title>

  </mat-card-header>
  <mat-divider></mat-divider>

  <mat-card-content >
    <app-footer></app-footer>
  </mat-card-content>


  <mat-card-actions>
    <div class=row>
      <div class="col-10"></div>
      <div class="col-2"><button mat-button class="w100 btn-primary mat-flat-button"  (click)="onOK()"  >OK</button></div>
    </div>

  </mat-card-actions>
</mat-card>


import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DataService } from '../Services/data.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { iCustomers, iProducts } from '../models/AlaskaModels';
import { SnackbarService } from '../Services/snackbar.service';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import { IdbService } from '../Services/idb.service';
import { ShopingCartComponent } from '../shopingCart/shopingCart.component';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-quickBooking',
  templateUrl: './quickBooking.component.html',
  styleUrls: ['./quickBooking.component.scss']
})
export class QuickBookingComponent implements OnInit {

  filterValue: string ;

  selectedCustomer: iCustomers;
  deliveryDate: Date;
  productUnit:string='';
  pipe = new DatePipe('de-DE');

  constructor(
    private dataService: DataService,
    private idbService: IdbService,
    private snackbarService: SnackbarService
    ) { }

  ngOnInit() {
    let localstorageDate: Date = new Date(localStorage.getItem('deliveryDate'));
    this.deliveryDate = localstorageDate? localstorageDate: new Date(Date.now());

    this.dataService.selectedCustomerSubject$.subscribe( (selectedCustomer) => {
        this.selectedCustomer = selectedCustomer;
      }, (err) => { console.log('error - selectedCustomer-subscription: ', err);  } );

    this.dataService.deliveryDateSubject$.subscribe(
      deliveryDate => {
        this.deliveryDate = deliveryDate;}
    );

    this.dataService.activeProductsSubject$.subscribe(
      (activeroductsData) => {
        this.selectedCustomer = this.selectedCustomer == undefined? JSON.parse( localStorage.getItem('selectedCustomer') ): this.selectedCustomer;

      },
      (err) => { console.log('error - products-subscription in product component: ', err); }
    );

    this.dataService.msgSubject$.subscribe(
      msg => {
        if(msg.Name.toLowerCase() == "mattabclicked"){

        }
        if( msg.Type == "products" && msg.Message == "getProducts"){

        }

      });


  }


onInpuBlur(){
  var ProductnumberInput = document.getElementById("quickbookingproductnumber");
  var quantityInput = document.getElementById("quickbookingquantity");

  if(ProductnumberInput && quantityInput){
    setTimeout(() => (ProductnumberInput.style.backgroundColor = '' ), 0);
    setTimeout(() => (quantityInput.style.backgroundColor = '' ), 0);
  }
}
onInpuFocus(){
  var ProductnumberInput = document.getElementById("quickbookingproductnumber");
  var quantityInput = document.getElementById("quickbookingquantity");

  if(ProductnumberInput && quantityInput){
    setTimeout(() => (ProductnumberInput.style.backgroundColor = '#a3bfff' ), 0);
    setTimeout(() => (quantityInput.style.backgroundColor = '#a3bfff' ), 0);
  }
}

productnumberInput:string;
quantityInput:string
@ViewChild('shoppingCartComponent') shoppingCartComponent:ShopingCartComponent;

@Output() outQuickBooking2ShoppingCart: EventEmitter<iProducts> =   new EventEmitter();

onQuickBooking($event: any){

    let keyCode = $event.keyCode;
    let inputValue = $event.currentTarget.value

    let product:iProducts;

    if( keyCode == 13){

    var ProductnumberInput = document.getElementById("quickbookingproductnumber");
    var quantityInput = document.getElementById("quickbookingquantity");
    var filterAndSearchBox = document.getElementById("filterandsearchbox-0");

    if(this.productnumberInput == undefined || this.productnumberInput == ""){
      setTimeout(() => ProductnumberInput.focus(), 0);
    }
    if(this.productnumberInput != "" && (this.quantityInput == undefined || this.quantityInput == "")){
      setTimeout(() => quantityInput.focus(), 0);
    }

    if(this.productnumberInput && this.quantityInput){

        product = this.idbService.idbActiveProducts.find(x => x.ProductNumber == this.productnumberInput);

        if(product){
         this.productUnit = product.QuantityUnitsShortDesc;

         product.QuantityInput = +this.quantityInput;

        this.selectedCustomer = this.selectedCustomer == undefined? JSON.parse(localStorage.getItem('selectedCustomer')): this.selectedCustomer ;

        this.idbService.insertOrUpdateCustomerShoppingCart(this.selectedCustomer, this.deliveryDate,product);
         this.outQuickBooking2ShoppingCart.emit(product) ;

         this.productnumberInput = '';
         this.quantityInput = '';
         this.productUnit = '';
         setTimeout(() => ProductnumberInput.focus(), 0);

        }
        else{
          this.snackbarService.snackbarAction( 'kein Produkt für produktnummer: '+ this.productnumberInput +' gefunden.'  );
          setTimeout(() => ProductnumberInput.focus(), 0);
         }

    }


    }
}

onPreventStep($event?: any) {
  let code = $event.code;
  if ($event.keyCode == 38 || $event.keyCode == 40) {
    $event.preventDefault();
    return;
  }
}

}

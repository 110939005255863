import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTabsModule, MatTab, MatTabGroup} from '@angular/material/tabs';
import {  iOptions, iAddresses, iUsers, iCustomers, iContacts, iRegistrations } from 'src/app/models/AlaskaModels';
import { DataService } from 'src/app/Services/data.service';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { NgForm, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRegisterComponent } from '../userRegister/userRegister.component';
import { CustomerRegisterComponent } from '../customerRegister/customerRegister.component';
import { CustomerAddressComponent } from '../customerAddress/customerAddress.component';
import { CustomerContactComponent } from '../customerContact/customerContact.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
tabIndex: number = 0;
  @ViewChild('tabRef') tabRef: MatTabGroup;
  @ViewChild('userRegister') userRegister: UserRegisterComponent;
  @ViewChild('customerRegister') customerRegister: CustomerRegisterComponent;
  @ViewChild('customerAddress') customerAddress: CustomerAddressComponent;
  @ViewChild('customerContact') customerContact: CustomerContactComponent;


  constructor(private dataService: DataService,
    private snackbarService: SnackbarService,
    private router: Router) { }

  ngOnInit() {

  }


  onTabSelectedChange(tabIndex: number){

  }
  onSelectedTabChange(index: any)
  {
    console.log('tab index', index)
  }
  onNextClick(index: number){
  }

  onBackClick(index: number){
  }

  onSendPersonClick(){
    this.userRegister.setUser();
    this.onSendClick();
  }

  onSendClick(){

    let user: iUsers = JSON.parse(localStorage.getItem('reg-user'));
    let customer: iCustomers = JSON.parse(localStorage.getItem('reg-customer'));
    let address: iAddresses = JSON.parse(localStorage.getItem('reg-address'));
    let contact: iContacts = JSON.parse(localStorage.getItem('reg-contact'));
    let registration: iRegistrations = {User: user, Customer: customer, Address: address, Contact: contact}

    console.log('send-user: ', user);
    console.log('send-customer: ', customer);
    console.log('send-address: ', address);
    console.log('send-contact: ', contact);
    console.log('send-registration: ', registration);

            user.Password = btoa(user.Password);

              this.dataService.PostData('custom', registration,'postRegistrations').subscribe(
              result => {
                console.log("send subscribe result: ", result);

                this.snackbarService.snackbarAction('Danke für die Registrierung. Bitte aktivieren Sie Ihre Registrierung, indem Sie auf den Bestätigungslink in Ihrer E-Mail '+user.Email.toString()+' klicken. ');

                this.userRegister.usersForm.reset();
                this.customerRegister.customerForm.reset();
                this.customerAddress.addressForm.reset();
                this.customerContact.contactForm.reset();
                this.router.navigate(['/user/login']);
                this.snackbarService.snackbarAction('Registrierungsdaten wurden erfolgreich gesendet.');
                }, err =>  {
                  console.log("PostData error", err)
                  this.snackbarService.snackbarAction('Registrierung fehlgeschlagen!!.');
                }
             );



  }

  onPrint(toPrint: any){
    console.log('print', toPrint);
  }
}



<span class="close btn text-danger material-icons" (click)="onCancel()">  cancel  </span>

<div class="card">

  <div class="card-header">

    <div class="row">
      <div  class="col-5 text-primary " >   <mat-paginator  [pageSizeOptions]="[8, 20,50]" showFirstLastButtons [pageSize]="15" #paginator></mat-paginator>   </div>
      <div class="col-4" ><span *ngIf="selectedCustomer != undefined">{{ selectedCustomer.CustomerNumber }} - {{ selectedCustomer.Name1 }} </span> </div>
      <div class="col-3"><span class="float-r"> <b> Gesamt: {{totalAmount() | number:'1.2-2':'de' }}</b> </span>  </div>
    </div>

    <div > </div>
  </div>

        <table class="alaska-table-container table-striped" mat-table [dataSource]="datasource" >

        <ng-container matColumnDef="OpenItemId" class="m-1">
          <th mat-header-cell *matHeaderCellDef> Belegnummer </th>
          <td mat-cell *matCellDef="let element" > {{element.OpenItemId}}  </td>
        </ng-container>

        <ng-container matColumnDef="OrderDate" class="m-1">
          <th mat-header-cell *matHeaderCellDef> Belegdatum </th>
          <td mat-cell *matCellDef="let element"> {{ element.OrderDate  | date: 'dd.MM.yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="DueDate" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Fälligkeit </th>
          <td mat-cell *matCellDef="let element"> {{ element.DueDate  | date: 'dd.MM.yyyy' }}  </td>
        </ng-container>

        <ng-container matColumnDef="DebitCredit" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Soll/Haben </th>
          <td mat-cell *matCellDef="let element" >
              <span *ngIf="element.DebitCredit == 1">Soll</span>
              <span *ngIf="element.DebitCredit == 2">Haben</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="Text" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Beschreibung </th>
          <td mat-cell *matCellDef="let element"> {{ element.Text }} </td>
        </ng-container>

        <ng-container matColumnDef="Amount" class="mar-r" >
          <th  mat-header-cell *matHeaderCellDef> Betrag (€) </th>
          <td mat-cell *matCellDef="let element"> {{ element.Amount |  number:'1.2-2':'de' }}</td>
        </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">Es wurden keine öffeneposten gefunden,  </td>
                  </tr>

                  </table>
                </div>




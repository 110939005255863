import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit} from '@angular/core';
import {iOrders, iCustomers, iTitles, iGenders, iRegistrationstypes, iPositions, iInputOptions, iUsers, iOrderStates, iRoles, iShoppingcartitems, iShoppingcarts, iA1OrderBody, iA1OrderHead, iProducts, iReferencenumbers, iTooltipDiscountDisplay} from '../../../models/AlaskaModels';
import { InputErrorStateMatcher} from '../../../models/InputErrorStateMatcher';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../../../Services/data.service';
import { DialogService } from '../../../Services/dialog.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ErrorStateMatcher} from '@angular/material/core';
import { SnackbarService } from 'src/app/Services/snackbar.service';

import { MatDialogRef } from '@angular/material/dialog';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";

import { Router } from "@angular/router";

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IdbService } from '../../../Services/idb.service';


const ORDERS_DATA: Array<iOrders> = [];
const SHOPINGCARTITEMS_DATA: Array<iShoppingcartitems> = [];


@Component({
  selector: 'app-adminOrders',
  templateUrl: './adminOrders.component.html',
  styleUrls: ['./adminOrders.component.scss']
})
export class AdminOrdersComponent implements OnInit {

  loggedInUser: iUsers;
  loggedInRoleIds: number[] = [];
  selectedOrder: iOrders;
  selectedCustomer: iCustomers;
  products:iProducts[] = [];
  item: string = 'Bestellungen ...';
  selectediShoppingcartitems: iShoppingcartitems;

  order: iOrders;
  orders: iOrders[] = [];

  datasource = new MatTableDataSource(ORDERS_DATA);
  editDatasource = new MatTableDataSource(SHOPINGCARTITEMS_DATA);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() editableCustomer: iCustomers;


  mode: string = 'list';
  formSubmited: boolean = false;
  ordersForm: FormGroup;
  tableColumns: string[] = ['OrdersId', 'Orderdate',  'ShoppingCartsId',  'PreparedBy',  'OrderedBy',  'OrderedFor',  'DeliveryAddress',  'InvoiceAddress',  'OrderStatesId', 'Published',  'Imported', 'OrderInfo', 'Comment', 'Deleted', 'TotalPrice', 'Selection', 'Actions'];
  displayedColumns: string[] = ['OrdersId', 'Orderdate','Deliverydate', 'OrderedFor', 'OrderStatesId', 'Published', 'Imported', 'OrderInfo', 'Comment', 'Deleted', 'TotalPrice', 'Selection', 'Actions','hiddenAdminOrderInput'];
  filterOptions: string[] = [ 'freigegeben', 'nicht-freigegeben', 'gelöscht', 'Angebote','Vorlagen', 'importiert', 'alle']

  displayedAdminOrderDetailColumns: string[] = ['AdminOrderDetail_ProductId','AdminOrderDetail_DescRow1','AdminOrderDetail_Unit','AdminOrderDetail_Quantity','AdminOrderDetail_Price', 'AdminOrderDetail_PosDiscount','AdminOrderDetail_TotalDiscount','AdminOrderDetail_TotalPrice','AdminOrderDetail_Info','AdminOrderDetail_Actions'] ;

  // displayedAdminOrderDetailColumns: string[] = ['AdminOrderDetail_ProductId','AdminOrderDetail_DescRow1','AdminOrderDetail_Unit','AdminOrderDetail_QuantityInput','AdminOrderDetail_Price', 'AdminOrderDetail_PosDiscount','AdminOrderDetail_TotalDiscount','AdminOrderDetail_TotalPrice','AdminOrderDetail_Info','AdminOrderDetail_Action'] ;

  editableCustomerId: number;
  inputOptions: iInputOptions;
  Titles: iTitles[] = [];
  Genders: iGenders[] = [];
  RegistrationsTypes: iRegistrationstypes[] = [];
  Users: iUsers[];
  Customers: iCustomers[] = [];
  Positions: iPositions[] = [];
  OrderStates: iOrderStates[];
  matcher = new InputErrorStateMatcher();
  searchTerm: string;
  columnsForm: FormControl;
  DataDisplayFilterRange: number = 1;
  selectedFilter:string = 'freigegeben';
  notImportedData: string = '1';
  deliveryDate:Date
  pageSize: number = 50;
pageView: string = '';
pipe = new DatePipe('de-DE');
isLoading:boolean = false;


  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private idbService: IdbService,
    private router: Router,
    private http: HttpClient,
    private matPaginatorIntl: MatPaginatorIntl

  ) {}

  ngOnInit() {

    this.columnsForm = new FormControl( this.displayedColumns);
    this.inputOptions = this.setInputOptions();
    this.loggedInRoleIds = this.dataService.getLoggedInUserRoleIds();
    this.selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));


    this.Titles = this.inputOptions['titles'];
    this.Genders = this.inputOptions['genders'];
    this.RegistrationsTypes = this.inputOptions['registrationstypes'];
    this.Positions = this.inputOptions['positions'];
    this.Customers = JSON.parse(localStorage.getItem('customers'));
    this.Users  = JSON.parse(localStorage.getItem('users'));
    this.OrderStates = this.inputOptions['orderstates'];

    this.createOrdersForm();

    this.dataService.deliveryDateSubject$.subscribe(
      deliveryDate => {
       this.deliveryDate = deliveryDate;
     }
   );

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;
      }, err => {
        console.log("error loggedin user: ", err);
      });

      this.dataService.selectedCustomerSubject$.subscribe(
        (selectedCustomer) => {
          this.selectedCustomer = selectedCustomer;

        },
        (err) => {
          console.log('error - selectedCustomer-subscription: ', err);
        }
      );
      this.dataService.activeProductsSubject$.subscribe(
        (products) => {
          this.products = products;
        },
        (err) => {
          console.log('error - selectedproduct-subscription: ', err);
        }
      );

      this.dataService.msgSubject$.subscribe(
        msg => {
          if(msg.Name.toLowerCase() == "editablecustomerid"){
              this.editableCustomerId = +msg.Message
          }

          if (msg.Name.toLowerCase() == 'applyproductfilter') {
            this.datasource.filterPredicate = (data: iOrders , filter: string) => (JSON.stringify(data).toLowerCase().indexOf(filter)) != -1;
            this.datasource.filter = msg.Message.trim().toLowerCase();
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
            setTimeout(() => {
              if (msg.Type.toLowerCase() == 'enter'   || msg.Type.toLowerCase() == 'numpadenter') {

              }
            }, 0);
          }

        }, err => {
            console.log("error adding order: ", err);
          }
          );


  }

    applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  onSelectColumns(event: {isUserInput: any;    source: { value: any; selected: any };  })
  {
   if (event.isUserInput) {
     if (event.source.selected === true) {
       this.displayedColumns.push(event.source.value);
     } else {
       this.displayedColumns = this.displayedColumns.filter(x => x !=  event.source.value)
     }
     if(!!this.displayedColumns.filter(x => x == 'Actions')){
       this.displayedColumns = this.displayedColumns.filter(x => x !=  'Actions');
       this.displayedColumns.push('Actions');
     }
   }
 }


  populateData(){

    this.dataService.getData('custom','getnestedorders').subscribe(
      data => {

let order: iOrders;
this.orders = [];


        for(const id in data)
        {
            if(data.hasOwnProperty(id))
            {
              order = data[id].Orders;
              order.ShoppingCarts = data[id].Orders.ShoppingCarts[0];
              order.Selection = order.Published;
              this.orders.push(order);
            }
        }

        this.datasource.data = this.orders;

        this.datasource.filterPredicate = (data: iOrders, filter: string) => (!!data.Imported == !!(+filter))
        this.datasource.filter = '0'
        this.datasource.sort = this.sort;
        this.datasource.paginator = this.paginator;
        this.onSelectFilter('alle');
        console.log(' this.orders4: ', this.orders);
      }, err => {
        console.log('error retriving orders: ', err);
      });

  }

  updateDataSource(orders: iOrders[]){
    this.datasource.data = orders;
    console.log("orders datasource1: ", this.datasource.data);
    if(this.datasource.data){
      console.log("orders datasource2: ", this.datasource.data);
      this.datasource.filter = this.notImportedData == '1'?'':'0';
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }

  }

  updateEditDataSource(orders: iOrders){
    this.selectedOrder = orders;

    this.editDatasource.data = orders.ShoppingCarts.Shoppingcartitems
    console.log("editDatasource: ", this.editDatasource.data);
    if(this.editDatasource.data){
      console.log("orders datasource2: ", this.editDatasource.data);
      this.datasource.filter = this.notImportedData == '1'?'':'0';
      this.editDatasource.sort = this.sort;
      this.editDatasource.paginator = this.paginator;

      { setTimeout(() => this.onFocusAdminOrderDetailRow(orders.ShoppingCarts.Shoppingcartitems[0],'AdminOrderDetail_QuantityInput','AdminOrderDetailRow', null,0), 0); }
    }

  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';

    this.populateData();
  }

  getLSCustomerById(customerId: number){
  let customer: iCustomers;
  customer = this.Customers? this.Customers.find(x => x.CustomersId == customerId): customer;
  console.log("order let customer: ", customer);
  return customer != undefined? '('+ customer.CustomersId +')'+ customer.Name1: customerId.toString();
  }
  getLSUserById(userId: number){
  let user: iUsers = this.Users.find(x => x.UsersId == userId);
  return user != undefined? '('+ user.UsersId +')'+ user.FirstName+ ' ' + user.LastName : userId.toString();
  }
  getLSOrderStateById(OrderStatesId: number):string{

    let OrderStates: string  = this.OrderStates.find(x => x.OrderStatesId  == OrderStatesId).Name;
    return OrderStates != undefined? OrderStates : OrderStatesId.toString();
  }

  createOrdersForm(order?: iOrders){
    this.ordersForm = this.formBuilder.group(
      {
        OrdersId:        		  [order != undefined && order.OrdersId != undefined? order.OrdersId : 0, []],
        ShoppingCartsId:      [order != undefined && order.ShoppingCartsId != undefined? order.ShoppingCartsId : 0, []],
        PreparedBy:        		[order != undefined && order.PreparedBy != undefined? order.PreparedBy : 0, []],
        OrderedBy:        		[order != undefined && order.OrderedBy != undefined? order.OrderedBy : 0, []],
        OrderedFor:        		[order != undefined && order.OrderedFor != undefined? order.OrderedFor : 0, []],
        DeliveryAddress:      [order != undefined && order.DeliveryAddress != undefined? order.DeliveryAddress : 0, []],
        InvoiceAddress:       [order != undefined && order.InvoiceAddress != undefined? order.InvoiceAddress : 0, []],
        OrderStatesId:        [order != undefined && order.OrderStatesId != undefined? order.OrderStatesId : 0, []],
        Comment:        		  [order != undefined && order.ShoppingCarts != undefined && order.ShoppingCarts.Comment != undefined? order.ShoppingCarts.Comment : '', []],
        OrderInfo:        		[order != undefined && order.ShoppingCarts != undefined && order.ShoppingCarts.OrderInfo != undefined? order.ShoppingCarts.OrderInfo : '', []]

      }
    );
  }


  onList(){
    this.mode = 'list';
    this.item = 'Bestellungen . . .';
    console.log("this mode: ", this.mode);
    this.populateData();

  }

  onDetail(){
    this.mode =  'detail';
    this.item = 'Bestellpositionen . . .';
  }


  onEdit(order: iOrders){
    this.mode = 'edit';
    this.order = order;

    this.item = 'Bestellung: '+ order.ShoppingCarts.CustomersName1 + ' ' + order.ShoppingCarts.CustomersName2 + ', (Lieferdatum:' + this.pipe.transform(order.ShoppingCarts.DeliveryDate,'dd.MM.yyyy')+')' ;
    this.setSelectedOrder(order);
    this.createOrdersForm(order);
  }
  onCreate(){
    this.mode = 'create';
    this.item = 'Neu Bestellung erstellen';
    this.reSetSelectedOrder()
    this.createOrdersForm();
  }

  getFormData(): iOrders{
    // let _order: iOrders;
    // _order.OrdersId =            +this.ordersForm.get('OrdersId').value;
    // _order.ShoppingCartsId =      +this.ordersForm.get('ShoppingCartsId').value;
    // _order.PreparedBy =            this.ordersForm.get('PreparedBy').value;
    // _order.OrderedBy =            this.ordersForm.get('OrderedBy').value;
    // _order.OrderedFor =           this.ordersForm.get('OrderedFor').value;
    // _order.DeliveryAddress =      this.ordersForm.get('DeliveryAddress').value;
    // _order.InvoiceAddress =     this.ordersForm.get('InvoiceAddress').value;
    // _order.OrderStatesId=       +this.ordersForm.get('OrderStatesId').value;
    // _order.ShoppingCarts.Comment =              this.ordersForm.get('Comment').value;
    // _order.ShoppingCarts.OrderInfo=            this.ordersForm.get('OrderInfo').value;
    let _order: iOrders =
    {
      OrdersId :            +this.ordersForm.get('OrdersId').value,
      ShoppingCartsId:      +this.ordersForm.get('ShoppingCartsId').value,
      PreparedBy:            this.ordersForm.get('PreparedBy').value,
      OrderedBy:             this.ordersForm.get('OrderedBy').value,
      OrderedFor:            this.ordersForm.get('OrderedFor').value,
      DeliveryAddress:      this.ordersForm.get('DeliveryAddress').value,
      InvoiceAddress :      this.ordersForm.get('InvoiceAddress').value,
      OrderStatesId:       +this.ordersForm.get('OrderStatesId').value

    }

    _order.ShoppingCarts =  this.selectedOrder.ShoppingCarts;
    _order.ShoppingCarts.Comment =  this.selectedOrder.ShoppingCarts.Comment
    _order.ShoppingCarts.OrderInfo= this.selectedOrder.ShoppingCarts.OrderInfo
    return _order;

  }

  setSelectedOrder(order: iOrders){
    if(order != undefined){
      this.selectedOrder = order;
      localStorage.setItem('selectedOrder', JSON.stringify(this.selectedOrder));
      this.updateEditDataSource(order);
    }
  }

  reSetSelectedOrder(){
      this.selectedOrder = undefined;
      localStorage.removeItem('selectedUser');
  }

  onReset(){
    this.mode = 'list';
  }


  isStaffUser(orderForm: FormGroup): boolean{
    if(!orderForm.get('RegistrationsTypeId')){ return false;}
    else{
      let staffOrders: number[]= [1,2,3,4]
      console.log("type: ", orderForm.get('RegistrationsTypeId').value);
      console.log("isstaff: ", !!staffOrders.find(x => x == +orderForm.get('RegistrationsTypeId').value));
      return !!(+orderForm.get('RegistrationsTypeId').value == 1);
    }
  }

  public setInputOptions(): iInputOptions{
    if(!!localStorage.getItem("inputOptions")){
      this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
      console.log("inputOptions0", this.inputOptions);
      return this.inputOptions;
    }
    else{
         this.dataService.getData("custom","getoptions").subscribe(
      data => {
        this.inputOptions = data;
        if(this.inputOptions){
          console.log("inputOptions1", this.inputOptions);
          localStorage.removeItem("inputOptions");
          localStorage.setItem("inputOptions", JSON.stringify(this.inputOptions));
          return this.inputOptions;
        }
        else{
          this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
          console.log("inputOptions2", this.inputOptions);
          return this.inputOptions;
        }
      },err =>{
        console.log("error get inputOptions in app-component", err);
      }
    );
    }

  }


    openInfoDialog(shoppingcartitems: iShoppingcartitems){
    this.dialogService.infoInputDialog(shoppingcartitems.PosInfo).afterClosed().subscribe(
      info => {
        console.log('info is ', info);
        if(info != undefined){
          shoppingcartitems.PosInfo = info;
        }

        console.log('iShoppingcartitems ', this.selectedOrder.ShoppingCarts.Shoppingcartitems);
      }, err => {
        console.log('error dialog ', err);
      }
    );
  }


  saveLsOrders(order: iOrders){
    let orders: iOrders[] = [];
    if(!!localStorage.getItem(this.selectedCustomer.CustomersId+'_orders')){
      orders = JSON.parse(localStorage.getItem(this.selectedCustomer.CustomersId+'_orders'));
      orders = [order, ...orders];
      localStorage.removeItem(this.selectedCustomer.CustomersId+'_orders');
    }else{
      orders = [order];
    }

    localStorage.setItem(this.selectedCustomer.CustomersId+'_orders', JSON.stringify(orders));

  }
  removeOrderedShopingCard(shoppingCart: iShoppingcarts){

          localStorage.removeItem(this.selectedCustomer.CustomersId+'_selectedShoppingCart');
          localStorage.removeItem(this.selectedCustomer.CustomersId+'_shoppingCartItemsArray');

          let shoppingCartArray: iShoppingcarts[] = JSON.parse(localStorage.getItem(this.selectedCustomer.CustomersId+'_shoppingCartArray'));
          if(shoppingCartArray && shoppingCartArray.length > 1){
            shoppingCartArray = shoppingCartArray.filter(x => x.Name != shoppingCart.Name);
            localStorage.setItem(this.selectedCustomer.CustomersId+'_shoppingCartArray', JSON.stringify(shoppingCartArray));
          }else{
            localStorage.removeItem(this.selectedCustomer.CustomersId+'_shoppingCartArray');
          }
          this.populateData();
  }

  createOrder(shoppingcart: iShoppingcarts): iOrders{

    if(!shoppingcart){
      console.log('no shoppingcart to order: ',shoppingcart);
      return;
    }
    // let order: iOrders;
    // order.OrdersId = 0;
    // order.ShoppingCartsId = shoppingcart.ShoppingcartsId;
    // order.PreparedBy = shoppingcart.UserId;
    // order.OrderedBy = shoppingcart.UserId;
    // order.OrderedFor = shoppingcart.CustomerId;
    // order.DeliveryAddress = +this.ordersForm.get('DeliveryAddress') ?  this.ordersForm.get('DeliveryAddress').value : 0;
    // order.InvoiceAddress = +this.ordersForm.get('InvoiceAddress') ?  this.ordersForm.get('InvoiceAddress').value : 0;
    // order.OrderStatesId = 0;
    // order.ShoppingCarts.Comment = this.ordersForm.get('Comment') ?  this.ordersForm.get('Comment').value : '';
    // order.ShoppingCarts.OrderInfo = this.ordersForm.get('OrderInfo') ?  this.ordersForm.get('OrderInfo').value : '';
    // order.DeliveryDate = this.ordersForm.get('DeliveryDate') && this.ordersForm.get('DeliveryDate').value != ''?  this.ordersForm.get('DeliveryDate').value : new Date(Date.now());
    // order.CreateDate = new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    // order.ModifyDate = new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    // order.ShoppingCarts = shoppingcart;

    // return order;

    let _order: iOrders = {
      OrdersId: 0,
      ShoppingCartsId: shoppingcart.ShoppingcartsId,
      PreparedBy: shoppingcart.UserId,
      OrderedBy: shoppingcart.UserId,
      OrderedFor: shoppingcart.CustomerId,
      DeliveryAddress: +this.ordersForm.get('DeliveryAddress') ?  this.ordersForm.get('DeliveryAddress').value : 0,
      InvoiceAddress: +this.ordersForm.get('InvoiceAddress') ?  this.ordersForm.get('InvoiceAddress').value : 0,
      OrderStatesId: 0,
      DeliveryDate: this.ordersForm.get('DeliveryDate') && this.ordersForm.get('DeliveryDate').value != ''?  this.ordersForm.get('DeliveryDate').value : new Date(Date.now()),
      CreateDate: new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
      ModifyDate: new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
      ShoppingCarts: shoppingcart
    }

    _order.ShoppingCarts =  this.selectedOrder.ShoppingCarts;
    _order.ShoppingCarts.Comment =  this.selectedOrder.ShoppingCarts.Comment
    _order.ShoppingCarts.OrderInfo= this.selectedOrder.ShoppingCarts.OrderInfo
  }

  onEditSave(order: iOrders){

    if(order != null){
      let formdata: iOrders = this.getFormData();
      order.OrderStatesId = formdata != undefined && formdata.OrderStatesId != undefined? +formdata.OrderStatesId : order.OrderStatesId ;
      order.ShoppingCarts.Comment = formdata != undefined && formdata.ShoppingCarts.Comment != undefined? formdata.ShoppingCarts.Comment : order.ShoppingCarts.Comment ;
      order.ShoppingCarts.OrderInfo = formdata != undefined && formdata.ShoppingCarts.OrderInfo != undefined? formdata.ShoppingCarts.OrderInfo : order.ShoppingCarts.OrderInfo ;

      this.dataService.PutData('custom',order,"putOrders").subscribe(
        data => {
          // this.order = data;
          if(data != undefined)
          {
            this.datasource.data = this.orders;
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
            this.mode = 'list';
            this.snackbarService.snackbarAction('Data update erfolgreich ','ok');
            this.onList();
          }
        }, err =>{
          this.snackbarService.snackbarAction('Data update fehlgeschlagen ','ok');
          this.mode = 'list';
        }
      );
    }

  }



  onUnDeleteOrder(order: iOrders){
    if(order != null){

      if(order.Canceled != 1){
        // this.snackbarService.snackbarAction('Der Auftrag Auftrag worde gelöscht, kann nicht freigegeben oder rückgängig gemacht werden ','ok');
        return
      }

      this.dialogService.conformDialog("Auftrag", "Möchten Sie den Auftrag wiederherstellen? ", "info", "Abrechen", "Ok").afterClosed().subscribe(
        data => {
          console.log(" delete data conformed ", data)
          if(data != 'ok'){ return; }
          order.Canceled = 0;
          this.dataService.PutData('custom',order,"putOrders").subscribe(
            data => {
              // this.order = data;
              if(data != undefined)
              {
                this.datasource.data = this.orders;
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
                this.mode = 'list';
                this.snackbarService.snackbarAction('Auftrag wurde erfolgreich wiederhergestellt ','ok');
              }
            }, err =>{
              this.snackbarService.snackbarAction('Auftrag wiederherstellung fehlgeschlagen ','ok');
              this.mode = 'list';
            }
          );
        });
    }
  }

  onDeleteOrder(order: iOrders){
    if(order != null){

      if(order.Canceled == 1){
        this.snackbarService.snackbarAction('Der Auftrag Auftrag worde gelöscht, kann nicht freigegeben oder rückgängig gemacht werden ','ok');
        return
      }

      this.dialogService.conformDialog("Auftrag", "Möchten Sie den Auftrag löschen? ", "info", "Abrechen", "Löschen").afterClosed().subscribe(
        data => {
          console.log(" delete data conformed ", data)
          if(data != 'ok'){ return; }
          order.Canceled = 1;
          this.dataService.PutData('custom',order,"putOrders").subscribe(
            data => {
              // this.order = data;
              if(data != undefined)
              {
                this.datasource.data = this.orders;
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
                this.mode = 'list';
                this.snackbarService.snackbarAction('Auftrag wurde erfolgreich storniert ','ok');
              }
            }, err =>{
              this.snackbarService.snackbarAction('Auftrag stornieren fehlgeschlagen ','ok');
              this.mode = 'list';
            }
          );
        });
    }
  }

  onDeletePosition(shoppingcartitem: iShoppingcartitems){
    if(shoppingcartitem != null){

      this.dialogService.conformDialog("Bestellen", "Möchten Sie den bestell position löschen? ", "info", "Abrechen", "Löschen").afterClosed().subscribe(
        data => {
          console.log(" delete data conformed ", data)
          if(data != 'ok'){ return; }

          this.dataService.RemoveData('custom',shoppingcartitem.ShoppingcartitemsId,'deleteShoppingcartitems').subscribe(
            data => {
              if(data != undefined && +data > 0)
              {
                this.selectedOrder.ShoppingCarts.Shoppingcartitems = this.selectedOrder.ShoppingCarts.Shoppingcartitems.filter(x => x.ShoppingcartitemsId != shoppingcartitem.ShoppingcartitemsId)
                this.datasource.data = this.orders;
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
                this.mode = 'list';
                this.snackbarService.snackbarAction('Auftragsposition erfolgreich gelöscht ','ok');

              }
            }, err =>{
              this.snackbarService.snackbarAction('Auftragsposition löschen fehlgeschlagen ','ok');
              this.mode = 'list';
            }
          );

        });
    }
  }

  onReactivatePosition(order: iOrders){
    if(order != null){

      this.dialogService.conformDialog("Aeins reimport", "Sie sind dabei den Auftragsimport wieder zu aktivieren? ", "info", "Abrechen", "OK").afterClosed().subscribe(
        data => {
          console.log(" Auftragsimport wieder zu aktivieren ", data)
          if(data != 'ok'){ return; }

          order.Imported = null;

          this.dataService.PutData('custom',order,"putOrders").subscribe(
            data => {
              if(data != undefined)
              {
                this.datasource.data = this.orders;
                this.datasource.sort = this.sort;
                this.datasource.paginator = this.paginator;
                this.mode = 'list';
                this.snackbarService.snackbarAction('Data update erfolgreich ','ok');
              }
            }, err =>{
              this.snackbarService.snackbarAction('Data update fehlgeschlagen ','ok');
              this.mode = 'list';
            }
          );

        });
    }
  }



onSendOdataOrdersToAeins(){
  let ordersToExport: iOrders[] = this.datasource.data.filter(x => x.Selection == 1 && x.Imported == null && x.Canceled != 1 && x.Published == 1 );

  ordersToExport.forEach(order => {
    // do not set order.Published = 1;
    order.Imported = null;

    this.dataService.getData('custom', 'pingodata').subscribe(
      odataPing => {
        console.log("odata ping result: ", odataPing);
        if(odataPing && odataPing != 200){
          this.isLoading = false;
          this.dialogService.okDialog("Error", "Odata ist nicht verfügbar, Im Moment kann Ihre Auftrag  mit AuftragsId: (" + order.OrdersId+") nicht importiert werden!!", "danger",'40%');
          return;
        }
        else{
          // ping is OK post the order
          this.dataService.PutData('custom',order,'importOrderToOdata').subscribe(
            resultOrder => {
              let postedOrder: iOrders = resultOrder;
              this.isLoading = false;
              console.log("post result: ", resultOrder);

              if(postedOrder == null || postedOrder.Imported == null){
                this.datasource.data = this.orders;
                this.mode = 'list';
                this.onList();
                this.dialogService.okDialog("Error", "Ihre Odata import AuftragsId: (" + order.OrdersId+") ist fehlgeschlagen!! " , "danger",'40%');
              }
              else{

                this.datasource.data = this.orders;
                this.mode = 'list';
                this.onList();
                this.snackbarService.snackbarAction("Ihre Odata import AuftragsId: (" + order.OrdersId+") wurde erfolgreich gespeichert.");
              }

            }, err => {
              this.isLoading = false;
              console.log('odata import error: ', err);
              this.dialogService.okDialog("Error", "Auftrag absenden fehlgeschlagen!!" , "danger",'40%');
            }
          );

        }
      }, err => {
        this.isLoading = false;
        console.log('odata import error: ', err);
        this.dialogService.okDialog("Error", "Auftrag absenden fehlgeschlagen!!" , "danger",'40%');
      }
    );

});

  // this.dataService.PostData('custom',ordersToExport,'postodataordersrange').subscribe(
  //   data => {
  //     console.log("postodataordersrange",data)
  //       if(+data == ordersToExport.length){
  //         this.snackbarService.snackbarAction('Auftragsimport data update war erfolgreich ','ok');

  //         this.onList();
  //       }else{
  //         this.snackbarService.snackbarAction('Mindestens eine Auftragsimport data update fehlgeschlagen ','ok');
  //       }

  //   }, err =>{
  //     this.snackbarService.snackbarAction('Auftragsimport über Odata fehlgeschlagen ','ok');
  //   }
  // );

}

  getCsvOrderdata(order: iOrders): string{

    let referenceNumberId = 'T-'+this.dataService.getDate(order.CreateDate.toString(),'yyyyMM')+'-000'+order.ImportReferenceNumber;

    let head: iA1OrderHead =
    {

    Code:               'K',
    ReferenceNumberId:  referenceNumberId,
    Index:              order.ShoppingCarts.UsersUserrole && order.ShoppingCarts.UsersUserrole.includes(5)?'K':'V',
    CustomerNumber:     order.ShoppingCarts.CustomersCustomerNumber,
    CreateDate:         this.dataService.getDate(order.CreateDate.toString(),'dd.MM.yyyy'),
    DeliveryDate:       this.dataService.getDate(order.DeliveryDate.toString(),'dd.MM.yyyy'),
    OrderInfo:          order.ShoppingCarts.OrderInfo != null ? order.ShoppingCarts.OrderInfo : '',
    data1: '',
    data2: ''

    };


  let csvHead = "'"+head.Code+"';'"+head.ReferenceNumberId+"';'"+head.Index+"';'"+head.CustomerNumber+"';'"+head.CreateDate+"';'"+head.DeliveryDate+"';'"+head.OrderInfo+"';'"+head.data1+"';'"+head.data2+"'\n";
  let csvBody = "";
  order.ShoppingCarts.Shoppingcartitems.forEach((element) => {
    element.Price = element.Price != undefined? element.Price: 0.00;
    element.PosDiscount = element.PosDiscount != undefined? element.PosDiscount : 0.00;

    let body: iA1OrderBody =
    {
      Code : 'P',                                                               //A
      ReferenceNumberId:    referenceNumberId.toString(),                       //B
      Index:                element.PosIndex,                                   //C
      ProductNumber:        element.Product.ProductNumber,                      //D
      Quantity:             +element.Quantity.toFixed(8),                       //E
      Price:                +element.Price.toFixed(8),                          //F
      DiscountedPrice:      +element.Price.toFixed(8),  //G
      // DiscountedPrice:      +(element.Price - element.Price * element.PosDiscount / 100).toFixed(8),  //G
      // DiscountedPrice:  +(element.Price - element.PosDiscount).toFixed(8),  //G
      PosDiscount:          +(+element.PosDiscount.toFixed(2)).toFixed(8),      //H
      // BulkDiscount:          +(+element.BulkDiscount.toFixed(2)).toFixed(8),      //H
      PosInfo:              element.PosInfo,                                    //I
      PriceType:            element.PriceType                                   //J

    }

      csvBody = csvBody + "'"+body.Code+"';'"+body.ReferenceNumberId.toString()+"';"+body.Index+";'"+body.ProductNumber+"';"+body.Quantity.toFixed(8)+";"+body.DiscountedPrice.toFixed(8)+";"+body.Price.toFixed(8)+";"+body.PosDiscount.toFixed(8)+";'"+body.PosInfo+"';'"+body.PriceType+"'\n";
  })

  return csvHead + csvBody;
}

onDisabledClick($event: any, order: iOrders):boolean{
  return false;

}

onSelectionChecked($event: any, order: iOrders){
  if ($event.checked == true && order != undefined) {
    order.Selection = 1;
  } else if($event.checked == false && order != undefined) {
    order.Selection = 0;
  }
}


onSelectFilter($event){
  if($event){
    if($event == "nicht-freigegeben"){
      this.selectedFilter = 'nicht-freigegeben';
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Published == 0 && data.Canceled == 0 && data.ShoppingCarts.ShoppingCartStateId != 7 && data.ShoppingCarts.ShoppingCartStateId != 8}

    }
    if($event == "freigegeben"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Published == 1 && data.Canceled == 0 && data.Imported == null && data.Exported == null && data.ShoppingCarts.ShoppingCartStateId != 7 && data.ShoppingCarts.ShoppingCartStateId != 8}
      this.selectedFilter = 'freigegeben';
    }
    if($event == "gelöscht"){

      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Canceled == 1 }
      this.selectedFilter = 'gelöscht';
    }
    if($event == "Angebote"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.ShoppingCarts.ShoppingCartStateId == 7}
      this.selectedFilter = 'Angebote';
    }
    if($event == "Vorlagen"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.ShoppingCarts.ShoppingCartStateId == 8}
      this.selectedFilter = 'Vorlagen';
    }
    if($event == "importiert"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.Imported != null && data.Canceled == 0 && data.OrdersId != 0}
      this.selectedFilter = 'alle';
    }
    if($event == "alle"){
      this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iOrders, filter: string) => {return data.OrdersId != 0 && data.Canceled == 0}
      this.selectedFilter = 'alle';
    }

    this.datasource.filter = '1';
    this.datasource.data = this.orders;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;


    this.mode = 'list';
    setTimeout(() => { this.focusFirstAdminOrderRow('hiddenAdminOrderInput','AdminOrderRow', 0);  }, 0);
  }

 }


focustedOrderInputId: string = '';
focusedOrderRowId: string = '';
onFocusOrderRow(item: iOrders, selfPrefix:string, nextPrefix?:string, $event?: any ) {
  var resetFocusedRow = document.getElementById(this.focusedOrderRowId);
  if(resetFocusedRow){resetFocusedRow.style.backgroundColor = '';}
  if( selfPrefix == 'OrderRow'){ }
  let currentIndex = this.datasource.filteredData.findIndex(row => row === item);
  this.focustedOrderInputId = 'orderKeyUp-' + item.OrdersId.toString();
    this.focusedOrderRowId = 'Order_row-' + item.OrdersId.toString();
    var row = document.getElementById(this.focusedOrderRowId);
    var orderKeyUpInput = document.getElementById(this.focustedOrderInputId);
    if(orderKeyUpInput){ setTimeout(() => orderKeyUpInput.focus(), 0); }
    if(row){setTimeout(() => row.style.backgroundColor = '#ccffcc', 0);}

  }


getItemsCount(order: iOrders):number{
  let count = 0;
  count = order? order.ShoppingCarts.Shoppingcartitems.length: 0;
  return count;
}

onAdminOrderKeyUp(item: iOrders, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number) {
  if( $event && $event.keyCode == 27 || $event.keyCode == 9 || $event.keyCode == 13 || $event.keyCode == 37 || $event.keyCode == 38 || $event.keyCode == 39 || $event.keyCode == 40 )
  {
      this.onAdminOrderKeyUpEvent(item, selfPrefix, nextPrefix, $event,  rowIndex);
  }
}

onAdminOrderKeyUpEvent(item?: iOrders, selfPrefix?:string, nextPrefix?:string, $event?: any,  rowIndex?: number ){

  let code = $event != undefined? $event.code: undefined;

  if(code && code == 'Escape'){
    this.onList();
    return
  }else if(code && code == 'Enter'  || code == 'NumpadEnter'){
    this.onEdit(item);
    rowIndex = this.datasource.filteredData.length -1 > rowIndex + 1? rowIndex + 1:rowIndex ;
    // { setTimeout(() => this.onFocusAdminOrderDetailRow(item.ShoppingCarts.Shoppingcartitems[0],'AdminOrderDetail_QuantityInput','AdminOrderDetailRow', null,0), 0); }

    setTimeout( () => this.FocusAdminOrderInfoInput('AdminOrderInfoInput'), 0 );


  }else if(code && code == 'ArrowDown'){

    this.goToNextInput('hiddenAdminOrderInput', rowIndex, 1);
    this.goToNextRow('AdminOrderRow', rowIndex, 1);
  }
  else if(code && code == 'ArrowUp'){
    this.goToNextInput('hiddenAdminOrderInput', rowIndex, -1);
    this.goToNextRow('AdminOrderRow', rowIndex, -1);
  }
  else if (code && code == 'ArrowLeft') {
    setTimeout( () => this.FocusAdminOrderInfoInput('AdminOrderInfoInput'), 0 );
  } else if(code && code == 'ArrowRight') {
    setTimeout( () => this.FocusAdminOrderInfoInput('AdminOrderCommentInput'), 0 );
  }


}

goToNextInput(prefix?: string, rowIndex?:number, nexOrPrevious?: number){
  let inputId = prefix+'-' + rowIndex.toString();
  let inputNextId = prefix+'-' + (rowIndex + nexOrPrevious).toString();


  let currentInput = document.getElementById(inputId);
  let nextInput = document.getElementById(inputNextId );

  if (nextInput) {
    setTimeout(() => nextInput.focus(), 0);
  }
}

goToNextRow(prefix?: string, rowIndex?:number, nexOrPrevious?: number){

  let rowId = prefix+'-' + rowIndex.toString();
  let rowNextId = prefix+'-' + (rowIndex + nexOrPrevious).toString();
  let currentRow = document.getElementById(rowId);
  let nextRow = document.getElementById(rowNextId);

  if (nextRow) {
    setTimeout(() => (nextRow.style.backgroundColor = '#ccffcc'), 0);
    if(currentRow){  setTimeout(() => (currentRow.style.backgroundColor = ''), 0);  }
  }
}


focusFirstAdminOrderRow(inputPrefix?: string,rowPrefix?: string, rowIndex?:number){
  let inputId = inputPrefix+'-' + rowIndex.toString();
  let rowId = rowPrefix+'-' + rowIndex.toString();
  let input = document.getElementById(inputId);
  let row = document.getElementById(rowId);

  if (input) {
    setTimeout(() => (input.focus()), 0);
  }
  if (row) {
    setTimeout(() => (row.style.backgroundColor = '#ccffcc'), 0);
  }
}

onPreventStep(item: iOrders, $event?: any ){
  if($event.keyCode == 38 || $event.keyCode == 40){
    $event.preventDefault();
    return;  }
  }

  onFocusOutPricePosDiscountQuantityInput(shoppingcartitem: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number): iShoppingcartitems {
    shoppingcartitem.Quantity = +shoppingcartitem.Quantity.toFixed(2);
    shoppingcartitem.PosDiscount = +shoppingcartitem.PosDiscount.toFixed(2);
    shoppingcartitem.Price = +shoppingcartitem.Price.toFixed(2);
    shoppingcartitem.Product.QuantityInput = +shoppingcartitem.Quantity.toFixed(2);
    shoppingcartitem =  this.dataService.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix+'-');
    return shoppingcartitem;

  }

  onAdminOrderDetailKeyUp2(item: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number) {
         this.dataService.onKeyCombination($event, item.Product);
    if( $event && $event.keyCode == 27 || $event.keyCode == 9 || $event.keyCode == 13 || $event.keyCode == 37 || $event.keyCode == 38 || $event.keyCode == 39 || $event.keyCode == 40 )
    {
        this.onAdminOrderDetailKeyUpEvent(item, selfPrefix, nextPrefix, $event, rowIndex);

    }
  }
  onAdminOrderDetailKeyUp(item: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number) {
    // let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90,83,89];
    let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 73, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90, 83,    71, 81, 85, 82];
    if( $event && keyCodes.includes($event.keyCode) )
    {
      this.onAdminOrderDetailKeyUpEvent(item, selfPrefix, nextPrefix, $event, rowIndex);
        // this.onInputEdit(item, selfPrefix, nextPrefix, $event, rowIndex);
        this.dataService.onKeyCombination($event, item?item.Product:null );
    }
  }

  onAdminOrderDetailKeyUpEvent(item?: iShoppingcartitems, selfPrefix?:string, nextPrefix?:string, $event?: any, rowIndex?: number ){

    let code = $event != undefined? $event.code: undefined;

    if( code && code == 'Escape'){
      this.mode=='list';
      this.onList();

      return
    }else if(code && code == 'Enter'  || code == 'NumpadEnter'){
      item = this.onFocusOutPricePosDiscountQuantityInput(item, selfPrefix+'-', '', $event, rowIndex);
      if(item){
       this.onEditSave(this.selectedOrder)
      }

      this.mode=='list';

      return
    }
    else if (code && code == 'ArrowLeft') {
      setTimeout( () => this.FocusAdminOrderInfoInput('AdminOrderInfoInput'), 0 );
      // nextPrefix = selfPrefix == 'AdminOrderDetail_PosDiscount'?'AdminOrderDetail_Price':(selfPrefix == 'AdminOrderDetail_Price'? 'AdminOrderDetail_QuantityInput':'');
      // item = this.onFocusOutPricePosDiscountQuantityInput(item, selfPrefix+'-', '', $event, rowIndex);
      // this.goToNextInput(nextPrefix, rowIndex, 0);
    } else if(code && code == 'ArrowRight') {
      setTimeout( () => this.FocusAdminOrderInfoInput('AdminOrderCommentInput'), 0 );
      // nextPrefix = selfPrefix == 'AdminOrderDetail_QuantityInput'?'AdminOrderDetail_Price':(selfPrefix == 'AdminOrderDetail_Price'? 'AdminOrderDetail_PosDiscount':'');
      // item = this.onFocusOutPricePosDiscountQuantityInput(item, selfPrefix+'-', '', $event, rowIndex);
      // this.goToNextInput(nextPrefix, rowIndex, 0);
    }
    else if(code == 'ArrowDown'){

      // item = this.onFocusOutPricePosDiscountQuantityInput(item, selfPrefix+'-', '', $event, rowIndex);
      // this.goToNextInput(selfPrefix, rowIndex, 1);
      // this.goToNextRow('OrderDetail_row', rowIndex, 1);
    }
    else if(code == 'ArrowUp'){

      // item = this.onFocusOutPricePosDiscountQuantityInput(item, selfPrefix+'-', '', $event, rowIndex);
      // this.goToNextInput(selfPrefix, rowIndex, -1);
      // this.goToNextRow('OrderDetail_row', rowIndex, -1);
    }

}


onFocusAdminOrderDetailRow(item: iShoppingcartitems, inputfPrefix:string, rowPrefix?:string, $event?: any,index?:number ) {
  var input = document.getElementById(inputfPrefix+'-'+index);
  var row = document.getElementById(rowPrefix+'-'+index);
  if(input){ setTimeout(() => input.focus(), 0); }
  if(row){setTimeout(() => row.style.backgroundColor = '#ccffcc', 0);}

}

onFocusOutPrice(shoppingcartitem: iShoppingcartitems, $event:any){

  let offeredPrice =  shoppingcartitem.Price - (shoppingcartitem.Price * shoppingcartitem.PosDiscount / 100);
  let minSellPrice = shoppingcartitem.Product.AveragePurchasePrice * 1.17;

  if(offeredPrice < minSellPrice){
    this.snackbarService.snackbarAction("Mindest-VP liegt 17% über mEK!");
    shoppingcartitem.Price = 0;
  }
  return shoppingcartitem.Price.toFixed(2);
}
getBg(menge: number, type:string):string{
  if(type == 'OrderDetail_DispositionStockValue' || type == 'OrderDetail_StockValue'){
    return  menge < 5? 'bg-yelow':'bg-green'
  }
  if(type == 'OrderDetail_QuantityInput'){
    return  menge > 0? 'bg-green':''
  }

}

sortOrder: string ='asc'
 changeSort(column:string){


    if(column == 'OrdersId'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.OrdersId < b.OrdersId ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.OrdersId > b.OrdersId ? -1 : 1) ;}

    else if(column == 'ShoppingCartsId'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.ShoppingCartsId < b.ShoppingCartsId ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.ShoppingCartsId > b.ShoppingCartsId ? -1 : 1) ;}

    else if(column == 'CreateDate'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.CreateDate < b.CreateDate ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.CreateDate > b.CreateDate ? -1 : 1) ;}

    else if(column == 'DeliveryDate'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.DeliveryDate < b.DeliveryDate ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.DeliveryDate > b.DeliveryDate ? -1 : 1) ;}

    else if(column == 'PreparedBy'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.PreparedBy < b.PreparedBy ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.PreparedBy > b.PreparedBy ? -1 : 1) ;}

    else if(column == 'UsersFirstName'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.ShoppingCarts.UsersFirstName < b.ShoppingCarts.UsersFirstName ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.ShoppingCarts.UsersFirstName > b.ShoppingCarts.UsersFirstName ? -1 : 1) ;}

    else if(column == 'CustomerNumber'){ this.datasource.data = this.sortOrder == 'asc'? this.datasource.data.sort((a, b) =>   a.ShoppingCarts.CustomersCustomerNumber < b.ShoppingCarts.CustomersCustomerNumber ? -1 : 1) : this.datasource.data.sort((a, b) =>   a.ShoppingCarts.CustomersCustomerNumber > b.ShoppingCarts.CustomersCustomerNumber ? -1 : 1) ;}


  this.sortOrder = this.sortOrder == 'asc' ? 'desc' : 'asc';
 }

onPageSizeChange($event){
  this.paginator.pageSize = +this.pageSize;
  this.paginator.pageIndex = 0;
  this.paginator._changePageSize(+this.pageSize);
  this.pgnFirstPage();
}

getPageVisits():string{
  let visits = '';
  if(this.mode!='edit'){
    try{
      let nextVisit =  this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize < this.paginator.length? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize : this.paginator.length : '';
      visits = this.paginator && nextVisit != 0 && +this.paginator.length != 0?  ((this.paginator.pageIndex * this.pageSize)+1).toString() +'-'+ nextVisit.toString()+'/'+this.paginator.length : '';
    }
    catch{  } // do nothing
  }else{
    visits = '';
  }

  return  visits
}
  pgnFirstPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.firstPage();
  }
  pgnBackPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.previousPage();
  }
  pgnNextPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.nextPage();
  }
  pgnLastPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.lastPage();
  }

  totalRowDiscount(shoppingcartitem: iShoppingcartitems): number{
    return shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.PosDiscount / 100;
  }

  totalOrderPrice(order: iOrders): number{
    let total: number = 0;
    let sumRowProPrice = 0;
    let sumRowProDiscount = 0;
    let shopingCartItems: iShoppingcartitems[] = order.ShoppingCarts.Shoppingcartitems;
    if(shopingCartItems){
      shopingCartItems.forEach(shopingCartItem => {
        sumRowProPrice += this.totalRowPrice(shopingCartItem);
        sumRowProDiscount += this.totalRowDiscount(shopingCartItem);
    });
    }

    return sumRowProPrice - sumRowProDiscount;
  }

  totalRowPrice(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowPrice(shoppingcartitem);
  }

  totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowSurcharge(shoppingcartitem);

  }
  positionRowPartialPackageCharge(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.positionRowPartialPackageCharge(shoppingcartitem);
  }

  getTooltipDiscount(shoppingcartitem: iShoppingcartitems): iTooltipDiscountDisplay{
    return  this.dataService.getTooltipDiscount(shoppingcartitem);
  }

  totalRowNetDiscount(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowNetDiscount(shoppingcartitem);
  }

  onEditAsShoppingCart(order: iOrders){
    if(order == undefined){
      return;
    }
    let shoppingcart2Load: iShoppingcarts = order? order.ShoppingCarts : null;
    this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
    let item: string = ''

    if(order.ShoppingCarts.ShoppingCartStateId == 7){ item = 'Angebot'}else if(order.ShoppingCarts.ShoppingCartStateId == 8){ item = 'Vorlage'} else{ item = 'Auftrag'}

      if(!shoppingcart2Load){
        this.dialogService.okDialog("Error", "bitte WK auswählen. " , "danger",'40%');
        return;
      }else if(!this.selectedCustomer){
        this.dialogService.okDialog("Error", "bitte ein Kunde auswählen. ", "danger",'40%');
        return;
      }else if(shoppingcart2Load){

            this.dialogService.conformDialog(  'Warenkorb',  ' Der evtl. gerade von Ihnen in Arbeit befindliche WK geht bei dieser Aktion verloren. Gespeicherten '+item+' trotzdem laden und aus Coolkauf-Datenbank löschen?',  'info',  'abrechen',  'OK'  ).afterClosed().subscribe(
              (data) => {
              if (data != 'ok') {
                return;
              }
              else{


                if(this.selectedCustomer && shoppingcart2Load && this.deliveryDate){

                    // shoppingcart2Load.ShoppingcartsId = +Date.now();
                    shoppingcart2Load.Customer = this.selectedCustomer;
                    shoppingcart2Load.CustomerId = this.selectedCustomer.CustomersId;
                    shoppingcart2Load.CustomersCustomerNumber = this.selectedCustomer.CustomerNumber;
                    shoppingcart2Load.CustomersName1 = this.selectedCustomer.Name1;
                    shoppingcart2Load.CustomersName2 = this.selectedCustomer.Name2;
                    shoppingcart2Load.ShoppingCartStateId = 0;
                    shoppingcart2Load.Name = this.selectedCustomer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
                    shoppingcart2Load.DeliveryDate = this.deliveryDate;
                    shoppingcart2Load.UserId = this.loggedInUser? this.loggedInUser.UsersId : 0;
                    shoppingcart2Load.UsersUserrole = this.dataService.getLoggedInUserRoleIds();

                    shoppingcart2Load.Shoppingcartitems.forEach(Shoppingcartitem => {
                      // Shoppingcartitem.ShoppingcartitemsId =  +Date.now() + Shoppingcartitem.PosIndex
                      Shoppingcartitem.Product.QuantityInput = Shoppingcartitem.Quantity;
                    });
                  this.idbService.copyAndCreateCustomerShoppingCartFromTemplate(shoppingcart2Load);

                  // delete order
                  order.Canceled = 1;

                  this.dataService.PutData('custom',order,"putOrders").subscribe(
                    data => {
                      // this.order = data;
                      this.isLoading = false;
                      if(data != undefined)
                      {
                        this.datasource.data = this.orders;
                        this.mode = 'list';
                        this.snackbarService.snackbarAction(item + ' update erfolgreich ','ok');
                        this.onList();
                        setTimeout(() => { this.onFocusOrderRow(order, ''); }, 0);
                      }
                    }, err =>{
                      this.isLoading = false;
                      this.snackbarService.snackbarAction(item + ' update fehlgeschlagen ','ok');
                      this.mode = 'list';
                    }
                  );
              }
            }
          });

      }

    }

    FocusAdminOrderInfoInput(id:string){
      var input = document.getElementById(id);
      if(input){ setTimeout(() => input.focus(), 0); }
    }


}





import { Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from '../Services/data.service';
import { iCustomers } from '../models/AlaskaModels';
import {   DatePipe } from "@angular/common";

@Component({
  selector: 'app-deliveryDate',
  templateUrl: './deliveryDate.component.html',
  styleUrls: ['./deliveryDate.component.scss']
})
export class DeliveryDateComponent implements OnInit {

  formatedDate:string;
  deliveryDate: Date;
  localstorageDate: Date;

  DeliveryDateForm:FormControl;
  minDate: Date = new Date(Date.now() );
  selectedCustomer: iCustomers;
  pipe = new DatePipe('de-DE');
  @Input() source:string ;


  constructor(
    private dataService: DataService,

    ) { }

    ngOnInit() {

      this.deliveryDate  = new Date(localStorage.getItem('deliveryDate'));
      this.setDeliveryDateForm(this.deliveryDate );

    }

  setDeliveryDateForm(deliveryDate: Date){
    this.DeliveryDateForm = new FormControl(this.deliveryDate );
    this.localstorageDate = new Date(localStorage.getItem('deliveryDate'));
    this.formatedDate = this.localstorageDate?  this.dataService.getDate(this.localstorageDate  , 'dd.MM.yyyy') : this.dataService.getDate(this.deliveryDate  , 'dd.MM.yyyy');
    this.DeliveryDateForm.reset();
    this.DeliveryDateForm.reset(this.deliveryDate);
  }

  onDateSelect($event: any){

    this.localstorageDate = new Date(localStorage.getItem('deliveryDate'));
    this.formatedDate = this.localstorageDate?  this.dataService.getDate(this.localstorageDate  , 'dd.MM.yyyy') : this.dataService.getDate(this.deliveryDate  , 'dd.MM.yyyy');
    let source = this.source;
    console.log(this.source);

    if(this.source.toLowerCase() == 'warenkorb'){
      this.dataService.setMsgSourceSubject( 'deliverydatechanged',  '',  this.source,  'shoppingcart',  '', 'deliverydatechanged',  new Date($event),  +Date.now() );
    }
    else{
      this.dataService.setDeliveryDateSubject(new Date($event));
    }


  }

}

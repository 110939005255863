import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../Services/data.service';
import { SnackbarService } from '../Services/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import { iCustomers, iProductcategories, iProducts, iPublicationTargets, iSupplierproductorders, iUpdatereports } from 'src/app/models/AlaskaModels';
import { FormControl } from '@angular/forms';


const ELEMENT_DATA: Array<iSupplierproductorders> = [];
@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss']
})
export class DeliveriesComponent implements OnInit {

  selectedProductCategory: iProductcategories;
  selectedCustomer: iCustomers;
   showSpinner: boolean = false;
  datasource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  displayedColumns: string[] = ['SuppliersId','OrderDate', 'DeliveryDate',  'SupplierName', 'ProductNumber','DescRow1', 'AveragePurchasePrice',  'Quantity',  'ShortDesc'];

  constructor(private dataservice: DataService,
              private snackbarService: SnackbarService,
              private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.populate();


    this.dataservice.msgSubject$.subscribe((msg) => {


    if (msg.Name.toLowerCase() == 'applyproductfilter') {
      let filterArray = msg.Message
        .trim()
        .toLowerCase()
        .split(/(\s+)/)
        .filter((e) => e.trim().length > 0);
      let filter0 =    filterArray && filterArray[0] != undefined ? filterArray[0] : '';
      let filter1 =    filterArray && filterArray[1] != undefined ? filterArray[1] : '';
      let filter2 =    filterArray && filterArray[2] != undefined ? filterArray[2] : '';

      this.datasource.filter = msg.Message.trim().toLowerCase();
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
      this.datasource.filterPredicate = (data: iProducts, filter: string) => {

        if (filter0 != '' && filter1 == '' && filter2 == '') {
          if(this.selectedProductCategory != undefined){
            return JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1 && data.ProductcategoriesId == this.selectedProductCategory.ProductcategoriesId;
          }else{
            return JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1
          }
        }
        if (filter0 != '' && filter1 != '' && filter2 == '') {
          if(this.selectedProductCategory != undefined){
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description ).toLowerCase().indexOf(filter0) != -1 &&
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1 &&  data.ProductcategoriesId == this.selectedProductCategory.ProductcategoriesId
          }else{

          }
          return (
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description ).toLowerCase().indexOf(filter0) != -1 &&
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1
          );
        }
        if (filter0 != '' && filter1 != '' && filter2 != '') {
          if(this.selectedProductCategory != undefined){
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1 &&
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1 &&
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter2) != -1 &&  data.ProductcategoriesId == this.selectedProductCategory.ProductcategoriesId
          }else{

          }
          return (
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1 &&
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1 &&
            JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter2) != -1
          );
        }

    }


    }

    });

  }
  focusFirstRow(products: iProducts[]) {
    if (products != undefined && products.length > 0) {

    }
  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Zeilen:';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';


  }



  populate(){
    this.dataservice.getData('custom','getSupplierproductorders').subscribe(
      data =>{

          this.datasource.data = data;
          this.datasource.sort = this.sort;
          this.datasource.paginator = this.paginator;
      }, err => {
            console.log('Error-getUpdateReport', err);
        }
    );
  }










}



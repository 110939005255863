

  <table>
    <tr>
      <td>
        <div ><mat-paginator [length]="itemLength"  [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons  ></mat-paginator></div>
      </td>
      <td>
        <mat-form-field appearance="outline" >
          <mat-label>PDF speichern/druck optionen</mat-label>
           <mat-select [formControl]="shoppingcartControl" disableRipple [(ngModel)]="optionValue" (selectionChange)="onSelectionChange($event['value']['Value'])">
             <!-- <mat-option value="withpicture">warenkorb mit bilder</mat-option>
             <mat-option  value="withoutpicture">warenkorb ohne bilder</mat-option> -->

             <mat-option *ngFor="let schoppingcard of shoppingcardOptions" [value]="schoppingcard">
              {{schoppingcard.Key}}
            </mat-option>

           </mat-select>
         </mat-form-field>
      </td>
      <td>
        <button   class="btn m-2" [useExistingCss]="true"   printSectionId="orderContainer" ngxPrint

        > <i class="material-icons btn-outline-primary md-48" matTooltip="Mit Preisen und Bildern ">print</i></button>

        <button (click)="onCancel()" class="btn m-2"> <i class="material-icons btn-outline-danger md-48 " matTooltip="abrechen">cancel</i> </button>
      </td>
    </tr>
    <tr>
      <td>

      </td>
      <td>

      </td>
    </tr>
  </table>

  <div id="orderContainer">

    <div class="row" style="margin:10px 20px 10px 20px;">
      <div class="col-12" >



      <!-- <table class="" style="width:100%; height:0; font-family: Roboto, 'Helvetica Neue', sans-serif; " >
        <tr style="height:0; padding: 5px;">
          <td style="width:50%;">
            <span class=""><a target="_blank" href="https://alaska-tkk.de">  <img src="../assets/icons/logo_alaska.jpg" data-holder-rendered="true" width="200" />  </a></span>
            <p></p><p></p>

            <div class="floatl" *ngIf="selectedCustomer != undefined" style="float:left; text-align:left">
              <table class="customertbl"  >
                <tr><td class="">             </td><td class="wrap ">{{ selectedCustomer.Name1 }}</td></tr>
                <tr><td class="">             </td><td class="wrap">{{ selectedCustomer.Name2 }}</td></tr>
                <tr><td class="">             </td><td class="wrap ">{{selectedCustomer.Street}}</td></tr>
                <tr><td class="">             </td><td class="wrap ">{{selectedCustomer.Zip}}, {{selectedCustomer.City}}</td></tr>
              </table>
            </div>
          </td>
          <td style="height:0; padding: 5px;">
            <div class="floatr" style="text-align:right; ">
              <br><br><p></p><p></p>
              <p class="txtr" style="height:0; padding: 5px;">Kauritzerstr. 29, 04639 Gößnitz</p>
              <p class="txtr" style="height:0; padding: 5px;">Tel: 034493/22588</p>
              <p class="txtr" style="height:0; padding: 5px;">Fax: 034493/36532</p>
            </div>
          </td>
        </tr>


        <tr >
          <td>
            <div class="floatl" style="float:left; text-align:left">
              <p >   </p>
            </div>
          </td>
          <td>
            <div class="floatr" style="float:right; text-align:right">
              <p class="txtr" *ngIf=" date != undefined" style="height:0; padding: 5px;"> Datum: {{date}} </p>
              <p class="txtr" *ngIf=" optionValue != '' && deliveryDate != undefined" style="height:0; padding: 5px;"> Lieferdatum: {{deliveryDate}} </p>
              <p class="txtr" *ngIf=" optionValue != ''  && totalPriceSum() != undefined" style="height:0; padding: 5px;"><b>Gesamtbetrag: {{totalPriceSum()}} € </b> </p>
            </div>
          </td>
        </tr>

      </table> -->


        <!-- <div class="col-12 "  *ngIf="title != undefined && optionValue != ''"> <p>{{title}} ( KundenNr.: {{ selectedCustomer.CustomerNumber }} ) </p> </div>
        <p class="floatl " *ngIf=" selectedCustomer == undefined  "> <span class="text-danger"> bitte wählen Sie ein Kunde aus!</span></p>
        <p class="floatl " *ngIf="  optionValue == '' && selectedCustomer != undefined "> <span class="text-danger"> bitte wählen Sie ein druckoption aus!</span></p> -->

 <!-- ################################### -->

 <table style="width: 100%;  margin: 10px;">
  <tbody>
    <tr > <td class="alignLeft"><span class=""><a target="_blank" href="https://alaska-tkk.de">  <img src="../assets/icons/logo_alaska.jpg" data-holder-rendered="true" width="200" />  </a></span></td> <td ></td> <td ></td>  </tr>

    <tr >
      <td class="alignLeft">
        <p *ngIf="selectedCustomer != undefined">{{ selectedCustomer.Name1 }}</p>
        <p *ngIf="selectedCustomer != undefined">{{ selectedCustomer.Name2 }}</p>
        <p *ngIf="selectedCustomer != undefined">{{selectedCustomer.Street}}</p>
        <p *ngIf="selectedCustomer != undefined">{{selectedCustomer.Zip}}, {{selectedCustomer.City}}</p>
      </td>
      <td class="alignRight" colspan="2">
        <p>Kauritzerstr. 29, 04639 G&ouml;&szlig;nitz</p>
        <p>Tel: 034493/22588</p>
        <p>Fax: 034493/36532</p>
        <p *ngIf=" date != undefined" > Datum: {{date}} </p>
        <p *ngIf=" optionValue != '' && deliveryDate != undefined" > Lieferdatum: {{deliveryDate}} </p>
        <p><strong> <span  *ngIf=" optionValue != ''  && totalPriceSum() != undefined" ><b>Gesamtbetrag: {{totalPriceSum()}} € </b> </span></strong></p>
      </td>
    </tr>
    <tr>
      <td class="alignLeft" colspan="3">
        <p  *ngIf="title != undefined && optionValue != ''" > {{title}} ( KundenNr.: {{ selectedCustomer.CustomerNumber }} )  </p>
        <p  *ngIf=" selectedCustomer == undefined  " class="text-danger">bitte wählen Sie ein Kunde aus!</p>
        <p  *ngIf="  optionValue == '' && selectedCustomer != undefined "class="text-danger"> bitte wählen Sie ein druckoption aus!</p>
      </td>
    </tr>
  </tbody>
</table>

<!-- ######################################### -->

        <div class="col-12" *ngIf="  optionValue != '' && selectedCustomer != undefined  ">

          <table mat-table class="table-striped mat-elevation-z8"   [dataSource]=datasource matSort   matSortDirection="asc"   >

            <ng-container matColumnDef="Artikel" class="m-1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> A-Nr </th>
              <td mat-cell *matCellDef="let element"> {{element.Product.ProductNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="DescRow1" class="m-1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Bezeichnung1 </th>
              <td mat-cell *matCellDef="let element"> {{element.Product.DescRow1}} </td>
            </ng-container>

            <ng-container matColumnDef="DescRow2" class="m-1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header > Bezeichnung2 </th>
              <td mat-cell *matCellDef="let element"> {{element.Product.DescRow2}} </td>
            </ng-container>

            <ng-container matColumnDef="Stock" class="m-1">
              <th  mat-header-cell *matHeaderCellDef > Verfügbarkeit </th>
              <td mat-cell *matCellDef="let element">

                <table class="stockTable">
                  <tr *ngIf="hasRoles([1,2,3,4]) == true"><td [ngClass]="getBg(element.Product.StockValue,'StockValue')"> Ist: {{ element.Product.StockValue | number:'1.2-2':'de' }} </td></tr>
                  <tr *ngIf="hasRoles([1,2,3,4]) == true"><td [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')"> Dispo: {{ element.Product.DispositionStockValue | number:'1.2-2':'de' }} </td></tr>

                  <tr *ngIf="hasRoles([1,2,3,4]) == false"><td [ngClass]="getBg(element.Product.DispositionStockValue,'stockIconClass')" matTooltip="{{bigOrSmall(element.DispositionStockValue)}}"> {{getIcon(element.Product.DispositionStockValue, 'stockAvailabilityIcon') }} </td></tr>

                  </table>
              </td>
            </ng-container>

            <ng-container matColumnDef="Picture" class="mar-r" >
              <th class="txtRight"  mat-header-cell *matHeaderCellDef>  <span *ngIf=" optionValue == 'withpicture' ">Bild</span></th>
              <td class="txtRight" mat-cell *matCellDef="let element"> <span *ngIf=" optionValue == 'withpicture' "> <img class="prodImage " src="{{IMAGE_URL}}{{element.Product.ProductNumber}}.jpg" height="80px" id="img"></span>  </td>
            </ng-container>

            <ng-container matColumnDef="Price" class="mar-r">
              <th class="txtRight" mat-header-cell *matHeaderCellDef> Preis(€) </th>
              <td class="txtRight" mat-cell *matCellDef="let element">

                  <p *ngIf=" element.Product.PriceType == 'I' || element.Product.PriceType == 'S'" class="m-0 p-0" ><del>{{ element.Product.ListPrice  | number:'1.2-2':'de'}} </del></p>
                  <p *ngIf=" element.Product.PriceType == 'L' || element.PriceType == 'B' " class="m-0 p-0 ">{{ element.Product.Price  | number:'1.2-2':'de'}} </p>
                  <p *ngIf=" element.Product.PriceType == 'S' " class="m-0 p-0 text-danger bold"  matTooltip="Sonderaktion" >{{ element.Product.Price  | number:'1.2-2':'de'}} </p>
                  <p *ngIf=" element.Product.PriceType == 'I' " class="m-0 p-0 txt-pink"  matTooltip="indiv. Preis">{{ element.Product.Price  | number:'1.2-2':'de'}} </p>

                  <p *ngIf=" element.Product.PriceType == 'I' " class="m-0 p-0 txt-pink txt-xsm">bis:{{ element.Product.CustomerproductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>
                  <p *ngIf=" element.Product.PriceType == 'S' " class="m-0 p-0 text-danger txt-xsm"> bis:{{ element.Product.ProductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>

              </td>

            </ng-container>



            <ng-container  matColumnDef="PriceInput" >
              <th class="txtRight" mat-header-cell *matHeaderCellDef> V-Preis(€)  </th>
              <td class="txtRight" mat-cell *matCellDef="let element; let i = index;"> {{ element.Price | number:'1.2-2':'de' }} </td>
            </ng-container>

            <ng-container matColumnDef="PosDiscount">
              <th class="txtRight" mat-header-cell *matHeaderCellDef> Rabatt(%)  </th>
              <td class="txtRight" mat-cell *matCellDef="let element; let i = index; "> {{ element.PosDiscount | number:'1.2-2':'de'}} </td>
            </ng-container>

            <ng-container matColumnDef="TotalDiscount">
              <th class="txtRight" mat-header-cell *matHeaderCellDef> G.Rabatt(€) </th>
              <td class="txtRight" mat-cell *matCellDef="let element"> {{totalRowDiscount(element)  | number:'1.2-2':'de'}} </td>
            </ng-container>

            <ng-container matColumnDef="Surcharge">
              <th class="txtRight" mat-header-cell *matHeaderCellDef> Zuschlag(3%) </th>
              <td class="txtRight" mat-cell *matCellDef="let element"> <span *ngIf="totalRowSurcharge(element) != 0"> <b>{{totalRowSurcharge(element)  | number:'1.2-2':'de'}}</b> </span> <span *ngIf="totalRowSurcharge(element) == 0">{{totalRowSurcharge(element)  | number:'1.2-2':'de'}} </span> </td>
            </ng-container>

            <ng-container matColumnDef="TotalPrice">
              <th class="txtRight" mat-header-cell *matHeaderCellDef> G.Preis(€) </th>
              <td class="txtRight" mat-cell *matCellDef="let element"> {{totalRowPrice(element) | number:'1.2-2':'de' }} </td>
            </ng-container>

            <ng-container matColumnDef="Info">
              <th class="tcenter" mat-header-cell *matHeaderCellDef> Info an ALASKA </th>
              <td class="tcenter" mat-cell *matCellDef="let element"> {{ element.PosInfo}} </td>
            </ng-container>

            <ng-container matColumnDef="QuantityInput">
              <th class="txtRight" mat-header-cell *matHeaderCellDef> Menge  </th>
              <td class="txtRight" mat-cell *matCellDef="let element; let i = index; "> {{ element.Product.QuantityInput | number:'1.2-2':'de' }} {{ element.Product.QuantityUnitsShortDesc }} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="Unit" class="mar-r">
              <th  mat-header-cell *matHeaderCellDef> Einh. </th>
              <td mat-cell *matCellDef="let element"> {{ element.Product.QuantityUnitsShortDesc }} </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row   *matRowDef="let row;  let i = index; columns: displayedColumns;"  id="rowSC-{{i}}"  ></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">Es wurden keine Daten gefunden,  </td>
            </tr>
          </table>

        </div>
      </div>

    </div> <!-- //content -->

    <div class="divPrintFooter"> Website: www.alaskaonline.de, <span class="tab100"></span>  Email: alaska-tkk@t-online.de </div>
  </div>





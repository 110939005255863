import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-okDialog',
  templateUrl: './okDialog.component.html',
  styleUrls: ['./okDialog.component.scss']
})
export class OkDialogComponent implements OnInit {

  title: string;
  type: string;
  msg: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public okDialogRef: MatDialogRef<OkDialogComponent>){}

  ngOnInit() {
  this.title = this.data.title;
  this.type = this.data.type; //danger, warning, info
  this.msg = this.data.msg;
  }
ngAfterViewInit(){

}

onOK(){

    return this.okDialogRef.close('ok');
  }

}


import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../Services/data.service';
import { SnackbarService } from '../../../Services/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import { iPublicationTargets, iUpdatereports } from 'src/app/models/AlaskaModels';
import { FormControl } from '@angular/forms';


const ELEMENT_DATA: Array<iUpdatereports> = [];
@Component({
  selector: 'app-AdminPublication',
  templateUrl: './AdminPublication.component.html',
  styleUrls: ['./AdminPublication.component.scss']
})
export class AdminPublicationComponent implements OnInit {

  datasource = new MatTableDataSource(ELEMENT_DATA);
  updateReports: iUpdatereports[] = [];
  displayedColumns: string[] = [  'UpdatereportsId', 'Entity', 'Start', 'End', 'Elapsed', 'Updateable', 'Updated', 'Ignored', 'Failed', 'Shortmsg', 'Aktions'];
  columnsForm: FormControl;

  publicationTargets: iPublicationTargets[] = [
    {value: 'AllPublication', viewValue:'Alle-Update'},
    {value: 'CategoryproductsPublication', viewValue:'Kategorieprodukte-Update'},
    {value: 'TaxkeysPublication', viewValue:'Steuersätze-Update'},
    {value: 'MainmaterialgroupsPublication', viewValue:'Hauptmaterialgruppen-Update'},
    {value: 'MaterialgroupsPublication', viewValue:'Materialgruppen-Update'},
    {value: 'QuantityunitsPublication', viewValue:'Mengeneinheiten-Update'},
    {value: 'ProductstocksPublication', viewValue:'Produktbestände-Update'},
    {value: 'ProductsPublication', viewValue:'Produkte-Update'},
    {value: 'ProductCategoryPublication', viewValue:'Produktkategorie-Update'},
    {value: 'OpenitemsPublication', viewValue:'Offeneposten-Update'},
    {value: 'ProductpricesPublication', viewValue:'Produktpreise-Update'},
    {value: 'DiscountmatrixPublication', viewValue:'Discountmatrix-Update'},
    {value: 'CustomermaterialgroupdiscountsPublication', viewValue:'Kundensmaterialgruppenrabatte-Update'},
    {value: 'CustomersPublication', viewValue:'Kunden-Update'},
    {value: 'CustomerproductpricesPublication', viewValue:'Kundenproduktpreise-Update'},
    {value: 'AddressesUpdatePublication', viewValue:'AdressenUpdate-Update'},
    {value: 'AddressesInsertPublication', viewValue:'AddressesInsert-Update'},
    {value: 'DynamicorderrecordsPublication', viewValue:'Dynamicorderrecords-Update'},
    {value: 'SuppliersPublication', viewValue:'Lieferanten-Update'},

    {value: 'CustomerInfoPublication', viewValue:'Kundeninfo-Update'},
    {value: 'CustomerInfovertreterPublication', viewValue:'Verträterinfo-Update'},
    {value: 'ProductImagePublication', viewValue:'Produktbilder-Update'}

  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedViewValue:string ;
  selectedValue:string ;

  isLoading:boolean = false;
  pageSize: number = 50;
  pageView: string = '';

  constructor(private dataservice: DataService,
              private snackbarService: SnackbarService,
              private matPaginatorIntl: MatPaginatorIntl) { }

  ngOnInit() {
    this.populate();
    this.selectedViewValue = "update-auswählen";

    this.DatenUpdateControl = new FormControl( );

  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';


  }

  DatenUpdateControl: FormControl;


  onSelectColumns($event:any){
    if($event){
      this.selectedValue= $event;

    }

  }

  populate(){
    this.dataservice.getData('custom','getUpdatereports').subscribe(
      data =>{
          this.updateReports = data;
          this.datasource.data = this.updateReports;
          this.datasource.sort = this.sort;
          this.datasource.paginator = this.paginator;
      }, err => {
            console.log('Error-getUpdateReport', err);
        }
    );
  }

  onPublication(){
  if(!this.selectedValue){
    this.snackbarService.snackbarAction("Bitte wählen Sie ein Update-Ziel.");
    return;
  }

    if(this.selectedValue != 'AllPublication'){
      this.isLoading = true;
      this.dataservice.getData('publication',this.selectedValue).subscribe(
        data => {
          this.isLoading = false;
          if(data = "success"){
            this.snackbarService.snackbarAction("Datenaktualisierung wurde erfolgreich ausgeführt!.");
          }else{
            this.snackbarService.snackbarAction("Datenaktualisierung war nicht erfolgreich!!.");
          }
          this.populate();
        }, err => {
            console.log('Error-datenaktualisierung', err);
            this.isLoading = false;
            this.populate();
        }
      );
    }

    if(this.selectedValue == 'AllPublication'){
      this.isLoading = true;
      this.dataservice.getData('publication','AllPublication').subscribe(
        data => {
          this.isLoading = false;
          if(data = "success"){
            this.snackbarService.snackbarAction("Datenaktualisierung wurde erfolgreich ausgeführt!.");
          }else{
            this.snackbarService.snackbarAction("Datenaktualisierung war nicht erfolgreich!!.");
          }
          this.populate();
        }, err => {
            console.log('Error-datenaktualisierung', err);
            this.isLoading = false;
            this.populate();
        }
      );
    }
  }



  onRemoveReportClick(report: iUpdatereports){
    this.dataservice.DeleteData('custom','deleteUpdatereports',report.UpdatereportsId).subscribe(
      data => {
        this.isLoading = false;
        if(data != 0){
          this.snackbarService.snackbarAction("Datensatz wurde erfolgreich gelöscht!.");
        }else{
          this.snackbarService.snackbarAction("Datensatz löschen war nicht erfolgreich!!.");
        }
        this.populate();
      }, err => {
          console.log('Error-datenlöschen', err);
          this.isLoading = false;
          this.populate();
      }
    );
  }


  onPageSizeChange($event){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(+this.pageSize);
    this.pgnFirstPage();
  }

  getPageVisits():string{
    let visits = '';  // setTimeout(() => {  }, 0); // with this the chro was collapsed shut down
    try{
      let nextVisit =  this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize < this.paginator.length? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize : this.paginator.length : '';
      visits = this.paginator && nextVisit != 0 && +this.paginator.length != 0?  ((this.paginator.pageIndex * this.pageSize)+1).toString() +'-'+ nextVisit.toString()+'/'+this.paginator.length : '';
    }
    catch{  } // do nothing
    return  visits
  }
    pgnFirstPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.firstPage();
    }
    pgnBackPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.previousPage();
    }
    pgnNextPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.nextPage();
    }
    pgnLastPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.lastPage();
    }

}



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { iProducts, iProductsQuantityInput, iUsers, iCustomers, iDynamicorderrecords, iProductcategories, iShoppingcartitems, iShoppingcarts, iMsg, iOptions, iRoles, iInputOptions, iAddresses, iUserroles, iApiSource, iShoppingCartInfoBag, iOrders, iAdditives, iTooltipDiscountDisplay, iCustomerproductorders, iSupplierproductorders, iProductstocks, iParameterBag } from '../models/AlaskaModels';
import { DialogService} from '../Services/dialog.service'
import { Observable, Subject,OperatorFunction, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DEV } from '../configurations/conf';
import { Guid } from "guid-typescript";
import { Router } from '@angular/router';
import { time } from 'console';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { MatTableDataSource } from '@angular/material/table';

import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');

import { stringify } from 'querystring';
import { environment } from 'src/environments/environment';
import { SnackbarService } from './snackbar.service';
import { IdbService } from './idb.service';
import { subtract } from 'ngx-bootstrap/chronos';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  API_SCHEME = environment.API_SCHEME;
  API_HOST = environment.API_HOST;
  API_PORT = environment.API_PORT;
  API_PATH = environment.API_PATH;
  API_BASE_URL = environment.API_BASE_URL;
  API_URL = environment.API_URL;
  CULTURE = environment.CULTURE;

  customers: iCustomers[] = [];
  loggedInUser: iUsers = null;
  loggedInRoles: number[] = [];

  selectedCustomer: iCustomers = null;
  selectedDeliveryDate: Date;
  shopingCartItemsArray: Array<iShoppingcartitems> = [];
  productsOfSelectedPC: Array<iProducts> = [];

  products: Array<iProducts> = [];

  shoppingCartsArray: Array<iShoppingcarts> = [];
  selectedShoppingCart: iShoppingcarts;

  productQuantityInput: iProducts;

  private previousUrl: string;
  private currentUrl: string;
  private hideTopPannel: boolean;


  private selectedProductCategorySourceSubject = new Subject<iProductcategories>();
  private loggedInUserSourceSubject = new Subject<iUsers>();
  private shoppingCartDeletedSourceSubject = new Subject<iShoppingcarts>();
  public selectedDeliveryDateSourceSubject = new Subject<Date>();
  private selectedCustomerSourceSubject = new Subject<iCustomers>();
  private shopingCartItemsSourceSubject = new Subject<Array<iShoppingcartitems>>();
  private shoppingCartSourceSubject = new Subject<iShoppingcarts>();
  private productsOfSelectedPCSourceSubject = new Subject<Array<iProducts>>();
  private activeProductsSourceSubject = new Subject<Array<iProducts>>();
  private allProductsSourceSubject = new Subject<Array<iProducts>>();
  private dynamicOrderRecordsSourceSubject = new Subject<Array<iDynamicorderrecords>>();
  private discountProductsSelectedSubject = new Subject<boolean>();

  private productsWithProcessCode98SourceSubject = new Subject<Array<iProducts>>();
  private msgSourceSubject = new Subject<iMsg>();
  private idbDataSubject = new Subject<any>();
  private shoppingCartInfoBagSubject = new Subject<iShoppingCartInfoBag>();
  private productsQuantitySubject = new Subject<iProducts>();
  private isLoadingSubject = new Subject<boolean>();
  private clearFilterBoxSubject = new Subject<boolean>();
  private inputOptionsSourceSubject = new Subject<iInputOptions>();
  private parameterBagSubject = new Subject<iParameterBag>();
  private ShoppingCartCountsOfCustomer = new Subject<number>();


  selectedProductCategory$ = this.selectedProductCategorySourceSubject.asObservable();
  loggedInUserSubject$ = this.loggedInUserSourceSubject.asObservable();
  shoppingCartDeletedSourceSubject$ = this.shoppingCartDeletedSourceSubject.asObservable();
  deliveryDateSubject$ = this.selectedDeliveryDateSourceSubject.asObservable();
  selectedCustomerSubject$ = this.selectedCustomerSourceSubject.asObservable();
  shopingCartItemsSubject$ = this.shopingCartItemsSourceSubject.asObservable();
  shoppingCartSourceSubject$ = this.shoppingCartSourceSubject.asObservable();
  productsOfSelectedPCSubject$ = this.productsOfSelectedPCSourceSubject.asObservable();
  activeProductsSubject$ = this.activeProductsSourceSubject.asObservable();
  allProductsSubject$ =  this.allProductsSourceSubject.asObservable();
  dynamicOrderRecordsSourceSubject$ =  this.dynamicOrderRecordsSourceSubject.asObservable();
  discountProductsSelectedSubject$ =  this.discountProductsSelectedSubject.asObservable();
  productsWithProcessCode98$ = this.productsWithProcessCode98SourceSubject.asObservable();
  msgSubject$ = this.msgSourceSubject.asObservable();
  idbData$ = this.idbDataSubject.asObservable();
  shoppingCartInfoBagSubject$ = this.shoppingCartInfoBagSubject.asObservable();
  productsQuantitySubject$ = this.productsQuantitySubject.asObservable();
  isLoadingSubject$ = this.isLoadingSubject.asObservable();
  clearFilterBoxSubject$ = this.clearFilterBoxSubject.asObservable();
  inputOptionsSourceSubject$ = this.inputOptionsSourceSubject.asObservable();
  parameterBagSubject$ = this.parameterBagSubject.asObservable();
  ShoppingCartCountsOfCustomer$ = this.ShoppingCartCountsOfCustomer.asObservable();

  pipe = new DatePipe('de-DE');
  inputOptions: iInputOptions;
  observableInfoBag(): Observable<iShoppingCartInfoBag>{return of(JSON.parse(localStorage.getItem('shoppingcartinfobag')));}


  constructor(private http: HttpClient,
              private router: Router,
              private dialogService: DialogService,
              private location: Location,
              private snackbarService: SnackbarService,) {

              }



  getLogedInUser(): iUsers{ return this.loggedInUser; }
  getSelectedCustomer():iCustomers{ return this.selectedCustomer; }
  getShoppingCartItems():iShoppingcartitems[]{ return this.shopingCartItemsArray; }
  getHideTopPannel():boolean{ return this.hideTopPannel; }

  setPreviousUrl(url: string){ this.previousUrl= url; }
  getPreviousUrl(){ return this.previousUrl; }
  getCurrentUrl(){ return this.currentUrl;}

  setParameterBagSubject(parameterBag: iParameterBag){
    this.parameterBagSubject.next(parameterBag)
  }


  setCurrentUrl(url: string){
    this.previousUrl = this.currentUrl; this.currentUrl = url;
    if(url == 'userlogin' || url == 'userregister'){
      this.hideTopPannel = true;
    }else{ this.hideTopPannel = false; }
  }

  setclearFilterBoxSubject(reset:boolean){
    if(reset== true){ this.clearFilterBoxSubject.next(true)}
  }

  setShoppingCartCountsOfCustomer(count: number){
    this.ShoppingCartCountsOfCustomer.next(count);
  }
  parameterBag: iParameterBag = {};
  setLogedInUserSubject(user: iUsers){
    this.addLocalStoratgeUser(user);
    this.loggedInUser = user;
    this.setLoggedInRoles(user);
    if(this.loggedInUser && this.loggedInUser.Customers){
      if(this.selectedCustomer  == undefined || (this.selectedCustomer && this.selectedCustomer.CustomersId != this.loggedInUser.Customers.CustomersId)){
          this.selectedCustomer = this.loggedInUser.Customers;
          this.setSelectedCustomerSubject(this.loggedInUser.Customers);

          this.parameterBag.SelectedCustomer = this.loggedInUser.Customers;
          this.setParameterBagSubject(this.parameterBag);
      }

    }
    this.loggedInUserSourceSubject.next(user);
  }

  setIsLoadingSubject(isLoaging: boolean){
    this.isLoadingSubject.next(isLoaging);
  }

  setMsgSourceSubject( Name: string, Type: string, Source: string, Target: string, Action: string, Message:string, Data?:any, Timestamp?: number ){

    let msg:iMsg = { Name: Name, Type: Type, Source: Source,  Target: Target,  Action: Action,  Message: Message, Data: Data, Timestamp: Timestamp  };
    this.msgSourceSubject.next(msg);
  }

  setShopingCartItemsSourceSubject(shoppingCartItems: iShoppingcartitems[]){
    this.shopingCartItemsSourceSubject.next(shoppingCartItems);
  }

  setShoppingCartSourceSubject(shoppingCart: iShoppingcarts){
    this.shoppingCartSourceSubject.next(shoppingCart);
  }

  setDynamicOrderRecordsSourceSubject(dynamicOrderRecords: iDynamicorderrecords[]){
    this.dynamicOrderRecordsSourceSubject.next(dynamicOrderRecords);
  }

  setSelectedCustomerSubject(customer: iCustomers){

    if( customer == undefined){
      localStorage.removeItem('selectedCustomer');

    }else{
        localStorage.setItem('selectedCustomer', JSON.stringify(customer));
        this.setSelectedCustomerAddresses(customer);
        this.setSelectedCustomerContacts(customer);
        this.selectedCustomer = customer;
        this.selectedCustomerSourceSubject.next(customer);
    // if(customer){
    //   localStorage.setItem('selectedCustomer',JSON.stringify(customer));
    //   this.selectedCustomerSourceSubject.next(customer);
    // }

    }

    // console.log("data-service-selected-customer: ", this.selectedCustomer);
  }

  setProductsQuantitySubject(product: iProducts){
    this.productsQuantitySubject.next(product);
  }

  setDiscountProductsSelectedSubject(discountSelected: boolean){
    this.discountProductsSelectedSubject.next(discountSelected);
  }

  setActiveProductsSubject(products: iProducts[]){

    this.activeProductsSourceSubject.next(products);

  }

  setAllProductsSubject(products: iProducts[]){

    this.allProductsSourceSubject.next(products);
  }

  setInputOptionsSubject(inputOption: iInputOptions){
    this.inputOptionsSourceSubject.next(inputOption);
  }

  setProductsWithProcessCode98Subject(productsWithProcessCode98: iProducts[]){
    this.productsWithProcessCode98SourceSubject.next(productsWithProcessCode98);
  }

  setShoppingCartDeletedSourceSubject(shoppingCart: iShoppingcarts){
    this.shoppingCartDeletedSourceSubject.next(shoppingCart);
  }

  setDeliveryDateSubject(selectedDeliveryDate?: Date, target?:string){
    this.selectedDeliveryDate = selectedDeliveryDate? new Date(selectedDeliveryDate): undefined;
    if(this.selectedDeliveryDate == undefined){
      this.selectedDeliveryDate = localStorage.getItem('deliveryDate')? new Date(localStorage.getItem('deliveryDate')): undefined;
      this.selectedDeliveryDate =  this.selectedDeliveryDate == undefined? new Date(Date.now()): this.selectedDeliveryDate;
    }

    if(this.selectedDeliveryDate != undefined){

      localStorage.setItem('deliveryDate',this.selectedDeliveryDate.toString());
      this.selectedDeliveryDateSourceSubject.next( selectedDeliveryDate);
    }
  }

  setShoppingCartInfoBagSubject(shoppingCartInfoBag: iShoppingCartInfoBag){

    if(shoppingCartInfoBag){
        localStorage.setItem('shoppingcartinfobag', JSON.stringify(shoppingCartInfoBag));
         this.shoppingCartInfoBagSubject.next(shoppingCartInfoBag);
    }else{
      let shoppingCartInfoBag: iShoppingCartInfoBag = { TotalPrice: 0.00,  TotalNatoPrice: 0.00,  TotalDiscount: 0.00,  TotalSurCharge: 0.00,  Lieferkosten: 0.00    }
      localStorage.removeItem('shoppingcartinfobag');
      localStorage.setItem('shoppingcartinfobag', JSON.stringify(shoppingCartInfoBag));
      this.shoppingCartInfoBagSubject.next(shoppingCartInfoBag);
    }

  }

  setProductCategorySubject(productCategory?: iProductcategories){
    if(productCategory == undefined){
      localStorage.removeItem('selectedProductCategory')
    }else{
      localStorage.setItem('selectedProductCategory',JSON.stringify(productCategory));
    }
      this.selectedProductCategorySourceSubject.next(productCategory);
      console.log("data-service-selected-productCategory: ", productCategory);

  }


  setCustomers(){
    let user: iUsers = this.getLoggedinUser();

    if( user != undefined && this.isStaffUser(user.UsersId)){
      this.getData('custom','getcustomers').subscribe(
        data => {
          for(const id in data)
          {
              if(data.hasOwnProperty(id))
              {
                this.customers.push(data[id]);
              }
          }
          localStorage.setItem('customers', JSON.stringify(this.customers));
        }
      ), err =>{
        this.dialogService.okDialog("Error", "Kundensuche fehlgeschlagen!" , "danger",'40%');
      };
      console.log('isStaffUser: ', this.isStaffUser(user.UsersId));
    }
  }

  setSelectedCustomerAddresses(customer: iCustomers){

    let addresses: iAddresses[] = [];
      this.getData('custom','getaddressesbycustomer',customer.CustomersId).subscribe(
        data => {
          for(const id in data)
          {
              if(data.hasOwnProperty(id))
              {
                addresses.push(data[id]);
              }
          }
          localStorage.setItem('sca', JSON.stringify(addresses));
        }
      ), err =>{
        this.dialogService.okDialog("Error", "Kundenadressesuche fehlgeschlagen!" , "danger",'40%');
      };
  }

  setSelectedCustomerContacts(customer: iCustomers){

    let users: iUsers[] = [];
      this.getData('custom','getusersbycustomer',customer.CustomersId).subscribe(
        data => {
          for(const id in data)
          {
              if(data.hasOwnProperty(id))
              {
                users.push(data[id]);
              }
          }
          localStorage.setItem('scc', JSON.stringify(users));
        }
      ), err =>{
        this.dialogService.okDialog("Error", "Kundenkontaktssuche fehlgeschlagen!" , "danger",'40%');
      };
  }



  setProducts(productCategory: iProductcategories){
  let productsOfSelectedPC: Array<iProducts> = [];

          this.getData('products','category',productCategory.ProductcategoriesId).subscribe(
            dataProducts => {
            for(const id in dataProducts)
          {
              if(dataProducts.hasOwnProperty(id))
              {
                productsOfSelectedPC.push(dataProducts[id]);
              }
          }
            localStorage.setItem('productsOfSelectedPC',JSON.stringify(productsOfSelectedPC));
            this.productsOfSelectedPCSourceSubject.next(productsOfSelectedPC);

          }, err => {
            console.log("error getData: ",err);
          })
    console.log("data-service-productsOfSelectedPC: ", productsOfSelectedPC);
    console.log("data-service-productsOfSelectedPC2: ", JSON.stringify(productsOfSelectedPC));
  }

  setLoggedInRoles(user: iUsers){

    if(user && user.Userroles){
      user.Userroles.filter(x => x.UsersId == this.loggedInUser.UsersId).forEach(role => {
        this.loggedInRoles.push(role.RolesId);
      });}
  }

  hasRoles(roleIds: number[]): boolean{
    let roles: number[] = [];
    this.loggedInUser = this.getLoggedinUser();
    if(this.loggedInUser && this.loggedInUser.Userroles){
      roleIds.forEach(roleId => {
        if(this.loggedInUser.Userroles.find(x => x.RolesId == roleId))
        roles.push(roleId);

      });

    }else{   }
    return roles.length > 0;
  }

  getLoggedInUserRoleIds(): number[]{
    this.loggedInUser = this.getLoggedinUser();
    let roleIds: number[] = [];
    if(this.loggedInUser && this.loggedInUser.Userroles){
      this.loggedInUser.Userroles.forEach(userRole => {
        roleIds.push(userRole.RolesId)
      });
    }else{
        console.log("this.loggedInUser not set: ", this.loggedInUser);
        return;
    }
    return roleIds;
  }

  onLogOut(){
    localStorage.clear();

    this.setMsgSourceSubject( 'userloggedout', '',  'navbar',  '', '', 'userloggedout', undefined ,  0 );
    this.router.navigate(['/']);
    this.snackbarService.snackbarAction(" Sie sind abgemeldet, auf Wiedersehen!!");
  }

  getHeaders():HttpHeaders{
    return new HttpHeaders()
      .set('content-type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('ApiKey', 'MzgzZmQ1ZDM=')
      .set('Authorization',localStorage.getItem('token')?  'Bearer ' + localStorage.getItem('token') : '');
  }

  getApiUrl(controller: string, customMethod?: string, id?: number): string{

    customMethod = customMethod == undefined? null: customMethod;
    id = id == undefined ? null : id;

    let ApiUrl: string =  environment.API_URL+controller+"/";
    ApiUrl = customMethod !== null ? ApiUrl+customMethod+'/': ApiUrl;
    ApiUrl = id !== null ? ApiUrl+id: ApiUrl;
    console.log("api_url", ApiUrl);
    return ApiUrl;
 }

 getApiUrlByMultiIds(controller: string, customMethod?: string, id?: number[]): string{
  customMethod = customMethod == undefined? null: customMethod;
  id = id == undefined ? null : id;

  let ApiUrl: string =  environment.API_URL+controller+"/";
  ApiUrl = customMethod !== null ? ApiUrl+customMethod+'/': ApiUrl;
  ApiUrl = id !== null ? ApiUrl+id: ApiUrl;
  console.log("api_url", ApiUrl);
  return ApiUrl;
}

 getApiUrlByQs(controller: string, customMethod?: string, queryString?: string): string{
  customMethod = customMethod == undefined? null: customMethod;
  queryString = queryString == '' || queryString == undefined? null : queryString;

  let ApiUrl: string =  environment.API_URL+controller+"/";
  ApiUrl = customMethod !== null ? ApiUrl+customMethod+'/': ApiUrl;
  ApiUrl = queryString !== null ? ApiUrl+queryString: ApiUrl;
  console.log("api_url", ApiUrl);
  return ApiUrl;
}


  getData(controller: string, customMethod?: string, id?: number): Observable<any>{
    const Url = this.getApiUrl(controller, customMethod, id);

    return this.http.get(Url).pipe(
      map(
        data =>
        {
            return data;
        },err => {return err}
      )
    );
  }

  DeleteData(controller: string, customMethod?: string, id?: number): Observable<any>{
    const Url = this.getApiUrl(controller, customMethod, id);
    return this.http.delete(Url).pipe(
      map(
        data =>
        {
            return data;
        }
      )
    );
  }

  getDataByQuerystring(controller: string, customMethod?: string, querystring?: string): Observable<any>{
    const Url = this.getApiUrlByQs(controller, customMethod, querystring);
    return this.http.get(Url).pipe(
      map(
        data =>
        {
            return data;
        }
      )
    );
  }

  getDataByFilter(filterField: string, filterValue: string, controller: string, entity?: string, id?: number): Observable<any>{
    const Url = this.getApiUrl(controller, entity, id);
    const found: Array<any> = [];
    return this.http.get(Url).pipe(
      map(
        data =>
        {
            for(const id in data)
            {
                if(data.hasOwnProperty(id) && data[filterField] == filterValue)
                {
                  found.push(data[id]);
                }
            }
                return found;
        }
      )

    );
  }

  PostData(controller: string, data: any, customMethod?: string): Observable<any>{
    const Url = this.getApiUrl(controller, customMethod, null);
    const header: HttpHeaders = new HttpHeaders({'Content-Type':'application/json'});
    let resultData: any;
    return this.http.post(Url, data);

  }

  PutData(controller: string, data: any, customMethod?: string): Observable<any>{
    const Url = this.getApiUrl(controller, customMethod, null);
    let header: HttpHeaders = new HttpHeaders({'Content-Type':'application/json'});
    let resultData: any;
    return this.http.put(Url, data);
  }

  RemoveData(controller: string, id: number, customMethod?: string): Observable<any>{
    const Url = this.getApiUrl(controller, customMethod, id);
    let header: HttpHeaders = new HttpHeaders({'Content-Type':'application/json'});
    let resultData: any;
    return this.http.delete(Url);
  }

  handleError(handleError: any): any {
    throw new Error("Method not implemented.");
  }

  ErrorHandler(errorResponse: HttpErrorResponse) {
    if(errorResponse.error instanceof ErrorEvent){
      console.log("clientside error: ", errorResponse.error.message );
    }else{
      console.log("Server side error: ", errorResponse );
    }
      return throwError("There is a problem with the service, we are notified and are working hard to solve the problem. please try latter. ");

  }

  private DELIVERYDATE = new Subject<Date>();

  observableDate = this.DELIVERYDATE.asObservable();

  CreateShoppingCart(customer: iCustomers,  deliveryDate?:Date): iShoppingcarts{

    let shoppingCard: iShoppingcarts;
  // customer && this.loggedInUser   7=angebot,   8=vorlage
    if(customer  ){
       shoppingCard = {
        ShoppingcartsId: +Date.now(),
        CustomerId:   customer.CustomersId,

        ShoppingCartStateId:  0,
        OrderDate:  new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
        CreateDate:  new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
        Name:  customer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss'),
        ReferenceNumberId:   6,
        ShoppingCartBatchId:   6,
        DeliveryDate:  deliveryDate != undefined? deliveryDate : new Date(Date.now()),
        UserId:  this.loggedInUser? this.loggedInUser.UsersId : 0,
        Customer: customer,
        UsersUserrole: this.getLoggedInUserRoleIds(),
        Shoppingcartitems: [],
        Comment: '',
        OrderInfo: ''

      }
    }else{
      console.log("customer not provided ")
    }
    return shoppingCard;
  }

  createShoppingCartItem(product: iProducts, shoppingCart: iShoppingcarts): iShoppingcartitems{

    let shoppingcartitems: iShoppingcartitems = {
      ShoppingcartitemsId: +Date.now(),
      ShoppingCartId: shoppingCart.ShoppingcartsId,
      ProductId: product.ProductsId,
      PosInfo: '',
      PosIndex: shoppingCart != undefined && shoppingCart.Shoppingcartitems != undefined ? shoppingCart.Shoppingcartitems.length: 0,
      Quantity: product.QuantityInput != undefined? +product.QuantityInput.toFixed(2): 0.00,
      ListPrice: product.ListPrice != undefined? +product.ListPrice.toFixed(2): 0.00,
      Price: product.Price != undefined? +product.Price.toFixed(2): 0.00,
      PosDiscount: 0,
      BulkDiscount: 0,
      TotalDiscount: 0,
      Product: product,
      PriceType: product.PriceType

  };
      this.setBulkDiscount(shoppingcartitems);
      return shoppingcartitems;
  }

  addLocalStoratgeUser(user: iUsers){

    let UsersArray: iUsers[] = [];
    if(localStorage.getItem('loggedinUsers')){
      UsersArray = JSON.parse(localStorage.getItem('loggedinUsers'));
      if(UsersArray.find(p => p.Email.toLowerCase() !== user.Email.toLowerCase()))
      {

        UsersArray = [user, ...UsersArray];
      }
    }else{
      UsersArray = [user];
    }
      localStorage.setItem('loggedinUsers', JSON.stringify(UsersArray));
      localStorage.setItem('token', user.Token);
      console.log("user-tocken", user);

  }

  getLoggedinUser(): iUsers{
      let user: iUsers;
      if(!!JSON.parse(localStorage.getItem('loggedinUsers'))){
        user = JSON.parse(localStorage.getItem('loggedinUsers'))[0];

      }
      return user;
  }

  isStaffUser(userId: number): boolean{
      let staffUserRoles: number[]= [1,2,3]
      return !!(staffUserRoles.find(x => x == userId));
  }

  getDate(date:string | Date, format:string): string{
    let transformedDate:string ='';
    registerLocaleData(localeDe);
    let datePipe = new DatePipe('de')
    if(date != null && date != ''){
      transformedDate =  datePipe.transform(date.toString(),format,'de');
    }
      return transformedDate;
  }


  setBulkDiscount(shoppingcartitem: iShoppingcartitems):iShoppingcartitems{
    let kgUntitIds: number[] = [1,93];
    let currentQuantityInput = +shoppingcartitem.Product.QuantityInput.toFixed(2);
    let offeredQuantityInput =  +(shoppingcartitem.Quantity).toFixed(2);

    if( shoppingcartitem.PriceType == 'L' && !kgUntitIds.includes(shoppingcartitem.Product.QuantityUnitId)){ //except q.u=kg, if listprice give quantity discount
      if(shoppingcartitem.Quantity >= 5 && shoppingcartitem.Quantity < 10){
        shoppingcartitem.BulkDiscount = 2;
      }else if(shoppingcartitem.Quantity >= 10){
        shoppingcartitem.BulkDiscount = 4;
      }else{
        shoppingcartitem.BulkDiscount = 0;
      }
    }
    shoppingcartitem.Quantity = +shoppingcartitem.Quantity.toFixed(2);
    return shoppingcartitem;
  }

    goToNextInput(prefix: string, rowIndex?:number, nexOrPrevious?: number):boolean{
      let inputId = prefix+'-' + rowIndex.toString();
      let inputNextId = prefix+'-' + (rowIndex + nexOrPrevious).toString();
      let inputPreviousId = prefix+'-' + (rowIndex - 1).toString();
    let success = false;

      let currentInput = document.getElementById(inputId);
      let nextInput = document.getElementById(inputNextId );
      let previousInput = document.getElementById(inputPreviousId );

      if (nextInput) {
        setTimeout(() => nextInput.focus(), 0);
        success = true;
      }else if(previousInput){
        setTimeout(() => previousInput.focus(), 0);
      }
      return success;
    }

    coloredRowId:string = '';
    goToNextRow(prefix: string, rowIndex?:number, nexOrPrevious?: number){

      this.coloredRowId = prefix + '-' + rowIndex.toString();
      let rowNextId = prefix + '-' + (rowIndex + nexOrPrevious).toString();
      let rowPreviousId = prefix + '-' + (rowIndex - 1).toString();

      let matTable = document.getElementById('matTable');
      let currentRow = document.getElementById(this.coloredRowId);
      let nextRow = document.getElementById(rowNextId);
      let previousRow = document.getElementById(rowPreviousId );

      if (nextRow) {
         setTimeout(() => (currentRow.style.backgroundColor = ''), 0);
        setTimeout(() => (nextRow.style.backgroundColor = '#ccffcc'), 0);

      }else if(previousRow){
         setTimeout(() => (currentRow.style.backgroundColor = ''), 0);
        setTimeout(() => (previousRow.style.backgroundColor = '#ccffcc'), 0);

      }
    }

    updatePriceTypeOfShoppingCartItems(shoppingCarts: iShoppingcarts ){

      if(shoppingCarts){

            shoppingCarts.Shoppingcartitems.forEach(shoppingcartitem => {
                if(shoppingcartitem.Price != shoppingcartitem.Product.Price){
                  shoppingcartitem.PriceType = 'V';
                }
            });
      }
      return shoppingCarts;
    }

    getAndSetShoppingCartInfoBag(shoppingcart: iShoppingcarts): iShoppingCartInfoBag{

    let shoppingCartItems: iShoppingcartitems[] = shoppingcart? shoppingcart.Shoppingcartitems : [];

      let shoppingCartInfoBag : iShoppingCartInfoBag = { TotalPrice: 0.00, TotalNatoPrice: 0.00,TotalDiscount: 0.00, TotalSurCharge: 0.00, Lieferkosten: 0.00  }

      if(shoppingcart == undefined || shoppingCartItems.length == 0){

         this.setShoppingCartInfoBagSubject(shoppingCartInfoBag);

        return shoppingCartInfoBag;
      }

      if( shoppingCartItems){
        shoppingCartItems.forEach(row => {
          shoppingCartInfoBag.TotalPrice = shoppingCartInfoBag.TotalPrice + this.totalRowPrice(row);
          shoppingCartInfoBag.TotalDiscount = shoppingCartInfoBag.TotalDiscount + this.totalRowDiscount(row);
          shoppingCartInfoBag.TotalSurCharge = shoppingCartInfoBag.TotalSurCharge + this.totalRowSurcharge(row);    // only 3% of the price
        });
      }

      shoppingCartInfoBag.TotalPrice = Math.round((shoppingCartInfoBag.TotalPrice  + Number.EPSILON) * 100) / 100;
      shoppingCartInfoBag.TotalDiscount = Math.round((shoppingCartInfoBag.TotalDiscount  + Number.EPSILON) * 100) / 100;
      shoppingCartInfoBag.TotalSurCharge = Math.round((shoppingCartInfoBag.TotalSurCharge  + Number.EPSILON) * 100) / 100;
      shoppingCartInfoBag.TotalNatoPrice = Math.round(( (shoppingCartInfoBag.TotalPrice - (shoppingCartInfoBag.TotalSurCharge + shoppingCartInfoBag.TotalDiscount))  + Number.EPSILON) * 100) / 100;
      // shoppingCartInfoBag.TotalPrice = +shoppingCartInfoBag.TotalPrice.toFixed(2);
      // shoppingCartInfoBag.TotalDiscount = +shoppingCartInfoBag.TotalDiscount.toFixed(2)
      // shoppingCartInfoBag.TotalSurCharge = +shoppingCartInfoBag.TotalSurCharge.toFixed(2)
      // shoppingCartInfoBag.TotalNatoPrice = +(shoppingCartInfoBag.TotalPrice - (shoppingCartInfoBag.TotalSurCharge + shoppingCartInfoBag.TotalDiscount)).toFixed(2)

      this.setShoppingCartInfoBagSubject(shoppingCartInfoBag );
      return shoppingCartInfoBag
    }

    totalShoppingDiscount(datasource:MatTableDataSource<iShoppingcartitems>): number{
      let total: number = 0;
      if(datasource && datasource.data){
        datasource.data.forEach(row => {
            total = total + this.totalRowPrice(row);
        });
      }
      return +total.toFixed(2);
    }

    totalRowDiscount(shoppingcartitem: iShoppingcartitems): number{
      return +(shoppingcartitem.Price * shoppingcartitem.Quantity * ((shoppingcartitem.PosDiscount / 100) + (shoppingcartitem.BulkDiscount / 100))).toFixed(2);

    }

    totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
      let totalRowSurcharge = 0.00;
      if( shoppingcartitem.Quantity > 0 && shoppingcartitem.Quantity < 1 && shoppingcartitem.Product.PriceType != 'I'  && shoppingcartitem.Product.PartialPackageChargeable == -1){
        totalRowSurcharge = shoppingcartitem.Price * shoppingcartitem.Quantity  * shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100
      }
      return totalRowSurcharge;
    }


    roundUpQuantity(product: iProducts):iProducts{

      let nonDividableUnits: number[] = [4,11,12,13,14,15,16,18,19,21,22,23,24,25,26,27,94];
      if(product == undefined){ return;}
      if(product.QuantityInput){


        if(nonDividableUnits.includes(product.QuantityUnitId) ){
          product.QuantityInput = +(Math.ceil(product.QuantityInput).toFixed(2));
        }
        if(product.QuantityUnitId == 17 ){
          // change 1.234 > 1.24   1.111 > 1.12
          product.QuantityInput = +product.QuantityInput.toFixed(3) > +product.QuantityInput.toFixed(2)? +(product.QuantityInput.toFixed(2) + 0.01): +product.QuantityInput.toFixed(2);
        }

        product.QuantityInput = +(product.QuantityInput).toFixed(2);
      }else{
        // product.QuantityInput = 0.00
      }

      return product;
    }





    onKeyCombination($event: any, product?: iProducts){
      let tabMode = localStorage.getItem("tabMode");
      let tabLebel = '';
      let key = ''
      if($event && $event != ''){
        key = $event.key;

        if($event.altKey == true && key.toLowerCase() == 's') { this.goToNextInput('filterandsearchbox', 0, 0);   }
        if($event.altKey == true && key.toLowerCase() == 'm' && product != undefined) { this.snackbarService.snackbarAction("mEK: " + this.getMek(product) + ", lEK: " + this.getLek(product), '');  }
        if($event.altKey == true && key.toLowerCase() == 'i' && product != undefined && product.ProductInfo && product.ProductInfo != '') { this.dialogService.okDialog("Info", product.ProductInfo, "success",'40%');  }
        // if($event.altKey == true && key.toLowerCase() == 'k' && product != undefined ) { this.snackbarService.snackbarAction("mEK: " + this.getMek(product) + ", lEK: " + this.getLek(product), '');  }

        if($event.altKey == true && key.toLowerCase() == 'g') { let spot = document.getElementById('productCategoryControl');             if(spot){ setTimeout(() => spot.click(), 0);}  }
        if($event.altKey == true && key.toLowerCase() == 'q') { let spot = document.getElementById('quickbookingproductnumber');  if(spot){ setTimeout(() => spot.focus(), 0);}  }
        if($event.altKey == true && key.toLowerCase() == 'u') { let spot = document.getElementById('additiveControl');            if(spot){ setTimeout(() => spot.click(), 0);}  }
        if($event.altKey == true && key.toLowerCase() == 'r') { let spot = document.getElementById('discountControl');            if(spot){ setTimeout(() => spot.click(), 0);}  }

        if($event.altKey==true && tabMode == '0' && key.toLowerCase() == 'p'){ tabLebel = 'Produkte';}
        if($event.altKey==true && tabMode == '0' && key.toLowerCase() == 'w'){ tabLebel = 'Warenkorb';}
        if($event.altKey==true && tabMode == '0' && key.toLowerCase() == 'o'){ tabLebel = 'Ordersatz';}
        if($event.altKey==true && tabMode == '0' && key.toLowerCase() == 'a'){ tabLebel = 'Auftragssammler';}
        if($event.altKey==true && tabMode == '0' && key.toLowerCase() == 'z'){ tabLebel = 'Adminansicht';}

        if($event.altKey==true && tabMode == '1' && key.toLowerCase() == 'v'){ tabLebel = 'Kundenverwaltung';}
        if($event.altKey==true && tabMode == '1' && key.toLowerCase() == 'n'){ tabLebel = 'Benutzerverwaltung';}
        if($event.altKey==true && tabMode == '1' && key.toLowerCase() == 'b'){ tabLebel = 'Bestellverwaltung';}
        if($event.altKey==true && tabMode == '1' && key.toLowerCase() == 'd'){ tabLebel = 'Datenverwaltung';}
        if($event.altKey==true && tabMode == '1' && key.toLowerCase() == 'k'){ tabLebel = 'Kundenansicht';}

        let tab = document.getElementById(tabLebel);
        if(tab){tab.click()}

      }


  }

  clickTab(tabLebel:string){
    let tab = document.getElementById(tabLebel);
    if(tab){tab.click()}
  }

  getMek(product: iProducts): string{
    return product != undefined && product.AveragePurchasePrice != undefined? product.AveragePurchasePrice.toFixed(2) : '--';
  }
  getLek(product: iProducts): string{
    return product != undefined && product.LastPurchasePrice != undefined? product.LastPurchasePrice.toFixed(2) : '--';
  }
  getBg(quantity: number, type: string): string {
    if (type == 'DispositionStockValue' || type == 'StockValue' || type == 'OpenOrdersStockValue' || type == 'SupplierDeliveryStockValue') {
      return quantity && quantity >= 5 ?  'bg-green': 'bg-yelow' ;
    }
    if (type == 'QuantityInput') {
      return quantity != 0 ?  'bg-green inputbox': 'bg-white inputbox';
    }
    if (type == 'btnBasket') {
      return quantity != 0 ? 'material-icons btn-outline-success' : 'material-icons btn-outline-primary';
    }
    if (type == 'stockIconClass') {
      return quantity <= 5?  'material-icons bg-warning' : 'material-icons  bg-success';
    }
  }

  getIcon(quantity: number, type: string): string{
    if(type == 'stockAvailabilityIcon'){
      return quantity < 5?  'done' : 'done_all';
    }

    if (type == 'stockIconStyle') {
      return quantity <= 5?  'material-icons  btn-outline-warning font-weight-bolder' : 'material-icons  btn-outline-success font-weight-bolder';
    }

  }
  bigOrSmall(quantity:number):string{
    return quantity >=5 ? 'voll verfügbar' : 'Geringe Verfügbarkeit';
  }

  getScreenSize():string{
    let windowSize = 'L';
    if((window.innerHeight > window.innerWidth && window.innerWidth  <= 420) || (window.innerHeight < window.innerWidth && window.innerWidth  <= 740)) {windowSize = 'S'} else
    if((window.innerHeight > window.innerWidth && window.innerWidth  >= 768) || (window.innerHeight < window.innerWidth && window.innerWidth  <= 1024)) {windowSize = 'M'} else {windowSize = 'L'}
    return windowSize;
  }

  setInputOptions(): iInputOptions{
    if(!!localStorage.getItem("inputOptions")){
      this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
      console.log("inputOptions0", this.inputOptions);
      return this.inputOptions;
    }
    else{
    this.getData("custom","getoptions").subscribe(
      data => {
        this.inputOptions = data;
        if(this.inputOptions){
          console.log("inputOptions1", this.inputOptions);
          localStorage.removeItem("inputOptions");
          localStorage.setItem("inputOptions", JSON.stringify(this.inputOptions));
          this.setInputOptionsSubject(this.inputOptions);
          return this.inputOptions;
        }
        else{
          this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
          console.log("inputOptions2", this.inputOptions);
          return this.inputOptions;
        }
      },err =>{
        console.log("error get inputOptions in app-component", err);
      }
    );

    }

  }

  setProductCategories(){
    this.getData( 'custom','getProductcategories').subscribe(
      data => {
        let Productcategories: iProductcategories[] = data;
        Productcategories.sort((a, b) => +a.Name - +b.Name );
        localStorage.setItem('Productcategories',JSON.stringify(Productcategories));
      }), error => {
      console.log("dataService error productCategory",error);
      }
  }

  positionRowPartialPackageCharge(shoppingcartitem: iShoppingcartitems): number{
    let rowSurcharge = 0;
    if( shoppingcartitem.Quantity > 0 && shoppingcartitem.Quantity < 1 && shoppingcartitem.Product.PriceType != 'I'  && shoppingcartitem.Product.PartialPackageChargeable == -1){
      rowSurcharge =  shoppingcartitem.Product.QuantityUnitsPartialPackageCharge
    }
    return 0 - +rowSurcharge.toFixed(2);
  }

  getTooltipDiscount(shoppingcartitem: iShoppingcartitems): iTooltipDiscountDisplay{

    let totalRowDescount: iTooltipDiscountDisplay = {
      PauschalRabatt: shoppingcartitem.Product.CustomersBlanketDiscount? shoppingcartitem.Product.CustomersBlanketDiscount: 0.00,
      PositionRabatt: shoppingcartitem.PosDiscount? shoppingcartitem.PosDiscount : 0.00,
      MengenRabatt: shoppingcartitem.BulkDiscount ? shoppingcartitem.BulkDiscount : 0.00,

      WarenGruppeRabatt: shoppingcartitem.Product.CustomermaterialgroupDiscount ? shoppingcartitem.Product.CustomermaterialgroupDiscount : 0.00,
      RabattGruppeRabatt: shoppingcartitem.Product.DiscountMatrixsDiscount ? shoppingcartitem.Product.DiscountMatrixsDiscount : 0.00,
      Kartonanbruch: this.positionRowPartialPackageCharge(shoppingcartitem),
      Gesamt: 0
    }
    totalRowDescount.Gesamt =   (shoppingcartitem.Product.CustomersBlanketDiscount? shoppingcartitem.Product.CustomersBlanketDiscount: 0.00)
                                + (totalRowDescount.PositionRabatt ? totalRowDescount.PositionRabatt : 0.00)
                                + (totalRowDescount.MengenRabatt ? totalRowDescount.MengenRabatt : 0.00)
                                + (totalRowDescount.Kartonanbruch)
    return totalRowDescount;

  }

  totalRowNetDiscount(shoppingcartitem: iShoppingcartitems): number{
    if(shoppingcartitem == undefined || shoppingcartitem.Product == undefined){return;}
    // wenn der Produkt kein Sonderangebotspreis oder  Individualpreis hat.
    let paushalrabatt =  shoppingcartitem.Product.CustomersBlanketDiscount && shoppingcartitem.Product.PriceType.toLowerCase() != 's' && shoppingcartitem.Product.PriceType.toLowerCase() != 'i' ?  shoppingcartitem.Product.CustomersBlanketDiscount : 0.00;
    return shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount + this.positionRowPartialPackageCharge(shoppingcartitem) + paushalrabatt;
  }

  // sugestPositionDiscount(shoppingcartitem: iShoppingcartitems){
  //   if(shoppingcartitem == undefined || shoppingcartitem.Product == undefined){return;}
  //   // wenn der Produkt kein Sonderangebotspreis oder  Individualpreis hat.
  //   let paushalrabatt =  shoppingcartitem.Product.CustomersBlanketDiscount && shoppingcartitem.Product.PriceType.toLowerCase() != 's' && shoppingcartitem.Product.PriceType.toLowerCase() != 'i' ?  shoppingcartitem.Product.CustomersBlanketDiscount : 0.00;
  //   return shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount + this.positionRowPartialPackageCharge(shoppingcartitem) + paushalrabatt;
  // }

  totalRowPrice(shoppingcartitem: iShoppingcartitems): number{

    // let rowSurcharge = this.positionRowPartialPackageCharge(shoppingcartitem) != 0?   ( 1 + (shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100)) : 1
    let rowSurcharge = 0;
    let total = 0;
    let totalRowNetDiscount = this.totalRowNetDiscount(shoppingcartitem);

    // if( shoppingcartitem.Quantity > 0 && shoppingcartitem.Quantity < 1 && shoppingcartitem.Product.PriceType != 'I'  && shoppingcartitem.Product.PartialPackageChargeable == -1){
    //   rowSurcharge =  0 - +shoppingcartitem.Product.QuantityUnitsPartialPackageCharge.toFixed(2);
    // }

    //  rowSurcharge = rowSurcharge != 0?  1 + (shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100) : 1;

      // total =  +((shoppingcartitem.Price * shoppingcartitem.Quantity *  (rowSurcharge) )
      // - ((shoppingcartitem.Price * shoppingcartitem.Quantity)*(shoppingcartitem.BulkDiscount + shoppingcartitem.PosDiscount + totalRowNetDiscount )/100)).toFixed(2);

      total =  +((shoppingcartitem.Price * shoppingcartitem.Quantity  )
      - ((shoppingcartitem.Price * shoppingcartitem.Quantity)*(totalRowNetDiscount )/100)).toFixed(2);


      return Math.round((total  + Number.EPSILON) * 100) / 100;


    // return +((shoppingcartitem.Price * shoppingcartitem.Quantity * ( 1 + (shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100)))
    // - ((shoppingcartitem.Price * shoppingcartitem.Quantity)*(shoppingcartitem.BulkDiscount + shoppingcartitem.PosDiscount )/100)).toFixed(2);

  }


  onFocusOutPricePosDiscountQuantityInput(shoppingcartitem: iShoppingcartitems, $event, rowIndex?: number, prefix?: string): iShoppingcartitems{

    this.clearRowBgColor(prefix,rowIndex);

    let event = $event;
    let targetPrefix:string = event.currentTarget.id != undefined? event.currentTarget.id.toString().split('-')[0]:'';
    let targetId:string = event.currentTarget.id;
    if(event.ctrlKey != true){

      shoppingcartitem.Product= this.roundUpQuantity(shoppingcartitem.Product);
      shoppingcartitem.Quantity = +shoppingcartitem.Product.QuantityInput.toFixed(2);
      this.totalRowNetDiscount(shoppingcartitem);
      shoppingcartitem = this.setBulkDiscount(shoppingcartitem);

      shoppingcartitem.PosDiscount = shoppingcartitem.PosDiscount == null? 0.00: shoppingcartitem.PosDiscount;
      let currentQuantityInput  = shoppingcartitem.Product.QuantityInput? +shoppingcartitem.Product.QuantityInput.toFixed(2): 0.00;
      let offeredQuantityInput  =  shoppingcartitem.Product.QuantityInput? +shoppingcartitem.Product.QuantityInput.toFixed(2): 0.00;

      let currentPrice          = shoppingcartitem.Product.Price ? +shoppingcartitem.Product.Price.toFixed(2): 0.00;
      let offeredPrice          =  shoppingcartitem.Price? +(shoppingcartitem.Price).toFixed(2): 0.00;
      let offeredDiscountpercent      = shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount;
      let offeredDiscount       =  offeredDiscountpercent ?  +(offeredPrice * offeredDiscountpercent / 100).toFixed(2): 0.00;
      let offeredNetPrice       =  offeredPrice - offeredDiscount? +(offeredPrice - offeredDiscount).toFixed(2): 0.00;
       let discountedPrice      =  +(offeredPrice - (offeredPrice * offeredDiscount / 100)).toFixed(2);

      let minSellPrice          = +(shoppingcartitem.Product.AveragePurchasePrice * 1.17).toFixed(2);
      let extraMinSellPrice     = +(shoppingcartitem.Product.AveragePurchasePrice * 1.15).toFixed(2);
      let topOnMinSellPrice     = +(shoppingcartitem.Product.AveragePurchasePrice * 0.02).toFixed(2);
      let maxPosibleDiscount    = offeredPrice - extraMinSellPrice
      let nonDividableUnits: number[] = [4,11,12,13,14,15,16,18,19,21,22,23,24,25,26,27,94]
      let kgUntitIds: number[] = [1,93];

      if((this.hasRoles([1,2,3,4]) &&  offeredNetPrice < minSellPrice &&  currentPrice != offeredPrice) == false){

          if(shoppingcartitem.PriceType == 'I' && offeredDiscount != 0) {
            shoppingcartitem.Price = +currentPrice.toFixed(2);
            shoppingcartitem.PosDiscount = 0;
            this.dialogService.okDialog('Ungültige Eingabe', 'Auf individualpreis darf darf kein zusätzlicher Rabatt erfasst werden', 'danger','40%');
            return;
          }

          if((shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) > 4 || (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) < 0 ){
            this.dialogService.okDialog('Ungültige Eingabe', 'Gesamtrabatt: '+ (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge).toFixed(2)  +'% is ungültig!', 'danger', '40%');
            shoppingcartitem.PosDiscount = 0;
            return;
          }

          if(shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount  > 4){
            this.dialogService.okDialog('Ungültige Eingabe', 'Überschreitung der Gesamtrabatt: 4% is ungültig!', 'danger', '40%');
            shoppingcartitem.PosDiscount = shoppingcartitem.PosDiscount - shoppingcartitem.BulkDiscount >= 0? shoppingcartitem.PosDiscount - shoppingcartitem.BulkDiscount: 0;

            return;
          }

          if(currentPrice != offeredPrice && shoppingcartitem.Product.AveragePurchasePrice == 0){ // warn if mEK is 0
            shoppingcartitem.Price = shoppingcartitem.Product.Price;
              this.dialogService.okDialog('Warnung: ungepflegter mEK' , ' Der mEK dieses Produktes beträgt 0,00 €. ' + 'Mindestpreis kann nicht ermittelt werden!', 'warning', '60%');
              return;
          }

          if(discountedPrice < minSellPrice && shoppingcartitem.PriceType == 'I' && currentPrice == offeredPrice){ // warn if a-eins preis is less than mEK + 15%, block further discount

            this.dialogService.okDialog('Mindestpreisunterschreitung', "Der ermittelter  preis: ("+discountedPrice.toString()+" €) unterschreitet den Mindest-VK mEK + 17%: (" + minSellPrice.toString()+" €)", 'warning', '60%');
            shoppingcartitem.PosDiscount = 0;
            return;
          }

          if(shoppingcartitem.Product.AveragePurchasePrice == undefined || shoppingcartitem.Product.AveragePurchasePrice == 0){
            shoppingcartitem.Product.ListPrice = shoppingcartitem.ListPrice;
            shoppingcartitem.Product.Price = shoppingcartitem.ListPrice;
            shoppingcartitem.Price = shoppingcartitem.Product.Price;
            this.dialogService.okDialog('Warnung: ungepflegter mEK' , ' Der mEK dieses Produktes beträgt 0,00 €. ' + 'Mindestpreis kann nicht ermittelt werden!', 'warning', '60%');
            return;
          }else{
            return shoppingcartitem;
          }



      }else if(this.hasRoles([1,2,3,4]) &&  offeredNetPrice < minSellPrice &&  currentPrice != offeredPrice)
      {
            shoppingcartitem = this.setBulkDiscount(shoppingcartitem);

            if(shoppingcartitem.PriceType == 'I' && offeredDiscount != 0) {
              shoppingcartitem.Price = +currentPrice.toFixed(2);
              shoppingcartitem.PosDiscount = 0;
              this.dialogService.okDialog('Ungültige Eingabe', 'Auf individualpreis darf darf kein zusätzlicher Rabatt erfasst werden', 'danger','40%');
              return;
            }

            if((shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) > 4 || (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) < 0 ){
              this.dialogService.okDialog('Ungültige Eingabe', 'Gesamtrabatt: '+ (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge).toFixed(2) +'% is ungültig!', 'danger', '40%');
              shoppingcartitem.PosDiscount = 0;
              return;
            }

            if(shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount  > 4){
              this.dialogService.okDialog('Ungültige Eingabe', 'Überschreitung der maximalerabatt: 4% is ungültig!', 'danger', '40%');
              shoppingcartitem.PosDiscount = shoppingcartitem.PosDiscount - shoppingcartitem.BulkDiscount >= 0? shoppingcartitem.PosDiscount - shoppingcartitem.BulkDiscount: 0;
              // shoppingcartitem.PosDiscount = 0;
              return;
            }

            if(currentPrice != offeredPrice && shoppingcartitem.Product.AveragePurchasePrice == 0){ // warn if mEK is 0
              shoppingcartitem.Price = shoppingcartitem.Product.Price;
                this.dialogService.okDialog('Warnung: ungepflegter mEK' , ' Der mEK dieses Produktes beträgt 0,00 €. ' + 'Mindestpreis kann nicht ermittelt werden!', 'warning', '60%');
                return;
            }

            if(discountedPrice < minSellPrice && shoppingcartitem.PriceType == 'I' && currentPrice == offeredPrice){ // warn if a-eins preis is less than mEK + 15%, block further discount

              this.dialogService.okDialog('Mindestpreisunterschreitung', "Der ermittelter  preis: ("+discountedPrice.toString()+" €) unterschreitet den Mindest-VK mEK + 17%: (" + minSellPrice.toString()+" €)", 'warning', '60%');
              shoppingcartitem.PosDiscount = 0;
              return;
            }

          this.dialogService.conformDialog(  'Mindestpreisunterschreitung',  'Der eingegebene Preis: ('+offeredPrice.toString()+' €) abzüglich aller Rabatte ('+offeredDiscountpercent.toString()+' %) unterschreitet den Mindestverkaufspreis ('+minSellPrice.toString()+' €.)\n Soll der Mindest-VK übernommen werden?',  'info',  'Nein',  'Ja' ,'50%' ).afterClosed().subscribe(
          (data) => {
          if (data != 'ok') {
            shoppingcartitem.Price = +currentPrice.toFixed(2);
            shoppingcartitem.PosDiscount = 0;
            this.goToNextRow(targetPrefix, rowIndex, 1);
            this.goToNextInput(targetPrefix, rowIndex, 1);
            //shoppingcartitem.PriceType = 'L';

          }
          else{
                if(shoppingcartitem.Product.AveragePurchasePrice == undefined){
                  this.dialogService.okDialog('Warnung: ungepflegter mEK' , ' Der mEK dieses Produktes beträgt 0,00 €. ' + 'Mindestpreis kann nicht ermittelt werden!', 'warning', '60%');
                  return;
                }else{
                shoppingcartitem.Price = +(minSellPrice / (1 - (offeredDiscountpercent / 100))).toFixed(2);
                this.goToNextRow(targetPrefix, rowIndex, 1);
                this.goToNextInput(targetPrefix, rowIndex, 1);
                }

          }

              return shoppingcartitem;
        });
      }

  }

  }

  clearRowBgColor(prefix:string, rowIndex?:number){
      let rowId = prefix + (rowIndex).toString();
      let row = document.getElementById(rowId);
      if(row){
        setTimeout(() => (row.style.backgroundColor = ''), 0);
      }
  }

  onFocusOrClickTableRow(item: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any,index?:number,totalDetailRowsCount?:number ) {

    for (let index = 0; index < totalDetailRowsCount; index++) {
      var row = document.getElementById(selfPrefix+'-'+index);
      if(row){setTimeout(() => row.style.backgroundColor = '', 0);}
    }
    var input = document.getElementById(selfPrefix+'-'+index);
    var row = document.getElementById(selfPrefix+'-'+index);
    if(input){ setTimeout(() => input.focus(), 0); }
    if(row){setTimeout(() => row.style.backgroundColor = '#ccffcc', 0);}

  }

  isOnLine(): Observable<any>{
    const Url = this.getApiUrl('custom', 'ping', null);
    let header: HttpHeaders = new HttpHeaders({'Content-Type':'application/json'});
    return this.http.get(Url).pipe(
      map(
        data =>
        {
            return data? 'ok' == data : '';
        },err =>{
          return err;
        }
      )
    );
  }


  onOdataCustomerOrderShow(productNumber: string){
    let customerNumber = this.selectedCustomer ?this.selectedCustomer.CustomerNumber:'0';
    let customerOrders: iCustomerproductorders[] = [];
    let productandcustomernumber = productNumber + '_'+ customerNumber;

    this.getDataByQuerystring('custom','CustomerOrderOdataByProductnumber', productandcustomernumber).subscribe(
      data => {
        customerOrders = data;
        this.dialogService.openCustomerOrdersDialog(customerOrders, '60%', '50%' );

      }
      ), err =>{
        console.log("Error onOdataStockUpdate", err);
      };
    }

    onOdataSuplierOrderShow(productNumber: string){
      let suplierOrders: iSupplierproductorders[] = [];

      this.getDataByQuerystring('custom','SuplierOrderOdataByProductnumber', productNumber).subscribe(
        data => {
          suplierOrders = data;
          this.dialogService.openSuplierOrdersDialog(suplierOrders, '60%', '50%' );

        }
        ), err =>{
          console.log("Error onOdataStockUpdate", err);
        };
      }



// ##############################################################################################################################

 getHtmlOutput(){

    let myListCollumns = this.listColumns;
   let myDetailCollumns = this.detailColumns;
   let modelCapital = 'Orders'
   let modelSmall = 'order'
   let formName = modelSmall+'Form'
let formCollumns = this.formColumns
let createForm ='';
let getFormData = '';
        myListCollumns.push('actions')

        myDetailCollumns.push('actions')
    let listCols: string = '';
    let detailCols: string = '';
    let ifListEditCreateMode = "'list' || mode=='edit' || mode=='create'";
    let ifDetailMode = "'detail'";
    let ifListMode = "'list'";

    myListCollumns.forEach(element => {

     createForm = createForm +'\n'+ element + ':        		['+modelSmall+' != undefined && '+modelSmall+'.'+element+' != undefined? '+modelSmall+'.'+element+' : 0, []],'

     getFormData = getFormData +'\n'+ element+' :             this.'+modelSmall+'Form.get("'+element+'").value,'

      listCols = listCols + "'"+ element + "',\n"
    });
    myDetailCollumns.forEach(element => {
      detailCols = detailCols + "'"+ element + "',\n"
    });
    let cardOutput: string = '<div class="card"> \n<div class="card-header"> \n <div class="row">\n<div class="col-10 card-title"> <p> {{item}} </p></div>\n  <div class="col-2 btn-right">\n';

    cardOutput = '<button (click)="onCreate()"  class="mat-icon-button"><span class="material-icons btn-outline-primary" matTooltip="neu erstellen">group_add</span></button>\n';
    cardOutput = '<button (click)="onList()"    class="mat-icon-button "><span class="material-icons btn-outline-primary" matTooltip="beschränkte ansicht">view_list</span></button>\n';
    cardOutput = '<button (click)="onDetail()"  class="mat-icon-button "><span class="material-icons btn-outline-primary" matTooltip="erweiterte ansicht">view_list</span></button>\n </div>\n </div>\n </div>\n\n ';

    let headerOutput = '<div class="card-body" *ngIf="mode=='+ifListMode+'">\n '  ;

    let listOutput = headerOutput + '<mat-table [dataSource]="dataSource" matSort>\n';

    myListCollumns.forEach(element => {
        if(element == 'actions'){
          listOutput = listOutput + '<ng-container matColumnDef="'+element+'">\n<mat-header-cell class="" mat-sort-header *matHeaderCellDef >' + element +'</mat-header-cell>\n<mat-cell class="" *matCellDef="let row"><button (click)="onEdit(row)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="bearbeiten">edit</i> </button><button (click)="onDelete(row)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button> </mat-cell></ng-container>\n\n'
        }else{
          listOutput = listOutput + '<ng-container matColumnDef="'+element+'">\n<mat-header-cell class="" mat-sort-header *matHeaderCellDef >' + element +'</mat-header-cell>\n<mat-cell class="" *matCellDef="let row">{{ row.'+element+' }}</mat-cell></ng-container>\n\n'
        }

      });
      listOutput = listOutput + '<mat-header-row *matHeaderRowDef="[\n';
      listOutput = listOutput + listCols;
      listOutput = listOutput + '\n]"></mat-header-row>\n'

      listOutput = listOutput + '<mat-row *matRowDef=" let row; columns: [\n';
      listOutput = listOutput + listCols;
      listOutput = listOutput + '\n] "></mat-row>\n'

      listOutput = listOutput + '\n</mat-table> \n <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>\n'
      listOutput = listOutput + '\n</div>\n'


      headerOutput = '<div class="card-body" *ngIf="mode=='+ifDetailMode+'">\n '  ;
      let detailOutput = headerOutput + '<mat-table [dataSource]="dataSource" matSort>\n\n';

      myDetailCollumns.forEach(element => {
        if(element == 'actions'){
          detailOutput = detailOutput + '<ng-container matColumnDef="'+element+'">\n<mat-header-cell class="" mat-sort-header *matHeaderCellDef >' + element +'</mat-header-cell>\n<mat-cell class="" *matCellDef="let row"><button (click)="onEdit(row)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="bearbeiten">edit</i> </button><button (click)="onDelete(row)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button> </mat-cell></ng-container>\n\n'
        }else{
          detailOutput = detailOutput + '<ng-container matColumnDef="'+element+'">\n<mat-header-cell class="" mat-sort-header *matHeaderCellDef >' + element +'</mat-header-cell>\n<mat-cell class="" *matCellDef="let row">{{ row.'+element+' }}</mat-cell></ng-container>\n\n'
        }

      });
      detailOutput = detailOutput + '<mat-header-row *matHeaderRowDef="[\n';
      detailOutput = detailOutput + detailCols;
      detailOutput = detailOutput + '\n]"></mat-header-row>\n'

      detailOutput = detailOutput + '<mat-row *matRowDef=" let row; columns: [\n';
      detailOutput = detailOutput + detailCols;
      detailOutput = detailOutput + '\n] "></mat-row>\n'

      detailOutput = detailOutput + '\n</mat-table>\n <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>'
      detailOutput = detailOutput + '\n</div>\n'

      let formOutput = this.getCreateForm(formCollumns, formName);

      let output = cardOutput + listOutput + detailOutput + formOutput + '</div>\n'

      output = output + '\n\n\n' + createForm
      output = output + '\n\n\n' + getFormData

      console.log("output:-  ", output);

    }

getCreateForm(fields: string[], formName: string): string{

  let ifEdit = "'edit'";
  let ifCreate = "'create'";
  let output = '<div class="card-body" *ngIf="mode=='+ifEdit+' || mode=='+ifCreate+'">\n'
  output = output + ' <form  [formGroup]="'+formName+'" >\n';
  let n = 0;
  fields.forEach(element => {
    console.log('elements', element.split(':',2))
    console.log('0', element.split(':',2)[0])
   console.log('1', element.split(':',2)[1])
    let row = "";
    let field = element.split(':',2)[0].trim().replace('?','');
    let cuotedField = "'"+field+"'";
    let type = element.split(':',2)[1].toLowerCase().trim();
    if(n % 2  == 0){ output = output + '\n<div class="row">'}

    if(type == 'string'){
      output = output + '\n <div class="col-6">\n <mat-form-field appearance="outline" class="w-100 ">\n <input matInput formControlName="'+field+'" placeholder="'+field+'"> \n<mat-error class="error-block" *ngIf="!'+formName+'.get('+cuotedField+').valid && ('+formName+'.get('+cuotedField+').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>\n  </div>';
    }else if(type == 'number'){
      output = output + '\n <div class="col-6">\n <mat-form-field appearance="outline" class="w-100 ">\n <input matInput type="number" lang="de" formControlName="'+field+'" placeholder="'+field+'">  \n<mat-error class="error-block" *ngIf="!'+formName+'.get('+cuotedField+').valid && ('+formName+'.get('+cuotedField+').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>\n  </div>';
    }else if(type == 'number*'){
      output = output + '\n <div class="col-6">\n <mat-form-field appearance="outline" class="w-100" appearance="outline">\n <mat-select class="form-control" formControlName="'+field+'" placeholder="'+field+'">  \n<mat-option *ngFor="let '+field+' of '+field+'s" [value]="'+field+'.'+field+'">{{ '+field+'.'+field+' }}</mat-option> </mat-select> \n<span class="error-block" *ngIf="!'+formName+'.get('+cuotedField+').valid && ('+formName+'.get('+cuotedField+').touched  || formSubmited)">Ungültiger Eintrag</span> </mat-form-field>\n  </div>';
    }else if(type == 'boolean'){
      output = output + '\n <div class="col-6">\n <section >\n <mat-checkbox formControlName="'+field+'" placeholder="'+field+'"> '+field+' </mat-checkbox>\n<span class="error-block" *ngIf="!'+formName+'.get('+cuotedField+').valid && ('+formName+'.get('+cuotedField+').touched  || formSubmited)">Ungültiger Eintrag</span> </section>\n </div>';
    }else if(type == 'date'){
      output = output + '\n <div class="col-6">\n <mat-form-field appearance="outline" appearance="outline" class="w-100">\n <input matInput formControlName="'+field+'" [matDatepicker]="'+field+'" > <mat-datepicker-toggle matSuffix [for]="'+field+'"></mat-datepicker-toggle>\n<span class="error-block" *ngIf="!'+formName+'.get('+cuotedField+').valid && ('+formName+'.get('+cuotedField+').touched  || formSubmited)">Ungültiger Eintrag</span> \n<mat-datepicker #'+field+'></mat-datepicker>\n</mat-form-field>\n </div>';
    }

    if(n % 2  != 0 || n == fields.length){ output = output +  '\n</div>'}
    n++;
  });

    output = output +'\n  <div class="row">\n<div class="col-6"><div class="form-group"><button (click)="onReset()" type="reset" class="btn btn-secondary ">Zurücksetzen</button></div></div><div class="col-6"><div class="form-group"><button (click)="onSubmit()" type="button" class="btn btn-primary">Speichern</button></div>\n </div>\n  </div>'

  output = output + '\n </form>';
  output = output + '\n </div>\n';

  return output;

}



  listColumns = [
    'OrdersId',
    'ShoppingCartsId',
    'PreparedBy',
    'OrderedBy',
    'OrderedFor',
    'DeliveryAddress',
    'InvoiceAddress',
    'OrderStatesId',
    'Comment'
  ];
  detailColumns = [
    'OrdersId',
    'ShoppingCartsId',
    'PreparedBy',
    'OrderedBy',
    'OrderedFor',
    'DeliveryAddress',
    'InvoiceAddress',
    'OrderStatesId',
    'Comment'
  ];
  formColumns = [
    'OrdersId?: number',
    'ShoppingCartsId: number',
    'PreparedBy: number',
    'OrderedBy: number',
    'OrderedFor?: number',
    'DeliveryAddress?: number',
    'InvoiceAddress?: number',
    'OrderStatesId?: number*',
    'Comment?:string'
  ];

}
function keys<T>() {
  throw new Error( 'Function not implemented.' );
}


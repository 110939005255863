import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { iAdditives, iInputOptions } from '../models/AlaskaModels';
import { DataService } from '../Services/data.service';

@Component({
  selector: 'app-additivesControl',
  templateUrl: './additivesControl.component.html',
  styleUrls: ['./additivesControl.component.scss']
})
export class AdditivesControlComponent implements OnInit {


  inputOptions: iInputOptions;
  additives: iAdditives[]= [];
  checkedExcludeValues = new Set<string>();

  additiveFormControl = new FormControl('');

  constructor(private dataService: DataService) { }

  ngOnInit() {


  this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));

    if(this.inputOptions != null){
      this.additives = this.inputOptions['additives'];
      console.log("this.additives: ",  this.additives);
    }

  }

  onClick(){
    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
    if(this.inputOptions != null){
      this.additives = this.inputOptions['additives'];
    }
  }

  onSendExcludeMessage(selectedAdditivesArray: any){
    // let checkedExcludeValues = new Set<string>();
    // if(change && change.source._selected == true){
    //   this.checkedExcludeValues.add(change.source.value.trim().toLowerCase());
    // }
    // if(change && change.source._selected == false){
    //   this.checkedExcludeValues.delete(change.source.value.trim().toLowerCase());
    // }


    // this.dataService.setMsgSourceSubject( 'applyExcludeFilter',  'Type',  'topPannel',  'Target',  'Action', [...this.checkedExcludeValues].join(','),  undefined,  0 );
    this.dataService.setMsgSourceSubject( 'applyExcludeFilter',  'Type',  'topPannel',  'Target',  'Action', selectedAdditivesArray.join(',').toLowerCase(),  undefined,  0 );


  }


}




<div class="toolbar hidden-print">
  <div class="text-right">
    <button (click)="onCancel()" class="btn btn-secondary" [mat-dialog-close]="true"><i class="material-icons">cancel</i></button>
    <button (click)="onCreatePdf()" class="btn btn-primary"  [mat-dialog-close]="false"><i class="material-icons">save</i></button>

  </div>
  <hr>
</div>

<div id="pdfContent">

<div class="invoice overflow-auto">
  <div style="min-width: 600px">
      <header>
          <div class="row">
              <div class="col">
                  <a target="_blank" href="https://alaska-tkk.de">
                      <img src="../../assets/icons/logo_alaska.jpg" data-holder-rendered="true" />
                      </a>
              </div>
              <div class="col company-details">
                  <h2 class="col-md-12 name">
                      <a target="_blank" href="https://alaska-tkk.de">
                      Alaska GmbH
                      </a>
                  </h2>
                  <div class="col-md-12">Alaska TKK</div>
                  <div class="col-md-12">Kauritzerstr. 29</div>
                  <div class="col-md-12">04639 Gößnitz</div>
                  <div class="col-md-12">Tel: 034493/22588</div>
                  <div class="col-md-12">Fax: 034493/36532</div>
                  <div class="col-md-12">Steuer-Nr. 16110502854</div>
                  <div class="col-md-12">USt-Id-Nr. DE 150510865</div>
                  <div class="col-md-12">alaska-tkk@t-online.de</div>
                  <div class="col-md-12">www.alaskaonline.de</div>
              </div>
          </div>
      </header>
      <main>
          <div class="row contacts">
              <div class="col invoice-to">

                  <h1 class="to">Bestellung von {{order.ShoppingCarts.Customer.Name1}} am {{order.ShoppingCarts.OrderDate | date: 'dd.MM.yyyy' }}</h1>


              </div>

          </div>
      </main>
    </div>
  </div>

<div  class="mat-elevation-z8 table-striped">
  <mat-table [dataSource]="datasource" matSort>
    <ng-container matColumnDef="ProductId">
      <mat-header-cell class="m-1" mat-sort-header *matHeaderCellDef >Id</mat-header-cell>
      <mat-cell class="m-1" *matCellDef="let row">{{ row.Product.ProductId }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="DescRow1">
      <mat-header-cell class="m-1" *matHeaderCellDef>DescRow1</mat-header-cell>
      <mat-cell class="m-1" *matCellDef="let row">{{ row.Product.DescRow1 }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="Quantity">
      <mat-header-cell class="m-1" *matHeaderCellDef>
        Menge
      </mat-header-cell>
      <mat-cell class="m-1" *matCellDef="let row">{{ row.Quantity}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="PosDiscount">
      <mat-header-cell class="m-1" *matHeaderCellDef>
        PosRabatt
      </mat-header-cell>
      <mat-cell class="m-1" *matCellDef="let row">{{ row.PosDiscount}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="TotalDiscount">
      <mat-header-cell class="m-1" *matHeaderCellDef>
        Ges. Rabatt
      </mat-header-cell>
      <mat-cell class="m-1" *matCellDef="let row">{{ row.TotalDiscount}}</mat-cell>

    </ng-container>

    <ng-container matColumnDef="Price">
      <mat-header-cell class="m-1" *matHeaderCellDef>
        Price
      </mat-header-cell>
      <mat-cell class="m-1" *matCellDef="let row">{{ row.Price | currency:'EUR':true}}</mat-cell>

    </ng-container>

    <ng-container matColumnDef="Summe">
      <mat-header-cell class="m-1" *matHeaderCellDef>
        Summe
      </mat-header-cell>
      <mat-cell class="m-1" *matCellDef="let row">{{ row.Price * row.Quantity | currency:'EUR':true}}</mat-cell>


    </ng-container>

    <mat-header-row *matHeaderRowDef="[ 'ProductId', 'DescRow1', 'Quantity', 'PosDiscount', 'TotalDiscount', 'Price', 'Summe' ]"></mat-header-row>
    <mat-row *matRowDef=" let row; columns: [ 'ProductId', 'DescRow1', 'Quantity', 'PosDiscount', 'TotalDiscount', 'Price', 'Summe' ] "></mat-row>

  </mat-table>



</div>



</div>

import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatDivider} from '@angular/material/divider';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-deliveryDateDialog',
  templateUrl: './deliveryDateDialog.component.html',
  styleUrls: ['./deliveryDateDialog.component.scss']
})
export class DeliveryDateDialogComponent implements OnInit {

  title: string;
  type: string;
  msg: string;
  noLabel: string;
  yesLabel:string;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public conformDialogRef: MatDialogRef<DeliveryDateDialogComponent>) { }

  ngOnInit() {
    this.title = this.data.title;
    this.type = this.data.type; //danger, warning, info
    this.msg = this.data.msg;
    this.noLabel =  this.data.noLabel;;
    this.yesLabel = this.data.yesLabel;;

// this.conformDialogRef.afterOpened().pipe(take(1))
// this.conformDialogRef.afterClosed().pipe(take(1))

  }

  ngAfterViewInit(){

}
  onCancel(){
    this.conformDialogRef.close();
  }
  onSave(){
    return this.conformDialogRef.close('ok');
  }
}

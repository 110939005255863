import { Component, OnInit, Input, Renderer2, ElementRef, ViewChild, AfterViewInit,QueryList, ViewChildren } from '@angular/core';

import { iMenue, iProductcategories, iProducts,iProductCategoryDropDown } from '../models/AlaskaModels';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import {NgbdSortableHeader, SortEvent} from './sortable.directive';
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../Services/data.service';
import html2canvas from 'html2canvas';
import { CloseScrollStrategy } from '@angular/cdk/overlay';
import { MatMenu } from '@angular/material/menu';



declare const mytest: any;
declare const myalert: any;


const ELEMENT_DATA: Array<iProducts> = [];

@Component({
  selector: 'app-productCategory',
  templateUrl: './productCategory.component.html',
  // template: '<div> <button appearance="outline" mat-stroked-button class=" but95 "  mat-button [matMenuTriggerFor]="parent_"> Alle Warengruppen . . .</button>  <mat-menu #parent_="matMenu">  <button mat-menu-item (click)="onProductCategoryChanged()"><b>Alle Warengruppen . . .</b></button>  <span *ngFor="let item1 of sortedProductCategories">    <button *ngIf="item1.ParentId == null && childrenIds.includes(item1.ProductcategoriesId)" mat-menu-item [matMenuTriggerFor]=trigerFor(item1.ProductcategoriesId)>{{item1.Name}}</button>    <button *ngIf="item1.ParentId == null && !childrenIds.includes(item1.ProductcategoriesId)" mat-menu-item (click)="onProductCategoryChanged(item1.ProductcategoriesId)">{{item1.Name}}</button>  </span></mat-menu> <span *ngFor="let p of parent">    <mat-menu *ngIf="parentHasChildren(p) == true" #m1="matMenu">      <span *ngFor="let c1 of getchildrenByParent(p)">        <button  mat-menu-item [matMenuTriggerFor]="trigerFor2(c1.ProductcategoriesId)" >{{c1.Name}}</button></span>    </mat-menu > </span></div>',
  styleUrls: ['./productCategory.component.scss']
})
export class ProductCategoryComponent implements OnInit {


  @Input() Productcategories: Array<iProductcategories> = [];
  @ViewChildren(NgbdSortableHeader) headers:  QueryList<NgbdSortableHeader>;
  refreshed: boolean = false;
parent_ = 'parent_'
  selectedProductCategory: iProductcategories;
  products: Array<iProducts> = [];
  datasource = new MatTableDataSource(Array<iProducts>());

  text: any;
@ViewChild('spanContent') spanContent;
@ViewChild('routerLink') routerLink;
@ViewChild(MatSort) sort: MatSort;
@ViewChild('dropDown') dropDown: string;


html:SafeHtml;
hh = 'hihi';
total$: Observable<number>;

sortedProductCategories: Array<iProductcategories> = [];
parent: Array<iProductcategories> = [];
child1: Array<iProductcategories> = [];
child2: Array<iProductcategories> = [];
child3: Array<iProductcategories> = [];
// childrenIds: number[] = [];
navItems: iProductCategoryDropDown[] = [];
items: iProductCategoryDropDown[] = [];


navItems2: iProductCategoryDropDown[] = [
  {
    Name: 'AngularMix',
    Icon: 'close',
    Children: [
      {
        Name: 'Speakers',
        Icon: 'group',
        Children: [
          {
            Name: 'Michael Prentice',
            Icon: 'person',
            Route: 'michael-prentice',
            Children: [
              {
                Name: 'Delight your Organization',
                Icon: 'star_rate',
                Route: 'material-design'
              }
            ]
          },
          {
            Name: 'Stephen Fluin',
            Icon: 'person',
            Route: 'stephen-fluin',
            Children: [
              {
                Name: 'What\'s up with the Web?',
                Icon: 'star_rate',
                Route: 'what-up-web'
              }
            ]
          },
          {
            Name: 'Mike Brocchi',
            Icon: 'person',
            Route: 'mike-brocchi',
            Children: [
              {
                Name: 'My ally, the CLI',
                Icon: 'star_rate',
                Route: 'my-ally-cli'
              },
              {
                Name: 'Become an Angular Tailor',
                Icon: 'star_rate',
                Route: 'become-angular-tailer'
              }
            ]
          }
        ]
      },
      {
        Name: 'Sessions',
        Icon: 'speaker_notes',
        Children: [
          {
            Name: 'Delight your Organization',
            Icon: 'star_rate',
            Route: 'material-design'
          },
          {
            Name: 'What\'s up with the Web?',
            Icon: 'star_rate',
            Route: 'what-up-web'
          },
          {
            Name: 'My ally, the CLI',
            Icon: 'star_rate',
            Route: 'my-ally-cli'
          },
          {
            Name: 'Become an Angular Tailor',
            Icon: 'star_rate',
            Route: 'become-angular-tailer'
          }
        ]
      },
      {
        Name: 'Feedback',
        Icon: 'feedback',
        Route: 'feedback'
      }
    ]
  }
];

  constructor(private route: ActivatedRoute,
              private renderer: Renderer2,
              private element: ElementRef,
              private sanitizer: DomSanitizer,
              private dataService: DataService ) {  }

  getSelectedProductCategory():iProductcategories{
    return this.selectedProductCategory;
  }

  sbuildMenue:string;

   ngOnInit() {


    this.setDropDown();


    // this.sortedProductCategories = [];
    // this.sortedProductCategories = this.Productcategories.sort((a, b) => +a.Index - +b.Index );
    // this.setProductCategoryDropDownItems();

    // this.sortedProductCategories.filter(x => x.ParentId != null).forEach(element => { if(!this.childrenIds.includes(element.ParentId)){ this.childrenIds.push(element.ParentId)}  });
    // this.createDropDownHtmlTag();

  }

  getProductCategoryDropDown(p: iProductcategories):iProductCategoryDropDown{
    return {
      Name: p.Name,
      Disabled: false,
      Icon: p.Icon,
      Route: '',
      ProductcategoriesId: p.ProductcategoriesId,
      ParentId: p.ParentId,
      Index: p.Index,
      Children: []
    }
  }


  setProductCategoryDropDownItems(): string{
    let parentDropDowns: iProductCategoryDropDown[] = [];
    let allProductCategoryDropDown: iProductCategoryDropDown = {Name:'Alle Warengruppen . . .',Disabled:false,Icon:'',Route:'',ProductcategoriesId:0,ParentId:null,Index:0,Children:[]};
    parentDropDowns.push(allProductCategoryDropDown);

    this.sortedProductCategories.filter(x => x.ParentId == null).forEach(parent => {

      let parentDropDown: iProductCategoryDropDown = this.getProductCategoryDropDown(parent)
      let children1ProductCategory: iProductcategories[] = this.sortedProductCategories.filter(x => x.ParentId == parent.ProductcategoriesId);
      children1ProductCategory.forEach(child1ProductCategory => {
        let childr1DropDown: iProductCategoryDropDown = this.getProductCategoryDropDown(child1ProductCategory)
        parentDropDown.Children.push(childr1DropDown);

          let children2ProductCategory: iProductcategories[] = this.sortedProductCategories.filter(x => x.ParentId == child1ProductCategory.ProductcategoriesId);
          children2ProductCategory.forEach(child2ProductCategory => {
            let childr2DropDown: iProductCategoryDropDown = this.getProductCategoryDropDown(child2ProductCategory)
            childr1DropDown.Children.push(childr2DropDown);

            let children3ProductCategory: iProductcategories[] = this.sortedProductCategories.filter(x => x.ParentId == child2ProductCategory.ProductcategoriesId);
            children3ProductCategory.forEach(child3ProductCategory => {
              let childr3DropDown: iProductCategoryDropDown = this.getProductCategoryDropDown(child3ProductCategory)
              childr2DropDown.Children.push(childr3DropDown);
            });

          });
      });

      parentDropDowns.push(parentDropDown);
    })
    this.navItems = parentDropDowns
    this.items = parentDropDowns;
      return JSON.stringify(parentDropDowns)
  }

  ngAfterViewInit(){
    console.log("Afterviewinit");

  }

  createDropDownHtmlTag(): string{

    let menue:string = '<button appearance="outline" mat-stroked-button class=" but95 "  mat-button [matMenuTriggerFor]="parent_"> Alle Warengruppen . . .</button>\n';
    menue = menue + '<mat-menu #parent_="matMenu">\n';
    menue = menue + '<button mat-menu-item (click)="onProductCategoryChanged()"><b>Alle Warengruppen . . .</b></button>\n';
    menue = menue + '\n';

    let parentIds: number[] = [];
    let child1Ids: number[] = [];

    let child2Ids: number[] = [];
    let child3Ids: number[] = [];
    let childrenIds: number[] = [];
    this.Productcategories.filter(x => x.ParentId != null).forEach(element => {
      if(!(parentIds.includes(element.ProductcategoriesId)))
      {
        childrenIds.push(element.ProductcategoriesId)}
      });
    // this.Productcategories.filter(x => x.ParentId != null).forEach(element => { if(!childrenIds.includes(element.ParentId)){ childrenIds.push(element.ParentId)}  });
    let childrenProductCategories: iProductcategories[] = this.Productcategories.filter(x => x.ParentId != null);
    let children: iProductcategories[] = [];
    // let sortedProductcategories: iProductcategories[] = this.Productcategories.sort((a, b) => +a.Index - +b.Index );
    this.Productcategories.filter(x => x.ParentId == null).sort((a, b) => +a.Index - +b.Index ).forEach(item1 => {

        if(this.Productcategories.find(x => x.ParentId == item1.ProductcategoriesId) != null){
          menue = menue + '<button mat-menu-item [matMenuTriggerFor]="m'+item1.ProductcategoriesId+'">'+item1.Name+'</button>\n';
        }
        else if(this.Productcategories.find(x => x.ParentId == item1.ProductcategoriesId) == null){
          menue = menue + '<button mat-menu-item (click)="onProductCategoryChanged('+item1.ProductcategoriesId+')">'+item1.Name+'</button>\n';
        }

    });

      menue = menue + '</mat-menu>\n';

      this.parent.sort((a, b) => +a.Index - +b.Index ).forEach(parent => {
        if(this.child1.filter(x => x.ParentId == parent.ProductcategoriesId).length > 0){


        menue = menue + '<mat-menu #m'+parent.ProductcategoriesId+'="matMenu">\n';
        this.child1.filter(x => x.ParentId == parent.ProductcategoriesId).sort((a, b) => +a.Name - +b.Name ).forEach(c1 => {
          if(this.child2.find( x => x.ParentId ==  c1.ProductcategoriesId)){
            menue = menue + '<button mat-menu-item [matMenuTriggerFor]="m'+c1.ProductcategoriesId+'">'+c1.Name+'</button>\n';
          }
          else if(this.child2.find( x => x.ParentId !=  c1.ProductcategoriesId)){
            menue = menue + '<button mat-menu-item (click)="onProductCategoryChanged('+c1.ProductcategoriesId+')">'+c1.Name+'</button>\n';
          }
        });
        menue = menue + '</mat-menu>\n';
  }
      });

      this.child1.sort((a, b) => +a.Index - +b.Index ).filter(x => x.ParentId != null).forEach(c1 => {
        if(this.child2.filter(x => x.ParentId == c1.ProductcategoriesId).length > 0){

        menue = menue + '<mat-menu #m'+c1.ProductcategoriesId+'="matMenu">\n';
        this.child2.filter(x => x.ParentId == c1.ProductcategoriesId).sort((a, b) => +a.Name - +b.Name ).forEach(c2 => {
          if(this.child3.find( x => x.ParentId ==  c2.ProductcategoriesId)){
            menue = menue + '<button mat-menu-item [matMenuTriggerFor]="m'+c2.ProductcategoriesId+'">'+c2.Name+'</button>\n';
          }
          else if(this.child3.find( x => x.ParentId !=  c1.ProductcategoriesId)){
            menue = menue + '<button mat-menu-item (click)="onProductCategoryChanged('+c2.ProductcategoriesId+')">'+c2.Name+'</button>\n';
          }
        });
        menue = menue + '</mat-menu>\n';

  }
      });

      this.child2.sort((a, b) => +a.Index - +b.Index ).filter(x => x.ParentId != null).forEach(c2 => {
        if(this.child3.filter(x => x.ParentId == c2.ProductcategoriesId).length > 0){


        menue = menue + '<mat-menu #m'+c2.ProductcategoriesId+'="matMenu">\n';
        this.child3.filter(x => x.ParentId == c2.ProductcategoriesId).sort((a, b) => +a.Name.substring(1,3) - +b.Name.substring(1,3) ).forEach(c3 => {
            menue = menue + '<button mat-menu-item (click)="onProductCategoryChanged('+c3.ProductcategoriesId+')">'+c3.Name+'</button>\n';
        });
        menue = menue + '</mat-menu>\n';
 }
      });


this.dropDown = menue;

    // let productCategoryDropDown = document.getElementById('productCategoryDropDown')
    // if(productCategoryDropDown != undefined){
    //   productCategoryDropDown.innerHTML = menue;
    // }
    return menue;
  }

setDropDown(){

  if(localStorage.getItem('Productcategories')){
    this.Productcategories = JSON.parse(localStorage.getItem('Productcategories'));
    this.sortedProductCategories = this.Productcategories.sort((a, b) => +a.Index - +b.Index );
    this.setProductCategoryDropDownItems();
    this.setParent(this.Productcategories);
    this.setChildren(this.Productcategories,this.parent,this.child1);
    this.setChildren(this.Productcategories,this.child1,this.child2);
    this.setChildren(this.Productcategories,this.child2,this.child3);
  }else{
    this.dataService.getData( 'custom','getProductcategories').subscribe(
      data => {
        this.Productcategories = data;
        this.Productcategories.sort((a, b) => +a.Name - +b.Name );
        localStorage.setItem('Productcategories',JSON.stringify(this.Productcategories));
        this.sortedProductCategories = this.Productcategories.sort((a, b) => +a.Index - +b.Index );
        this.setProductCategoryDropDownItems();
        this.setParent(this.Productcategories);
        this.setChildren(this.Productcategories,this.parent,this.child1);
        this.setChildren(this.Productcategories,this.child1,this.child2);
        this.setChildren(this.Productcategories,this.child2,this.child3);

      }), error => {
      console.log("error productCategory",error);
      }
    }
}

  setParent(productCategories: Array<iProductcategories>)
  {
   this.parent = productCategories.filter(x => x.ParentId === null).sort((a, b) => +a.Name - +b.Name );
  }
  onProductsCategoryChanged(productCategory?: iProductcategories){

    let productTab = document.getElementById("mat-tab-label-0-0");
    if(productTab){ setTimeout(() => productTab.click(),0); }

    this.dataService.setProductCategorySubject(productCategory);
  }

  onProductCategoryChanged(productCategoryId?: number){

    let productTab = document.getElementById("mat-tab-label-0-0");
    if(productTab){ setTimeout(() => productTab.click(),0); }
    let productCategory: iProductcategories = this.Productcategories.find(x => x.ProductcategoriesId == productCategoryId)

      this.dataService.setProductCategorySubject(productCategory);
  }

  resetProductCategory() {
    this.dataService.setProductCategorySubject(undefined);
  }

  setChildren(data: iProductcategories[], closeParent: Array<iProductcategories>, fillToChild: Array<iProductcategories>)
  {

   for(let i =0; i < closeParent.length; i++){
     let closeParentId = closeParent[i].ProductcategoriesId;
     let children: Array<iProductcategories> = data.filter(x => x.ParentId === closeParentId).sort((a, b) => +a.Name - +b.Name );

      if(children != undefined){
       children.forEach(x => fillToChild.push(x));
     }
   }

  }

  sortByName(datasource: any[], sortBy:string):any[] {

    return datasource.sort((a, b) => a[sortBy]> b[sortBy]? 1 : a[sortBy] === b[sortBy] ? 0 : -1);
  }



// getmenue(name?:string):string{
// let menue = '';
//   let parentmenue:string = '<mat-menu #parent_="matMenu">'+'\n'+'<button mat-menu-item (click)="onProductCategoryChanged()">Alle Warengruppen . . .</button>';
//   let child1menue:string = '';
//   let child2menue:string = '';
//   let child3menue:string = '';

//   this.parent = this.sortByName(this.parent,'Index' );
//   this.child1 = this.sortByName(this.child1,'Index' );
//   this.child2 = this.sortByName(this.child2,'Index' );
//   this.child3 = this.sortByName(this.child3,'Index' );

//   this.parent.forEach(p => {
//     // ###
//     let c1 = this.child1.find(x => x.ParentId == p.ProductcategoriesId);
//     if(c1){
//       parentmenue = parentmenue +'\n' + '<button mat-menu-item [matMenuTriggerFor]="m'+ c1.ProductcategoriesId + '">' + p.Name + '</button>'
//       child1menue = child1menue +'\n'+ '<mat-menu #m'+ c1.ProductcategoriesId +'="matMenu">';
//         this.child1.forEach(cc1 => {
//           if(cc1.ParentId == p.ProductcategoriesId){
//             let c2 = this.child2.find(x => x.ParentId == cc1.ProductcategoriesId);
//             if(c2){

//               child1menue = child1menue +'\n'+ '<button mat-menu-item [matMenuTriggerFor]="m'+ c2.ProductcategoriesId + '">' + cc1.Name + '</button>'

//                 //2###
//                 child2menue = child2menue +'\n'+ '<mat-menu #m'+ c2.ProductcategoriesId +'="matMenu">';
//                 this.child2.forEach(cc2 => {
//                   if(cc2.ParentId == cc1.ProductcategoriesId){
//                     let c3 = this.child3.find(x => x.ParentId == cc2.ProductcategoriesId);
//                     if(c3){

//                       child2menue = child2menue +'\n'+ '<button mat-menu-item [matMenuTriggerFor]="m'+ c3.ProductcategoriesId + '">' + cc2.Name + '</button>'

//                       //3###
//                       child3menue = child3menue +'\n'+ '<mat-menu #m'+ c3.ProductcategoriesId +'="matMenu">';
//                       this.child3.forEach(cc3 => {
//                         if(cc3.ParentId == cc2.ProductcategoriesId){
//                           let c4 = this.Productcategories.find(x => x.ParentId == cc3.ProductcategoriesId);
//                           if(c4){
//                           }
//                           else{
//                             child3menue = child3menue +'\n'+ '<button mat-menu-item (click)="onProductCategoryChanged('+cc3.ProductcategoriesId+')">' + cc3.Name + '</button>'
//                           }
//                         }
//                       });
//                       child3menue = child3menue +'\n'+ '</mat-menu>';
//                       //3###


//                     }
//                     else{
//                       child2menue = child2menue +'\n'+ '<button mat-menu-item (click)="onProductCategoryChanged('+cc2.ProductcategoriesId+')">' + cc2.Name + '</button>'
//                     }
//                   }
//                 });
//                 child2menue = child2menue +'\n'+ '</mat-menu>';
//                 //2###

//             }
//             else{
//               child1menue = child1menue +'\n'+ '<button mat-menu-item (click)="onProductCategoryChanged('+cc1.ProductcategoriesId+')">' + cc1.Name + '</button>'
//             }
//           }
//         });
//       child1menue = child1menue +'\n'+ '</mat-menu>';


//     }
//     //###
//     else{
//       parentmenue = parentmenue +'\n'+ '<button mat-menu-item (click)="onProductCategoryChanged('+p.ProductcategoriesId+')">' + p.Name + '</button>'
//     }
//   });
//   parentmenue = parentmenue +'\n' + '</mat-menu>';


//   console.log(parentmenue +'\n'+ child1menue +'\n'+ child2menue +'\n'+ child3menue);
//   return parentmenue +'\n'+ child1menue +'\n'+ child2menue +'\n'+ child3menue;

// }


// getname(name?:string):string{
//   let menue = '';
//     let parentmenue:string = '<mat-menu #parent_="matMenu">';
//     let child1menue:string = '';

//     this.parent.forEach(p => {
//       // ###
//       let c1 = this.child1.find(x => x.ParentId == p.ProductcategoriesId);
//       if(c1){
//         parentmenue = parentmenue +'\n' + '<button mat-menu-item [matMenuTriggerFor]="m'+ c1.ProductcategoriesId + '">' + p.Name + '</button>'
//         child1menue = child1menue +'\n'+ '<mat-menu #m'+ c1.ProductcategoriesId +'="matMenu">';
//           this.child1.forEach(cc1 => {
//             if(cc1.ParentId == p.ProductcategoriesId){
//               let c2 = this.child2.find(x => x.ParentId == c1.ProductcategoriesId);
//               if(c2){

//                 child1menue = child1menue +'\n'+ '<button mat-menu-item [matMenuTriggerFor]="m'+ c2.ProductcategoriesId + '">' + cc1.Name + '</button>'
//               }
//               else{
//                 child1menue = child1menue +'\n'+ '<button mat-menu-item >' + cc1.Name + '</button>'
//               }
//             }
//           });
//         child1menue = child1menue +'\n'+ '</mat-menu>';
//       }
//       //###
//       else{
//         parentmenue = parentmenue +'\n'+ '<button mat-menu-item >' + p.Name + '</button>'
//       }
//     });
//     parentmenue = parentmenue +'\n' + '</mat-menu>';

//     return parentmenue +'\n'+ child1menue;


//   }


  // items = [
  //   {
  //     name: 'One'
  //   },
  //   {
  //     name: 'Two',
  //     children: [
  //       {
  //         name: 'TwoOne',
  //         children: [
  //           {
  //             name: 'TwoOneOne'
  //           }
  //         ]
  //       },
  //       {
  //         name: 'TwoTwo',
  //         children: [
  //           {
  //             name: 'TwoTwoOne'
  //           },
  //                       {
  //             name: 'TwoTwoTwo'
  //           }
  //         ]
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Three'
  //   }
  // ]

}






<mat-table [dataSource]="datasource" matSort >
  <ng-container matColumnDef="UsersId">
  <mat-header-cell class="" mat-sort-header *matHeaderCellDef >UsersId</mat-header-cell>
  <mat-cell class="" *matCellDef="let row">{{ row.UsersId }}</mat-cell>
</ng-container>
  <mat-header-row *matHeaderRowDef="[   'UsersId'  ]"></mat-header-row>
  <mat-row *matRowDef=" let row; columns: [  'UsersId'   ] "></mat-row>

  </mat-table>




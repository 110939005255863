<div class="navcontainer fr ">
  <div class="z1000">
    <div *ngIf="!logedIn()">
      <ul class="">
        <li class=""> <a class="ptr" (click) = "onRegister()">Registrierung</a>  </li>
        <li class=""> <a class="ptr" (click) = "onLogin()">Login</a> </li>
      </ul>
    </div>
    <div *ngIf="logedIn()" class="  z1000" dropdown  >
      <div *ngIf="loggedInUser!=undefined"><a  href="#" class="dropdown-toggle text-light" dropdownToggle (click)="false"><span class="hidebelow360">Herzlich willkommen: </span>{{loggedInUser.FirstName}} {{loggedInUser.LastName}}</a></div>
      <div *dropdownMenu class="dropdown-menu">
        <a (click) = "onMyProfile()" class="dropdown-item ptr"  ><i class="far fa-id-card"></i> mein Profil</a>
        <li class="divider dropdown-divider"></li>
        <a (click) = "onChangePassowrd()" class="dropdown-item ptr" ><i class="far fa-edit "></i> Passwort ändern</a>
        <li class="divider dropdown-divider"></li>
        <a routerLink="/" class="dropdown-item" (click)="onReset()" ><i class="fas fa-sign-out-alt"></i> Zurücksetzen</a>
        <li class="divider dropdown-divider"></li>
        <a routerLink="/" class="dropdown-item" (click)="onLogOut()" ><i class="fas fa-sign-out-alt"></i> Logout</a>
        <li class="divider dropdown-divider"></li>
        <span class="dropdown-item" ><i class=""></i>ver.: 1.030522</span>
      </div>
    </div>
  </div>
</div>

<!-- --------------------------------------------------------------------------------------------- -->







import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { DataService} from '../../Services/data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { iCustomers, iMaterialgroups, iOrders, iRegistrations, iRegistrationstypes, iRoles, iShoppingcartitems, iShoppingcarts, iUsers } from 'src/app/models/AlaskaModels';

import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

const USERDATA: Array<iUsers> = [];

@Component({
  selector: 'app-ProfileDialog',
  templateUrl: './ProfileDialog.component.html',
  styleUrls: ['./ProfileDialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {

  order: iOrders;
  user: iUsers;
  selectedOrder: iOrders;
  selectedCustomer: iCustomers;
  selectedCustomerName:string;
  selectedShoppingCartsName:string;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(USERDATA);
  registrationType: iRegistrationstypes;
  role: iRoles;
  pipe = new DatePipe('de-DE');
  smallDeviceSize:number = 1000;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dataService:DataService,
              public infoInputDialogRef: MatDialogRef<ProfileDialogComponent>,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit() {

    this.selectedCustomer = this.data  && this.data.customer? this.data.customer: null;
    this.order = this.data  && this.data.order? this.data.order: null;
    this.user = this.data  && this.data.user? this.data.user: null;
    this.registrationType = this.data && this.data.registrationType? this.data.registrationType: null;
    this.role = this.data && this.data.role? this.data.role: null;

  }

  onChangeDeviceModeInput(){
    localStorage.setItem('smallDeviceSize', this.smallDeviceSize.toString());
  }

}

import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import { iCustomers, iOrders, iShoppingcartitems, iShoppingcarts,iOpenitems, iCustomerproductorders } from 'src/app/models/AlaskaModels';
import { jsPDF } from "jspdf";
import html2Canvas  from 'html2canvas';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataService } from '../../Services/data.service';
import { environment } from 'src/environments/environment';

const ELEMENT_DATA: Array<iCustomerproductorders> = [];
@Component({
  selector: 'app-customerOrdersDialog',
  templateUrl: './customerOrdersDialog.component.html',
  styleUrls: ['./customerOrdersDialog.component.scss']
})
export class CustomerOrdersDialogComponent implements OnInit {

  customerOrders: iCustomerproductorders[]=[];
  selectedCustomer: iCustomers;
  selectedCustomerName:string;
  selectedShoppingCartsName:string;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  productName: string;
  optionValue:number = 1;
  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 8;

  displayedColumns:string[] = ['OrderDate','DeliveryDate', 'CustomerNumber', 'customerName','OrderedQuantity','Price','DeliveredQuantity','UndeliveredQuantity', 'QuantityShortDesc', 'RestPrice'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
              public openItemsDialogRef: MatDialogRef<CustomerOrdersDialogComponent>,
              private dataService:DataService,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit() {
    console.log('invoice dialog: ' , this.data);
    this.customerOrders = this.data && this.data.customerOrders? this.data.customerOrders: null;
    this.selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    let firstOrder:iCustomerproductorders = this.customerOrders? this.customerOrders[0]: undefined;
    this.productName = firstOrder ? ' von '+ firstOrder.ProductNumber + '-' + firstOrder.DescRow1 : '';
    this.title = this.customerOrders.length > 0 ? 'Unerledigte Kundenaufträge' + this.productName : 'kein Unerledigter Kundenauftrag';

    this.datasource.data = this.customerOrders;
    this.datasource.data.sort;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Artikel pro Seite:';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';
  }

  onCancel(){
    this.openItemsDialogRef.close();

  }


}


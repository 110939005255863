
<div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-12">  Benutzer-info   </div>

      </div>
    </div>
    <div class="card-body bodybg">

      <div class="row">
        <div class="col-3">   Benutzername:  </div>     <div class="col-3">   {{user.FirstName}} {{user.LastName}}    </div>

        <div class="col-3">   Registrierungsdatum:  </div>     <div class="col-3">   {{user.CreateDate  | date: 'dd.MM.yyyy' }}    </div>
      </div>
      <div class="row">
        <div class="col-3">   Email:  </div>     <div class="col-3">   {{user.Email}}    </div>
        <div class="col-3">   Deaktiviert:  </div>     <div class="col-3">   {{user.Enabled == 1? 'Nein':'Ja'}}    </div>
      </div>
      <div class="row">
        <div class="col-3">   E-Mail bestätigt:  </div>     <div class="col-3">   {{user.EmailConfirmed == 1? 'Ja':'Nein'}}    </div>

        <div class="col-3">   Letzte Änderung:  </div>     <div class="col-3">   {{user.ModifyDate  | date: 'dd.MM.yyyy'}}    </div>
      </div>
      <div class="row">
        <div class="col-3">   Registrierungstyp:  </div>     <div class="col-3">   {{ registrationType != undefined? registrationType.Name: ''}}    </div>
        <div class="col-3">   Zugriffsrolle:  </div>     <div class="col-3">   {{role != undefined? role.Name: ''}}    </div>
      </div>
    </div>

    <mat-divider  class="p-4"></mat-divider>

    <div class="card-header">
      <div class="row">
        <div class="col-12">  Unternehmensinfo   </div>

      </div>
    </div>

    <div class="card-body bodybg">

      <div class="row">
        <div class="col-3">   Kunde:  </div>     <div class="col-3">   {{selectedCustomer.Name1}}   </div>
        <div class="col-3">   Rabattgruppe:  </div>     <div class="col-3">   {{selectedCustomer.DiscountGroup}}    </div>
      </div>
      <div class="row">
        <div class="col-3">   Beschreibung:  </div>     <div class="col-3">   {{selectedCustomer.ShortDesc}}   </div>
        <div class="col-3">   PriceGroup:  </div>     <div class="col-3">   {{selectedCustomer.PriceGroup}}    </div>
      </div>
      <div class="row">
        <div class="col-3">   KundNr:  </div>     <div class="col-3">   {{selectedCustomer.CustomerNumber}}   </div>

        <div class="col-3">   Telefon:  </div>     <div class="col-3">   {{selectedCustomer.Phone}}    </div>
      </div>
      <div class="row">
        <div class="col-3">   KundID:  </div>     <div class="col-3">   {{selectedCustomer.CustomersId}}    </div>
        <div class="col-3">   Fax:  </div>     <div class="col-3">   {{selectedCustomer.Fax}}    </div>
      </div>

    </div>

<mat-divider  class="p-4"></mat-divider>

<div class="card-header">
  <div class="row">
    <div class="col-12">  Konfiguration   </div>

  </div>
</div>
<div class="card-body ">
      <div class="row"> <div class="col-6 ">

        <table> <tr>
          <td>
            <mat-form-field  appearance="outline" class=" " [ngStyle]="{ 'max-width.px': '180' }">
              <mat-label>Anzeigebreite Konfiguration</mat-label>
              <input onfocus="this.select();" name="deviceSizeInput" type="number" matInput placeholder="{{smallDeviceSize}} pixle"  [(ngModel)]="smallDeviceSize"
              />
            </mat-form-field>
          </td>
          <td>
            <button mat-raised-button  aria-label="deviceSize" class="mlft10" (click)="onChangeDeviceModeInput()">  <span [ngStyle]="{ color: 'blue' }">ok</span> </button>
          </td>
        </tr></table>

        <!-- <button mat-button  matSuffix mat-icon-button aria-label="deviceSize" [ngStyle]="{ color: 'blue' }" (click)="onChangeDeviceModeInput()"><mat-icon [ngStyle]="{ color: 'blue' }" id="clearproductfilter">check_box</mat-icon></button> -->
        </div>
        <div class="col-6">   </div>
      </div>
</div>

</div>



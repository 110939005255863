

 <div class="card">

   <div class="row">
    <div class="col-12 buttonrow">
      <!-- <mat-paginator [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator> -->
      <div class="paginator250px ">
        <select (change)="onPageSizeChange($event)" class="small pgnselect" [(ngModel)]="pageSize"> <option  value=10>10</option> <option  value=20>20</option> <option  value=50>50</option> </select>
        <span class="small">{{getPageVisits()}}</span>
        <button (click)="pgnFirstPage()" class=" arrowxxl" id="productfirstpage"><span>&#171;</span></button>
        <button (click)="pgnBackPage()" class=" arrowxxl"><span>&#8249;</span></button>
        <button (click)="pgnNextPage()" class=" arrowxxl"><span>&#8250;</span></button>
        <button (click)="pgnLastPage()" class=" arrowxxl"><span>&#187;</span></button>
      </div>


    <div class="buttonrow ">
      <mat-form-field appearance="outline">
        <mat-label>Columns</mat-label>
        <mat-select [formControl]="columnsForm" placeholder="Columns"  multiple>
          <mat-option (onSelectionChange)="onSelectColumns($event)" *ngFor="let columns of tableColumns" [value]="columns">{{ columns }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button (click)="onCreate()"  class="mat-icon-button"><span class="material-icons btn-outline-primary md-36 m2" matTooltip="neu erstellen">add_box</span></button>
      <button (click)="onList()"    class="mat-icon-button "><span class="material-icons btn-outline-primary md-36 m2" matTooltip="beschränkte ansicht">view_list</span></button>
 </div>
 </div>
   </div>


<div class="alaska-table-container  table-striped" *ngIf="mode=='list'">

<table mat-table [dataSource]="datasource" matSort matSortActive="UsersId" matSortDisableClear matSortDirection="asc" class="">

  <!-- Position Column -->
  <ng-container matColumnDef="UsersId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.UsersId}} </td>
  </ng-container>

  <ng-container matColumnDef="CreateDate">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Erstellt am </th>
    <td mat-cell *matCellDef="let element"> {{element.CreateDate  | date: 'dd.MM.yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="TitleId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Anrede/Titel </th>
    <td mat-cell *matCellDef="let element"> {{getTitleById(element.TitleId)}} </td>
  </ng-container>

  <ng-container matColumnDef="FirstName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Vorname </th>
    <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
  </ng-container>

  <ng-container matColumnDef="LastName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Nachname </th>
    <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
  </ng-container>

  <ng-container matColumnDef="Email">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
  </ng-container>

  <ng-container matColumnDef="EmailConfirmed">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> E-Mail bestätigt </th>
    <td mat-cell class="" *matCellDef="let element"> <mat-checkbox  class="mat-checkbox mat-accent"  checked={{element.EmailConfirmed}}></mat-checkbox></td>
  </ng-container>

  <ng-container matColumnDef="Enabled">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Aktiviert </th>
    <td mat-cell class="" *matCellDef="let element"> <mat-checkbox  class="mat-checkbox mat-accent"  checked={{element.Enabled}}></mat-checkbox></td>
  </ng-container>

  <ng-container matColumnDef="BirthDate">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> geb.datum </th>
    <td mat-cell *matCellDef="let element"> {{element.BirthDate  | date: 'dd.MM.yyyy'}}</td>
  </ng-container>

  <ng-container matColumnDef="AccessFailedCount">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> fehl-anmeldung </th>
    <td mat-cell *matCellDef="let element"> {{element.AccessFailedCount}} </td>
  </ng-container>

  <ng-container matColumnDef="Token">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Token </th>
    <td mat-cell *matCellDef="let element"> {{element.Token}} </td>
  </ng-container>

  <ng-container matColumnDef="Blocked">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Gesperrt </th>
    <td mat-cell class="" *matCellDef="let element"> <mat-checkbox  class="mat-checkbox mat-accent"  checked={{element.Blocked}}></mat-checkbox></td>
  </ng-container>

  <ng-container matColumnDef="ModifyDate">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Änd-datum </th>
    <td mat-cell *matCellDef="let element"> {{element.ModifyDate  | date: 'dd.MM.yyyy'}} </td>
  </ng-container>

  <ng-container matColumnDef="GenderId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Geschlecht  </th>
    <td mat-cell *matCellDef="let element"> {{ getGenderById(element.GenderId)}} </td>
  </ng-container>

  <ng-container matColumnDef="Role">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Role </th>
    <td mat-cell *matCellDef="let element"> {{getRoleByUser(element)}} </td>
  </ng-container>

  <ng-container matColumnDef="CustomerId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> KundNr </th>
    <td mat-cell *matCellDef="let element"> {{getCustomerNumberByUser(element)}} </td>
  </ng-container>

  <ng-container matColumnDef="RegistrationsTypeId">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Reg-typ </th>
    <td mat-cell *matCellDef="let element"> {{getRegistrationsTypeById(element.RegistrationsTypeId)}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell mat-sort-header *matHeaderCellDef> Aktion </th>
    <td mat-cell *matCellDef="let element">
    <button (click)="onEdit(element)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="bearbeiten">edit</i> </button>

    <button (click)="onListRoles(element)" class="mat-icon-button "><span class="material-icons btn-outline-primary" matTooltip="benutzer rollen">verified_user</span></button>
    <button (click)="onDelete(element)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button>
    <button (click)="onResetPassword(element)" class="mat-icon-button "> <span class="material-icons btn-outline-danger " matTooltip="passwort zurücksetzen">lock</span> </button>
    </td>

  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>

  </tr>

  </table>
  <mat-paginator hidePageSize="true"  [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>
  </div>



<!-- ---------------------------------------------------------------------------------------------------------------- -->



  <div class="card-body" *ngIf="mode=='edit' || mode=='create' ">
    <form  [formGroup] ="usersForm" >

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100 mt-3" appearance="outline">
          <mat-label>Anrede/Titel</mat-label>
          <mat-select   formControlName="TitleId" placeholder="Titel" > <mat-option *ngFor="let titel of Titles" [value]="titel.TitlesId" >{{ titel.Name }} </mat-option> </mat-select>
          <span class="error-block" *ngIf="!usersForm.get('TitleId').valid && (usersForm.get('TitleId').touched  || formSubmited)"></span>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100 mt-3" appearance="outline">
          <mat-label>Geschlecht</mat-label>
          <mat-select  formControlName="GenderId" placeholder="Geschlecht" > <mat-option *ngFor="let gender of Genders" [value]="gender.GendersId">{{ gender.Name }} </mat-option> </mat-select>
          <span class="error-block" *ngIf="!usersForm.get('GenderId').valid && (usersForm.get('GenderId').touched  || formSubmited)"></span>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100 mt-3" appearance="outline">
          <mat-label>Vorname</mat-label>
          <input matInput  formControlName="FirstName" placeholder="Vorname" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('FirstName').valid && (usersForm.get('FirstName').touched  || formSubmited)"></mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100 mt-3" appearance="outline">
          <mat-label>Nachname</mat-label>
          <input matInput formControlName="LastName" placeholder="Nachname" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('LastName').valid && (usersForm.get('LastName').touched  || formSubmited)"></mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100 mt-3" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput  formControlName="Email" type="email" placeholder="Email" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('Email').valid && (usersForm.get('Email').value != ''  && usersForm.get('Email').touched  || formSubmited)"></mat-error>
        </mat-form-field>


      </div>
      <div class="col-6">

        <mat-form-field appearance="outline" class="w-100 mt-3">
          <mat-label>Geburtsdatum</mat-label>
          <input matInput  formControlName="BirthDate" [matDatepicker]="BirthDate" > <mat-datepicker-toggle matSuffix [for]="BirthDate" locale ></mat-datepicker-toggle>
          <span class="error-block" *ngIf="!usersForm.get('BirthDate').valid && (usersForm.get('BirthDate').touched  || formSubmited)"></span>
          <mat-datepicker #BirthDate></mat-datepicker>
        </mat-form-field>
      </div>

    </div>



    <div class="row">
      <div class="col-3">

          <mat-checkbox  class="mt-3"  formControlName="Enabled" unchecked > Aktiviert </mat-checkbox>

      </div>
      <div class="col-3">

          <mat-checkbox  class="mt-3"   formControlName="EmailConfirmed" > E-Mail bestätigt </mat-checkbox>

      </div>
      <div class="col-3">

          <mat-checkbox  class="mt-3"  formControlName="Blocked" > Gesperrt </mat-checkbox>

      </div>
      <div class="col-3">
      </div>
    </div>


    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 mt-3">
          <mat-label>Registrationstyp</mat-label>
          <mat-select (selectionChange)="onTypeChanged(usersForm)" formControlName="RegistrationsTypeId" placeholder="Registrationstyp" ><mat-option *ngFor="let regType of RegistrationsTypes" [value]="regType.RegistrationstypesId">{{ regType.Name }} </mat-option> </mat-select>
          <span class="error-block" *ngIf="!usersForm.get('RegistrationsTypeId').valid && (usersForm.get('RegistrationsTypeId').touched  || formSubmited)"></span>
        </mat-form-field>
      </div>
      <div class="col-6">
      </div>
    </div>

<mat-divider></mat-divider>

    <div *ngIf="usersForm.get('RegistrationsTypeId').valid && isCompanyRegistration(usersForm) ">
      <div class="row">
        <div class="col-6">

    <div  class="w-100 mt-3" *ngIf="hasRoles([1,2])" > <app-searchCustomer></app-searchCustomer> </div>
      </div>
        <div class="col-6">
          <mat-form-field appearance="outline" class="w-100 mt-3">
           <mat-label>Position</mat-label>
            <mat-select  formControlName="PositionsId" placeholder="PositionsId" > <mat-option *ngFor="let position of Positions" [value]="position.PositionsId">{{ position.Name }} </mat-option> </mat-select>
            <span class="error-block" *ngIf="!usersForm.get('PositionsId').valid && (usersForm.get('PositionsId').touched  || formSubmited)"></span>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6"></div>
      <div class="col-3 mt-3 mb-3"><div class="form-group"><button (click)="onReset()" type="reset" class="btn btn-secondary ">Abrechen</button></div></div>
      <div class="col-3 mt-3 mb-3"><div class="form-group"><button (click)="onSubmit()" type="button" class="btn btn-primary">Speichern</button></div></div>
    </div>
  </form>
</div>

<div class="card-body" *ngIf="mode=='editcompany'">

</div>

      <div class="card-body" *ngIf="mode=='listrole'">
        <app-adminUserRoles [editableUser]="editableUser"></app-adminUserRoles>
      </div>

  </div>

<!-- ------------------------------------------------------------------------------------------------------------------------------ -->


<div  *ngIf="mode == 'passwordreset'">
  <form    [formGroup]="resetForm" >

      <div class="form-group col-12">

        <mat-form-field class="w-100" appearance="outline">
          <input matInput formControlName="rEmail" type="email" placeholder="email" [errorStateMatcher]="matcher" ngModel email="true">
          <mat-error class="error-block" *ngIf="!resetForm.get('rEmail').valid && resetForm.get('rEmail').value != '' &&  (resetForm.get('rEmail').touched  || formSubmited)"></mat-error>
        </mat-form-field>

      </div>

      <div class="form-group col-12">
        <div class=row>

          <div class="col-md-6 "><div class="form-group"><button (click)="onSubmitPasswordReset()" class="btn btn-primary">zurücksetzen</button></div></div>
          <div class="col-md-6 "><div class="form-group"><button (click)="onCancelPasswordReset()" class="btn btn-primary">abrechen</button></div></div>
        </div>

      </div>
  </form>
</div>


    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline"  class="w100 " >
          <mat-label>Aktionsartikeln / Alle</mat-label>
          <mat-select id="discountControl" [formControl]="discountFormControl" [(ngModel)]="selectedDiscountOption" (ngModelChange)="onDiscountOption($event)" disableOptionCentering >
            <mat-option    *ngFor="let item of discountOptions"  [value]="item"> {{item}}</mat-option>  </mat-select>
        </mat-form-field>
      </div>
    </div>




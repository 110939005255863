import { Component, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';

import { Router } from '@angular/router';

import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule} from  '@angular/material/sidenav';
import { MatIconModule} from  '@angular/material/icon';
import { DataService } from '../Services/data.service';
import { IdbService } from '../Services/idb.service';
import { iCustomers, iRegistrations, iRegistrationstypes, iRoles, iUsers } from '../models/AlaskaModels';
import { DialogService } from '../Services/dialog.service';
@Component({
  selector: 'app-NavBar',
  templateUrl: './NavBar.component.html',
  styleUrls: ['./NavBar.component.scss'],

})

export class NavBarComponent implements OnInit, AfterViewInit {
  loggedInUser: iUsers;
   @Output() sidenavClose = new EventEmitter();

   placement = "left";
   isLoading:boolean;
   selectedCustomer:iCustomers;
   registrationType: iRegistrationstypes;
    role: iRoles;

  constructor(
    private router: Router,
    private snackbarService: SnackbarService,
    private dataService: DataService,
    private dialogService: DialogService,
    private idb: IdbService) {

  }

  ngOnInit() {

    this.selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));

    if(!this.loggedInUser){
      this.loggedInUser = this.dataService.getLoggedinUser();

    }

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;
        if(this.loggedInUser && this.loggedInUser.Userroles && this.loggedInUser.Userroles.length == 0){

          this.dialogService.okDialog('Anmeldung erfolgreich', 'Ihre Anmeldung war erfolgreich. Ihr Konto ist leider noch nicht aktiviert  Wir informieren Sie per e-mail am nächsten Werktag über die Freischaltung Ihres Coolkauf-Zugangs','danger', '40%').afterClosed().subscribe(
            (data) => {
              this.dataService.onLogOut()
              window.location.reload();
              // if(data == 'ok'){
              //   this.dataService.onLogOut()
              //   window.location.reload();
            }
          );

        }
        if(this.loggedInUser && this.loggedInUser.Userroles && this.loggedInUser.Userroles.length > 0){
          this.getRole();
          this.getRegistrationTypes();
        }

      }, err =>{
        console.log("error user loggin: ", err);
    } );

      this.dataService.selectedCustomerSubject$.subscribe(
        selectedCustomer => {
          this.selectedCustomer = selectedCustomer;
          this.getRole();
          this.getRegistrationTypes();

        }, err => {
          console.log("error - navbar-selectedCustomer-subscription: ",  err);
        }
      );
  }

  ngAfterViewInit(){

  }


  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }


  deleteAllCookies() {
      var cookies = document.cookie.split(";");

      for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
      }
  }

  onReset(){

    // this.isLoading = true;
    indexedDB.deleteDatabase('alaska');
    localStorage.clear();
    window.location.reload();
    localStorage.setItem('setIdb','1');

    this.router.navigate(['/']);
    this.snackbarService.snackbarAction(" Cache-Daten wurden zurückgesetzt!!");

  }

  onLogOut(){
    // this.deleteAllCookies();
    this.dataService.onLogOut();
    window.location.reload();

  }

  logedIn(){
    return !!localStorage.getItem('token'); // returns only true/false(!!)
  }
  onNavigateAlaska(){
    window.open('https://alaska-tkk.de/', '_blank');
  }

  onSendMessage(){}

    navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  onChangePassowrd(){
    this.dataService.setMsgSourceSubject( 'GoToChangePassoword',  'Type',  'Source',  'Target',  'Action', 'GoToChangePassoword',  undefined,  0 );
  }
  onRegister(){
    this.dataService.setMsgSourceSubject( 'GoToRegister',  'Type',  'Source',  'Target',  'Action', 'GoToRegister',  undefined,  0 );
  }
  onLogin(){
    this.dataService.setMsgSourceSubject( 'GoToLogin',  '',  '',  '',  '', 'GoToLogin',  undefined,  0 );
  }

  getRole(){
    if(this.loggedInUser && this.loggedInUser.Userroles[0] != undefined){
        this.dataService.getData('custom','getRoles', this.loggedInUser.Userroles[0].RolesId ).subscribe(
          role => {
            this.role = role
          }, err => {
            console.log('error onMyProfile, getRoles: ', err)}
        );
    }
  }

  getRegistrationTypes(){
    if(this.loggedInUser && this.loggedInUser.RegistrationsTypeId){
        this.dataService.getData('custom','getRegistrationstypes',this.loggedInUser.RegistrationsTypeId).subscribe(
          registrationType => {
          this.registrationType = registrationType;}, err => {
        console.log('error onMyProfile getRegistrationstypes: ', err)}
        );
    }
  }

  onMyProfile(){

    if(this.selectedCustomer ){
      this.dialogService.ProfileDialog(this.selectedCustomer, null,this.loggedInUser,this.registrationType, this.role,'60%').afterClosed().subscribe(
        info => {
        }, err => {
          console.log('error dialog ', err);
        }
      );
    }
  }
  onMyAccount(){
    // this.dataService.setMsgSourceSubject({ Name: "GoToMyAccount", Type: "", Action: "", Source: "", Target: "", Message: "GoToMyAccount" });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}


}

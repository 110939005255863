import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { iParameterBag, iProductCategoryDropDown, iProductcategories } from '../models/AlaskaModels';
import { DataService } from '../Services/data.service';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() public Productcategories: iProductcategories[] = [];
  @Input() public items: iProductCategoryDropDown[] = [];
  @ViewChild('childMenu') public childMenu;
  @ViewChild("menu", {static: true}) menu: MatMenu;
  // @Input() items: {name: string, children: string[]}[];

  parameterBagSubject: iParameterBag = {};
  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
    // this.Productcategories = JSON.parse(localStorage.getItem('Productcategories'));

    // this.dataService.parameterBagSubject$.subscribe(
    //   (bag) => {
    //     this.parameterBagSubject = bag;
    //   }, (err) => {
    //     console.log('error - selectedCustomer-subscription: ', err);
    //   }
    // );

  }
  onProductCategoryChanged(productCategoryId?: number){

    // let productTab = document.getElementById("mat-tab-label-0-0");
    // if(productTab){ setTimeout(() => productTab.click(),0); }
    if(this.Productcategories == undefined || this.Productcategories.length == 0){
      this.Productcategories = JSON.parse(localStorage.getItem('Productcategories'));
    }

    let productCategory: iProductcategories = this.Productcategories? this.Productcategories.find(x => x.ProductcategoriesId == productCategoryId): undefined;

    this.dataService.setProductCategorySubject(productCategory);

  }




}

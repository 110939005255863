import { Component, OnInit } from '@angular/core';
import { DataService } from '../Services/data.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { iParameterBag } from '../models/AlaskaModels';

@Component({
  selector: 'app-filterProducts',
  templateUrl: './filterProducts.component.html',
  styleUrls: ['./filterProducts.component.scss']
})
export class FilterProductsComponent implements OnInit {

  filterValue: string ;
  parameterBagSubject: iParameterBag = {};
  constructor(    private dataService: DataService) { }

  ngOnInit() {

    this.dataService.msgSubject$.subscribe(
      msg => {
        if(msg.Name.toLowerCase() == "mattabclicked" &&  msg.Source.toLowerCase() == 'katalog'){

        }

      });

    this.dataService.clearFilterBoxSubject$.subscribe(
      reset => {
        if(reset == true){ this.clearValue('');}
      }
    );

    // this.dataService.parameterBagSubject$.subscribe(
    //   (bag) => {
    //     this.parameterBagSubject = bag;
    //   }, (err) => {
    //     console.log('error - selectedCustomer-subscription: ', err);
    //   }
    // );



  }

  clearValue(filterValue: string){
    if(this.filterValue != ''){
      this.filterValue = '';
      this.dataService.setProductCategorySubject(undefined);
    }
    this.onSendMessage('','clear');
}



onSendMessage($event: any,action?:string){

    let filterValue = '';
    let keyCode = '';
    let key = ''
  if($event && $event != ''){
    filterValue = $event.target.value;
    keyCode = $event.keyCode;
    key = $event.key;
    this.dataService.onKeyCombination($event);

  }

    if(keyCode && keyCode.toString() == '13'){  }

      this.dataService.setMsgSourceSubject( 'applyproductfilter',  key != undefined? key: '',  'menue',  '',  action!= undefined? action: '', filterValue != undefined? filterValue: '', undefined ,  0 );

}


// onKeyUpEvent(controlId: string, $event: any){

// }


}

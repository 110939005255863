
<div class="card">
  <div class="card-header">
    <div class=row>
      <div class="col-10 text-primary"> <p> {{userRoleItem}} </p></div>

      <div class="col-2 float-right">
        <button (click)="onAdd()" class="mat-icon-button "><span class="material-icons btn-outline-primary" matTooltip="hinzufügen">add_box</span></button>
        <button (click)="onList()" class="mat-icon-button "><span class="material-icons btn-outline-primary" matTooltip="List ansicht">view_list</span></button>
      </div>
    </div>
   </div>

<!-- ---------------------------------------------------------------- -->

    <div class="table-responsive" *ngIf="mode =='list'">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Rolle</th>
            <th>Erstellt am</th>
            <th>Geändert am</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let userRole of userRoles">
            <td>{{userRole.UserrolesId}}</td>
            <td>{{userRole.Roles.Name}}</td>
            <td>{{userRole.CreateDate  | date: 'dd.MM.yyyy'}}</td>
            <td>{{userRole.ModifyDate  | date: 'dd.MM.yyyy'}}</td>
            <td><div><button (click)="onEdit(userRole)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="bearbeiten">edit</i> </button>
              <button (click)="onAdd(userRole)" class="mat-icon-button "><span class="material-icons btn-outline-primary" matTooltip="hinzufügen">supervisor_account</span></button>
              <button (click)="onDelete(userRole)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button></div></td>

          </tr>
        </tbody>
      </table>
    </div>

<!-- ---------------------------------------------------------------- -->

<div  *ngIf="mode =='edit' || mode =='add' || mode =='delete'">
  <form  [formGroup]="userRolesForm" >
    <div class="row">
      <div class="col-6 pt-4">
        <p class="text-danger pt-4"  *ngIf="mode =='edit' && selectedUser != undefined " > zugriffrecht von {{selectedUser.FirstName}} {{selectedUser.LastName}} ändern: </p>
        <p class="mat-warn pt-4"  *ngIf="mode =='add' && selectedUser != undefined " > zugriffsrechte für {{selectedUser.FirstName}} {{selectedUser.LastName}} hinzufügen: </p>
        <p class="text-danger pt-4"  *ngIf="mode =='delete' && selectedUser != undefined " > zugriffsrechte von {{selectedUser.FirstName}} {{selectedUser.LastName}} löschen: </p>
      </div>
      <div class="col-6 pt-4"  >
        <mat-select  [disabled]="!roleSelectEnabled" class="form-control" formControlName="RolesId" placeholder="Role"> <mat-option  *ngFor="let role of roles" [value]="role.RolesId">{{ role.Name }} </mat-option> </mat-select>
        <span class="error-block" *ngIf="!userRolesForm.get('RolesId').valid && (userRolesForm.get('RolesId').touched  || formSubmited)">Ungültiger Eintrag</span>
      </div>
    </div>

    <div class="row">
      <div class="col-6 pt-4"></div>
      <div class="col-3 pt-4">
        <div class="form-group"><button (click)="onCancel()" type="reset" class="btn btn-secondary ">Abrechen</button></div>
      </div>
      <div class="col-3 pt-4">
        <div class="form-group" *ngIf="mode =='edit' || mode =='add'"><button (click)="onSubmit()" type="button" class="btn btn-primary">Speichern</button></div>
        <div class="form-group" *ngIf="mode =='delete'"><button (click)="onSubmit()" type="button" class="btn btn-primary">Löschen</button></div>

      </div>
    </div>
  </form>
</div>

<!-- ---------------------------------------------------------------- -->

</div>







import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { stringify } from 'querystring';

import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { iUsers, iRoles, iUserroles, iCustomers, iGenders, iTitles, iRegistrationstypes, iInputOptions, iShoppingcarts, iPrintOptions, iParameterBag } from 'src/app/models/AlaskaModels';
import { DataService } from 'src/app/Services/data.service';
import { IdbService } from 'src/app/Services/idb.service';
import { JSDocTagName, NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { InputErrorStateMatcher} from 'src/app/models/InputErrorStateMatcher';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../Services/dialog.service';
import { DateAdapter } from '@angular/material/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-UserLogin',
  templateUrl: './UserLogin.component.html',
  styleUrls: ['./UserLogin.component.css']
})
export class UserLoginComponent implements OnInit {


  // Login ####################################################################

  loginForm: FormGroup;
  resetForm: FormGroup;
  formSubmited:  boolean;
  passwordForget: boolean;
  passwordReset: boolean;
  loggedInUser: iUsers ;
  user: iUsers ;
  selectedCustomer:iCustomers;
  selectedDeliveryDate: Date = new Date();
  matcher = new InputErrorStateMatcher();
  isLoading: boolean = false
  mode:string = "login";
  cardHeader:string = "Login";


  // registration ################################################################

  RegistrationUsersForm: FormGroup;
  registrationFormSubmited: boolean;
  RegistrationUser: iUsers;
  RegistrationInputOptions: iInputOptions;

  activationToken: string;

  Titles: iTitles[] = [];
  Genders: iGenders[] = [];
  RegistrationsTypes: iRegistrationstypes[] = [];
  RegistrationMatcher = new InputErrorStateMatcher();
  minDate: Date = new Date(new Date().getTime() - 365 * 18 * 24 * 60 * 60 * 1000);
  pipe = new DatePipe('de-DE');
  printOption:iPrintOptions;

  constructor(private dataService: DataService,
              private router: Router,
              private snackbarService: SnackbarService,
              private idbService: IdbService,
              private formBuilder: FormBuilder,
              private dialogService: DialogService,


              private dateAdapter: DateAdapter<any>,
              private http: HttpClient,
              private RegistrationormBuilder: FormBuilder

              ) { }


  setMode(navigation: string) {
    this.mode = navigation && navigation != ""? navigation : 'login';
  }

  parameterBagSubject: iParameterBag = {};
  deliveryDate:Date =  new Date(Date.now());
  ngOnInit() {
  // parameterBagSubject: iParameterBag;
  // this.dataService.parameterBagSubject$.subscribe(
  //   (bag) => {
  //         this.parameterBagSubject = bag;
  //         this.selectedCustomer = bag && bag.SelectedCustomer? bag.SelectedCustomer : this.selectedCustomer;
  //         this.deliveryDate = bag && bag.DeliveryDate? bag.DeliveryDate: this.deliveryDate;
  //   }, (err) => { console.log('error - parameterBagSubject-subscription: ', err);  });
    // Login
    this.passwordForget=false;
    this.createLoginForm();
    this.createResetForm();

    let x = this.mode;


    // registration
    this.dateAdapter.setLocale('de-DE');
    this.Titles = JSON.parse(localStorage.getItem('inputOptions'))? JSON.parse(localStorage.getItem('inputOptions')).titles : this.Titles ;
    this.Genders = JSON.parse(localStorage.getItem('inputOptions'))? JSON.parse(localStorage.getItem('inputOptions')).genders : this.Genders ;
    this.RegistrationsTypes = JSON.parse(localStorage.getItem('inputOptions'))? JSON.parse(localStorage.getItem('inputOptions')).registrationstypes : this.RegistrationsTypes ;


    this.activationToken = localStorage.getItem("activationToken");
    this.createRegistrationUserForm();

  }

  createLoginForm(){
    this.loginForm = this.formBuilder.group(
      {
        email: new FormControl(null,[Validators.required,Validators.email]),
        password: new FormControl(null, [Validators.required,Validators.minLength(6)]),
      }
    )
  }

  createResetForm(){
    this.resetForm = this.formBuilder.group(
      {
        rEmail: new FormControl(null,[Validators.required, Validators.email]),
        rcurrentpassword: new FormControl(null, [Validators.required]),
        rPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
        rConformpassword: new FormControl(null),
      },{ validators: this.checkPasswords }
    )
  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    let pass = group.get('rPassword').value;
    let confirmPass = group.get('rConformpassword').value
    return pass === confirmPass ? null : { notSame: true }
  }

get email(){return this.loginForm.get('email');}
get password(){return this.loginForm.get('password');}

  onSubmit(){
    this.formSubmited = true;
    this.user = {Email: this.email.value, Password: btoa(this.password.value)};
    this.loginValidate(this.user);
    this.formSubmited = false;
  }

  onPasswordForget(){

    this.mode = 'passwordreset';
    this.cardHeader = 'Passwort zurücksetzen';
  }

  onReset(){

  }

  onPasswordResetRequest(){
    this.formSubmited = true;
    this.user = {Email: this.email.value, Password: btoa("dummy")};

    this.dataService.PostData("custom", this.user, "UserChangePasswordRequest").subscribe(
      data => {

        this.snackbarService.snackbarAction('Passwort erfolgreich zurückgesetzt – schauen Sie bitte in Ihre e-Mail und ändern Sie das Passwort zeitnah. ','ok');
        console.log("Postresult Data : ", data);
        console.log("Postresult Data tocken: ", data["Token"]);
        this.passwordReset = true;

        localStorage.setItem("resetToken", data["Token"]);
        this.router.navigate(['/']);
        this.dataService.onLogOut();
        this.cardHeader = 'Login';
        this.mode = 'login';

        this.dataService.setMsgSourceSubject( 'GoToLogin',  '',  '',  '',  '', 'GoToLogin',  undefined,  0 );

      }, err => {
        console.log("PostData error : ", err);
      }, () => {
        console.log("PostData completed : ");
      });

console.log("reset email",  this.email);
  }

  loginValidate(user: iUsers):  iUsers
  {
    //cretae IDB before login
    this.idbService.createObjectstores();

    let resultUser: iUsers ;
    let shoppingCartArray: iShoppingcarts[] = [];

    this.dataService.PostData("custom", user, "uservalidate").subscribe(
    result => {

      resultUser = result;
      localStorage.setItem('setIdb', '1');
      localStorage.setItem('baskets', JSON.stringify(shoppingCartArray));
      if(result == 'Unauthorized'){
        this.dialogService.okDialog('Unberechtigt', 'Ihr Anmeldung ist fehlgeschlagen. ' + 'Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort.', 'danger', '40%');
        return;
      }
      else if(resultUser){
         this.dataService.setLogedInUserSubject(resultUser);
         this.printOption = {  OrdersatzPdf:'',  OrdersatzExcelAndCsv:'', WarenkorbkPdf:'', AuftrgssamlerPdf:''}
         localStorage.setItem('printOptions', JSON.stringify(this.printOption))

        if(this.dataService.hasRoles([1,2,3,4])){
          // setTimeout(() => {}, 0);

            this.idbService.populateData(this.selectedCustomer, this.selectedDeliveryDate);
        }
        else if(this.dataService.hasRoles([5])){

          this.dataService.getData('custom','getCustomers',resultUser.CustomersId).subscribe(
            customer =>{
              this.selectedCustomer = customer;
              this.dataService.setSelectedCustomerSubject(this.selectedCustomer);
              this.idbService.populateCustomerData(this.selectedCustomer, this.deliveryDate, true)

            },err =>{  console.log('error setting customer of logedin user',err);  } );
        }
        else if(this.dataService.hasRoles([6])){

          this.dialogService.okDialog('Anmeldung erfolgreich', 'Ihr Zugang ist leider noch nicht aktiviert – Wir informieren Sie per e-mail am nächsten Werktag über die Freischaltung Ihres Coolkauf-Zugangs','danger', '40%').afterClosed().subscribe(
            (data) => {
              this.dataService.onLogOut()
              window.location.reload();
            }
          );
        }
      //##############
    }

      this.router.navigate(['/']);
      this.mode = 'home';
      this.dataService.setMsgSourceSubject( 'gotoproducts',  '',  '',  '',  '', 'gotoproducts',  undefined,  0 );
      this.snackbarService.snackbarAction('wilkommen '+user.Email.toString() );

      console.log("loginValidate subscribe result parsed: ", resultUser);
    }, err => {
      this.snackbarService.snackbarAction('Ihr Anmeldung ist fehlgeschlagen ','ok');
      console.log("login fehlgeschlagen: ", err);
    });

    return resultUser;

  }

  hasRoles(roleIds: number[]): boolean{
    return this.dataService.hasRoles(roleIds);
   }

  onPasswordReset(){
    this.formSubmited = true;
    this.user = {
      Email: this.email.value,
      Password: btoa(this.password.value),
      Token: localStorage.getItem("resetToken")};
      console.log("usertocken : ", this.user);

    this.dataService.PostData("custom",this.user,"UserChangePassword").subscribe(
      data => {
        console.log("reset result Data : ", data);
      }, err => {
        console.log("PostData error : ", err);
      }, () => {
        console.log("PostData completed : ");
      });


  }

  onPassWordForget(){
    this.mode = 'passwordreset';
    this.cardHeader = 'Passwort zurücksetzen';
  }

  onCancel(){
    this.mode = 'login';
    this.cardHeader = 'Login';
    this.dataService.setMsgSourceSubject( 'isAborted',  'Type',  'Source',  'Target',  'Action', 'isAborted',  undefined,  0 );
  }

  onCancelPasswordReset(){
    this.mode = 'login';
    this.cardHeader = 'Login';
  }

  onSubmitPasswordReset(){
    this.formSubmited = true;
    if(this.validatePasswordConfirmation()==true){
      this.user = {
        Email: this.rEmail.value,
        Password: '',
        Token: localStorage.getItem("resetToken")};

        this.dataService.PostData("custom",this.user,"UserPasswordReset").subscribe(
          data => {
            console.log("reset result Data : ", data);
            this.router.navigate(['/']);
            this.dialogService.okDialog('Passwort zurücksetzen', 'Passwort erfolgreich zurückgesetzt – schauen Sie bitte in Ihre e-Mail und ändern Sie das Passwort zeitnah. ', 'success','40%');
            this.dataService.onLogOut();
            this.cardHeader = 'Login';
            this.mode = 'login';

            this.dataService.setMsgSourceSubject( 'GoToLogin',  '',  '',  '',  '', 'GoToLogin',  undefined,  0 );

          }, err => {
            console.log("PostData error : ", err);
            this.dialogService.okDialog('Passwort zurücksetzen', 'Passwortzurücksetzen fehlgeschlagen', 'danger','40%');
          }, () => {
            console.log("PostData completed : ");
          });
    }



  }

  get rEmail(){return this.resetForm.get('rEmail');}
  get rcurrentpassword(){return this.resetForm.get('rcurrentpassword');}
  get rPassword(){return this.resetForm.get('rPassword');}
  get rConformpassword(){return this.resetForm.get('rConformpassword');}


validatePasswordConfirmation():any {
  return true;
}


// Registration #################################################################################################



createRegistrationUserForm(user?: iUsers){
this.RegistrationUsersForm = this.formBuilder.group(
  {
    UsersId :             [user != undefined && user.UsersId != undefined? user.UsersId : 0, []],
    TitleId:              [user != undefined && user.TitleId != undefined? user.TitleId : 0, [Validators.required]],
    GenderId:             [user != undefined && user.GenderId != undefined? user.GenderId : 0, [Validators.required]],
    FirstName:            [user != undefined && user.FirstName != undefined? user.FirstName : '', [Validators.required]],
    LastName:             [user != undefined && user.LastName != undefined? user.LastName : '', [Validators.required]],
    BirthDate:            [user != undefined && user.BirthDate != undefined? user.BirthDate : '', [Validators.required]],
    Email:                [user != undefined && user.Email != undefined? user.Email : '', [Validators.required, Validators.email]],
    Password :            [user != undefined && user.Password != undefined? user.Password : '', [Validators.required, Validators.minLength(6)]],
    ConformPassword :     ['', [Validators.required, Validators.minLength(6)]],
    RegistrationsTypeId:  [user != undefined && user.RegistrationsTypeId != undefined? user.RegistrationsTypeId : 0, [Validators.required]]
  },
  {
    validators: this.passwordMatchValidation
  }
);
}

setUser(){
  this.RegistrationUser = {
    UsersId: +this.RegistrationUsersForm.get('UsersId').value,
    TitleId: +this.RegistrationUsersForm.get('TitleId').value,
    GenderId: +this.RegistrationUsersForm.get('GenderId').value,
    FirstName: this.RegistrationUsersForm.get('FirstName').value,
    LastName: this.RegistrationUsersForm.get('LastName').value,
    BirthDate: this.RegistrationUsersForm.get('BirthDate').value,
    Email: this.RegistrationUsersForm.get('Email').value,
    Password: this.RegistrationUsersForm.get('Password').value,
    RegistrationsTypeId: +this.RegistrationUsersForm.get('RegistrationsTypeId').value,

  }
localStorage.setItem('reg-user',JSON.stringify(this.RegistrationUser));
}
get UsersId(){ return this.RegistrationUsersForm.get('UsersId');}
get TitleId(){ return this.RegistrationUsersForm.get('TitleId');}
get GenderId(){ return this.RegistrationUsersForm.get('GenderId');}
get FirstName(){ return this.RegistrationUsersForm.get('FirstName');}
get LastName(){ return this.RegistrationUsersForm.get('LastName');}
get BirthDate(){ return this.RegistrationUsersForm.get('BirthDate');}
get Email(){ return this.RegistrationUsersForm.get('Email');}
get Password(){ return this.RegistrationUsersForm.get('Password');}
get ConformPassword(){ return this.RegistrationUsersForm.get('ConformPassword');}
get RegistrationsTypeId(){ return this.RegistrationUsersForm.get('RegistrationsTypeId');}

onNextClick(){
  this.setUser();
  console.log('LSuser: ' , localStorage.getItem('reg-user'))

}

onRegistrationSubmit(){
  console.log(this.RegistrationUsersForm)
  this.registrationFormSubmited = true;

  if(this.RegistrationUsersForm.valid){

      this.setUser();
      this.RegistrationUser.Password = btoa(this.RegistrationUser.Password);
      let age =   (+ (new Date(Date.now())).getFullYear()) - +this.RegistrationUser.BirthDate.getFullYear()
      if(age < 18){
        this.snackbarService.snackbarAction('Leider Eine Registrierung ist erst ab 18 Jahren möglich.');
        return
      }
      else{



        this.dataService.PostData('custom', this.RegistrationUser, 'postUsers').subscribe(
        result => {

          this.dataService.setMsgSourceSubject( 'GoToLogin',  '',  '',  '',  '', 'GoToLogin',  undefined,  0 );


          this.activationToken = result["Token"]; localStorage.getItem("activationToken");
          this.RegistrationUsersForm.reset();
          this.router.navigate(['/']);
          this.dialogService.okDialog('Registrierung erfolgreich', 'Vielen Dank für Ihre Registrierung – Wir informieren Sie per e-mail am nächsten Werktag über die Freischaltung Ihres Coolkauf-Zugangs','success', '40%');

          console.log("loginValidate subscribe result: ", result);

          },
        err =>  {
          this.snackbarService.snackbarAction('Registrierungsdaten fehlgeschlagen.');
          console.log("PostData error", err)
        }
        );

        this.registrationFormSubmited = false;

        this.snackbarService.snackbarAction('Registrierungsdaten wurden erfolgreich gesendet.');
      }
    }else{

      this.snackbarService.snackbarAction('there was an error while validating user');
  }


}

passwordMatchValidation(fg: FormGroup): Validators{
  return fg.get('Password').value === fg.get('ConformPassword').value? null: {passwordNotMatched: true};
}

onRegistrationReset(){
  this.RegistrationUsersForm.reset();
}

}



<table class="table-striped mat-elevation-z8" mat-table [dataSource]="datasource" >


          <ng-container matColumnDef="Artikel">
            <th mat-header-cell   *matHeaderCellDef >A-Nr</th>
            <td mat-cell class="m-2"  *matCellDef="let element" > {{ element.Product.ProductNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="ProcessCode">
            <th mat-header-cell   *matHeaderCellDef >AC</th>
            <td mat-cell class="m-2"  *matCellDef="let element"   >{{ element.Product.ProcessCode }}</td>
          </ng-container>

          <ng-container matColumnDef="Date">
          <th mat-header-cell   *matHeaderCellDef >Lieferdatum</th>
          <td mat-cell class="m-2"  *matCellDef="let element"   >{{ element.DeliveryDate  | date: 'dd.MM.yyyy' }}</td>
          </ng-container>

          <ng-container matColumnDef="Name">
            <th mat-header-cell  *matHeaderCellDef >Artikelkbezeichnung</th>
          <td mat-cell class="m-2"  *matCellDef="let element" >{{element.Product.Description}}</td>
          </ng-container>

          <ng-container matColumnDef="Available">
            <th mat-header-cell   *matHeaderCellDef >Verfügbar</th>
          <td mat-cell class="m-2"  *matCellDef="let element">

            <table class="stockTable">
              <tr *ngIf="hasRoles([1,2,3,4]) == true"><td [ngClass]="getBg(element.Product.StockValue,'StockValue')"  (click)="onOdataStockUpdate(element.Product.ProductNumber)"  class=" qtyspan"> Ist: {{ element.Product.StockValue | number:'1.2-2':'de' }} </td>  <td [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" (click)="onOdataStockUpdate(element.Product.ProductNumber)"  class=" qtyspan"> Dispo: {{ element.Product.DispositionStockValue | number:'1.2-2':'de' }} </td> </tr>
              <tr *ngIf="hasRoles([1,2,3,4]) == true"><td [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" (click)="onOdataCustomerOrderShow(element.Product.ProductNumber)"  class=" qtyspan">K-Bestlg.: {{ element.Product.OpenOrdersStockValue | number:'1.2-2':'de' }} </td> <td [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')" (click)="onOdataSuplierOrderShow(element.Product.ProductNumber)"  class=" qtyspan"> L-Bestlg.: {{ element.Product.SupplierDeliveryStockValue | number:'1.2-2':'de' }} </td> </tr>

              <tr *ngIf="hasRoles([1,2,3,4]) == false"><td > <span [ngClass]="getIcon(element.Product.DispositionStockValue,'stockIconStyle')"  matTooltip="{{bigOrSmall(element.Product.DispositionStockValue)}}"  >  {{getIcon(element.Product.DispositionStockValue, 'stockAvailabilityIcon') }}</span>  </td> <td></td> </tr>
            </table>

          </td>

          </ng-container>

          <ng-container matColumnDef="ListPrice">
            <th mat-header-cell  *matHeaderCellDef >Aktuelle-preise(€)</th>
            <td mat-cell class="m-2"  *matCellDef="let element"> {{ getPrice(element) | number:'1.2-2':'de'}}</td>
          </ng-container>

          <ng-container matColumnDef="Quanity">
            <th mat-header-cell   *matHeaderCellDef >letzte Menge</th>
          <td mat-cell class="m-2"  *matCellDef="let element">{{ element.Quantity | number:'1.2-2':'de'}}</td>
          </ng-container>

          <ng-container matColumnDef="Price">
            <th mat-header-cell  *matHeaderCellDef >Letzter-preis(€)</th>
          <td mat-cell class="m-2"  *matCellDef="let element"> {{element.Price | number:'1.2-2':'de' }}</td>
          </ng-container>

          <ng-container matColumnDef="Unit">
            <th mat-header-cell   *matHeaderCellDef >Einheit</th>
          <td mat-cell class="m-2"  *matCellDef="let element">{{ element.Product.ShortDesc }}</td>
          </ng-container>

          <tr class="p-2" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr class="p-2" mat-row *matRowDef="let row; columns: displayedColumns;"  id="OrderableDetail_row-{{ row.ProductId }}">  </tr>

        </table>



<div class="card ">

  <div class="card-header">
    <div class="row">
      <div class="col-12" ><span class="text-primary" >{{title}} </span> </div>
    </div>

  </div>
  <div class="tablecontainer" *ngIf="datasource.data.length > 0">
        <table class="  alaska-table-container  table-striped" mat-table [dataSource]="datasource.data" >


        <ng-container matColumnDef="OrderDate" class="mar-r" >
          <th  mat-header-cell *matHeaderCellDef> Bestelldatum </th>
          <td mat-cell *matCellDef="let element"> {{ element.OrderDate   | date: 'dd.MM.yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="DeliveryDate" class="mar-r" >
          <th  mat-header-cell *matHeaderCellDef> Lieferdatum </th>
          <td mat-cell *matCellDef="let element"> {{ element.DeliveryDate   | date: 'dd.MM.yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="CustomerNumber" class="mar-r" >
          <th  mat-header-cell *matHeaderCellDef> KundNr </th>
          <td mat-cell *matCellDef="let element"> {{ element.CustomerNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="customerName" class="mar-r" >
          <th  mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{ element.customerName  }}</td>
        </ng-container>

        <ng-container matColumnDef="OrderedQuantity" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Bestellt </th>
          <td mat-cell *matCellDef="let element"> {{ element.OrderedQuantity |  number:'1.2-2':'de' }}  </td>
        </ng-container>

        <ng-container matColumnDef="Price" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Preis </th>
          <td mat-cell *matCellDef="let element"> {{ element.Price |  number:'1.2-2':'de' }}  </td>
        </ng-container>

        <ng-container matColumnDef="DeliveredQuantity" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Geliefert </th>
          <td mat-cell *matCellDef="let element"> {{ element.DeliveredQuantity |  number:'1.2-2':'de' }}  </td>
        </ng-container>
        <ng-container matColumnDef="UndeliveredQuantity" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Offen </th>
          <td mat-cell *matCellDef="let element"> {{ element.UndeliveredQuantity |  number:'1.2-2':'de' }}  </td>
        </ng-container>

        <ng-container matColumnDef="QuantityShortDesc" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Einh. </th>
          <td mat-cell *matCellDef="let element"> {{ element.QuantityShortDesc  }} </td>
        </ng-container>

        <ng-container matColumnDef="RestPrice" class="m-1">
          <th mat-header-cell *matHeaderCellDef> Restwert </th>
          <td mat-cell *matCellDef="let element" >   {{ (element.UndeliveredQuantity *  element.Price) |  number:'1.2-2':'de' }}  </td>
        </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  </table>
</div>
</div>



import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appendFile } from 'fs';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService} from '../Services/snackbar.service';
import { DataService} from '../Services/data.service'
import { DialogService} from '../Services/dialog.service'


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor  {

constructor(
  private dataService: DataService,
  private snackbarService: SnackbarService,
  private dialogService: DialogService,
) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = this.addHeaders(req);

    return next.handle(req).pipe(
      catchError(
        (error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('A client-side error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error('Backend returned status code ' + error.status + ' ' + error.statusText + ' , body was: ' + error.error);
          // if(error && error.status == 0){
          //   this.dialogService.okDialog('Verbindungsfehler', 'Netzwerkverbindungsfehler aufgetreten. ' + error.statusText, 'danger', '40%')
          // }

          //this.snackbarService.snackbarAction('Token abgelaufen, bitte melden Sie sich erneut an!')
          if(error.error && error.error.text && error.error.text.toLowerCase() == 'unauthorized'){
            let errText = '(Unauthorized) Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort.';
            this.dialogService.okDialog('Unberechtigt', 'Ihr Anmeldung ist fehlgeschlagen. ' + errText, 'danger', '40%')
          }


        }
        return EMPTY;
        // return error;
      }
      )

    );
  }

  addHeaders(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        "Content-type": "application/json",
        'Authorization': localStorage.getItem('token')?  'Bearer ' + localStorage.getItem('token') : '',
      }
    });
  }

}

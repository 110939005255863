
<div class="row" >

  <button (click)="onCancel()" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">cancel</i> </button>

  <mat-form-field appearance="outline">
    <mat-label>PDF speichern/druck optionen</mat-label>
    <mat-select disableRipple [(ngModel)]="optionValue" (ngModelChange)="onSelectionChange($event)">

      <mat-option value="1">Angebot</mat-option>
      <mat-option value="2">Angebot ohne Preise</mat-option>
      <mat-option value="3">Angebot ohne Bilder</mat-option>
      <mat-option value="4">Bestellauskunft</mat-option>
      <mat-option value="5">Bestellauskunft ohne Preise</mat-option>
      <mat-option value="6">Bestellauskunft ohne Bilder</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="col-6"> <mat-paginator  [pageSizeOptions]="[8, 20]" showFirstLastButtons [pageSize]="pageSize" #paginator></mat-paginator>  </div>
  <button   class="mat-icon-button md-36" [useExistingCss]="true"  printSectionId="orderContainer" ngxPrint><i class="material-icons btn-outline-primary " matTooltip="Mit Preisen und Bildern ">print</i></button>

</div>

<div id="orderContainer">

    <div class="row head">
    <div class=" col-6 col-l">
      <span class=""><a target="_blank" href="https://alaska-tkk.de">  <img src="../assets/icons/logo_alaska.jpg" data-holder-rendered="true" width="200" />  </a></span>
      <p></p><p></p>

      <div *ngIf="selectedOrder != undefined">
        <p class="m-1">KundenNr.: {{selectedOrder.ShoppingCarts.CustomersCustomerNumber}}</p>
        <p class="m-1">{{selectedOrder.ShoppingCarts.CustomersName1}}</p>
        <p class="m-1">{{selectedOrder.DeliveryAddress}}</p>
      </div>
    </div>

    <div class="col-6 col-r">
      <p class="m-1">Kauritzerstr. 29, 04639 Gößnitz</p>
      <p class="m-1">Tel: 034493/22588</p>
      <p class="m-1">Fax: 034493/36532</p>
      <p class="m-1">Steuer-Nr. 16110502854</p>
      <p class="m-1">USt-Id-Nr. DE 150510865</p>
      <p class="m-1">alaska-tkk@t-online.de</p>
      <p class="m-1">www.alaskaonline.de</p>
    </div>
    </div>



  <div class=" row ">
    <div class="col-8 col-l">
      <div *ngIf="title != undefined"> <h5>{{title}} </h5> </div>
    </div>
    <div class="col-4 col-r">
      <div *ngIf="date != undefined"> Datum: {{date}} </div>
      <div *ngIf="deliveryDate != undefined"> Lieferdatum: {{deliveryDate}} </div>
      <div *ngIf="optionValue != 2 && optionValue != 5 && totalPriceSum() != undefined"><b>Gesamtbetrag: {{totalPriceSum()}} € </b> </div>
    </div>
  </div>

  <div class="row t-content">

  <!-- Angebot  mit/ohne Bilder/Price -->

<div class="col-12" *ngIf="optionValue == 1 || optionValue == 2 || optionValue == 3">
      <div class="card-body" >
        <table mat-table [dataSource]="datasource" >

        <ng-container matColumnDef="ProductId" class="m-1">
          <th mat-header-cell *matHeaderCellDef> A-Nr </th>
          <td mat-cell *matCellDef="let element" > {{element.Product.ProductNumber}}  </td>
        </ng-container>

        <ng-container matColumnDef="DescRow1" class="m-1">
          <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
          <td mat-cell *matCellDef="let element"> {{element.Product.DescRow1}}  <br /> {{element.Product.DescRow2}}</td>
        </ng-container>

        <ng-container matColumnDef="Unit" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Einh. </th>
          <td mat-cell *matCellDef="let element"> {{ element.Product.QuantityUnitsShortDesc }} </td>
        </ng-container>

        <ng-container matColumnDef="Quantity" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Menge </th>
          <td mat-cell *matCellDef="let element"> {{ element.Quantity }} </td>
        </ng-container>

        <ng-container matColumnDef="Price" class="mar-r" *ngIf="optionValue != 2 || optionValue != 5">
          <th  mat-header-cell *matHeaderCellDef> Preis (€) </th>
          <td mat-cell *matCellDef="let element"> {{ element.Price |  number:'1.2-2':'de' }}</td>
        </ng-container>

        <ng-container matColumnDef="PosDiscount" class="mar-r">
          <th  mat-header-cell *matHeaderCellDef> Rabatt (%) </th>
          <td mat-cell *matCellDef="let element"> {{ element.PosDiscount | number:'1.2-2':'de'}}</td>
        </ng-container>

        <ng-container matColumnDef="TotalDiscount">
          <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Rabatt (€) </th>
          <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowDiscount(element)  | number:'1.2-2':'de'}} </td>
        </ng-container>

        <ng-container matColumnDef="Surcharge">
          <th class="tcenter" mat-header-cell *matHeaderCellDef> Zuschlag (3%) </th>
          <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowSurcharge(element)  | number:'1.2-2':'de'}} </td>
        </ng-container>

        <ng-container matColumnDef="TotalPrice">
          <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Preis(€) </th>
          <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowPrice(element) | number:'1.2-2':'de' }} </td>
        </ng-container>

        <ng-container matColumnDef="Picture" *ngIf="optionValue != 3 && optionValue != 6">
          <th mat-header-cell *matHeaderCellDef> Bilder </th>
          <td mat-cell *matCellDef="let element">
            <img class=" " src="{{IMAGE_URL}}{{element.Product.ProductNumber}}.jpg" height="80px" id="img" >
          </td>
        </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                  <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">Es wurden keine Daten gefunden,  </td>
                  </tr>

                  </table>
                </div>

  </div>

  <!-- Bestellauskunft mit/ohne Bilder/Price -->

  <div class="col-12" *ngIf="optionValue == 4 || optionValue == 5 || optionValue == 6">
    <div class="card-body" >
      <table mat-table [dataSource]="datasource" >

      <ng-container matColumnDef="ProductId" class="m-1">
        <th mat-header-cell *matHeaderCellDef> A-Nr 2</th>
        <td mat-cell *matCellDef="let element"> {{element.Product.ProductNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="DescRow1" class="m-1">
        <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
        <td mat-cell *matCellDef="let element"> {{element.Product.DescRow1}}  <br /> {{element.Product.DescRow2}}</td>
      </ng-container>

      <ng-container matColumnDef="Unit" class="mar-r">
        <th  mat-header-cell *matHeaderCellDef> Einh. </th>
        <td mat-cell *matCellDef="let element"> {{ element.Product.QuantityUnitsShortDesc }} </td>
      </ng-container>

      <ng-container matColumnDef="Quantity" class="mar-r">
        <th  mat-header-cell *matHeaderCellDef> Menge </th>
        <td mat-cell *matCellDef="let element"> {{ element.Quantity }} </td>
      </ng-container>

      <ng-container matColumnDef="Price" class="mar-r" *ngIf="optionValue != 2 || optionValue != 5">
        <th  mat-header-cell *matHeaderCellDef> Preis( € ) </th>
        <td mat-cell *matCellDef="let element"> {{ element.Price |  number:'1.2-2':'de' }}</td>
      </ng-container>

      <ng-container matColumnDef="PosDiscount" class="mar-r">
        <th  mat-header-cell *matHeaderCellDef> Rabatt(%) </th>
        <td mat-cell *matCellDef="let element"> {{ element.PosDiscount | number:'1.2-2':'de'}}</td>
      </ng-container>

      <ng-container matColumnDef="TotalDiscount">
        <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Rabatt(€) </th>
        <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowDiscount(element)  | number:'1.2-2':'de'}} </td>
      </ng-container>

      <ng-container matColumnDef="Surcharge">
        <th class="tcenter" mat-header-cell *matHeaderCellDef> Zuschlag(3%) </th>
        <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowSurcharge(element)  | number:'1.2-2':'de'}} </td>
      </ng-container>

      <ng-container matColumnDef="TotalPrice">
        <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Preis(€) </th>
        <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowPrice(element) | number:'1.2-2':'de' }} </td>
      </ng-container>

      <ng-container matColumnDef="Picture" *ngIf="optionValue != 3 && optionValue != 6">
        <th mat-header-cell *matHeaderCellDef> Bilder </th>
        <td mat-cell *matCellDef="let element">
          <img class=" " src="{{IMAGE_URL}}{{element.Product.ProductNumber}}.jpg" height="80px" id="img" >
        </td>
      </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Es wurden keine Daten gefunden,  </td>
                </tr>

                </table>
              </div>

</div>



  </div> <!-- //content -->

  <footer class="footer" id="footer">
    <app-footer></app-footer>
  </footer>

  </div>








  <mat-tab-group #tabRef (selectedTabChange)="onSelectedTabChange(tabRef)">
    <mat-tab label="Personlisches Daten">
      <app-userRegister #userRegister></app-userRegister>
    </mat-tab>

    <mat-tab label="Unternehmen Daten">
      <app-customerRegister #customerRegister></app-customerRegister>
    </mat-tab>

    <mat-tab label="Address Daten">
      <app-customerAddress #customerAddress></app-customerAddress>
    </mat-tab>

    <mat-tab label="Kontakt Daten">
      <app-customerContact #customerContact></app-customerContact>
    </mat-tab>

  </mat-tab-group>




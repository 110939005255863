


<div calss="card">
  <div calss="card-body">

    <img class="img-w" mat-card-image src="{{data.img}}"  onerror="this.src='../assets/icons/1_leider_noch_kein_Bild.jpg';" alt="{{data.productNumber}}">
  </div>
</div>


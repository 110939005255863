
          <!-- <mat-select disableOptionCentering [formControl]="toppings" placeholder="Ausschlussfilter Allergene und Zusatzstoffe" (onSelectionChange)="onSendExcludeMessage($event)" multiple> -->
          <!-- <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option> -->

    <div class="exclude">
      <mat-form-field  class="w100 " appearance="outline" matTooltip="Ausschlussfilter Allergene und Zusatzstoffe" (click)="onClick()">
        <mat-label>Ausschlussfilter</mat-label>
        <mat-select id="additiveControl" [formControl]="additiveFormControl" placeholder="Ausschlussfilter Allergene und Zusatzstoffe" (ngModelChange)="onSendExcludeMessage($event)" multiple>

          <mat-option *ngFor="let term of additives" [value]="term.Name" > ( {{term.Shortname}} ) {{term.Name}} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

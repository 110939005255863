

<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">

    <mat-toolbar color="primary" class=""> <button type="button" aria-label="Toggle sidenav"  mat-icon-button (click)="drawer.toggle()" >
      <div id="logo"><img src="../assets/icons/logo_alaska.jpg" height="60px"></div>  </button> Menu
    </mat-toolbar>
    <mat-nav-list>
      <mat-card id="matcardcontent-left">
          <!-- <div > -->
            <div class=" leftmenu">                             <div class=""> <app-filterProducts></app-filterProducts></div></div>
            <div class=" leftmenu">                             <div class=""> <app-productCategory></app-productCategory> </div></div>
            <div class=" leftmenu" *ngIf="hasRoles([1,2,3,4])"> <div class=""  > <app-searchCustomer  (outSelectedCustomerControl)="onOutSelectedCustomerControl($event)"></app-searchCustomer> </div></div>
            <div class=" leftmenu">                             <div class=""> <app-dateControl [source]="content"  (outDeliveryDateFromDateControl)="onDeliveryDateFromDateControl($event)" ></app-dateControl>  </div></div>
            <div class=" leftmenu">                             <div class=""> <app-additivesControl></app-additivesControl>  </div></div>
            <div class=" leftmenu">                             <div class=""> <app-quickBooking (outQuickBooking2ShoppingCart)="onInsertOrUpdateIdbShoppingCart($event)"></app-quickBooking>  </div></div>
            <div class=" leftmenu">                             <div class=""> <app-discountControl></app-discountControl> </div></div>

            <br>
          <mat-divider></mat-divider>
          <div class="text-primary" *ngIf="selectedCustomer">
              <span class="pinktxt"> Stammdaten</span><br />

              <table class="infotbl"  >
                <tr><td class="">Kunde:       </td><td class="wrap ">{{ selectedCustomer.CustomerNumber }} - {{ selectedCustomer.Name1 }}</td></tr>
                <tr><td class="">             </td><td class="wrap">{{ selectedCustomer.Name2 }}</td></tr>
                <tr><td class="">Adresse:     </td><td class="wrap ">{{selectedCustomer.Street}}</td></tr>
                <tr><td class="">             </td><td class="wrap ">{{selectedCustomer.Zip}}, {{selectedCustomer.City}}</td></tr>
                <tr><td class="">Kontakt:     </td><td class="wrap ">{{selectedCustomer.Contact}}</td></tr>
                <tr><td class="">Tel.:        </td><td class="wrap ">{{getFormatedTel(selectedCustomer.Phone)}}</td></tr>
                <tr><td class="">Email:       </td><td class="wrap ">{{getFormatedEmail(selectedCustomer.Email)}}</td></tr>
                <tr><td class="">Zahlung:     </td><td class="wrap ">{{selectedCustomer.PaymentTermsTxt}}</td></tr>
                <tr><td class="">Lastschr.:   </td><td class="wrap ">{{selectedCustomer.DirectDebit != undefined && selectedCustomer.DirectDebit == 0? 'Nein':'Ja'}}</td></tr>
                <tr><td class="">K-Rabatt:    </td><td class="wrap ">{{selectedCustomer.BlanketDiscount}}</td></tr>
            </table>


            </div>
            <mat-divider></mat-divider>

            <div class="" *ngIf="shoppingCartInfoBag != undefined">
              <div class="text-primary">
                <span class=" pinktxt"> Summierung</span><br />
                <table class="">
                  <!-- <tr><td class="">WK-Gesamtpreis:       </td><td class=" pinktxt">{{getshoppingcartinfobag().TotalPrice}} €</td></tr>
                  <tr><td class="">WK-Gesamtrabatt:      </td><td class=" pinktxt">{{getshoppingcartinfobag().TotalDiscount}} €</td></tr>
                  <tr><td class="">WK-Gesamtzuschlag:    </td><td class=" pinktxt">{{getshoppingcartinfobag().TotalSurCharge}} €</td></tr> -->
                  <tr><td class="">WK-Gesamtpreis:       </td><td class=" pinktxt">{{shoppingCartInfoBag.TotalPrice}} €</td></tr>
                  <tr><td class="">WK-Gesamtrabatt:      </td><td class=" pinktxt">{{shoppingCartInfoBag.TotalDiscount}} €</td></tr>
                  <tr><td class="">WK-Gesamtzuschlag:    </td><td class=" pinktxt">{{shoppingCartInfoBag.TotalSurCharge}} €</td></tr>
                </table>
              </div>

            </div>
            <mat-divider></mat-divider>
            <div class="w100" *ngIf="hasRoles([1,2,3,4,5]) && selectedCustomer != undefined "> <div class=" ptr" (click)="onCustomerInfoShow(true)"><a class=" pinktxt ptr">Mitteilungen anzeigen</a> </div> </div>
            <div class="w100" *ngIf="hasRoles([1,2,3,4]) && selectedCustomer != undefined && selectedCustomer.TourIds && selectedCustomer.TourIds != null && selectedCustomer.TourIds != '' && selectedCustomer.TourIds != '0'"> <div class=" ptr" (click)="onToursShow(selectedCustomer)"><a class=" pinktxt ptr">Liefertage</a> </div> </div>
            <div class="w100" *ngIf="hasRoles([1,2,3,4]) && selectedCustomer != undefined && selectedCustomer.SalesOverviewLink && selectedCustomer.SalesOverviewLink != null && selectedCustomer.SalesOverviewLink != ''"> <div class=" ptr" (click)="onShowSalesOverviewTab(selectedCustomer.SalesOverviewLink)"><a class=" pinktxt ptr">Umsatzübersicht</a> </div> </div>
            <div class="w100"> <div class=" ptr" *ngIf="selectedCustomer != undefined && hasOpenItems() && hasRoles([1,2,3,4])" (click)="onShowOpenItems()"><a class=" pinktxt ptr">OPs anzeigen</a> </div> </div>
            <div class="w100"> <div class=" ptr" (click)="onImpressiumDialog()"><a class=" pinktxt ptr">Impressum</a> </div> </div>
          <!-- </div> -->
      </mat-card>
    </mat-nav-list>
  </mat-sidenav>


  <mat-sidenav-content>

    <mat-toolbar color="primary">
      <div class="row">
        <!-- <div  class="col-1">    <button  type="button"  aria-label="Toggle sidenav"   mat-icon-button  (click)="drawer.toggle()" >  <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>  </button> <span class="material-icons btn-outline-success md-24 m-1" matTooltip="Warenkorb speichern und freigeben">language</span> <button><mat-icon *ngIf="isOnline == false" class="material-symbols-outlined text-danger" > language </mat-icon> </button>  </div> -->
        <div  class="col-1 ">    <button type="button"  aria-label="Toggle sidenav"   mat-icon-button  (click)="drawer.toggle()" >  <mat-icon  aria-label="Side nav toggle icon">menu</mat-icon>  </button>    </div>
        <div  class="col-5"></div>
        <div  class="col-6 "> <app-NavBar></app-NavBar>   </div>
      </div>

    </mat-toolbar>

    <mat-card class="matcardtab ">
        <div id="tab">
          <ul>
            <div *ngIf="adminMode == false ">
              <li><button class="tabbutton" id="Produkte"           *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Produkte')">           <u>P</u>rodukte</button></li>
              <li><button class="tabbutton" id="Ordersatz"          *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Ordersatz')">          <u>O</u>rdersatz</button></li>
              <li><button class="tabbutton" id="Warenkorb"          *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Warenkorb')" >         <u>W</u>arenkorb <span matBadge="{{shoppingCartCountsOfCustomer}}" matBadgeOverlap="false" matBadgeSize="small" matBadgeColor="{{badgColor}}" matBadgePosition="above after"></span>       </button></li>
              <li><button class="tabbutton" id="Auftragssammler"    *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Auftragssammler')">    <u>A</u>uftragssammler</button></li>
              <li><button class="tabbutton" id="Adminansicht"       *ngIf="(hasRoles([1,2  ])     )"  (click)="setContent('Adminansicht')">       Adminansicht</button></li>
            </div>
            <div *ngIf="adminMode == true ">
              <li><button class="tabbutton" id="Kundenverwaltung"   *ngIf="(hasRoles([1,2]) )"  (click)="setContent('Kundenverwaltung')">   Kundenverwaltung</button></li>
              <li><button class="tabbutton" id="Benutzerverwaltung" *ngIf="(hasRoles([1,2]) )"  (click)="setContent('Benutzerverwaltung')"> Benutzerverwaltung</button></li>
              <li><button class="tabbutton" id="Bestellverwaltung"  *ngIf="(hasRoles([1,2]) )"  (click)="setContent('Bestellverwaltung')">  Bestellverwaltung</button></li>
              <li><button class="tabbutton" id="Datenverwaltung"    *ngIf="(hasRoles([1,2]) )"  (click)="setContent('Datenverwaltung')">    Datenverwaltung</button></li>
              <li><button class="tabbutton" id="Kundenansicht"      *ngIf="(hasRoles([1,2]) )"  (click)="setContent('Kundenansicht')">      Kundenansicht</button></li>
          </div>
          </ul>
        </div>
    </mat-card>

    <mat-card class="matcardcontent-right">

      <div >
        <div *ngIf="(( navigation !='gotoregister' &&  !hasRoles([1,2,3,4,5]) ) || hasRoles([1,2,3,4,5]) == undefined )"  >   <app-UserLogin></app-UserLogin>  </div>
        <div *ngIf="(hasRoles([1,2,3,4,5]) ) && navigation=='gotochangepassoword'"  >                   <app-changePassword></app-changePassword>  </div>
        <div *ngIf=" !hasRoles([1,2,3,4,5]) && navigation=='gotoregister'"  >                   <app-userRegister> </app-userRegister>  </div>

        <div *ngIf="(hasRoles([1,2,3,4,5]) && adminMode == false && content=='Produkte')"  >            <app-products     #productsComponent></app-products>  </div>
        <div *ngIf="(hasRoles([1,2,3,4,5]) && adminMode == false && content=='Ordersatz')"  >           <app-orderables   #orderableComponent></app-orderables>   </div>
        <div *ngIf="(hasRoles([1,2,3,4,5]) && adminMode == false && content=='Warenkorb')"  >           <app-shopingCart [selectedCustomer]="selectedCustomer" [SelectedProductCategory]="SelectedProductCategory"  #shoppingCartComponent></app-shopingCart>   </div>
        <div *ngIf="(hasRoles([1,2,3,4,5]) && adminMode == false && content=='Auftragssammler')"  >     <app-orders       #ordersComponent></app-orders>  </div>
        <div *ngIf="(hasRoles([1,2]) && adminMode == false && content=='Adminansicht')"  >              <div></div>   </div>
        <div *ngIf="(hasRoles([1,2]) && adminMode == true && content=='Kundenverwaltung')"  >           <app-adminCustomers>  </app-adminCustomers> </div>
        <div *ngIf="(hasRoles([1,2]) && adminMode == true && content=='Benutzerverwaltung')"  >         <app-adminUsers>      </app-adminUsers>    </div>
        <div *ngIf="(hasRoles([1,2]) && adminMode == true && content=='Bestellverwaltung')"  >          <app-adminOrders>     </app-adminOrders>   </div>
        <div *ngIf="(hasRoles([1,2]) && adminMode == true && content=='Datenverwaltung')"  >            <app-AdminPublication></app-AdminPublication>  </div>
        <div *ngIf="(hasRoles([1,2]) && adminMode == true && content=='Kundenansicht')"  >              <div></div>   </div>
      </div>

    </mat-card>






  </mat-sidenav-content>
</mat-sidenav-container>



<form  [formGroup]="customerForm" >
  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="CustomerNumber" placeholder="Customer-Number">
    <span class="error-block" *ngIf="!customerForm.get('CustomerNumber').valid && (customerForm.get('CustomerNumber').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="text" class="form-control" formControlName="Name1" placeholder="Firmaname">
  <span class="error-block" *ngIf="!customerForm.get('Name1').valid && (customerForm.get('Name1').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="text" class="form-control" formControlName="Name2" placeholder="Firmaname-zusatz">
  <span class="error-block" *ngIf="!customerForm.get('Name2').valid && (customerForm.get('Name2').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="number" lang="de" class="form-control" formControlName="DiscountGroup" placeholder="DiscountGroup">
  <span class="error-block" *ngIf="!customerForm.get('DiscountGroup').valid && (customerForm.get('DiscountGroup').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="number" lang="de" class="form-control" formControlName="BlanketDiscount" placeholder="BlanketDiscount">
  <span class="error-block" *ngIf="!customerForm.get('BlanketDiscount').valid && (customerForm.get('BlanketDiscount').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="number" lang="de" class="form-control" formControlName="PriceGroup" placeholder="PriceGroup">
  <span class="error-block" *ngIf="!customerForm.get('PriceGroup').valid && (customerForm.get('PriceGroup').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="number" lang="de" class="form-control" formControlName="NetTargetDay" placeholder="NetTargetDay">
  <span class="error-block" *ngIf="!customerForm.get('NetTargetDay').valid && (customerForm.get('NetTargetDay').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="number" lang="de"class="form-control" formControlName="CashDiscountTargetDay" placeholder="CashDiscountTargetDay">
  <span class="error-block" *ngIf="!customerForm.get('CashDiscountTargetDay').valid && (customerForm.get('CashDiscountTargetDay').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="number" lang="de" class="form-control" formControlName="CashDiscountRate" placeholder="CashDiscountRate">
  <span class="error-block" *ngIf="!customerForm.get('CashDiscountRate').valid && (customerForm.get('CashDiscountRate').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="text" class="form-control" formControlName="Description" placeholder="Description">
  <span class="error-block" *ngIf="!customerForm.get('Description').valid && (customerForm.get('Description').touched  || formSubmited)"></span>
</div>
<div class="form-group col-12">

  <input type="text" class="form-control" formControlName="ShortDesc" placeholder="ShortDesc">
  <span class="error-block" *ngIf="!customerForm.get('ShortDesc').valid && (customerForm.get('ShortDesc').touched  || formSubmited)"></span>
</div>

<div class="form-group col-12">
  <button (click)="onSubmit()" type="button" class="btn btn-primary">Speichern</button>
  <button type="reset" class="btn btn-secondary ml-2">Zurücksetzen</button>
</div>

</form>

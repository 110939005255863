import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import { iCustomers, iOrders, iShoppingcartitems, iShoppingcarts,iOpenitems, iCustomerInfo, iUsers, iAgentInfo } from 'src/app/models/AlaskaModels';
import { jsPDF } from "jspdf";
import html2Canvas  from 'html2canvas';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataService } from '../../Services/data.service';
import { environment } from 'src/environments/environment';

const CUSTOMER_DATA: Array<iCustomerInfo> = [];
const AGENT_DATA: Array<iCustomerInfo> = [];

@Component({
  selector: 'app-customerInfoDialog',
  templateUrl: './customerInfoDialog.component.html',
  styleUrls: ['./customerInfoDialog.component.scss']
})
export class CustomerInfoDialogComponent implements OnInit {

  customers: iCustomers[]=[];

  customerAndAgentInfo: iCustomerInfo[];
  selectedCustomer: iCustomers;
  selectedCustomerName:string;
  selectedCustomerNumber: string
  agentCustomerNumber: string
  agentName: string


  logedInUser: iUsers;
  logedInUserIsStaff: boolean;
  customerDisabledValue:boolean = true;
  agentDisabledValue:boolean = true;


  inputForm: FormGroup;
  customerDatasource = new MatTableDataSource(CUSTOMER_DATA);
  agentDatasource = new MatTableDataSource(AGENT_DATA);
  customerConformationRequired: boolean = false;
  agentConformationRequired: boolean = false;
  agentCustomerInfos:iAgentInfo[] = [];
  customerInfos:iCustomerInfo[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  optionValue:number = 1;
  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 8;


  displayedCustomerColumns:string[] = ['Info'];
  displayedAgentColumns:string[] = ['Info'];
  customerConformationChecked:boolean = false;
agentConformationChecked:boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
              public customerinfoDialogRef: MatDialogRef<CustomerInfoDialogComponent>,
              private dataService:DataService,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }



  ngOnInit() {

    console.log('invoice dialog: ' , this.data);

    this.logedInUser = this.dataService.getLogedInUser();
    let logedInUserRoles: number[] = this.dataService.loggedInRoles;
    this.logedInUserIsStaff = this.dataService.hasRoles([1,2,3,4]);
    this.selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    this.customers = JSON.parse(localStorage.getItem('customers')) ;
     this.selectedCustomerNumber = this.selectedCustomer.CustomerNumber;
     this.selectedCustomerName = this.selectedCustomer? this.selectedCustomer.Name1:'';

    if(this.logedInUserIsStaff){
      let agent: iCustomers = this.customers  ? this.customers .find(x => x.CustomersId == this.logedInUser.CustomersId): undefined;
      this.agentCustomerNumber = agent? agent.CustomerNumber: '';
      this.agentName = agent? agent.Name1: '';
    }

    this.customerDatasource.data = this.data.customerInfos;
    this.agentDatasource.data = this.data.agentCustomerInfos;

    this.customerConformationRequired =  this.customerDatasource.data && this.customerDatasource.data.find(x => x.ConfirmRequired != 0 && x.Confirmed == 0)? true:false;
    this.agentConformationRequired = this.agentDatasource.data && this.agentDatasource.data.find(x => x.ConfirmRequired != 0 && x.Confirmed == 0)? true:false;

    if(this.customerConformationRequired  || this.agentConformationRequired ){
      this.customerinfoDialogRef.disableClose = true;
    }

  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Artikel pro Seite:';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';
  }

  onCancel(){
    this.customerinfoDialogRef.close();
  }


  populateData(){

    let customerNumberArray: string[] = [];
    if(this.selectedCustomerNumber){customerNumberArray.push(this.selectedCustomerNumber)};
    if(this.agentCustomerNumber){customerNumberArray.push(this.agentCustomerNumber)};


      this.dataService.getDataByQuerystring('custom','GetCustomerInfoByCustomerNumberArray',JSON.stringify(customerNumberArray)).subscribe(
      data => {
        this.customerAndAgentInfo = data;
        this.customerDatasource.data = this.customerAndAgentInfo.filter(x => (x.CustomerNumber == '' || x.CustomerNumber == this.selectedCustomerNumber) && x.Info != '' && x.CustomersGroup != 9992);
        this.agentDatasource.data = this.customerAndAgentInfo.filter(x => (x.CustomerNumber == '' ||  x.CustomerNumber == this.agentCustomerNumber) && x.Info != '' && x.CustomersGroup == 9992);

        this.customerConformationRequired =  this.customerDatasource.data && this.customerDatasource.data.find(x => x.ConfirmRequired != 0 && x.Confirmed == 0)? true:false;
        this.agentConformationRequired = this.agentDatasource.data && this.agentDatasource.data.find(x => x.ConfirmRequired != 0 && x.Confirmed == 0)? true:false;

        if(this.customerConformationRequired  || this.agentConformationRequired ){
          this.customerinfoDialogRef.disableClose = true;
        }


      }, err => {
        console.log('error retriving customers: ', err);
      });
   }


  onCustomerConformation($event: any){
    this.customerConformationChecked = $event? $event.checked : this.customerConformationChecked;
    this.customerDisabledValue = !this.customerConformationChecked;
   }

  onagentConformation($event: any){
    this.agentConformationChecked = $event? $event.checked : this.agentConformationChecked;
    this.agentDisabledValue = !this.agentConformationChecked;
    }

    onCustomerConformationChecked(){

      if(this.customerConformationRequired == true && this.customerConformationChecked == true )
      {
        this.customerDatasource.data.forEach(customerInfo =>{
          if(customerInfo.CustomerNumber == this.selectedCustomer.CustomerNumber){
            customerInfo.Confirmed = 1;

            this.dataService.PutData('custom',customerInfo,'putCustomerInfo').subscribe(
              data => {
                this.customerinfoDialogRef.close();
                this.populateData();
              }, err =>{
                console.log("error customerInfo update", err)
              }
            );

          }
        });

        this.agentDatasource.data.forEach(agentElement =>{
          if(agentElement.CustomerNumber == this.selectedCustomer.CustomerNumber){
            agentElement.Confirmed = 1;

            this.dataService.PutData('custom',agentElement,'putAgentInfo').subscribe(
              data => {
                this.customerinfoDialogRef.close();
                this.populateData();
              }, err =>{
                console.log("error customerInfo update", err)
              }
            );
          }
        });

      }

    }

    onAgentConformationChecked(){
      if(this.agentConformationRequired == true && this.agentConformationChecked == true ){
        this.customerAndAgentInfo.forEach(element =>{
          if(element.CustomerNumber == this.agentCustomerNumber){element.Confirmed = 1;}
        });

        this.dataService.PutData('custom',this.customerAndAgentInfo,'putCustomerInfoArray').subscribe(
          data => {
            this.customerinfoDialogRef.close();
            this.populateData();
          }, err =>{
            console.log("error customerInfo update", err)
          }
        );
      }

    }

}



import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivationStart } from '@angular/router';
import { CustomSnackbarComponent } from '../dialogs/customSnackbar/customSnackbar.component'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

constructor(private snackbar: MatSnackBar) { }


snackbarAction(msg: string, action?: string){
  let snackbarRef = this.snackbar.open(msg, action,{duration:4000});
}

snackbarCustom(msg: string,action: string, duration: number, hPosition: string){
    let snackbarRef = this.snackbar.open('snackbar', 'ok',{duration: 4000,horizontalPosition: "center"});
  // snackbarRef.dismiss();
  snackbarRef.afterDismissed().subscribe(
    () => {
      console.log("afterdismiss");

    }
  );
  snackbarRef.onAction().subscribe(
    () => {
      console.log("onaction");
    }
  );
}

openCustomSnackbar(){
  this.snackbar.openFromComponent(CustomSnackbarComponent, {duration:4000})
}

}





import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {  iOptions, iAddresses, iUsers, iCustomers, iTours, iInputOptions } from 'src/app/models/AlaskaModels';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import {MatTabsModule, MatTab, MatTabGroup} from '@angular/material/tabs'


@Component({
  selector: 'app-customerRegister',
  templateUrl: './customerRegister.component.html',
  styleUrls: ['./customerRegister.component.css']
})
export class CustomerRegisterComponent implements OnInit {

  customerForm: FormGroup;
  formSubmited: boolean;
  @ViewChild("addressDiv") addressDiv: ElementRef;
  @ViewChild('tabRef') tabRef: MatTabGroup;

  gates: iTours[]= [];
  loggedInUser: iUsers;
  customer: iCustomers;
  inputOptions: iInputOptions;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef) { }

  ngOnInit() {

    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
    this.createCustomerForm();
    this.gates = this.inputOptions['gates']; //('gate');
    this.dataService.loggedInUserSubject$.subscribe((data) => {
      this.loggedInUser = this.dataService.getLogedInUser();
    });
  }

  onSubmit(){

  }

  getLsOption(filterOption: string): iOptions[]{
    let lsOptions: iOptions[] = JSON.parse(localStorage.getItem('options'));
    return lsOptions.filter(x => x.Name.toLowerCase() == filterOption.toLocaleLowerCase());
  }


  createCustomerForm(){
    this.customerForm = this.formBuilder.group(
      {
        CustomerNumber: ['', []],
        Name1:  ['', [Validators.required]],
        Name2:  ['', []],
        DiscountGroup:   ['', []],
        BlanketDiscount:  ['', []],
        PriceGroup:   ['', []],
        NetTargetDay:   ['', []],
        CashDiscountTargetDay:   ['', []],
        CashDiscountRate:   ['', []],
        Description:  ['', [Validators.required]],
        ShortDesc:  ['', []],

      }
    )
  }
  setCustomer(){
    this.customer = {
      CustomersId: 0,
      CustomerNumber: this.customerForm.get('CustomerNumber').value,
      Name1:  this.customerForm.get('Name1').value,
      Name2:  this.customerForm.get('Name2').value,
      DiscountGroup:   +this.customerForm.get('DiscountGroup').value,
      BlanketDiscount:  +this.customerForm.get('BlanketDiscount').value,
      PriceGroup:   +this.customerForm.get('PriceGroup').value,
      NetTargetDay:   +this.customerForm.get('NetTargetDay').value,
      CashDiscountTargetDay:  +this.customerForm.get('CashDiscountTargetDay').value,
      CashDiscountRate:   +this.customerForm.get('CashDiscountRate').value,
      Description:  this.customerForm.get('Description').value,
      ShortDesc:  this.customerForm.get('ShortDesc').value,
    }
localStorage.setItem('reg-customer',JSON.stringify(this.customer));
}

  onNextClick(){
    this.setCustomer();
    console.log('LScustomer: ' , localStorage.getItem('reg-customer'))

  }

}

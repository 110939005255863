
<button (click)="toggelDiv()" type="button" class="collapsible">Open Collapsible</button>
<div class="toggle" #addressDiv>
  <p>collapsed area...</p>
</div>



<div class="card">
  <div class="card-header">
Bitte kunden adresse eingeben.
  </div>
  <div class="card-body">

    <form  [formGroup]="addressForm" >
      <div class="form-group col-12">

          <mat-select class="form-control" formControlName="AddressTypeId" placeholder="Adresstype">
            <mat-option *ngFor="let addressType of addressTypes" [value]="addressType.AddressestypesId">{{ addressType.Name }}</mat-option>
          </mat-select>

      </div>
      <div class="form-group col-12">

        <input type="text" class="form-control" formControlName="Street" placeholder="Strasse">
        <span class="error-block" *ngIf="!addressForm.get('Street').valid && (addressForm.get('Street').touched  || formSubmited)"></span>
    </div>
    <div class="form-group col-12">

      <input type="text" class="form-control" formControlName="Street1" placeholder="Strasse zusatz">

    </div>
    <div class="form-group col-12">

      <input type="text" class="form-control" formControlName="Zip" placeholder="PLZ">
      <span class="error-block" *ngIf="!addressForm.get('Zip').valid && (addressForm.get('Zip').touched  || formSubmited)"></span>
    </div>
    <div class="form-group col-12">

      <input type="text" class="form-control" formControlName="City" placeholder="Stadt">
      <span class="error-block" *ngIf="!addressForm.get('City').valid && (addressForm.get('City').touched  || formSubmited)"></span>
    </div>
    <div class="form-group col-12">

      <input type="text" class="form-control" formControlName="District" placeholder="Kreis">
      <span class="error-block" *ngIf="!addressForm.get('District').valid && (addressForm.get('District').touched  || formSubmited)"></span>
    </div>
    <div class="form-group col-12">

      <input type="text" class="form-control" formControlName="Country" placeholder="Land">
      <span class="error-block" *ngIf="!addressForm.get('Country').valid && (addressForm.get('Country').touched  || formSubmited)"></span>
    </div>
    <div class="form-group col-12">


      <span class="error-block" *ngIf="!addressForm.get('Tours').valid && (addressForm.get('Tours').touched  || formSubmited)"></span>
    </div>

    <div class="form-group col-12">
      <button (click)="onSubmit()" type="button" class="btn btn-primary">Speichern</button>
      <button type="reset" class="btn btn-secondary ml-2">Zurücksetzen</button>
    </div>

    </form>

  </div>
</div>

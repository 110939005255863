
<mat-card class = "card">
  <mat-card-header>

     <mat-card-title> <span matTooltip="nachricht"  class="material-icons btn-outline-primary button-big">attach_file</span>  Bitte geben Sie Ihre Nachricht ein... </mat-card-title>
     <mat-card-subtitle>Alaska kümmert sich um Ihre Zufriedenheit.</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content >


     <br>


    <Form  [formGroup]="inputForm">
     <mat-form-field class="w100" appearance="outline">
      <textarea type="text" class="input-group-text input-textarea" matInput placeholder="Ihre Nachricht" formControlName="inputInfo"></textarea>
     </mat-form-field>
    </Form>

  </mat-card-content>
  <mat-card-actions>
    <div class=row>
      <div class="col-6"><button class="w100 btn-danger" mat-flat-button (click)="onCancel()" mat-button>abrechen</button></div>
      <div class="col-6"><button class="w100 btn-primary" mat-flat-button (click)="onSave()" mat-button >speichern</button></div>

    </div>

  </mat-card-actions>
</mat-card>

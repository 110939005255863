import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit} from '@angular/core';

import { FormControl } from '@angular/forms';
import { DataService } from '../Services/data.service';
import { iCustomers, iParameterBag, iShoppingcarts } from '../models/AlaskaModels';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { now } from 'moment';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";

import localeDe from '@angular/common/locales/de';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { IdbService } from '../Services/idb.service';
import { SnackbarService } from '../Services/snackbar.service';
registerLocaleData(localeDe, 'de');

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dateControl',
  templateUrl: './dateControl.component.html',
  styleUrls: ['./dateControl.component.scss'],

  providers: [

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],

})
export class DateControlComponent implements OnInit {


  deliveryDate: Date;
  localstorageDate: Date;
  shopingCartDeliveryDate: Date;

  DeliveryDateForm:FormControl;
  minDate: Date = new Date(Date.now() );
  selectedCustomer: iCustomers;
  pipe = new DatePipe('de-DE');
  @Input() source:string ;
  parameterBagSubject: iParameterBag = {};

  @Output() outDeliveryDateFromDateControl: EventEmitter<Date> =   new EventEmitter();


  constructor(
    private dataService: DataService,
    private idbService: IdbService,
    private snackbarService: SnackbarService,
    private _adapter: DateAdapter<any>

    ) { }

  ngOnInit() {
      // parameterBagSubject: iParameterBag;
      // this.dataService.parameterBagSubject$.subscribe(
      //   (bag) => {
      //       this.parameterBagSubject = bag;
      //       this.selectedCustomer = bag && bag.SelectedCustomer? bag.SelectedCustomer : this.selectedCustomer;
      //       this.deliveryDate = bag && bag.DeliveryDate? bag.DeliveryDate: this.deliveryDate;
      // }, (err) => { console.log('error - parameterBagSubject-subscription: ', err);  });

    this._adapter.setLocale('de');

    this.setDeliveryDateForm();
    this.selectedCustomer = this.selectedCustomer == undefined? JSON.parse(localStorage.getItem("selectedCustomer")): this.selectedCustomer;

    this.dataService.selectedCustomerSubject$.subscribe(
      (selectedCustomer) => {
        this.selectedCustomer = selectedCustomer;

        this.DeliveryDateForm.reset();
        this.deliveryDate = new Date(Date.now());
        this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
        this.DeliveryDateForm = new FormControl(this.deliveryDate);
        this.onDateSelect(this.deliveryDate);

      },
      (err) => {
        console.log('error date control - selectedCustomer-subscription: ', err);
      }
    );

    this.dataService.msgSubject$.subscribe((msg) => {
      if (msg.Name.toLowerCase() == 'resetdeliverydate' || msg.Name.toLowerCase() == 'orderadded') {
        this.setDeliveryDateForm(this.deliveryDate );
      }
    });

  }

  setDeliveryDateForm(newDeliveryDate?: Date){

    this.minDate = new Date(this.pipe.transform(new Date(),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    this.deliveryDate = new Date(this.pipe.transform(new Date(this.minDate),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    this.DeliveryDateForm = new FormControl(this.deliveryDate );

    if(newDeliveryDate){
      this.deliveryDate = newDeliveryDate ; new Date(this.pipe.transform(new Date(newDeliveryDate),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

    }else{

        this.localstorageDate = localStorage.getItem('deliveryDate')? new Date(localStorage.getItem('deliveryDate')):this.deliveryDate;
        this.deliveryDate =  this.localstorageDate? this.localstorageDate: this.deliveryDate;
        this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

    }

    this.DeliveryDateForm = new FormControl(this.deliveryDate );
    this.dataService.setDeliveryDateSubject(this.deliveryDate);

  }


  onDateSelect($event: any){

    let message:string = 'Diese Aktion wirkt sich auf Ihr aktives WK aus., Möchten Sie den WK Vertreterpreis beibehalten?';

    this.deliveryDate =  $event? new Date($event): this.deliveryDate;
    this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    localStorage.setItem('deliveryDate',this.deliveryDate.toString());
    this.setDeliveryDateForm(this.deliveryDate);

    let source = this.source;
    console.log(this.source);

    if(source != 'Warenkorb'){
      if(this.dataService.hasRoles([5])){
        this.idbService.populateCustomerData(this.selectedCustomer, this.deliveryDate, true);
        this.idbService.setDeliveryDateOfActiveShoppingCart( this.selectedCustomer , this.deliveryDate, false );
      }else{
        this.idbService.getCustomerProductPricesByDeliverydate(this.idbService.idbAllProducts,this.selectedCustomer , this.deliveryDate);
        this.idbService.setDeliveryDateOfActiveShoppingCartWithPriceConformation( this.selectedCustomer , this.deliveryDate, message );
      }

    }else{

      this.idbService.getCustomerProductPricesByDeliverydate(this.idbService.idbAllProducts,this.selectedCustomer , this.deliveryDate);
      // this.idbService.setDeliveryDateOfActiveShoppingCartWithPriceConformation( this.selectedCustomer , this.deliveryDate, message );
      this.outDeliveryDateFromDateControl.emit(this.deliveryDate);

    }

  }

}



import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit, OnChanges, SimpleChanges, TemplateRef} from '@angular/core';
import {iUsers,iCustomers,iShoppingcartitems,iShoppingcarts, iProductcategories, iOrders, iAddresses, iProducts, iShoppingCartInfoBag, iTooltipDiscountDisplay, iProductstocks, iParameterBag} from '../models/AlaskaModels';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { environment } from 'src/environments/environment';

import { DataService } from '../Services/data.service';

import { DialogService } from '../Services/dialog.service';
import { SnackbarService } from '../Services/snackbar.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoInputDialogComponent } from '../dialogs/infoInputDialog/infoInputDialog.component';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { IndexKind } from 'typescript';
import { IdbService } from '../Services/idb.service';
import { Location, formatDate,  DatePipe, registerLocaleData, JsonPipe  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import { listenerCount } from 'process';
import { ImgDialogComponent } from '../imgDialog/imgDialog.component';
import { switchMap, take } from 'rxjs/operators';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription, fromEvent, of } from 'rxjs';
import { SearchCustomerComponent } from '../searchCustomer/searchCustomer.component';
import { InprogressDialogComponent } from '../dialogs/inprogressDialog/inprogressDialog.component';


registerLocaleData(localeDe, 'de');

const ELEMENT_DATA: Array<iShoppingcartitems> = [];
const ELEMENT_DATA_LIST: Array<iShoppingcarts> = [];

@Component({
  selector: 'app-shopingCart',
  templateUrl: './shopingCart.component.html',
  styleUrls: ['./shopingCart.component.scss'],
})
export class ShopingCartComponent implements OnInit {

  loggedInUser: iUsers;
  SelectedCustomer: iCustomers;
  allShoppingCarts: iShoppingcarts[] = [];
  shoppingCartArray: iShoppingcarts[] = [];
  SelectedCustomerAddresses: iAddresses[] = [];
  SelectedCustomerContacts: iUsers[] = [];
  selectedProductCategory: iProductcategories;
  selectedShoppingCart: iShoppingcarts;
  selectedShoppingCartItemsArray: Array<iShoppingcartitems> = [];
  datasource = new MatTableDataSource(ELEMENT_DATA);
  datasourceList = new MatTableDataSource(ELEMENT_DATA_LIST);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  shopingCartItemsFilled = false;
  orderForm: FormGroup;
  IMAGE_URL = environment.IMAGE_URL;
  aktiveShoppingCartId:string = '';
  aktiveShoppingCartDeliveryDate: Date ;

  @Input() selectedCustomer: iCustomers;

  @Input() SelectedProductCategory: iProductcategories;

  displayedShoppingCartListColumns:string[] = ['ShoppingcartsId', 'Date', 'CustomerName', 'Customernumber', 'Name', 'DeliveryDate', 'Transfer', 'Actions'];
                                // 'PosIndex',
  displayedColumns:string[] = [ 'Artikel','DescRow1','QuantityInput', 'Unit', 'PriceInput', 'PosDiscount','ProdInfo','Spec','Picture', 'Stock', 'Price','TotalDiscount',  'TotalPrice','Info','Action'];


  mode: number = 0;
  isReadonly:boolean = true;
  showShoppingCartList:boolean = false;
  formSubmited: boolean;
  deliveryDate:Date;
  formatedDate:string;
  pipe = new DatePipe('de-DE');
  matTabName: string;
  shoppingCartInfoBag: iShoppingCartInfoBag;
  transferCustomerNumber: string;
  inputwkorderinfo:string = '';
  inputwkcomment:string = '';
  pageSize: number = 50;
  pageView: string = '';
  focustedInputId: string = '';
  focusedRowId: string = '';
  coloredRowId:string = '';
  coloredRowIndex: number=0;
  focusedRowIndex: number=0;
  deliverydatechangedMsg:number = 0;
  isLoading:boolean = false;

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private dialogService: DialogService,

    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private idbService: IdbService,
    private modalService: NgbModal

  ) {}

  populateData(selectedCustomer: iCustomers){

    this.shoppingCartArray = this.idbService.getAllCusromerShoppingCarts(selectedCustomer);
    this.selectedShoppingCart = this.idbService.getActiveCustomerShoppingCart(selectedCustomer);
    this.selectedShoppingCartItemsArray = this.selectedShoppingCart ? this.selectedShoppingCart.Shoppingcartitems: [];
    this.datasourceList.data = this.shoppingCartArray? this.shoppingCartArray.filter(x => x.CustomerId == selectedCustomer.CustomersId ):[];
    this.datasource.data = this.selectedShoppingCartItemsArray? this.selectedShoppingCartItemsArray.sort((a, b) =>   a.PosIndex < b.PosIndex ? -1 : 1): [];
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

    this.clearCommentInput();

    setTimeout(() => { if(this.selectedShoppingCart){
      this.inputwkcomment = this.selectedShoppingCart? this.selectedShoppingCart.Comment: '' ;
      this.inputwkorderinfo = this.selectedShoppingCart? this.selectedShoppingCart.OrderInfo: '' ;
      this.focusFirstRow(this.selectedShoppingCart.Shoppingcartitems);
      this.aktiveShoppingCartId = this.selectedShoppingCart.ShoppingcartsId.toString();
      this.aktiveShoppingCartDeliveryDate = this.selectedShoppingCart.DeliveryDate;
    } }, 0);
      this.dataService.getAndSetShoppingCartInfoBag(this.selectedShoppingCart);



  }

  createOrdersForm(order?: iOrders){
    this.orderForm = this.formBuilder.group(
      {
        OrdersId:        		  [order != undefined && order.OrdersId != undefined? order.OrdersId : 0, []],
        ShoppingCartsId:      [order != undefined && order.ShoppingCartsId != undefined? order.ShoppingCartsId : 0, []],
        PreparedBy:        		[order != undefined && order.PreparedBy != undefined? order.PreparedBy : 0, []],
        OrderedBy:        		[order != undefined && order.OrderedBy != undefined? order.OrderedBy : 0, []],
        OrderedFor:        		[order != undefined && order.OrderedFor != undefined? order.OrderedFor : 0, []],
        DeliveryAddress:      [order != undefined && order.DeliveryAddress != undefined? order.DeliveryAddress : 0, []],
        InvoiceAddress:       [order != undefined && order.InvoiceAddress != undefined? order.InvoiceAddress : 0, []],
        OrderStatesId:        [order != undefined && order.OrderStatesId != undefined? order.OrderStatesId : 0, []],
        OrderInfo:        	  [order != undefined && order.ShoppingCarts.OrderInfo != undefined? order.ShoppingCarts.OrderInfo : '', []],
        Comment:        		  [order != undefined && order.ShoppingCarts.Comment != undefined? order.ShoppingCarts.Comment : '', []],
        DeliveryDate:        	[order != undefined && order.DeliveryDate != undefined? order.DeliveryDate : '', []],



      }
    );
  }


deliverToday:Boolean;
parameterBagSubject: iParameterBag = {};
windowWidth = window.innerWidth;
windowHeight = window.innerHeight;
resizeObservable$: Observable<Event>
resizeSubscription$: Subscription
smallDeviceSize = 1000;
changeSmallDeviceMode = false;
changeDeviceMode(){
  this.smallDeviceSize = localStorage.getItem('smallDeviceSize')? +localStorage.getItem('smallDeviceSize') : this.smallDeviceSize;
  this.windowWidth = window.innerWidth;
  this.windowHeight = window.innerHeight;
  this.changeSmallDeviceMode = this.windowWidth  < this.smallDeviceSize? true : false;
  if( this.changeSmallDeviceMode == true){
    this.displayedColumns= [ 'Artikel','DescRow1','QuantityInput','Action', 'Unit', 'PriceInput', 'PosDiscount','ProdInfo','Spec','Picture', 'Stock', 'Price','TotalDiscount',  'TotalPrice','Info'];
  }
  else{
    this.displayedColumns = [ 'Artikel','DescRow1','QuantityInput', 'Unit', 'PriceInput', 'PosDiscount','ProdInfo','Spec','Picture', 'Stock', 'Price','TotalDiscount',  'TotalPrice','Info','Action'];

  }
}

  ngOnInit() {
    this.changeDeviceMode();
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.changeDeviceMode();
    })

  // parameterBagSubject: iParameterBag;
    // this.dataService.parameterBagSubject$.subscribe(
    //   (bag) => {
    //         this.parameterBagSubject = bag;
    //         this.selectedCustomer = bag && bag.SelectedCustomer? bag.SelectedCustomer : this.selectedCustomer;
    //         let newDeliveryDate = bag && bag.DeliveryDate? bag.DeliveryDate: this.deliveryDate;
    //   }, (err) => { console.log('error - parameterBagSubject-subscription: ', err);  });

    console.log('coolkauf online: ' , navigator.onLine)
    this.deliveryDate = new Date(localStorage.getItem('deliveryDate'));
    this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

    this.displayedColumns = this.dataService.hasRoles([1,2,3,4]) == true? this.displayedColumns: this.displayedColumns.filter(x => x != 'StockValue' && x != 'PosDiscount');

    this.datasource.data =  this.selectedShoppingCartItemsArray.sort((a, b) =>   a.PosIndex < b.PosIndex ? -1 : 1);;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

    this.SelectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    this.SelectedCustomerAddresses =JSON.parse(localStorage.getItem("sca"));
    this.SelectedCustomerContacts = JSON.parse(localStorage.getItem("scc"));
    console.log("ngOnInit");

    this.displayedColumns = this.hasRoles([1,2,3,4]) == false ? this.displayedColumns.filter(x => x != 'PriceInput'):this.displayedColumns ;
    this.createOrdersForm();

      this.dataService.productsQuantitySubject$.subscribe(
        product => {
          if(this.datasource.data.find(x => x.Product.ProductsId == product.ProductsId) != undefined){
            this.datasource.data.find(x => x.Product.ProductsId == product.ProductsId).Product.QuantityInput = product.QuantityInput;
            this.datasource.data.find(x => x.Product.ProductsId == product.ProductsId).Quantity = product.QuantityInput;
          }
        }
      );


    this.dataService.selectedCustomerSubject$.subscribe(
      customer => {
        this.SelectedCustomer = customer? customer: this.SelectedCustomer ;
        this.SelectedCustomerAddresses =JSON.parse(localStorage.getItem("sca"));
        this.SelectedCustomerContacts = JSON.parse(localStorage.getItem("scc"));

        this.clearCommentInput();

    }, err =>{
        console.log("error selected customer: msg: ", err);
    });

    if(this.SelectedCustomer ){
      this.populateData(this.SelectedCustomer);
    }

    this.dataService.selectedProductCategory$.subscribe(
      productCategory => {
      this.selectedProductCategory = productCategory;}
    );
    this.dataService.activeProductsSubject$.subscribe(
      (productsData) => {
      },
      (err) => { console.log('error - products-subscription in shoppingcart component: ', err); }
    );

    this.dataService.loggedInUserSubject$.subscribe(
      user => { this.loggedInUser = user;
      }, err =>{
        console.log("error user loggin: ", err);
    } );

    this.dataService.shoppingCartSourceSubject$.subscribe(
      shoppingCartChanged => {
        this.populateData(this.selectedCustomer);
      }, err =>{
        console.log("error shoppingCartChanged: ", err);
    } );


    this.dataService.msgSubject$.subscribe(
      msg => {

        if(msg.Name.toLowerCase() == "shoppingcartselected"){
          this.selectedShoppingCart = msg.Data;

          this.populateData(this.SelectedCustomer);


            if(msg.Message.toLowerCase() == 'warenkorb'){ setTimeout(() => {this.focusFirstRow(this.datasource.filteredData); }, 0); }

        }
        if(msg.Name.toLowerCase() == "applyproductfilter"){
          this.datasource.filterPredicate = (data: iShoppingcartitems, filter: string) => (JSON.stringify(data).toLowerCase().indexOf(filter)) != -1;
          this.datasource.filter = msg.Message.trim().toLowerCase();
            if(msg.Type.toLowerCase() == 'enter'   || msg.Type.toLowerCase() == 'numpadenter'){setTimeout(() => {this.focusFirstRow(this.datasource.filteredData); }, 0); }

        }
        if(msg.Name.toLowerCase() == "shoppingcartitemadded" || msg.Name.toLowerCase() == "shoppingcartitemremoved" ){
          this.populateData(this.SelectedCustomer);

          if( msg.Source != 'quickbooking'){  setTimeout(() => {this.focusFirstRow(this.datasource.filteredData) }, 0);      }

          console.log("shoping cart: msg shoppingcartitemadded");
        }
        if(msg.Name.toLowerCase() == "shoppingcartadded" ){
          if(msg.Data){
            this.selectedShoppingCart = msg.Data

            this.populateData(this.SelectedCustomer);
            this.dataService.setShoppingCartInfoBagSubject(this.shoppingCartInfoBag );

            setTimeout(() => {this.focusFirstRow(this.datasource.filteredData) }, 0);
            console.log("shoping cart: msg shoppingcartadded");
          }

        }
        if(msg.Name.toLowerCase() == "shoppingcarttransfered" || msg.Name.toLowerCase() == "shoppingcartimported"){
          if(msg.Data){
            this.selectedShoppingCart = msg.Data;

            // this.dataService.setSelectedCustomerSubject(this.selectedCustomer );
            this.populateData(this.selectedCustomer);
            // this.dataService.setShoppingCartInfoBagSubject(this.shoppingCartInfoBag );

            setTimeout(() => {this.focusFirstRow(this.datasource.filteredData) }, 0);
            console.log("shoping cart: msg shoppingcarttransfered");
          }

        }

        if(msg.Name.toLowerCase() == "userloggedout"){
          this.selectedShoppingCart = undefined;
          this.selectedCustomer = undefined;
          this.datasource.data = [];

          this.dataService.getAndSetShoppingCartInfoBag(this.selectedShoppingCart);
        }

        if(msg.Name.toLowerCase() == "applyexcludefilter"){
          console.log("shoping cart: excludeTermsr", msg.Message.split(','));
          let excludeTerms: string[] = msg.Message.split(',');
          let shoppingCartItemsArray: iShoppingcartitems[] = this.selectedShoppingCartItemsArray;
          if(excludeTerms && excludeTerms.length > 0){
          excludeTerms.forEach(
            x =>  {
                if(x != ""){
                  shoppingCartItemsArray = shoppingCartItemsArray.filter(product => JSON.stringify(product).toLowerCase().indexOf(x.toLowerCase()) == -1) ;
                }

            })
          }
            this.populateData(this.SelectedCustomer);
            console.log("shoping cart filtered data: ", shoppingCartItemsArray);
        }

      }
    );

    setTimeout(() => {
      // this.inputwkorderinfo = localStorage.getItem('wkorderinfo')?localStorage.getItem('wkorderinfo'):'';
      // this.inputwkcomment = localStorage.getItem('wkcomment')?localStorage.getItem('wkcomment'):'';
      // this.selectedShoppingCart.Comment = this.inputwkcomment;
      // this.selectedShoppingCart.OrderInfo = this.inputwkorderinfo;
     }, 0);



  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator

  }

  setBulkDiscount(shoppingcartitem: iShoppingcartitems):iShoppingcartitems{
    return this.dataService.setBulkDiscount(shoppingcartitem);
  }

  onFocusOutPricePosDiscountQuantityInput(shoppingcartitem: iShoppingcartitems, $event, rowIndex?: number, prefix?: string): iShoppingcartitems{

    this.clearRowBgColor('rowSC-',rowIndex);
    let shoppingcartitemIndex:number = this.selectedShoppingCart.Shoppingcartitems.indexOf(shoppingcartitem);

    let isQtyInputPrefix = prefix && prefix == 'QuantityInputSC'? true: false;

    let event = $event;
    let targetPrefix:string = event.currentTarget.id != undefined? event.currentTarget.id.toString().split('-')[0]:'';
    let targetId:string = event.currentTarget.id;
    if(event.ctrlKey != true){

      shoppingcartitem.PosDiscount = shoppingcartitem.PosDiscount == null? 0.00: shoppingcartitem.PosDiscount;
      let currentQuantityInput  = shoppingcartitem.Product.QuantityInput? +shoppingcartitem.Product.QuantityInput.toFixed(2): 0.00;

      let offeredQuantityInput  =  shoppingcartitem.Product.QuantityInput? +shoppingcartitem.Product.QuantityInput.toFixed(2): 0.00;

      let currentPrice          = shoppingcartitem.Product.Price ? +shoppingcartitem.Product.Price.toFixed(2): 0.00;
      let offeredPrice          =  shoppingcartitem.Price? +(shoppingcartitem.Price).toFixed(2): 0.00;
      let offeredDiscountpercent      = shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount;
      let offeredDiscount       =  shoppingcartitem.PosDiscount ? +(offeredPrice * shoppingcartitem.PosDiscount / 100).toFixed(2): 0.00;
      let offeredNetPrice       =  offeredPrice - offeredDiscount? +(offeredPrice - offeredDiscount).toFixed(2): 0.00;
       let discountedPrice      =  +(offeredPrice - (offeredPrice * offeredDiscount / 100)).toFixed(2);

      let minSellPrice          = +(shoppingcartitem.Product.AveragePurchasePrice * 1.17).toFixed(2);
      let extraMinSellPrice     = +(shoppingcartitem.Product.AveragePurchasePrice * 1.15).toFixed(2);
      let topOnMinSellPrice     = +(shoppingcartitem.Product.AveragePurchasePrice * 0.02).toFixed(2);
      let maxPosibleDiscount    = offeredPrice - extraMinSellPrice
      let nonDividableUnits: number[] = [4,11,12,13,14,15,16,18,19,21,22,23,24,25,26,27,94]
      let kgUntitIds: number[] = [1,93];
      let KartonUntitIds: number[] = [17,40,94];
      let partialpackageCharge = this.positionRowPartialPackageCharge(shoppingcartitem);

      if(offeredQuantityInput == 0){
        this.onRemoveShoppingCartItemClick(shoppingcartitem);
        return;
      }

      shoppingcartitem.Product= this.dataService.roundUpQuantity(shoppingcartitem.Product);
      shoppingcartitem.Quantity = +shoppingcartitem.Product.QuantityInput.toFixed(2);
      // this.totalRowNetDiscount(shoppingcartitem);
      // this.totalRowNetDiscount(shoppingcartitem) = shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount + partialpackageCharge + CustomerBlanketDiscount;
      // let paushalrabatt = this.totalRowNetDiscount(shoppingcartitem);  // wenn der Produkt kein Sonderangebotspreis oder  Individualpreis hat.
      let totalRowNetDiscount = this.totalRowNetDiscount(shoppingcartitem);
      shoppingcartitem = this.setBulkDiscount(shoppingcartitem);

      if((this.hasRoles([1,2,3,4]) &&  offeredNetPrice < minSellPrice &&  currentPrice != offeredPrice) == false){

          if(shoppingcartitem.PriceType == 'I' && offeredDiscount != 0) {
            shoppingcartitem.Price = +currentPrice.toFixed(2);
            shoppingcartitem.PosDiscount = 0;
            this.dialogService.okDialog('Ungültige Eingabe', 'Auf individualpreis darf darf kein zusätzlicher Rabatt erfasst werden', 'danger','40%');

              return;

          }

          //if((shoppingcartitem.PosDiscount - shoppingcartitem.PartialPackageCharge) > 4 || (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) < 0 )

          // if((totalRowNetDiscount + shoppingcartitem.PartialPackageCharge) > 4 || (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) < 0 ){
          //   this.dialogService.okDialog('Ungültige Eingabe', 'Gesamtrabatt: '+ (totalRowNetDiscount + shoppingcartitem.PartialPackageCharge).toFixed(2) +'% ist ungültig!', 'danger', '40%');
          //   shoppingcartitem.PosDiscount = 0;
          //   return;
          // }

          //if(shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount  > 4)
          // if(totalRowNetDiscount + partialpackageCharge > 4){
          if(shoppingcartitem.PosDiscount  > 4){
            this.dialogService.okDialog('Ungültige Eingabe', 'Überschreitung der Pos.-rabatt: 4% ist ungültig!', 'danger', '40%');
            // this.dialogService.okDialog('Ungültige Eingabe', 'Überschreitung der Gesamtrabatt: 4% ist ungültig!', 'danger', '40%');
            // shoppingcartitem.PosDiscount = totalRowNetDiscount - shoppingcartitem.PosDiscount  >= 0? totalRowNetDiscount - shoppingcartitem.PosDiscount: 0;
            // shoppingcartitem.PosDiscount = shoppingcartitem.PosDiscount - shoppingcartitem.BulkDiscount >= 0? shoppingcartitem.PosDiscount - shoppingcartitem.BulkDiscount: 0;
            shoppingcartitem.PosDiscount = 0;

            return;
          }

          if(currentPrice != offeredPrice && shoppingcartitem.Product.AveragePurchasePrice == 0){ // warn if mEK is 0
            shoppingcartitem.Price = shoppingcartitem.Product.Price;
              this.dialogService.okDialog('Warnung: ungepflegter mEK' , ' Der mEK dieses Produktes beträgt 0,00 €. ' + 'Mindestpreis kann nicht ermittelt werden!', 'warning', '60%');
              if(isQtyInputPrefix == false){
                return;
              }

          }

          if(discountedPrice < minSellPrice && shoppingcartitem.PriceType == 'I' && currentPrice == offeredPrice){ // warn if a-eins preis is less than mEK + 15%, block further discount

            this.dialogService.okDialog('Mindestpreisunterschreitung', "Der ermittelter  preis: ("+discountedPrice.toString()+" €) unterschreitet den Mindest-VK mEK + 17%: (" + minSellPrice.toString()+" €)", 'warning', '60%');
            shoppingcartitem.PosDiscount = 0;
            return;
          }

          // this method is called twice b/s the conform dialog is visited latter, the method must serve the above controlls too.

          if(shoppingcartitem.Product.AveragePurchasePrice == undefined || shoppingcartitem.Product.AveragePurchasePrice == 0){
            shoppingcartitem.Product.ListPrice = shoppingcartitem.ListPrice;
            shoppingcartitem.Product.Price = shoppingcartitem.ListPrice;
            shoppingcartitem.Price = shoppingcartitem.Product.Price;
            this.dialogService.okDialog('Warnung: ungepflegter mEK' , ' Der mEK dieses Produktes beträgt 0,00 €. ' + 'Mindestpreis kann nicht ermittelt werden!', 'warning', '60%');
            if(isQtyInputPrefix == false){
              return;
            }
          }

          this.selectedShoppingCart.Shoppingcartitems[shoppingcartitemIndex] = shoppingcartitem;
          this.idbService.insertOrUpdateShoppingCartItemInActiveShoppingCart( this.selectedCustomer, shoppingcartitem);
          this.datasource.data =  this.selectedShoppingCart.Shoppingcartitems.sort((a, b) =>   a.PosIndex < b.PosIndex ? -1 : 1);;


            return shoppingcartitem;


      }else if(this.hasRoles([1,2,3,4]) &&  offeredNetPrice < minSellPrice &&  currentPrice != offeredPrice)
      {
            // this is the same as the above only to avoide double call of the method below
            shoppingcartitem = this.setBulkDiscount(shoppingcartitem);

            if(shoppingcartitem.PriceType == 'I' && offeredDiscount != 0) {
              shoppingcartitem.Price = +currentPrice.toFixed(2);
              shoppingcartitem.PosDiscount = 0;
              this.dialogService.okDialog('Ungültige Eingabe', 'Auf individualpreis darf darf kein zusätzlicher Rabatt erfasst werden', 'danger','40%');
              return;
            }

            //if((shoppingcartitem.PosDiscount - shoppingcartitem.PartialPackageCharge) > 4 || (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) < 0 )

            // if((totalRowNetDiscount + shoppingcartitem.PartialPackageCharge) > 4 || (shoppingcartitem.PosDiscount + shoppingcartitem.PartialPackageCharge) < 0 ){
            //   this.dialogService.okDialog('Ungültige Eingabe', 'Gesamtrabatt: '+ (totalRowNetDiscount + shoppingcartitem.PartialPackageCharge).toFixed(2) +'% is ungültig!', 'danger', '40%');
            //   shoppingcartitem.PosDiscount = 0;
            //   return;
            // }

            ////if(shoppingcartitem.PosDiscount + shoppingcartitem.BulkDiscount  > 4)
          // if(totalRowNetDiscount + partialpackageCharge > 4){
            if(shoppingcartitem.PosDiscount  > 4){
              this.dialogService.okDialog('Ungültige Eingabe', 'Überschreitung der Pos.-rabatt: 4% ist ungültig!', 'danger', '40%');
              // this.dialogService.okDialog('Ungültige Eingabe', 'Überschreitung der Gesamtrabatt: 4% ist ungültig!', 'danger', '40%');
              // shoppingcartitem.PosDiscount = totalRowNetDiscount - shoppingcartitem.PosDiscount  >= 0? totalRowNetDiscount - shoppingcartitem.PosDiscount: 0;
              shoppingcartitem.PosDiscount = 0;

              return;
            }

            if(currentPrice != offeredPrice && shoppingcartitem.Product.AveragePurchasePrice == 0){ // warn if mEK is 0
              shoppingcartitem.Price = shoppingcartitem.Product.Price;
                this.dialogService.okDialog('Warnung: ungepflegter mEK' , ' Der mEK dieses Produktes beträgt 0,00 €. ' + 'Mindestpreis kann nicht ermittelt werden!', 'warning', '60%');
                if(isQtyInputPrefix == false){
                  return;
                }
            }

            if(discountedPrice < minSellPrice && shoppingcartitem.PriceType == 'I' && currentPrice == offeredPrice){ // warn if a-eins preis is less than mEK + 15%, block further discount

              this.dialogService.okDialog('Mindestpreisunterschreitung', "Der ermittelter  preis: ("+discountedPrice.toString()+" €) unterschreitet den Mindest-VK mEK + 17%: (" + minSellPrice.toString()+" €)", 'warning', '60%');
              shoppingcartitem.PosDiscount = 0;
              return;
            }

          this.dialogService.conformDialog(  'Mindestpreisunterschreitung',  'Der eingegebene Preis: ('+offeredPrice.toString()+' €) abzüglich aller Rabatte ('+offeredDiscountpercent.toString()+' %) unterschreitet den Mindestverkaufspreis ('+minSellPrice.toString()+' €.)\n Soll der Mindest-VK übernommen werden?',  'info',  'Nein',  'Ja' ,'50%' ).afterClosed().subscribe(
          (data) => {
          if (data != 'ok') {
            shoppingcartitem.Price = +currentPrice.toFixed(2);
            shoppingcartitem.PosDiscount = 0;
            this.goToNextRow(targetPrefix, rowIndex, 1);
            this.goToNextInput(targetPrefix, rowIndex, 1);

          }
          else{
            shoppingcartitem.Price = +(minSellPrice / (1 - (offeredDiscountpercent / 100))).toFixed(2);
            this.goToNextRow(targetPrefix, rowIndex, 1);
            this.goToNextInput(targetPrefix, rowIndex, 1);

          }
            // this method is called twice b/s the conform dialog is visited latter, the method must serve the above controlls too.

            this.selectedShoppingCart.Shoppingcartitems[shoppingcartitemIndex] = shoppingcartitem;
            this.idbService.insertOrUpdateShoppingCartItemInActiveShoppingCart( this.selectedCustomer, shoppingcartitem);
            this.datasource.data =  this.selectedShoppingCart.Shoppingcartitems.sort((a, b) =>   a.PosIndex < b.PosIndex ? -1 : 1);;

              return shoppingcartitem;
        });
      }

  }

  }

  onRemoveShoppingCartItemClick(shoppingcartitem: iShoppingcartitems){
      let product: iProducts = shoppingcartitem.Product;
      this.selectedShoppingCart.Shoppingcartitems = this.selectedShoppingCart.Shoppingcartitems.filter(x => x.ProductId != product.ProductsId);
      this.idbService.updateCustomerShoppingCart( this.selectedCustomer, this.selectedShoppingCart);
      this.datasource.data =  this.selectedShoppingCart.Shoppingcartitems.sort((a, b) =>   a.PosIndex < b.PosIndex ? -1 : 1);;
      this.populateData(this.selectedCustomer);
  }

  removeThisAndSetOtherActiveIdbShoppingCartAndPopulate(shoppingCart: iShoppingcarts){

    if(shoppingCart){
      this.idbService.removeCustomerShoppingCart(this.selectedCustomer, shoppingCart);
      this.populateData(this.SelectedCustomer);
      this.dataService.setShoppingCartInfoBagSubject(undefined);

    }

  }

  shoppingcartselectionrow(prefix:string, rowIndex:number, shoppingcartEdit?: iShoppingcarts){
    let shoppingcartarraylength = this.shoppingCartArray != undefined? this.shoppingCartArray.length:0;
    for (let index = 0; index < shoppingcartarraylength; index++) {

      this.clearRowBgColor(prefix, this.coloredRowIndex);

    }
    let rowId = prefix +'-'+ rowIndex.toString()

    this.goToNextInput(prefix, rowIndex, 0);
    this.goToNextRow(prefix, rowIndex, 0);
  }

  onChangeMode(mode:number, shoppingcartClicked?: iShoppingcarts, selfPrefix?: string, $event?:any, rowIndex?:number ){
    // 0 = list,  1 = active,    2 = edit,    3 = create

    this.showShoppingCartList = this.showShoppingCartList == true? false:true;
    this.mode = mode != undefined? mode:this.mode;

    if(this.mode == 0)
    {
      this.onListBasketClick();
    }
    if(this.mode == 1  && shoppingcartClicked) // active
    {
      this.onActiveBasketClick(shoppingcartClicked);

    }
    if(this.mode == 2 && shoppingcartClicked)
    {

     }
    if(this.mode == 3)
    {
      this.onCreateEmptyBasketClick();
    }

    if(selfPrefix &&  selfPrefix != '' && $event != undefined &&  rowIndex != undefined)
    {
      let code:string = $event.code

      if(code == 'ArrowDown'){this.goToNextRow('shoppingcartselectionrow', rowIndex, 1);}
      if(code == 'ArrowDown'){this.goToNextInput(selfPrefix, rowIndex, 1);}
      if(code == 'ArrowUp'){this.goToNextRow('shoppingcartselectionrow', rowIndex, -1);}
      if(code == 'ArrowUp'){this.goToNextInput(selfPrefix, rowIndex, -1);}

    }

  }
  onCreateEmptyBasketClick(){

    this.idbService.createCustomerShoppingCart(this.selectedCustomer, this.deliveryDate);
    this.mode = 1

    this.populateData(this.selectedCustomer);

    this.clearCommentInput();
    this.onListBasketClick();

  }


  setShoppingcartListBackgroundRowColor(){
    setTimeout(() =>   {
      let activeRowId = 'shoppingcartselectionrow-'+this.datasourceList.data.indexOf(this.datasourceList.data.find(x => x.ShoppingCartStateId == 0));
      let activeRow = document.getElementById(activeRowId);
      if(activeRow){activeRow.style.backgroundColor = '#ccffcc'}}    , 0);
  }

  onListBasketClick(){

    this.datasourceList.data =  this.shoppingCartArray ? this.shoppingCartArray.filter(x => x.CustomerId == this.selectedCustomer.CustomersId):[];
    this.datasourceList.data  = this.datasourceList.data.sort((a, b) =>   a.ShoppingcartsId < b.ShoppingcartsId ? -1 : 1);;
    this.setShoppingcartListBackgroundRowColor();
  }

  onActiveBasketClick(shoppingcart2Active: iShoppingcarts){


    this.idbService.setCustomerShoppingCartActive(this.selectedCustomer, shoppingcart2Active);
    this.populateData(this.selectedCustomer);

  }

  onShowBasketItemsClick(shoppingcartEdit: iShoppingcarts){
    this.selectedShoppingCart =  shoppingcartEdit != undefined? shoppingcartEdit: this.selectedShoppingCart;

    if(this.SelectedCustomer && this.selectedShoppingCart && this.selectedShoppingCart.Shoppingcartitems != undefined){
      this.selectedShoppingCartItemsArray = this.selectedShoppingCart.Shoppingcartitems;
      this.datasource.data =  this.selectedShoppingCart.Shoppingcartitems.sort((a, b) =>   a.PosIndex < b.PosIndex ? -1 : 1);;

    }

  }

  onDeleteBasketClick(shoppingcartClicked: iShoppingcarts){

    if(this.selectedCustomer && shoppingcartClicked ){

      this.dialogService.conformDialog("Löschen", "Möchten Sie den Warenkorb löschen? ", "danger", "abrechen", "löschen").afterClosed().subscribe(
        data => {
          if(data != 'ok'){ return; }

          this.idbService.removeCustomerShoppingCart(this.selectedCustomer, shoppingcartClicked);
          this.populateData(this.selectedCustomer);
          this.setShoppingcartListBackgroundRowColor();

        }
      );
     }
  }

  getShoppingcartItemsCount(shoppingcartEdit: iShoppingcarts):string{
    return shoppingcartEdit? shoppingcartEdit.Shoppingcartitems.length.toString() : '';
  }


  onOrderClick(selectedShopingcart: iShoppingcarts, ShoppingCartStateId:number, autoPublish: boolean){

    console.log('shopdatasource.data: ', this.selectedShoppingCart);

      if(selectedShopingcart && ShoppingCartStateId){ selectedShopingcart.ShoppingCartStateId = ShoppingCartStateId}

    if( this.pipe.transform(selectedShopingcart.DeliveryDate,'yyyy-MM-dd') < this.pipe.transform(Date.now(),'yyyy-MM-dd')){
      this.dialogService.okDialog('Error', 'Bestelldatum liegt in vergangenheit','danger', '40%');

      return ;
    }
    else if( this.pipe.transform(selectedShopingcart.DeliveryDate,'yyyy-MM-dd') == this.pipe.transform(Date.now(),'yyyy-MM-dd')){
      this.dialogService.conformDialog(  'Warenkorb',  'Beabsichtigen Sie den ' + this.pipe.transform(selectedShopingcart.DeliveryDate,'dd.MM.yyyy') + ' als Lieferdatum?',  'info',  'Nein',  'Ja'  ).afterClosed().subscribe(
        (data) => {
            if (data != 'ok')
            {
                return;
            }
            else if (data == 'ok')
            {
              this.prepareOrder(selectedShopingcart,ShoppingCartStateId,autoPublish);
            }
            }
        );
    }
    else if( this.pipe.transform(selectedShopingcart.DeliveryDate,'yyyy-MM-dd') > this.pipe.transform(Date.now(),'yyyy-MM-dd')){

      this.prepareOrder(selectedShopingcart,ShoppingCartStateId, autoPublish);

    }
  }

  prepareOrder(shoppingCart: iShoppingcarts, ShoppingcartStateId:number, autoPublish:boolean){
    let order: iOrders;
    let nonShoppingCardTypes: number[] = [7,8];
    let name = ShoppingcartStateId && ShoppingcartStateId == 7? 'Angebot vom ':(ShoppingcartStateId && ShoppingcartStateId == 8? 'Vorlage vom ':'Warenkorb vom ');
    name = name + this.pipe.transform(Date.now(),'yyyy.MM.dd HH:mm:ss');
    let totalPrice:number = this.totalShoppingCartPrice().TotalPrice? +this.totalShoppingCartPrice().TotalPrice: 0.00;

      if(!nonShoppingCardTypes.includes(ShoppingcartStateId) && totalPrice < 100.00){
        this.dialogService.conformDialog(  'Warenkorb',  'Für Bestellungen mit einem Bestellwert von weniger als 100 € wird ein Versandzuschlag von 10 € erhoben!',  'info',  'Zurück',  'Ok'  ).afterClosed().subscribe(
        (data) => {
        if (data != 'ok') {
          return;
        }else{
          this.postOrder(shoppingCart, ShoppingcartStateId, autoPublish);

        }})
      }
      else{ // for Angebot & Vorlage
        this.postOrder(shoppingCart, ShoppingcartStateId, autoPublish);
      }

  }
  isDisabled():boolean{
    return  this.isLoading == false? false: true;
  }
  getClass(addClass: string){
    let standardClass = ' material-icons  md-24 m-1 '
    return this.isLoading == false? standardClass + addClass: standardClass + " btn-outline-warning "
  }
  postOrder(shoppingCart: iShoppingcarts, ShoppingcartStateId:number, autoPublish:boolean){
    // if(navigator.onLine == false){ this.dialogService.okDialog("Error", "anscheinend sind Sie nicht online.!!" , "danger",'40%');}
    let order: iOrders;
    let name = ShoppingcartStateId && ShoppingcartStateId == 7? 'Angebot vom ':(ShoppingcartStateId && ShoppingcartStateId == 8? 'Vorlage vom ':'Warenkorb vom ');
    name = name + this.pipe.transform(Date.now(),'yyyy.MM.dd HH:mm:ss');

    this.loggedInUser = this.dataService.getLoggedinUser();
    shoppingCart.Name = name;
    shoppingCart.UserId = this.loggedInUser.UsersId;
    shoppingCart.ShoppingCartStateId = ShoppingcartStateId == 0 || ShoppingcartStateId == 1? 3 :ShoppingcartStateId;
    order = this.createOrder(shoppingCart, ShoppingcartStateId, autoPublish);
    let shoppingcartsId: number = shoppingCart.ShoppingcartsId;
    let shoppingCart2Remove: iShoppingcarts = shoppingCart;
    console.log("post order: ", order);

    order.ShoppingCarts = this.dataService.updatePriceTypeOfShoppingCartItems(order.ShoppingCarts);
    let w = window.innerWidth > window.innerHeight ? '400px' :'90%';

    this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'Info', type:'success', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: 'OK'}}).afterOpened().subscribe(
      result =>{

      if(order.Published == 1){
          // test odata is reachable
        this.dataService.getData('custom', 'pingodata').subscribe(
          odataPing => {
            console.log("odata ping result: ", odataPing);
            if(odataPing && odataPing != 200){
              // this.isLoading = false;
              this.dialog.closeAll();
              console.log("odata ping result: ", odataPing)
              this.dialogService.okDialog("Error", "Odata ist nicht verfügbar, Ihre WK kann in CoolKauf gespeichert werden. Im Moment kann Ihre Auftrag jedoch nicht freigegeben werden!!", "danger",'40%');
              return;
            }
            else{
              // ping is OK post the order
              this.dataService.PostData('custom', order, 'PostOrders').subscribe(
                resultOrder => {
                  let postedOrder: iOrders = resultOrder;
                  // this.isLoading = false;
                  this.dialog.closeAll();
                  console.log("post result: ", resultOrder);
                  if(postedOrder == null){
                    this.dialogService.okDialog("Error", "Auftrag speichern/freigeben fehlgeschlagen!!" , "danger",'40%');
                    return
                  }
                  else if(postedOrder.Imported == null){
                    // this.isLoading = false;
                    this.dialog.closeAll();
                    console.log("imported result: ", postedOrder.Imported);
                    this.idbService.removeCustomerShoppingCart( this.SelectedCustomer, shoppingCart);
                    this.clearCommentInput();
                    this.populateData(this.SelectedCustomer);
                    this.dialogService.okDialog("Error", "Ihre Auftrag wurde erfolgreich gespeichert. aber die Auftragsfreigabe ist fehlgeschlagen!! " , "danger",'40%');
                  }
                  else if(postedOrder.Imported != null){
                    // this.isLoading = false;
                    this.dialog.closeAll();
                    console.log("imported result: ", postedOrder.Imported);
                    this.idbService.removeCustomerShoppingCart( this.SelectedCustomer, shoppingCart);
                    this.clearCommentInput();
                    this.populateData(this.SelectedCustomer);
                    this.snackbarService.snackbarAction("Ihre Auftrag wurde erfolgreich gespeichert und freigegeben.");
                  }

                }, err => {
                  // this.isLoading = false;
                  this.dialog.closeAll();
                  console.log('shoppingCart error: ', err);
                  this.dialogService.okDialog("Error", "Auftrag absenden fehlgeschlagen!!" , "danger",'40%');
                }
              );

            }
          }, err => {
            // this.isLoading = false;
            this.dialog.closeAll();
            console.log('shoppingCart error: ', err);
            this.dialogService.okDialog("Error", "Auftrag absenden fehlgeschlagen!!" , "danger",'40%');
          }
        );
      }
      else{
        // post order without publishing to Odata
        this.dataService.PostData('custom', order, 'PostOrders').subscribe(
          resultOrder => {
            // this.isLoading = false;
            this.dialog.closeAll();
            console.log("post result: ", resultOrder);

            if(resultOrder ){
                this.idbService.removeCustomerShoppingCart( this.SelectedCustomer, shoppingCart);
                this.clearCommentInput();
                this.populateData(this.SelectedCustomer);
                this.snackbarService.snackbarAction("Ihre Auftrag wurde erfolgreich gespeichert.");
              }
              else{
                this.dialogService.okDialog("Error", "Auftrag speichern fehlgeschlagen!!" , "danger",'40%');
              }

          }, err => {
            // this.isLoading = false;
            this.dialog.closeAll();
            console.log('shoppingCart error: ', err);
            this.dialogService.okDialog("Error", "Auftrag absenden fehlgeschlagen!!" , "danger",'40%');
          }
        );
      }

    // ########## end of subscribe
      });
  }

  getIcon(quantity: number, type: string): string {
    return this.dataService.getIcon(quantity,type);
  }
  bigOrSmall(quantity:number):string{
    return this.dataService.bigOrSmall(quantity);
  }
  getBg(quantity: number, type: string): string {
    return this.dataService.getBg(quantity,type);
  }


  createOrder(shoppingcart: iShoppingcarts, ShoppingCartStateId:number, autoPublish:boolean): iOrders{

    if(!shoppingcart){
      console.log('no shoppingcart to order: ',shoppingcart);

      return;
    }
    if(ShoppingCartStateId && ShoppingCartStateId != 0){
      shoppingcart.ShoppingCartStateId = ShoppingCartStateId;
    }
    return  {
      OrdersId: 0,
      ShoppingCartsId: shoppingcart.ShoppingcartsId,
      PreparedBy: shoppingcart.UserId,
      OrderedBy: shoppingcart.UserId,
      OrderedFor: shoppingcart.CustomerId,
      DeliveryAddress: +this.orderForm.get('DeliveryAddress') ?  this.orderForm.get('DeliveryAddress').value : 0,
      InvoiceAddress: +this.orderForm.get('InvoiceAddress') ?  this.orderForm.get('InvoiceAddress').value : 0,
      OrderStatesId: 0,

      // Comment: this.orderForm.get('Comment') ?  this.orderForm.get('Comment').value : '',
      // OrderInfo: this.orderForm.get('OrderInfo') ?  this.orderForm.get('OrderInfo').value : '',
      DeliveryDate:  shoppingcart.DeliveryDate,
      CreateDate:  new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
      ModifyDate:  new Date(this.pipe.transform(new Date(Date.now()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
      Published: autoPublish && autoPublish == true? 1 : 0,
      AeinsReferenceNumberId: 0,
      AeinsOrderReferenceName: '',
      ShoppingCarts: shoppingcart

    }
  }

  openInfoDialog(shoppingcartitems: iShoppingcartitems){
    this.dialogService.infoInputDialog(shoppingcartitems.PosInfo).afterClosed().subscribe(
      info => {
        console.log('info is ', info);
        if(info != undefined){
          shoppingcartitems.PosInfo = info;
          this.idbService.insertOrUpdateShoppingCartItemInActiveShoppingCart( this.selectedCustomer, shoppingcartitems);
        }
        console.log('iShoppingcartitems ', this.selectedShoppingCart.Shoppingcartitems);
      }, err => {
        console.log('error dialog ', err);
      }
    );
  }

  getTooltipDiscount(shoppingcartitem: iShoppingcartitems): iTooltipDiscountDisplay{
    return  this.dataService.getTooltipDiscount(shoppingcartitem);
  }

  totalRowNetDiscount(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowNetDiscount(shoppingcartitem);
  }

  totalRowPrice(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowPrice(shoppingcartitem);
    // setTimeout(() => {  }, 0);
  }

  positionRowPartialPackageCharge(shoppingcartitem: iShoppingcartitems): number{

    return this.dataService.positionRowPartialPackageCharge(shoppingcartitem);
  }

  totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
    return this.dataService.totalRowSurcharge(shoppingcartitem);

  }

  totalShoppingCartPrice(): iShoppingCartInfoBag{
    let totalPrice: number = 0.00;
    let totalNatoPrice: number = 0.00;
    let totalDiscount: number = 0.00;
    let totalSurCharge: number = 0.00;
    let lieferkosten: number = 0.00;

    if(this.datasource && this.datasource.data){
      this.datasource.data.forEach(row => {
          totalPrice = totalPrice + this.totalRowPrice(row);
          totalDiscount = totalDiscount + this.totalRowNetDiscount(row);
          totalSurCharge = totalSurCharge + this.totalRowSurcharge(row);
      });
    }

    totalNatoPrice = (totalPrice + totalSurCharge - totalDiscount);

    let shoppingCartInfoBag: iShoppingCartInfoBag = {
      TotalPrice: totalPrice,
      TotalNatoPrice: totalNatoPrice,
      TotalDiscount: totalDiscount,
      TotalSurCharge: totalSurCharge,
      Lieferkosten: totalPrice > 0 && totalPrice < 100 ? 10.00: 0.00
    }

    this.shoppingCartInfoBag = shoppingCartInfoBag;
    this.dataService.setShoppingCartInfoBagSubject(this.shoppingCartInfoBag );
    return shoppingCartInfoBag

  }


  onTransferBasketClick(shoppingcart2Transfer: iShoppingcarts){

    if(shoppingcart2Transfer == undefined){  return;    }

    let w = window.innerWidth > window.innerHeight ? '400px' :'90%';

    this.dialog.open(InprogressDialogComponent, {width:w, maxHeight: '90%',  disableClose:true, data: {title: 'Info', type:'success', msg: 'in Bearbeitung, bitte warten ....', noLabel: '', yesLabel: 'OK'}}).afterOpened().subscribe(

      result =>{

        if(shoppingcart2Transfer == undefined){  return;   }

        this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
        this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

        if(this.transferCustomerNumber && this.transferCustomerNumber != '' && shoppingcart2Transfer){

          console.log('shoppingCarts is ', this.transferCustomerNumber);
          if(this.transferCustomerNumber == shoppingcart2Transfer.Customer.CustomerNumber){
            this.dialog.closeAll();
            this.dialogService.okDialog("Info", "Bitte geben Sie die Kundennummer des Zielkunden an!", "danger",'40%');
            return; }

          let customersArray: iCustomers[] = JSON.parse(localStorage.getItem('customers'));
          let transferToCustomer: iCustomers = customersArray.find(x => x.CustomerNumber == this.transferCustomerNumber);
          if(transferToCustomer && shoppingcart2Transfer){

            this.idbService.transferCustomerShoppingCart( transferToCustomer,shoppingcart2Transfer);
            this.dataService.setMsgSourceSubject( 'shoppingcarttransfered',  'shoppingcarttransfered',  '',  '',  '',  "",  transferToCustomer,  0 );
            let activeShoppingCart: iShoppingcarts = this.idbService.setCustomerShoppingCartActiveByIndex(transferToCustomer, 0);
            this.dataService.setSelectedCustomerSubject(transferToCustomer);

            // this.parameterBagSubject.SelectedCustomer = transferToCustomer;
            // this.parameterBagSubject.DeliveryDate = this.deliveryDate;
            // this.parameterBagSubject.SelectedShoppingCart= activeShoppingCart;
            // this.dataService.setParameterBagSubject(this.parameterBagSubject);

            // this.populateData(transferToCustomer);

              this.dialog.closeAll();
              this.transferCustomerNumber = '';
              this.onChangeMode(0);
            }
          }
          else
          {
            this.dialog.closeAll();
            this.dialogService.okDialog("Info", "bitte kundennummer eingeben!", "danger",'40%');
          }
    // #### end of subscribe
        });


  }

  // onTransferBasketClick_old(shoppingcart2Transfer: iShoppingcarts){

  //   // if(shoppingcart2Transfer == undefined){
  //   //   return;
  //   // }

  //   this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
  //   this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

  //   // let transferCustomerNumberInput:any  = document.getElementById(inputId);
  //   // this.transferCustomerNumber = transferCustomerNumberInput != undefined? transferCustomerNumberInput.value : '';

  //   if(this.transferCustomerNumber && this.transferCustomerNumber != '' && shoppingcart2Transfer){
  //     console.log('shoppingCarts is ', this.transferCustomerNumber);
  //     if(this.transferCustomerNumber == shoppingcart2Transfer.Customer.CustomerNumber){
  //       this.dialogService.okDialog("Info", "Bitte geben Sie die Kundennummer des Zielkunden an!", "danger",'40%');
  //        return; }

  //     let customersArray: iCustomers[] = JSON.parse(localStorage.getItem('customers'));
  //     let transferToCustomer: iCustomers = customersArray.find(x => x.CustomerNumber == this.transferCustomerNumber);
  //     if(transferToCustomer && shoppingcart2Transfer){

  //       this.idbService.transferCustomerShoppingCart( transferToCustomer,shoppingcart2Transfer);
  //       this.dataService.setMsgSourceSubject( 'shoppingcarttransfered',  'shoppingcarttransfered',  '',  '',  '',  "",  transferToCustomer,  0 );
  //       this.idbService.setCustomerShoppingCartActiveByIndex(transferToCustomer, 0);
  //       this.dataService.setSelectedCustomerSubject(transferToCustomer);

  //       // this.populateData(transferToCustomer);

  //       }
  //     }
  //     else
  //     {

  //       this.dialogService.okDialog("Info", "bitte kundennummer eingeben!", "danger",'40%');
  //     }

  // }

  clearAllLocalstorageShoppingCards(){
    var i;
    for (i in localStorage) {
      if (localStorage.hasOwnProperty(i)) {
        if (i.match(/^\d+_shoppingCart$/)) {
          localStorage.removeItem(i);
        }
      }
    }
  }

  clearAllLocalstorageShoppingCartItemss(){
    var i;
    for (i in localStorage) {
      if (localStorage.hasOwnProperty(i)) {
        if (i.match(/^\d+_shoppingCartItemsArray$/)) {
          localStorage.removeItem(i);
        }
      }
    }
  }


  hasRoles(roleIds: number[]): boolean{
    return this.dataService.hasRoles(roleIds);
  }

//  #-----------------------------------------------------------------------------------------------


onInputKeyUp(shoppingcartitem: iShoppingcartitems, selfPrefix:string, nextPrefix?:string, $event?: any, rowIndex?: number){
  let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 73, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90, 83,    71, 70, 85, 82];

  if( $event && keyCodes.includes($event.keyCode) ){
    // 9:keyTab, 37:keyLeft, 38:keyUp, 39:keyRight, 40:keyDown,  13:keyEnter,
    this.onKeyUpEvent(shoppingcartitem, selfPrefix, nextPrefix, $event, rowIndex);
    return;
  }
}

onPreventStep(product: iProducts, $event?: any ){
if($event.keyCode == 38 || $event.keyCode == 40){
  $event.preventDefault();
  return;  }
}

onKeyUpEvent(shoppingcartitem?: iShoppingcartitems, selfPrefix?:string, nextPrefix?:string, $event?: any, rowIndex?: number ){
  //$event.keyCode => 9:keyTab, 37:keyLeft, 38:keyUp, 39:keyRight, 40:keyDown,  13:keyEnter,
  let code = $event != undefined? $event.code: undefined;
  let nextIndex: number;
  let id = selfPrefix+'-'+shoppingcartitem.Product.ProductsId.toString();
  let basketId = 'basket-'+shoppingcartitem.Product.ProductsId.toString();

  let prods:iProducts[] = this.idbService.idbAllProducts;
  let product:iProducts = this.idbService.idbAllProducts.find(x => x.ProductsId == shoppingcartitem.Product.ProductsId);
  if(product){shoppingcartitem.Product.AveragePurchasePrice = product.AveragePurchasePrice;  shoppingcartitem.Product.LastPurchasePrice = product.LastPurchasePrice}

  this.dataService.onKeyCombination($event, product);

  if(code && code == 'Enter'   || code == 'NumpadEnter'){

    shoppingcartitem = selfPrefix && selfPrefix == 'QuantityInputSC' ? this.idbService.setPartialPackageCharge(this.selectedCustomer, shoppingcartitem): shoppingcartitem;
     this.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix);
    this.goToNextRow('rowSC', rowIndex, 1);
    this.goToNextInput(selfPrefix, rowIndex, 1)

  }else if (code && code == 'ArrowLeft') {

    shoppingcartitem = selfPrefix && selfPrefix == 'QuantityInputSC' ? this.idbService.setPartialPackageCharge(this.selectedCustomer, shoppingcartitem): shoppingcartitem;
    this.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix);
    nextPrefix = selfPrefix == 'PriceSC'?'QuantityInputSC':(selfPrefix == 'PosDiscountSC'? 'PriceSC':'');
    this.goToNextInput(nextPrefix, rowIndex, 0);

  } else if(code && code == 'ArrowRight') {

    shoppingcartitem = selfPrefix && selfPrefix == 'QuantityInputSC' ? this.idbService.setPartialPackageCharge(this.selectedCustomer, shoppingcartitem): shoppingcartitem;
    this.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix);
    nextPrefix = selfPrefix == 'QuantityInputSC'?'PriceSC':(selfPrefix == 'PriceSC'? 'PosDiscountSC':'');
    this.goToNextInput(nextPrefix, rowIndex, 0);

  } else if(code == 'ArrowDown'){

    this.mode = 2;
    shoppingcartitem = selfPrefix && selfPrefix == 'QuantityInputSC' ? this.idbService.setPartialPackageCharge(this.selectedCustomer, shoppingcartitem): shoppingcartitem;
    this.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix);
    this.goToNextInput(selfPrefix, rowIndex, 1);
    this.goToNextRow('rowSC', rowIndex, 1);
  }
  else if(code == 'ArrowUp'){
    this.mode = 2;
    shoppingcartitem = selfPrefix && selfPrefix == 'QuantityInputSC' ? this.idbService.setPartialPackageCharge(this.selectedCustomer, shoppingcartitem): shoppingcartitem;
    this.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix);
    this.goToNextInput(selfPrefix, rowIndex, -1);
    this.goToNextRow('rowSC', rowIndex, -1);
  }

}

focusFirstRow(items: iShoppingcartitems[]){
  if(items != undefined && items.length > 0)
  {
    this.goToNextInput('QuantityInputSC', 0, 0);
    this.goToNextRow('rowSC', 0, 0);
  }
}

onClickShoppingCartSellection( prefix?: string, rowIndex?:number){

  this.clearRowBgColor(prefix, this.coloredRowIndex);
  this.goToNextInput(prefix, rowIndex, 0);
  this.goToNextRow(prefix, rowIndex, 0);

}

onFocusClickedInput( prefix?: string, rowIndex?:number){

   this.clearRowBgColor(prefix, this.coloredRowIndex);
   this.goToNextInput(prefix, rowIndex, 0);
   this.goToNextRow('rowSC', rowIndex, 0);

}

goToNextInput(prefix?: string, rowIndex?:number, nexOrPrevious?: number):boolean{
  let inputId = prefix +'-' + rowIndex.toString();
  let inputNextId = prefix +'-' + (rowIndex + nexOrPrevious).toString();
  let inputPreviousId = prefix +'-' + (rowIndex - 1).toString();
  let success = false;

  let currentInput = document.getElementById(inputId);
  let nextInput = document.getElementById(inputNextId );
  let previousInput = document.getElementById(inputPreviousId );

  if (nextInput) {
    setTimeout(() => nextInput.focus(), 0);
    this.focusedRowIndex = rowIndex + nexOrPrevious;
    success = true;
  }else if(previousInput){
    setTimeout(() => previousInput.focus(), 0);
  }
  return success;
}

goToNextRow(prefix?: string, rowIndex?:number, nexOrPrevious?: number){
prefix = prefix && prefix  != ''?prefix + '-' :'rowSC-';
  this.coloredRowId = prefix + rowIndex.toString();
  let rowNextId = prefix + (rowIndex + nexOrPrevious).toString();
  let rowPreviousId = prefix + (rowIndex - 1).toString();

  let currentRow = document.getElementById(this.coloredRowId);
  let nextRow = document.getElementById(rowNextId);
  let previousRow = document.getElementById(rowPreviousId );

  if (nextRow) {
     setTimeout(() => (currentRow.style.backgroundColor = ''), 0);
    setTimeout(() => (nextRow.style.backgroundColor = '#ccffcc'), 0);
    this.coloredRowIndex = rowIndex + nexOrPrevious;

  }else if(previousRow){
     setTimeout(() => (currentRow.style.backgroundColor = ''), 0);
    setTimeout(() => (previousRow.style.backgroundColor = '#ccffcc'), 0);

  }
}

clearAllRowsBgColor(items?: iShoppingcartitems[]){
  this.datasource.data.forEach( x => {
    let rowId = 'rowSC-' + (x.PosIndex).toString();
    let row = document.getElementById(rowId);
    if(row){
      setTimeout(() => (row.style.backgroundColor = ''), 0);
    }
  })
}

clearRowBgColor(prefix:string, rowIndex?:number){
  prefix = prefix && prefix  != ''?prefix + '-' :'rowSC-';
    let rowId = prefix + (rowIndex).toString();
    let row = document.getElementById(rowId);
    if(row){
      setTimeout(() => (row.style.backgroundColor = ''), 0);
    }
}


onOpenImgDialogMessage(item: iShoppingcartitems) {
  let img = this.IMAGE_URL  + item.Product.ProductNumber.toString() + '.jpg';

  this.dialog.open(ImgDialogComponent, {
    data: {
      img: img,
      productNumber: item.Product.ProductNumber,
      productName: item.Product.DescRow1,
      productPrice: item.Product.CustomerPrice,
      productUnit: item.Product.QuantityUnitsShortDesc,
    },
  });
}

onPrintBasketClick(){
  if(this.datasource.data){
    this.dialogService.PrintShoppingcartDialog(this.selectedCustomer, this.selectedShoppingCart).afterClosed().subscribe(
      info => {

      }, err => {
        console.log('error dialog ', err);
      }
    );
  }

}

onFocusOutEvent($event:any,id:string){
  if($event && id){
    localStorage.setItem(id,$event.currentTarget.value)
    this.selectedShoppingCart.OrderInfo = id == 'wkorderinfo'? $event.currentTarget.value: this.selectedShoppingCart.OrderInfo;
    this.selectedShoppingCart.Comment = id == 'wkcomment'? $event.currentTarget.value: this.selectedShoppingCart.Comment;
    this.idbService.updateCustomerShoppingCart(this.selectedCustomer,this.selectedShoppingCart );

  }

}



onPageSizeChange($event){
  this.paginator.pageSize = +this.pageSize;
  this.paginator.pageIndex = 0;
  this.paginator._changePageSize(+this.pageSize);
  this.pgnFirstPage();
}

getPageVisits():string{
  let visits = '';
  try{
    let nextVisit =  this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize < this.paginator.length? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize : this.paginator.length : '';
    visits = this.paginator && nextVisit != 0 && +this.paginator.length != 0?  ((this.paginator.pageIndex * this.pageSize)+1).toString() +'-'+ nextVisit.toString()+'/'+this.paginator.length : '';
  }
  catch{  }
  return  visits
}

  pgnFirstPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.firstPage();
  }
  pgnBackPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.previousPage();
  }
  pgnNextPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.nextPage();
  }
  pgnLastPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.lastPage();
  }

  onDeliveryDateFromApp(newDeliveryDate: Date){
    let message:string = 'Möchten Sie den WK Vertreterpreis beibehalten?';
    this.selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    //check if the new deliveryDate is different from the WK deliveryDate
    // let deliveryDateChanged: boolean = newDeliveryDate && this.selectedShoppingCart && new Date(new Date(this.selectedShoppingCart.DeliveryDate).getFullYear(),new Date(this.selectedShoppingCart.DeliveryDate).getMonth(), new Date(this.selectedShoppingCart.DeliveryDate).getDay(),0,0,0).getTime()
    // == (new Date(newDeliveryDate.getFullYear(),newDeliveryDate.getMonth(), newDeliveryDate.getDay(),0,0,0)).getTime()? false: true;

    //check if the new deliveryDate is different from the WK deliveryDate
    let deliveryDateChanged: boolean = newDeliveryDate && this.selectedShoppingCart && this.dataService.getDate( this.selectedShoppingCart.DeliveryDate, 'yyyy.MM.dd'  ) != this.dataService.getDate( newDeliveryDate, 'yyyy.MM.dd'  )

      if(newDeliveryDate && this.selectedShoppingCart && deliveryDateChanged == true ){

        this.selectedShoppingCart = this.idbService.setDeliveryDateOfActiveShoppingCartWithPriceConformation(this.selectedCustomer, newDeliveryDate, message);

      }
  }

  onSelectedCustomerFromAppControl(customer: iCustomers) {
    this.SelectedCustomer = customer? customer: this.SelectedCustomer ;
    this.SelectedCustomerAddresses =JSON.parse(localStorage.getItem("sca"));
    this.SelectedCustomerContacts = JSON.parse(localStorage.getItem("scc"));
    this.populateData(this.SelectedCustomer);

    // localStorage.removeItem('wkorderinfo');
    // localStorage.removeItem('wkcomment');

    // let wkorderinfo = document.getElementById('wkorderinfo');
    // let wkcomment = document.getElementById('wkcomment');
    // if(wkorderinfo){  setTimeout(() =>  wkorderinfo.innerText = '', 0);}
    // if(wkcomment){  setTimeout(() =>  wkcomment.innerText = '', 0);}

    this.shopingCartItemsFilled = true;
    this.mode = 0;
    localStorage.setItem('selectedCustomer', JSON.stringify(this.SelectedCustomer));


  }

clearCommentInput(){
  localStorage.removeItem('wkorderinfo');
  localStorage.removeItem('wkcomment');
  // let wkorderinfo = document.getElementById('wkorderinfo');
  // let wkcomment = document.getElementById('wkcomment');
  this.inputwkorderinfo = '';
  this.inputwkcomment = '';
}

removeProductWithZeroInputQuantity(shoppingcart: iShoppingcarts){
  if(shoppingcart != undefined){
    shoppingcart.Shoppingcartitems.forEach(element => {
      if(element && element.Product && element.Product.QuantityInput == 0){
        this.onRemoveShoppingCartItemClick(element);
      }
    });
  }

}

onUpdateShoppingCartItemClick(shoppingcartitem?: iShoppingcartitems, selfPrefix?:string, nextPrefix?:string, $event?: any, rowIndex?: number ){
  shoppingcartitem = selfPrefix && selfPrefix == 'QuantityInputSC' ? this.idbService.setPartialPackageCharge(this.selectedCustomer, shoppingcartitem): shoppingcartitem;
  this.onFocusOutPricePosDiscountQuantityInput(shoppingcartitem, $event, rowIndex, selfPrefix);
 this.goToNextRow('rowSC', rowIndex, 1);
 this.goToNextInput(selfPrefix, rowIndex, 1)
}
onSpecTab(url: string){
  if(url && url != '')
  {
    window.open(url);
  }
}

onProductInfo(product: iProducts){
  { this.dialogService.okDialog("Zusatzinformation", product.ProductInfo, "success",'40%');  }
}

onOdataStockUpdate(productNumber: string){
  let productstock: iProductstocks;

  this.dataService.getDataByQuerystring('custom','ProductstockOdataByProductnumber', productNumber).subscribe(
    data => {
      productstock = data;
      let prod: iProducts = this.idbService.idbActiveProducts.find(x => x.ProductNumber == productstock.ProductNumber);
      if(prod){
        prod.StockValue  = productstock.StockValue;
        prod.DispositionStockValue  = productstock.DispositionStockValue;
        prod.OpenOrdersStockValue  = productstock.OpenOrdersStockValue;
        prod.SupplierDeliveryStockValue  = productstock.SupplierDeliveryStockValue;

        let idbActiveProductsIndex = this.idbService.idbActiveProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber) ;
        let idbAllProductsIndex = this.idbService.idbAllProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber);
        let datasourcedataIndex = this.datasource.data.findIndex(x => x.Product.ProductNumber == productstock.ProductNumber);

        this.idbService.idbActiveProducts[idbActiveProductsIndex]= prod;
        this.idbService.idbAllProducts[idbAllProductsIndex]= prod;
        this.datasource.data[datasourcedataIndex].Product= prod;
      }
    }
    ), err =>{
      console.log("Error onOdataStockUpdate", err);
    };
  }

onOdataCustomerOrderShow(productNumber: string){
  this.dataService.onOdataCustomerOrderShow(productNumber);
}
onOdataSuplierOrderShow(productNumber: string){
  this.dataService.onOdataSuplierOrderShow(productNumber);
}



}

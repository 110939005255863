import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import {iUsers, iTitles, iInputOptions, iRegistrationstypes, iGenders } from '../../models/AlaskaModels';
import { DataService } from 'src/app/Services/data.service';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { DialogService } from 'src/app/Services/dialog.service';

import { Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { InputErrorStateMatcher } from 'src/app/models/InputErrorStateMatcher';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-userRegister',
  templateUrl: './userRegister.component.html',
  styleUrls: ['./userRegister.component.scss']
})
export class UserRegisterComponent implements OnInit, AfterViewInit {

  pipe = new DatePipe('de-DE');
  usersForm: FormGroup;
  formSubmited: boolean;
  user: iUsers;
  inputOptions: iInputOptions;

  hideTopPannel = true;
  activationToken: string;
  @ViewChild('tabRef') tabRef: MatTabGroup;

  Titles: iTitles[] = [];
  Genders: iGenders[] = [];
  RegistrationsTypes: iRegistrationstypes[] = [];
  matcher = new InputErrorStateMatcher();
  minDate: Date = new Date(new Date().getTime() - 365 * 18 * 24 * 60 * 60 * 1000);




  constructor(private fb: FormBuilder,

    private dialogService: DialogService,
    private dateAdapter: DateAdapter<any>,
              private http: HttpClient,
              private dataService: DataService,
              private snackbarService: SnackbarService,
              private router: Router,
              private formBuilder: FormBuilder) { }

  ngOnInit() {


    if(localStorage.getItem('inputOptions') == undefined){
        this.dataService.setInputOptions();
    }
    else{
      this.dateAdapter.setLocale('de-DE');
      this.Titles = JSON.parse(localStorage.getItem('inputOptions')).titles;
      this.Genders = JSON.parse(localStorage.getItem('inputOptions')).genders;
      this.RegistrationsTypes = JSON.parse(localStorage.getItem('inputOptions')).registrationstypes;
    }


    if(!this.Titles || !this.Genders || !this.RegistrationsTypes || this.Titles.length == 0 || this.Genders.length == 0 || this.RegistrationsTypes.length == 0){
    }

    this.dataService.setCurrentUrl("userregister");
    this.activationToken = localStorage.getItem("activationToken");
    this.createUserForm();

    console.log("title",  this.Titles);
    console.log("registrationTypes", this.RegistrationsTypes);
  }

  ngAfterViewInit(){

  }

  createUserForm(user?: iUsers){
  this.usersForm = this.formBuilder.group(
    {
      UsersId :             [user != undefined && user.UsersId != undefined? user.UsersId : 0, []],
      TitleId:              [user != undefined && user.TitleId != undefined? user.TitleId : 0, [Validators.required]],
      GenderId:             [user != undefined && user.GenderId != undefined? user.GenderId : 0, [Validators.required]],
      FirstName:            [user != undefined && user.FirstName != undefined? user.FirstName : '', [Validators.required]],
      LastName:             [user != undefined && user.LastName != undefined? user.LastName : '', [Validators.required]],
      BirthDate:            [user != undefined && user.BirthDate != undefined? user.BirthDate : '', [Validators.required]],
      Email:                [user != undefined && user.Email != undefined? user.Email : '', [Validators.required, Validators.email]],
      Password :            [user != undefined && user.Password != undefined? user.Password : '', [Validators.required, Validators.minLength(6)]],
      ConformPassword :     ['', [Validators.required, Validators.minLength(6)]],
      RegistrationsTypeId:  [user != undefined && user.RegistrationsTypeId != undefined? user.RegistrationsTypeId : 0, [Validators.required]]
    },
    {
      validators: this.passwordMatchValidation
    }
  );
}

  setUser(){
    this.user = {
      UsersId: +this.usersForm.get('UsersId').value,
      TitleId: +this.usersForm.get('TitleId').value,
      GenderId: +this.usersForm.get('GenderId').value,
      FirstName: this.usersForm.get('FirstName').value,
      LastName: this.usersForm.get('LastName').value,
      BirthDate: new Date(this.pipe.transform(this.usersForm.get('BirthDate').value,'yyyy-MM-dd', 'GMT+0200', 'de-DE')),
      Email: this.usersForm.get('Email').value,
      Password: this.usersForm.get('Password').value,
      RegistrationsTypeId: +this.usersForm.get('RegistrationsTypeId').value,

    }
localStorage.setItem('reg-user',JSON.stringify(this.user));
}
  get UsersId(){ return this.usersForm.get('UsersId');}
  get TitleId(){ return this.usersForm.get('TitleId');}
  get GenderId(){ return this.usersForm.get('GenderId');}
  get FirstName(){ return this.usersForm.get('FirstName');}
  get LastName(){ return this.usersForm.get('LastName');}
  get BirthDate(){ return this.usersForm.get('BirthDate');}
  get Email(){ return this.usersForm.get('Email');}
  get Password(){ return this.usersForm.get('Password');}
  get ConformPassword(){ return this.usersForm.get('ConformPassword');}
  get RegistrationsTypeId(){ return this.usersForm.get('RegistrationsTypeId');}

  onNextClick(){
    this.setUser();
    console.log('LSuser: ' , localStorage.getItem('reg-user'))

  }

  onSubmit(){
    console.log(this.usersForm)
    this.formSubmited = true;

    if(this.usersForm.valid){

        this.setUser();
        this.user.Password = btoa(this.user.Password);
        let age =   (+ (new Date(Date.now())).getFullYear()) - +this.user.BirthDate.getFullYear()
        if(age < 18){
          this.snackbarService.snackbarAction('Leider Eine Registrierung ist erst ab 18 Jahren möglich.');
          return
        }
        else{



          this.dataService.PostData('custom', this.user, 'postUsers').subscribe(
          result => {

            this.dialogService.okDialog('Registrierung erfolgreich', 'Vielen Dank für Ihre Registrierung – Wir informieren Sie per e-mail am nächsten Werktag über die Freischaltung Ihres Coolkauf-Zugangs','success', '40%').afterClosed().subscribe(
              (data) => {
                if(data == 'ok'){
                  this.dataService.onLogOut()
                  this.usersForm.reset();
                  window.location.reload();
                }
              }
            );

            console.log("loginValidate subscribe result: ", result);

            },
          err =>  {
            this.snackbarService.snackbarAction('Registrierungsdaten fehlgeschlagen.');
            console.log("PostData error", err)
          }
          );

          this.formSubmited = false;

          this.snackbarService.snackbarAction('Registrierungsdaten wurden erfolgreich gesendet.');
        }
      }else{

        this.snackbarService.snackbarAction('there was an error while validating user');
    }


  }

  passwordMatchValidation(fg: FormGroup): Validators{
    return fg.get('Password').value === fg.get('ConformPassword').value? null: {passwordNotMatched: true};
  }

  onReset(){
    this.usersForm.reset();
  }

}

import { PipeTransform, Pipe } from '@angular/core';
import { iCustomers } from '../models/AlaskaModels'

@Pipe({ name: 'customersFilter'})
export class CustomerFilterPipe implements PipeTransform
{
  transform(customers: iCustomers[], searchTerm: string): iCustomers[] {

    if(!customers || !searchTerm)
    {
      return customers;
    }
    else
    {
      var re = new RegExp("\\s");
      let searchTermArray: string[] = searchTerm.split(re);

      let s0: string = searchTermArray != null && searchTermArray[0] != null? searchTermArray[0] : '';
      let s1: string = searchTermArray != null && searchTermArray[1] != null? searchTermArray[1] : '';
      let s2: string = searchTermArray != null && searchTermArray[2] != null? searchTermArray[2] : '';
      let s3: string = searchTermArray != null && searchTermArray[3] != null? searchTermArray[3] : '';

        if(s0 != '' && s1 == '' && s2 == ''){
          return customers.filter(customer => JSON.stringify(customer).toLowerCase().includes(s0))
        }
        if(s0 != '' && s1 != ''  && s2 == ''){
          return customers.filter(customer => JSON.stringify(customer).toLowerCase().includes(s0) && JSON.stringify(customer).toLowerCase().includes(s1))
        }
        if(s0 != '' && s1 != ''  && s2 != ''){
          return customers.filter(customer => JSON.stringify(customer).toLowerCase().includes(s0) && JSON.stringify(customer).toLowerCase().includes(s1) && JSON.stringify(customer).toLowerCase().includes(s2))
        }

    }
  }

}


<!-- Kund ############################################ -->
<div class="card" >
  <div class="card-header txt-pink-b">  Kundenbenachrichtigungen <span class="material-icons txt-pink">  notifications   </span>
  </div>
  <div *ngIf="customerDatasource.data.length == 0">
    <br><span class="text-success"> Es gibt keine Benachrichtigung für Kunde {{selectedCustomerName}}. </span><br>
  </div>

  <div class="" *ngIf="customerDatasource.data.length > 0">
    <table class="alaska-table-container table-striped" mat-table [dataSource]="customerDatasource.data" >

      <ng-container matColumnDef="Info" class="m-1">
        <td mat-cell *matCellDef="let element">    <div class="row"> <div class="col-2 "> {{element.CustomerInfoId}} </div> <div class="col-10 "><span class="text-pink">{{element.Info}}</span></div></div> </td>
      </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedCustomerColumns;"></tr>

    </table>
    <div class="row"  *ngIf="customerConformationRequired == true">
      <div class="col-10 ">  <mat-checkbox (change)="onCustomerConformation($event)" class="mat-checkbox text-primary float-right" labelPosition="before" >Lesebestätigung</mat-checkbox>  </div>
      <div class="col-2 "> <button (click)="onCustomerConformationChecked()" class="btn  btn-primary float-right" mat-button  [disabled]="customerDisabledValue" > ok</button></div>
    </div>
  </div>

</div>

<div *ngIf="logedInUserIsStaff == true"> <br> </div>
<!-- Verträter ############################################ -->

<div class="card" *ngIf="logedInUserIsStaff == true">
  <div class="card-header txt-pink-b">
    Vertretermitteilungen <span class="material-icons text-pink">  notifications   </span>
  </div>
  <div *ngIf="agentDatasource.data.length == 0">
    <br><span class="text-success">  Es gibt keine Vertretermitteilung.  </span><br>
  </div>
  <div class="card-body" *ngIf="agentDatasource.data.length > 0">

    <table class="alaska-table-container table-striped" mat-table [dataSource]="agentDatasource.data" >

      <ng-container matColumnDef="Info" class="m-1">
        <td mat-cell *matCellDef="let element">    <div class="row"> <div class="col-4">Info: {{element.CustomerInfoId}} </div> <div class="col-8 "><span class="text-pink">{{element.Info}}</span></div></div> </td>
      </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedAgentColumns;"></tr>
    </table>
    <div  *ngIf="agentConformationRequired == true">  <div>

      <mat-checkbox (change)="onCustomerConformation($event)" class="mat-checkbox" ><span  matTooltip="Lesebestätigung"></span>Lesebestätigung</mat-checkbox>
     </div>
   </div>
  </div>
</div>


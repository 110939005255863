

<div class="card">

 <div class="card-header">
  <div class="row">
    <div class="col-12 buttonrow">
      <!-- <mat-paginator [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>  buttonrow   -->
      <div class="paginator250px ">
        <select (change)="onPageSizeChange($event)" class="small pgnselect" [(ngModel)]="pageSize"> <option  value=10>10</option> <option  value=20>20</option> <option  value=50>50</option> </select>
        <span class="small">{{getPageVisits()}}</span>
        <button (click)="pgnFirstPage()" class=" arrowxxl" id="productfirstpage"><span>&#171;</span></button>
        <button (click)="pgnBackPage()" class=" arrowxxl"><span>&#8249;</span></button>
        <button (click)="pgnNextPage()" class=" arrowxxl"><span>&#8250;</span></button>
        <button (click)="pgnLastPage()" class=" arrowxxl"><span>&#187;</span></button>
      </div>



    <div class="buttonrow ">
    <mat-form-field appearance="outline">
      <mat-label>Anzeigeoptionen</mat-label>
      <mat-select name="adminselectedfilter" [(ngModel)]="selectedFilter" (ngModelChange)="onSelectFilter($event)">
        <mat-option    *ngFor="let filter of filterOptions"  [value]="filter"> {{filter}}</mat-option>
      </mat-select>
    </mat-form-field>



        <button (click)="onList()"    class="mat-icon-button "><span class="material-icons btn-outline-primary md-36 m2" matTooltip="Listenansicht">view_list</span></button>

        <button (click)="onSendOdataOrdersToAeins()"   class="mat-icon-button " *ngIf="selectedFilter == 'freigegeben' "><span class="material-icons btn-outline-danger md-36 m2" matTooltip="A.eins-Odata-Übergabe">send</span></button>
      </div>
    </div>
  </div>

</div>

<div class="alaska-table-container mat-elevation-z8 tables table-striped" *ngIf="mode=='list'">
 <table mat-table [dataSource]="datasource" matSort matSortActive="CreateDate"  matSortDirection="desc" >

<ng-container matColumnDef="OrdersId">
<th mat-header-cell   *matHeaderCellDef mat-sort-header (click)="changeSort('OrdersId')">BestellId</th>
<td mat-cell class="ptr"   *matCellDef="let element"  (click)="onEdit(element)"> <span class="m-2" [matBadge]="getItemsCount(element)" matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="false">  {{ element.OrdersId }}</span> </td></ng-container>

<ng-container matColumnDef="Orderdate" >
  <th mat-header-cell  *matHeaderCellDef mat-sort-header (click)="changeSort('CreateDate')">Datum</th>
  <td mat-cell class="ptr"   *matCellDef="let element"  (click)="onEdit(element)"> {{ element.CreateDate | date: 'dd.MM.yyyy' }}</td></ng-container>

<ng-container matColumnDef="Deliverydate" >
    <th mat-header-cell   *matHeaderCellDef mat-sort-header (click)="changeSort('DeliveryDate')">Lieferdatum</th>
    <td mat-cell class="ptr"   *matCellDef="let element"  (click)="onEdit(element)"> {{ element.DeliveryDate | date: 'dd.MM.yyyy' }}</td></ng-container>

<ng-container matColumnDef="ShoppingCartsId" >
<th mat-header-cell class=""  *matHeaderCellDef  (click)="changeSort('ShoppingCartsId')">WarenkorbId</th>
<td mat-cell class="ptr"  *matCellDef="let element"  (click)="onEdit(element)"> {{ element.ShoppingCartsId }}</td></ng-container>

<ng-container matColumnDef="PreparedBy">
<th mat-header-cell class=""  *matHeaderCellDef (click)="changeSort('PreparedBy')" >Erstellt-von</th>
<td mat-cell class="ptr"  *matCellDef="let element"  (click)="onEdit(element)"> {{ element.PreparedBy }}</td></ng-container>

<ng-container matColumnDef="OrderedBy">
<th mat-header-cell class=""  *matHeaderCellDef (click)="changeSort('UsersFirstName')" >Bestellt-von</th>
<td mat-cell class="ptr"  *matCellDef="let element"  (click)="onEdit(element)"> {{element.ShoppingCarts.UsersFirstName}} {{element.ShoppingCarts.UsersLastName}} </td></ng-container>

<ng-container matColumnDef="OrderedFor">
<th mat-header-cell class="" *matHeaderCellDef (click)="changeSort('CustomerNumber')" >Bestellt-für</th>
<td mat-cell class="ptr"  *matCellDef="let element"  (click)="onEdit(element)"> {{element.ShoppingCarts.CustomersCustomerNumber}}-{{element.ShoppingCarts.CustomersName1}} </td></ng-container>

<ng-container matColumnDef="DeliveryAddress">
<th mat-header-cell class=""  *matHeaderCellDef >Lieferadresse</th>
<td mat-cell class="ptr"  *matCellDef="let element"  (click)="onEdit(element)"> {{element.DeliveryAddress}} </td></ng-container>

<ng-container matColumnDef="InvoiceAddress">
<th mat-header-cell class=""  *matHeaderCellDef >Rechnungsadresse</th>
<td mat-cell class="ptr"  *matCellDef="let element"  (click)="onEdit(element)"> {{ element.InvoiceAddress }}</td></ng-container>

<ng-container matColumnDef="OrderStatesId">
<th mat-header-cell class=""  *matHeaderCellDef >Status</th>
<td mat-cell class="ptr" *matCellDef="let element"  (click)="onEdit(element)"> {{element.OrderstatesName}} </td></ng-container>

<ng-container matColumnDef="Published">
  <th mat-header-cell class=""  *matHeaderCellDef > Freigegeben </th>
  <td mat-cell class="" *matCellDef="let element"  (click)="onEdit(element)">

    <mat-checkbox *ngIf="selectedFilter != 'Vorlagen' && selectedFilter != 'Angebote'"  (click)="onDisabledClick($event, element)" class="mat-checkbox mat-primary" id="publish-{{ element.OrdersId }}" checked={{element.Published}}></mat-checkbox></td>
  </ng-container>

  <ng-container matColumnDef="Imported">
    <th mat-header-cell class=""  *matHeaderCellDef >Importiert</th>
    <td mat-cell class="" *matCellDef="let element"  (click)="onEdit(element)">
      <mat-checkbox *ngIf="selectedFilter != 'Vorlagen' && selectedFilter != 'Angebote'"  (click)="onDisabledClick($event, element)" class="mat-checkbox mat-primary" id="import-{{ element.OrdersId }}"  [(ngModel)]=element.Imported></mat-checkbox></td>
  </ng-container>


<ng-container matColumnDef="OrderInfo">
  <th mat-header-cell class=""  *matHeaderCellDef >Kunden-info</th>
  <td mat-cell class="ptr" *matCellDef="let element"  (click)="onEdit(element)"> {{element.ShoppingCarts.OrderInfo}} </td></ng-container>

<ng-container matColumnDef="Deleted">
   <th mat-header-cell class="" *matHeaderCellDef >Gelöscht</th>
   <td mat-cell class="" *matCellDef="let element"  (click)="onEdit(element)">  <mat-checkbox *ngIf="selectedFilter != 'Vorlagen' && selectedFilter != 'Angebote'" (click)="onDisabledClick($event,element)"   class="mat-checkbox mat-primary" id="import-{{ element.OrdersId }}"  [(ngModel)]=element.Canceled ></mat-checkbox></td></ng-container>

  <ng-container matColumnDef="TotalPrice">
    <th mat-header-cell class=""  *matHeaderCellDef >G.Preise</th>
    <td mat-cell class="ptr" *matCellDef="let element"  (click)="onEdit(element)"> {{totalOrderPrice(element)  | number:'1.2-2':'de'}}</td></ng-container>

<ng-container matColumnDef="Comment">
<th mat-header-cell class="" *matHeaderCellDef >Komentar</th>
<td mat-cell class="ptr" *matCellDef="let element"  (click)="onEdit(element)"> {{ element.ShoppingCarts.Comment }}</td></ng-container>

<ng-container matColumnDef="Selection">

  <th mat-header-cell class=""  *matHeaderCellDef >Importieren</th>
  <td mat-cell class="" *matCellDef="let element" >  <mat-checkbox *ngIf="selectedFilter != 'Vorlagen' && selectedFilter != 'Angebote' && !element.Imported" [disabled]="element.Published == 0"  (change)="onSelectionChecked($event, element)" checked=!!{{element.Selection}} class="mat-checkbox" id="selection-{{ element.OrdersId }}" [(ngModel)]=element.Selection  ><span  matTooltip="Aftragsimport ändern"></span></mat-checkbox></td>
  </ng-container>


<ng-container matColumnDef="Actions">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef ></th>
<td mat-cell class="" *matCellDef="let element">
  <button (click)="onTransferShopingCards(element)" class="mat-icon-button " *ngIf="selectedFilter == 'Vorlagen' || selectedFilter == 'Angebote'"><span class="material-icons btn-outline-primary md-32 m3" matTooltip="In den aktuellen WK Übernehmen">redo</span></button>


  <button *ngIf="element.Canceled != 1" (click)="onDeleteOrder(element)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button>
  <button *ngIf="element.Canceled != 0" (click)="onUnDeleteOrder(element)" class="mat-icon-button "> <i class="material-icons btn-outline-success" matTooltip="wiederherstellen">undo</i> </button>
  <button *ngIf=" selectedCustomer != undefined && element.ShoppingCarts.ShoppingCartStateId != 8 && element.Published != 1 && element.Canceled != 1 && element.Imported == null"  (click)="onEditAsShoppingCart(element)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="als Warenkorb bearbeiten und ersetzen">edit</i> </button>


</td>


</ng-container>

<ng-container matColumnDef="hiddenAdminOrderInput">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef ></th>
  <td mat-cell *matCellDef="let element; let x = index;">
      <input class="hiddenAdminOrderInput"
      id="hiddenAdminOrderInput-{{x}}"
      (keyup)="onAdminOrderKeyUp(element,'hiddenAdminOrderInput','', $event, x)"
        />
  </td>
</ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
  <tr mat-row *matRowDef="let row;  let x = index; columns: displayedColumns;" id="AdminOrderRow-{{x}}"  ></tr>

  </table>
  <mat-paginator hidePageSize="true"  [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>

</div>

<!-- -------------------------------------------------------------------------------------------------------------------------- -->

<div class="card" *ngIf="mode=='edit'">

  <div class="card-header">
    <div class="row">
      <div class="col-10 text-primary"><span class="material-icons btn-outline-primary " >edit</span>  {{item}} </div>

      <div class="col-2"><button (click)="onEditSave(selectedOrder)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="Äderungen speichern">save</i> </button></div>
    </div>
  </div>

  <div class="card-body" >

  <form  [formGroup]="ordersForm" >

  <div class="row">
    <div class="col-4">
      <mat-form-field class="w-100 " appearance="outline">
      <input matInput formControlName="OrderInfo" placeholder="kunden-info" [(ngModel)]="selectedOrder.ShoppingCarts.OrderInfo"
          onfocus="this.select();"
          id="AdminOrderInfoInput"
          (keyup)="onAdminOrderDetailKeyUp(selectediShoppingcartitems,'AdminOrderInfoInput','', $event, x)"
      >
      <mat-error class="error-block" *ngIf="!ordersForm.get('OrderInfo').valid && (ordersForm.get('OrderInfo').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
    </div>
      <div class="col-4">
      <mat-form-field class="w-100 " appearance="outline">
      <input matInput formControlName="Comment" placeholder="Comment"  [(ngModel)]="selectedOrder.ShoppingCarts.Comment"
      onfocus="this.select();"
      id="AdminOrderCommentInput"
      (keyup)="onAdminOrderDetailKeyUp(selectediShoppingcartitems,'AdminOrderCommentInput','', $event, x)"
      >
      <mat-error class="error-block" *ngIf="!ordersForm.get('Comment').valid && (ordersForm.get('Comment').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="w-100" appearance="outline">
        <mat-select  formControlName="OrderStatesId" placeholder="OrderStatesId">
        <mat-option *ngFor="let OrderState of OrderStates" [value]="OrderState.OrderStatesId">{{ OrderState.Name }}</mat-option> </mat-select>
        <span class="error-block" *ngIf="!ordersForm.get('OrderStatesId').valid && (ordersForm.get('OrderStatesId').touched  || formSubmited)">Ungültiger Eintrag</span>
      </mat-form-field>
    </div>
  </div>

  </form>


  <div class="card alaska-detail-table-container mat-elevation-z8 ">

  <table class="table-striped" mat-table [dataSource]="editDatasource">

    <!-- Position Column -->
    <ng-container matColumnDef="AdminOrderDetail_ProductId" class="m-1">
      <th mat-header-cell *matHeaderCellDef> A-Nr </th>
      <td mat-cell *matCellDef="let element"  [(selectediShoppingcartitems)]="selectediShoppingcartitems"> {{element.Product.ProductNumber}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="AdminOrderDetail_DescRow1" class="m-1">
      <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
      <td mat-cell *matCellDef="let element"> {{element.Product.DescRow1}}  <br /> {{element.Product.DescRow2}}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="AdminOrderDetail_StockValue" class="mar-r">
      <th class="tright" mat-header-cell *matHeaderCellDef > Verfügbarkeit </th>
      <td  mat-cell *matCellDef="let element">
        <p class="tright" [ngClass]="getBg(element.Product.StockValue,'OrderDetail_StockValue')">Ist: {{ element.Product.StockValue - element.Quantity | number:'1.2-2':'de' }}</p>
        <p class="tright" [ngClass]="getBg(element.Product.DispositionStockValue, 'OrderDetail_DispositionStockValue')">Dispo: {{ element.Product.DispositionStockValue - element.Quantity | number:'1.2-2':'de' }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="AdminOrderDetail_Unit" class="mar-r">
      <th  mat-header-cell *matHeaderCellDef> Einh. </th>
      <td mat-cell *matCellDef="let element"> {{ element.Product.QuantityUnitsShortDesc }} </td>
    </ng-container>

    <ng-container matColumnDef="AdminOrderDetail_Price" class="mar-r">
      <th  mat-header-cell *matHeaderCellDef> Preis( € ) </th>
      <td mat-cell *matCellDef="let element"> {{ element.Price | number}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="AdminOrderDetail_Price" class="mar-r">
      <th mat-header-cell *matHeaderCellDef> Preis( € )  </th>
      <td mat-cell *matCellDef="let element; let x = index;">
        <div class="input-group">
          <input onfocus="this.select();"
          id="AdminOrderDetail_Price-{{x}}"
          type="number"
          min="0"
          lang="de"
          class="input-group-text inputbox "
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value="{{ element.Price | number }}"
          [(ngModel)]="element.Price"
          (keydown)="onPreventStep(element,$event)"
          (keyup)="onAdminOrderDetailKeyUp(element,'AdminOrderDetail_Price','', $event, x)"
          (focusout)="onFocusOutPrice(element, $event)"
          (click)="onFocusAdminOrderDetailRow(element,'AdminOrderDetail_Price','',$event)"

            />
        </div>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="AdminOrderDetail_PosDiscount" class="mar-r">
      <th  mat-header-cell *matHeaderCellDef> Rabatt(%) </th>
      <td mat-cell *matCellDef="let element"> {{ element.PosDiscount | number:'1.2-2':'de'}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="AdminOrderDetail_PosDiscount">
      <th mat-header-cell *matHeaderCellDef> Rabatt(%)  </th>
      <td mat-cell *matCellDef="let element; let x = index;">
        <div class="input-group">
          <input onfocus="this.select();"
          id="AdminOrderDetail_PosDiscount-{{x}}"
          type="number"
          min="0"
          lang="de"
          class="input-group-text inputbox "
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value="{{ element.PosDiscount | number}}"
          [(ngModel)]="element.PosDiscount"
          (keydown)="onPreventStep(element,$event)"
          (keyup)="onAdminOrderDetailKeyUp(element,'AdminOrderDetail_PosDiscount','', $event, x)"
          (click)="onFocusAdminOrderDetailRow(element,'AdminOrderDetail_PosDiscount','',$event)"


            />
        </div>
      </td>
    </ng-container> -->



    <ng-container matColumnDef="AdminOrderDetail_TotalDiscount">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Rabatt(%) </th>

      <td class="tcenter" mat-cell *matCellDef="let element" > <span class=" mat-lar ptr "  matTooltip=
        "
        Pauschalrabatt: &nbsp;&nbsp;+{{getTooltipDiscount(element).PauschalRabatt | number : '1.2-2': 'de' }} % &#13;
        Pos.-rabatt:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).PositionRabatt | number:'1.2-2':'de'}} % &#13;
        Mengenrabatt: &nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).MengenRabatt  | number:'1.2-2':'de'}} % &#13;
        Wa.Gr.-rabatt:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).WarenGruppeRabatt  | number:'1.2-2':'de'}} % &#13;
        Rab.Gr.-rabatt: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).RabattGruppeRabatt | number:'1.2-2':'de'}} % &#13;
        Kartonanbruch:  &nbsp;&nbsp;&nbsp;&nbsp;-{{positionRowPartialPackageCharge(element) | number:'1.2-2':'de'}} % &#13;

        Gesamtrabatt: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{getTooltipDiscount(element).Gesamt | number:'1.2-2':'de'}} % &#13;

        "> <span  [ngClass]="totalRowNetDiscount(element) < 0 ? 'txt-pink': (totalRowNetDiscount(element) > 0 ?'text-primary' : '')">{{totalRowNetDiscount(element) | number:'1.2-2':'de'}} </span> </span> </td>


    </ng-container>

    <ng-container matColumnDef="AdminOrderDetail_Surcharge">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Zuschlag(3%) </th>
      <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowSurcharge(element)  | number:'1.2-2':'de'}} </td>
    </ng-container>

    <ng-container matColumnDef="AdminOrderDetail_TotalPrice">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Preis(€) </th>
      <td class="tcenter" mat-cell *matCellDef="let element"> {{totalRowPrice(element) | number:'1.2-2':'de' }} </td>
    </ng-container>

    <ng-container matColumnDef="AdminOrderDetail_Info">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Info an ALASKA </th>
      <td class="tcenter" mat-cell *matCellDef="let element"> <span (click)="openInfoDialog(element)" matTooltip="{{ element.PosInfo}}"  class="material-icons btn-outline-primary">attach_file</span> </td>
    </ng-container>

    <ng-container matColumnDef="AdminOrderDetail_Quantity">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Menge </th>
      <td class="tcenter" mat-cell *matCellDef="let element"> {{ element.Quantity | number}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="AdminOrderDetail_QuantityInput">
      <th mat-header-cell *matHeaderCellDef> Wunschmenge  </th>
      <td mat-cell *matCellDef="let element; let x = index;">
        <div class="input-group">
          <input onfocus="this.select();"
          id="AdminOrderDetail_QuantityInput-{{x}}"
          type="number"
          min="0"
          lang="de"
          class="input-group-text inputbox "
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value="{{ element.Quantity | number}}"
          [(ngModel)]="element.Quantity"
          (keydown)="onPreventStep(element,$event)"
          (keyup)="onAdminOrderDetailKeyUp(element,'AdminOrderDetail_QuantityInput','', $event, x)"
          (click)="onFocusAdminOrderDetailRow(element,'AdminOrderDetail_QuantityInput','',$event.x)"
            />
        </div>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="AdminOrderDetail_Actions">
      <th  mat-header-cell *matHeaderCellDef>

        <div class="col-2" *ngIf="order.Imported != undefined"><button (click)="onReactivatePosition(selectedOrder)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="Wiederholung Aeins Übergabe">autorenew</i> </button></div>

    </th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="selectedOrder.Canceled != 1" (click)="onDeletePosition(element)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button>
      </td>

    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedAdminOrderDetailColumns; sticky: true"></tr>

    <tr mat-row   *matRowDef="let row; let x = index; columns: displayedAdminOrderDetailColumns;"  id="AdminOrderDetailRow-{{x}}" (click)="onFocusAdminOrderDetailRow(row,'AdminOrderDetailRow','',$event,x)"></tr>


  </table>
</div>

  </div>

</div>


<!-- -end of edit---------------------------------------------------------------------------------------------------------------------- -->


</div>

<div class="loading-indicator">
  <mat-spinner mode="indeterminate" color="accent" *ngIf="isLoading"></mat-spinner>

</div>

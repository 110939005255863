import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-customSnackbar',
  templateUrl: './customSnackbar.component.html',
  styleUrls: ['./customSnackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  msg: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data,
  public customSnackbarRef: MatSnackBarRef<CustomSnackbarComponent>) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.msg = this.data.msg;
}
onCancel(){
  this.customSnackbarRef.dismiss();
}
onSave(){
  return this.customSnackbarRef.onAction().subscribe(
    () => { console.log(this.data.msg)}
  );
}

}

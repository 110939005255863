
<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-10 card-title"> <span class="material-icons btn-outline-primary">location_on</span>  {{item}} </div>
        <div class="col-2 btn-right">
          <button (click)="onCreate()"  class="mat-icon-button"><span class="material-icons btn-outline-primary" matTooltip="neu erstellen">add_box</span></button>
          <button (click)="onList()"    class="mat-icon-button "><span class="material-icons btn-outline-primary" matTooltip="beschränkte ansicht">view_list</span></button>
        </div>
    </div>
 </div>

<div class="card-body" *ngIf="mode=='list'">

<table mat-table [dataSource]="dataSource" matSort>

<ng-container matColumnDef="AddressesId">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Addr-Id</th>
<tr mat-cell class="" *matCellDef="let element">{{ element.AddressesId }}</tr></ng-container>

<ng-container matColumnDef="CustomersId">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Cust-Id</th>
<tr mat-cell class="" *matCellDef="let element">{{ element.CustomersId }}</tr></ng-container>

<ng-container matColumnDef="AddressestypesId">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Type</th>
<tr mat-cell class="" *matCellDef="let element">{{ element.AddressestypesId }}</tr></ng-container>

<ng-container matColumnDef="Street">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Street</th>
<tr mat-cell class="" *matCellDef="let element">{{ element.Street }}</tr></ng-container>

<ng-container matColumnDef="Zip">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Zip</th>
<tr mat-cell class="" *matCellDef="let element">{{ element.Zip }}</tr></ng-container>

<ng-container matColumnDef="City">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >City</th>
<tr mat-cell class="" *matCellDef="let element">{{ element.City }}</tr></ng-container>

<ng-container matColumnDef="Tours">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Tours</th>
<tr mat-cell class="" *matCellDef="let element">{{ element.Tours }}</tr></ng-container>

<ng-container matColumnDef="actions">
<th mat-header-cell class="" mat-sort-header *matHeaderCellDef >actions</th>
<tr mat-cell class="" *matCellDef="let element">
  <button (click)="onEdit(element)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="bearbeiten">edit</i> </button>
  <button (click)="onEdit(element)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="Add Tür">subway</i></button>
  <button (click)="onDelete(element)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button>
</tr>
</ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <!-- <td class="mat-cell" colspan="4">Es wurden keine Daten gefunden, die mit dem Filtertext "{{input.value}}" übereinstimmen </td> -->
  </tr>

  </table>
  <mat-paginator [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>

</div>
<div class="card-body" *ngIf="mode=='edit' || mode=='create'">
 <form  [formGroup]="addressesForm" >

<div class="row">
 <div class="col-6">
 <mat-form-field class="w-100 " appearance="outline">
 <input matInput type="number" lang="de" formControlName="AddressesId" placeholder="AddressesId">
<mat-error class="error-block" *ngIf="!addressesForm.get('AddressesId').valid && (addressesForm.get('AddressesId').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
  </div>
 <div class="col-6">
 <mat-form-field class="w-100" appearance="outline">
 <mat-select class="form-control" formControlName="CustomersId" placeholder="CustomersId">
<mat-option *ngFor="let customer of Customers" [value]="customer.CustomersId">{{ customer.Name1 }}</mat-option> </mat-select>
<span class="error-block" *ngIf="!addressesForm.get('CustomersId').valid && (addressesForm.get('CustomersId').touched  || formSubmited)">Ungültiger Eintrag</span> </mat-form-field>
  </div>
</div>
<div class="row">
 <div class="col-6">
 <mat-form-field class="w-100" appearance="outline">
 <mat-select class="form-control" formControlName="AddressestypesId" placeholder="AddressestypesId">
<mat-option *ngFor="let types of AddressTypes" [value]="types.AddressestypesId">{{ types.Name }}</mat-option> </mat-select>
<span class="error-block" *ngIf="!addressesForm.get('AddressestypesId').valid && (addressesForm.get('AddressestypesId').touched  || formSubmited)">Ungültiger Eintrag</span> </mat-form-field>
  </div>
 <div class="col-6">
 <mat-form-field class="w-100 " appearance="outline">
 <input matInput formControlName="Street" placeholder="Street">
<mat-error class="error-block" *ngIf="!addressesForm.get('Street').valid && (addressesForm.get('Street').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
  </div>
</div>
<div class="row">
 <div class="col-6">
 <mat-form-field class="w-100 " appearance="outline">
 <input matInput formControlName="Street1" placeholder="Street1">
<mat-error class="error-block" *ngIf="!addressesForm.get('Street1').valid && (addressesForm.get('Street1').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
  </div>
 <div class="col-6">
 <mat-form-field class="w-100 " appearance="outline">
 <input matInput formControlName="Zip" placeholder="Zip">
<mat-error class="error-block" *ngIf="!addressesForm.get('Zip').valid && (addressesForm.get('Zip').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
  </div>
</div>
<div class="row">
 <div class="col-6">
 <mat-form-field class="w-100 " appearance="outline">
 <input matInput formControlName="City" placeholder="City">
<mat-error class="error-block" *ngIf="!addressesForm.get('City').valid && (addressesForm.get('City').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
  </div>
 <div class="col-6">
 <mat-form-field class="w-100 " appearance="outline">
 <input matInput formControlName="District" placeholder="District">
<mat-error class="error-block" *ngIf="!addressesForm.get('District').valid && (addressesForm.get('District').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
  </div>
</div>
<div class="row">
 <div class="col-6">
 <mat-form-field class="w-100 " appearance="outline">
 <input matInput formControlName="Country" placeholder="Country">
<mat-error class="error-block" *ngIf="!addressesForm.get('Country').valid && (addressesForm.get('Country').touched  || formSubmited)">Ungültiger Eintrag</mat-error> </mat-form-field>
  </div>
</div>

<!-- <mat-select class="form-control" formControlName="Tours" placeholder="Türe" multiple>
  <mat-option *ngFor="let gate of tours" [value]="gate.ToursId">{{ gate.Name }}</mat-option>
</mat-select> -->

  <div class="row">
<div class="col-6">
  <div class="form-group">
    <button (click)="onReset()" type="reset" class="btn btn-secondary ">Zurücksetzen</button>
  </div>
</div>
<div class="col-6">
  <div class="form-group">
    <button (click)="onSubmit()" type="button" class="btn btn-primary">Speichern</button>
  </div>
 </div>
  </div>
 </form>
 </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'app-footerDialog',
  templateUrl: './footerDialog.component.html',
  styleUrls: ['./footerDialog.component.scss']
})
export class FooterDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public footerDialogRef: MatDialogRef<FooterDialogComponent>){}

  ngOnInit() {
  }

  onOK(){
    this.footerDialogRef.close();
  }

}

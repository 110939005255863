import {  Component,  OnInit,  ViewChild,  ElementRef,  AfterViewInit,  TemplateRef,  Input,} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {  iProducts,  iProductcategories,  iCustomers,  iProductprices,  iCustomermaterialgroupdiscounts,  iDiscountmatrixs,  iMaterialgroups,  iCustomerproductprices,  iCategoryproducts, iProductParameterBag,  iApiSource, iUsers, iUserroles, iShoppingcartitems, iParameterBag, iProductstocks, iSupplierproductorders, iShoppingcarts, iCustomerproductorders} from '../models/AlaskaModels';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { DataService } from '../Services/data.service';
import { DialogService } from '../Services/dialog.service';
import { SnackbarService } from '../Services/snackbar.service';
import { IdbService } from '../Services/idb.service';
import {  ReactiveFormsModule,  NgForm,  FormGroup,  FormBuilder,  Validators,  FormControl,} from '@angular/forms';

import { MatTableDataSource, MatTable, MatRow } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {  MatDialogModule,  MatDialog,  MatDialogConfig,} from '@angular/material/dialog';
import { ImgDialogComponent } from '../imgDialog/imgDialog.component';
import { time } from 'console';
import { element } from 'protractor';
import { SelectionModel } from '@angular/cdk/collections';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ShopingCartComponent } from '../shopingCart/shopingCart.component';
import { take } from 'rxjs/operators';
import {  OnlyNumberDirective  } from '../onlyNumber/onlyNumber.directive';
import { Observable, Subscription, fromEvent } from 'rxjs';


registerLocaleData(localeDe, 'de');

const ELEMENT_DATA: Array<iProducts> = [];

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {


  selectedProductCategory: iProductcategories;
  selectedCustomer: iCustomers;
  priceGroup:number = 1;

  idbActiveProducts: iProducts[] = [];
  allProducts: Array<iProducts> = [];
  datasource = new MatTableDataSource(ELEMENT_DATA);
  showSpinner: boolean = false;
  @ViewChild('shoppingCartComponent') shoppingCartComponent:ShopingCartComponent;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  deliveryDate: Date;
  private productsTableFilled = false;
  checkedExcludeValues = new Set<string>();
  loadAllProducts: boolean = false;
  API_BASE_URL = environment.API_BASE_URL;
  IMAGE_URL = environment.IMAGE_URL;
  isLoading: boolean = false;
  specialDiscount:boolean = false;
  dateForm: FormControl;
  formatedDate: string;
  searchValue: string = '';
  QuantityInputFormControl: FormControl = new FormControl();
  pipe = new DatePipe('de-DE');
  focustedInputId: string = '';
  focusedRowId: string = '';
  focusedRow: number = 0;
  coloredRowIndex: number=0;
  focusedRowIndex: number=0;
  displayedColumns: string[] = [  'Artikel',    'DescRow1',  'QunatityInput', 'Unit', 'StockValue',    'Price',  'Info', 'Spec',   'Picture',       'Action' ];
  endUserisplayedColumns: string[] = [  'Artikel',    'DescRow1',  'QunatityInput',  'Unit',  'Price',  'Info', 'Spec',    'Picture',       'Action'  ];

  selectionModel: SelectionModel<iProducts[]>;
  loggedinUser: iUsers;
  userRoles:iUserroles[];
  kgUntitIds: number[] = [1,93];
  smallScreen: boolean = false;
  idbAllProducts: iProducts[] = [];
  deliverydatechangedMsg:number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private element: ElementRef,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private idbService: IdbService,
    private matPaginatorIntl: MatPaginatorIntl,
    private el: ElementRef

  ) {

    // this.dataService.activeProductsSubject$.subscribe(
    //   (activeProductsData) => {
    //     // this.selectedCustomer = this.selectedCustomer == undefined? JSON.parse( localStorage.getItem('selectedCustomer') ): this.selectedCustomer;
    //     this.idbActiveProducts =  activeProductsData;

    //     if(this.idbActiveProducts.length > 0 ){
    //       this.getSelectedProductCategoryFilteredDataFromDatasource(this.selectedCustomer, this.idbActiveProducts);

    //       this.idbActiveProducts = this.idbService.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(this.selectedCustomer, false);

    //     }
    //     else if(this.idbActiveProducts.length <= 1 ){
    //       // this.populateData();
    //     }
    //     console.log(  'activeProductsSubject in products: ' , this.idbActiveProducts );  //this.products

    //   },
    //   (err) => { console.log('error - products-subscription in product component: ', err); }
    // );

   }
  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';


  }
pageSize: number = 50;
pageView: string = '';

onPageSizeChange($event){
  this.paginator.pageSize = +this.pageSize;
  this.paginator.pageIndex = 0;
  this.paginator._changePageSize(+this.pageSize);
  this.pgnFirstPage();
}

getPageVisits():string{
  let visits = '';
    let nextVisit =  this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize < this.paginator.length? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize : this.paginator.length : '';
    visits = this.paginator ?  ((this.paginator.pageIndex * this.pageSize)+1).toString() +'-'+ nextVisit.toString()+'/'+this.paginator.length : '';
  return visits;
}
  pgnFirstPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.firstPage();
  }
  pgnBackPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.previousPage();
  }
  pgnNextPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.nextPage();
  }
  pgnLastPage(){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.lastPage();
  }

  hasRoles(roles: number[]):boolean {
    return this.dataService.hasRoles(roles)
  }

  filterProductsByCategory(products:iProducts[], productCategory:iProductcategories):iProducts[] {

      let filteredProducts:iProducts[] = [];

      products.forEach(product => {
        product.Categoryproducts.forEach(categoryproduct => {
            if(categoryproduct.ProductcategoriesId == productCategory.ProductcategoriesId){ filteredProducts.push(product)}
        });
      });
      return filteredProducts;
    }

  getUniqueProducts(products: iProducts[], customer: iCustomers):iProducts[]{
  let uniqueProducts: iProducts[] = [];
  let uniqeid: number[] = [];
  products.forEach(element => {
    if(!uniqeid.includes(element.ProductsId )){
      uniqueProducts.push(element);uniqeid.push(element.ProductsId)
    }
    else if(uniqeid.includes(element.ProductsId )){
      if(element.ProductpricesPriceGroup == 10){
        uniqueProducts.filter(x => x.ProductsId != element.ProductsId ).push(element);
        uniqeid.filter(x => x != element.ProductsId ).push(element.ProductsId);
      }
    }
  });

   return uniqueProducts;
}

getSelectedProductCategoryFilteredDataFromDatasource(customer: iCustomers, products: iProducts[]):iProducts[]{

  this.datasource.data = this.idbActiveProducts
  this.datasource.sort = this.sort;
  this.datasource.paginator = this.paginator;


  customer = customer == undefined? JSON.parse( localStorage.getItem('selectedCustomer') ): customer;

  if(this.dataService.hasRoles([5]) && this.selectedProductCategory != undefined){
    this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iProducts, filter: string) => {
      return (data.ProductcategoriesId == null  == undefined? 0:data.ProductcategoriesId)  == (this.selectedProductCategory != undefined ? this.selectedProductCategory.ProductcategoriesId : 0)}
    this.datasource.filter = this.selectedProductCategory.ProductcategoriesId.toString();
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }
  else if(this.dataService.hasRoles([5]) &&  this.selectedProductCategory == undefined){

    this.datasource.filterPredicate =  this.datasource.filterPredicate = (data: iProducts, filter: string) => {return data.ProductcategoriesId >= 0}
    this.datasource.filter = '';
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  if(!this.dataService.hasRoles([5]) && this.selectedProductCategory != undefined){
    this.datasource.filterPredicate = this.datasource.filterPredicate = (data: iProducts, filter: string) => {
      if(data && data.ProductcategoriesId == undefined){
        // console.log('undefined productid', data.ProductcategoriesId)
      }
      else{
        if(this.selectedProductCategory != undefined){
          return (data.ProductcategoriesId == null? 0:data.ProductcategoriesId) == this.selectedProductCategory.ProductcategoriesId
        }
        else{
          return false;
        }
      }

    }
    this.datasource.filter = this.selectedProductCategory.ProductcategoriesId.toString();
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;


  }
  else if(!this.dataService.hasRoles([5]) && this.selectedProductCategory == undefined){

    this.datasource.filterPredicate =  this.datasource.filterPredicate = (data: iProducts, filter: string) => {return data.ProductcategoriesId >= 0}
    this.datasource.filter = '';
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }
  setTimeout(() => {  this.focusFirstRow(this.datasource.data); }, 0);
  return this.datasource.filteredData;
}

updateQuantityInputFromShoppingcart_(targetProducts: iProducts[]){


  if(localStorage.getItem('activShoppingcart'))
  {
    this.idbService.getAllIdbPromisData('Shoppingcarts').then(
      (Shoppingcarts) => {
        let shoppingcarts: iShoppingcarts[] = Shoppingcarts? Shoppingcarts : [];
        let customerShoppingcarts: iShoppingcarts[] = shoppingcarts && shoppingcarts.length > 0 ? shoppingcarts.filter(x => x.CustomerId == this.selectedCustomer.CustomersId && x.ShoppingCartStateId == 0): [];
        if(customerShoppingcarts && customerShoppingcarts.length > 0 ){
              customerShoppingcarts.forEach(shoppingcart => {
                if(shoppingcart.Shoppingcartitems.length > 0){ // if not empty shoppingcart
                    shoppingcart.Shoppingcartitems.forEach(shoppingcartitem => {
                    try {
                      targetProducts.find(x => x.ProductsId == shoppingcartitem.Product.ProductsId).QuantityInput = shoppingcartitem.Product.QuantityInput;
                    } catch (error) {
                      // do nothing
                    }
                  });
                }else{
                  this.datasource.data.forEach(prod => { prod.QuantityInput = 0; });
                }

              });
        }
        else{
          this.datasource.data.forEach(prod => { prod.QuantityInput = 0; });
        }
      }
    );
  }
}


parameterBagSubject: iParameterBag;
windowWidth = window.innerWidth;
windowHeight = window.innerHeight;
resizeObservable$: Observable<Event>
resizeSubscription$: Subscription
smallDeviceSize = 1000;
changeSmallDeviceMode = false;
changeDeviceMode(){
  this.smallDeviceSize = localStorage.getItem('smallDeviceSize')? +localStorage.getItem('smallDeviceSize') : this.smallDeviceSize;
  this.windowWidth = window.innerWidth;
  this.windowHeight = window.innerHeight;
  this.changeSmallDeviceMode = this.windowWidth  < this.smallDeviceSize? true : false;
  if( this.changeSmallDeviceMode == true){
    this.displayedColumns = [  'Artikel',    'DescRow1',  'QunatityInput', 'Action' , 'Unit', 'StockValue',    'Price',  'Info', 'Spec',   'Picture'];
    this.endUserisplayedColumns = [  'Artikel',    'DescRow1',  'QunatityInput',       'Action',  'Unit',  'Price',  'Info', 'Spec',    'Picture'  ];
  }
  else{
    this.displayedColumns = [  'Artikel',    'DescRow1',  'QunatityInput', 'Unit', 'StockValue',    'Price',  'Info', 'Spec',   'Picture',       'Action' ];
    this.endUserisplayedColumns = [  'Artikel',    'DescRow1',  'QunatityInput',  'Unit',  'Price',  'Info', 'Spec',    'Picture',       'Action'  ];
  }
}

  ngOnInit() {
    this.changeDeviceMode();
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.changeDeviceMode();
    })

    this.isLoading = true;
    localStorage.removeItem('selectedProductCategory');

    this.selectedCustomer = JSON.parse( localStorage.getItem('selectedCustomer') );
    this.deliveryDate = new Date(localStorage.getItem('deliveryDate'));
    this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));


    // this.dataService.parameterBagSubject$.subscribe(
    //   (bag) => {
    //     this.parameterBagSubject = bag;
    //     let customer: iCustomers = bag.SelectedCustomer;
    //     let date: Date = bag.DeliveryDate
    //     let deliveryDateChanged: boolean = ((new Date(bag.DeliveryDate.getFullYear(),bag.DeliveryDate.getMonth(), bag.DeliveryDate.getDay(),0,0,0)).getTime() != (new Date(this.deliveryDate.getFullYear(),this.deliveryDate.getMonth(), this.deliveryDate.getDay(),0,0,0)).getTime());
    //     let shopingCart: iShoppingcarts =  bag.SelectedShoppingCart;
    //     if(this.selectedCustomer.CustomersId != customer.CustomersId || (deliveryDateChanged == true)){
    //       this.deliverydatechangedMsg  = deliveryDateChanged == true? 1 : 0;
    //       this.selectedCustomer = customer;
    //       this.deliveryDate = date;
    //       // this.populateData();
    //     }
    //   }, (err) => {  console.log('error - selectedCustomer-subscription: ', err);  });


    this.dataService.selectedCustomerSubject$.subscribe(
      // (customer) => {
      //   this.selectedCustomer = customer;
      ////   this.populateData();

      // }, (err) => {
      //   console.log('error - selectedCustomer-subscription: ', err);
      // }
    );

this.dataService.deliveryDateSubject$.subscribe(
  // newDeliveryDate =>{
  //   this.deliveryDate = newDeliveryDate;
  //   this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
  //   this.deliverydatechangedMsg = 1;
  ////   this.populateData();
  // }
);

this.dataService.activeProductsSubject$.subscribe(
      (activeProductsData) => {
        this.selectedCustomer = this.selectedCustomer == undefined? JSON.parse( localStorage.getItem('selectedCustomer') ): this.selectedCustomer;
        this.idbActiveProducts = activeProductsData.length > 0? activeProductsData: this.idbActiveProducts;

        if(this.idbActiveProducts.length > 0 ){
          this.getSelectedProductCategoryFilteredDataFromDatasource(this.selectedCustomer, this.idbActiveProducts);

          this.idbActiveProducts = this.idbService.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(this.selectedCustomer, false);

        }
        else if(this.idbActiveProducts.length <= 1 ){
          this.populateData();
        }
        console.log(  'activeProductsSubject in products: ' , this.idbActiveProducts );  //this.products

      },
      (err) => { console.log('error - products-subscription in product component: ', err); }
    );

    this.dataService.selectedProductCategory$.subscribe(
      (selectedProductCategory) => {
        if((this.selectedProductCategory == undefined || selectedProductCategory == undefined) || (selectedProductCategory && this.selectedProductCategory.ProductcategoriesId != selectedProductCategory.ProductcategoriesId)){
          this.selectedProductCategory = selectedProductCategory;
          this.selectedCustomer = this.selectedCustomer == undefined? JSON.parse( localStorage.getItem('selectedCustomer') ): this.selectedCustomer;
          this.populateData();
        }
        console.log(  'selectedProductCategory-subscription: ',  this.selectedProductCategory  );

      },
      (err) => {
        console.log('error - selectedProductCategory-subscription: ', err);
      }
    );

    this.dataService.discountProductsSelectedSubject$.subscribe(
        discounts => {

        this.datasource.data = discounts == true ? this.idbService.idbActiveProducts.filter( x => x.ProductpricesPriceGroup == 10) : this.idbService.idbActiveProducts.filter( x => x.ProductpricesPriceGroup >= 0);
        }
    );


    this.dataService.msgSubject$.subscribe((msg) => {
      if (msg.Name.toLowerCase() == 'productcategoryclicked') {
      }

      if (msg.Name.toLowerCase() == 'selectedCustomerChanged') {

      }

      if(msg.Name.toLowerCase() == "userloggedout"){
        this.selectedCustomer = undefined;
      }
      if (msg.Name.toLowerCase() == 'shoppingcartitemadded') {
        let product:iProducts = msg.Data;
        if(product){this.setBasketColor(+product.ProductsId,  'btn-outline-success');}
      }
      if (msg.Name.toLowerCase() == 'shoppingcartitemremoved') {
        this.datasource.data.find(x => x.ProductsId == +msg.Message ).QuantityInput = 0;
        this.setBasketColor(+msg.Message, 'btn-outline-primary');

      }

      if (msg.Name.toLowerCase() == 'applyproductfilter') {
        let filterArray = msg.Message
          .trim()
          .toLowerCase()
          .split(/(\s+)/)
          .filter((e) => e.trim().length > 0);
        let filter0 =    filterArray && filterArray[0] != undefined ? filterArray[0] : '';
        let filter1 =    filterArray && filterArray[1] != undefined ? filterArray[1] : '';
        let filter2 =    filterArray && filterArray[2] != undefined ? filterArray[2] : '';

      if (this.selectedProductCategory != undefined  && filter0 == '' && filter1 == '' && filter2 == '') {
          this.datasource.filteredData = this.datasource.data.filter(x => x.ProductcategoriesId == this.selectedProductCategory.ProductcategoriesId);
      }

        this.datasource.filter = msg.Message.trim().toLowerCase();
        this.datasource.sort = this.sort;
        this.datasource.paginator = this.paginator;

        this.datasource.filterPredicate = (data: iProducts, filter: string) => {

          if (filter0 != '' && filter1 == '' && filter2 == '') {
            if(this.selectedProductCategory != undefined){
              return JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1 && data.ProductcategoriesId == this.selectedProductCategory.ProductcategoriesId;
            }else{
              return JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1
            }
          }
          if (filter0 != '' && filter1 != '' && filter2 == '') {
            if(this.selectedProductCategory != undefined){
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description ).toLowerCase().indexOf(filter0) != -1 &&
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1 &&  data.ProductcategoriesId == this.selectedProductCategory.ProductcategoriesId
            }else{

            }
            return (
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description ).toLowerCase().indexOf(filter0) != -1 &&
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1
            );
          }
          if (filter0 != '' && filter1 != '' && filter2 != '') {
            if(this.selectedProductCategory != undefined){
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1 &&
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1 &&
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter2) != -1 &&  data.ProductcategoriesId == this.selectedProductCategory.ProductcategoriesId
            }else{

            }
            return (
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter0) != -1 &&
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter1) != -1 &&
              JSON.stringify( data.ProductNumber +' '+ data.DescRow1 +' '+ data.DescRow2 +' '+ data.Description).toLowerCase().indexOf(filter2) != -1
            );
          }

        };

        setTimeout(() => {
          if (  msg.Type.toLowerCase() == 'enter' ||  msg.Type.toLowerCase() == 'numpadenter' || msg.Action.toLowerCase() == 'clear'       ) {
            this.focusFirstRow(this.datasource.filteredData);
          }
        }, 0);



      }

      if (msg.Name.toLowerCase() == 'applyexcludefilter') {
        let excludeTerms: string[] = msg.Message.split(',');
        let prods: iProducts[] = this.idbService.idbActiveProducts;
        let ids: number[] = [];
        this.getSelectedProductCategoryFilteredDataFromDatasource(this.selectedCustomer, this.idbActiveProducts );

        this.datasource.sort = this.sort;
        this.datasource.paginator = this.paginator;

        if (excludeTerms.length > 0) {
          excludeTerms.forEach((x) => {
            if (x != '') {
               prods = this.idbService.idbActiveProducts.filter( (product) => JSON.stringify(product).toLowerCase().indexOf(x.toLowerCase()) != -1  );
               if(prods && prods.length > 0){
                  prods.forEach(prod => {  ids.push(prod.ProductsId)   });
               }
            }
          });
          this.datasource.data = this.datasource.data.filter(x => !ids.includes(x.ProductsId));
        }
        setTimeout(() => {  this.focusFirstRow(this.datasource.data);}, 0);
      }

    });

      this.populateData();



  }

  populateData(){

    if(this.selectedCustomer == undefined){
      setTimeout(() =>  { if(this.selectedCustomer == undefined){ this.snackbarService.snackbarAction( 'bitte ein kunde auswählen.' );  return;}  }, 0);
    }
    if(this.selectedCustomer && this.deliveryDate){
        if(this.hasRoles([1,2,3,4])){
          if( this.idbActiveProducts.length > 0){
            if(this.deliverydatechangedMsg == 1){
              this.idbService.getCustomerProductPricesByDeliverydate(this.idbService.idbAllProducts ,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);
              this.deliverydatechangedMsg = 0;
            }
            else{
              this.idbActiveProducts = this.idbService.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(this.selectedCustomer, false);
              this.getSelectedProductCategoryFilteredDataFromDatasource(this.selectedCustomer, this.idbActiveProducts );
            }


          }
          else if(this.idbActiveProducts.length < 1 &&  this.idbService.idbActiveProducts.length > 0 ){
            if(this.idbService.idbActiveProducts[0].ListPrice == 0){// checking product contains preis
              this.idbService.getCustomerProductPricesByDeliverydate(this.idbService.idbAllProducts ,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);
            }else{
              this.idbActiveProducts = this.idbService.idbActiveProducts ;

              this.idbActiveProducts = this.idbService.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(this.selectedCustomer, false);
              this.getSelectedProductCategoryFilteredDataFromDatasource(this.selectedCustomer, this.idbActiveProducts);
            }

          }

          else if(this.idbActiveProducts.length < 1 && this.idbService.idbActiveProducts.length < 1 && this.idbService.idbAllProducts ){
            this.idbService.getCustomerProductPricesByDeliverydate(this.idbService.idbAllProducts ,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);

          }
        }
        else if(this.hasRoles([5]) && this.idbActiveProducts.length  <= 1){

          this.idbActiveProducts = this.idbService.idbActiveProducts.length > 0 ? this.idbService.idbActiveProducts: this.idbActiveProducts;
          if(this.idbActiveProducts.length == 0){
            this.idbService.populateCustomerData(this.selectedCustomer, this.deliveryDate, true);
          }
          else{
            this.idbActiveProducts = this.idbService.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(this.selectedCustomer, false);
            this.getSelectedProductCategoryFilteredDataFromDatasource(this.selectedCustomer, this.idbActiveProducts );
          }


        }
        else if(this.hasRoles([5]) && this.idbActiveProducts.length  > 0){
          // this.idbService.populateCustomerData(this.selectedCustomer, this.deliveryDate, false);

          this.idbActiveProducts = this.idbService.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(this.selectedCustomer, false);
          this.getSelectedProductCategoryFilteredDataFromDatasource(this.selectedCustomer, this.idbActiveProducts );
        }
    }
  }


  onAddBasket(product: iProducts, prefix?: string, rowIndex?:number) {
    if(product.ListPrice == 0){// check that the product has price
      this.dialogService.okDialog("Error", "Produkt Listenpreis darf nicht 0.00 sein" , "danger",'40%');
      return;
    }
    this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;
    this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

    let shoppingCart: iShoppingcarts= this.idbService.insertOrUpdateCustomerShoppingCart( this.selectedCustomer,this.deliveryDate, product);
    this.dataService.getAndSetShoppingCartInfoBag(shoppingCart);

  }

  onRemoveShoppingCartItemClick(product: iProducts){
    product.QuantityInput = 0;
    this.idbService.insertOrUpdateCustomerShoppingCart(this.selectedCustomer, this.deliveryDate,product);
  }


  onOpenImgDialogMessage(product: iProducts) {

    let img = this.IMAGE_URL  + product.ProductNumber.toString() + '.jpg';

    this.dialog.open(ImgDialogComponent, {
      data: {
        img: img,
        productNumber: product.ProductNumber,
        productName: product.DescRow1,
        productPrice: product.CustomerPrice,
        productUnit: product.QuantityUnitsShortDesc,
      },
    });
  }



  roundUpQuantity(product: iProducts):iProducts{

    let nonDividableUnits: number[] = [4,11,12,13,14,15,16,18,19,21,22,23,24,25,26,27,94]
    if(product && product.QuantityInput != 0 && nonDividableUnits.includes(product.QuantityUnitId) ){
      product.QuantityInput = +(Math.ceil(product.QuantityInput).toFixed(2));
    }
    if(product && product.QuantityInput != 0 && product.QuantityUnitId == 17 ){

      product.QuantityInput = +product.QuantityInput.toFixed(3) > +product.QuantityInput.toFixed(2)? +(product.QuantityInput.toFixed(2) + 0.01): +product.QuantityInput.toFixed(2);
    }

    product.QuantityInput = +(product.QuantityInput).toFixed(2);
    return product;
  }

 onKeyDownEvent($event?: any){
    if ($event.keyCode == 38 || $event.keyCode == 40) {
      $event.preventDefault();
    }
 }

  onKeyUpEvent( product: iProducts, selfPrefix: string,  nextPrefix?: string, $event?: any, rowIndex?: number  ) {

    let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 73, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90, 83,      71, 81, 85, 82];


    let code = $event != undefined ? $event.code : undefined;
    let nextIndex: number;
    let inputId = 'QunatityInput-' + rowIndex.toString();
    let rowId = 'row-' + rowIndex.toString();
    let basketId = 'basket-' + rowIndex.toString();
    product= this.dataService.roundUpQuantity(product);
    this.dataService.onKeyCombination($event, product);

    if ((code && code == 'Enter') || code == 'NumpadEnter') {

      if(product.QuantityUnitId == 12){             //if unit == dose
        if(product && product.QuantityInput != 0 && product.QuantityInput != 0 && product.QuantityInput % 1 != 0 ){
          product.QuantityInput = +(Math.ceil(product.QuantityInput).toFixed(2));
        }
      }

      this.onAddBasket(product, selfPrefix, rowIndex );
      this.goToNextInput(selfPrefix, rowIndex, 1);
      this.goToNextRow(selfPrefix, rowIndex, 1);

    } else if (code && code == 'ArrowLeft') {
// do nothing
    } else if (code && code == 'ArrowRight') {
// do nothing
    } else if (code && code == 'ArrowDown') {

      this.goToNextInput(selfPrefix, rowIndex, 1);
      this.goToNextRow(selfPrefix, rowIndex, 1);
    } else if (code && code == 'ArrowUp') {

      this.goToNextInput(selfPrefix, rowIndex, -1);
      this.goToNextRow(selfPrefix, rowIndex, -1);
    }

  }

  setBasketColor(productId: number, cssClass: string) {
    let basketId = 'basket-' + productId.toString();
    let basket = document.getElementById(basketId);
    if(basket){
      basket.className = 'material-icons ' + cssClass;
    }

  }

  goToNextInput(prefix?: string, rowIndex?:number, nexOrPrevious?: number){
    let inputId = prefix + '-' + rowIndex.toString();
    let inputNextId = prefix + '-' + (rowIndex + nexOrPrevious).toString();
    let currentInput = document.getElementById(inputId);
    let nextInput = document.getElementById(inputNextId );

    if (nextInput) {
      setTimeout(() => nextInput.focus(), 0);
    }
}

  goToNextRow(prefix?: string, rowIndex?:number, nexOrPrevious?: number){

      let rowId = 'row-' + rowIndex.toString();
      let rowNextId = 'row-' + (rowIndex + nexOrPrevious).toString();
      let currentRow = document.getElementById(rowId);
      let nextRow = document.getElementById(rowNextId);

      if (currentRow) {
        setTimeout(() => (currentRow.style.backgroundColor = ''), 0);
        this.coloredRowIndex = rowIndex + nexOrPrevious;
      }
        if (nextRow) {
        setTimeout(() => (nextRow.style.backgroundColor = '#ccffcc'), 0);
        this.coloredRowIndex = rowIndex + nexOrPrevious;
      }
  }

  getBg(quantity: number, type: string): string {
    return this.dataService.getBg(quantity,type);
  }
  getIcon(quantity: number, type: string): string {
    return this.dataService.getIcon(quantity,type);
  }
  bigOrSmall(quantity:number):string{
    return this.dataService.bigOrSmall(quantity);
  }

  onPreventStep(product: iProducts, $event?: any) {
    let code = $event.code;
    let filteredDataSource: iProducts[] = this.datasource.filteredData;
    if ($event.keyCode == 38 || $event.keyCode == 40) {
      $event.preventDefault();
      return;
    }
  }

  onFocusClickedInput( prefix?: string, rowIndex?:number){
    this.clearRowBgColor(this.coloredRowIndex);
    this.goToNextInput(prefix, rowIndex, 0);
    this.goToNextRow(prefix, rowIndex, 0);

 }

//  getRowBg(index: number, row: any, $event):string{
//   // {highlighted: selectedIndex === row.key  }
//   // if(row){
//   //      index ==  this.datasource.data.indexOf(row)
//   // }
//   $event.currentTarget.firstElementChild.bgColor = '#ccffcc'
//   $event.currentTarget.attributes
//   return row && index && index ==  this.datasource.data.indexOf(row)? 'rowBg' : '';
//  }

  focusFirstRow(products: iProducts[]) {
    if (products != undefined && products.length > 0) {
      this.goToNextRow('row',0,0);
      this.goToNextInput('QunatityInput',0,0);
    }
  }

  keyCombination(item: iProducts,$event?: any, rowIndex?: number){
    let event = $event;
    if(event.altKey == true && event.keyCode == 75) // strg + k
    {
      this.snackbarService.snackbarAction("mEK: " + item.AveragePurchasePrice.toString() + ", lEK: " + item.LastPurchasePrice.toString(), '');
    }
  }

  clearAllRowsBgColor(items?: iProducts[]){
    this.datasource.data.forEach( x => {
      let index = 0;
      let rowId = 'row-' + (index).toString();
      let row = document.getElementById(rowId);
      if(row){
        setTimeout(() => (row.style.backgroundColor = ''), 0);
      }
      index++;
    })
  }
  clearRowBgColor(rowIndex?:number){
      let rowId = 'row-' + (rowIndex).toString();
      let row = document.getElementById(rowId);
      if(row){
        setTimeout(() => (row.style.backgroundColor = ''), 0);
      }

  }

  onSpecTab(url: string){
    if(url && url != '')
    {
      window.open(url);
    }

  }

onOdataStockUpdate(productNumber: string){
  let productstock: iProductstocks;

  this.dataService.getDataByQuerystring('custom','ProductstockOdataByProductnumber', productNumber).subscribe(
    data => {
      productstock = data;
      let prod: iProducts = this.idbService.idbActiveProducts.find(x => x.ProductNumber == productstock.ProductNumber);
      if(prod){
        prod.StockValue  = productstock.StockValue;
        prod.DispositionStockValue  = productstock.DispositionStockValue;
        prod.OpenOrdersStockValue  = productstock.OpenOrdersStockValue;
        prod.SupplierDeliveryStockValue  = productstock.SupplierDeliveryStockValue;

        let idbActiveProductsIndex = this.idbService.idbActiveProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber) ;
        let idbAllProductsIndex = this.idbService.idbAllProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber);
        let datasourcedataIndex = this.datasource.data.findIndex(x => x.ProductNumber == productstock.ProductNumber);

        this.idbService.idbActiveProducts[idbActiveProductsIndex]= prod;
        this.idbService.idbAllProducts[idbAllProductsIndex]= prod;
        this.datasource.data[datasourcedataIndex]= prod;
      }
    }
    ), err =>{
      console.log("Error onOdataStockUpdate", err);
    };
  }

  onOdataSuplierOrderShow(productNumber: string){
    let suplierOrders: iSupplierproductorders[] = [];

    this.dataService.getDataByQuerystring('custom','SuplierOrderOdataByProductnumber', productNumber).subscribe(
      data => {
        suplierOrders = data;
        this.dialogService.openSuplierOrdersDialog(suplierOrders, '60%', '50%' );

      }
      ), err =>{
        console.log("Error onOdataStockUpdate", err);
      };
    }

    onOdataCustomerOrderShow(productNumber: string){
      let customerNumber = this.selectedCustomer ?this.selectedCustomer.CustomerNumber:'0';
      let customerOrders: iCustomerproductorders[] = [];
      let productandcustomernumber = productNumber + '_'+ customerNumber;

      this.dataService.getDataByQuerystring('custom','CustomerOrderOdataByProductnumber', productandcustomernumber).subscribe(
        data => {
          customerOrders = data;
          this.dialogService.openCustomerOrdersDialog(customerOrders, '60%', '50%' );

        }
        ), err =>{
          console.log("Error onOdataStockUpdate", err);
        };
      }

    onDeliveryDateFromApp(newDeliveryDate: Date){
     if(newDeliveryDate){
        this.deliveryDate =  newDeliveryDate ? new Date(this.pipe.transform(newDeliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE')): this.deliveryDate;

      // if(this.selectedCustomer ){
      //   if(this.hasRoles([1,2,3,4])){
      //     this.idbService.getCustomerProductPricesByDeliverydate(this.idbActiveProducts,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);

      //       }
      //       if(this.hasRoles([5])){
      //         this.idbService.populateCustomerData(this.selectedCustomer,this.deliveryDate , false);
      //       }
      //     }
     }
    }

    onSelectedCustomerFromAppControl(customer: iCustomers) {
      this.selectedCustomer = customer;
      this.idbService.getCustomerProductPricesByDeliverydate(this.idbActiveProducts,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);
    }

    isInputNumber($event){
      var inp = $event.currentTarget.value;
      if (/^([-]?)(\d+(\,\d{1,2})?)$/.test(inp)) {
        return true;
      } else {
        $event.preventDefault();
        return false;
      }

    }

    onProductInfo(product: iProducts){
      { this.dialogService.okDialog("Zusatzinformation", product.ProductInfo, "success",'40%');  }
    }




}

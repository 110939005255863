import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumberInput]'
})
export class OnlyNumberDirective {

  constructor(private elementRef: ElementRef) { }

  /**
   * Event handler for host's change event
   * @param e
   */
   @HostListener('input', ['$event'])
   onInput(event: Event) {
       this.elementRef.nativeElement.value = (<HTMLInputElement>event.currentTarget).value.replace(/[^0-9]/g, '');
   }

}


<mat-menu #menu>
  <ng-container *ngFor="let item of items">
    <!-- <span *ngIf="item == items[0]"><button mat-menu-item (click)="onProductCategoryChanged()"><b>Alle Warengruppen . . .</b></button></span> -->
    <button *ngIf="!item.Children || item.Children.length == 0; else branch" mat-menu-item (click)="onProductCategoryChanged(item.ProductcategoriesId)">{{item.Name}}</button>
    <ng-template #branch>
      <button mat-menu-item [matMenuTriggerFor]="innerPanel.menu">{{item.Name}}</button>
      <app-menu-item #innerPanel [items]="item.Children"></app-menu-item>
    </ng-template>
  </ng-container>
</mat-menu>

<!-- <mat-menu #menu>
  <ng-container *ngFor="let item of items">
    <button *ngIf="!item.Children; else branch" mat-menu-item>{{item.Name}}</button>
    <ng-template #branch>
      <button mat-menu-item [matMenuTriggerFor]="innerPanel.menu">{{item.Name}}</button>
      <app-menu-item #innerPanel [items]="item.Children"></app-menu-item>
    </ng-template>
  </ng-container>
</mat-menu> -->


<!-- <mat-menu #menu>
  <ng-container *ngFor="let item of items">
    <button *ngIf="!item.children; else branch" mat-menu-item>{{item.name}}</button>
    <ng-template #branch>
      <button mat-menu-item [matMenuTriggerFor]="innerPanel.menu">{{item.name}}</button>
      <app-menu-item #innerPanel [items]="item.children"></app-menu-item>
    </ng-template>
  </ng-container>
</mat-menu> -->


<!-- // items = [
//   {
//     name: 'One'
//   },
//   {
//     name: 'Two',
//     children: [
//       {
//         name: 'TwoOne',
//         children: [
//           {
//             name: 'TwoOneOne'
//           }
//         ]
//       },
//       {
//         name: 'TwoTwo',
//         children: [
//           {
//             name: 'TwoTwoOne'
//           },
//                       {
//             name: 'TwoTwoTwo'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     name: 'Three'
//   }
// ] -->

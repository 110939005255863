import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';

@Component({
  selector: 'app-infoInputDialog',
  templateUrl: './infoInputDialog.component.html',
  styleUrls: ['./infoInputDialog.component.css']
})
export class InfoInputDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('inputInfo') inputInfo: string;
  inputForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public infoInputDialogRef: MatDialogRef<InfoInputDialogComponent>,
              private formBuilder: FormBuilder  ) { }

  ngOnInit() {
    this.createInfoInputForm();

  }
ngAfterViewInit(){
    this.inputForm.get('inputInfo').setValue(this.data.info);
}
  createInfoInputForm(){
    this.inputForm = this.formBuilder.group(
      {
        inputInfo:   ['', [Validators.required, Validators.maxLength(255)]]
      }
    )
  }
  onCancel(){
    this.infoInputDialogRef.close();
  }
  onSave(){
    return this.infoInputDialogRef.close(this.inputForm.get('inputInfo').value);
  }
}

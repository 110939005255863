import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from '../Services/data.service';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-discountControl',
  templateUrl: './discountControl.component.html',
  styleUrls: ['./discountControl.component.scss']
})
export class DiscountControlComponent implements OnInit {

  discountOptions: string[] = ['Alle Artikeln', 'nur Aktionsartikeln'];
  selectedDiscountOption = 'Alle Artikeln';
  discountFormControl: FormControl;
  deliveryDate:Date;




  constructor(    private dataService: DataService,) { }

  ngOnInit() {

    this.discountFormControl = new FormControl('');


      this.dataService.deliveryDateSubject$.subscribe(
        deliveryDate => {
          this.deliveryDate = deliveryDate;
          this.discountFormControl.reset('');
        }
      );
      this.dataService.selectedProductCategory$.subscribe(
        productCategory => {
          this.discountFormControl.reset();
        }
      );

  }

  onDiscountOption($event:any){

    if(this.selectedDiscountOption){ this.dataService.setDiscountProductsSelectedSubject(this.selectedDiscountOption.trim().toLowerCase() != 'Alle Artikeln'.trim().toLowerCase());}

  }


}

import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit, OnDestroy} from '@angular/core';
import {iUsers,iRoles, iCustomers, iTitles, iGenders, iRegistrationstypes, iUserroles, iPositions, iInputOptions, iInvoices} from '../models/AlaskaModels';
import {InputErrorStateMatcher} from '../models/InputErrorStateMatcher';
import { DataService } from '../Services/data.service';
import { DialogService } from '../Services/dialog.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ErrorStateMatcher} from '@angular/material/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '../../../node_modules/@angular/material/sort';
import { MatPaginator } from '../../../node_modules/@angular/material/paginator';
import { MatTableDataSource } from '../../../node_modules/@angular/material/table';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  loggedInUser: iUsers;
  userSubscription: Subscription;
  loggedInUserSubscription: Subscription;
  tableColumns: string[] = [  'UsersId',  'TitleId',  'FirstName',  'LastName',  'Email',  'BirthDate',  'Password',  'EmailConfirmed',  'Enabled',  'AccessFailedCount',  'Token',  'Blocked',  'CreateDate',  'ModifyDate',  'GenderId',  'RegistrationsTypeId', 'actions'];
  displayedColumns: string[] = [ 'UsersId',  'TitleId',  'FirstName',  'LastName', 'actions'];

  item: string = 'Liste von kundenrechnungen';
  invoices: iInvoices[];
  editableInvoice: iInvoices;
  datasource = new MatTableDataSource<iInvoices>();

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() editableCustomer: iCustomers;
  mode: string = 'list';
  formSubmited: boolean = false;
  invoiceForm: FormGroup;
  inputOptions: iInputOptions;

  Titles: iTitles[] = [];
  Genders: iGenders[] = [];
  RegistrationsTypes: iRegistrationstypes[] = [];
  Customers: iCustomers[] = [];
  Positions: iPositions[] = [];
  matcher = new InputErrorStateMatcher();
  searchTerm: string;
  deliveryDate:Date;

  columnsForm: FormControl;


  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: MatSnackBar
  ) {}

  ngOnDestroy(){
    if(this.userSubscription){
      this.userSubscription.unsubscribe();
    }
  }

  populateData(){
    if(this.editableCustomer){
      this.userSubscription = this.dataService.getData('custom','getinvoicesbycustomer', this.editableCustomer.CustomersId).subscribe(
        data => {
          this.invoices = data;
          this.datasource.data = data;
          this.datasource.sort = this.sort;
          this.datasource.paginator = this.paginator;;
        }, err => {
          console.log('error retriving users: ', err);
        });
    }
  }

  ngOnInit() {
        this.columnsForm = new FormControl( this.displayedColumns);


    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));

    this.Titles = this.inputOptions['titles'];
    this.Genders = this.inputOptions['genders'];
    this.RegistrationsTypes = this.inputOptions['registrationstypes'];
    this.Positions = this.inputOptions['positions'];
    this.Customers = JSON.parse(localStorage.getItem('customers'));


    this.dataService.deliveryDateSubject$.subscribe(
      deliveryDate => {
        this.deliveryDate = deliveryDate;}
    );

    this.createUserForm();

    this.loggedInUserSubscription = this.dataService.loggedInUserSubject$.subscribe(
                                    user => {
                                      this.loggedInUser = user;
                                    }, err => {
                                      console.log("error loggedin user: ", err);
                                    });

  }

    applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

   onSelectColumns(event: {isUserInput: any;    source: { value: any; selected: any };  })
   {
    if (event.isUserInput) {
      if (event.source.selected === true) {
        this.displayedColumns.push(event.source.value);
      } else {
        this.displayedColumns = this.displayedColumns.filter(x => x !=  event.source.value)
      }
      if(!!this.displayedColumns.filter(x => x == 'actions')){
        this.displayedColumns = this.displayedColumns.filter(x => x !=  'actions');
        this.displayedColumns.push('actions');
      }
    }

  }

  updateDataSource(invoices: iInvoices[]){
    this.datasource.data = invoices;
    if(this.datasource.data){
      this.datasource.sort = this.sort;
      this.datasource.paginator = this.paginator;
    }
  }

  ngAfterViewInit() {
    this.userSubscription = this.dataService.getData('custom','getinvoices').subscribe(
                            data => {
                              this.invoices = data;
                              this.datasource.data = data;
                              this.datasource.sort = this.sort;
                              this.datasource.paginator = this.paginator;;
                            }, err => {
                              console.log('error retriving invoices: ', err);
                            });
  }


  createUserForm(invoice?: iInvoices){
    this.invoiceForm = this.formBuilder.group(
      {
        InvoicesId :         [invoice != undefined && invoice.InvoicesId != undefined? invoice.InvoicesId : 0, []],
        CustomersId :        [invoice != undefined && invoice.CustomersId != undefined? invoice.CustomersId : 0, []],
        OrdersId :           [invoice != undefined && invoice.OrdersId != undefined? invoice.OrdersId : 0, []],
        Ammount :            [invoice != undefined && invoice.Ammount != undefined? invoice.Ammount : 0, []],
        DueFrom :            [invoice != undefined && invoice.DueFrom != undefined? invoice.DueFrom : '', []],
        DueTo :              [invoice != undefined && invoice.DueTo != undefined? invoice.DueTo : '', []],
        Remarks :            [invoice != undefined && invoice.Remarks != undefined? invoice.Remarks : '', []]
      }
    );
  }


  onList(){
    this.mode = 'list';
    this.item = 'List-ansicht von kundenrechnungen . . .';
    this.populateData();
  }

  onEdit(invoice: iInvoices){
    this.mode = 'edit';
    let editableCustomer: iCustomers = invoice.Orders.ShoppingCarts.Customer;
    this.editableInvoice = invoice;
    const curentCustomer:string   = editableCustomer != undefined ? editableCustomer.Name1 :'';
    this.item = 'Rechnung: '+ invoice.InvoicesId + ' von '+curentCustomer+' bearbeiten . . .';

  }
  onCreate(){
    this.mode = 'create';
    this.item = 'Neu Alaska benutzer erstellen';
    this.reSetSelectedUser()
    this.createUserForm();
  }
  onDelete(invoice: iInvoices){
    this.mode = 'delete';
    this.editableCustomer = invoice.Orders.ShoppingCarts.Customer;
    this.editableInvoice = invoice;
    const curentCustomer:string   = this.editableCustomer  != undefined ? this.editableCustomer .Name1 :'';
    this.item = 'Rechnung: '+ invoice.InvoicesId + ' von '+curentCustomer+' löschen . . .';

    this.onSubmitDeleteUser(invoice);
  }

  getFormData(): iInvoices{
    let _invoice: iInvoices =
    {
      InvoicesId :        +this.invoiceForm.get('InvoicesId').value,
      CustomersId:        +this.invoiceForm.get('CustomersId').value,
      OrdersId:           +this.invoiceForm.get('OrdersId').value,
      Ammount:            +this.invoiceForm.get('Ammount').value,
      DueFrom:            this.invoiceForm.get('DueFrom').value,
      DueTo:              this.invoiceForm.get('DueTo').value,
      Remarks :           this.invoiceForm.get('Remarks').value,
    }
    return _invoice;
  }

  onSubmitCreateUser(invoice: iInvoices){

      let sub: Subscription = this.dataService.PostData('custom',invoice, 'postInvoices').subscribe(
        result => {
            this.editableInvoice = result;

            this.dialogService.okDialog("Erfolg", "Rechnung erfolgreich angelegt. " , "success",'40%');
        }, err => {
          if(err.text == "Redirect user_exist"){ }

            console.log('error inserting err.error.text', err.error.text);
            this.dialogService.okDialog("Error", "Rechnung hinzufügen fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');
        }, () => {}
      );
      if(sub){sub.unsubscribe}

  }

  onSubmitEditUser(invoice: iInvoices){

    let sub: Subscription = this.dataService.PutData('custom',invoice, 'putinvoices').subscribe(
        result => {
            this.editableInvoice = result;

            this.snackbarService.open("Rechnung erfolgreich geändert.", "ok");
        }, err => {
            console.log('error editing rechnung', err.error.text);
            this.dialogService.okDialog("Error", "Rechnung bearbeitung fehlgeschlagen. \n Grund: "+ err.error.text , "danger");
        }, () => {}
      );
        if(sub){sub.unsubscribe}
  }

  onSubmitDeleteUser(invoice: iInvoices){

    const curentCustomer:string   = this.editableCustomer  != undefined ? this.editableCustomer .Name1 :'';
        let sub1: Subscription = this.dialogService.conformDialog("Bestätigung", "Möchet Sie den rechnung "+invoice.InvoicesId+", von "+curentCustomer+" löschen? \n Hinweis: Benutzer kann gelöscht werden, wenn die Benutzer-ID nicht bereits in einer anderen Tabelle referenziert ist.  ", "danger","abrechen","löschen").afterClosed().subscribe(
        data => {
               if(data !== 'ok'){  this.onList(); return;}

               let sub2: Subscription = this.dataService.RemoveData('custom',invoice.InvoicesId, 'deleteinvoices',).subscribe(
                result => {
                    this.editableInvoice = result;
                    // this.setUsers();
                    this.snackbarService.open("Rechnung erfolgreich gelöscht. " , "ok");
                }, err => {
                    console.log('error deleting rechnung', err);
                    this.dialogService.okDialog("Error", "Rechnung löschen fehlgeschlagen. Grund: "+ err.error.text , "danger",'40%');
                }, () => {}
              );
              if(sub2){sub2.unsubscribe}
        }
      );
      if(sub1){sub1.unsubscribe}
  }

  onSubmit(){
    this.formSubmited = true;
    let invoice: iInvoices = this.getFormData();

    if(this.mode == 'create'){
      this.onSubmitCreateUser(invoice);
    }

    if(this.mode == 'edit'){
      this.onSubmitEditUser(invoice);
    }
      this.onList();
  }

  onListOrders(invoice: iInvoices ){
    this.mode = 'listOrders';
    const curentCustomer:string   = this.editableCustomer  != undefined ? this.editableCustomer .Name1 :'';
    this.item = 'Bestellte produkte in rechnung '+invoice.InvoicesId+', bestellt von, '+ invoice.Orders.OrderedBy +', '+ curentCustomer;

    if(invoice == undefined){
      console.log("invoice is null: ", invoice);
      return;
    }else{
      this.seteditableInvoice(invoice);
    }

  }

  seteditableInvoice(invoice: iInvoices ){
    if(invoice != undefined){
      this.editableInvoice = invoice;
      localStorage.setItem('editableInvoice', JSON.stringify(this.editableInvoice));
    }
  }

  reSetSelectedUser(){
      this.editableInvoice = undefined;
      localStorage.removeItem('editableInvoice');
  }
  onReset(){
    this.mode = 'list';
  }
  onTypeChanged(userForm: FormGroup){
    this.isStaffUser(userForm);
  }
  isStaffUser(userForm: FormGroup): boolean{
    if(!userForm.get('RegistrationsTypeId')){ return false;}
    else{
      let staffUsers: number[]= [1,2,3,4]
      console.log("type: ", userForm.get('RegistrationsTypeId').value);
      console.log("isstaff: ", !!staffUsers.find(x => x == +userForm.get('RegistrationsTypeId').value));
      return !!(+userForm.get('RegistrationsTypeId').value == 1);
    }
  }

}






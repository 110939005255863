import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import { iCustomers, iOrders, iShoppingcartitems, iShoppingcarts } from 'src/app/models/AlaskaModels';

import { jsPDF } from "jspdf";
import html2Canvas  from 'html2canvas';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataService } from '../../Services/data.service';
import { environment } from 'src/environments/environment';

const ELEMENT_DATA: Array<iShoppingcartitems> = [];
@Component({
  selector: 'app-invoiceDialog',
  templateUrl: './invoiceDialog.component.html',
  styleUrls: ['./invoiceDialog.component.css']
})
export class InvoiceDialogComponent implements OnInit {

  order: iOrders;
  selectedOrder: iOrders;
  selectedCustomer: iCustomers;
  selectedCustomerName:string;
  selectedShoppingCartsName:string;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  optionValue:number = 1;
  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 8;

  displayedInvoiceColumns:string[] = ['ProductId','DescRow1','Unit', 'Quantity', 'Price','PosDiscount','TotalDiscount', 'Surcharge', 'TotalPrice', 'Picture'];
  displayedColumns: string[] = [] ;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public infoInputDialogRef: MatDialogRef<InvoiceDialogComponent>,
              private formBuilder: FormBuilder,
              private dataService:DataService,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit() {
    console.log('invoice dialog: ' , this.data);
    this.order = this.data && this.data.order? this.data.order: null;
    this.selectedOrder = this.data && this.data.order? this.data.order: null;
    this.selectedCustomer = this.selectedOrder? this.selectedOrder.ShoppingCarts.Customer:this.selectedCustomer;

    this.selectedCustomerName = this.selectedOrder? this.selectedOrder.ShoppingCarts.CustomersName1:this.selectedCustomerName;
    this.selectedShoppingCartsName = this.selectedOrder? this.selectedOrder.ShoppingCarts.Name:this.selectedShoppingCartsName;
    this.date = this.dataService.getDate(new Date() , 'EEEE, dd.MM.yyyy');

    let dDate = this.selectedOrder?this.selectedOrder.DeliveryDate:null;

    this.deliveryDate =  dDate? this.dataService.getDate(dDate , 'EEEE, dd.MM.yyyy'): this.deliveryDate;
    this.title = 'Auftrag von '+ this.selectedCustomerName + ' '+ this.selectedShoppingCartsName;
    this.displayedColumns = this.displayedInvoiceColumns;

    this.datasource.data = this.order && this.order.ShoppingCarts && this.order.ShoppingCarts.Shoppingcartitems? this.order.ShoppingCarts.Shoppingcartitems: null;

    this.datasource.data.sort;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Artikel pro Seite:';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';
  }

  onSelectionChange(optionValue:any){
    this.optionValue = +optionValue;
    this.displayedColumns = this.displayedInvoiceColumns;

    if(this.selectedCustomer != undefined && optionValue && (optionValue == 1 || optionValue == 2 || optionValue == 3)){
      this.title = 'Auftrag von '+ this.selectedCustomer.Name1 + ' '+ this.selectedOrder.ShoppingCarts.Name;
    }
    if(this.selectedCustomer != undefined && optionValue && (optionValue == 4 || optionValue == 5 || optionValue == 6)){
      this.title = 'Auftrag von '+ this.selectedCustomer.Name1 + ' '+ this.selectedOrder.ShoppingCarts.Name;
    }
    if(optionValue && (optionValue == 2 || optionValue == 5) ){
      this.displayedColumns = this.displayedInvoiceColumns.filter(x => x != 'Price')
      this.pageSize = 8;
    }
    if(optionValue && (optionValue == 3 || optionValue == 6) ){
      this.displayedColumns = this.displayedInvoiceColumns.filter(x => x != 'Picture')
      this.pageSize = 20;
    }


  }

  getNetPrice() {
    return this.datasource.data.map(t => t.Price * t.Quantity).reduce((acc, value) => acc + value, 0);
  }

  getTotalPrice() {
    return this.datasource.data.map(t => t.Price  * t.Quantity * 1.07).reduce((acc, value) => acc + value, 0);
  }

  onCancel(){
    this.infoInputDialogRef.close();
  }

    totalRowDiscount(shoppingcartitem: iShoppingcartitems): number{
    return shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.PosDiscount / 100;
  }

  totalRowPrice(shoppingcartitem: iShoppingcartitems): number{
    return (shoppingcartitem.Price * shoppingcartitem.Quantity) - this.totalRowDiscount(shoppingcartitem) ;
  }



  totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
    let totalRowSurcharge: number = 0;
    if( shoppingcartitem.Quantity % 1 != 0){
      let surchargeQuantity = shoppingcartitem.Quantity % 1 ;
      totalRowSurcharge =  shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100;
    }
    return totalRowSurcharge
  }

  totalPriceSum(): number{
    let totalPrice: number = 0;
    let totalNatoPrice: number = 0;
    let totalDiscount: number = 0;
    let totalSurCharge: number = 0;
    let lieferkosten: number = 0;


    if(this.datasource && this.datasource.data){
      this.datasource.data.forEach(row => {
          totalPrice = totalPrice + this.totalRowPrice(row);
          totalDiscount = totalDiscount + this.totalRowDiscount(row);
          totalSurCharge = totalSurCharge + this.totalRowSurcharge(row);
      });
    }
    totalNatoPrice = (totalPrice + totalSurCharge - totalDiscount);
    return +totalNatoPrice.toFixed(2);
  }


}



import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef, Output, Input, EventEmitter, HostListener } from '@angular/core';
import { DataService } from './Services/data.service';
import {MatTabsModule, MatTab, MatTabGroup} from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { iAdditives, iAddressestypes, iAgentInfo, iCustomerInfo, iCustomers, iInputOptions, iOpenitems, iParameterBag, iProductcategories, iProducts, iShoppingCartInfoBag, iShoppingcarts, iUserroles, iUsers } from './models/AlaskaModels';
import { Subject, Subscription, Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DialogService } from './Services/dialog.service';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import { IdbService } from './Services/idb.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import { ChangePasswordComponent } from './auth/changePassword/changePassword.component';
import { promise } from 'protractor';
import { NONE_TYPE, ThisReceiver } from '@angular/compiler';
import { ShopingCartComponent } from './shopingCart/shopingCart.component';
import { ProductsComponent } from './products/products.component';
import { OrderablesComponent } from './orderables/orderables.component';
import { OrdersComponent } from './orders/orders.component';
import { ProductCategoryComponent } from './productCategory/productCategory.component';
import { UserLoginComponent } from './auth/UserLogin/UserLogin.component';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { map, shareReplay } from 'rxjs/operators';


registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent  implements OnInit, AfterViewInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  mode: ProgressSpinnerMode = 'determinate';
  value = 0;

  title = 'AlaskaWeb';
  currentUrl: string = "home";
  previousUrl: string;
  hideTopPannel: boolean ;
  inputOptions: iInputOptions;
  selectedCustomer:iCustomers;
  loggedInCustomer: iCustomers;
  loggedInUser: iUsers;
  navigation: string = "";
  isLoading:boolean = false;
  adminVisibility:string = 'hideDive';
  userVisibility:string = 'showDiv';

  formatedDate:string;
  deliveryDate: Date;

  DeliveryDateForm:FormControl;
  additives: iAdditives[];
  selectedTabIndex:string ; //'Produkte';
  isAborted = false;

  products: iProducts[] = [];

  userRoles: iUserroles;
  adminMode: boolean = false;
  bgprimary:string = 'primary';
  bgaccent:string = 'accent';
  activeTab:string = 'Produkte'
  windowWidth:number;
  windowHeight:number;
  windowSize = 'L'
  tableHeight:number;
  panelOpenState:boolean = false;
  badgColor = this.activeTab != 'Warenkorb'? 'warn': 'primary';

  showmenue = false;
  showtab = false;
  showcustomerdata = false;

  SelectedProductCategory: iProductcategories;
  shoppingCartInfoBag:iShoppingCartInfoBag = { TotalPrice: 0.00,  TotalNatoPrice: 0.00,  TotalDiscount: 0.00,  TotalSurCharge: 0.00,  Lieferkosten: 0.00    };
  @ViewChild("catalogTabRef", { static: false }) catalogTabRef: MatTabGroup;
  customerTabs = ['Produkte', 'Ordersatz', 'Warenkorb', 'Auftragssammler', 'Adminansicht', ]
  adminTabs = ['Kundenverwaltung', 'Benutzerverwaltung', 'Bestellverwaltung', 'Datenverwaltung', 'Kundenansicht']
  content:string = 'Produkte';
  selected = new FormControl(0);
  @ViewChild('productsComponent') productsComponent:ProductsComponent;
  @ViewChild('orderableComponent') orderableComponent:OrderablesComponent;
  @ViewChild('shoppingCartComponent') shoppingCartComponent:ShopingCartComponent;
  @ViewChild('ordersComponent') ordersComponent:OrdersComponent;
  @ViewChild('productCategoryComponent') productCategoryComponent:ProductCategoryComponent;
  @ViewChild('userLoginComponent') userLoginComponent:UserLoginComponent;
  pipe = new DatePipe('de-DE');
  agentCustomerInfos:iAgentInfo[] = [];
  customerInfos:iCustomerInfo[] = [];



  constructor(
    private breakpointObserver: BreakpointObserver,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private idbService: IdbService,
    private router: Router,
    private location: Location,
    private changedetactor:ChangeDetectorRef

  ) {}

  hideorshowmenue():boolean{
    this.showmenue = !this.showmenue;
    return this.showmenue;
  }
  hideorshowshowtab():boolean{
    this.showtab = !this.showtab;
    return this.showtab;
  }
  hideorshowcustomerdata():boolean{
    this.showcustomerdata = !this.showcustomerdata;
    return this.showcustomerdata;
  }
  getScreenSize(){ return this.dataService.getScreenSize();}

isOnline:boolean = false;
  // isOffLine(){

  //   let sub: Subscription = this.dataService.getData("custom","ping").subscribe(
  //     data => {
  //       'ok' = data;
  //     },err =>{
  //       console.log("error get inputOptions in app-component", err);
  //     }
  //   );

  //   return navigator.onLine == true;
  // }

  idbActiveProducts:iProducts[];
  idbAllProducts:iProducts[];
  parameterBagSubject: iParameterBag = {};
  smallDeviceSize = 1000;
  shoppingCartCountsOfCustomer = '0';

  ngOnInit(){

    // this.dataService.setParameterBagSubject(this.parameterBagSubject);
    // parameterBagSubject: iParameterBag;
    // this.dataService.parameterBagSubject$.subscribe(
    //   (bag) => {
    //     this.parameterBagSubject = bag;
    //     this.selectedCustomer = bag && bag.SelectedCustomer? bag.SelectedCustomer : this.selectedCustomer;
    //     this.deliveryDate = bag && bag.DeliveryDate? bag.DeliveryDate: this.deliveryDate;
    //   }, (err) => { console.log('error - parameterBagSubject-subscription: ', err); });

    localStorage.setItem('smallDeviceSize', this.smallDeviceSize.toString());

      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.content = 'Produkte';

    // this.shoppingCartInfoBag = { TotalPrice: 0.00,  TotalNatoPrice: 0.00,  TotalDiscount: 0.00,  TotalSurCharge: 0.00,  Lieferkosten: 0.00    };
    this.shoppingCartInfoBag = JSON.parse(localStorage.getItem('shoppingcartinfobag')) != undefined?  JSON.parse(localStorage.getItem('shoppingcartinfobag')): this.shoppingCartInfoBag ;
    // window.addEventListener('storage', (event: StorageEvent) => { if (event.key === 'shoppingcartinfobag') { this.shoppingCartInfoBag = JSON.parse(event.newValue) ; } });

    this.dataService.shoppingCartInfoBagSubject$.subscribe(
      value => {
        this.shoppingCartInfoBag = value != undefined? value : this.shoppingCartInfoBag;
        // this.shoppingCartInfoBag = JSON.parse(localStorage.getItem('shoppingcartinfobag')) ;
      }
    );

    this.dataService.ShoppingCartCountsOfCustomer$.subscribe(
      value => {
        this.shoppingCartCountsOfCustomer = value.toString();
        // setTimeout(() => {   }, 0);
      }
    );

//     this.value$ = new Observable<any>(subscriber => {
//       subscriber.next(localStorage);
//       return () => {
// let x = localStorage.getItem('shoppingcartinfobag')
//       };
//     }).pipe(
//       map(value => value || 'Default Value')
//     );


    this.adminMode = false;
    localStorage.setItem("tabMode", '0');
    this.dataService.setDeliveryDateSubject();
    this.selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));
    this.dataService.setSelectedCustomerSubject(this.selectedCustomer);

    let activeShoppingCart = this.idbService.getActiveCustomerShoppingCart(this.selectedCustomer);
    this.dataService.getAndSetShoppingCartInfoBag(activeShoppingCart);

    // this.parameterBagSubject.SelectedCustomer = this.selectedCustomer;
    // this.parameterBagSubject.DeliveryDate= this.deliveryDate;
    // this.parameterBagSubject.SelectedShoppingCart= activeShoppingCart;
    // this.dataService.setParameterBagSubject(this.parameterBagSubject);


    // window.addEventListener('storage', (event: StorageEvent) => { if (event.key === 'shoppingcartinfobag') { this.shoppingCartInfoBag = JSON.parse(event.newValue) ; } });

    // this.dataService.getData('custom', 'ping').subscribe( data => {
    //   data && data == 'ok'? this.isOnline = true: false; },err => {
    //     this.isOnline = false});

    this.dataService.selectedProductCategory$.subscribe(
      (selectedProductCategory) => {
        this.SelectedProductCategory = selectedProductCategory;
      },
        (err) => {  console.log('error - selectedProductCategory-subscription: ', err);
      });

      this.dataService.loggedInUserSubject$.subscribe(
        loggedInUser => {
          this.loggedInUser = loggedInUser;
          this.loggedInCustomer = this.loggedInUser? this.loggedInUser.Customers: undefined;
        },
        (err) => {  console.log('error - loggedInUser-subscription: ', err);
      });

      this.dataService.deliveryDateSubject$.subscribe(
        deliveryDate => {
          this.deliveryDate = deliveryDate;
        },
        (err) => {  console.log('error - deliveryDate-subscription: ', err);
      });

      this.dataService.selectedCustomerSubject$.subscribe(
        selectedCustomer => {
            this.selectedCustomer = selectedCustomer;
            if(this.content == ''){this.setContent('Produkte');}
            this.onCustomerInfoShow(false);

          console.log("productCustomer: ",  this.selectedCustomer);
        },
        (err) => {  console.log('error - selectedCustomerSubject-subscription: ', err);
      });

      this.dataService.isLoadingSubject$.subscribe(
        (loading) => {
          this.isLoading = loading;
        }
      );

    this.shoppingCartInfoBag = JSON.parse(localStorage.getItem('shoppingcartinfobag')) != undefined?  JSON.parse(localStorage.getItem('shoppingcartinfobag')): this.shoppingCartInfoBag ;

    // this.dataService.shoppingCartInfoBagSubject$.subscribe(
    //   (shoppingCartInfoBagData) => {
    //       setTimeout(() => {
    //           this.shoppingCartInfoBag = JSON.parse(localStorage.getItem('shoppingcartinfobag')) != undefined?  JSON.parse(localStorage.getItem('shoppingcartinfobag')): this.shoppingCartInfoBag ;
    //           console.log(  'shoppingCartInfoBagData-subscription: '  );  //this.products
    //       }, 0);
    //   },
    //   (err) => { console.log('error - shoppingCartInfoBagData-subscription in app component: ', err); }
    // );

    this.dataService.msgSubject$.subscribe(
      msg => {
        if(msg.Name.toLowerCase() == 'gotochangepassoword' || msg.Name.toLowerCase() == 'gotomyprofile'||
         msg.Name.toLowerCase() ==  'gotomyaccount' || msg.Name.toLowerCase() ==  'gotoregister' || msg.Name.toLowerCase() ==  'userloggedout' ||
         msg.Name.toLowerCase() ==  'gotologin' || msg.Name.toLowerCase() ==  'gotoproducts' || msg.Name.toLowerCase() ==  'isaborted'){
          this.navigation = msg.Name.toLowerCase();

        if(this.navigation == 'gotoregister' && this.logedIn() ){
          this.dialogService.okDialog('Bitte abmelden', 'Bitte melden Sie sich zuerst ab, um sich registrieren zu können.','danger', '40%');
          this.navigation = '';
        }

        if(msg.Name.toLowerCase() ==  'isaborted'){this.content='Produkte';}else{this.content='';}

        }

        if(msg.Name.toLowerCase() == "userloggedout"){
          this.selectedCustomer = undefined;
        }

        if(msg.Name.toLowerCase() == "shoppingcartselected"){

          this.selectedCustomer = msg.Data.Customer;
        }



      }
    );

      this.setInputOptions();
      setTimeout(() => { let prod = document.getElementById('Produkte'); if(prod){prod.style.backgroundColor = '#f2375f';} }, 0);

      this.onCustomerInfoShow(false);
  }


  setContent(content:string){
    this.navigation = '';
    if( content=='Adminansicht'){this.content= 'Bestellverwaltung'; this.adminMode=true; localStorage.setItem("tabMode", '1');}
    else if( content=='Kundenansicht'){this.content= 'Produkte'; this.adminMode=false; localStorage.setItem("tabMode", '0');}
    else{this.content= content;}

    if(this.content){ this.setTabsBG(this.content);}

    this.onResetProductCategory()

    this.dataService.setclearFilterBoxSubject(true);
  }

  setTabsBG(content:string){
      let customerTabs:string[] = ['Produkte', 'Ordersatz', 'Warenkorb', 'Auftragssammler', 'Adminansicht', ]
      let adminTabs: string[] = ['Kundenverwaltung', 'Benutzerverwaltung', 'Bestellverwaltung', 'Datenverwaltung', 'Kundenansicht']
      customerTabs.forEach(element => {
        let tab = document.getElementById(element);
        if(tab){ tab.style.backgroundColor = '';}
      });
      adminTabs.forEach(element => {
        let tab = document.getElementById(element);
        if(tab){ tab.style.backgroundColor = '';}
      });

      setTimeout(() => {
        let tab = document.getElementById(content);
        if(tab){ tab.style.backgroundColor = '#f2375f';}
      }, 0);

    }

  ngAfterViewInit() {

  }


  getshoppingcartinfobag():iShoppingCartInfoBag{
        let infoBag:iShoppingCartInfoBag = JSON.parse(localStorage.getItem('shoppingcartinfobag')) ;
        this.shoppingCartInfoBag = infoBag && infoBag.TotalPrice != this.shoppingCartInfoBag.TotalNatoPrice ? infoBag : this.shoppingCartInfoBag;
        return this.shoppingCartInfoBag;
  }

  setInputOptions(): iInputOptions{
      if(!!localStorage.getItem("inputOptions")){
        this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
        console.log("inputOptions0", this.inputOptions);
        return this.inputOptions;
      }
      else{
      let sub: Subscription = this.dataService.getData("custom","getoptions").subscribe(
        data => {
          this.inputOptions = data;
          if(this.inputOptions){
            console.log("inputOptions1", this.inputOptions);
            localStorage.removeItem("inputOptions");
            localStorage.setItem("inputOptions", JSON.stringify(this.inputOptions));
            return this.inputOptions;
          }
          else{
            this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
            console.log("inputOptions2", this.inputOptions);
            return this.inputOptions;
          }
        },err =>{
          console.log("error get inputOptions in app-component", err);
        }
      );
      }

    }


  userLogedIn(): boolean{
    let loggedIn: boolean = !!localStorage.getItem('token') && JSON.parse(localStorage.getItem('loggedinUsers')) != undefined;

    return loggedIn;
  }


  logedIn(): boolean{
    if(!!localStorage.getItem('token')){

      return true;
    }else{
    return false
  }
  }
  isBaseUrl(): boolean{
    return this.location.isCurrentPathEqualTo("/")
  }

  onImpressiumDialog(){
    this.dialogService.footerDialog();
  }

  onNavigateAlaska(){
    window.open('https://alaska-tkk.de/', '_blank');
  }

  hasRoles(roleIds: number[]): boolean{
    return this.dataService.hasRoles(roleIds);
   }

hasOpenItems(): boolean{
  return true;
}
getNavigation(){
     return this.navigation;
}

getFormatedTel(telephones:string):string{
  let telArray: string[] = telephones? telephones.split(','): [];

  let filteredTels: string[] = [];
  telArray.forEach(element => { if(!filteredTels.includes(element)){filteredTels.push(element)} });
  return filteredTels.join(',').replace(',', ', ').replace('-', '/');
}

getFormatedEmail(emails:string):string{
  let telArray = emails? emails.split(','): [];

  let filteredEmails: string[] = [];
  telArray.forEach(element => { if(!filteredEmails.includes(element)){filteredEmails.push(element)} });
  return filteredEmails.join(',').replace(',', ', ');
}



onShowOpenItems(){
  let openItems: iOpenitems[] = [];

  this.dataService.getData('custom','getOpenItemyByCustomerId', this.selectedCustomer.CustomersId).subscribe(
      data => {
        openItems = data;

        this.dialogService.openItemsDialog(openItems, '80%', '' );

      },err => {
        console.log("error get openItemsDialog : ", err);
      }
    );


   }

   onShowSalesOverviewTab(url:string){
    if(url && url != '')  {   window.open(url);    }
   }

   onToursShow(customer: iCustomers){
    this.dialogService.openToursDialog(customer, '40%', '' );
   }

   onCustomerInfoShow(forceShowDialog: boolean){

    this.loggedInUser = this.loggedInUser? this.loggedInUser: this.dataService.getLogedInUser();
    this.loggedInCustomer = this.loggedInUser? this.loggedInUser.Customers: undefined;

    if(this.selectedCustomer && this.dataService.hasRoles([5])){

      this.dataService.getDataByQuerystring('custom','GetOdataCustomerInfoByCustomerNumber',this.selectedCustomer.CustomerNumber).subscribe(
        data => {
          this.customerInfos = data;
          this.agentCustomerInfos = [];

          if(forceShowDialog == true){
            this.dialogService.openCustomerInfoDialog(this.customerInfos, this.agentCustomerInfos, '50%', '' );
          }
          if(forceShowDialog == false && (this.customerInfos.length > 0 || this.agentCustomerInfos.length > 0 )){
            this.dialogService.openCustomerInfoDialog(this.customerInfos, this.agentCustomerInfos, '50%', '' );
          }

        }, err =>{
          console.log("error customerInfo count", err);
        }
        );
    }
    if(this.selectedCustomer && this.loggedInCustomer && this.dataService.hasRoles([1,2,3,4])){

      this.dataService.getDataByQuerystring('custom','GetOdataCustomerInfoByCustomerNumber',this.selectedCustomer.CustomerNumber).subscribe(
        data => {
          this.customerInfos = data;

              this.dataService.getDataByQuerystring('custom','GetOdataAgentInfoByCustomerNumber',this.loggedInCustomer.CustomerNumber).subscribe(
                data => {
                  this.agentCustomerInfos = data;

                  if(forceShowDialog == true){
                      this.dialogService.openCustomerInfoDialog(this.customerInfos, this.agentCustomerInfos, '50%', '' );
                  }
                  if(forceShowDialog == false && (this.customerInfos.length > 0 || this.agentCustomerInfos.length > 0 )){
                      this.dialogService.openCustomerInfoDialog(this.customerInfos, this.agentCustomerInfos, '50%', '' );
                  }


                }, err =>{
                  console.log("error customerInfo count", err);
                }
                );

        }, err =>{
          console.log("error customerInfo count", err);
        }
        );


    }


   }

   onDeliveryDateFromDateControl($event){
    let newDeliveryDate:Date = new Date($event);
    this.deliveryDate =  newDeliveryDate ? new Date(this.pipe.transform(newDeliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE')): new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

          if(this.deliveryDate && this.shoppingCartComponent){ this.shoppingCartComponent.onDeliveryDateFromApp(this.deliveryDate)   }
    else  if(this.deliveryDate && this.orderableComponent){ this.orderableComponent.onDeliveryDateFromApp(this.deliveryDate)   }
    else  if(this.deliveryDate && this.productsComponent){ this.productsComponent.onDeliveryDateFromApp(this.deliveryDate)   }
    else  if(this.deliveryDate && this.ordersComponent){ this.ordersComponent.onDeliveryDateFromApp(this.deliveryDate)   }

   }

   onInsertOrUpdateIdbShoppingCart($event){

     if(this.shoppingCartComponent != undefined){
        this.shoppingCartComponent.ngOnInit();
     }

   }

   onPopulateDynamicOrdersatz($event){

    if(this.orderableComponent != undefined){
       this.orderableComponent.ngOnInit();
    }
  }

  onOutSelectedCustomerControl($event){
    let customer:iCustomers = $event;
    if(customer && this.orderableComponent){ this.orderableComponent.onSelectedCustomerFromAppControl(customer);  }
    if(customer && this.shoppingCartComponent){ this.shoppingCartComponent.onSelectedCustomerFromAppControl(customer);  }
    if(customer && this.productsComponent){ this.productsComponent.onSelectedCustomerFromAppControl(customer);  }
    }

    onResetProductCategory(){
     if(this.productCategoryComponent){  this.productCategoryComponent.resetProductCategory()  }
   }

}

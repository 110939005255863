import { Component, OnInit, Input } from '@angular/core';

import { CatPro } from 'src/assets/CatPro';
import { iProducts, iCategoryproducts } from '../models/AlaskaModels';
import { JsonPipe } from '@angular/common';
// import * as alertify from 'alertifyjs';
import { getInterpolationArgsLength } from '@angular/compiler/src/render3/view/util';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../Services/data.service';

@Component({
  selector: 'app-category-products',
  templateUrl: './category-products.component.html',
  styleUrls: ['./category-products.component.css']
})
export class CategoryProductsComponent implements OnInit {

  @Input() CategoryProducts: Array<iCategoryproducts> ;

  categoryArray: Array<any> = [{"id":"1", "parent":"0", "name":"one"},{"id":"2", "parent":"0", "name":"two"},{"id":"3","parent":"0", "name":"three"},{"id":"4", "parent":"1", "name":"four"},{"id":"5", "parent":"1", "name":"five"},{"id":"6","parent":"2", "name":"six"}, {"id":"7","parent":"2", "name":"seven"},{"id":"8","parent":"4", "name":"eight"},{"id":"9","parent":"4", "name":"nine"},{"id":"10","parent":"2", "name":"ten"},]
  parent: Array<any> = [];
  child1: Array<any> = [];
  child2: Array<any> = [];
  child3: Array<any> = [];
  categoryProductArray: Array<any> = [];
  categoryProductId: number;
   constructor( private router: Router, private dataService: DataService ) {  }

  ngOnInit(): void {

     this.dataService.getData('categoryproducts').subscribe(

       data=>{  this.CategoryProducts= data;  }

     ), error => {
       console.log(error);
     }


  }

     setParent(parent: Array<any>)
     {
      this.parent = this.categoryArray.filter(x => x.parent === "0");


      console.log(this.parent);
      console.log(this.categoryProductArray);

     }

     setChildren(closeParent: Array<any>, fillToChild: Array<any>)
     {

      for(let i =0; i < closeParent.length; i++){
        let closeParentId: string = closeParent[i].id;
        let child = this.categoryArray.find(x => x.parent === closeParentId);
        console.log(closeParentId);
        if(child != undefined){
          fillToChild.push(child);
        }
      }

      console.log(fillToChild);
     }

     onSelect(param){
        this.router.navigate(['/products',param]);
     }

}


import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatDivider} from '@angular/material/divider';

@Component({
  selector: 'app-conformDialog',
  templateUrl: './conformDialog.component.html',
  styleUrls: ['./conformDialog.component.css']
})
export class ConformDialogComponent implements OnInit {

  title: string;
  type: string;
  msg: string;
  noLabel: string;
  yesLabel:string;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public conformDialogRef: MatDialogRef<ConformDialogComponent>) { }

  ngOnInit() {
    this.title = this.data.title;
    this.type = this.data.type; //danger, warning, info
    this.msg = this.data.msg;
    this.noLabel =  this.data.noLabel;;
    this.yesLabel = this.data.yesLabel;;

  }

  ngAfterViewInit(){

}
  onCancel(){
    this.conformDialogRef.close();
  }
  onSave(){
    return this.conformDialogRef.close('ok');
  }
}

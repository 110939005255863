
<div class="auto-width" id="searchCustomerContainer" >

  <mat-form-field appearance="outline"  class="w100 " >
    <mat-label  id="searchCustomer">Kundensuche</mat-label>
    <mat-select disableOptionCentering (selectionChange)="onCustomerClick($event)"  [formControl]="customerControl" placeholder="Kundensuche" class="searchbox"  #singleSelect>
      <mat-option>
        <ngx-mat-select-search   [formControl]="customerFilterControl" [(ngModel)]="searchTerm" ></ngx-mat-select-search>
        <mat-error class="error-block" *ngIf="searchTerm == ''">bitte kunden auswählen</mat-error>
      </mat-option>
      <mat-option  *ngFor="let customer of customers | customersFilter: searchTerm" [value]="customer" >
        {{customer.CustomerNumber}} - {{customer.Description}}, {{customer.Name1}}, {{customer.Name2}}, {{customer.Zip}}-{{customer.City}}, {{customer.Street}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>



<!-- element.style {
  min-width: 190px;
  font-size: 16px;
  top: 200px;
  left: 23px;
  transform: translateX(-16px);
} -->

import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {  iOptions, iAddresses, iUsers, iCustomers, iInputOptions, iAddressestypes, iTours } from 'src/app/models/AlaskaModels';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import {MatTabsModule, MatTab, MatTabGroup} from '@angular/material/tabs'


@Component({
  selector: 'app-customerAddress',
  templateUrl: './customerAddress.component.html',
  styleUrls: ['./customerAddress.component.css']
})
export class CustomerAddressComponent implements OnInit {
  addressForm: FormGroup;
  formSubmited: boolean;

  @ViewChild("addressDiv") addressDiv: ElementRef;
  @ViewChild('tabRef') tabRef: MatTabGroup;

  addressTypes: iAddressestypes[];
  gates: iTours[]= [];
  loggedInUser: iUsers;
  SelectedCustomer: iCustomers;
  address: iAddresses;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef) { }

  ngOnInit() {

    this.createAddressForm();

    this.addressTypes = JSON.parse(localStorage.getItem('inputOptions')).addressestypes;
    this.gates = JSON.parse(localStorage.getItem('inputOptions')).gates;

    this.dataService.loggedInUserSubject$.subscribe((data) => {
      this.loggedInUser = this.dataService.getLogedInUser();
    });
    this.dataService.selectedCustomerSubject$.subscribe((data) => {
      this.SelectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    });
  }

  ngAfterViewInit() {

    this.addressTypes = JSON.parse(localStorage.getItem('inputOptions')).addressestypes;
    this.gates = JSON.parse(localStorage.getItem('inputOptions')).gates;

    console.log('this.addressType: ',this.addressTypes);
    console.log('this.gates: ',this.gates);

  }
  createAddressForm(){
    this.addressForm = this.formBuilder.group(
      {
        AddressTypesId: ['', [Validators.required]],
        Street: ['', [Validators.required]],
        Street1: ['', []],
        Zip: ['', [Validators.required, Validators.minLength(5)]],
        City: ['', [Validators.required]],
        District: ['', []],
        Country: ['', [Validators.required]]

      }
    )
  }

  setAdresses(){
        this.address = {
    AddressesId: 0,
    CustomersId: 0,
    AddressestypesId: +this.addressForm.get('AddressestypesId').value,
    Street:  this.addressForm.get('Street').value,
    Street1:  this.addressForm.get('Street1').value,
    Zip:  this.addressForm.get('Zip').value,
    City:  this.addressForm.get('City').value,
    District:  this.addressForm.get('District').value,
    Country:  this.addressForm.get('Country').value

  }
    localStorage.setItem('reg-address',JSON.stringify(this.address));
  }
  onSubmit(){
    this.setAdresses();
  }

  toggelDiv(){
    if (this.addressDiv.nativeElement.style.display === "block") {
      this.addressDiv.nativeElement.style.display = "none";
    } else {
      this.addressDiv.nativeElement.style.display = "block";
    }
  }

  onNextClick(){
    console.log('LSaddress: ' , localStorage.getItem('reg-address'));
  }

}

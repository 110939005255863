
<div class="card">

  <div class="card-header">
    <div class="row">
      <div class="col-12 buttonrow">
        <!-- <mat-paginator [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>   -->
        <div class="paginator250px ">
          <select (change)="onPageSizeChange($event)" class="small pgnselect" [(ngModel)]="pageSize"> <option  value=10>10</option> <option  value=20>20</option> <option  value=50>50</option> </select>
          <span class="small">{{getPageVisits()}}</span>
          <button (click)="pgnFirstPage()" class=" arrowxxl" id="productfirstpage"><span>&#171;</span></button>
          <button (click)="pgnBackPage()" class=" arrowxxl"><span>&#8249;</span></button>
          <button (click)="pgnNextPage()" class=" arrowxxl"><span>&#8250;</span></button>
          <button (click)="pgnLastPage()" class=" arrowxxl"><span>&#187;</span></button>
        </div>


      <div class="buttonrow ">

       <mat-form-field  appearance="outline">
          <mat-label>Daten-update</mat-label>
          <mat-select  [formControl]="DatenUpdateControl"  [(ngModel)]="selectedViewValue" (ngModelChange)="onSelectColumns($event)">
          <mat-option onSelectionChange="onSelectColumns($event)" *ngFor="let target of publicationTargets" [value]="target.value">{{ target.viewValue}}</mat-option> </mat-select>
        </mat-form-field>

        <button (click)="onPublication()"  class="mat-icon-button" > <span class="material-icons btn-outline-primary md-48 m2" matTooltip="Veröffentlichen"> refresh  </span> </button>
      </div>
  </div>

    </div>


  </div>



  <div class="card mat-elevation-z8 tables alaska-table-container table-striped">
    <table mat-table [dataSource]="datasource" matSort>



   <ng-container matColumnDef="UpdatereportsId">
   <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Id</th>
   <td mat-cell class="" *matCellDef="let element">{{ element.UpdatereportsId }}</td></ng-container>

   <ng-container matColumnDef="Entity">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Tabele</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.Entity }}</td></ng-container>

    <ng-container matColumnDef="Start">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Start</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.Start | date: 'dd.MM.yyyy hh:mm:ss'}}</td></ng-container>

    <ng-container matColumnDef="End">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Ende</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.End | date: 'dd.MM.yyyy hh:mm:ss'}}</td></ng-container>

    <ng-container matColumnDef="Elapsed">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Dauer(sec.)</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.Elapsed }}</td></ng-container>

    <ng-container matColumnDef="Updateable">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Verfügbar</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.Updateable }}</td></ng-container>

    <ng-container matColumnDef="Updated">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Aktualisiert</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.Updated }}</td></ng-container>

    <ng-container matColumnDef="Ignored">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >übersprungen</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.Ignored }}</td></ng-container>

    <ng-container matColumnDef="Failed">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >fehlschlagen</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.Failed }}</td></ng-container>

    <ng-container matColumnDef="Shortmsg">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Info</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.Shortmsg }}</td></ng-container>

    <ng-container matColumnDef="Aktions">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Aktions</th>
      <td mat-cell class="" *matCellDef="let element"><button (click)="onRemoveReportClick(element)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button></td></ng-container>

     <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


     </table>
     <mat-paginator hidePageSize="true"  [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>

   </div>

</div>
     <div class="loading-indicator">
      <mat-spinner mode="indeterminate" color="accent" *ngIf="isLoading"></mat-spinner>

    </div>


<div class="row">
  <div class="col-12 ">
  <div class="card m200">
    <div class="card-header"> Passwort ändern </div>

    <div class="card-body">

      <form  [formGroup]="passwordResetForm">
        <div class="form-group col-12">

          <mat-form-field class="w-100" appearance="outline">
            <input matInput formControlName="email"   readonly  [ngModel]="loggedInEmail">
          </mat-form-field>

        </div>

        <div class="form-group col-12">
          <mat-form-field class="w-100" appearance="outline">
            <input matInput type="password" formControlName="currentpassword" placeholder="bisheriges PW" [errorStateMatcher]="matcher">
            <mat-error class="error-block" *ngIf="!passwordResetForm.get('currentpassword').valid && (passwordResetForm.get('currentpassword').touched  || passwordResetFormSubmited)"></mat-error>
          </mat-form-field>
        </div>

        <div class="form-group col-12">
          <mat-form-field class="w-100" appearance="outline">
            <input matInput type="password" formControlName="password" placeholder="neues Passwort" [errorStateMatcher]="matcher">
            <mat-error class="error-block" *ngIf="!passwordResetForm.get('password').valid && (passwordResetForm.get('password').touched  || passwordResetFormSubmited)"></mat-error>
          </mat-form-field>
        </div>

        <div class="form-group col-12">

          <mat-form-field class="w-100" appearance="outline">
            <input matInput type="password" formControlName="conformpassword" placeholder="neues Passwort wiederholen" [errorStateMatcher]="matcher">
            <mat-error class="error-block" *ngIf="validatePasswordConfirmation() != true || !passwordResetForm.get('conformpassword').valid && (passwordResetForm.get('conformpassword').touched  || passwordResetFormSubmited)"></mat-error>

          </mat-form-field>

        </div>
        <div class="row">

          <div class="form-group col-6">
            <button [disabled]="!passwordResetForm.get('email').valid || !passwordResetForm.get('currentpassword').valid || !passwordResetForm.get('password').valid || validatePasswordConfirmation() != true || !passwordResetForm.get('conformpassword').valid && (passwordResetForm.get('conformpassword').touched  || passwordResetFormSubmited)"  type="button" (click)="onPasswordReset()" class="btn btn-primary">Passwort zurücksetzen</button>
          </div>
            <div class="col-6"><button type="button" (click)="onAbort()" class="btn btn-danger">abrechen</button></div>

        </div>

      </form>

    </div>
  </div>
  </div>
</div>


<div class="card">

  <div class=row>
    <div class="column">
      <div class="card">
        <div class="col-4"></div>
        <div class="col-8">Meine Zugangsdaten</div>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="col-4"></div>
        <div class="col-8">Meine Zugangsdaten</div>
      </div>
    </div>
  </div>

  <div class=row>
    <div class="column">
      <div class="card">
        <div class="col-4"></div>
        <div class="col-8">Meine Bestellungen</div>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="col-4"></div>
        <div class="col-8">Meine Lieferungen</div>
      </div>
    </div>
  </div>

</div>


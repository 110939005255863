
<table>
  <tr>
    <td>
      <div ><mat-paginator [length]="itemLength"  [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons  ></mat-paginator></div>
    </td>
    <td>
      <mat-form-field appearance="outline" *ngIf="optionValueNumber != 0">
        <mat-label>PDF speichern/druck optionen</mat-label>
        <!-- <mat-select [formControl]="orderFormControl" disableRipple [(ngModel)]="optionValueNumber" (ngModelChange)="onSelectionChange($event['value']['Value'])"> -->
        <mat-select [formControl]="orderFormControl" disableRipple [(ngModel)]="optionValueNumber" (selectionChange)="onSelectionChange($event['value']['Value'])">
          <mat-option value="0_ ">optionen</mat-option>
          <!-- <mat-option *ngIf="ShoppingCartStateId == 7" value="1_Angebot">Angebot</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 7" value="2_Angebot">Angebot ohne Preise</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 7" value="3_Angebot">Angebot ohne Bilder</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 8" value="4_Bestellauskunft">Bestellauskunft</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 8" value="5_Bestellauskunft">Bestellauskunft ohne Preise</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 8" value="6_Bestellauskunft">Bestellauskunft ohne Bilder</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 9" value="7_Bestellvorlage">Bestellvorlage</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 9" value="8_Bestellvorlage">Bestellvorlage ohne Preise</mat-option>
          <mat-option *ngIf="ShoppingCartStateId == 9" value="9_Bestellvorlage">Bestellvorlage ohne Bilder</mat-option> -->

        <mat-option *ngFor="let order of orderOptions" [value]="order">
          {{order.Key}}
        </mat-option>

        </mat-select>



      </mat-form-field>
    </td>
    <td>
      <button   class="btn m-2" [useExistingCss]="true"   printSectionId="orderContainer" ngxPrint

      > <i class="material-icons btn-outline-primary md-48" matTooltip="Mit Preisen und Bildern ">print</i></button>
      <button (click)="onCancel()" class="btn m-2"> <i class="material-icons btn-outline-danger md-48 " matTooltip="abrechen">cancel</i> </button>
    </td>
  </tr>
  <tr>
    <td>

    </td>
    <td>

    </td>
  </tr>
</table>

<div id="orderContainer">

  <div class="row" style="margin:10px 20px 10px 20px;">
<div class="col-12" >



<!-- <table class="" style="width:100%; height:0; font-family: Roboto, 'Helvetica Neue', sans-serif; " >
  <tr style="height:0; padding: 5px;">
    <td style="width:50%;">
      <span class=""><a target="_blank" href="https://alaska-tkk.de">  <img src="../assets/icons/logo_alaska.jpg" data-holder-rendered="true" width="200" />  </a></span>
      <p></p><p></p>

      <div class="floatl" *ngIf="selectedCustomer != undefined" style="float:left; text-align:left">
        <table class="customertbl"  >
          <tr><td class="">             </td><td class="wrap ">{{ selectedCustomer.Name1 }}</td></tr>
          <tr><td class="">             </td><td class="wrap">{{ selectedCustomer.Name2 }}</td></tr>
          <tr><td class="">             </td><td class="wrap ">{{selectedCustomer.Street}}</td></tr>
          <tr><td class="">             </td><td class="wrap ">{{selectedCustomer.Zip}}, {{selectedCustomer.City}}</td></tr>
        </table>
      </div>
    </td>
    <td style="height:0; padding: 5px;">
      <div class="floatr" style="text-align:right; ">
        <br><br><br><p></p>
        <p class="txtr" style="height:0; padding: 5px;">Kauritzerstr. 29, 04639 Gößnitz</p>
        <p class="txtr" style="height:0; padding: 5px;">Tel: 034493/22588</p>
        <p class="txtr" style="height:0; padding: 5px;">Fax: 034493/36532</p>
      </div>
    </td>
  </tr>


  <tr >
    <td>
      <div class="floatl" style="float:left; text-align:left">
        <p >   </p>
      </div>
    </td>
    <td>
      <div class="floatr" style="float:right; text-align:right">
        <p class="txtr" *ngIf=" date != undefined" style="height:0; padding: 5px;"> Datum: {{date}} </p>
        <p class="txtr" *ngIf=" [1,2,3,4,5,6,7,8,9].includes(optionValueNumber) == true && deliveryDate != undefined" style="height:0; padding: 5px;"> Lieferdatum: {{deliveryDate}} </p>
        <p class="txtr" *ngIf=" [1,3,4,6,7,9].includes(optionValueNumber) == true  && totalPriceSum() != undefined" style="height:0; padding: 5px;"><b>Gesamtbetrag: {{totalPriceSum()}} € </b> </p>
      </div>
    </td>
  </tr>

</table> -->

  <!-- <div class="col-12 "  *ngIf="title != undefined && [1,2,3,4,5,6,7,8,9].includes(optionValueNumber) == true"> <p>{{title}} ( KundenNr.: {{ selectedCustomer.CustomerNumber }} ) </p> </div>
  <p class="floatl " *ngIf=" selectedCustomer == undefined  "> <span class="text-danger"> bitte wählen Sie ein Kunde aus!</span></p>
  <p class="floatl " *ngIf=" [1,2,3,4,5,6,7,8,9].includes(optionValueNumber) == false && selectedCustomer != undefined "> <span class="text-danger"> bitte wählen Sie ein druckoption aus!</span></p> -->

<!-- ############### -->
<table style="width: 100%;  margin: 10px;">
  <tbody>
    <tr > <td class="alignLeft">&nbsp;<span class=""><a target="_blank" href="https://alaska-tkk.de">  <img src="../assets/icons/logo_alaska.jpg" data-holder-rendered="true" width="200" />  </a></span></td> <td ></td> <td ></td>  </tr>

    <tr >
      <td class="alignLeft">
        <p *ngIf="selectedCustomer != undefined">{{ selectedCustomer.Name1 }}</p>
        <p *ngIf="selectedCustomer != undefined">{{ selectedCustomer.Name2 }}</p>
        <p *ngIf="selectedCustomer != undefined">{{selectedCustomer.Street}}</p>
        <p *ngIf="selectedCustomer != undefined">{{selectedCustomer.Zip}}, {{selectedCustomer.City}}</p>
      </td>
      <td class="alignRight" colspan="2">
        <p>Kauritzerstr. 29, 04639 G&ouml;&szlig;nitz</p>
        <p>Tel: 034493/22588</p>
        <p>Fax: 034493/36532</p>
        <p  *ngIf=" date != undefined" > Datum: {{date}} </p>
        <p  *ngIf=" [1,2,3,4,5,6,7,8,9].includes(optionValueNumber) == true && deliveryDate != undefined" > Lieferdatum: {{deliveryDate}} </p>
        <p><strong> <span *ngIf=" [1,3,4,6,7,9].includes(optionValueNumber) == true  && totalPriceSum() != undefined" ><b>Gesamtbetrag: {{totalPriceSum()}} € </b> </span></strong></p>
      </td>
    </tr>
    <tr>
      <td class="alignLeft" colspan="3">
        <p  *ngIf=" title != undefined && [1,2,3,4,5,6,7,8,9].includes(optionValueNumber) == true" > {{title}} ( KundenNr.: {{ selectedCustomer.CustomerNumber }} )  </p>
        <p  *ngIf=" selectedCustomer == undefined  " class="text-danger">bitte wählen Sie ein Kunde aus!</p>
        <p  *ngIf=" [1,2,3,4,5,6,7,8,9].includes(optionValueNumber) == false && selectedCustomer != undefined " class="text-danger"> bitte wählen Sie ein druckoption aus!</p>
      </td>
    </tr>
  </tbody>
</table>

<!-- ################ -->


  <div class="col-12" *ngIf=" [1,2,3,4,5,6,7,8,9].includes(optionValueNumber) == true  && selectedCustomer != undefined  ">

          <table mat-table [dataSource]=datasource class="  mat-elevation-z8 table-striped"  >

          <ng-container matColumnDef="ProductId" class="m-1">
            <th mat-header-cell *matHeaderCellDef> A-Nr </th>
            <td mat-cell *matCellDef="let element" > {{element.Product.ProductNumber}}  </td>
          </ng-container>

          <ng-container matColumnDef="DescRow1" class="m-1">
            <th mat-header-cell *matHeaderCellDef> Bezeichnung1 </th>
            <td mat-cell *matCellDef="let element"> {{element.Product.DescRow1}} </td>
          </ng-container>

          <ng-container matColumnDef="DescRow2" class="m-1">
            <th mat-header-cell *matHeaderCellDef> Bezeichnung2 </th>
            <td mat-cell *matCellDef="let element"> {{element.Product.DescRow2}} </td>
          </ng-container>

          <ng-container matColumnDef="Unit" class="mar-r">
            <th  mat-header-cell *matHeaderCellDef> Einh. </th>
            <td mat-cell *matCellDef="let element"> {{ element.Product.QuantityUnitsShortDesc }} </td>
          </ng-container>

          <ng-container matColumnDef="Quantity" class="mar-r">
            <th class="txtRight" mat-header-cell *matHeaderCellDef> Menge </th>
            <td class="txtRight" mat-cell *matCellDef="let element"> {{ element.Quantity | number:'1.2-2':'de' }} {{ element.Product.QuantityUnitsShortDesc }}  </td>
          </ng-container>


          <ng-container matColumnDef="Price" class="mar-r" *ngIf="optionValueNumber != 2 || optionValueNumber != 5">
            <th class="txtRight" mat-header-cell *matHeaderCellDef> Preis (€) </th>
            <td class="txtRight" mat-cell *matCellDef="let element"> {{ element.Price |  number:'1.2-2':'de' }} </td>
          </ng-container>

          <ng-container matColumnDef="PosDiscount" class="mar-r">
            <th class="txtRight" mat-header-cell *matHeaderCellDef> Rabatt (%) </th>
            <td class="txtRight" mat-cell *matCellDef="let element"> {{ element.PosDiscount | number:'1.2-2':'de'}}</td>
          </ng-container>

          <ng-container matColumnDef="TotalDiscount">
            <th class="txtRight" mat-header-cell *matHeaderCellDef> G.Rabatt (€) </th>
            <td class="txtRight" mat-cell *matCellDef="let element"> {{totalRowDiscount(element)  | number:'1.2-2':'de'}} </td>
          </ng-container>

          <ng-container matColumnDef="Surcharge">
            <th class="txtRight"mat-header-cell *matHeaderCellDef> Zuschlag (3%) </th>
            <td class="txtRight" mat-cell *matCellDef="let element"> {{totalRowSurcharge(element)  | number:'1.2-2':'de'}} </td>
          </ng-container>

          <ng-container matColumnDef="TotalPrice">
            <th class="txtRight" mat-header-cell *matHeaderCellDef> G.Preis (€) </th>
            <td class="txtRight" mat-cell *matCellDef="let element"> {{totalRowPrice(element) | number:'1.2-2':'de' }} </td>
          </ng-container>



          <ng-container matColumnDef="Picture" *ngIf="optionValueNumber != 3 && optionValueNumber != 6">
            <th class="txtRight" mat-header-cell *matHeaderCellDef> Bilder </th>
            <td class="txtRight" mat-cell *matCellDef="let element"> <img class=" " src="{{IMAGE_URL}}{{element.Product.ProductNumber}}.jpg" height="50px" id="img" style="max-height:50px; " > </td>
          </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="4">Es wurden keine Daten gefunden,  </td>
                    </tr>

          </table>



        </div>
</div>





  </div>

  <div class="divPrintFooter"> Website: www.alaskaonline.de, <span class="tab100"></span>  Email: alaska-tkk@t-online.de </div>
</div>

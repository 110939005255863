<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">

    <mat-toolbar color="primary"> <button type="button" aria-label="Toggle sidenav"  mat-icon-button (click)="drawer.toggle()"
      *ngIf="isHandset$ | async">  <mat-icon aria-label="Side nav toggle icon">menu</mat-icon> </button> <span>AlaskaUi</span> Menu </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#">Link 1</a>
      <a mat-list-item href="#">Link 2</a>
      <a mat-list-item href="#">Link 3</a>

      <div class=" leftmenu">                             <div class=""> <app-filterProducts></app-filterProducts></div></div>
      <div class=" leftmenu">                             <div class=""> <app-productCategory></app-productCategory> </div></div>
      <div class=" leftmenu" *ngIf="hasRoles([1,2,3,4])"> <div class=""  > <app-searchCustomer  (outSelectedCustomerControl)="onOutSelectedCustomerControl($event)"></app-searchCustomer> </div></div>
      <div class=" leftmenu">                             <div class=""> <app-dateControl [source]="content"  (outDeliveryDateFromDateControl)="onDeliveryDateFromDateControl($event)" ></app-dateControl>  </div></div>
      <div class=" leftmenu">                             <div class=""> <app-additivesControl></app-additivesControl>  </div></div>
      <div class=" leftmenu">                             <div class=""> <app-quickBooking (outQuickBooking2ShoppingCart)="onInsertOrUpdateIdbShoppingCart($event)"></app-quickBooking>  </div></div>
      <div class=" leftmenu">                             <div class=""> <app-discountControl></app-discountControl> </div></div>
    <br><br>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>AlaskaUi</span>



    </mat-toolbar>

      content1
      <section class="tab">
        <div id="tab">
          <ul>
            <div>
              <li><button class="tabbutton" id="Produkte"           *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Produkte')">           Produkte</button></li>
              <li><button class="tabbutton" id="Ordersatz"          *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Ordersatz')">          Ordersatz</button></li>
              <li><button class="tabbutton" id="Warenkorb"          *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Warenkorb')">          Warenkorb</button></li>
              <li><button class="tabbutton" id="Auftragssammler"    *ngIf="(hasRoles([1,2,3,4,5]) )"  (click)="setContent('Auftragssammler')">    Auftragssammler</button></li>
              <li><button class="tabbutton" id="Adminansicht"       *ngIf="(hasRoles([1,2  ])     )"  (click)="setContent('Adminansicht')">       Adminansicht</button></li>
            </div>

          </ul>
        </div>

       </section>


    <div> content2</div>
  </mat-sidenav-content>
</mat-sidenav-container>


import { Component, OnInit, ViewChild,ElementRef, AfterViewInit, Output, EventEmitter  } from '@angular/core';
import { iUsers, iCustomers, iShoppingcarts, iShoppingCartInfoBag, iParameterBag } from '../models/AlaskaModels';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../Services/data.service';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


import { TYPED_NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { MatPaginator } from '@angular/material/paginator';
import { IdbService } from '../Services/idb.service';



const ELEMENT_DATA: Array<iCustomers> = [];

@Component({
  selector: 'app-searchCustomer',
  templateUrl: './searchCustomer.component.html',
  styleUrls: ['./searchCustomer.component.scss']
})
export class SearchCustomerComponent implements OnInit {

  user: iUsers;
  customers: Array<iCustomers> = [];
  datasource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selectedCustomer: iCustomers ;

  searchTerm: string;

  isCollapsed = false;

  customerControl: FormControl = new FormControl();
  customerFilterControl: FormControl = new FormControl();
  deliveryDate: Date;
  @Output() outSelectedCustomerControl: EventEmitter<iCustomers> =   new EventEmitter<iCustomers>();


  constructor(private dataService: DataService,
              private idbService: IdbService,
              private element: ElementRef

              ) { }

  getSelectedCustomer():iCustomers{
    return this.selectedCustomer;
  }
  parameterBagSubject: iParameterBag = {};
  ngOnInit()
  {
      // parameterBagSubject: iParameterBag;
      this.dataService.parameterBagSubject$.subscribe(
        (bag) => {
            this.parameterBagSubject = bag;
            this.selectedCustomer = bag && bag.SelectedCustomer? bag.SelectedCustomer : this.selectedCustomer;
            this.deliveryDate = bag && bag.DeliveryDate? bag.DeliveryDate: this.deliveryDate;
      }, (err) => { console.log('error - parameterBagSubject-subscription: ', err);  });

    this.getCustomers();

    if(localStorage.getItem('selectedCustomer')){
      console.log("selectedCustomer: ", localStorage.getItem('selectedCustomer'));
      if(this.selectedCustomer != JSON.parse(localStorage.getItem('selectedCustomer'))){
        this.selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));

      }
    }

    this.dataService.msgSubject$.subscribe(
      msg => {
        if(msg.Name.toLowerCase() == "shoppingcarttransfered" ){
          if(msg.Data){
            let customer: iCustomers = msg.Data;

            this.onCustomerTransfer(customer);
            console.log("shoppingcarttransfered to customer: ",customer.Name1 );
          }

        }

      });


}

getCustomers(){
  this.customers = JSON.parse(localStorage.getItem('customers'))
  if(this.customers == undefined || this.customers.length == 0){
    this.customers = [];
     this.dataService.getData('custom',"getCustomers").subscribe( data => {
      for(const id in data)
        {
            if(data.hasOwnProperty(id))
            {
              this.customers.push(data[id]);
            }
        }
        localStorage.setItem('customers',JSON.stringify(this.customers))
      })

  }
  this.datasource.data = this.customers;
}

ngAfterViewInit()
{
  this.DropDownHide(true);
}

hasRoles(roleIds: number[]): boolean{
  return this.dataService.hasRoles(roleIds);
 }

DropDownToggle()
{

}
onFocusOut(){

}

DropDownHide(state: boolean)
{
  const controlState = state == true? 'none': 'block';
}

onMouseOver(){
this.customers = JSON.parse(localStorage.getItem('customers'))
}
onCustomerClick(event: any)
{
  let shoppingCartInfoBag: iShoppingCartInfoBag = { TotalPrice: 0.00,  TotalNatoPrice: 0.00,  TotalDiscount: 0.00,  TotalSurCharge: 0.00,  Lieferkosten: 0.00    }

    this.selectedCustomer = event.value;
    localStorage.setItem('selectedCustomer', JSON.stringify(this.selectedCustomer));
    this.outSelectedCustomerControl.emit(this.selectedCustomer);
    this.dataService.setSelectedCustomerSubject(this.selectedCustomer);
    this.dataService.setDeliveryDateSubject(new Date(Date.now()));
    // this.dataService.setShoppingCartInfoBagSubject(shoppingCartInfoBag);
    let activeShoppingCart = this.idbService.getActiveCustomerShoppingCart(this.selectedCustomer);
    this.dataService.getAndSetShoppingCartInfoBag(activeShoppingCart);

    // let iParameterBag: iParameterBag = {SelectedCustomer: this.selectedCustomer, DeliveryDate: new Date(Date.now()), SelectedShoppingCart: activeShoppingCart}
    // if(this.parameterBagSubject){ }
      // this.parameterBagSubject.SelectedCustomer = this.selectedCustomer;
      // this.parameterBagSubject.DeliveryDate= new Date(Date.now());
      // this.parameterBagSubject.SelectedShoppingCart= activeShoppingCart;
      // this.dataService.setParameterBagSubject(this.parameterBagSubject);


}

onCustomerTransfer(customer: iCustomers)
{
  let shoppingCartInfoBag: iShoppingCartInfoBag = { TotalPrice: 0.00,  TotalNatoPrice: 0.00,  TotalDiscount: 0.00,  TotalSurCharge: 0.00,  Lieferkosten: 0.00    }

    this.selectedCustomer = customer;
    localStorage.setItem('selectedCustomer', JSON.stringify(this.selectedCustomer));
    this.outSelectedCustomerControl.emit(this.selectedCustomer);
    this.dataService.setSelectedCustomerSubject(this.selectedCustomer);
    this.dataService.setDeliveryDateSubject(new Date(Date.now()));
    this.dataService.setShoppingCartInfoBagSubject(shoppingCartInfoBag);
    let activeShoppingCart = this.idbService.getActiveCustomerShoppingCart(this.selectedCustomer);
    this.dataService.getAndSetShoppingCartInfoBag(activeShoppingCart);

    this.parameterBagSubject.SelectedCustomer = this.selectedCustomer;
    this.parameterBagSubject.DeliveryDate= new Date(Date.now());
    this.parameterBagSubject.SelectedShoppingCart= activeShoppingCart;
    this.dataService.setParameterBagSubject(this.parameterBagSubject);

    this.customerFilterControl.reset();
    this.customerControl.reset();
    this.customerControl.setValue(this.selectedCustomer);

    // this.customerFilterControl.setValue(this.selectedCustomer.CustomerNumber.toString() +" - "+ this.selectedCustomer.Name1)

}

onSendMessage($event: any,action?:string){
  let filterValue = '';
  let keyCode = '';
if($event != ''){
  filterValue = $event.target.value;
  keyCode = $event.code;  }

// --------------
let y = this.searchTerm;

      let filterArray =  $event.target.value
        .trim()
        .toLowerCase()
        .split(/(\s+)/)
        .filter((e) => e.trim().length > 0);
      let filter0 =    filterArray && filterArray[0] != undefined ? filterArray[0] : '';
      let filter1 =    filterArray && filterArray[1] != undefined ? filterArray[1] : '';
      let filter2 =    filterArray && filterArray[2] != undefined ? filterArray[2] : '';

      this.datasource.filter = "filter";

      this.datasource.filterPredicate = (data: iCustomers, filter: string) => {
        if (filter0 != '' && filter1 == '' && filter2 == '') {
          return JSON.stringify( data.CustomersId +' '+ data.CustomerNumber +' '+ data.Name1 +' '+ data.Name2).toLowerCase().indexOf(filter0) != -1;
        }
        if (filter0 != '' && filter1 != '' && filter2 == '') {
          return (
            JSON.stringify( data.CustomersId +' '+ data.CustomerNumber +' '+ data.Name1 +' '+ data.Name2 ).toLowerCase().indexOf(filter0) != -1 &&
            JSON.stringify( data.CustomersId +' '+ data.CustomerNumber +' '+ data.Name1 +' '+ data.Name2 ).toLowerCase().indexOf(filter1) != -1
          );
        }
        if (filter0 != '' && filter1 != '' && filter2 != '') {
          return (
            JSON.stringify( data.CustomersId +' '+ data.CustomerNumber +' '+ data.Name1 +' '+ data.Name2 ).toLowerCase().indexOf(filter0) != -1 &&
            JSON.stringify( data.CustomersId +' '+ data.CustomerNumber +' '+ data.Name1 +' '+ data.Name2 ).toLowerCase().indexOf(filter1) != -1 &&
            JSON.stringify( data.CustomersId +' '+ data.CustomerNumber +' '+ data.Name1 +' '+ data.Name2 ).toLowerCase().indexOf(filter2) != -1
          );
        }

      };

 this.dataService.setMsgSourceSubject( 'applycustomerfilter',  keyCode != undefined? keyCode: '',  'menue',  'Target',  action!= undefined? action: '',  filterValue != undefined? filterValue: "",  undefined,  0 );


}

onDeliveryDateFromApp(newDeliveryDate: Date){
 if(newDeliveryDate){
    this.deliveryDate = newDeliveryDate;
 }
}


}



import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import { iCustomers, iOrders, iShoppingcartitems, iShoppingcarts,iOpenitems, iSupplierproductorders } from 'src/app/models/AlaskaModels';

import { jsPDF } from "jspdf";
import html2Canvas  from 'html2canvas';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataService } from '../../Services/data.service';
import { environment } from 'src/environments/environment';

const ELEMENT_DATA: Array<iSupplierproductorders> = [];
@Component({
  selector: 'app-suplierOrdersDialog',
  templateUrl: './suplierOrdersDialog.component.html',
  styleUrls: ['./suplierOrdersDialog.component.scss']
})
export class SuplierOrdersDialogComponent implements OnInit {

  suplierOrders: iSupplierproductorders[]=[];
  selectedCustomer: iCustomers;
  selectedCustomerName:string;
  selectedShoppingCartsName:string;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  productName: string;
  optionValue:number = 1;
  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 8;

  displayedColumns:string[] = ['DeliveryDate','SuppliersId','SupplierName','OrderedQuantity','DeliveredQuantity','UndeliveredQuantity', 'QuantityShortDesc', 'OrderDate'];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
              public openItemsDialogRef: MatDialogRef<SuplierOrdersDialogComponent>,
              private dataService:DataService,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit() {
    console.log('invoice dialog: ' , this.data);
    this.suplierOrders = this.data && this.data.suplierOrders? this.data.suplierOrders: null;
    this.selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    let firstOrder:iSupplierproductorders = this.suplierOrders? this.suplierOrders[0]: undefined;
    this.productName = firstOrder ? ' von '+ firstOrder.ProductNumber + '-' + firstOrder.DescRow1 : '';
    this.title = this.suplierOrders.length > 0 ? 'Unerledigte Lieferantenaufträge ' + this.productName : 'Kein Unerledigter Lieferantenauftrag';

    this.datasource.data = this.suplierOrders;
    this.datasource.data.sort;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Artikel pro Seite:';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';
  }

  onCancel(){
    this.openItemsDialogRef.close();

  }

}


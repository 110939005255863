import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit, OnDestroy} from '@angular/core';
import {iUsers,iRoles, iCustomers, iTitles, iGenders, iRegistrationstypes, iUserroles, iPositions,  iInputOptions} from '../../../models/AlaskaModels';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import { DataService } from '../../../Services/data.service';
import { DialogService } from '../../../Services/dialog.service';

import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ErrorStateMatcher} from '@angular/material/core';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subscription } from 'rxjs';

const ELEMENT_DATA: Array<iCustomers> = [];

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-adminCustomers',
  templateUrl: './adminCustomers.component.html',
  styleUrls: ['./adminCustomers.component.scss']
})
export class AdminCustomersComponent implements OnInit {

  loggedInUser: iUsers;
  selectedUser: iUsers;
  SelectedCustomer: iCustomers;
  customerSubscription: Subscription;
  item: string = 'Liste von Alaska kunden';

  editableCustomer: iCustomers;
  customers: iCustomers[];
  userRole: iUsers[];
  datasource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  mode: string = 'list';
  formSubmited: boolean = false;
  customersForm: FormGroup;
  tableColumns: string[] = ['CustomersId',  'CustomerNumber',  'Name1',  'Name2',  'PriceGroup',  'DiscountGroup',  'Description',  'ShortDesc',  'BlanketDiscount',  'NetTargetDay',  'CashDiscountTargetDay',  'CashDiscountRate',  'BackLogId',  'Street',  'Zip',  'City',  'Phone',  'Fax',  'SalesPerson',  'Contact',  'CreateDate',  'ModifyDate',  'actions'];
  displayedColumns: string[] = ['CustomersId',   'Name1',  'Name2',  'PriceGroup',  'DiscountGroup',  'ShortDesc'];
  pageSize: number = 50;
  pageView: string = '';

  inputOptions: iInputOptions;
  Customers: iCustomers[] = [];
  Positions: iPositions[] = [];

  matcher = new MyErrorStateMatcher();
  columnsForm: FormControl;

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private dialogService: DialogService,
              private snackbarService: MatSnackBar,
              private matPaginatorIntl: MatPaginatorIntl ) {}


ngOnDestroy()
{
  if(this.customerSubscription){this.customerSubscription.unsubscribe}
}

  ngOnInit() {


    this.columnsForm = new FormControl( this.displayedColumns);

    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));


    this.Positions = this.inputOptions['positions'];
    this.Customers = JSON.parse(localStorage.getItem('customers'));


    this.createCustomerForm(this.editableCustomer);

    this.SelectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;
      }, err => {
        console.log("error loggedin user: ", err);
      });

      this.dataService.msgSubject$.subscribe(
        (msg) => {
          if (msg.Name.toLowerCase() == 'applyproductfilter') {
            this.datasource.filterPredicate = (data: iCustomers, filter: string) =>   JSON.stringify(data).toLowerCase().indexOf(filter) != -1;
            this.datasource.filter = msg.Message.trim().toLowerCase();
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;
            setTimeout(() => {
              if (  msg.Type.toLowerCase() == 'enter' ||  msg.Type.toLowerCase() == 'numpadenter' ) {

              }
            }, 0);
          }

        },
        (err) => {
          console.log('error adding order: ', err);
        }
      );


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  onSelectColumns(event: {isUserInput: any;    source: { value: any; selected: any };  })
  {
   if (event.isUserInput) {
     if (event.source.selected === true) {
       this.displayedColumns.push(event.source.value);
     } else {
       this.displayedColumns = this.displayedColumns.filter(x => x !=  event.source.value)
     }
     if(!!this.displayedColumns.filter(x => x == 'actions')){
       this.displayedColumns = this.displayedColumns.filter(x => x !=  'actions');
       this.displayedColumns.push('actions');
     }
   }
 }

  populateData(){

    if(this.editableCustomer ){
      this.customerSubscription =   this.dataService.getData('custom','getcustomers',this.editableCustomer.CustomersId).subscribe(
      data => {
        this.customers = data;
        this.datasource.data = this.customers;
        this.datasource.sort = this.sort;
        this.datasource.paginator = this.paginator;
      }, err => {
        console.log('error retriving customers: ', err);
      });
    }else{
      this.customerSubscription =   this.dataService.getData('custom','getcustomers').subscribe(
      data => {
        this.customers = data;
        this.datasource.data = this.customers;
        this.datasource.sort = this.sort;
        this.datasource.paginator = this.paginator;
      }, err => {
        console.log('error retriving customers: ', err);
      });
    }

   }



  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';

   this.populateData();

  }


  createCustomerForm(customer?: iCustomers){
    this.customersForm = this.formBuilder.group(
      {
        CustomersId :        		[customer != undefined && customer.CustomersId != undefined? customer.CustomersId : 0, []],
        Name1:              		[customer != undefined && customer.Name1 != undefined? customer.Name1 : '', [Validators.required]],
        Name2:            			[customer != undefined && customer.Name2 != undefined? customer.Name2 : '', [Validators.required]],
        ShortDesc:              [customer != undefined && customer.ShortDesc != undefined? customer.ShortDesc : '', []],
        Description:            [customer != undefined && customer.Description != undefined? customer.Description : '', []],
        CustomerNumber:      		[customer != undefined && customer.CustomerNumber != undefined? customer.CustomerNumber : '', []],
        DiscountGroup :         [customer != undefined && customer.DiscountGroup != undefined? customer.DiscountGroup : 0, [Validators.required]],
        PriceGroup:             [customer != undefined && customer.PriceGroup != undefined? customer.PriceGroup : 0, [Validators.required]],
        BlanketDiscount:        [customer != undefined && customer.BlanketDiscount != undefined? customer.BlanketDiscount : 0, []],
        NetTargetDay :   			  [customer != undefined && customer.NetTargetDay != undefined? customer.NetTargetDay : 0, []],
        CashDiscountTargetDay:  [customer != undefined && customer.CashDiscountTargetDay != undefined? customer.CashDiscountTargetDay : 0, []],
		    CashDiscountRate:       [customer != undefined && customer.CashDiscountRate != undefined? customer.CashDiscountRate : 0, []]
      }
    );
  }


  onList(){
    this.mode = 'list';
    this.item = 'Beschränkte ansicht von Alaska kunden . . .';
    console.log("this mode: ", this.mode);
    this.populateData();
  }

  onDetail(){
    this.mode =  'detail';
    this.item = 'Erweiterte ansicht von Alaska kunden . . .';
  }
  onSetAddress(customer: iCustomers){
    this.mode = 'setAddress';
    this.editableCustomer = customer;
    localStorage.setItem("editableCustomer",JSON.stringify(this.editableCustomer));

    const curentCustomer:string   = customer != undefined && customer.Name1 != undefined?  customer.Name1 :'';
    this.item = 'Ein Addresse für kund: '+ curentCustomer +' zuordenen . . .  ';

    this.dataService.setMsgSourceSubject( 'editablecustomerid',  'Type',  'Source',  'Target',  'Action',  customer.CustomersId.toString(),  undefined,  0 );
  }
  onSetContact(customer: iCustomers){
    this.mode = 'setContact';
    this.editableCustomer = customer;
    localStorage.setItem("editableCustomer",JSON.stringify(this.editableCustomer));
    const curentCustomer:string   = customer != undefined && customer.Name1 != undefined?  customer.Name1 :'';
    this.item = 'Ein Kontakt für kund: '+ curentCustomer +' zuordenen . . . ';

    this.dataService.setMsgSourceSubject( 'editablecustomerid',  'Type',  'Source',  'Target',  'Action',  customer.CustomersId.toString(),  undefined,  0 );


  }
  onEdit(customer: iCustomers){
    this.mode = 'edit';
    this.editableCustomer = customer;
    const curentCustomer:string   = customer != undefined && customer.Name1 != undefined?  customer.Name1 :'';
    this.item = 'Alaska kund: '+ curentCustomer +' bearbeiten . . .';
    this.setEditableCustomer(customer);
    this.createCustomerForm(customer);
  }
  onCreate(){
    this.mode = 'create';
    this.item = 'Neu kund erstellen';
    this.removeEditableCustomer()
    this.createCustomerForm();
  }
  onDelete(customer: iCustomers){
    this.mode = 'delete';
    this.editableCustomer = customer;
    const curentCustomer:string   = customer != undefined && customer.Name1 != undefined?  customer.Name1 :'';
    this.item = 'Alaska kund: '+ curentCustomer +' löschen . . .';

    this.onSubmit(customer);
  }


  getFormData(): iCustomers{
    let _customer: iCustomers =
    {
          CustomersId :        		+this.customersForm.get('CustomersId').value,
          Name1:              		this.customersForm.get('Name1').value,
          Name2:            			this.customersForm.get('Name2').value,
          ShortDesc:              this.customersForm.get('ShortDesc').value,
          Description:            this.customersForm.get('Description').value,
          CustomerNumber:      		this.customersForm.get('CustomerNumber').value,
          DiscountGroup :         +this.customersForm.get('DiscountGroup').value,
          PriceGroup:             +this.customersForm.get('PriceGroup').value,
          BlanketDiscount:        +this.customersForm.get('BlanketDiscount').value,
          NetTargetDay :   			  +this.customersForm.get('NetTargetDay').value,
          CashDiscountTargetDay:  +this.customersForm.get('CashDiscountTargetDay').value,
          CashDiscountRate:       +this.customersForm.get('CashDiscountRate').value

    }
    return _customer;
  }

  onSubmit(customer?: iCustomers){
    this.formSubmited = true;
    const curentCustomer:string   = customer != undefined && customer.Name1 != undefined?  customer.Name1 :'';
    console.log("this form: ", this.customersForm);
    let _customer: iCustomers = this.getFormData();
    let emailExist:boolean = false;

    if(this.mode == 'create'){


      this.dataService.PostData('custom',_customer,'postcustomers').subscribe(
        result => {
            this.customers = result;


            this.dialogService.okDialog("Erfolg", "Kund erfolgreich angelegt." , "success",'40%');
        }, err => {

            console.log('error inserting customer', err);
            console.log('error inserting err.error.text', err.error.text);
            this.dialogService.okDialog("Error", "kund hinzufügen fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');

        }
      );
  }

    if(this.mode == 'edit'){
      this.dataService.PutData('custom',_customer, 'putcustomers').subscribe(
        result => {
            this.editableCustomer = result;

            this.snackbarService.open("Kunddaten erfolgreich geändert.", "ok");
        }, err => {
            console.log('error editing customer', err.error.text);
            this.dialogService.okDialog("Error", "Kunddaten bearbeitung fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');
        }
      );
    }

    if(this.mode == 'delete'){

      this.dialogService.conformDialog("Bestätigung", "Möchet Sie den Kund "+curentCustomer+" löschen? Hinweis: kund kann gelöscht werden, wenn die ID nicht bereits in einer anderen Tabelle referenziert ist.  ", "danger","abrechen","löschen").afterClosed().subscribe(
        data => {
               if(data !== 'ok'){  this.onList(); return;}

        this.dataService.RemoveData('custom',this.editableCustomer.CustomersId, 'deletecustomers',).subscribe(
        result => {
            this.customers = result;

            this.snackbarService.open("kund erfolgreich gelöscht. " , "ok");
        }, err => {
            console.log('error deleting customer', err);
            this.dialogService.okDialog("Error", "kund löschen fehlgeschlagen. Grund: "+ err.error.text , "danger",'40%');
        }
      );
        }
      );

    }
      this.onList();

  }


  setEditableCustomer(customer: iCustomers){
    if(customer != undefined){
      this.editableCustomer= customer;
      localStorage.setItem('editableCustomer', JSON.stringify(this.editableCustomer));


       this.dataService.setMsgSourceSubject( 'editablecustomerid',  'Type',  'Source',  'Target',  'Action',  customer.CustomersId.toString(),  undefined,  0 );

    }
  }

  removeEditableCustomer(){
      this.editableCustomer = undefined;
      localStorage.removeItem('editableCustomer');
  }
  onReset(){
    this.mode = 'list';
  }


  onPageSizeChange($event){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(+this.pageSize);
    this.pgnFirstPage();
  }

  getPageVisits():string{
    let visits = '';
    try{
      let nextVisit =  this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize < this.paginator.length? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize : this.paginator.length : '';
      visits = this.paginator && nextVisit != 0 && +this.paginator.length != 0?  ((this.paginator.pageIndex * this.pageSize)+1).toString() +'-'+ nextVisit.toString()+'/'+this.paginator.length : '';
    }
    catch{  } // do nothing
    return  visits
  }
    pgnFirstPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.firstPage();
    }
    pgnBackPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.previousPage();
    }
    pgnNextPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.nextPage();
    }
    pgnLastPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.lastPage();
    }


}





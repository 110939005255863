<p>
  customerContact works!
</p>


<form  [formGroup]="contactForm" >

  <div class="form-group col-12">
    <mat-select class="form-control" formControlName="TitlesId" placeholder="Anrede">
      <mat-option *ngFor="let title of titles" [value]="title.TitlesId">{{ title.ShortName }}</mat-option>
    </mat-select>
  </div>

  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="FirstName" placeholder="Vorname">
    <span class="error-block" *ngIf="!contactForm.get('FirstName').valid && (contactForm.get('FirstName').touched  || formSubmited)"></span>
  </div>

  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="LastName" placeholder="Nachname">
    <span class="error-block" *ngIf="!contactForm.get('LastName').valid && (contactForm.get('LastName').touched  || formSubmited)"></span>
  </div>

  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="BirthDay" placeholder="Geburtstag">
    <span class="error-block" *ngIf="!contactForm.get('BirthDay').valid && (contactForm.get('BirthDay').touched  || formSubmited)"></span>
  </div>

  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="Email" placeholder="Email">
    <span class="error-block" *ngIf="!contactForm.get('Email').valid && (contactForm.get('Email').touched  || formSubmited)"></span>
  </div>

  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="Phone" placeholder="Telefon">
    <span class="error-block" *ngIf="!contactForm.get('Phone').valid && (contactForm.get('Phone').touched  || formSubmited)"></span>
  </div>

  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="Mobile" placeholder="Mobil-telefon">
    <span class="error-block" *ngIf="!contactForm.get('Mobile').valid && (contactForm.get('Mobile').touched  || formSubmited)"></span>
  </div>

  <div class="form-group col-12">

    <input type="text" class="form-control" formControlName="Fax" placeholder="Fax">
    <span class="error-block" *ngIf="!contactForm.get('Fax').valid && (contactForm.get('Fax').touched  || formSubmited)"></span>
  </div>

  <div class="form-group col-12">
    <mat-select class="form-control" formControlName="Position" placeholder="Position">
      <mat-option *ngFor="let position of positions" [value]="position.PositionsId">{{ position.Name }}</mat-option>
    </mat-select>
  </div>

    <div class="form-group col-12">
      <button (click)="onSubmit()" type="button" class="btn btn-primary">Speichern</button>
      <button type="reset" class="btn btn-secondary ml-2">Zurücksetzen</button>
    </div>
</form>

import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { DataService} from '../../Services/data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { iCustomers, iDynamicorderrecords, iMaterialgroups, iOrders, iShoppingcartitems, iShoppingcarts, iKeyValue, iPrintOptions } from 'src/app/models/AlaskaModels';

import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

const SHOPPINGCARTITEMDATA: Array<iShoppingcartitems> = [];
@Component({
  selector: 'app-printShoppingcartDialog',
  templateUrl: './printShoppingcartDialog.component.html',
  styleUrls: ['./printShoppingcartDialog.component.scss']
})
export class PrintShoppingcartDialogComponent implements OnInit {

  shoppingcardOptions: iKeyValue[] = [ {Key: 'warenkorb mit bilder', Value: 'withpicture'},{Key: 'warenkorb ohne bilder', Value: 'withoutpicture'} ];
  printOption:iPrintOptions ;

  selectedShoppingcart: iShoppingcarts;
  selectedCustomer: iCustomers;
  selectedCustomerName:string = '';
  selectedShoppingCartsName:string = '';
  inputForm: FormGroup;
  pipe = new DatePipe('de-DE');

  shoppingcartControl:FormControl = new FormControl(null, Validators.required);

  datasource = new MatTableDataSource(SHOPPINGCARTITEMDATA);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  optionValue:string = '';

  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 50;
  itemLength: number = 100;
  ShoppingCartStateId = 0;
  options: string[] =  ['letzte Ordersätze', 'alle Ordersätze'];
  pageEvent :any;
  // dialogType: string ;


  // displayedColumns:string[] = [ 'Artikel','DescRow1', 'DescRow2','Unit', 'Stock', 'Price','QuantityInput', 'PriceInput', 'PosDiscount','TotalDiscount', 'Surcharge', 'TotalPrice','Picture'];
  displayedColumns:string[] = [ 'Artikel','DescRow1', 'DescRow2','QuantityInput', 'PriceInput',  'TotalPrice','Picture'];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dataService:DataService,
              public infoInputDialogRef: MatDialogRef<PrintShoppingcartDialogComponent>
  ) { }

  ngOnInit() {

    console.log('invoice dialog: ' , this.data);
    this.selectedCustomer = this.data  && this.data.customer? this.data.customer: null;

    this.selectedShoppingcart =  this.data  && this.data.shoppingcart? this.data.shoppingcart: null;


    this.dataService.selectedCustomerSubject$.subscribe(
      customer => {
        this.selectedCustomer = customer;
      }, err => {console.log('error - selectedCustomer-subscription: ', err);}
    );

    this.selectedCustomerName = this.selectedCustomer? this.selectedCustomer.Name1:this.selectedCustomerName;
    this.selectedShoppingCartsName = this.selectedShoppingcart? this.selectedShoppingcart.Name:this.selectedShoppingCartsName;
    this.date = this.dataService.getDate(new Date() , 'EEEE, dd.MM.yyyy');

    let dDate = this.selectedShoppingcart?this.selectedShoppingcart.DeliveryDate:null;

    this.deliveryDate =  dDate? this.dataService.getDate(dDate , 'EEEE, dd.MM.yyyy'): this.deliveryDate;

    this.datasource.data = this.selectedShoppingcart? this.selectedShoppingcart.Shoppingcartitems: [];
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

    this.optionValue =  this.getPrintOptionValue()
    setTimeout(() => (this.onSelectionChange(this.optionValue)), 0);

  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }

  getPrintOptionValue():string{
    this.printOption = JSON.parse(localStorage.getItem('printOptions')) ;
    if(this.printOption == undefined){
      this.printOption = {  OrdersatzPdf:'',  OrdersatzExcelAndCsv:'', WarenkorbkPdf:'', AuftrgssamlerPdf:''}
      localStorage.setItem('printOptions', JSON.stringify(this.printOption))
    }
    return this.printOption.WarenkorbkPdf;
  }

  onSelectionChange(optionValue:string){
    this.optionValue = optionValue == undefined? '': optionValue;
    let ofDate:string =this.selectedShoppingcart && this.selectedShoppingcart.CreateDate ? ' vom '+ this.pipe.transform(this.selectedShoppingcart.CreateDate,'dd.MM.yyyy'): '';

    if(this.optionValue == 'withpicture' && this.selectedCustomer != undefined ) {

      this.title = this.optionValue && this.optionValue == 'withpicture'? 'Warenkorb für '+ this.selectedCustomer.Name1 + ofDate:this.title;

    }
    if(this.optionValue == 'withoutpicture'  && this.selectedCustomer != undefined) {

      this.title = this.optionValue && this.optionValue == 'withoutpicture'? 'Warenkorb für '+ this.selectedCustomer.Name1 + ofDate :this.title;

    }
    this.printOption.WarenkorbkPdf = this.optionValue;
    localStorage.setItem('printOptions', JSON.stringify(this.printOption))
  }

  getNetPrice() {
    return this.datasource.data.map(t => t.Price * t.Quantity).reduce((acc, value) => acc + value, 0);
  }

  getTotalPrice() {
    return this.datasource.data.map(t => t.Price  * t.Quantity * 1.07).reduce((acc, value) => acc + value, 0);
  }

  onCancel(){
    this.infoInputDialogRef.close();
  }

  totalRowDiscount(shoppingcartitem: iShoppingcartitems): number{
    return shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.PosDiscount / 100;
  }

  totalRowPrice(shoppingcartitem: iShoppingcartitems): number{
    return (shoppingcartitem.Price * shoppingcartitem.Quantity) ;
  }

  totalPriceSum(): number{
    let totalPrice: number = 0;
    let totalNatoPrice: number = 0;
    let totalDiscount: number = 0;
    let totalSurCharge: number = 0;
    let lieferkosten: number = 0;

    if(this.datasource && this.datasource.data){
      this.datasource.data.forEach(row => {
          totalPrice = totalPrice + this.totalRowPrice(row);
      });
    }
    totalNatoPrice = (totalPrice + totalSurCharge - totalDiscount);
    return +totalNatoPrice.toFixed(2);
  }

  getDynamicOrdersHead(dynamicorderrecords: iDynamicorderrecords[]):iDynamicorderrecords[]{
        let  dyn: iDynamicorderrecords[] = [];
          if(dynamicorderrecords){
            dynamicorderrecords.forEach(element => {
              let existHeadDynamicorderrecords = dyn.find(x => x.MaterialGroupId == element.MaterialGroupId);
                      if(existHeadDynamicorderrecords == undefined){
                            dyn.push(element)
                      }

            });
          }
             return dyn;
  }

  getTop3DynRecords(dynamicOrders: iDynamicorderrecords[], dynamicOrder: iDynamicorderrecords):iDynamicorderrecords[]{
    let indexes: number[] = [1,2,3];
    return dynamicOrders.filter(x => x.MaterialGroupId == dynamicOrder.MaterialGroupId && indexes.includes(dynamicOrder.RowIndex));

  }

  onPaginateChange(data) {
    this.datasource.filteredData = this.datasource.data.slice(data.pageIndex * data.pageSize, data.pageIndex + 1 * data.pageSize);
  }

hasRoles(roleIds: number[]): boolean{
  return this.dataService.hasRoles(roleIds);
}
getIcon(quantity: number, type: string): string {
  return this.dataService.getIcon(quantity,type);
}
bigOrSmall(quantity:number):string{
  return this.dataService.bigOrSmall(quantity);
}
getBg(quantity: number, type: string): string {
  return this.dataService.getBg(quantity,type);
}

totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
  let totalRowSurcharge = 0;
  if( shoppingcartitem.Quantity > 0 && shoppingcartitem.Quantity < 1 && shoppingcartitem.Product.PriceType != 'I'  && shoppingcartitem.Product.PartialPackageChargeable == -1){
    totalRowSurcharge =  (shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100);

  }
  return +totalRowSurcharge.toFixed(2);
}

}

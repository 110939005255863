
import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import { iOrders, iShoppingcartitems } from 'src/app/models/AlaskaModels';
import { jsPDF } from "jspdf";
import html2Canvas  from 'html2canvas';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';

const ELEMENT_DATA: Array<iShoppingcartitems> = [];
@Component({
  selector: 'app-pdfDialog',
  templateUrl: './pdfDialog.component.html',
  styleUrls: ['./pdfDialog.component.css']
})
export class PdfDialogComponent implements OnInit {

  order: iOrders;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  footer: string[] = [ 'Price', 'Summe'];
  pipe = new DatePipe('de-DE');

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public infoInputDialogRef: MatDialogRef<PdfDialogComponent>,
              private formBuilder: FormBuilder
  ) { }

  ngOnInit() {

    this.order = this.data.order;

    this.datasource.data = this.order.ShoppingCarts.Shoppingcartitems;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
  }
ngAfterViewInit(){

}

getNetPrice() {
  return this.datasource.data.map(t => t.Price * t.Quantity).reduce((acc, value) => acc + value, 0);
}

getTotalPrice() {
  return this.datasource.data.map(t => t.Price  * t.Quantity * 1.07).reduce((acc, value) => acc + value, 0);
}

onCancel(){
    this.infoInputDialogRef.close();
  }

onCreatePdf(){
  html2Canvas(document.getElementById("pdfContent")).then(function (canvas) {
      let imgWidth=208;
  let pageHeight = 295;
  let imgHeight = canvas.height*imgWidth/canvas.width;
  let heightLeft = imgHeight;

  var imgData = canvas.toDataURL("image/png", 1.0);
      let pdf = new jsPDF('p','mm', 'a4');
  let position = 0;

  // pdf.setFontSize(20);//optional
  // pdf.setTextColor(40);//optional
  // pdf.setFont('normal');//optional
  // pdf.text("Report", 50, 22);// set your margins
  // pdf.text('www.alaskaonline.de   alaska-tkk@t-online.de',10,10);
  pdf.addImage(imgData,'PNG',0,position,imgWidth,imgHeight);
   pdf.output('dataurlnewwindow');

    pdf.save('new-pdf');

  });

  this.infoInputDialogRef.close();

}


}



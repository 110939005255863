import { Component, OnInit } from '@angular/core';
import { DataService } from '../../Services/data.service';
import { ActivatedRoute } from '@angular/router';
import {MatTabsModule, MatTab, MatTabGroup} from '@angular/material/tabs';
import { iUsers, iUserroles, iInputOptions } from 'src/app/models/AlaskaModels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  loggedInUser: iUsers;
  inputOptions: iInputOptions;

  constructor(private dataService: DataService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.loggedInUser  = this.dataService.getLoggedinUser();
    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;
      }, err => {
        console.log("error loggedin user: ", err);
      });
  }

  hasRoles(roleIds: number[]): boolean{
    return this.dataService.hasRoles(roleIds);
   }


}

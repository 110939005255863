
<button id="productCategoryControl" appearance="outline" mat-stroked-button class=" but95 " mat-button [matMenuTriggerFor]="panel.menu">Alle Warengruppen . . .</button>
<app-menu-item #panel [items]="items"></app-menu-item>



<!-- ################################################################################################# -->

<!-- <div>

<button appearance="outline" mat-stroked-button class=" but95 "  mat-button [matMenuTriggerFor]="parent_"> Alle Warengruppen . . .</button>
<mat-menu #parent_="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged()"><b>Alle Warengruppen . . .</b></button>

<button mat-menu-item (click)="onProductCategoryChanged(236)">Neuheiten 2021</button>
<button mat-menu-item [matMenuTriggerFor]="m1">Geflügel</button>
<button mat-menu-item [matMenuTriggerFor]="m2">Wild</button>
<button mat-menu-item [matMenuTriggerFor]="m3">Kaninchen</button>
<button mat-menu-item [matMenuTriggerFor]="m4">Rind</button>
<button mat-menu-item (click)="onProductCategoryChanged(44)">Kalb</button>
<button mat-menu-item (click)="onProductCategoryChanged(43)">Lamm+Schaf+Zicklein</button>
<button mat-menu-item [matMenuTriggerFor]="m39">Schwein</button>
<button mat-menu-item [matMenuTriggerFor]="m107">Wurstwaren</button>
<button mat-menu-item [matMenuTriggerFor]="m45">Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(49)">Pizza & Pasta</button>
<button mat-menu-item [matMenuTriggerFor]="m50">Gemüse</button>
<button mat-menu-item (click)="onProductCategoryChanged(54)">Obst</button>
<button mat-menu-item [matMenuTriggerFor]="m55">Kartoffelprodukte</button>
<button mat-menu-item [matMenuTriggerFor]="m59">Fisch</button>
<button mat-menu-item [matMenuTriggerFor]="m148">Backwaren & Spezialitäten</button>
<button mat-menu-item [matMenuTriggerFor]="m70">Kuchen & Torten</button>
<button mat-menu-item [matMenuTriggerFor]="m78">Eis</button>
<button mat-menu-item [matMenuTriggerFor]="m93">Haushaltpackungen</button>
<button mat-menu-item [matMenuTriggerFor]="m99">Trockenwaren & Nährmittel</button>
<button mat-menu-item (click)="onProductCategoryChanged(125)">Garnierung</button>
<button mat-menu-item (click)="onProductCategoryChanged(126)">Verschiedenes</button>
<button mat-menu-item (click)="onProductCategoryChanged(165)">Service - Artikel</button>
<button mat-menu-item [matMenuTriggerFor]="m202">Grillen & Party</button>
<button mat-menu-item (click)="onProductCategoryChanged(128)">Freibadgastronomie</button>
<button mat-menu-item (click)="onProductCategoryChanged(166)">Fingerfood</button>
<button mat-menu-item [matMenuTriggerFor]="m129">kaltes Buffet</button>
<button mat-menu-item (click)="onProductCategoryChanged(206)">Vegetarisches</button>
<button mat-menu-item [matMenuTriggerFor]="m150">Neuheiten</button>
</mat-menu>
<mat-menu #m1="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m6">Pute</button>
<button mat-menu-item [matMenuTriggerFor]="m7">Hähnchen</button>
<button mat-menu-item [matMenuTriggerFor]="m8">Flugente</button>
<button mat-menu-item (click)="onProductCategoryChanged(9)">Hausente</button>
<button mat-menu-item [matMenuTriggerFor]="m10">Gänse</button>
</mat-menu>
<mat-menu #m2="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(26)">Hirsch</button>
<button mat-menu-item (click)="onProductCategoryChanged(27)">Wildschwein</button>
<button mat-menu-item (click)="onProductCategoryChanged(28)">Reh</button>
<button mat-menu-item (click)="onProductCategoryChanged(29)">Wildhase</button>
<button mat-menu-item (click)="onProductCategoryChanged(30)">Wildgeflügel</button>
<button mat-menu-item (click)="onProductCategoryChanged(31)">Exoten</button>
<button mat-menu-item (click)="onProductCategoryChanged(32)">Elch</button>
</mat-menu>
<mat-menu #m3="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(33)">Ganze Kaninchen</button>
<button mat-menu-item (click)="onProductCategoryChanged(35)">Kaninchen Teile+Spez.</button>
</mat-menu>
<mat-menu #m4="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(37)">Rind portioniert, küchenfertig & gegart</button>
<button mat-menu-item (click)="onProductCategoryChanged(38)">Rindfl.-Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(181)">Rindfleisch frisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(182)">Rindfleisch TK</button>
</mat-menu>
<mat-menu #m39="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(41)">Schweine Schnitzel + Steaks</button>
<button mat-menu-item (click)="onProductCategoryChanged(42)">Schweinefl.- Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(177)">Schweinefleisch frisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(178)">Schwein Frikadellen + Haxen</button>
<button mat-menu-item (click)="onProductCategoryChanged(179)">Schweinefleisch Spieße + Rouladen</button>
<button mat-menu-item (click)="onProductCategoryChanged(180)">Schwein Gulasch + Braten</button>
<button mat-menu-item (click)="onProductCategoryChanged(190)">Schweinefleisch TK</button>
</mat-menu>
<mat-menu #m107="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(183)">Wurst und Wurstprodukte</button>
<button mat-menu-item (click)="onProductCategoryChanged(184)">Schinken + Speck</button>
<button mat-menu-item (click)="onProductCategoryChanged(185)">Wurstspezialitäten</button>
</mat-menu>
<mat-menu #m45="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(46)">Fleisch - Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(47)">Gemüse - Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(48)">Spezialitäten - sonstige</button>
<button mat-menu-item (click)="onProductCategoryChanged(108)">Butter- Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(186)">Pfannen+Suppen Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(187)">Käse Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(188)">Mehl-Kartoffel-Quark-Teigspeisen</button>
<button mat-menu-item (click)="onProductCategoryChanged(189)">Knödel, Eierkuchen</button>
</mat-menu>
<mat-menu #m50="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(51)">Gemüse allgemein</button>
<button mat-menu-item (click)="onProductCategoryChanged(52)">Gemüsemischungen</button>
<button mat-menu-item (click)="onProductCategoryChanged(53)">Pilze & Pilzmischungen</button>
</mat-menu>
<mat-menu #m55="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(56)">Pommes Frites</button>
<button mat-menu-item (click)="onProductCategoryChanged(57)">Kartoffel - Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(58)">Klöße & Kloßteig</button>
<button mat-menu-item (click)="onProductCategoryChanged(111)">Frischeprogramm Kartoffeln</button>
<button mat-menu-item (click)="onProductCategoryChanged(112)">Kartoffelsalat + Pürree</button>
<button mat-menu-item (click)="onProductCategoryChanged(153)">Rösti, Taschen, Reibekuchen</button>
<button mat-menu-item (click)="onProductCategoryChanged(192)">Wedges (Westernkartoffeln)</button>
</mat-menu>
<mat-menu #m59="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(60)">Fischtafeln paniert</button>
<button mat-menu-item (click)="onProductCategoryChanged(61)">Fischtafeln natur</button>
<button mat-menu-item (click)="onProductCategoryChanged(62)">Fisch natur interlive</button>
<button mat-menu-item (click)="onProductCategoryChanged(63)">Fisch - Einzelfilet paniert</button>
<button mat-menu-item (click)="onProductCategoryChanged(64)">Fisch - Steaks & Teller</button>
<button mat-menu-item (click)="onProductCategoryChanged(65)">Fisch - Convenience</button>
<button mat-menu-item (click)="onProductCategoryChanged(66)">Fisch - Einzelfilet natur  A - L</button>
<button mat-menu-item (click)="onProductCategoryChanged(67)">Meeresspezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(68)">Fisch- Räucherware</button>
<button mat-menu-item (click)="onProductCategoryChanged(69)">Fisch Feinkost & Marinaden</button>
<button mat-menu-item (click)="onProductCategoryChanged(193)">Fisch - Einzelfilet natur  M - Z</button>
<button mat-menu-item (click)="onProductCategoryChanged(194)">Fisch - ganze Fische & Räucher-Rohware</button>
<button mat-menu-item (click)="onProductCategoryChanged(195)">Shrimps + Garnelen</button>
</mat-menu>
<mat-menu #m148="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(168)">Donuts</button>
<button mat-menu-item (click)="onProductCategoryChanged(169)">Backdessert süß</button>
<button mat-menu-item (click)="onProductCategoryChanged(170)">Back Spezialitäten herzhaft</button>
<button mat-menu-item (click)="onProductCategoryChanged(171)">Brot Spezialitäten & Blätterteig</button>
<button mat-menu-item [matMenuTriggerFor]="m172">Brötchen & Baguette</button>
<button mat-menu-item (click)="onProductCategoryChanged(173)">Baguette Stangen</button>
</mat-menu>
<mat-menu #m70="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(71)">Vandemoortele Kuchen + Sahnerollen</button>
<button mat-menu-item (click)="onProductCategoryChanged(72)">Kuchen rund Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(74)">Torten Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(75)">Balance Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(76)">Spezialitäten Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(167)">Schnitten und Blechkuchen Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(213)">THOKS - Thüringer Obstkuchen</button>
<button mat-menu-item (click)="onProductCategoryChanged(223)">Rustikale Landfrauenkuchen Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(224)">Fruchtige Tartes</button>
<button mat-menu-item (click)="onProductCategoryChanged(230)">Gemischte Torten</button>
</mat-menu>
<mat-menu #m78="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(83)">Eis Echte Frucht</button>
<button mat-menu-item (click)="onProductCategoryChanged(84)">Eis Copa Steingut</button>
<button mat-menu-item (click)="onProductCategoryChanged(88)">Eisbuffet + Sonderanfertigung</button>
<button mat-menu-item (click)="onProductCategoryChanged(89)">BB Deko & Garnier -  Zubehör</button>
<button mat-menu-item (click)="onProductCategoryChanged(90)">Schlagsahne</button>
<button mat-menu-item (click)="onProductCategoryChanged(91)">BB Waffeln</button>
<button mat-menu-item (click)="onProductCategoryChanged(92)">Dessert - Soßen  z.B. für Eis, Kuchen . . .</button>
<button mat-menu-item (click)="onProductCategoryChanged(124)">Eiswürfel für Cocktails</button>
<button mat-menu-item (click)="onProductCategoryChanged(160)">Eis Spezialitäten Van Gils + Ysco</button>
<button mat-menu-item (click)="onProductCategoryChanged(196)">Eis - Karten</button>
<button mat-menu-item (click)="onProductCategoryChanged(197)">Eis Gläser</button>
<button mat-menu-item (click)="onProductCategoryChanged(201)">Eis-&Mousse-Dessert Ostern+Weihnachten+...</button>
<button mat-menu-item (click)="onProductCategoryChanged(205)">Eis Käfer 5 Liter Wannen </button>
<button mat-menu-item (click)="onProductCategoryChanged(221)">Eis Gelato Antonio 5 Liter Wanne</button>
<button mat-menu-item (click)="onProductCategoryChanged(222)">Eis Spiel-Figuren</button>
<button mat-menu-item (click)="onProductCategoryChanged(225)">Eis 2x2,4 Liter Schalen - Käfer</button>
<button mat-menu-item (click)="onProductCategoryChanged(227)">Eis Impuls - Süd Eis/Van Gils/Mio/Nostalgie</button>
<button mat-menu-item (click)="onProductCategoryChanged(228)">Eis Mars </button>
<button mat-menu-item (click)="onProductCategoryChanged(229)">Eis Desserts / Riegel / Torten</button>
<button mat-menu-item (click)="onProductCategoryChanged(233)">Eis Sommerland 5 Liter Dosen</button>
<button mat-menu-item (click)="onProductCategoryChanged(234)">Eis G+G Gelato 5,7 Liter Wannen</button>
</mat-menu>
<mat-menu #m93="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(94)">HP Geflügel + Sonstige</button>
<button mat-menu-item (click)="onProductCategoryChanged(95)">HP Gemüse</button>
<button mat-menu-item (click)="onProductCategoryChanged(96)">HP Früchte</button>
<button mat-menu-item (click)="onProductCategoryChanged(97)">HP Kartoffelprodukte</button>
<button mat-menu-item (click)="onProductCategoryChanged(98)">HP Fisch</button>
</mat-menu>
<mat-menu #m99="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m100">Getränke</button>
<button mat-menu-item [matMenuTriggerFor]="m101">Trockenprodukte+Nährmittel</button>
<button mat-menu-item [matMenuTriggerFor]="m102">Gewürze + Essig</button>
<button mat-menu-item (click)="onProductCategoryChanged(103)">Portionsware</button>
<button mat-menu-item (click)="onProductCategoryChanged(104)">Gemüse & Sauerkonserven</button>
<button mat-menu-item (click)="onProductCategoryChanged(105)">Frucht - Konserven</button>
<button mat-menu-item (click)="onProductCategoryChanged(106)">Konfitüre & Marmelade</button>
<button mat-menu-item (click)="onProductCategoryChanged(109)">frische Eier</button>
<button mat-menu-item (click)="onProductCategoryChanged(110)">Ketchu/Mayo/Senf/Dressing</button>
<button mat-menu-item (click)="onProductCategoryChanged(113)">Koch - Brat & Backzutaten</button>
<button mat-menu-item [matMenuTriggerFor]="m114">Oscar Fond/Food-Service/Knorr/Pfanni/Maggi</button>
<button mat-menu-item (click)="onProductCategoryChanged(159)">Molkereiprodukte</button>
</mat-menu>
<mat-menu #m202="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(214)">Frische Suppen und Eintöpfe</button>
</mat-menu>
<mat-menu #m129="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(130)">Wurst+Schinken</button>
<button mat-menu-item (click)="onProductCategoryChanged(131)">Brot+Gebäck</button>
<button mat-menu-item [matMenuTriggerFor]="m132">Fleisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(133)">Salate+Gewürzgurken</button>
<button mat-menu-item (click)="onProductCategoryChanged(134)">Butterfiguren</button>
<button mat-menu-item [matMenuTriggerFor]="m138">Fisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(139)">Gemüse</button>
<button mat-menu-item (click)="onProductCategoryChanged(140)">Eier+Käse</button>
<button mat-menu-item (click)="onProductCategoryChanged(141)">Früchte</button>
<button mat-menu-item (click)="onProductCategoryChanged(142)">Portionsware</button>
<button mat-menu-item (click)="onProductCategoryChanged(146)">Eis-Cocktailwürfel</button>
<button mat-menu-item (click)="onProductCategoryChanged(147)">Garnieren+Verfeinern mit zB Mayo, Dressing...</button>
</mat-menu>
<mat-menu #m150="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(152)">Änderungen</button>
<button mat-menu-item (click)="onProductCategoryChanged(157)">Rest-& Sonderposten</button>
<button mat-menu-item (click)="onProductCategoryChanged(176)">Neuheiten Aktuell</button>
<button mat-menu-item (click)="onProductCategoryChanged(191)">Neuheiten letzten 12 Monate</button>
<button mat-menu-item (click)="onProductCategoryChanged(211)">Auslistungen</button>
</mat-menu>
<mat-menu #m6="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m11">Putenteile + Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(12)">Puten ganze</button>
</mat-menu>
<mat-menu #m100="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(116)">Milch</button>
<button mat-menu-item (click)="onProductCategoryChanged(231)">Wein/Glühwein/Punsch</button>
<button mat-menu-item (click)="onProductCategoryChanged(232)">Kaffee/Tee/Kakao/Saft</button>
</mat-menu>
<mat-menu #m172="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(174)">Brötchen Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(175)">Brötchen klassisch</button>
</mat-menu>
<mat-menu #m7="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m16">Hähnchenteile + Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(17)">Hähnchen ganze</button>
</mat-menu>
<mat-menu #m101="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(198)">Teigwaren</button>
<button mat-menu-item (click)="onProductCategoryChanged(199)">Öle, Fette, Butter</button>
<button mat-menu-item (click)="onProductCategoryChanged(200)">Trockenprodukte</button>
</mat-menu>
<mat-menu #m132="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(135)">Rind</button>
<button mat-menu-item (click)="onProductCategoryChanged(136)">Schwein</button>
<button mat-menu-item (click)="onProductCategoryChanged(137)">Geflügel</button>
</mat-menu>
<mat-menu #m8="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(22)">Flugentenbrust</button>
<button mat-menu-item (click)="onProductCategoryChanged(23)">Flugenten ganze</button>
<button mat-menu-item (click)="onProductCategoryChanged(163)">Flugentenkeulen</button>
</mat-menu>
<mat-menu #m102="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(115)">Gewürze</button>
<button mat-menu-item (click)="onProductCategoryChanged(158)">Essig + Zitrone</button>
</mat-menu>
<mat-menu #m138="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(143)">Räucherfisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(144)">Meeresfrüchte</button>
<button mat-menu-item (click)="onProductCategoryChanged(145)">Fischmarinaden</button>
</mat-menu>
<mat-menu #m10="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(24)">Gänse ganz</button>
<button mat-menu-item (click)="onProductCategoryChanged(25)">Gänseteile</button>
</mat-menu>
<mat-menu #m114="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(118)">Hollondaise + Bernaise</button>
<button mat-menu-item (click)="onProductCategoryChanged(119)">Suppen</button>
<button mat-menu-item (click)="onProductCategoryChanged(120)">Soßen + Brühe</button>
<button mat-menu-item (click)="onProductCategoryChanged(121)">Beilagen & Fixprodukte</button>
<button mat-menu-item (click)="onProductCategoryChanged(122)">Soßenbinder + Mehlschwitze</button>
<button mat-menu-item (click)="onProductCategoryChanged(123)">Dessert</button>
</mat-menu>
<mat-menu #m11="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(13)">Schnitzel + Steaks</button>
<button mat-menu-item (click)="onProductCategoryChanged(14)">Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(15)">Teile</button>
</mat-menu>
<mat-menu #m16="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(18)">Schnitzel + Steaks</button>
<button mat-menu-item (click)="onProductCategoryChanged(19)">Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(20)">Keulen</button>
<button mat-menu-item (click)="onProductCategoryChanged(21)">Fleisch</button>
</mat-menu>

</div> -->

<!-- ################################################################################################# -->

<!-- <div id="productCategoryDropDown">

<button appearance="outline" mat-stroked-button class=" but95 "  mat-button [matMenuTriggerFor]="parent_"> Alle Warengruppen . . .</button>

<mat-menu #parent_="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged()"><b>Alle Warengruppen . . .</b></button>
<button mat-menu-item (click)="onProductCategoryChanged(236)">Neuheiten 2021</button>
<button mat-menu-item [matMenuTriggerFor]="m6">Geflügel</button>
<button mat-menu-item [matMenuTriggerFor]="m26">Wild</button>
<button mat-menu-item [matMenuTriggerFor]="m33">Kaninchen</button>
<button mat-menu-item [matMenuTriggerFor]="m181">Rind</button>
<button mat-menu-item (click)="onProductCategoryChanged(44)">Kalb</button>
<button mat-menu-item (click)="onProductCategoryChanged(43)">Lamm+Schaf+Zicklein</button>
<button mat-menu-item [matMenuTriggerFor]="m177">Schwein</button>
<button mat-menu-item [matMenuTriggerFor]="m183">Wurstwaren</button>
<button mat-menu-item [matMenuTriggerFor]="m46">Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(49)">Pizza & Pasta</button>
<button mat-menu-item [matMenuTriggerFor]="m51">Gemüse</button>
<button mat-menu-item (click)="onProductCategoryChanged(54)">Obst</button>
<button mat-menu-item [matMenuTriggerFor]="m56">Kartoffelprodukte</button>
<button mat-menu-item [matMenuTriggerFor]="m60">Fisch</button>
<button mat-menu-item [matMenuTriggerFor]="m172">Backwaren & Spezialitäten</button>
<button mat-menu-item [matMenuTriggerFor]="m72">Kuchen & Torten</button>
<button mat-menu-item [matMenuTriggerFor]="m227">Eis</button>
<button mat-menu-item [matMenuTriggerFor]="m94">Haushaltpackungen</button>
<button mat-menu-item [matMenuTriggerFor]="m100">Trockenwaren & Nährmittel</button>
<button mat-menu-item (click)="onProductCategoryChanged(125)">Garnierung</button>
<button mat-menu-item (click)="onProductCategoryChanged(126)">Verschiedenes</button>
<button mat-menu-item (click)="onProductCategoryChanged(165)">Service - Artikel</button>
<button mat-menu-item [matMenuTriggerFor]="m214">Grillen & Party</button>
<button mat-menu-item (click)="onProductCategoryChanged(128)">Freibadgastronomie</button>
<button mat-menu-item (click)="onProductCategoryChanged(166)">Fingerfood</button>
<button mat-menu-item [matMenuTriggerFor]="m130">kaltes Buffet</button>
<button mat-menu-item (click)="onProductCategoryChanged(206)">Vegetarisches</button>
<button mat-menu-item [matMenuTriggerFor]="m176">Neuheiten</button>
</mat-menu>
<mat-menu #m6="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m11">Pute</button>
<button mat-menu-item [matMenuTriggerFor]="m16">Hähnchen</button>
<button mat-menu-item [matMenuTriggerFor]="m22">Flugente</button>
<button mat-menu-item (click)="onProductCategoryChanged(9)">Hausente</button>
<button mat-menu-item [matMenuTriggerFor]="m24">Gänse</button>
</mat-menu>
<mat-menu #m26="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(26)">Hirsch</button>
<button mat-menu-item (click)="onProductCategoryChanged(27)">Wildschwein</button>
<button mat-menu-item (click)="onProductCategoryChanged(28)">Reh</button>
<button mat-menu-item (click)="onProductCategoryChanged(29)">Wildhase</button>
<button mat-menu-item (click)="onProductCategoryChanged(30)">Wildgeflügel</button>
<button mat-menu-item (click)="onProductCategoryChanged(31)">Exoten</button>
<button mat-menu-item (click)="onProductCategoryChanged(32)">Elch</button>
</mat-menu>
<mat-menu #m33="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(33)">Ganze Kaninchen</button>
<button mat-menu-item (click)="onProductCategoryChanged(35)">Kaninchen Teile+Spez.</button>
</mat-menu>
<mat-menu #m181="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(181)">Rindfleisch frisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(182)">Rindfleisch TK</button>
<button mat-menu-item (click)="onProductCategoryChanged(37)">Rind portioniert, küchenfertig & gegart</button>
<button mat-menu-item (click)="onProductCategoryChanged(38)">Rindfl.-Spezialitäten</button>
</mat-menu>
<mat-menu #m177="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(177)">Schweinefleisch frisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(190)">Schweinefleisch TK</button>
<button mat-menu-item (click)="onProductCategoryChanged(41)">Schweine Schnitzel + Steaks</button>
<button mat-menu-item (click)="onProductCategoryChanged(179)">Schweinefleisch Spieße + Rouladen</button>
<button mat-menu-item (click)="onProductCategoryChanged(178)">Schwein Frikadellen + Haxen</button>
<button mat-menu-item (click)="onProductCategoryChanged(180)">Schwein Gulasch + Braten</button>
<button mat-menu-item (click)="onProductCategoryChanged(42)">Schweinefl.- Spezialitäten</button>
</mat-menu>
<mat-menu #m183="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(183)">Wurst und Wurstprodukte</button>
<button mat-menu-item (click)="onProductCategoryChanged(184)">Schinken + Speck</button>
<button mat-menu-item (click)="onProductCategoryChanged(185)">Wurstspezialitäten</button>
</mat-menu>
<mat-menu #m46="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(46)">Fleisch - Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(47)">Gemüse - Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(108)">Butter- Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(187)">Käse Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(188)">Mehl-Kartoffel-Quark-Teigspeisen</button>
<button mat-menu-item (click)="onProductCategoryChanged(189)">Knödel, Eierkuchen</button>
<button mat-menu-item (click)="onProductCategoryChanged(186)">Pfannen+Suppen Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(48)">Spezialitäten - sonstige</button>
</mat-menu>
<mat-menu #m51="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(51)">Gemüse allgemein</button>
<button mat-menu-item (click)="onProductCategoryChanged(52)">Gemüsemischungen</button>
<button mat-menu-item (click)="onProductCategoryChanged(53)">Pilze & Pilzmischungen</button>
</mat-menu>
<mat-menu #m56="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(56)">Pommes Frites</button>
<button mat-menu-item (click)="onProductCategoryChanged(192)">Wedges (Westernkartoffeln)</button>
<button mat-menu-item (click)="onProductCategoryChanged(57)">Kartoffel - Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(153)">Rösti, Taschen, Reibekuchen</button>
<button mat-menu-item (click)="onProductCategoryChanged(58)">Klöße & Kloßteig</button>
<button mat-menu-item (click)="onProductCategoryChanged(111)">Frischeprogramm Kartoffeln</button>
<button mat-menu-item (click)="onProductCategoryChanged(112)">Kartoffelsalat + Pürree</button>
</mat-menu>
<mat-menu #m60="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(60)">Fischtafeln paniert</button>
<button mat-menu-item (click)="onProductCategoryChanged(61)">Fischtafeln natur</button>
<button mat-menu-item (click)="onProductCategoryChanged(62)">Fisch natur interlive</button>
<button mat-menu-item (click)="onProductCategoryChanged(63)">Fisch - Einzelfilet paniert</button>
<button mat-menu-item (click)="onProductCategoryChanged(66)">Fisch - Einzelfilet natur  A - L</button>
<button mat-menu-item (click)="onProductCategoryChanged(193)">Fisch - Einzelfilet natur  M - Z</button>
<button mat-menu-item (click)="onProductCategoryChanged(194)">Fisch - ganze Fische & Räucher-Rohware</button>
<button mat-menu-item (click)="onProductCategoryChanged(64)">Fisch - Steaks & Teller</button>
<button mat-menu-item (click)="onProductCategoryChanged(65)">Fisch - Convinence</button>
<button mat-menu-item (click)="onProductCategoryChanged(67)">Meeresspezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(195)">Shrimps + Garnelen</button>
<button mat-menu-item (click)="onProductCategoryChanged(68)">Fisch- Räucherware</button>
<button mat-menu-item (click)="onProductCategoryChanged(69)">Fisch Feinkost & Marinaden</button>
</mat-menu>
<mat-menu #m172="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m175">Brötchen & Baguette</button>
<button mat-menu-item (click)="onProductCategoryChanged(173)">Baguette Stangen</button>
<button mat-menu-item (click)="onProductCategoryChanged(171)">Brot Spezialitäten & Blätterteig</button>
<button mat-menu-item (click)="onProductCategoryChanged(170)">Back Spezialitäten herzhaft</button>
<button mat-menu-item (click)="onProductCategoryChanged(169)">Backdessert süß</button>
<button mat-menu-item (click)="onProductCategoryChanged(168)">Donuts</button>
</mat-menu>
<mat-menu #m72="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(72)">Kuchen rund Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(167)">Schnitten und Blechkuchen Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(74)">Torten Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(76)">Spezialitäten Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(224)">Fruchtige Tartes</button>
<button mat-menu-item (click)="onProductCategoryChanged(223)">Rustikale Landfrauenkuchen Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(75)">Balance Pfalzgraf</button>
<button mat-menu-item (click)="onProductCategoryChanged(213)">THOKS - Thüringer Obstkuchen</button>
<button mat-menu-item (click)="onProductCategoryChanged(71)">Vandemoortele Kuchen + Sahnerollen</button>
<button mat-menu-item (click)="onProductCategoryChanged(230)">Gemischte Torten</button>
</mat-menu>
<mat-menu #m227="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(227)">Eis Impuls - Süd Eis/Van Gils/Mio/Nostalgie</button>
<button mat-menu-item (click)="onProductCategoryChanged(228)">Eis Mars </button>
<button mat-menu-item (click)="onProductCategoryChanged(229)">Eis Desserts / Riegel / Torten</button>
<button mat-menu-item (click)="onProductCategoryChanged(160)">Eis Spezialitäten Van Gils + Ysco</button>
<button mat-menu-item (click)="onProductCategoryChanged(233)">Eis Sommerland 5 Liter Dosen</button>
<button mat-menu-item (click)="onProductCategoryChanged(221)">Eis Gelato Antonio 5 Liter Wanne</button>
<button mat-menu-item (click)="onProductCategoryChanged(205)">Eis Käfer 5 Liter Wannen </button>
<button mat-menu-item (click)="onProductCategoryChanged(234)">Eis G+G Gelato 5,7 Liter Wannen</button>
<button mat-menu-item (click)="onProductCategoryChanged(225)">Eis 2x2,5 Liter Schalen - Mio</button>
<button mat-menu-item (click)="onProductCategoryChanged(83)">Eis Echte Frucht</button>
<button mat-menu-item (click)="onProductCategoryChanged(222)">Eis Spiel-Figuren</button>
<button mat-menu-item (click)="onProductCategoryChanged(201)">Eis-&Mousse-Dessert Ostern+Weihnachten+...</button>
<button mat-menu-item (click)="onProductCategoryChanged(84)">Eis Copa Steingut</button>
<button mat-menu-item (click)="onProductCategoryChanged(88)">Eisbuffet + Sonderanfertigung</button>
<button mat-menu-item (click)="onProductCategoryChanged(91)">BB Waffeln</button>
<button mat-menu-item (click)="onProductCategoryChanged(92)">Dessert - Soßen  z.B. für Eis, Kuchen . . .</button>
<button mat-menu-item (click)="onProductCategoryChanged(90)">Schlagsahne</button>
<button mat-menu-item (click)="onProductCategoryChanged(124)">Eiswürfel für Cocktails</button>
<button mat-menu-item (click)="onProductCategoryChanged(89)">BB Deko & Garnier -  Zubehör</button>
<button mat-menu-item (click)="onProductCategoryChanged(196)">Eis - Karten</button>
<button mat-menu-item (click)="onProductCategoryChanged(197)">Eis Gläser</button>
</mat-menu>
<mat-menu #m94="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(94)">HP Geflügel + Sonstige</button>
<button mat-menu-item (click)="onProductCategoryChanged(95)">HP Gemüse</button>
<button mat-menu-item (click)="onProductCategoryChanged(96)">HP Früchte</button>
<button mat-menu-item (click)="onProductCategoryChanged(97)">HP Kartoffelprodukte</button>
<button mat-menu-item (click)="onProductCategoryChanged(98)">HP Fisch</button>
</mat-menu>
<mat-menu #m100="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m116">Getränke</button>
<button mat-menu-item [matMenuTriggerFor]="m198">Trockenprodukte+Nährmittel</button>
<button mat-menu-item (click)="onProductCategoryChanged(159)">Molkereiprodukte</button>
<button mat-menu-item [matMenuTriggerFor]="m158">Gewürze + Essig</button>
<button mat-menu-item (click)="onProductCategoryChanged(103)">Portionsware</button>
<button mat-menu-item (click)="onProductCategoryChanged(104)">Gemüse & Sauerkonserven</button>
<button mat-menu-item (click)="onProductCategoryChanged(110)">Ketchu/Mayo/Senf/Dressing</button>
<button mat-menu-item (click)="onProductCategoryChanged(105)">Frucht - Konserven</button>
<button mat-menu-item (click)="onProductCategoryChanged(106)">Konfitüre & Marmelade</button>
<button mat-menu-item (click)="onProductCategoryChanged(109)">frische Eier</button>
<button mat-menu-item (click)="onProductCategoryChanged(113)">Koch - Brat & Backzutaten</button>
<button mat-menu-item [matMenuTriggerFor]="m118">Oscar Fond/Food-Service/Knorr/Pfanni/Maggi</button>
</mat-menu>
<mat-menu #m214="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(214)">Frische Suppen und Eintöpfe</button>
</mat-menu>
<mat-menu #m130="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(130)">Wurst+Schinken</button>
<button mat-menu-item [matMenuTriggerFor]="m135">Fleisch</button>
<button mat-menu-item [matMenuTriggerFor]="m143">Fisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(139)">Gemüse</button>
<button mat-menu-item (click)="onProductCategoryChanged(141)">Früchte</button>
<button mat-menu-item (click)="onProductCategoryChanged(131)">Brot+Gebäck</button>
<button mat-menu-item (click)="onProductCategoryChanged(134)">Butterfiguren</button>
<button mat-menu-item (click)="onProductCategoryChanged(140)">Eier+Käse</button>
<button mat-menu-item (click)="onProductCategoryChanged(133)">Salate+Gewürzgurken</button>
<button mat-menu-item (click)="onProductCategoryChanged(142)">Portionsware</button>
<button mat-menu-item (click)="onProductCategoryChanged(146)">Eis-Cocktailwürfel</button>
<button mat-menu-item (click)="onProductCategoryChanged(147)">Garnieren+Verfeinern mit zB Mayo, Dressing...</button>
</mat-menu>
<mat-menu #m176="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(176)">Neuheiten Aktuell</button>
<button mat-menu-item (click)="onProductCategoryChanged(191)">Neuheiten letzten 12 Monate</button>
<button mat-menu-item (click)="onProductCategoryChanged(152)">Änderungen</button>
<button mat-menu-item (click)="onProductCategoryChanged(157)">Rest-& Sonderposten</button>
<button mat-menu-item (click)="onProductCategoryChanged(211)">Auslistungen</button>
</mat-menu>
<mat-menu #m11="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m13">Putenteile + Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(12)">Puten ganze</button>
</mat-menu>
<mat-menu #m16="matMenu">
<button mat-menu-item [matMenuTriggerFor]="m18">Hähnchenteile + Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(17)">Hähnchen ganze</button>
</mat-menu>
<mat-menu #m22="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(22)">Flugentenbrust</button>
<button mat-menu-item (click)="onProductCategoryChanged(163)">Flugentenkeulen</button>
<button mat-menu-item (click)="onProductCategoryChanged(23)">Flugenten ganze</button>
</mat-menu>
<mat-menu #m24="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(24)">Gänse ganz</button>
<button mat-menu-item (click)="onProductCategoryChanged(25)">Gänseteile</button>
</mat-menu>
<mat-menu #m175="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(175)">Brötchen klassisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(174)">Brötchen Spezialitäten</button>
</mat-menu>
<mat-menu #m116="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(116)">Milch</button>
<button mat-menu-item (click)="onProductCategoryChanged(231)">Wein/Glühwein/Punsch</button>
<button mat-menu-item (click)="onProductCategoryChanged(232)">Kaffee/Tee/Kakao/Saft</button>
</mat-menu>
<mat-menu #m198="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(198)">Teigwaren</button>
<button mat-menu-item (click)="onProductCategoryChanged(199)">Öle, Fette, Butter</button>
<button mat-menu-item (click)="onProductCategoryChanged(200)">Trockenprodukte</button>
</mat-menu>
<mat-menu #m158="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(158)">Essig + Zitrone</button>
<button mat-menu-item (click)="onProductCategoryChanged(115)">Gewürze</button>
</mat-menu>
<mat-menu #m118="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(118)">Hollondaise + Bernaise</button>
<button mat-menu-item (click)="onProductCategoryChanged(119)">Suppen</button>
<button mat-menu-item (click)="onProductCategoryChanged(120)">Soßen + Brühe</button>
<button mat-menu-item (click)="onProductCategoryChanged(122)">Soßenbinder + Mehlschwitze</button>
<button mat-menu-item (click)="onProductCategoryChanged(121)">Beilagen & Fixprodukte</button>
<button mat-menu-item (click)="onProductCategoryChanged(123)">Dessert</button>
</mat-menu>
<mat-menu #m135="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(135)">Rind</button>
<button mat-menu-item (click)="onProductCategoryChanged(136)">Schwein</button>
<button mat-menu-item (click)="onProductCategoryChanged(137)">Geflügel</button>
</mat-menu>
<mat-menu #m143="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(143)">Räucherfisch</button>
<button mat-menu-item (click)="onProductCategoryChanged(144)">Meeresfrüchte</button>
<button mat-menu-item (click)="onProductCategoryChanged(145)">Fischmarinaden</button>
</mat-menu>
<mat-menu #m13="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(13)">Schnitzel + Steaks</button>
<button mat-menu-item (click)="onProductCategoryChanged(14)">Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(15)">Teile</button>
</mat-menu>
<mat-menu #m18="matMenu">
<button mat-menu-item (click)="onProductCategoryChanged(18)">Schnitzel + Steaks</button>
<button mat-menu-item (click)="onProductCategoryChanged(19)">Spezialitäten</button>
<button mat-menu-item (click)="onProductCategoryChanged(20)">Keulen</button>
<button mat-menu-item (click)="onProductCategoryChanged(21)">Fleisch</button>
</mat-menu>

</div> -->

<!-- 03012013################################################################################################ -->

<!-- <div>

  <button appearance="outline" mat-stroked-button class=" but95 "  mat-button [matMenuTriggerFor]="parent_"> Alle Warengruppen . . .</button>
  <mat-menu #parent_="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged()"><b>Alle Warengruppen . . .</b></button>

  <button mat-menu-item (click)="onProductCategoryChanged(236)">Neuheiten 2021</button>
  <button mat-menu-item [matMenuTriggerFor]="m1">Geflügel</button>
  <button mat-menu-item [matMenuTriggerFor]="m2">Wild</button>
  <button mat-menu-item [matMenuTriggerFor]="m3">Kaninchen</button>
  <button mat-menu-item [matMenuTriggerFor]="m4">Rind</button>
  <button mat-menu-item (click)="onProductCategoryChanged(44)">Kalb</button>
  <button mat-menu-item (click)="onProductCategoryChanged(43)">Lamm+Schaf+Zicklein</button>
  <button mat-menu-item [matMenuTriggerFor]="m39">Schwein</button>
  <button mat-menu-item [matMenuTriggerFor]="m107">Wurstwaren</button>
  <button mat-menu-item [matMenuTriggerFor]="m45">Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(49)">Pizza & Pasta</button>
  <button mat-menu-item [matMenuTriggerFor]="m50">Gemüse</button>
  <button mat-menu-item (click)="onProductCategoryChanged(54)">Obst</button>
  <button mat-menu-item [matMenuTriggerFor]="m55">Kartoffelprodukte</button>
  <button mat-menu-item [matMenuTriggerFor]="m59">Fisch</button>
  <button mat-menu-item [matMenuTriggerFor]="m148">Backwaren & Spezialitäten</button>
  <button mat-menu-item [matMenuTriggerFor]="m70">Kuchen & Torten</button>
  <button mat-menu-item [matMenuTriggerFor]="m78">Eis</button>
  <button mat-menu-item [matMenuTriggerFor]="m93">Haushaltpackungen</button>
  <button mat-menu-item [matMenuTriggerFor]="m99">Trockenwaren & Nährmittel</button>
  <button mat-menu-item (click)="onProductCategoryChanged(125)">Garnierung</button>
  <button mat-menu-item (click)="onProductCategoryChanged(126)">Verschiedenes</button>
  <button mat-menu-item (click)="onProductCategoryChanged(165)">Service - Artikel</button>
  <button mat-menu-item [matMenuTriggerFor]="m202">Grillen & Party</button>
  <button mat-menu-item (click)="onProductCategoryChanged(128)">Freibadgastronomie</button>
  <button mat-menu-item (click)="onProductCategoryChanged(166)">Fingerfood</button>
  <button mat-menu-item [matMenuTriggerFor]="m129">kaltes Buffet</button>
  <button mat-menu-item (click)="onProductCategoryChanged(206)">Vegetarisches</button>
  <button mat-menu-item [matMenuTriggerFor]="m150">Neuheiten</button>
  </mat-menu>
  <mat-menu #m1="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="m6">Pute</button>
  <button mat-menu-item [matMenuTriggerFor]="m7">Hähnchen</button>
  <button mat-menu-item [matMenuTriggerFor]="m8">Flugente</button>
  <button mat-menu-item (click)="onProductCategoryChanged(9)">Hausente</button>
  <button mat-menu-item [matMenuTriggerFor]="m10">Gänse</button>
  </mat-menu>
  <mat-menu #m2="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(26)">Hirsch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(27)">Wildschwein</button>
  <button mat-menu-item (click)="onProductCategoryChanged(28)">Reh</button>
  <button mat-menu-item (click)="onProductCategoryChanged(29)">Wildhase</button>
  <button mat-menu-item (click)="onProductCategoryChanged(30)">Wildgeflügel</button>
  <button mat-menu-item (click)="onProductCategoryChanged(31)">Exoten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(32)">Elch</button>
  </mat-menu>
  <mat-menu #m3="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(33)">Ganze Kaninchen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(35)">Kaninchen Teile+Spez.</button>
  </mat-menu>
  <mat-menu #m4="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(37)">Rind portioniert, küchenfertig & gegart</button>
  <button mat-menu-item (click)="onProductCategoryChanged(38)">Rindfl.-Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(181)">Rindfleisch frisch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(182)">Rindfleisch TK</button>
  </mat-menu>
  <mat-menu #m39="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(41)">Schweine Schnitzel + Steaks</button>
  <button mat-menu-item (click)="onProductCategoryChanged(42)">Schweinefl.- Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(177)">Schweinefleisch frisch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(178)">Schwein Frikadellen + Haxen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(179)">Schweinefleisch Spieße + Rouladen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(180)">Schwein Gulasch + Braten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(190)">Schweinefleisch TK</button>
  </mat-menu>
  <mat-menu #m107="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(183)">Wurst und Wurstprodukte</button>
  <button mat-menu-item (click)="onProductCategoryChanged(184)">Schinken + Speck</button>
  <button mat-menu-item (click)="onProductCategoryChanged(185)">Wurstspezialitäten</button>
  </mat-menu>
  <mat-menu #m45="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(46)">Fleisch - Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(47)">Gemüse - Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(48)">Spezialitäten - sonstige</button>
  <button mat-menu-item (click)="onProductCategoryChanged(108)">Butter- Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(186)">Pfannen+Suppen Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(187)">Käse Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(188)">Mehl-Kartoffel-Quark-Teigspeisen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(189)">Knödel, Eierkuchen</button>
  </mat-menu>
  <mat-menu #m50="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(51)">Gemüse allgemein</button>
  <button mat-menu-item (click)="onProductCategoryChanged(52)">Gemüsemischungen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(53)">Pilze & Pilzmischungen</button>
  </mat-menu>
  <mat-menu #m55="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(56)">Pommes Frites</button>
  <button mat-menu-item (click)="onProductCategoryChanged(57)">Kartoffel - Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(58)">Klöße & Kloßteig</button>
  <button mat-menu-item (click)="onProductCategoryChanged(111)">Frischeprogramm Kartoffeln</button>
  <button mat-menu-item (click)="onProductCategoryChanged(112)">Kartoffelsalat + Pürree</button>
  <button mat-menu-item (click)="onProductCategoryChanged(153)">Rösti, Taschen, Reibekuchen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(192)">Wedges (Westernkartoffeln)</button>
  </mat-menu>
  <mat-menu #m59="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(60)">Fischtafeln paniert</button>
  <button mat-menu-item (click)="onProductCategoryChanged(61)">Fischtafeln natur</button>
  <button mat-menu-item (click)="onProductCategoryChanged(62)">Fisch natur interlive</button>
  <button mat-menu-item (click)="onProductCategoryChanged(63)">Fisch - Einzelfilet paniert</button>
  <button mat-menu-item (click)="onProductCategoryChanged(64)">Fisch - Steaks & Teller</button>
  <button mat-menu-item (click)="onProductCategoryChanged(65)">Fisch - Convenience</button>
  <button mat-menu-item (click)="onProductCategoryChanged(66)">Fisch - Einzelfilet natur  A - L</button>
  <button mat-menu-item (click)="onProductCategoryChanged(67)">Meeresspezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(68)">Fisch- Räucherware</button>
  <button mat-menu-item (click)="onProductCategoryChanged(69)">Fisch Feinkost & Marinaden</button>
  <button mat-menu-item (click)="onProductCategoryChanged(193)">Fisch - Einzelfilet natur  M - Z</button>
  <button mat-menu-item (click)="onProductCategoryChanged(194)">Fisch - ganze Fische & Räucher-Rohware</button>
  <button mat-menu-item (click)="onProductCategoryChanged(195)">Shrimps + Garnelen</button>
  </mat-menu>
  <mat-menu #m148="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(168)">Donuts</button>
  <button mat-menu-item (click)="onProductCategoryChanged(169)">Backdessert süß</button>
  <button mat-menu-item (click)="onProductCategoryChanged(170)">Back Spezialitäten herzhaft</button>
  <button mat-menu-item (click)="onProductCategoryChanged(171)">Brot Spezialitäten & Blätterteig</button>
  <button mat-menu-item [matMenuTriggerFor]="m172">Brötchen & Baguette</button>
  <button mat-menu-item (click)="onProductCategoryChanged(173)">Baguette Stangen</button>
  </mat-menu>
  <mat-menu #m70="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(71)">Vandemoortele Kuchen + Sahnerollen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(72)">Kuchen rund Pfalzgraf</button>
  <button mat-menu-item (click)="onProductCategoryChanged(74)">Torten Pfalzgraf</button>
  <button mat-menu-item (click)="onProductCategoryChanged(75)">Balance Pfalzgraf</button>
  <button mat-menu-item (click)="onProductCategoryChanged(76)">Spezialitäten Pfalzgraf</button>
  <button mat-menu-item (click)="onProductCategoryChanged(167)">Schnitten und Blechkuchen Pfalzgraf</button>
  <button mat-menu-item (click)="onProductCategoryChanged(213)">THOKS - Thüringer Obstkuchen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(223)">Rustikale Landfrauenkuchen Pfalzgraf</button>
  <button mat-menu-item (click)="onProductCategoryChanged(224)">Fruchtige Tartes</button>
  <button mat-menu-item (click)="onProductCategoryChanged(230)">Gemischte Torten</button>
  </mat-menu>
  <mat-menu #m78="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(83)">Eis Echte Frucht</button>
  <button mat-menu-item (click)="onProductCategoryChanged(84)">Eis Copa Steingut</button>
  <button mat-menu-item (click)="onProductCategoryChanged(88)">Eisbuffet + Sonderanfertigung</button>
  <button mat-menu-item (click)="onProductCategoryChanged(89)">BB Deko & Garnier -  Zubehör</button>
  <button mat-menu-item (click)="onProductCategoryChanged(90)">Schlagsahne</button>
  <button mat-menu-item (click)="onProductCategoryChanged(91)">BB Waffeln</button>
  <button mat-menu-item (click)="onProductCategoryChanged(92)">Dessert - Soßen  z.B. für Eis, Kuchen . . .</button>
  <button mat-menu-item (click)="onProductCategoryChanged(124)">Eiswürfel für Cocktails</button>
  <button mat-menu-item (click)="onProductCategoryChanged(160)">Eis Spezialitäten Van Gils + Ysco</button>
  <button mat-menu-item (click)="onProductCategoryChanged(196)">Eis - Karten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(197)">Eis Gläser</button>
  <button mat-menu-item (click)="onProductCategoryChanged(201)">Eis-&Mousse-Dessert Ostern+Weihnachten+...</button>
  <button mat-menu-item (click)="onProductCategoryChanged(205)">Eis Käfer 5 Liter Wannen </button>
  <button mat-menu-item (click)="onProductCategoryChanged(221)">Eis Gelato Antonio 5 Liter Wanne</button>
  <button mat-menu-item (click)="onProductCategoryChanged(222)">Eis Spiel-Figuren</button>
  <button mat-menu-item (click)="onProductCategoryChanged(225)">Eis 2x2,4 Liter Schalen - Käfer</button>
  <button mat-menu-item (click)="onProductCategoryChanged(227)">Eis Impuls - Süd Eis/Van Gils/Mio/Nostalgie</button>
  <button mat-menu-item (click)="onProductCategoryChanged(228)">Eis Mars </button>
  <button mat-menu-item (click)="onProductCategoryChanged(229)">Eis Desserts / Riegel / Torten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(233)">Eis Sommerland 5 Liter Dosen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(234)">Eis G+G Gelato 5,7 Liter Wannen</button>
  </mat-menu>
  <mat-menu #m93="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(94)">HP Geflügel + Sonstige</button>
  <button mat-menu-item (click)="onProductCategoryChanged(95)">HP Gemüse</button>
  <button mat-menu-item (click)="onProductCategoryChanged(96)">HP Früchte</button>
  <button mat-menu-item (click)="onProductCategoryChanged(97)">HP Kartoffelprodukte</button>
  <button mat-menu-item (click)="onProductCategoryChanged(98)">HP Fisch</button>
  </mat-menu>
  <mat-menu #m99="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="m100">Getränke</button>
  <button mat-menu-item [matMenuTriggerFor]="m101">Trockenprodukte+Nährmittel</button>
  <button mat-menu-item [matMenuTriggerFor]="m102">Gewürze + Essig</button>
  <button mat-menu-item (click)="onProductCategoryChanged(103)">Portionsware</button>
  <button mat-menu-item (click)="onProductCategoryChanged(104)">Gemüse & Sauerkonserven</button>
  <button mat-menu-item (click)="onProductCategoryChanged(105)">Frucht - Konserven</button>
  <button mat-menu-item (click)="onProductCategoryChanged(106)">Konfitüre & Marmelade</button>
  <button mat-menu-item (click)="onProductCategoryChanged(109)">frische Eier</button>
  <button mat-menu-item (click)="onProductCategoryChanged(110)">Ketchu/Mayo/Senf/Dressing</button>
  <button mat-menu-item (click)="onProductCategoryChanged(113)">Koch - Brat & Backzutaten</button>
  <button mat-menu-item [matMenuTriggerFor]="m114">Oscar Fond/Food-Service/Knorr/Pfanni/Maggi</button>
  <button mat-menu-item (click)="onProductCategoryChanged(159)">Molkereiprodukte</button>
  </mat-menu>
  <mat-menu #m202="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(214)">Frische Suppen und Eintöpfe</button>
  </mat-menu>
  <mat-menu #m129="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(130)">Wurst+Schinken</button>
  <button mat-menu-item (click)="onProductCategoryChanged(131)">Brot+Gebäck</button>
  <button mat-menu-item [matMenuTriggerFor]="m132">Fleisch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(133)">Salate+Gewürzgurken</button>
  <button mat-menu-item (click)="onProductCategoryChanged(134)">Butterfiguren</button>
  <button mat-menu-item [matMenuTriggerFor]="m138">Fisch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(139)">Gemüse</button>
  <button mat-menu-item (click)="onProductCategoryChanged(140)">Eier+Käse</button>
  <button mat-menu-item (click)="onProductCategoryChanged(141)">Früchte</button>
  <button mat-menu-item (click)="onProductCategoryChanged(142)">Portionsware</button>
  <button mat-menu-item (click)="onProductCategoryChanged(146)">Eis-Cocktailwürfel</button>
  <button mat-menu-item (click)="onProductCategoryChanged(147)">Garnieren+Verfeinern mit zB Mayo, Dressing...</button>
  </mat-menu>
  <mat-menu #m150="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(152)">Änderungen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(157)">Rest-& Sonderposten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(176)">Neuheiten Aktuell</button>
  <button mat-menu-item (click)="onProductCategoryChanged(191)">Neuheiten letzten 12 Monate</button>
  <button mat-menu-item (click)="onProductCategoryChanged(211)">Auslistungen</button>
  </mat-menu>
  <mat-menu #m6="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="m11">Putenteile + Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(12)">Puten ganze</button>
  </mat-menu>
  <mat-menu #m100="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(116)">Milch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(231)">Wein/Glühwein/Punsch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(232)">Kaffee/Tee/Kakao/Saft</button>
  </mat-menu>
  <mat-menu #m172="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(174)">Brötchen Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(175)">Brötchen klassisch</button>
  </mat-menu>
  <mat-menu #m7="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="m16">Hähnchenteile + Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(17)">Hähnchen ganze</button>
  </mat-menu>
  <mat-menu #m101="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(198)">Teigwaren</button>
  <button mat-menu-item (click)="onProductCategoryChanged(199)">Öle, Fette, Butter</button>
  <button mat-menu-item (click)="onProductCategoryChanged(200)">Trockenprodukte</button>
  </mat-menu>
  <mat-menu #m132="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(135)">Rind</button>
  <button mat-menu-item (click)="onProductCategoryChanged(136)">Schwein</button>
  <button mat-menu-item (click)="onProductCategoryChanged(137)">Geflügel</button>
  </mat-menu>
  <mat-menu #m8="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(22)">Flugentenbrust</button>
  <button mat-menu-item (click)="onProductCategoryChanged(23)">Flugenten ganze</button>
  <button mat-menu-item (click)="onProductCategoryChanged(163)">Flugentenkeulen</button>
  </mat-menu>
  <mat-menu #m102="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(115)">Gewürze</button>
  <button mat-menu-item (click)="onProductCategoryChanged(158)">Essig + Zitrone</button>
  </mat-menu>
  <mat-menu #m138="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(143)">Räucherfisch</button>
  <button mat-menu-item (click)="onProductCategoryChanged(144)">Meeresfrüchte</button>
  <button mat-menu-item (click)="onProductCategoryChanged(145)">Fischmarinaden</button>
  </mat-menu>
  <mat-menu #m10="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(24)">Gänse ganz</button>
  <button mat-menu-item (click)="onProductCategoryChanged(25)">Gänseteile</button>
  </mat-menu>
  <mat-menu #m114="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(118)">Hollondaise + Bernaise</button>
  <button mat-menu-item (click)="onProductCategoryChanged(119)">Suppen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(120)">Soßen + Brühe</button>
  <button mat-menu-item (click)="onProductCategoryChanged(121)">Beilagen & Fixprodukte</button>
  <button mat-menu-item (click)="onProductCategoryChanged(122)">Soßenbinder + Mehlschwitze</button>
  <button mat-menu-item (click)="onProductCategoryChanged(123)">Dessert</button>
  </mat-menu>
  <mat-menu #m11="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(13)">Schnitzel + Steaks</button>
  <button mat-menu-item (click)="onProductCategoryChanged(14)">Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(15)">Teile</button>
  </mat-menu>
  <mat-menu #m16="matMenu">
  <button mat-menu-item (click)="onProductCategoryChanged(18)">Schnitzel + Steaks</button>
  <button mat-menu-item (click)="onProductCategoryChanged(19)">Spezialitäten</button>
  <button mat-menu-item (click)="onProductCategoryChanged(20)">Keulen</button>
  <button mat-menu-item (click)="onProductCategoryChanged(21)">Fleisch</button>
  </mat-menu>

</div> -->

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { stringify } from 'querystring';

import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { iUsers } from 'src/app/models/AlaskaModels';
import { DataService } from 'src/app/Services/data.service';
import { JSDocTagName, NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { InputErrorStateMatcher } from 'src/app/models/InputErrorStateMatcher';
import { AppComponent } from 'src/app/app.component';
import { DialogService } from '../../Services/dialog.service';

@Component({
  selector: 'app-changePassword',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordResetForm: FormGroup;
  passwordResetRequestForm: FormGroup;
  passwordResetRequestFormSubmited:  boolean;
  passwordResetFormSubmited: boolean;

  user: iUsers ;
  matcher = new InputErrorStateMatcher();
  loggedInUser: iUsers;
  loggedInEmail:string = '';


  constructor(private dataService: DataService,
    private router: Router,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService) { }

  ngOnInit() {
    this.dataService.setCurrentUrl("userlogin");
    this.passwordResetRequestFormSubmited = false;
    this.createPasswordResetRequestForm();
    this.createPasswordResetForm();
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedinUsers'))?  JSON.parse(localStorage.getItem('loggedinUsers'))[0] : undefined;
    this.loggedInEmail = this.loggedInUser ? this.loggedInUser.Email: '';

    this.dataService.loggedInUserSubject$.subscribe(
      loggedInUser => {
        this.loggedInUser = loggedInUser;
        this.loggedInEmail = loggedInUser.Email;
      },
      (err) => {  console.log('error - loggedInUser-subscription: ', err);
    });

    console.log('passwordResetRequestForm', this.passwordResetRequestForm);
    console.log('formcontrolemail', this.passwordResetRequestForm.get('passwordResetRequestEmail'));
  }

  createPasswordResetForm(){
    this.passwordResetForm = new FormGroup(
      {
        email: new FormControl(null,[Validators.required]),
        currentpassword: new FormControl(null, [Validators.required, Validators.minLength(3)]),
        password: new FormControl(null, [Validators.required, Validators.minLength(6)]),
        conformpassword: new FormControl(null, [Validators.required, Validators.minLength(6)])
      }
    )
  }

  createPasswordResetRequestForm(){
    this.passwordResetRequestForm = this.formBuilder.group(
      {
        passwordResetRequestEmail: ['', Validators.required],
      }
    )
  }

  get passwordResetRequestFormControls() { return this.passwordResetRequestForm.controls; }

get email(){return this.passwordResetForm.get('email');}
get currentpassword(){return this.passwordResetForm.get('currentpassword');}
get password(){return this.passwordResetForm.get('password');}
get conformpassword(){return this.passwordResetForm.get('conformpassword');}
get passwordResetRequestEmail(){return this.passwordResetRequestForm.get('passwordResetRequestEmail');}

validatePasswordConfirmation():any {
  var pw = this.passwordResetForm.controls['password'];
  var cpw = this.passwordResetForm.controls['conformpassword'];
  if (pw.value === '') {
    return {err:"Password is blank"};
  }

  if (cpw.value === '') {
    return {err:"Confirmation password is blank"};
  }

  if (pw.value !== cpw.value) {
    return {err:"Passwords do not match"}
  }

  return true;
}



onPasswordReset(){
  this.user = {
    Email: this.loggedInUser.Email,
    Password: btoa(this.password.value),
    currentpassword: btoa(this.currentpassword.value),

    Token: btoa(this.currentpassword.value)

  };

    console.log("usertocken : ", this.user);


  this.dataService.PostData("custom",this.user,"UserChangePassword").subscribe(
    data => {
      console.log("reset result Data : ", data);
      this.router.navigate(['/']);
      this.dialogService.okDialog('Passwort zurücksetzen', 'Passwort erfolgreich zurückgesetzt. ', 'success','40%');
      this.dataService.onLogOut();

      this.dataService.setMsgSourceSubject( 'GoToLogin',  '',  '',  '',  '', 'GoToLogin',  undefined,  0 );


    }, err => {
      console.log("PostData error : ", err);
      this.dialogService.okDialog('Passwort zurücksetzen', 'Passwort kann nicht verifiziert werden', 'danger','40%');
    }, () => {
      console.log("PostData completed : ");
    });

}

onAbort(){
  this.dataService.setMsgSourceSubject( 'isAborted',  '',  '',  '',  '', 'isAborted',  undefined,  0 );

}


}

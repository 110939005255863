import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { iActivationData, iOptions, iAddresses } from 'src/app/models/AlaskaModels';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { CollapseModule, CollapseDirective} from 'ngx-bootstrap/collapse';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  addressForm: FormGroup;
  formSubmited: boolean;
  @ViewChild("content") contentDivView: ElementRef;
  @ViewChild("colDirective") colDirective: CollapseDirective;
  @ViewChild("addressDiv") addressDiv: ElementRef;
  addressTypes: iOptions[]= [];
  tours: iOptions[]= [];

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef) { }

  ngOnInit() {

  }
  ngAfterViewInit(){

  }

  logedIn(): boolean{
    if(localStorage.getItem('token') == null){
      return false;
    }else{
      return true
    }
  }
}

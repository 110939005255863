import {Component, OnInit,ViewChild,Input} from '@angular/core';
import {iUsers, iCustomers,iPositions,  iInputOptions, iAddresses, iAddressestypes} from '../../../models/AlaskaModels';
import {InputErrorStateMatcher} from '../../../models/InputErrorStateMatcher';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../../../Services/data.service';
import { SnackbarService } from '../../../Services/snackbar.service';
import { DialogService } from '../../../Services/dialog.service';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

const ELEMENT_DATA: Array<iAddresses> = [];

@Component({
  selector: 'app-adminAddresses',
  templateUrl: './adminAddresses.component.html',
  styleUrls: ['./adminAddresses.component.scss']
})
export class AdminAddressesComponent implements OnInit {

  loggedInUser: iUsers;
  SelectedCustomer: iCustomers;



  item: string = 'Liste von Kundaddresse';
  mode: string = 'list';
  selectedUser: iUsers;
  editableAddress: iAddresses;

  tableColumns: string[] = [ 'AddressesId','CustomersId','AddressestypesId','Street','Zip','City','Tours','actions'];
  displayedColumns: string[] = [ 'AddressesId','CustomersId','AddressestypesId','Street','Zip','City','Tours','actions'];

  addresses: iAddresses[];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;
  @Input() editableCustomer: iCustomers;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  formSubmited: boolean = false;
  addressesForm: FormGroup;

  inputOptions: iInputOptions;
  AddressTypes: iAddressestypes[];
  Customers: iCustomers[] = [];
  Positions: iPositions[] = [];

  matcher = new InputErrorStateMatcher();


  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private matPaginatorIntl: MatPaginatorIntl
  ) {}

  ngOnInit() {
    this.editableCustomer = JSON.parse(localStorage.getItem("editableCustomer"));
    if(this.editableCustomer){

    }

    console.log('addresse this.editableCustomer: ', this.editableCustomer);

    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
    this.AddressTypes = this.inputOptions['addressestypes'];
    this.Customers = JSON.parse(localStorage.getItem('customers'));

    console.log("this.AddressTypes: ", this.AddressTypes);
    console.log("this.Customers: ", this.Customers);


    this.createAddressForm();

    this.SelectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;
      }, err => {
        console.log("error loggedin user: ", err);
      });

      this.dataService.msgSubject$.subscribe(
        msg => {
          if(msg.Name.toLowerCase() == "orderadded"){

          }}, err => {
            console.log("error adding order: ", err);
          }
          );


  }

  populateData(){
    if(localStorage.getItem('addresses')){
      this.addresses = JSON.parse(localStorage.getItem('addresses'));
      if(this.dataSource.data){
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }
    }
    else{
      if(this.editableCustomer)
      {
        this.dataService.getData('custom','getaddressesbycustomer',this.editableCustomer.CustomersId).subscribe(
          data => {
            this.addresses = data;
            if(this.dataSource.data){
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
            }
          }, err => {
            console.log('error retriving addresses: ', err);
          });
      }else
      {
        this.dataService.getData('custom','getaddresses').subscribe(
          data => {
            this.addresses = data;
            if(this.dataSource.data){
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
            }
          }, err => {
            console.log('error retriving addresses: ', err);
          });
      }

    }
    localStorage.removeItem("editableCustomer")  ;
  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = 'Artikel pro Seite:';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';

    this.populateData();

  }


  createAddressForm(address?: iAddresses){
    this.addressesForm = this.formBuilder.group(
      {
        CustomersId :      	[address != undefined && address.CustomersId != undefined? address.CustomersId : 0, []],
        AddressesId:      	[address != undefined && address.AddressesId != undefined? address.AddressesId : 0, [Validators.required]],
        AddressestypesId: 	[address != undefined && address.AddressestypesId != undefined? address.AddressestypesId : 0, [Validators.required]],
        Street:          	  [address != undefined && address.Street != undefined? address.Street : '', []],
        Street1:          	[address != undefined && address.Street1 != undefined? address.Street1 : '', []],
        Zip:      			    [address != undefined && address.Zip != undefined? address.Zip : '', []],
        City :         		  [address != undefined && address.City != undefined? address.City : '', [Validators.required]],
        District:         	[address != undefined && address.District != undefined? address.District : '', [Validators.required]],
        Country:        	  [address != undefined && address.Country != undefined? address.Country : '', []]
      }
    );
  }


  onList(){
    this.mode = 'list';
    this.item = 'Beschränkte ansicht von kundenadresse . . .';
    console.log("this mode: ", this.mode);
    this.populateData();
  }

  onDetail(){
    this.mode =  'detail';
    this.item = 'Erweiterte ansicht von  kundenadresse . . .';
  }


  onEdit(address: iAddresses){
    this.mode = 'edit';
    this.editableAddress = address;
    const curentAddress:string   = address != undefined && address.Street != undefined && address.Zip != undefined && address.City != undefined?  address.Street +', '+address.Zip +', ' +address.City  :'';
    this.item = 'Adresse: '+ curentAddress +' bearbeiten . . .';

    this.createAddressForm(address);
  }
  onCreate(){
    this.mode = 'create';
    this.item = 'Neu kundenadresse erstellen';
    this.createAddressForm();
  }
  onDelete(address: iAddresses){
    this.mode = 'delete';

    const curentAddress:string   = address != undefined && address.Street != undefined && address.Zip != undefined && address.City != undefined?  address.Street +', '+address.Zip +', ' +address.City  :'';
    this.item = 'kundenadresse: '+ curentAddress +' löschen . . .';

      this.dialogService.conformDialog("Bestätigung", "Möchet Sie den Kundenadresse "+curentAddress+" löschen? Hinweis: daten können gelöscht werden, wenn die ID nicht bereits in einer anderen Tabelle referenziert sind.  ", "danger","abrechen","löschen").afterClosed().subscribe(
        data => {
               if(data !== 'ok'){  this.onList(); return;}

        this.dataService.RemoveData('custom',address.AddressesId, 'deleteaddresses',).subscribe(
        result => {
            this.addresses = result;
            this.snackbarService.snackbarAction("Kundenadresse erfolgreich gelöscht. " , "ok");
        }, err => {
            console.log('error deleting Kundenadresse', err);
            this.dialogService.okDialog("Error", "Kundenadresse löschen fehlgeschlagen. Grund: "+ err.error.text , "danger",'40%');
        }
      );
        }
      );


    this.onSubmit(address);
  }


  getFormData(): iAddresses{
    let _address: iAddresses =
    {
          CustomersId :         +this.addressesForm.get('CustomersId').value,
          AddressesId:          +this.addressesForm.get('AddressesId').value,
          AddressestypesId:     +this.addressesForm.get('AddressestypesId').value,
          Street:               this.addressesForm.get('Street').value,
          Street1:      		    this.addressesForm.get('Street1').value,
          Zip :                 this.addressesForm.get('Zip').value,
          City:                 this.addressesForm.get('City').value,
          District:             this.addressesForm.get('District').value,
          Country :   			    this.addressesForm.get('Country').value


    }
    return _address;
  }

  onSubmit(address: iAddresses){
    this.formSubmited = true;
    const curentAddress:string   = address != undefined && address.Street != undefined && address.Zip != undefined && address.City != undefined?  address.Street +', '+address.Zip +', ' +address.City  :'';
    const curentCustomer:string   = this.editableCustomer != undefined && this.editableCustomer.Name1 != undefined?  this.editableCustomer.Name1 :'';
    console.log("this form: ", this.addressesForm);
    let _address: iAddresses = this.getFormData();
    let emailExist:boolean = false;

    if(this.mode == 'create'){
      _address.AddressesId = 0
      this.dataService.PostData('custom',_address,'postaddresses').subscribe(
        result => {
            this.addresses = result;
            this.populateData();

            this.dialogService.okDialog("Erfolg", "Kundenadresse erfolgreich angelegt." , "success",'40%');
        }, err => {

            console.log('error inserting address', err);
            console.log('error inserting address err.error.text', err.error.text);
            this.dialogService.okDialog("Error", "Kundenadresse hinzufügen fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');

        }
      );
  }

    if(this.mode == 'edit'){
      this.dataService.PutData('custom',_address, 'putaddresses').subscribe(
        result => {
            this.editableAddress = result;
            this.populateData();
            this.snackbarService.snackbarAction("Kundenadresse erfolgreich geändert.", "ok");
        }, err => {
            console.log('error editing adresse', err.error.text);
            this.dialogService.okDialog("Error", "Kundenadresse bearbeitung fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');
        }
      );
    }

      this.onList();

  }

  onReset(){
    this.mode = 'list';
  }


}





// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const productSourcex = ["Products", "custom/getProductCustoms"];


export const environment = {
  production: false,
  API_SCHEME: 'http',


  // API_HOST: 'alaska-api',
  // API_PORT: 443,
  // API_PATH: '',
  // API_BASE_URL: '',
  // API_URL: '',
  // IMAGE_URL:'https://alaska.jopp-net.de/akWeb/wsImages/ImageData/',



  API_HOST: 'http://localhost',
  API_PORT: 443,
  API_PATH: 'api',
  API_BASE_URL: '',
  // API_URL: 'http://localhost:52770/',
  API_URL: 'http://212.132.66.6/',
  IMAGE_URL:'http://alaska.jopp-net.de/akWeb/wsImages/ImageData/',



  CULTURE: 'de',
  MailServer: '',
  MailPort: 465,
  SenderName: '',
  SenderEmail: '',
  Password: '',
  EnableSsl:  true




};



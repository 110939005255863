import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

import { NumberValueToken } from 'html2canvas/dist/types/css/syntax/tokenizer';
import { locale } from 'moment';
import { Observable } from 'rxjs';
import { iCategoryproducts, iCustomermaterialgroupdiscounts, iCustomerproductprices, iCustomers, iDiscountmatrixs, iDynamicorderrecords, iMaterialgroups, iParameterBag, iProductcategories, iProductParameterBag, iProductprices, iProducts, iShoppingcartitems, iShoppingcarts } from '../models/AlaskaModels';
import { DataService } from './data.service';
import { SnackbarService } from 'src/app/Services/snackbar.service';
import { promise } from 'protractor';
import { DialogService } from './dialog.service';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InprogressDialogComponent } from '../dialogs/inprogressDialog/inprogressDialog.component';


@Injectable({
  providedIn: 'root'
})
export class IdbService {

  idbCategoryproducts: iCategoryproducts[] = [];
  idbDiscountmatrixs: iDiscountmatrixs[] = [];
  idbMaterialgroups: iMaterialgroups[] = [];
  idbAllProducts: iProducts[] = [];
  idbActiveProducts: iProducts[] = [];
  idbProductprices: iProductprices[] = [];
  idbCustomerproductprices: iCustomerproductprices[] = [];
  idbCustomermaterialgroupdiscounts: iCustomermaterialgroupdiscounts[] = [];
  idbSelectedCategoryproductsProductIds: number[] = [];
  idbDynamicOrderRecords: iDynamicorderrecords[] = [];

  private deliveryDate: Date = localStorage.getItem('deliveryDate')? new Date(localStorage.getItem('deliveryDate')): new Date(Date.now());
  selectedCustomer: iCustomers;
  selectedProductCategory?:iProductcategories;
  specialDiscount:boolean;
   stores = ['Materialgroups','Products','Productprices','Discountmatrixs','Customerproductprices','Customermaterialgroupdiscounts','','']

  MaterialgroupsSource: string[] = ["Materialgroups", "custom/getMaterialgroups"];

  ProductpricesSource = ["Productprices", "custom/getProductprices"];
  productSource = ["Products", "custom/getProductsWithcategories"];
  DiscountmatrixsSource = ["Discountmatrixs", "custom/getDiscountmatrix"];
  CustomerproductpricesSource = ["Customerproductprices", "custom/getCustomerproductprices"];
  CustomermaterialgroupdiscountsSource = ["Customermaterialgroupdiscounts", "custom/getCustomermaterialgroupdiscounts"];

  idbSources: string[][] = [this.MaterialgroupsSource, this.ProductpricesSource, this.productSource, this.DiscountmatrixsSource, this.CustomerproductpricesSource, this.CustomermaterialgroupdiscountsSource];

  pipe = new DatePipe('de-DE');
  request: IDBOpenDBRequest;
  db: IDBDatabase;
  productStore: IDBObjectStore;
  userStore: IDBObjectStore;

  objectStore:IDBObjectStore;
  dbName: string = 'alaska';
  productsWithProcessCode98: iProducts[]=[];
  parameterBagSubject: iParameterBag = {};
  inprogressWindowWidth = window.innerWidth > window.innerHeight ? '400px' :'90%';

constructor(
  private dataService: DataService,
  private dialogService: DialogService,
  private snackbarService: SnackbarService,
  private dialog: MatDialog,
  ) {

      // parameterBagSubject: iParameterBag;
  // this.dataService.parameterBagSubject$.subscribe(
  //   (bag) => {
  //       this.parameterBagSubject = bag;
  //       let newSelectedCustomer: iCustomers = bag.SelectedCustomer;
  //       let newDeliveryDate: Date = bag.DeliveryDate;
  //       let newSelectedProductCategory : iProductcategories = bag.SelectedProductCategory
  //       let deliveryDateChanged: boolean = (newDeliveryDate && (new Date(newDeliveryDate.getFullYear(),newDeliveryDate.getMonth(), newDeliveryDate.getDay(),0,0,0)).getTime() != (this.deliveryDate && (new Date(this.deliveryDate.getFullYear(),this.deliveryDate.getMonth(), this.deliveryDate.getDay(),0,0,0))).getTime());
  //       let selectedCustomerChanged: boolean = (this.selectedCustomer && this.selectedCustomer.CustomersId)  != (newSelectedCustomer && newSelectedCustomer.CustomersId);
  //       let selectedProductCategoryChanged: boolean = (this.selectedProductCategory && this.selectedProductCategory.ProductcategoriesId)  != (newSelectedProductCategory && newSelectedProductCategory.ProductcategoriesId);
  //       let shopingCart: iShoppingcarts =  bag.SelectedShoppingCart;

  //       if(localStorage.getItem('setIdb') == '0' && this.dataService.hasRoles([1,2,3,4]) && ( deliveryDateChanged == true || selectedCustomerChanged == true || selectedProductCategoryChanged == true)){

  //         this.selectedCustomer = bag && bag.SelectedCustomer? bag.SelectedCustomer : this.selectedCustomer;
  //         this.deliveryDate = bag && bag.DeliveryDate? bag.DeliveryDate: this.deliveryDate;
  //         this.selectedProductCategory = bag && bag.SelectedProductCategory? bag.SelectedProductCategory: this.selectedProductCategory;

  //         // this.getCustomerProductPricesByDeliverydate(this.idbAllProducts,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);
  //       }

  // }, (err) => { console.log('error - parameterBagSubject-subscription: ', err);  });

  this.dataService.selectedCustomerSubject$.subscribe(
    (selectedCustomer) => {
      this.selectedCustomer = selectedCustomer;
      if(localStorage.getItem('setIdb') == '0'){
        this.getCustomerProductPricesByDeliverydate(this.idbAllProducts,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);
      }
      console.log('selectedCustomer-subscription: ', this.selectedCustomer);
    },
    (err) => {
      console.log('error - selectedCustomer-subscription: ', err);
    }
  );

  this.dataService.loggedInUserSubject$.subscribe(
    user => {
      // setTimeout(() => {
      //   if(localStorage.getItem('setIdb') != '0' && this.dataService.hasRoles([1,2,3,4])){
      //     this.populateData(this.selectedCustomer,this.deliveryDate)
      //   }
      // }, 0);

    // if(localStorage.getItem('setIdb') != '0' && this.dataService.hasRoles([1,2,3,4])){
    //   this.populateData(this.selectedCustomer,this.deliveryDate)
    // }
    }
  );

  // if(this.idbActiveProducts.length < 1 && this.idbAllProducts.length < 1){
  //   //create DB before login.
  //   this.createObjectstores();
  //   if(this.dataService.hasRoles([1,2,3,4])){
  //     this.populateData(this.selectedCustomer,this.deliveryDate)
  //   }
  // }



 }

objectstoreExistAndHaveRecords(storeName: string): any{
return new Promise(
//  return false;
  function(resolve, reject){
    var request = indexedDB.open('alaska');
    var db: IDBDatabase;
    request.onerror = function(event) {
      console.log("alaska db could not be opened");
      reject(Error('Error openning idb '));
    };
    request.onsuccess = function(event) {
      let hasRecords: boolean = true
      var database      = request.result;
      var transaction   = database.transaction(storeName, 'readwrite');
        if(transaction){
                var objectStore   = transaction.objectStore(storeName);
                hasRecords =  +objectStore.count(storeName[objectStore.keyPath.toString()]) > 1;
                console.log("objectStor: "+storeName+' has ' + transaction.objectStore(storeName).count + ' records');
        }else{
          hasRecords = false
        }
      console.log("objectStorsHaveRecords: "+hasRecords.valueOf.toString());
      resolve(hasRecords);
    };
  }

);
}
createCustomerObjectstore(){
  return new Promise(
    function(resolve, reject) {

      indexedDB.deleteDatabase('alaska');


      var request = indexedDB.open('alaska');
      var db: IDBDatabase;

      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        reject(Error('Error openning idb '));
      };
      request.onsuccess = function(event) {
        console.log("alaska db opened");
        db = request.result;
        resolve(true);
};
      request.onupgradeneeded = function(event) {
        db = request.result;

                // iShoppingcarts
                let Shoppingcarts = db.createObjectStore('Shoppingcarts', {keyPath: 'ShoppingcartsId', autoIncrement: false});
                Shoppingcarts.createIndex('ShoppingcartsId', 'ShoppingcartsId');

                // Dynamicorderrecords
                let Dynamicorderrecords = db.createObjectStore('Dynamicorderrecords', {keyPath: 'Id', autoIncrement: true});
                Dynamicorderrecords.createIndex('CustomerId', 'CustomerId');

                let Products = db.createObjectStore('Products', {keyPath: 'Id', autoIncrement: true});

                Products.createIndex('ProductsId', 'ProductsId');

                request.onsuccess = function(event) {
                  resolve(true);
                };
    }


  })
}



createObjectstores() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('alaska');
    let stores = ['Materialgroups','Products','Productprices','Discountmatrixs','Customerproductprices','Customermaterialgroupdiscounts','Shoppingcarts','Dynamicorderrecords']

    request.onerror = function(event) {
      console.log("alaska db could not be opened");
      reject(Error('Error openning idb '));
    };



    request.onupgradeneeded = function(event) {
      const db = request.result;
      // let stores = ['Materialgroups','Products','Productprices','Discountmatrixs','Customerproductprices','Customermaterialgroupdiscounts','Shoppingcarts','Dynamicorderrecords']

      stores.forEach(storeName => {
        if (db.objectStoreNames.contains(storeName)) {
        db.deleteObjectStore(storeName);
        }
      });


      // const newStore = db.createObjectStore(storeName, newStoreOptions);
      let MaterialgroupsStore = db.createObjectStore('Materialgroups', {keyPath: 'Id', autoIncrement: true});
      MaterialgroupsStore.createIndex('MaterialgroupsId', 'MaterialgroupsId', {unique: true});

  let Products = db.createObjectStore('Products', {keyPath: 'Id', autoIncrement: true});
      Products.createIndex('ProductsId', 'ProductsId');

  let Productprices = db.createObjectStore('Productprices', {keyPath: 'Id', autoIncrement: true});
      Productprices.createIndex('ProductpricesId', 'ProductpricesId', {unique: true});
      Productprices.createIndex('ProductId', 'ProductId');
      Productprices.createIndex('ValidFrom', 'ValidFrom');
      Productprices.createIndex('ValidTo', 'ValidTo');


  let Discountmatrixs = db.createObjectStore('Discountmatrixs', {keyPath: 'Id', autoIncrement: true});
      Discountmatrixs.createIndex('DiscountmatrixsId', 'DiscountmatrixsId', {unique: true});

  let Customerproductprices = db.createObjectStore('Customerproductprices', {keyPath: 'Id', autoIncrement: true});
      Customerproductprices.createIndex('CustomerproductpricesId', 'CustomerproductpricesId', {unique: true});
      Customerproductprices.createIndex('ValidFrom', 'ValidFrom');
      Customerproductprices.createIndex('ValidTo', 'ValidTo');

  let Customermaterialgroupdiscounts = db.createObjectStore('Customermaterialgroupdiscounts', {keyPath: 'Id', autoIncrement: true});
      Customermaterialgroupdiscounts.createIndex('CustomermaterialgroupdiscountsId', 'CustomermaterialgroupdiscountsId', {unique: true});
      Customermaterialgroupdiscounts.createIndex('ValidFrom', 'ValidFrom');
      Customermaterialgroupdiscounts.createIndex('ValidTo', 'ValidTo');

  let Shoppingcarts = db.createObjectStore('Shoppingcarts', {keyPath: 'ShoppingcartsId', autoIncrement: false});
      Shoppingcarts.createIndex('ShoppingcartsId', 'ShoppingcartsId');

  let Dynamicorderrecords = db.createObjectStore('Dynamicorderrecords', {keyPath: 'Id', autoIncrement: true});
      Dynamicorderrecords.createIndex('CustomerId', 'CustomerId');
    };

    request.onsuccess = function(event) {
      const db = request.result;
      // db.close();
      resolve(true);
    };


  });
}

createObjectstores_old(){
  return new Promise(
    function(resolve, reject) {

      // IDBDatabase.
      window.indexedDB.deleteDatabase('alaska').onsuccess = function(event) {


    var request = indexedDB.open('alaska');

    request.onerror = function(event) {
      console.log("alaska db could not be opened");
      reject(Error('Error openning idb '));
    };
    request.onsuccess = function(event) {

    };
    request.onupgradeneeded = function(event) {
      var db = request.result;

    let stores = ['Materialgroups','Products','Productprices','Discountmatrixs','Customerproductprices','Customermaterialgroupdiscounts','','']

    let MaterialgroupsStore = db.createObjectStore('Materialgroups', {keyPath: 'Id', autoIncrement: true});
        MaterialgroupsStore.createIndex('MaterialgroupsId', 'MaterialgroupsId', {unique: true});

    let Products = db.createObjectStore('Products', {keyPath: 'Id', autoIncrement: true});
        Products.createIndex('ProductsId', 'ProductsId');

    let Productprices = db.createObjectStore('Productprices', {keyPath: 'Id', autoIncrement: true});
        Productprices.createIndex('ProductpricesId', 'ProductpricesId', {unique: true});
        Productprices.createIndex('ProductId', 'ProductId');
        Productprices.createIndex('ValidFrom', 'ValidFrom');
        Productprices.createIndex('ValidTo', 'ValidTo');


    let Discountmatrixs = db.createObjectStore('Discountmatrixs', {keyPath: 'Id', autoIncrement: true});
        Discountmatrixs.createIndex('DiscountmatrixsId', 'DiscountmatrixsId', {unique: true});

    let Customerproductprices = db.createObjectStore('Customerproductprices', {keyPath: 'Id', autoIncrement: true});
        Customerproductprices.createIndex('CustomerproductpricesId', 'CustomerproductpricesId', {unique: true});
        Customerproductprices.createIndex('ValidFrom', 'ValidFrom');
        Customerproductprices.createIndex('ValidTo', 'ValidTo');

    let Customermaterialgroupdiscounts = db.createObjectStore('Customermaterialgroupdiscounts', {keyPath: 'Id', autoIncrement: true});
        Customermaterialgroupdiscounts.createIndex('CustomermaterialgroupdiscountsId', 'CustomermaterialgroupdiscountsId', {unique: true});
        Customermaterialgroupdiscounts.createIndex('ValidFrom', 'ValidFrom');
        Customermaterialgroupdiscounts.createIndex('ValidTo', 'ValidTo');

        let Shoppingcarts = db.createObjectStore('Shoppingcarts', {keyPath: 'ShoppingcartsId', autoIncrement: false});
        Shoppingcarts.createIndex('ShoppingcartsId', 'ShoppingcartsId');

        let Dynamicorderrecords = db.createObjectStore('Dynamicorderrecords', {keyPath: 'Id', autoIncrement: true});
        Dynamicorderrecords.createIndex('CustomerId', 'CustomerId');


        db.onerror = function(event) {
          reject(Error('Error creating objectstore : ' ));
        };

      request.onsuccess= function(event) {
        resolve(true)
      };
  }

};  //delete database onsuccess

    });

}



  addData(objectStoreName:string, data:any[]){
    return new Promise(
      function(resolve, reject) {
    var request = indexedDB.open('alaska');
    var db: IDBDatabase;
    var objectRequest

      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        return
      };
      request.onsuccess = function(event) {
        db = request.result;
        if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){

          var transaction = db.transaction(objectStoreName, "readwrite");
          var objectStore = transaction.objectStore(objectStoreName);

            data.forEach(element => {
              objectRequest = objectStore.add(element);
            });

              objectRequest.onerror = function(event) {
                reject(Error('Error inserting data in : ' +objectStoreName));
              };
              objectRequest.onsuccess = function(event) {
                resolve(true);
              };

            console.log(data.length+" data added in: "+objectStoreName);
        }
      };
    }
    );}

  idbDataAvailable(): boolean{
    return  this.idbDiscountmatrixs.length > 0 && (this.idbActiveProducts.length > 0 || this.idbAllProducts.length > 0) &&
            this.idbMaterialgroups.length > 0 &&  this.idbProductprices.length > 0 &&
            this.idbCustomerproductprices.length > 0 && this.idbCustomermaterialgroupdiscounts.length > 0 ;
  }

  getAllData(objectStoreName:string, query?:any): any{

    return new Promise(
      function(resolve, reject) {
        var request = indexedDB.open('alaska');
        request.onerror = function(event) {
          reject(Error("Error text"));
        };
        request.onsuccess = function(event) {

            var database      = request.result;
            database.onerror = function(event) {
              reject(Error("Error dbOpen " + objectStoreName));
            };
            if(database.objectStoreNames.length > 0 && database.objectStoreNames.contains(objectStoreName)){
              var transaction   = database.transaction(objectStoreName, "readwrite");
              transaction.onerror = function(event) {
                reject(Error("Error transaction " + objectStoreName));
              };
              var objectStore   = transaction.objectStore(objectStoreName);
              var objectRequest = objectStore.getAll(query);
              objectRequest.onerror = function(event) {
                reject(Error('Error text'));
              };
              objectRequest.onsuccess = function(event) {
                if (objectRequest.result){
                  resolve(objectRequest.result);
                }else{
                  reject(Error('object not found'));
                }
              };
            }




        };
      }
    );
  }

// #################################################################################################

objectStorsHaveRecords():any{
  return new Promise(
    function(resolve, reject) {

      let storeNames = ['Materialgroups','Productprices', 'Products','Discountmatrixs','Customerproductprices', 'Customermaterialgroupdiscounts'];
      var request = indexedDB.open('alaska');
      var db: IDBDatabase;
      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        reject(Error('Error openning idb '));
      };
      request.onsuccess = function(event) {
        let hasRecords: boolean = true
        var database      = request.result;
        var transaction   = database.transaction(storeNames, 'readwrite');
          if(transaction){
            storeNames.forEach(store => {

                  var objectStore   = transaction.objectStore(store);
                  hasRecords = hasRecords == false? false : +objectStore.count(store[objectStore.keyPath.toString()]) > 1;
                  console.log("objectStor: "+store+' has ' + transaction.objectStore(store).count + ' records');
            });
          }else{
            hasRecords = false
          }
        console.log("objectStorsHaveRecords: "+hasRecords.valueOf.toString());
        resolve(hasRecords);
      };
    });
}
// #################################################################################################

getAllIdbData(objectStoreName:string, query?:any): any{

      var request = indexedDB.open('alaska');
      request.onerror = function(event) {
        console.log("error getAllIdbData "+objectStoreName, event);
      };
      request.onsuccess = function(event) {
        var database      = request.result;
        if(database.objectStoreNames.length > 0 && database.objectStoreNames.contains(objectStoreName)){
          var transaction   = database.transaction([objectStoreName], 'readwrite');
          var objectStore   = transaction.objectStore(objectStoreName);
          var objectRequest = objectStore.getAll(query);
          objectRequest.onerror = function(event) {
            console.log("error getAllIdbData "+objectStoreName, event);
          };
          objectRequest.onsuccess = function(event) {
            if (objectRequest.result){
              console.log("getAllIdbData OK "+objectStoreName, event);
            }else{
              console.log("error getAllIdbData "+objectStoreName, event);
            }
          };
        }

      };

}
// #################################################################################################

getAllIdbPromisData(objectStoreName:string): any{
  return new Promise(
    function (resolve, reject) {
  var request = indexedDB.open('alaska');
  var allData: any;
  let returnData: any;
  request.onsuccess = function(event) {
    var database      = request.result;

    database.onerror = function(event) {
      resolve(undefined);
    }
    if(database.objectStoreNames.length > 0 && database.objectStoreNames.contains(objectStoreName)){
        var transaction   = database.transaction(objectStoreName, 'readwrite');
        var objectStore   = transaction.objectStore(objectStoreName);
        allData = objectStore.getAll();

        allData.onerror = function(event) {
          console.log("getAllIdbPromisData "+ objectStoreName, event);
          reject(event);
        };
        allData.onsuccess = function(event) {
          if (allData.result){
            returnData = allData.result;
            resolve(returnData);
        }}
     }

  }
  request.onerror = function(event){
    reject(event);
  }

  });
}
// #################################################################################################
deleteIdbPromiseDataArray(objectStoreName:string, data:any[]){

  return new Promise(
    function (resolve, reject) {
    var request = indexedDB.open('alaska');
    var db: IDBDatabase;
      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        return
      };
      request.onsuccess = function(event) {
        console.log("alaska db opened");
        db = request.result;
        var objectRequest;
        if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
          var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);

            data.forEach(element => {
              objectRequest = objectStore.delete(element[objectStore.keyPath.toString()]);
              });
              objectRequest.onerror = function(event) {
                console.log("deleteIdbData", event);
              };
              objectRequest.onsuccess = function(event) {
                console.log("deleteIdbData OK", event);
                resolve(true);
              };

            console.log(data.length+" data deleted from: "+objectStoreName);
         }

      };
    });



}
// #################################################################################################
insertOrUpdateIdbPromiseDataArray(objectStoreName:string, data:any[]){

  return new Promise(
    function (resolve, reject) {
      var request = indexedDB.open('alaska');
      var db: IDBDatabase;
      var objectRequest

        request.onerror = function(event) {
          console.log("alaska db could not be opened");
          return
        };
        request.onsuccess = function(event) {
          db = request.result;
          if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
            var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);

              data.forEach(element => {
                objectRequest = objectStore.put(element);
              });

                objectRequest.onerror = function(event) {
                  console.log("error addIdbData "+objectStoreName, event);
                };
                objectRequest.onsuccess = function(event) {

                  console.log("addIdbData OK "+objectStoreName, event);
                  resolve(true);
                };
              console.log(data.length+" data added in: "+objectStoreName);
           }
        };

    });


}
// #################################################################################################
addIdbDataArray(objectStoreName:string, data:any[]){

  var request = indexedDB.open('alaska');
  var db: IDBDatabase;
  var objectRequest

    request.onerror = function(event) {
      console.log("alaska db could not be opened");
      return
    };
    request.onsuccess = function(event) {
      db = request.result;
      if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
        var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
          data.forEach(element => {
            objectRequest = objectStore.add(element);
          });
            objectRequest.onerror = function(event) {
              console.log("error addIdbData "+objectStoreName, event);
            };
            objectRequest.onsuccess = function(event) {

              console.log("addIdbData OK "+objectStoreName, event);
            };

          console.log(data.length+" data added in: "+objectStoreName);
       }

    };

}
// #################################################################################################

addIdbData(objectStoreName:string, data:any){

  var request = indexedDB.open('alaska');
  var db: IDBDatabase;
  var objectRequest

    request.onerror = function(event) {
      console.log("alaska db could not be opened");
      return
    };
    request.onsuccess = function(event) {
      db = request.result;
      if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
        var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);

            objectRequest = objectStore.add(data);

            objectRequest.onerror = function(event) {
              console.log("error addIdbData "+objectStoreName, event);
            };
            objectRequest.onsuccess = function(event) {

              console.log("addIdbData OK "+objectStoreName, event);
            };

          console.log(data.length+" data added in: "+objectStoreName);
       }

    };
}

// #################################################################################################

  getIdbDataById(objectStoreName:string, id:number): any{

        var request = indexedDB.open('alaska');
        request.onerror = function(event) {
          console.log("getIdbDataById", event);
        };
        request.onsuccess = function(event) {
          var database      = request.result;
          if(database.objectStoreNames.length > 0 && database.objectStoreNames.contains(objectStoreName)){
            var transaction   = database.transaction([objectStoreName], 'readwrite');
            var objectStore   = transaction.objectStore(objectStoreName);
            var objectRequest = objectStore.get(id);
            objectRequest.onerror = function(event) {
              console.log("getIdbDataById", event);
            };
            objectRequest.onsuccess = function(event) {

              if (objectRequest.result){
                return objectRequest.result;
              }else{
                return undefined;
              }
            };
           }

        };

  }

  // #################################################################################################

  deleteAllData(objectStoreName:string){
    return new Promise(
      function(resolve, reject) {
    var request = indexedDB.open('alaska');
    var db: IDBDatabase;

      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        return
      };
      request.onsuccess = function(event) {
        db = request.result;
        if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
          var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);
          var objectRequest =  objectStore.clear();
          objectRequest.onerror = function(event) {
            reject(Error('Error all Data Delete'));
          };
          objectRequest.onsuccess = function(event) {
            resolve(true);
          };
         }

      };
    });
  }



  activeIdbShoppingCartArray: iShoppingcarts[];

  updateIdbProductsQuantityInput(activehoppingcart?: iShoppingcarts, resetWKProducts?:boolean, resetIdbProducts?:boolean, notify?:boolean):iProducts[]{


    let activeIdbShoppingCartproductIds: number[] = [];

      if(resetIdbProducts){this.idbActiveProducts.forEach(
        x => { if(!activeIdbShoppingCartproductIds.includes(x.ProductsId)) { x.QuantityInput = 0;}  }   );}

      if(activehoppingcart){
        activehoppingcart.Shoppingcartitems.forEach(element => {
          this.idbActiveProducts.find(x => x.ProductsId == element.Product.ProductsId).QuantityInput = resetWKProducts && resetWKProducts == true? 0: element.Product.QuantityInput;
        });
      }

      if(activehoppingcart){activehoppingcart.Shoppingcartitems.forEach(element => { activeIdbShoppingCartproductIds.push(element.Product.ProductsId)   });}

      if(notify){
          this.dataService.setShoppingCartSourceSubject(activehoppingcart);
      }

      return this.idbActiveProducts;
  }

  createIdbDynamicRecords(customer: iCustomers){
    let pulledDynamicOrderRecords: iDynamicorderrecords[] = [];
    if( this.idbActiveProducts.length == 0  || ( this.idbActiveProducts[0] && this.idbActiveProducts[0].ListPrice == 0)){
      // check produkt price, dynamicorderrecord produkt has no price
      console.log('dynamic order product has no price')
      return;
    }

    this.dataService.getData('custom','ping').subscribe(
      (okData) => {
        if(okData == '200'){
     this.dataService.getData('custom','getDynamicOrdersByCustomerId', customer.CustomersId).subscribe(
      (data) => {

        for (const id in data) {
          if (data.hasOwnProperty(id)) {

              let activeProd: iProducts = this.idbActiveProducts.find(x => x.ProductsId == data[id].ProductId);
              let allProd: iProducts = this.idbAllProducts.find(x => x.ProductsId == data[id].ProductId);

              if(activeProd || allProd){
                  let dyn: iDynamicorderrecords = {
                            CustomerId : data[id].CustomerId,
                            CustomerNumber : data[id].CustomerNumber,
                            ProductId : data[id].ProductId,
                            DeliveryDate : data[id].DeliveryDate,
                            Price : data[id].Price,
                            Description : data[id].Description,
                            Quantity : data[id].Quantity,
                            MaterialGroupId : data[id].MaterialGroupId,
                            MaterialGroupName : data[id].MaterialGroupName,
                            CreateDate : data[id].CreateDate,
                            ModifyDate  : data[id].ModifyDate,
                            RowIndex: data[id].RowIndex,
                            detailExpandState: 'collapsed',
                            Product: activeProd ? activeProd : allProd
                          };
                  if(dyn){
                    pulledDynamicOrderRecords.push(dyn)
                  }
              }
          }}
            if(pulledDynamicOrderRecords && pulledDynamicOrderRecords.length > 0){
              pulledDynamicOrderRecords = pulledDynamicOrderRecords.sort((a, b) =>  a.MaterialGroupId < b.MaterialGroupId ? -1 : 1);

              this.getAllIdbPromisData('Dynamicorderrecords').then(
                (allDynamicOrderRecords) =>{
                  if(allDynamicOrderRecords){

                    let existingCustomerDynamicOrderRecords: iDynamicorderrecords[] =  allDynamicOrderRecords.filter(x => x.CustomerId == customer.CustomersId) ;

                    if(existingCustomerDynamicOrderRecords && existingCustomerDynamicOrderRecords.length > 0 ){
                      //delete existing + add new
                      this.deleteIdbPromiseDataArray('Dynamicorderrecords', existingCustomerDynamicOrderRecords).then(
                        (result1) =>{
                          this.insertOrUpdateIdbPromiseDataArray('Dynamicorderrecords', pulledDynamicOrderRecords).then(

                            (result2 ) => {

                              let selectedCustomer: iCustomers = JSON.parse(localStorage.getItem('selectedCustomer'));
                              if(customer.CustomersId == selectedCustomer.CustomersId){ this.dataService.setDynamicOrderRecordsSourceSubject(pulledDynamicOrderRecords); }
                              this.snackbarService.snackbarAction(pulledDynamicOrderRecords.length + ' Positionen von kunde '+customer.CustomerNumber+'  aktualiziert! ','ok');

                            });

                        }
                      );
                    }
                    else{
                      this.insertOrUpdateIdbPromiseDataArray('Dynamicorderrecords', pulledDynamicOrderRecords).then(
                        (result3) => {

                          let selectedCustomer: iCustomers = JSON.parse(localStorage.getItem('selectedCustomer'));
                          if(customer.CustomersId == selectedCustomer.CustomersId){ this.dataService.setDynamicOrderRecordsSourceSubject(pulledDynamicOrderRecords); }
                          this.snackbarService.snackbarAction(pulledDynamicOrderRecords.length + ' Positionen von kunde '+customer.CustomerNumber+'  hinzugefügt! ','ok');
                        });
                    }

                  }

                }
              );

            }

      });
        }else{
          this.snackbarService.snackbarAction('Sie sind gerade nicht online, Coolkauf arbeitet mit bereits gespeicherten Ordersätzen weiter! ','ok');
        }
      }
      );




  }

  getIdbPromisDynamicOrderRecords(customer: iCustomers):any{

    let dynamicOrderRecords: iDynamicorderrecords[] = [];

    return new Promise(
      function (resolve, reject) {
    var request = indexedDB.open('alaska');
    let returnData: any;
    let dynamicOrderRecords: iDynamicorderrecords[] = [];

    request.onsuccess = function(event) {
       request.onupgradeneeded = function(event) {    }
      var database      =  request.result;
      if(database.objectStoreNames.length > 0 && database.objectStoreNames.contains('Dynamicorderrecords')){
        var transaction   = database.transaction('Dynamicorderrecords','readwrite');
        var objectStore   = transaction.objectStore('Dynamicorderrecords');
        var allData = objectStore.getAll();

        allData.onerror = function(event) {
          console.log("getAllIdbPromisData "+ 'Dynamicorderrecords', event);
          reject(event);
        };
        allData.onsuccess = function(event) {
          if (allData.result){
            dynamicOrderRecords = allData.result;
            let  existingCustomerDynamicOrderRecords: iDynamicorderrecords[] =  dynamicOrderRecords.filter(x => x.CustomerId == customer.CustomersId) ;
            resolve(existingCustomerDynamicOrderRecords);
          }
        }
        allData.onerror = function(event) {
          dynamicOrderRecords = [];
          resolve(dynamicOrderRecords);
        }
       }


    }
  }
    );}

  removeIdbDynamicRecords(customer: iCustomers){
      let pulledDynamicOrderRecords: iDynamicorderrecords[] = [];

      this.getAllIdbPromisData('Dynamicorderrecords').then(
        (allDynamicOrderRecords) =>{
          if(allDynamicOrderRecords){

            let existingCustomerDynamicOrderRecords: iDynamicorderrecords[] =  allDynamicOrderRecords.filter(x => x.CustomerId == customer.CustomersId) ;

            if(existingCustomerDynamicOrderRecords && existingCustomerDynamicOrderRecords.length > 0 ){
              //delete existing + add new
              this.deleteIdbPromiseDataArray('Dynamicorderrecords', existingCustomerDynamicOrderRecords).then(
                (result1) =>{
                  this.snackbarService.snackbarAction(existingCustomerDynamicOrderRecords.length + ' ordersätze von kunde '+customer.CustomerNumber+' gelöscht! ','ok');
                      let selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'));
                      if(customer.CustomersId == selectedCustomer.customerId){ this.dataService.setDynamicOrderRecordsSourceSubject(pulledDynamicOrderRecords); }

                }
              );
            }
            else{
              this.snackbarService.snackbarAction('Kein datensatz gefunden für kunde '+ customer.CustomerNumber,'ok');
            }

          }

        }
      );

  }

  // #################################################################################################

  updateIdbData(objectStoreName:string, activeShoppingcart: iShoppingcarts){

    var request = indexedDB.open('alaska');
    var db: IDBDatabase;

      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        return
      };
      request.onsuccess = function(event) {
        db = request.result;
        if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
         var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);

            var objectRequest = objectStore.put(activeShoppingcart);
            objectRequest.onerror = function(event) {
              console.log("updateIdbData", event);
            };
            objectRequest.onsuccess = function(event) {
              console.log("updateIdbData OK", event);
            };
        }


      };

  }
// #################################################################################################

  deleteIdbData(objectStoreName:string, data:any[]){

    var request = indexedDB.open('alaska');
    var db: IDBDatabase;
      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        return
      };
      request.onsuccess = function(event) {
        console.log("alaska db opened");
        db = request.result;
        if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
         var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);

          data.forEach(element => {
            var objectRequest = objectStore.delete(element[objectStore.keyPath.toString()]);
            objectRequest.onerror = function(event) {
              console.log("deleteIdbData", event);
            };
            objectRequest.onsuccess = function(event) {
              console.log("deleteIdbData OK", event);
            };
          });
          console.log(data.length+" data deleted from: "+objectStoreName);
        }

      };

  }

// #################################################################################################

  deleteIdbDataById(objectStoreName:string, id:number){

    var request = indexedDB.open('alaska');
    var db: IDBDatabase;

      request.onerror = function(event) {
        console.log("alaska db could not be opened");
        return
      };
      request.onsuccess = function(event) {
        console.log("alaska db opened");
        db = request.result;
        if(db.objectStoreNames.length > 0 && db.objectStoreNames.contains(objectStoreName)){
          var objectStore = db.transaction(objectStoreName, "readwrite").objectStore(objectStoreName);

          var objectRequest =  objectStore.delete(id);
          objectRequest.onerror = function(event) {
            console.log("deleteIdbDataById", event);
          };
          objectRequest.onsuccess = function(event) {
            console.log("deleteIdbDataById OK", event);
          };
            console.log(" data with id: "+id+" deleted from: "+objectStoreName);
         }

      };

  }
// #################################################################################################

deleteAlaskaIdb():any{
var req = indexedDB.deleteDatabase('alaska');
req.onsuccess = function () {
    console.log("Deleted database successfully");
};
req.onerror = function () {
    console.log("Couldn't delete database");
};
req.onblocked = function () {
    console.log("Couldn't delete database due to the operation being blocked");
};

  }

// #################################################################################################

getProductIdsOfDynamicOrders(materialGroups: iMaterialgroups[]):number[]{
  let productIds:number[] =[];
  if(materialGroups){
    materialGroups.forEach(materialGroup => {
      materialGroup.DynamicOrders.forEach(x => {
          if(!productIds.includes(x.ProductId)){
            productIds.push(x.ProductId)
      }});
      });
  }
return productIds;
}

setDynamicOrdersCurrentPrice(materialGroups: iMaterialgroups[]):iMaterialgroups[]{
  if(materialGroups && materialGroups.length){
    materialGroups.forEach(materialGroup => {
      materialGroup.DynamicOrders.forEach(x =>
        {
          if(this.idbProductprices){

            if(!this.dataService.hasRoles([5])){
              let customeriProductprices:iProductprices = this.idbProductprices.find(y => y.ProductId == x.ProductId && this.toGMT(y.ValidFrom)  <= this.toGMT(x.DeliveryDate)  && this.toGMT(x.DeliveryDate)   <= this.toGMT(y.ValidTo) );
              if(customeriProductprices){
                x.Price = +customeriProductprices.Price;
              }
            }

          }

        });
      });

  return materialGroups;
    }
}
// populateCustomerData(customer: iCustomers, recreatIdb: boolean){
populateCustomerData(customer: iCustomers, deliveryDate:Date, recreatIdb?: boolean){



  if(this.dataService.hasRoles([5]))
  {
    this.selectedCustomer = customer? customer: this.selectedCustomer;

    let productBag: iProductParameterBag = {
      ProductsId: null,
      CustomersId: this.selectedCustomer? this.selectedCustomer.CustomersId:null,
      ProductCategoryIds: this.selectedProductCategory? [this.selectedProductCategory.ProductcategoriesId]:null,
      DeliveryDate: deliveryDate? new Date(this.pipe.transform(deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE')):  new Date(this.pipe.transform(new Date(new Date()),'yyyy-MM-dd', 'GMT+0200', 'de-DE')),

      PriceGroup: this.selectedCustomer? this.selectedCustomer.PriceGroup:null,
      Uid: this.dataService.getLoggedinUser() && this.dataService.getLoggedinUser().UsersId? this.dataService.getLoggedinUser().UsersId: 0,
      Other: ''
    }

    let parameterBag:string = JSON.stringify(productBag);

          this.dataService.getDataByQuerystring('custom','getProductsWithPriceByCustomerByParameters', parameterBag).subscribe(
          (data) => {
            this.idbAllProducts = [];

            for (const id in data) {
              if (data.hasOwnProperty(id)) {
                let product: iProducts =
                {
                  ProductsId                               : data[id].ProductsId,
                  BackLogId                                : data[id].BackLogId ,
                  ProductNumber                            : data[id].ProductNumber ,
                  DescRow1                                 : data[id].DescRow1 ,
                  DescRow2                                 : data[id].DescRow2  ,
                  MaterialGroupId                          : data[id].MaterialGroupId ,
                  TaxKeyId                                 : data[id].TaxKeyId  ,
                  AveragePurchasePrice                     : data[id].AveragePurchasePrice,
                  LastPurchasePrice                        : data[id].LastPurchasePrice,
                  QuantityUnitId                           : data[id].QuantityUnitId ,
                  DiscountGroup                            : data[id].DiscountGroup ,
                  Discountable                             : data[id].Discountable ,
                  Description                              : data[id].Description ,
                  ProcessCode                              : data[id].ProcessCode ,
                  ProductInfo                              : data[id].ProductInfo ,
                  PartialPackageChargeable                 : data[id].PartialPackageChargeable ,
                  StockValue                               : data[id].StockValue  ,
                  DispositionStockValue                    : data[id].DispositionStockValue ,
                  OpenOrdersStockValue                     : data[id].OpenOrdersStockValue ,
                  SupplierDeliveryStockValue               : data[id].SupplierDeliveryStockValue ,
                  SpecificationLink                        : data[id].SpecificationLink ,
                  Components                               : data[id].Components ,
                  CreateDate                               : data[id].CreateDate ,
                  ModifyDate                               : data[id].ModifyDate  ,
                  ProductcategoriesId                      : data[id].ProductcategoriesId ,
                  QuantityInput                            : data[id].QuantityInput  ,
                  TaxKeysDescription                       : data[id].TaxKeysDescription ,
                  TaxKeysTaxRate                           : data[id].TaxKeysTaxRate  ,
                  MaterialgroupsDescription                : data[id].MaterialgroupsDescription ,
                  QuantityUnitsShortDesc                   : data[id].QuantityUnitsShortDesc ,
                  QuantityUnitsPartialPackageCharge        : data[id].QuantityUnitsPartialPackageCharge,
                  CustomerPrice                            : data[id].CustomerPrice ,
                  ListPrice                                : data[id].ListPrice ,
                  SpecialPrice                             : data[id].SpecialPrice  ,
                  NegotiationPrice                         : data[id].NegotiationPrice  ,
                  CustomermaterialgroupDiscount            : data[id].CustomermaterialgroupDiscount ,
                  DiscountMatrixsDiscount                  : data[id].DiscountMatrixsDiscount ,
                  DiscountPrice                            : data[id].DiscountPrice ,
                  CustomersBlanketDiscount                 : data[id].CustomersBlanketDiscount ,
                  CustomerproductpricesPrice               : data[id].CustomerproductpricesPrice,
                  PriceType                                : data[id].PriceType ,
                  MinSalesPrice                            : data[id].MinSalesPrice  ,
                  ExtraMinSalesPrice                       : data[id].ExtraMinSalesPrice ,
                  PriceTooltip                             : data[id].PriceTooltip ,
                  Discount                                 : data[id].Discount ,
                  Price                                    : data[id].Price  ,
                  CustomersMaterialGroupDiscount           : data[id].CustomersMaterialGroupDiscount,
                  ProductpricesPriceGroup                   : data[id].ProductpricesPriceGroup,
                  ProductpricesPrice                        : data[id].ProductpricesPrice,
                  ProductpricesSpecialDiscountPrice           : data[id].ProductpricesSpecialDiscountPrice,
                  ProductpricesValidFrom                      : data[id].ProductpricesValidFrom,
                  ProductpricesValidTo                        : data[id].ProductpricesValidTo,
                  CustomerproductpricesValidFrom              : data[id].CustomerproductpricesValidFrom,
                  CustomerproductpricesValidTo                : data[id].CustomerproductpricesValidTo,

                }
                this.idbAllProducts.push(product);
              }
            }


              this.idbActiveProducts = this.getUniqueProducts(this.idbAllProducts,this.selectedCustomer)

              if(this.idbActiveProducts && this.idbActiveProducts.length > 0){

                    this.deleteAllData('Products').then(
                      (store) => {
                        this.addIdbData('Products', this.idbActiveProducts);

                        this.dataService.setAllProductsSubject(this.idbAllProducts);
                        this.dataService.setActiveProductsSubject(this.idbActiveProducts);
                        this.createIdbDynamicRecords(customer);
                      }
                    );



              }

            }, err =>{
            console.log("error: getProductsWithPriceByCustomerByParameters", err)
          }) ;

  }
}

populateData(selectedCustomer?: iCustomers, deliveryDate?: Date, selectedProductCategory?:iProductcategories, specialDiscount?:boolean) {

    this.selectedCustomer = selectedCustomer;
    this.deliveryDate = deliveryDate;
    this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    this.selectedProductCategory = selectedProductCategory;
    this.specialDiscount = specialDiscount;

    if (  localStorage.getItem('setIdb') == null ||  localStorage.getItem('setIdb') == '1'   ) {

      let counter = 0;
      this.dialog.open(InprogressDialogComponent, {width:this.inprogressWindowWidth, maxHeight: '90%',  disableClose:true, data: {title: 'info', type:'warning', msg: 'Daten werden geladen ....', noLabel: '', yesLabel: ''}});

      // this.createObjectstores().then((data) => {  }, (err) => { console.log('error: populateData');  });


        this.idbSources.forEach((element) => {
          this.dataService.getData(element[1]).subscribe(
            (data) => {
              if(data != undefined && element[0] != ''){

                this.addData(element[0], data).then((data) => {
                  counter++;

                  if (counter == this.idbSources.length) {

                    if(this.dataService.hasRoles([1,2,3,4])){

                      if(selectedCustomer == undefined){
                          this.snackbarService.snackbarAction('bitte ein Kunde wählen');
                      }

                          this.getCustomerProductPricesByDeliverydate(this.idbAllProducts,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);

                    }

                    localStorage.setItem('setIdb', '0');
                    this.dialog.closeAll();
                  }
                });
              }
            },
            (err) => {
              console.log('error: populateData');
            }
          );
        });
    //createObjectStore


    } else {

      if(this.idbDataAvailable()){

         this.getCustomerProductPricesByDeliverydate(this.idbAllProducts,this.selectedCustomer,this.deliveryDate, this.selectedProductCategory);
      }


    }
  }

getCustomerSingleProductPricesByDeliverydate(product: iProducts,  selectedCustomer: iCustomers,  deliveryDate: Date, selectedProductCategory?:iProductcategories):iProducts{

  let  productItem: iProducts = this.setListPrice(selectedCustomer, product, deliveryDate);
  if(productItem){
    let customermaterialgroupdiscountsOfDeliverydate: iCustomermaterialgroupdiscounts[] = this.idbCustomermaterialgroupdiscounts.filter(  (x) =>  x.CustomerId == selectedCustomer.CustomersId &&
    this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate)  &&  this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  ).sort((a, b) => +a.ValidFrom - +b.ValidFrom);

    let CustomerproductpricesOfDeliverydate: iCustomerproductprices[] = this.idbCustomerproductprices.filter( (x) => x.CustomerId == selectedCustomer.CustomersId &&
    this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate)  &&  this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  );

    if(selectedCustomer.PriceGroup != 4){


      productItem.CustomersBlanketDiscount = selectedCustomer.BlanketDiscount;

      productItem = this.getCustomerMaterialGroupDiscount(selectedCustomer, productItem, deliveryDate, customermaterialgroupdiscountsOfDeliverydate );

      productItem = this.getDiscountMatrixDiscount(selectedCustomer, productItem);

      productItem = this.getBlanketDiscountProductPrice(selectedCustomer,	productItem,deliveryDate);

      productItem = this.setSpecialDiscountPrice( productItem, deliveryDate);

      productItem = this.getCustomerProductPrice( selectedCustomer, productItem, deliveryDate, CustomerproductpricesOfDeliverydate );
  }


    // this.setSpecialDiscountPrice( productItem, deliveryDate);

    // let customermaterialgroupdiscountsOfDeliverydate: iCustomermaterialgroupdiscounts[] = this.idbCustomermaterialgroupdiscounts.filter(  (x) =>  x.CustomerId == selectedCustomer.CustomersId &&
    // this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate) &&   this.toGMT(deliveryDate) <= this.toGMT(x.ValidTo) ).sort((a, b) => +a.ValidFrom - +b.ValidFrom);


    // let CustomerproductpricesOfDeliverydate: iCustomerproductprices[] = this.idbCustomerproductprices.filter( (x) => x.CustomerId == selectedCustomer.CustomersId &&
    // this.toGMT(x.ValidFrom)  <= this.toGMT(deliveryDate)  && this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  );

    // productItem.CustomersBlanketDiscount = selectedCustomer.BlanketDiscount;

    // if ( this.getCustomerMaterialGroupDiscount(selectedCustomer, productItem, deliveryDate, customermaterialgroupdiscountsOfDeliverydate ).Discount == 0  ) {
    //   this.getDiscountMatrixDiscount(selectedCustomer, productItem);
    // }

    // if ( this.getCustomerProductPrice( selectedCustomer, productItem, deliveryDate, CustomerproductpricesOfDeliverydate ).CustomerPrice <= 0 ) {

    //   if (productItem.SpecialPrice<= 0	) {
    //     this.getBlanketDiscountProductPrice(selectedCustomer,	productItem,deliveryDate);
    //   }
    // }

  }
  return productItem;
}

getProductsWithProcessCode98( products: iProducts[],  selectedCustomer: iCustomers,  deliveryDate: Date) {
  let product: iProducts;
 this.productsWithProcessCode98 = [];

 if(selectedCustomer && deliveryDate){
    for (const id in products) {
      if ( products.hasOwnProperty(id)  && products[id].ProcessCode == 98) {
        product = products[id];
        product.Price = 0
        product.PriceType =  'L'; 0

        if (product != undefined){this.productsWithProcessCode98.push(product);}
      }
    }
       if(this.productsWithProcessCode98 && this.productsWithProcessCode98.length > 0)
    {
      this.dataService.setProductsWithProcessCode98Subject(this.productsWithProcessCode98);
    }
 }


}

setCustomerProductPricesByDeliverydate(products: iProducts[],  selectedCustomer: iCustomers,  deliveryDate: Date, selectedProductCategory?:iProductcategories):any {

  if(products.length > 0){

    this.deliveryDate =   deliveryDate != undefined ? deliveryDate : new Date(new Date());
    this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    let PriceGroup: number = selectedCustomer? selectedCustomer.PriceGroup: 1;

    if (selectedCustomer) {

      products = products.filter(x => x.ProcessCode != 98);

      let customermaterialgroupdiscountsOfDeliverydate: iCustomermaterialgroupdiscounts[] = this.idbCustomermaterialgroupdiscounts.filter(  (x) =>  x.CustomerId == selectedCustomer.CustomersId &&
      this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate)  &&  this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  ).sort((a, b) => +a.ValidFrom - +b.ValidFrom);

      let CustomerproductpricesOfDeliverydate: iCustomerproductprices[] = this.idbCustomerproductprices.filter( (x) => x.CustomerId == selectedCustomer.CustomersId &&
      this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate)  &&  this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  );

      products.forEach(element => {

        if(this.setListPrice(selectedCustomer, element, deliveryDate) == undefined){
          products = products.filter(x => x.ProductsId != element.ProductsId); // no price for a delivery date
        }
        else{

          if(PriceGroup != 4){


              element.CustomersBlanketDiscount = selectedCustomer.BlanketDiscount;

              element = this.getCustomerMaterialGroupDiscount(selectedCustomer, element, deliveryDate, customermaterialgroupdiscountsOfDeliverydate );

              element = this.getDiscountMatrixDiscount(selectedCustomer, element);

              element = this.getBlanketDiscountProductPrice(selectedCustomer,	element,deliveryDate);

              element = this.setSpecialDiscountPrice( element, deliveryDate);

              element = this.getCustomerProductPrice( selectedCustomer, element, deliveryDate, CustomerproductpricesOfDeliverydate );
          }

          }
      });

      this.idbActiveProducts = products;
      this.idbActiveProducts = this.getUniqueProducts(this.idbActiveProducts, selectedCustomer)

      this.idbActiveProducts = this.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(selectedCustomer, false);


    }else{
      products = [];
    }


    if(this.idbActiveProducts.length > 0  )
    {
      this.dataService.setActiveProductsSubject(this.idbActiveProducts);
    }
    if(this.idbAllProducts.length > 0 )
    {
      this.dataService.setAllProductsSubject(this.idbAllProducts);
    }
  }

}

getCustomerProductPricesByDeliverydate(products: iProducts[],  selectedCustomer: iCustomers,  deliveryDate: Date, selectedProductCategory?:iProductcategories):any {

  if(!products || products.length < 1 )
  {
    this.getAllData('Discountmatrixs').then( (Discountmatrixs) => {
      this.idbDiscountmatrixs = Discountmatrixs }, (err) => {
      console.log('error getDiscountmatrixs');})
    .then( () => { this.getAllData('Materialgroups').then( (Materialgroups) => {
      this.idbMaterialgroups = Materialgroups }, (err) => {
      console.log('error getMaterialgroups');}) })
    .then( () => { this.getAllData('Customermaterialgroupdiscounts').then( (Customermaterialgroupdiscounts) => {
      this.idbCustomermaterialgroupdiscounts = Customermaterialgroupdiscounts }, (err) => {
      console.log('error getCustomermaterialgroupdiscounts');}) })
    .then( () => { this.getAllData('Customerproductprices').then( (Customerproductprices) => {
      this.idbCustomerproductprices = Customerproductprices }, (err) => {
      console.log('error getCustomerproductprices');}) })
    .then( () => { this.getAllData('Productprices').then( (Productprices) => {
      this.idbProductprices = Productprices }, (err) => {
      console.log('error getProductprices');}) })
    .then( () => { this.getAllData('Products').then( (AllProducts) => {
      this.idbAllProducts = AllProducts;
      this.idbActiveProducts = this.idbAllProducts.filter(x => x.ProcessCode != 98);

      this.deliveryDate =   deliveryDate != undefined ? deliveryDate : new Date(new Date());
      this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
      let PriceGroup: number = selectedCustomer? selectedCustomer.PriceGroup: 1;

    if (selectedCustomer) {

    let customermaterialgroupdiscountsOfDeliverydate: iCustomermaterialgroupdiscounts[] = this.idbCustomermaterialgroupdiscounts.filter(  (x) =>  x.CustomerId == selectedCustomer.CustomersId &&
    this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate)  &&  this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  ).sort((a, b) => +a.ValidFrom - +b.ValidFrom);

    let CustomerproductpricesOfDeliverydate: iCustomerproductprices[] = this.idbCustomerproductprices.filter( (x) => x.CustomerId == selectedCustomer.CustomersId &&
    this.toGMT(x.ValidFrom)  <= this.toGMT(deliveryDate)  &&  this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  );

        this.idbActiveProducts.forEach(element => {

        if(this.setListPrice(selectedCustomer, element, deliveryDate) == undefined){
          this.idbActiveProducts = this.idbActiveProducts.filter(x => x.ProductsId != element.ProductsId);
        }
        else{

          if(PriceGroup != 4){


              element.CustomersBlanketDiscount = selectedCustomer.BlanketDiscount;

              element = this.getCustomerMaterialGroupDiscount(selectedCustomer, element, deliveryDate, customermaterialgroupdiscountsOfDeliverydate );

              element = this.getDiscountMatrixDiscount(selectedCustomer, element);

              element = this.getBlanketDiscountProductPrice(selectedCustomer,	element,deliveryDate);

              element = this.setSpecialDiscountPrice( element, deliveryDate);

              element = this.getCustomerProductPrice( selectedCustomer, element, deliveryDate, CustomerproductpricesOfDeliverydate );
          }

          }
      });

            if(this.idbActiveProducts.length > 0 ){

              this.idbActiveProducts = this.getUniqueProducts(this.idbActiveProducts, selectedCustomer)

              this.idbActiveProducts = this.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(selectedCustomer, false);
            }

            if(this.idbActiveProducts.length > 0 )
            {
              this.dataService.setActiveProductsSubject(this.idbActiveProducts);
            }
            if(this.idbAllProducts.length > 0 )
            {
              this.dataService.setAllProductsSubject(this.idbAllProducts);
            }
    }

    }, (err) => {
      console.log('error getProducts');}) })
    .then( () => {
      // do nothing

    });
  }
  else{

    this.deliveryDate =   deliveryDate != undefined ? deliveryDate : new Date(new Date());
    this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    let PriceGroup: number = selectedCustomer? selectedCustomer.PriceGroup: 1;

    if (selectedCustomer) {

      products = products.filter(x => x.ProcessCode != 98);

      let customermaterialgroupdiscountsOfDeliverydate: iCustomermaterialgroupdiscounts[] = this.idbCustomermaterialgroupdiscounts.filter(  (x) =>  x.CustomerId == selectedCustomer.CustomersId &&
      this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate)  &&  this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo)  ).sort((a, b) => +a.ValidFrom - +b.ValidFrom);

      let CustomerproductpricesOfDeliverydate: iCustomerproductprices[] = this.idbCustomerproductprices.filter( (x) => x.CustomerId == selectedCustomer.CustomersId &&
      this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate)  && this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo) );

      products.forEach(element => {

        if(this.setListPrice(selectedCustomer, element, deliveryDate) == undefined){
          products = products.filter(x => x.ProductsId != element.ProductsId); // no price for a delivery date
        }
        else{

          if(PriceGroup != 4){


              element.CustomersBlanketDiscount = selectedCustomer.BlanketDiscount;

              element = this.getCustomerMaterialGroupDiscount(selectedCustomer, element, deliveryDate, customermaterialgroupdiscountsOfDeliverydate );

              element = this.getDiscountMatrixDiscount(selectedCustomer, element);

              element = this.getBlanketDiscountProductPrice(selectedCustomer,	element,deliveryDate);

              element = this.setSpecialDiscountPrice( element, deliveryDate);

              element = this.getCustomerProductPrice( selectedCustomer, element, deliveryDate, CustomerproductpricesOfDeliverydate );
          }

          }
      });

      this.idbActiveProducts = products;
      this.idbActiveProducts = this.getUniqueProducts(this.idbActiveProducts, selectedCustomer)

      this.idbActiveProducts = this.updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(selectedCustomer, false);



    }else{
      products = [];
    }


    if(this.idbActiveProducts.length > 0  )
    {
      this.dataService.setActiveProductsSubject(this.idbActiveProducts);
    }
    if(this.idbAllProducts.length > 0 )
    {
      this.dataService.setAllProductsSubject(this.idbAllProducts);
    }


  }


}

  getUniqueProducts(products: iProducts[], customer: iCustomers):iProducts[]{
    let uniqueProducts: iProducts[] = [];
    let uniqeid: number[] = [];
    products.forEach(element => {
      if(!uniqeid.includes(element.ProductsId )){
        uniqueProducts.push(element);uniqeid.push(element.ProductsId)
      }
      else if(uniqeid.includes(element.ProductsId )){
        if(element.ProductpricesPriceGroup == 10){
          uniqueProducts.filter(x => x.ProductsId != element.ProductsId ).push(element);
          uniqeid.filter(x => x != element.ProductsId ).push(element.ProductsId);
        }
      }
    });

   return uniqueProducts;
}

getQuantityInputFromShoppingCardItems(products: iProducts[], customer:iCustomers): iProducts[]{
  let shopingCartItemsArray: iShoppingcartitems[] = customer? JSON.parse(localStorage.getItem(customer.CustomersId +'_shoppingCartItemsArray')): undefined;
  if(shopingCartItemsArray){
    shopingCartItemsArray.forEach(shopingCartItem => {
      if(products.find(x => x.ProductsId == shopingCartItem.ProductId ) != undefined){
        products.find(x => x.ProductsId == shopingCartItem.ProductId ).QuantityInput = shopingCartItem.Quantity;
      }
    });
  }
    return products;
}

getCustomerMaterialGroupDiscount(  customer: iCustomers,  product: iProducts,  deliveryDate: Date,  customermaterialgroupdiscountsOfDeliverydate: iCustomermaterialgroupdiscounts[]): iProducts {

  if(customer){
    if (customermaterialgroupdiscountsOfDeliverydate) {
      let discount: number = 0;
      let customerMaterialDiscount = customermaterialgroupdiscountsOfDeliverydate.find( (x) => x.MaterialGroupId == product.MaterialGroupId );
      if (customerMaterialDiscount) {
        product.CustomersMaterialGroupDiscount =  customerMaterialDiscount.Discount;
        product.Discount = customerMaterialDiscount.Discount;
        product.PriceTooltip = 'Preis mit Warengruppenrabatt';
      }
    }
  }

  return product;
}


getDiscountMatrixDiscount(  selectedCustomer: iCustomers,  product: iProducts): iProducts {
  if(selectedCustomer){
    if(product.CustomersMaterialGroupDiscount == 0){
            let discountMatrixDiscount: iDiscountmatrixs = this.idbDiscountmatrixs.find( (x) => x.CustomerDiscountGroup == selectedCustomer.DiscountGroup && x.ProductDiscountGroup == product.DiscountGroup );
      if (discountMatrixDiscount && product.CustomersMaterialGroupDiscount == 0) {
        product.DiscountMatrixsDiscount = discountMatrixDiscount.Discount;
        product.Discount = discountMatrixDiscount.Discount;
        product.PriceTooltip = 'Preis mit Rabattgruppenrabatt';
      }
    }
}

  return product;
}

getCustomerProductPrice(  customer: iCustomers,  product: iProducts,  deliveryDate: Date,  CustomerproductpricesOfDeliverydate: iCustomerproductprices[]): iProducts {

    let customerProductPrice: iCustomerproductprices = CustomerproductpricesOfDeliverydate.find( (x) => x.ProductId == product.ProductsId && x.CustomerId == customer.CustomersId && this.toGMT(x.ValidFrom)  <= this.toGMT(deliveryDate)  && this.toGMT(deliveryDate)  <= this.toGMT(x.ValidTo) );

      if (customerProductPrice && customerProductPrice.Price > 0 ) {
      product.CustomerPrice = customerProductPrice.Price;
      product.Price = customerProductPrice.Price;

      product.Discount = 0;
      product.PriceType = 'I';
      product.PriceTooltip = "Individualpreis";
      product.CustomerproductpricesValidFrom = this.toGMT(customerProductPrice.ValidFrom) ;
      product.CustomerproductpricesValidTo = this.toGMT(customerProductPrice.ValidTo) ;

    }


  return product;
}

getBlanketDiscountProductPrice(  customer: iCustomers,  product: iProducts,  deliveryDate: Date): iProducts {
  if (customer) {

  product.Discountable =  product.Discountable == null ? 0 : product.Discountable;
  if (product.Discountable && product.Discountable == -1) {
    product.Discount = product.Discount == undefined ? 0 : product.Discount;
    product.Discount = product.Discount + product.CustomersBlanketDiscount
    product.PriceType = 'L';

    product.DiscountPrice = +(  product.Price *  ((100 - product.Discount) / 100) ).toFixed(2);
    product.MinSalesPrice = +(product.ListPrice * 0.45).toFixed(2);
    product.PriceTooltip = 'Preis mit Warengruppenrabatt';


  }
}
  return product;
}

setListPrice( customer: iCustomers, products: iProducts, deliveryDate: Date): iProducts {

  let modifiedProduct: iProducts ;

  if (customer != undefined) {

    let productPriceOfDeliverydate: iProductprices = this.idbProductprices.find( (y) => y.ProductId == products.ProductsId && y.PriceGroup == customer.PriceGroup && this.toGMT(y.ValidFrom) <= this.toGMT(deliveryDate)  && this.toGMT(deliveryDate)  <= this.toGMT(y.ValidTo) );
    if (productPriceOfDeliverydate && productPriceOfDeliverydate.Price > 0) {
      products.ListPrice = productPriceOfDeliverydate.Price;
      products.ProductpricesSpecialDiscountPrice = 0;
      products.ProductpricesPriceGroup = productPriceOfDeliverydate.PriceGroup;
      products.Price = productPriceOfDeliverydate.Price;
      products.PriceType = customer.PriceGroup == 4? 'B' : 'L'; // Listprice
      products.MinSalesPrice = products.AveragePurchasePrice + 1.17;
      products.ExtraMinSalesPrice = products.AveragePurchasePrice + 1.15;
      modifiedProduct = products;
    }
      else{
    modifiedProduct = undefined;
    }
  }


  return modifiedProduct;
}

setSpecialDiscountPrice( products: iProducts, deliveryDate: Date): iProducts {

  // if(products.ProductsId == 2028){
  //   let pppp0 = this.idbProductprices.filter(x => x.ProductId == 2028)
  //     let pppp = this.idbProductprices.filter(x => x.ProductId == 2028 && x.PriceGroup == 10 && this.toGMT(x.ValidFrom) <= this.toGMT(deliveryDate) && this.toGMT(deliveryDate) <= this.toGMT(x.ValidTo))
  // }

    let productPriceOfDeliverydate: iProductprices = this.idbProductprices.find(
      (y) =>  y.ProductId == products.ProductsId && y.PriceGroup == 10 && this.toGMT(y.ValidFrom)  <= this.toGMT(deliveryDate)  && this.toGMT(deliveryDate)  <= this.toGMT(y.ValidTo)  );
    if (productPriceOfDeliverydate && productPriceOfDeliverydate.Price > 0) {
      products.DiscountPrice = productPriceOfDeliverydate.Price;
      products.ProductpricesSpecialDiscountPrice = productPriceOfDeliverydate.Price;
      products.ProductpricesPriceGroup = 10;
      products.PriceType = 'S'; // Sonderangebot
      products.Price = productPriceOfDeliverydate.Price;
      products.ProductpricesValidTo = productPriceOfDeliverydate.ValidTo;


    }


  return products;
}

//####################################################################

importAllIdbData(selectedCustomer?: iCustomers,  deliveryDate?: Date) {
  this.selectedCustomer = selectedCustomer? selectedCustomer : this.selectedCustomer ;
  this.deliveryDate = deliveryDate? deliveryDate : this.deliveryDate;
  this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

   this.getIdbDiscountMatrix();

}
getIdbDiscountMatrix() {
  this.getAllData('Discountmatrixs').then((data) => {
    this.idbDiscountmatrixs = [];
    for (const id in data) {
      if (data.hasOwnProperty(id)) {
        this.idbDiscountmatrixs.push(data[id]);
      }
    }
    this.getIdbMaterialGroups();
  }),
    (err) => {
      console.log('error getIdbDiscountMatrix');
    };
}
getIdbMaterialGroups() {
  this.getAllData('Materialgroups').then((data) => {
    this.idbMaterialgroups = [];
    for (const id in data) {
      if (data.hasOwnProperty(id)) {
        this.idbMaterialgroups.push(data[id]);
      }
    }
    this.getIdbCustomermaterialgroupdiscounts();
  }),
    (err) => {
      console.log('error Materialgroups');
    };
}
getIdbCustomermaterialgroupdiscounts() {
  this.getAllData('Customermaterialgroupdiscounts').then((data) => {
    this.idbCustomermaterialgroupdiscounts = [];
    for (const id in data) {
      if (data.hasOwnProperty(id)) {
        this.idbCustomermaterialgroupdiscounts.push(data[id]);
      }
    }
    this.getIdbCustomerproductprices();
  }),
    (err) => {
      console.log('error getIdbCustomermaterialgroupdiscounts');
    };
}
getIdbCustomerproductprices() {
  this.getAllData('Customerproductprices').then((data) => {
    this.idbCustomerproductprices = [];
    for (const id in data) {
      if (data.hasOwnProperty(id)) {
        this.idbCustomerproductprices.push(data[id]);
      }
    }
    this.getidbProductprices();
  }),
    (err) => {
      console.log('error getIdbCustomerproductprices');
    };
}

getidbProductprices() {
  this.getAllData('Productprices').then((data) => {
    this.idbProductprices = [];
    for (const id in data) {
      if (data.hasOwnProperty(id)) {
        this.idbProductprices.push(data[id]);
      }
    }
    this.getIdbProducts();
  },
    (err) => {
      console.log('error getIdbCustomerproductprices');
    }

  ).then(() => {});
}

getIdbProducts(selectedCustomer?: iCustomers,  deliveryDate?: Date) {
  this.selectedCustomer = selectedCustomer? selectedCustomer : this.selectedCustomer ;
  this.deliveryDate = deliveryDate? deliveryDate : this.deliveryDate;
  this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
  this.getAllData('Products').then((data) => {
    this.idbAllProducts = [];
    for (const id in data) {
      if ( data.hasOwnProperty(id)  ) {
        this.idbAllProducts.push(data[id]);

      }
    }

  }),
    (err) => {
      console.log('error getIdbProducts');
    };
}


//######################################################################

one:string;


getShoppingCartsOfAllCustomers():iShoppingcarts[]{

  let allShoppingCarts: iShoppingcarts[] = [];
  allShoppingCarts = JSON.parse(localStorage.getItem('baskets'));
  return allShoppingCarts != undefined? allShoppingCarts: [];
}

insertOrUpdateCustomerShoppingCart(customer: iCustomers, deliveryDate: Date, product?:iProducts): iShoppingcarts{
  if(customer == undefined || deliveryDate == undefined ){return;}
  let activshoppingCart: iShoppingcarts = this.getActiveCustomerShoppingCart(customer);

  if(activshoppingCart == undefined){
    activshoppingCart =  this.createCustomerShoppingCart(customer, deliveryDate, product);
  }else{
    let shoppingCartItem: iShoppingcartitems = this.dataService.createShoppingCartItem(product, activshoppingCart);
    activshoppingCart = this.insertOrUpdateShoppingCartItemInActiveShoppingCart(customer, shoppingCartItem)
  }
  return activshoppingCart;
}
getAllCusromerShoppingCarts(selectedCustomer: iCustomers):iShoppingcarts[]{
  if(selectedCustomer == undefined){return;}

  let allCustomerShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  let customerShoppingCarts: iShoppingcarts[] = allCustomerShoppingCarts? allCustomerShoppingCarts.filter(x => x.CustomerId == selectedCustomer.CustomersId): [];
  this.dataService.setShoppingCartCountsOfCustomer(customerShoppingCarts? customerShoppingCarts.length: 0);
  return  customerShoppingCarts;
}
getActiveCustomerShoppingCart(selectedCustomer: iCustomers):iShoppingcarts{
  if(selectedCustomer == undefined){return;}

  let allShoppingCarts: iShoppingcarts[] = [];
  let customerShoppingCarts: iShoppingcarts[] = this.getAllCusromerShoppingCarts(selectedCustomer);
  let activeShoppingCart: iShoppingcarts = customerShoppingCarts? customerShoppingCarts.find(x => x.ShoppingCartStateId == 0):undefined;

  return activeShoppingCart;
}
setAllCustomerShoppingCartInActive(selectedCustomer: iCustomers):iShoppingcarts[]{
  if(selectedCustomer == undefined){return;}
  let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  let allCustomerShoppingCarts: iShoppingcarts[] = allShoppingCarts.filter(x => x.CustomerId == selectedCustomer.CustomersId)

  allCustomerShoppingCarts.forEach(shoppingCart => {
    let index = allShoppingCarts.indexOf(shoppingCart);
    shoppingCart.ShoppingCartStateId = 1;
    allShoppingCarts[index] = shoppingCart;
  });
  localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));
  allCustomerShoppingCarts = allShoppingCarts.filter(x => x.CustomerId == selectedCustomer.CustomersId)
  return allCustomerShoppingCarts;
}
setCustomerShoppingCartActive(selectedCustomer: iCustomers, shoppingCart: iShoppingcarts):iShoppingcarts{
  if(selectedCustomer == undefined || shoppingCart == undefined ){return;}
    this.setAllCustomerShoppingCartInActive(selectedCustomer);
    let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
    let shoppingCart2Activate: iShoppingcarts = allShoppingCarts.find(x => x.CustomerId == selectedCustomer.CustomersId &&  x.ShoppingcartsId == shoppingCart.ShoppingcartsId);

    if (shoppingCart2Activate) {
      let index = allShoppingCarts.indexOf(shoppingCart2Activate);
      shoppingCart2Activate.ShoppingCartStateId = 0;
      allShoppingCarts[index] = shoppingCart2Activate;
    }
    localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));

    return shoppingCart2Activate;
}

setCustomerShoppingCartActiveByIndex(selectedCustomer: iCustomers, index: number):iShoppingcarts{
  if(selectedCustomer == undefined || index == undefined){return;}
    this.setAllCustomerShoppingCartInActive(selectedCustomer);
    let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
    let shoppingCarts2Activate: iShoppingcarts[] = allShoppingCarts.filter(x => x.CustomerId == selectedCustomer.CustomersId);
    let shoppingCart2Activate: iShoppingcarts =  shoppingCarts2Activate[index]
    if (shoppingCart2Activate) {
      shoppingCart2Activate.ShoppingCartStateId = 0;
      // allShoppingCarts[index] = shoppingCart2Activate;
    }
    localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));

    this.dataService.getAndSetShoppingCartInfoBag(shoppingCart2Activate);
    return shoppingCart2Activate;
}
createCustomerShoppingCart(customer: iCustomers, deliveryDate: Date, product?:iProducts): iShoppingcarts{
  if(customer == undefined || deliveryDate == undefined ){return;}
  let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  let activshoppingCart: iShoppingcarts;
  let activshoppingCartItem: iShoppingcartitems;
  let activshoppingCartItems: iShoppingcartitems[];
  activshoppingCart = this.dataService.CreateShoppingCart(customer, deliveryDate);

  if(product){
    activshoppingCartItem = this.dataService.createShoppingCartItem(product, activshoppingCart);
    activshoppingCartItem.PosIndex = 0;
    activshoppingCartItem.ShoppingCartId = activshoppingCart.ShoppingcartsId;
    activshoppingCart.Shoppingcartitems.push(activshoppingCartItem);
  }
  allShoppingCarts.push(activshoppingCart);
  localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));
  this.setCustomerShoppingCartActive(customer, activshoppingCart);
    //update customershoppingCart Count
    this.getAllCusromerShoppingCarts(customer);

  return activshoppingCart;
}
removeCustomerShoppingCart(customer: iCustomers, shoppingCart: iShoppingcarts):iShoppingcarts{
  if(customer == undefined || shoppingCart == undefined ){return;}
  let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  let shoppingCart2Activate: iShoppingcarts;
  allShoppingCarts = allShoppingCarts.filter(x => x.ShoppingcartsId != shoppingCart.ShoppingcartsId);
  let allCustomerShoppingCarts: iShoppingcarts[] = allShoppingCarts? allShoppingCarts.filter(x => x.CustomerId == customer.CustomersId):[];
  if(allCustomerShoppingCarts && allCustomerShoppingCarts.length > 0){
    allCustomerShoppingCarts[0].ShoppingCartStateId = 0;
    shoppingCart2Activate = allCustomerShoppingCarts[0];
  }
  localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));
  //update customershoppingCart Count
  this.getAllCusromerShoppingCarts(customer);

  return shoppingCart2Activate;
}
insertOrUpdateShoppingCartItemInActiveShoppingCart(customer: iCustomers, shoppingCartItem: iShoppingcartitems):iShoppingcarts{
  if(customer == undefined || shoppingCartItem == undefined ){return;}
  let activeCustomerShoppingCart: iShoppingcarts = this.getActiveCustomerShoppingCart(customer);
  let activeCustomerShoppingCartItems: iShoppingcartitems[] = activeCustomerShoppingCart? activeCustomerShoppingCart.Shoppingcartitems:[];

  let existingSchoppingCartItem = activeCustomerShoppingCartItems.find(x => x.ProductId == shoppingCartItem.ProductId);
  if(existingSchoppingCartItem){
    activeCustomerShoppingCartItems = activeCustomerShoppingCartItems.filter(x => x.ProductId != shoppingCartItem.ProductId);
    shoppingCartItem.PosIndex = existingSchoppingCartItem.PosIndex;
    if(shoppingCartItem.Quantity != 0 || shoppingCartItem.Product.QuantityInput != 0){
      activeCustomerShoppingCartItems.push(shoppingCartItem);
    }

  }else{
    if(shoppingCartItem.Quantity != 0 || shoppingCartItem.Product.QuantityInput != 0){
      activeCustomerShoppingCartItems.push(shoppingCartItem)
    }

  }
    activeCustomerShoppingCart.Shoppingcartitems = activeCustomerShoppingCartItems;
    this.updateCustomerShoppingCart( customer, activeCustomerShoppingCart);
    this.idbActiveProducts.find(x => x.ProductsId == shoppingCartItem.Product.ProductsId).QuantityInput = shoppingCartItem.Quantity;
    this.dataService.getAndSetShoppingCartInfoBag(activeCustomerShoppingCart);
    return activeCustomerShoppingCart;
}
updateCustomerShoppingCart(customer: iCustomers, shoppingCart: iShoppingcarts): iShoppingcarts{
  if(customer == undefined || shoppingCart == undefined ){return;}
  let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  let shoppingCart2Update: iShoppingcarts;
  shoppingCart2Update = allShoppingCarts.find(x => x.ShoppingcartsId == shoppingCart.ShoppingcartsId);
  let index = allShoppingCarts.indexOf(shoppingCart2Update);
  allShoppingCarts[index] = shoppingCart;
  localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));

  return shoppingCart
}
updateCustomerShoppingCartPriceFromActiveProducts(customer: iCustomers, shoppingCart: iShoppingcarts): iShoppingcarts{
  if(customer == undefined || shoppingCart == undefined ){return;}
  let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  let shoppingCart2Update: iShoppingcarts;
  shoppingCart2Update = allShoppingCarts.find(x => x.ShoppingcartsId == shoppingCart.ShoppingcartsId)
   //update the shoppingcart price from the active Products
  if(shoppingCart2Update){
    shoppingCart2Update.Shoppingcartitems.forEach(Shoppingcartitem => {
        let product = this.idbActiveProducts.find(x => x.ProductsId == Shoppingcartitem.Product.ProductsId)
        if(product){
          Shoppingcartitem.ListPrice = product.ListPrice;
          Shoppingcartitem.Price = product.ListPrice;
          Shoppingcartitem.PriceType = 'L';
        }
    });
  }
  let index = allShoppingCarts.indexOf(shoppingCart2Update);
  allShoppingCarts[index] = shoppingCart;
  localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));

  return shoppingCart
}
transferCustomerShoppingCart(transferToCustomer: iCustomers, shoppingCart: iShoppingcarts){
  if(transferToCustomer == undefined || shoppingCart == undefined ){return;}
  let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  let shoppingcart2Transfer: iShoppingcarts = allShoppingCarts.find(x => x.ShoppingcartsId == shoppingCart.ShoppingcartsId);
  let index = allShoppingCarts.indexOf(shoppingcart2Transfer);

  if(index >= 0){

    shoppingcart2Transfer.Customer = transferToCustomer;
    shoppingcart2Transfer.CustomerId = transferToCustomer.CustomersId;
    shoppingcart2Transfer.CustomersCustomerNumber = transferToCustomer.CustomerNumber;
    shoppingcart2Transfer.CustomersName1 = transferToCustomer.Name1;
    shoppingcart2Transfer.CustomersName2 = transferToCustomer.Name2;
    shoppingcart2Transfer.Name = transferToCustomer.CustomersId + '_' + this.pipe.transform(Date.now(),'yyyy-MM-dd HH:mm:ss');
    shoppingcart2Transfer.DeliveryDate = this.deliveryDate;

    allShoppingCarts[index] = shoppingcart2Transfer;
    localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));
      //update customershoppingCart Count
  this.getAllCusromerShoppingCarts(transferToCustomer);
  }

}

copyAndCreateCustomerShoppingCartFromTemplate(shoppingCartFromTemplate: iShoppingcarts):iShoppingcarts{
  if(shoppingCartFromTemplate == undefined ){return;}

  let shoppingCart2Activate: iShoppingcarts = shoppingCartFromTemplate;
  let customerShoppingCarts: iShoppingcarts[] = this.setAllCustomerShoppingCartInActive(shoppingCart2Activate.Customer);
  let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
  shoppingCart2Activate.ShoppingCartStateId = 0
  allShoppingCarts.push(shoppingCart2Activate);

  localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));
  this.dataService.getAndSetShoppingCartInfoBag(shoppingCart2Activate);
    //update customershoppingCart Count
    this.getAllCusromerShoppingCarts(shoppingCart2Activate.Customer);
  return shoppingCart2Activate;
}
// addOrderedCustomerShoppingCartAndSetActive(shoppingCart: iShoppingcarts):iShoppingcarts{
//   // Angebot and WK edit should be only transfered in shopping cart with the original customer
//   if( shoppingCart == undefined ){return;}
//     this.setAllCustomerShoppingCartInActive(shoppingCart.Customer);
//     let allShoppingCarts: iShoppingcarts[] = this.getShoppingCartsOfAllCustomers();
//     let shoppingCart2Activate: iShoppingcarts = shoppingCart;
//     shoppingCart2Activate.ShoppingCartStateId = 0;
//     allShoppingCarts.push(shoppingCart2Activate);

//     localStorage.setItem('baskets', JSON.stringify(allShoppingCarts));

//     return shoppingCart2Activate;
// }

setPartialPackageCharge(customer: iCustomers, shoppingcartitem: iShoppingcartitems): iShoppingcartitems{
  let KartonUntitIds: number[] = [17,40,94];
  if( KartonUntitIds.includes(shoppingcartitem.Product.QuantityUnitId) && shoppingcartitem.Product.QuantityInput > 0 && shoppingcartitem.Product.QuantityInput < 1 && shoppingcartitem.Product.PriceType != 'I'  && shoppingcartitem.Product.PartialPackageChargeable == -1){
    shoppingcartitem.BulkDiscount = 0.00;
    shoppingcartitem.PartialPackageCharge = 3.00;
    shoppingcartitem.Product.QuantityUnitsPartialPackageCharge = 3.00;
  }
  else{
    shoppingcartitem.PartialPackageCharge = 0.00;
    shoppingcartitem.Product.QuantityUnitsPartialPackageCharge = 0.00;
  }
  this.insertOrUpdateShoppingCartItemInActiveShoppingCart(customer, shoppingcartitem);
  return shoppingcartitem;
}
updateIdbProductsQuantityInputFromActiveCustomerShoppingCart(customer: iCustomers, notify:boolean):iProducts[]{

  let activeCustomerShoppingCart: iShoppingcarts = this.getActiveCustomerShoppingCart(customer);
  this.idbActiveProducts.forEach(product => { product.QuantityInput = 0;})
  if(activeCustomerShoppingCart && activeCustomerShoppingCart.Shoppingcartitems && activeCustomerShoppingCart.Shoppingcartitems.length > 0){
    activeCustomerShoppingCart.Shoppingcartitems.forEach(shoppingCartItem => {

      shoppingCartItem.Product.QuantityInput = shoppingCartItem.Quantity;

      if(this.idbActiveProducts.find(x => x.ProductsId == shoppingCartItem.Product.ProductsId) && shoppingCartItem){
        this.idbActiveProducts.find(x => x.ProductsId == shoppingCartItem.Product.ProductsId).QuantityInput = shoppingCartItem.Quantity;
      }

    });
  }

  if(notify){ this.dataService.setActiveProductsSubject(this.idbActiveProducts); }

  return this.idbActiveProducts;
}

setDeliveryDateOfActiveShoppingCart(customer: iCustomers, newDeliveryDate: Date, updateShoppingcartItemPrice:boolean, forcePriceUpdate?:boolean): iShoppingcarts{
  if(customer == undefined || newDeliveryDate == undefined ){return;}
  let activeCustomerShoppingCart: iShoppingcarts = this.getActiveCustomerShoppingCart(customer);
  if(activeCustomerShoppingCart == undefined){return;}

  // let deliveryDateChanged2: boolean = newDeliveryDate && activeCustomerShoppingCart && new Date(new Date(activeCustomerShoppingCart.DeliveryDate).getFullYear(),new Date(activeCustomerShoppingCart.DeliveryDate).getMonth(), new Date(activeCustomerShoppingCart.DeliveryDate).getDay(),0,0,0).getTime()
  //                                       == (new Date(this.deliveryDate.getFullYear(),this.deliveryDate.getMonth(), this.deliveryDate.getDay(),0,0,0)).getTime()? false: true;

  let deliveryDateChanged: boolean = (newDeliveryDate && activeCustomerShoppingCart) && this.dataService.getDate( activeCustomerShoppingCart.DeliveryDate, 'yyyy-MM-dd'  ) != this.dataService.getDate( newDeliveryDate, 'yyyy-MM-dd'  )

  deliveryDateChanged = forcePriceUpdate == true? true : deliveryDateChanged;

  let customerIsChanged =   activeCustomerShoppingCart && activeCustomerShoppingCart.CustomerId != this.selectedCustomer.CustomersId ;

    if(deliveryDateChanged == false )  {return;}

    // if(activeCustomerShoppingCart.CustomerId == customer.CustomersId ){
    //   activeCustomerShoppingCart.DeliveryDate = new Date(this.pipe.transform(new Date(newDeliveryDate),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
    // }
    activeCustomerShoppingCart.DeliveryDate = new Date(this.pipe.transform(new Date(newDeliveryDate),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

    activeCustomerShoppingCart.Shoppingcartitems.forEach(shoppingcartItem => {
      let itemProduct: iProducts;

        itemProduct = this.getCustomerSingleProductPricesByDeliverydate(shoppingcartItem.Product, customer, newDeliveryDate);

        if(itemProduct){
          shoppingcartItem.Product.Price = itemProduct.Price;
          shoppingcartItem.Product.CustomerPrice = itemProduct.CustomerPrice;
          shoppingcartItem.Product.Customerproductprices = itemProduct.Customerproductprices;
          shoppingcartItem.Product.CustomerproductpricesPrice = itemProduct.CustomerproductpricesPrice;
          shoppingcartItem.Product.ListPrice = itemProduct.ListPrice;
          shoppingcartItem.Product.Productprices = itemProduct.Productprices;
          shoppingcartItem.Product.ProductpricesPrice = itemProduct.ProductpricesPrice;
          shoppingcartItem.Product.SpecialPrice = itemProduct.SpecialPrice;
          shoppingcartItem.Product.LastPurchasePrice = itemProduct.LastPurchasePrice;
          shoppingcartItem.Product.AveragePurchasePrice = itemProduct.AveragePurchasePrice;
          shoppingcartItem.Product.PriceType = itemProduct.PriceType;
          shoppingcartItem.Product.CustomerproductpricesValidFrom = itemProduct.CustomerproductpricesValidFrom;
          shoppingcartItem.Product.CustomerproductpricesValidTo = itemProduct.CustomerproductpricesValidTo;
          shoppingcartItem.Product.ProductpricesValidFrom = itemProduct.ProductpricesValidFrom;
          shoppingcartItem.Product.ProductpricesValidTo = itemProduct.ProductpricesValidTo;

            if(updateShoppingcartItemPrice == true || customerIsChanged == true){
              shoppingcartItem.ListPrice = itemProduct.ListPrice;
              shoppingcartItem.Price = itemProduct.Price;
              shoppingcartItem.PriceType = itemProduct.PriceType;
            }
          }
    });

    activeCustomerShoppingCart =  this.updateCustomerShoppingCart(customer, activeCustomerShoppingCart);

    this.dataService.setShoppingCartSourceSubject(activeCustomerShoppingCart);
    return activeCustomerShoppingCart;

  // if(activeCustomerShoppingCart && updateShoppingcartItemPrice == false){
  //   // activeCustomerShoppingCart.DeliveryDate = new Date(this.pipe.transform(new Date(newDeliveryDate),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
  //   activeCustomerShoppingCart =  this.updateCustomerShoppingCart(customer, activeCustomerShoppingCart);
  // }
  // if(activeCustomerShoppingCart && updateShoppingcartItemPrice == true){
  //   // activeCustomerShoppingCart.DeliveryDate = new Date(this.pipe.transform(new Date(newDeliveryDate),'yyyy-MM-dd', 'GMT+0200', 'de-DE'));

  //     activeCustomerShoppingCart.Shoppingcartitems.forEach(shoppingcartItem => {
  //       let itemProduct: iProducts;

  //         itemProduct = this.getCustomerSingleProductPricesByDeliverydate(shoppingcartItem.Product, customer, newDeliveryDate);

  //         if(itemProduct){
  //           shoppingcartItem.Product.Price = itemProduct.Price;
  //           shoppingcartItem.Product.CustomerPrice = itemProduct.CustomerPrice;
  //           shoppingcartItem.Product.Customerproductprices = itemProduct.Customerproductprices;
  //           shoppingcartItem.Product.CustomerproductpricesPrice = itemProduct.CustomerproductpricesPrice;
  //           shoppingcartItem.Product.ListPrice = itemProduct.ListPrice;
  //           shoppingcartItem.Product.Productprices = itemProduct.Productprices;
  //           shoppingcartItem.Product.ProductpricesPrice = itemProduct.ProductpricesPrice;
  //           shoppingcartItem.Product.SpecialPrice = itemProduct.SpecialPrice;
  //           shoppingcartItem.Product.LastPurchasePrice = itemProduct.LastPurchasePrice;
  //           shoppingcartItem.Product.AveragePurchasePrice = itemProduct.AveragePurchasePrice;
  //           shoppingcartItem.Product.PriceType = itemProduct.PriceType;
  //           shoppingcartItem.Product.CustomerproductpricesValidFrom = itemProduct.CustomerproductpricesValidFrom;
  //           shoppingcartItem.Product.CustomerproductpricesValidTo = itemProduct.CustomerproductpricesValidTo;
  //           shoppingcartItem.Product.ProductpricesValidFrom = itemProduct.ProductpricesValidFrom;
  //           shoppingcartItem.Product.ProductpricesValidTo = itemProduct.ProductpricesValidTo;

  //           shoppingcartItem.ListPrice = itemProduct.ListPrice;
  //           shoppingcartItem.Price = itemProduct.Price;
  //           shoppingcartItem.PriceType = itemProduct.PriceType;

  //           }
  //     });
  //     activeCustomerShoppingCart = this.updateCustomerShoppingCart(customer, activeCustomerShoppingCart);
  // }
  // this.dataService.setShoppingCartSourceSubject(activeCustomerShoppingCart);
  // return activeCustomerShoppingCart;

}

setDeliveryDateOfActiveShoppingCartWithPriceConformation(customer: iCustomers, newDeliveryDate: Date,  message: string, shoppingCart?: iShoppingcarts, forcePriceWarning?:boolean): iShoppingcarts{

  let activeCustomerShoppingCart: iShoppingcarts = shoppingCart != undefined? shoppingCart : this.getActiveCustomerShoppingCart(customer);
 //let shoppingCartDeliveryDate: Date = activeCustomerShoppingCart.DeliveryDate;
// let deliveryDateChanged: boolean = (newDeliveryDate && activeCustomerShoppingCart && activeCustomerShoppingCart.DeliveryDate) && ((new Date(new Date(newDeliveryDate).getFullYear(),new Date(newDeliveryDate).getMonth()+1, new Date(newDeliveryDate).getDay(),0,0,0)).getTime() != ( new Date(new Date(shoppingCartDeliveryDate).getFullYear(),new Date(shoppingCartDeliveryDate).getMonth()+1, new Date(shoppingCartDeliveryDate).getDay(),0,0,0)).getTime());

let deliveryDateChanged: boolean = activeCustomerShoppingCart != undefined &&  this.dataService.getDate( activeCustomerShoppingCart.DeliveryDate, 'yyyy-MM-dd'  ) != this.dataService.getDate( newDeliveryDate, 'yyyy-MM-dd'  )


  deliveryDateChanged = forcePriceWarning == true? true : deliveryDateChanged;
  // if(newDeliveryDate && activeCustomerShoppingCart && this.dataService.getDate( activeCustomerShoppingCart.DeliveryDate, 'yyyy-MM-dd'  ) == this.dataService.getDate( newDeliveryDate, 'yyyy-MM-dd'  )  )
  if(deliveryDateChanged != true ){return activeCustomerShoppingCart;}

  if(activeCustomerShoppingCart){

    if(this.dataService.hasRoles([5])){

      return this.setDeliveryDateOfActiveShoppingCart( customer , newDeliveryDate,false, false );

    }
    else{
      this.dialogService.conformDialog(  'Warenkorb',  message,  'info',  'Nein',  'Ja'  ).afterClosed().subscribe(
        (data) => {
        if (data == 'ok') {

          return this.setDeliveryDateOfActiveShoppingCart( customer, newDeliveryDate, false, false );

        }
        else{

          return this.setDeliveryDateOfActiveShoppingCart( customer, newDeliveryDate, true, true );

        } });
    }
  }
}

toGMT(date: Date): Date{
  return new Date(this.pipe.transform(date,'yyyy-MM-dd', 'GMT+0200', 'de-DE'))
}

}





<mat-form-field appearance="outline"  class="w100 ">
  <mat-label > <span class=" txt-pink-b">Lieferdatum: {{ deliveryDate | date: 'dd.MM.yyyy'}}</span></mat-label>
  <input (dateChange)="onDateSelect($event.value)"  [min]="minDate" [formControl]="DeliveryDateForm" placeholder="deliveryDate"  class="w100 "  matInput [matDatepicker]="dp"  >

  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp></mat-datepicker>
</mat-form-field>




<mat-card >

  <mat-card-header class="d-{{type}}">
     <mat-card-title> <span class="material-icons text-{{type}}">info_outline</span> <span> {{title}} </span>  </mat-card-title>

  </mat-card-header>
  <mat-divider></mat-divider>

  <mat-card-content >
    <p></p>  <p>{{msg}}</p> <p></p>
  </mat-card-content>
  <mat-divider></mat-divider>

  <mat-card-actions>
    <div class=row>
      <!-- <div class="col-4"></div>      <div class="col-4"></div> -->
      <div class="center"><button mat-button class="w100 btn-primary mat-flat-button"  (click)="onOK()"  >OK</button></div>

    </div>
  </mat-card-actions>

</mat-card>

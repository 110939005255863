

<div class="card">
  <div class="card-header"> <h2> Sonderangebote </h2> </div>
    <div class="card-body">

      <div class="row" *ngFor="let row of productRows; index as i">

          <div class="col-6"  *ngFor="let item of row">
            <div class="card">
              <div class="card-header"> {{ item.ListPrice | number }} EUR.  </div>
                <div class="card-body" >
                  {{ item.Description }}
                  <a class="btn" (click)="onOpenImgDialogMessage(item)"> <img class="PropImage " [src]="showImage(item.ProductsId)" onerror="this.src='../../assets/img/f1.jpg'" > </a>
                </div>
            </div>
          </div>

      </div>

    </div>
</div>


import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import {  iOptions, iAddresses, iUsers, iCustomers, iContacts, iContactphones, iTitles, iPositions } from 'src/app/models/AlaskaModels';
import { SnackbarService } from 'src/app/Services/snackbar.service';

@Component({
  selector: 'app-customerContact',
  templateUrl: './customerContact.component.html',
  styleUrls: ['./customerContact.component.css']
})
export class CustomerContactComponent implements OnInit {

  contactForm: FormGroup;
  formSubmited: boolean;

  @ViewChild("contactDiv") contactDiv: ElementRef;

  titles: iTitles[]= [];
  positions: iPositions[]= [];
  loggedInUser: iUsers;
  contact: iContacts;
  contactPhones: iContactphones;


  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef) { }

  ngOnInit() {

    this.createContactForm();

    this.titles = JSON.parse(localStorage.getItem('inputOptions')).titles;
    this.positions = JSON.parse(localStorage.getItem('inputOptions')).positions
    this.dataService.loggedInUserSubject$.subscribe((data) => {
      this.loggedInUser = this.dataService.getLogedInUser();
    });

  }

  ngAfterViewInit() {

    this.titles = JSON.parse(localStorage.getItem('inputOptions')).titles;
    this.positions = JSON.parse(localStorage.getItem('inputOptions')).positions

    console.log('this.titles: ',this.titles);
    console.log('this.positions: ',this.positions);

  }

  onSubmit(){

  }

  getLsOption(filterOption: string): iOptions[]{
    let lsOptions: iOptions[] = JSON.parse(localStorage.getItem('options'));
    return lsOptions.filter(x => x.Name.toLowerCase() == filterOption.toLocaleLowerCase());
  }


  createContactForm(){
    let lsUsers: iUsers = JSON.parse(localStorage.getItem('reg-user'));

    let firstName: string = lsUsers == null? '':lsUsers.FirstName;
    let lastName: string = lsUsers == null? '':lsUsers.LastName;
    let email: string = lsUsers == null? '':lsUsers.Email
    this.contactForm = this.formBuilder.group(
      {
        ContactsId:   0,
        CustomersId:   0,
        TitlesId:   ['', [Validators.required]],
        FirstName:  [firstName, [Validators.required]],
        LastName:  [lastName, [Validators.required]],
        BirthDay:  ['', []],
        Email:  [email, [Validators.required]],
        Phone:  ['', [Validators.required]],
        Mobile:  ['', []],
        Fax:  ['', []],
        PositionsId:   ['', []]
      }
    )
  }

  setContact(){
    this.contact = {
      ContactsId: 0,
      CustomersId: 0,
      TitlesId:    this.contactForm.get('TitlesId')?      +this.contactForm.get('TitlesId').value : 0,
      FirstName:  this.contactForm.get('FirstName')?  this.contactForm.get('FirstName').value : '',
      LastName:   this.contactForm.get('LastName')?   this.contactForm.get('LastName').value: '',
      BirthDay:   this.contactForm.get('BirthDay')?   this.contactForm.get('BirthDay').value: '',
      Email:      this.contactForm.get('Email')?      this.contactForm.get('Email').value: '',
      PositionsId:   this.contactForm.get('PositionsId')?   +this.contactForm.get('PositionsId').value: 0
      }

      this.contactPhones = {
      ContactsId: 0,
      Name: '',
      Phone:      this.contactForm.get('Phone')?      this.contactForm.get('Phone').value: '',
      Mobile:     this.contactForm.get('Mobile')?     this.contactForm.get('Mobile').value: '',
      Fax:        this.contactForm.get('Fax')?        this.contactForm.get('Fax').value: '',
    }
localStorage.setItem('reg-contact',JSON.stringify(this.contact));
}

onSendClick(){
  this.setContact();
  console.log('LScontact: ' , localStorage.getItem('reg-contact'))
}
}


<div class="mat-table-container mat-elevation-z8 tables">
  <table class="table-striped" mat-table [dataSource]="datasource.data">

    <ng-container matColumnDef="Date" class="m-1">
      <th mat-header-cell *matHeaderCellDef> Datum </th>
      <td mat-cell *matCellDef="let element">  {{element.CreateDate  | date: 'dd.MM.yyyy'}}  </td>
    </ng-container>

    <ng-container matColumnDef="Customernumber" class="m-1">
      <th mat-header-cell *matHeaderCellDef>  Kundenummer</th>
      <td mat-cell *matCellDef="let element">  {{element.Customer.CustomerNumber}}  </td>
    </ng-container>


    <ng-container matColumnDef="Name" class="m-1">
      <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
      <td mat-cell *matCellDef="let element">{{element.Name}}  </td>
    </ng-container>

    <ng-container matColumnDef="DeliveryDate" class="mar-r">
      <th  mat-header-cell *matHeaderCellDef> Lieferdatum </th>
      <td mat-cell *matCellDef="let element"> {{ element.DeliveryDate  | date: 'dd.MM.yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="SearchCustomer" class="mar-r">
      <th  mat-header-cell *matHeaderCellDef> Kundensuche </th>
      <td mat-cell *matCellDef="let element"> <app-searchCustomer></app-searchCustomer> </td>
    </ng-container>

    <ng-container matColumnDef="Transfer" class="mar-r">
      <th  mat-header-cell *matHeaderCellDef> Übertragen</th>

      <td mat-cell *matCellDef="let element">  <button (click)="onTransferShopingCards(element)"    class="mat-icon-button "><span class="material-icons btn-outline-primary md-32 m3" matTooltip="listenansichtb">redo</span></button></td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr mat-row   *matRowDef="let row; columns: displayedColumns;"  ></tr>

  </table>
</div>


<div class="card">
  <div class="card-header text-primary">Benutzer registrierung</div>
  <div class="card-body" >
    <form  [formGroup] ="usersForm" >

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100 m-2" appearance="outline">
          <mat-select   formControlName="TitleId" placeholder="Titel" > <mat-option *ngFor="let titel of Titles" [value]="titel.TitlesId" >{{ titel.Name }} </mat-option> </mat-select>
          <span class="error-block" *ngIf="!usersForm.get('TitleId').valid && (usersForm.get('TitleId').touched  && formSubmited)"></span>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100 m-2" appearance="outline">
          <mat-select  formControlName="GenderId" placeholder="Gescläscht" > <mat-option *ngFor="let gender of Genders" [value]="gender.GendersId">{{ gender.Name }} </mat-option> </mat-select>
          <span class="error-block" *ngIf="!usersForm.get('GenderId').valid && (usersForm.get('GenderId').touched  && formSubmited)"></span>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100 m-2" appearance="outline">
          <input matInput  formControlName="FirstName" placeholder="Vorname" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('FirstName').valid && (usersForm.get('FirstName').touched  && formSubmited)"></mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100 m-2" appearance="outline">
          <input matInput formControlName="LastName" placeholder="Nachname" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('LastName').valid && (usersForm.get('LastName').touched  && formSubmited)"></mat-error>
        </mat-form-field>
      </div>
    </div>


    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100 m-2" appearance="outline">
          <input matInput type="email"  formControlName="Email" placeholder="Email" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('Email').valid && (usersForm.get('Email').touched  && formSubmited)"></mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 m-2">
          <input matInput formControlName="BirthDate" placeholder="Geburtsdatum" [matDatepicker]="BirthDate" > <mat-datepicker-toggle matSuffix [for]="BirthDate" ></mat-datepicker-toggle>
          <span class="error-block" *ngIf="!usersForm.get('BirthDate').valid && (usersForm.get('BirthDate').touched  && formSubmited)"></span>
          <mat-datepicker #BirthDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100 m-2" appearance="outline">
          <input matInput type="password" formControlName="Password" placeholder="Passwort" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('Password').valid && (usersForm.get('Password').touched  && formSubmited)"></mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100 m-2" appearance="outline">
          <input matInput type="password"  formControlName="ConformPassword" placeholder="Passwort wiederhollen" [errorStateMatcher]="matcher">
          <mat-error class="error-block" *ngIf="!usersForm.get('ConformPassword').valid && (usersForm.get('ConformPassword').touched  && formSubmited)"></mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" class="w-100 m-2 ">
          <mat-select  formControlName="RegistrationsTypeId" placeholder="Registrierungstyp" > <mat-option *ngFor="let regType of RegistrationsTypes" [value]="regType.RegistrationstypesId">{{ regType.Name }} </mat-option> </mat-select>
          <span class="error-block" *ngIf="!usersForm.get('RegistrationsTypeId').valid && (usersForm.get('RegistrationsTypeId').touched  && formSubmited)"></span>
        </mat-form-field>
      </div>
      <div class="col-md-3"><div class="form-group"><button (click)="onReset()" type="reset" class="btn btn-primary m-2">Abrechen</button></div></div>
      <div class="col-md-3"><div class="form-group"><button (click)="onSubmit()" type="button" class="btn btn-primary m-2">Speichern</button></div></div>
    </div>

    <div class="row">

    </div>
  </form>
</div>

</div>

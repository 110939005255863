import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit, OnDestroy} from '@angular/core';
import {iUsers,iRoles, iCustomers, iTitles, iGenders, iRegistrationstypes, iUserroles, iPositions, iInputOptions} from '../../../models/AlaskaModels';
import {InputErrorStateMatcher} from '../../../models/InputErrorStateMatcher';
import { DataService } from '../../../Services/data.service';
import { DialogService } from '../../../Services/dialog.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ErrorStateMatcher} from '@angular/material/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '../../../../../node_modules/@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '../../../../../node_modules/@angular/material/paginator';
import { MatTableDataSource } from '../../../../../node_modules/@angular/material/table';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { Console } from 'console';

@Component({
  selector: 'app-adminUsers',
  templateUrl: './adminUsers.component.html',
  styleUrls: ['./adminUsers.component.scss']
})

export class AdminUsersComponent implements OnInit, OnDestroy {

  loggedInUser: iUsers;
  userSubscription: Subscription;
  loggedInUserSubscription: Subscription;
  tableColumns: string[] = [  'UsersId', 'CreateDate',  'TitleId',  'FirstName',  'LastName',  'Email',   'EmailConfirmed',  'Enabled', 'actions','BirthDate',  'AccessFailedCount',  'Token',  'Blocked',   'ModifyDate',  'GenderId',  'RegistrationsTypeId'];
  displayedColumns: string[] = [ 'UsersId', 'CreateDate', 'TitleId',  'FirstName',  'LastName', 'Email', 'BirthDate', 'EmailConfirmed',  'Enabled', 'Role', 'CustomerId', 'RegistrationsTypeId', 'actions'];


  selectedCustomer: iCustomers;
  item: string = 'Liste von Alaska benutzern';
  users: iUsers[];
  editableUser: iUsers;
  datasource = new MatTableDataSource<iUsers>();

  usersCustomer: iCustomers;

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() editableCustomer: iCustomers;
  mode: string = 'list';
  formSubmited: boolean = false;
  usersForm: FormGroup;
  inputOptions: iInputOptions;
  Titles: iTitles[] = [];
  Genders: iGenders[] = [];
  RegistrationsTypes: iRegistrationstypes[] = [];
  Customers: iCustomers[] = [];
  Positions: iPositions[] = [];
  Roles: iRoles[] = [];
  matcher = new InputErrorStateMatcher();
  searchTerm: string;
  staffRegistrationTypes: number[]= [2,3]
  columnsForm: FormControl;
  minDate: Date = new Date(new Date().getTime() - 365 * 18 * 24 * 60 * 60 * 1000);
  pageSize: number = 50;
  pageView: string = '';


  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private snackbarService: MatSnackBar,
    private matPaginatorIntl: MatPaginatorIntl
  ) {}

  ngOnDestroy(){
    if(this.userSubscription){
      this.userSubscription.unsubscribe();
    }
  }

  getGenderById(id:number){ return this.Genders.find(x => x.GendersId == id).Name}
  getPositionById(id:number){ return this.Positions.find(x => x.PositionsId == id).Name}
  getTitleById(id:number){ return this.Titles.find(x => x.TitlesId == id).Name}
  getRoleByUser(user: iUsers){   if(user && user.Userroles && user.Userroles[0]){ let id = user.Userroles[0].RolesId; return this.Roles.find(x => x.RolesId == id).Name}   }
  getCustomerByUser(user: iUsers):iCustomers{ if(user && user.Customers){ return user.Customers; }else{ return null;}    }
  getCustomerNumberByUser(user: iUsers):string{ if(user && user.Customers){ return user.Customers.CustomerNumber; }else{ return '';}    }
  getRegistrationsTypeById(id:number){ return this.RegistrationsTypes.find(x => x.RegistrationstypesId == id).Name}

  getUsersCustomerName(){
    let usersCompanyName:string = '';
    this.users.forEach(user => {
      this.usersCustomer  = this.Customers != undefined? this.Customers.find(x => x.Users_UserId == user.UsersId): null;
    });

  }

  isStandardUser(user: iUsers):boolean{
    let result = false;
    let role = user && user.Userroles ?user.Userroles.find(x => x.RolesId == 5): undefined;
    result = role != undefined? true:false;
    return result;
  }

  onDateSelect($event: any){


  }


  ngOnInit() {


    this.columnsForm = new FormControl( this.displayedColumns);

    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));

    this.Titles = this.inputOptions['titles'];
    this.Genders = this.inputOptions['genders'];
    this.RegistrationsTypes =  this.inputOptions['registrationstypes'];

    this.Positions = this.inputOptions['positions'];
    this.Roles = this.inputOptions['roles'];
    this.Customers = JSON.parse(localStorage.getItem('customers'));


    this.createUserForm();

    this.loggedInUserSubscription = this.dataService.loggedInUserSubject$.subscribe(
                                    user => {
                                      this.loggedInUser = user;
                                    }, err => {
                                      console.log("error loggedin user: ", err);
                                    });

                                    this.dataService.selectedCustomerSubject$.subscribe(
                                      selectedCustomer => {

                                        this.selectedCustomer = selectedCustomer;
                                        console.log("productCustomer: ",  this.selectedCustomer);
                                      }
                                    );

        this.dataService.msgSubject$.subscribe((msg) => {
         if (msg.Name.toLowerCase() == 'applyproductfilter') {
           this.datasource.filterPredicate = (data: iUsers, filter: string) =>
             JSON.stringify(data).toLowerCase().indexOf(filter) != -1;
           this.datasource.filter = msg.Message.trim().toLowerCase();
           this.datasource.sort = this.sort;
           this.datasource.paginator = this.paginator;
           setTimeout(() => {
             if (  msg.Type.toLowerCase() == 'enter' ||  msg.Type.toLowerCase() == 'numpadenter' ) {

             }
           }, 0);
         }


       },
       (err) => {
         console.log('error adding order: ', err);
       }
     );


  }

    applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

   onSelectColumns(event: {isUserInput: any;    source: { value: any; selected: any };  })
   {
    if (event.isUserInput) {
      if (event.source.selected === true) {
        this.displayedColumns.push(event.source.value);
      } else {
        this.displayedColumns = this.displayedColumns.filter(x => x !=  event.source.value)
      }
      if(!!this.displayedColumns.filter(x => x == 'actions')){
        this.displayedColumns = this.displayedColumns.filter(x => x !=  'actions');
        this.displayedColumns.push('actions');
      }
    }

  }



  populateData(){

              this.userSubscription = this.dataService.getData('custom','getusers').subscribe(
                            data => {
                              this.users = data;
                              this.datasource.data = data;
                              this.datasource.sort = this.sort;
                              this.datasource.paginator = this.paginator;;
                            }, err => {
                              console.log('error retriving users: ', err);
                            });

  }

  ngAfterViewInit() {
    this.matPaginatorIntl.itemsPerPageLabel = '';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';

    this.populateData();

  }


  createUserForm(user?: iUsers){
    this.usersForm = this.formBuilder.group(
      {
        UsersId :              [user != undefined && user.UsersId != undefined? user.UsersId : 0, []],
        TitleId:              [user != undefined && user.TitleId != undefined? user.TitleId : 0, [Validators.required]],
        FirstName:            [user != undefined && user.FirstName != undefined? user.FirstName : '', [Validators.required]],
        LastName:             [user != undefined && user.LastName != undefined? user.LastName : '', [Validators.required]],
        BirthDate:            [user != undefined && user.BirthDate != undefined? user.BirthDate : '', [Validators.required]],
        Email:                [user != undefined && user.Email != undefined? user.Email : '', [Validators.required, Validators.email]],
        Password :            [user != undefined && user.Password != undefined? user.Password : '', []],

        EmailConfirmed:       [user != undefined && user.EmailConfirmed != undefined? user.EmailConfirmed : 0, [Validators.required, Validators.email]],
        Enabled:              [user != undefined && user.Enabled != undefined? user.Enabled : 0, []],
        AccessFailedCount :   [user != undefined && user.AccessFailedCount != undefined? user.AccessFailedCount : 0, []],
        Token:                [user != undefined && user.Token != undefined? user.Token : '', []],
        Blocked:              [user != undefined && user.Blocked != undefined? user.Blocked : 0, []],
        CreateDate:           [user != undefined && user.CreateDate != undefined? user.CreateDate : '', []],
        ModifyDate:           [user != undefined && user.ModifyDate != undefined? user.ModifyDate : '', []],
        GenderId:             [user != undefined && user.GenderId != undefined? user.GenderId : 0, [Validators.required]],
        RegistrationsTypeId:  [user != undefined && user.RegistrationsTypeId != undefined? user.RegistrationsTypeId : 0, [Validators.required]],
        CustomersId:         [user != undefined && user.CustomersId != undefined? user.CustomersId : 0, []],
        PositionsId:         [user != undefined && user.PositionsId != undefined? user.PositionsId : 0, []]
      }
    );
  }


  onList(){
    this.mode = 'list';
    this.item = 'Beschränkte ansicht von Alaska benutzern . . .';
    this.populateData();
  }


  onSetCompany(user: iUsers){
    this.mode = 'editCompany';
    this.editableUser = user;
    const curentUser:string   = user != undefined && user.FirstName != undefined && user.LastName != undefined ? user.FirstName +' '+ user.LastName :'';
    this.item = 'Ein Unternehmen (company) für: '+ curentUser +' zuordenen . . .  (Hinweis: RegistrationsType = Unternehmer is erforderlich)';
    this.setSelectedUser(user);
    this.createUserForm(user);
  }
  onEdit(user: iUsers){

    this.RegistrationsTypes = this.hasRoles([1,2,3])? this.RegistrationsTypes :this.RegistrationsTypes  ;
    this.mode = 'edit';
    this.editableUser = user;
    const curentUser:string   = user != undefined && user.FirstName != undefined && user.LastName != undefined ? user.FirstName +' '+ user.LastName :'';
    this.item = 'Alaska benutzer: '+ curentUser +' bearbeiten . . .';
    this.setSelectedUser(user);
    this.createUserForm(user);
  }
  onCreate(){
    this.mode = 'create';
    this.item = 'Neu Alaska benutzer erstellen';
    this.reSetSelectedUser()
    this.createUserForm();
  }
  onDelete(user: iUsers){
    this.mode = 'delete';
    this.editableUser = user;
    const curentUser:string   = user != undefined && user.FirstName != undefined && user.LastName != undefined ? user.FirstName +' '+ user.LastName :'';
    this.item = 'Alaska benutzer: '+ curentUser +' löschen . . .';

    this.onSubmitDeleteUser(user);
  }



  onResetPassword(user: iUsers){

    const curentUser:string   = user != undefined && user.FirstName != undefined && user.LastName != undefined ? user.FirstName +' '+ user.LastName :'';


    this.dialogService.conformDialog("Bestätigung", "Möchten Sie das Passwort von Benutzer "+ curentUser+" zurücksetzen?  Der Benutzer erhält ein temporäres Passwort per E-Mail. ", "danger","abrechen","zurücksetzen",'40%','40%').afterClosed().subscribe(
      data => {
             if(data !== 'ok')
             {
               this.onList(); return;
              }
              else{
                this.dataService.PostData("custom",user,"UserPasswordReset").subscribe(
                  data => {
                    console.log("reset result Data : ", data);
                    this.dialogService.okDialog('Passwort zurücksetzen', 'Passwort erfolgreich zurückgesetzt. ', 'success','40%');
                    this.onList();
                  }, err => {
                    console.log("PostData error : ", err);
                    this.dialogService.okDialog('Passwort zurücksetzen', 'Passwortzurücksetzen fehlgeschlagen', 'danger','40%');
                  }, () => {
                    console.log("PostData completed : ");
                  });

              }

      }
    );


  }


  getFormData(): iUsers{

    let _user: iUsers =
    {
      UsersId :             +this.usersForm.get('UsersId').value,
      TitleId:              +this.usersForm.get('TitleId').value,
      FirstName:            this.usersForm.get('FirstName').value,
      LastName:             this.usersForm.get('LastName').value,
      BirthDate:            this.usersForm.get('BirthDate').value,
      Email:                this.usersForm.get('Email').value,
      Password :            this.usersForm.get('Password').value,

      EmailConfirmed:       +this.usersForm.get('EmailConfirmed').value,
      Enabled:              +this.usersForm.get('Enabled').value,
      AccessFailedCount :   +this.usersForm.get('AccessFailedCount').value,
      Token:                this.usersForm.get('Token').value,
      Blocked:              +this.usersForm.get('Blocked').value,

      GenderId:             +this.usersForm.get('GenderId').value,
      RegistrationsTypeId:  +this.usersForm.get('RegistrationsTypeId').value,

      CustomersId:          this.selectedCustomer != undefined && this.isCompanyRegistration(this.usersForm)? this.selectedCustomer.CustomersId :+this.usersForm.get('CustomersId').value,
      PositionsId:          +this.usersForm.get('PositionsId').value

    }
    return _user;
  }

  existEmail(user: iUsers):boolean{
    let emailExist:boolean = true;
      this.dataService.getDataByQuerystring('custom','EmailExists', user.Email).subscribe(
        result => {
          emailExist = result;
          },err =>{
            this.dialogService.okDialog("Error", "prüfung email-exist fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');
          }
          )

      if(emailExist){
        this.dialogService.okDialog("Error", "Benutzer email, "+user.Email+" existiert bereits!" , "danger",'40%');

      }

      return emailExist;
  }



  onSubmitCreateUser(user: iUsers){

      let sub: Subscription = this.dataService.PostData('custom',user, 'postUsersByAdmin').subscribe(
        result => {
          if(result > 0){
            this.editableUser = user;
            this.datasource.data.push(user);

          }


            this.dialogService.okDialog("Erfolg", "Benutzer erfolgreich angelegt.  \n Ein Benachrichtigung Mail wurde verschickt." , "success",'40%');
            this.onList();
        }, err => {
          if(err.text == "Redirect user_exist"){ }
            console.log('error inserting UserRole', err);
            console.log('error inserting err.error.text', err.error.text);
            this.dialogService.okDialog("Error", "Benutzer hinzufügen fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');
        }, () => {}
      );
      if(sub){sub.unsubscribe}

  }

  onSubmitEditUser(user: iUsers){
    console.log('user to edit', user);
    let sub: Subscription = this.dataService.PutData('custom',user, 'putusers').subscribe(
        result => {
          if(result > 0){
            this.editableUser = user;
            this.datasource.data = this.datasource.data.filter(x => x.UsersId != user.UsersId);
            this.datasource.data.push(user);
          }


            this.snackbarService.open("Benutzerdaten erfolgreich geändert.", "ok");
            this.onList();
        }, err => {
            console.log('error editing UserRole', err.error.text);
            this.dialogService.okDialog("Error", "Benutzer bearbeitung fehlgeschlagen. \n Grund: "+ err.error.text , "danger",'40%');
        }, () => {}
      );
        if(sub){sub.unsubscribe}
  }

  onSubmitDeleteUser(user: iUsers){
    const curentUser:string   = user != undefined && user.FirstName != undefined && user.LastName != undefined ? user.FirstName +' '+ user.LastName :'';

        let sub1: Subscription = this.dialogService.conformDialog("Bestätigung", "Möchet Sie den Benutzer "+curentUser+" löschen? Hinweis: Benutzer kann gelöscht werden, wenn die Benutzer-ID nicht bereits in einer anderen Tabelle referenziert ist.  ", "danger","abrechen","löschen").afterClosed().subscribe(
        data => {
               if(data !== 'ok'){  this.onList(); return;}

               let sub2: Subscription = this.dataService.RemoveData('custom',user.UsersId, 'deleteusers',).subscribe(
                result => {
                    this.editableUser = result;
                    this.snackbarService.open("Benutzer erfolgreich gelöscht. " , "ok");
                    this.onList();
                }, err => {
                    console.log('error deleting UserRole', err);
                    this.dialogService.okDialog("Error", "Benutzer löschen fehlgeschlagen. Grund: "+ err.error.text , "danger",'40%');
                }, () => {}
              );
              if(sub2){sub2.unsubscribe}
        }
      );
      if(sub1){sub1.unsubscribe}
  }

  onSubmit(){
    this.formSubmited = true;
    let _user: iUsers = this.getFormData();

    if(this.mode == 'create'){
      this.onSubmitCreateUser(_user);
    }

    if(this.mode == 'edit'){
      this.onSubmitEditUser(_user);
    }

  }

  onListRoles(user: iUsers){
    this.mode = 'listrole';
    const curentUser:string   = user != undefined && user.FirstName != undefined && user.LastName != undefined ? user.FirstName +' '+ user.LastName :'';
    this.item = 'verfügbar zugriffrechte von: '+ curentUser;

    if(user == undefined){
      console.log("User is null: ", user);
      return;
    }else{
      this.setSelectedUser(user);
    }

  }

  setSelectedUser(user: iUsers){
    if(user != undefined){
      this.editableUser = user;
      localStorage.setItem('selectedUser', JSON.stringify(this.editableUser));
    }
  }

  reSetSelectedUser(){
      this.editableUser = undefined;
      localStorage.removeItem('selectedUser');
  }
  onReset(){
    this.mode = 'list';
  }
  onTypeChanged(userForm: FormGroup){
    this.isStaffUser(userForm);
  }
  isStaffUser(userForm: FormGroup): boolean{
    if(!userForm.get('RegistrationsTypeId')){ return false;}
    else{

      console.log("type: ", userForm.get('RegistrationsTypeId').value);
      console.log("isstaff: ", !!(this.staffRegistrationTypes.includes(+userForm.get('RegistrationsTypeId').value)));

      return !!(this.staffRegistrationTypes.includes(+userForm.get('RegistrationsTypeId').value));
    }
  }
  isCompanyRegistration(userForm: FormGroup): boolean{
    let KundAndVertreterType: number[] = [1,2];
    if(!userForm.get('RegistrationsTypeId') ){ return false;}
    else{
      return KundAndVertreterType.includes(+userForm.get('RegistrationsTypeId').value);
    }
  }

  hasRoles(roleIds: number[]): boolean{
    return this.dataService.hasRoles(roleIds);
   }


   onPageSizeChange($event){
    this.paginator.pageSize = +this.pageSize;
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(+this.pageSize);
    this.pgnFirstPage();
  }

  getPageVisits():string{
    let visits = '';
    try{
      let nextVisit =  this.paginator ? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize < this.paginator.length? this.paginator.pageIndex * this.paginator.pageSize + this.paginator.pageSize : this.paginator.length : '';
      visits = this.paginator && nextVisit != 0 && +this.paginator.length != 0?  ((this.paginator.pageIndex * this.pageSize)+1).toString() +'-'+ nextVisit.toString()+'/'+this.paginator.length : '';
    }
    catch{  }
    return  visits
  }
    pgnFirstPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.firstPage();
    }
    pgnBackPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.previousPage();
    }
    pgnNextPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.nextPage();
    }
    pgnLastPage(){
      this.paginator.pageSize = +this.pageSize;
      this.paginator.lastPage();
    }

}





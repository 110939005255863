import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CategoryProductsComponent } from './category-products/category-products.component';
import { NavBarComponent } from './NavBar/NavBar.component';
import { PageNotFoundComponent } from './pageNotFound/pageNotFound.component';
import { UserRegisterComponent } from './reg/userRegister/userRegister.component';
import { UserLoginComponent } from './auth/UserLogin/UserLogin.component';
import { ChangePasswordComponent } from './auth/changePassword/changePassword.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';         // carousel
import { ProductCategoryComponent } from './productCategory/productCategory.component';
import { MatTableModule } from '@angular/material/table';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatSortModule} from '@angular/material/sort';
import { MatPaginatorModule}  from '@angular/material/paginator';
import { MatProgressSpinnerModule}  from '@angular/material/progress-spinner';
import { ShopingCartComponent } from './shopingCart/shopingCart.component';
import { DataService } from './Services/data.service';
import { CustomerFilterPipe } from './pipes/customer.filter.pipe';
import { SearchCustomerComponent } from './searchCustomer/searchCustomer.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule} from  '@angular/material/toolbar';
import { MatSidenavModule} from  '@angular/material/sidenav';

import { MatTooltipModule} from  '@angular/material/tooltip';
import { MatButtonModule} from  '@angular/material/button';

import {MatBadgeModule} from '@angular/material/badge';
import {MatGridListModule} from '@angular/material/grid-list';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { ImgDialogComponent } from './imgDialog/imgDialog.component';
import { MatCardModule} from '@angular/material/card';
import { AppComponent } from './app.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { ProfileComponent } from './profile/profile.component';
import { MatTabsModule} from '@angular/material/tabs'
import { CustomerRegisterComponent } from './reg/customerRegister/customerRegister.component';
import { CustomerContactComponent } from './reg/customerContact/customerContact.component';
import { RegistrationComponent } from './reg/registration/registration.component';
import { CustomerAddressComponent } from './reg/customerAddress/customerAddress.component';
import { ProductsComponent } from './products/products.component';
import { InvoiceDialogComponent } from './dialogs/invoiceDialog/invoiceDialog.component';
import { PdfDialogComponent } from './dialogs/pdfDialog/pdfDialog.component';
import { InfoInputDialogComponent } from './dialogs/infoInputDialog/infoInputDialog.component';
import { ConformDialogComponent } from './dialogs/conformDialog/conformDialog.component';
import { DynamicOrdersDialogComponent } from './dialogs/dynamicOrdersDialog/dynamicOrdersDialog.component';
import { ShoppingCartDialogComponent } from './dialogs/shoppingCartDialog/shoppingCartDialog.component';
import { OkDialogComponent } from './dialogs/okDialog/okDialog.component';
import { FooterDialogComponent } from './dialogs/footerDialog/footerDialog.component';
import { DialogService } from './Services/dialog.service';
import { PrintDialogComponent } from './dialogs/printDialog/printDialog.component';
import { OpenItemsDialogComponent  } from './dialogs/openItemsDialog/openItemsDialog.component';
import { DeliveryDateDialogComponent  } from './dialogs/deliveryDateDialog/deliveryDateDialog.component';
import { ProfileDialogComponent  } from './dialogs/ProfileDialog/ProfileDialog.component';
import { PrintOrdersatzDialogComponent } from './dialogs/printOrdersatzDialog/printOrdersatzDialog.component';
import { PrintShoppingcartDialogComponent } from './dialogs/printShoppingcartDialog/printShoppingcartDialog.component';
import { DateControlComponent } from './dateControl/dateControl.component';

import { AdminComponent } from './adminArea/admin/admin.component';
import { AdminInvoicesComponent } from './adminArea/admin/adminInvoices/adminInvoices.component';
import { AdminAddressesComponent } from './adminArea/admin/adminAddresses/adminAddresses.component';
import { AdminCustomersComponent } from './adminArea/admin/adminCustomers/adminCustomers.component';
import { AdminUsersComponent } from './adminArea/admin/adminUsers/adminUsers.component';
import { AdminUserRolesComponent } from './adminArea/admin/adminUserRoles/adminUserRoles.component';
import { AdminOrdersComponent } from './adminArea/admin/adminOrders/adminOrders.component';
import { AdminPublicationComponent } from './adminArea/admin/AdminPublication/AdminPublication.component';


import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatDividerModule} from '@angular/material/divider';
import { CustomSnackbarComponent} from './dialogs/customSnackbar/customSnackbar.component'
import { AccountsComponent } from './accounts/accounts.component';
import { InvoicesComponent } from './invoices/invoices.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FooterComponent } from './footer/footer.component';


import { AdditivesControlComponent } from './additivesControl/additivesControl.component';

import { FilterProductsComponent } from './filterProducts/filterProducts.component';
import { OrderablesComponent } from './orderables/orderables.component';
import { OrdersComponent } from './orders/orders.component';
import { QuickBookingComponent } from './quickBooking/quickBooking.component';

import {NgxPrintModule} from 'ngx-print';
import { DiscountControlComponent } from './discountControl/discountControl.component';

import { DeliveriesComponent } from './deliveries/deliveries.component';
import {OpenToursDialogComponent} from './dialogs/openToursDialog/openToursDialog.component';
import {CustomerInfoDialogComponent} from './dialogs/customerInfoDialog/customerInfoDialog.component';
import {SuplierOrdersDialogComponent} from './dialogs/suplierOrdersDialog/suplierOrdersDialog.component';
import {CustomerOrdersDialogComponent} from './dialogs/customerOrdersDialog/customerOrdersDialog.component';
import {InprogressDialogComponent} from './dialogs/inprogressDialog/inprogressDialog.component';
import { DeliveryDateComponent } from './deliveryDate/deliveryDate.component';
import { OnlyNumberDirective } from '../app/onlyNumber/onlyNumber.directive';
import { TokenInterceptorService } from './Services/tokenInterceptor.service';
import { SidenavComponent } from './sidenav/sidenav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatListModule } from '@angular/material/list';
import { OnlineStatusModule } from 'ngx-online-status';
import { MenuItemComponent } from './menu-item/menu-item.component';


// import {CommonModule} from '@angular/common';
// import {RouterModule} from '@angular/router';
// import {A11yModule} from '@angular/cdk/a11y';
// import {BidiModule} from '@angular/cdk/bidi';
// import {ObserversModule} from '@angular/cdk/observers';
// import {OverlayModule} from '@angular/cdk/overlay';
// import {PlatformModule} from '@angular/cdk/platform';
// import {PortalModule} from '@angular/cdk/portal';
// import {CdkStepperModule} from '@angular/cdk/stepper';
// import {CdkTableModule} from '@angular/cdk/table';




@NgModule({
   declarations: [

      AppComponent,
      CategoryProductsComponent,
      NavBarComponent,
      PageNotFoundComponent,
      UserRegisterComponent,
      UserLoginComponent,
      ProductCategoryComponent,
      ShopingCartComponent,
      CustomerFilterPipe,
      SearchCustomerComponent,
      ImgDialogComponent,
      ChangePasswordComponent,
      DiscountsComponent,
      CustomerAddressComponent,
      ProfileComponent,
      CustomerRegisterComponent,
      CustomerContactComponent,
      RegistrationComponent,
      ProductsComponent,
      InfoInputDialogComponent,
      ConformDialogComponent,
      PdfDialogComponent,
      InvoiceDialogComponent,
      OkDialogComponent,
      AdminComponent,
      AdminInvoicesComponent,
      AdminAddressesComponent,
      AdminUsersComponent,
      AdminCustomersComponent,
      AdminUserRolesComponent,
      AdminOrdersComponent,
      AdminPublicationComponent,
      CustomSnackbarComponent,
      AccountsComponent,
      InvoicesComponent,
      OrderablesComponent,
      FooterComponent,
      AdditivesControlComponent,
      DateControlComponent,
      FilterProductsComponent,
      OrderablesComponent,
      OrdersComponent,
      DynamicOrdersDialogComponent,
      QuickBookingComponent,
      ShoppingCartDialogComponent,
      FooterDialogComponent,
      PrintDialogComponent,
      DiscountControlComponent,
      OpenItemsDialogComponent,
      ProfileDialogComponent,
      PrintOrdersatzDialogComponent,
      PrintShoppingcartDialogComponent,
      DeliveriesComponent,
      OpenToursDialogComponent,
      CustomerInfoDialogComponent,
      SuplierOrdersDialogComponent,
      CustomerOrdersDialogComponent,
      DeliveryDateDialogComponent,
      DeliveryDateComponent,
      OnlyNumberDirective,
      SidenavComponent,
      MenuItemComponent,
      InprogressDialogComponent
   ],
   entryComponents: [
      ImgDialogComponent,
      CustomSnackbarComponent
   ],
   imports: [
      BrowserModule,
      BsDropdownModule.forRoot(),
      TabsModule.forRoot(),
      TooltipModule.forRoot(),
      ButtonsModule.forRoot(),
      NgbModule,
      AppRoutingModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,

      BrowserAnimationsModule,

      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatProgressSpinnerModule,
      MatProgressBarModule,
      MatInputModule,
      MatIconModule,
      MatExpansionModule,
      MatMenuModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatTooltipModule,
      MatSlideToggleModule,
      MatButtonModule,
      MatCheckboxModule,
      MatRadioModule,
      MatSelectModule,
      MatDialogModule,
      MatBadgeModule,
      MatTabsModule,
      MatCardModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatSnackBarModule,
      MatDividerModule,
      MatProgressSpinnerModule,
      MatFormFieldModule,
      NgxMatSelectSearchModule,
      NgxPrintModule,
      MatButtonToggleModule,
      LayoutModule,
      MatListModule,
      MatGridListModule,
      OnlineStatusModule,


// CommonModule,
// RouterModule,
// A11yModule,
// BidiModule,
// ObserversModule,
// OverlayModule,
// PlatformModule,
// PortalModule,
// CdkStepperModule,
// CdkTableModule,


   ],
   providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi:true},
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
     {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
      DataService,
      {
        provide: MatDialogRef,
        useValue: {}
     },
      DialogService
   ],
   bootstrap: [  AppComponent  ]
})
export class AppModule { }


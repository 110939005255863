<p>
  profile works!
</p>

<div>
  <mat-tab-group>
    <mat-tab label="Personlisches Daten">
      <app-userRegister></app-userRegister>
    </mat-tab>

    <mat-tab label="Unternehmen Daten">
      <app-customerRegister></app-customerRegister>
    </mat-tab>

    <mat-tab label="Address Daten">
      <app-customerAddress></app-customerAddress>
    </mat-tab>

    <mat-tab label="Kontakt Daten">
      <app-customerContact></app-customerContact>
    </mat-tab>

    <mat-tab label="Bestellungen">

    </mat-tab>
    <mat-tab label="Lieferungen">

    </mat-tab>
    <mat-tab label="Rechnungen">

    </mat-tab>
  </mat-tab-group>
</div>

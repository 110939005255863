import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit,} from '@angular/core';
import {iUsers,iRoles, iCustomers, iTitles, iGenders, iRegistrationstypes, iUserroles, iInputOptions} from '../../../models/AlaskaModels';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../../../Services/data.service';
import { DialogService } from '../../../Services/dialog.service';
import { MatPaginator } from '@angular/material/paginator';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import { error } from '@angular/compiler/src/util';
import { log } from 'util';


const ELEMENT_DATA: Array<iUserroles> = [];


@Component({
  selector: 'app-adminUserRoles',
  templateUrl: './adminUserRoles.component.html',
  styleUrls: ['./adminUserRoles.component.scss']
})
export class AdminUserRolesComponent implements OnInit {

  loggedInUser: iUsers;
  userRoleItem: string = '';
  selectedUser: iUsers;
  userRole: iUserroles;
  userRoles: iUserroles[]=[];
  userRolesDataSource = new MatTableDataSource(ELEMENT_DATA);
  @ViewChild(MatSort) sort: MatSort;
  mode: string = 'list';
  formSubmited: boolean = false;
  roleSelectEnabled = true;
  userRolesForm: FormGroup;
  inputOptions: iInputOptions;
  roles: iRoles[]=[];
  @Input() editableUser: iUsers;


  constructor(
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,

  ) {}

  ngOnInit() {
    this.inputOptions = JSON.parse(localStorage.getItem("inputOptions"));
    this.roles = this.inputOptions['roles'];
    this.selectedUser = JSON.parse(localStorage.getItem('selectedUser'));

console.log("roll this.editableUser: ", this.editableUser);

    this.onList();

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;
      }, err => {
        console.log("error loggedin user: ", err);
      });

  }

  populateData(user: iUsers){
    this.dataService.getData('custom', 'getUserRolesByUserId', user.UsersId).subscribe(
      data => {
        if(data){
          this.userRoles = [];
          this.userRoles.push(data);
          }
        console.log('data: ', data);
        console.log('this.userRoles: ', this.userRoles);

        this.updateDataSource(this.userRoles)
      }, err => {
        console.log('error retriving users: ', err);
      });
  }

  updateDataSource(userroles: iUserroles[]){

    this.userRolesDataSource.data = userroles;

    console.log("users datasource: ", this.userRolesDataSource);
  }

  ngAfterViewInit() {

    this.populateData(this.editableUser);
  }


  createUserRoleForm(userrole?: iUserroles){
    this.userRolesForm = this.formBuilder.group(
      {
        UserRolesId :         [userrole != undefined && userrole.UserrolesId != undefined? userrole.UserrolesId : 0, []],
        RolesId:              [userrole != undefined && userrole.RolesId != undefined? userrole.RolesId : 0, [Validators.required]],
        UsersId:            [userrole != undefined && userrole.UsersId != undefined? userrole.UsersId : 0, []],

      }
    );
  }

  onList(){
    this.mode = 'list';
    const curentUser:string = this.selectedUser != undefined && this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined ? this.selectedUser.FirstName +' '+ this.selectedUser.LastName :'';
    this.userRoleItem = 'Rolen von benutzer: '+ curentUser;
    this.roleSelectEnabled = true;
  }

  onAdd(userrole?: iUserroles){
    this.mode = 'add';
    this.userRole = userrole;
    const curentUser:string = this.selectedUser != undefined && this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined ? this.selectedUser.FirstName +' '+ this.selectedUser.LastName :'';
    this.userRoleItem = 'Role für benutzer: '+ curentUser + ' hinzufügen';

    this.createUserRoleForm(userrole);
  }
  onCreate(userrole?: iUserroles){
    this.mode = 'create';
    this.userRole = userrole;
    const curentUser:string = this.selectedUser != undefined && this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined ? this.selectedUser.FirstName +' '+ this.selectedUser.LastName :'';
    this.userRoleItem = 'Role für benutzer: '+ curentUser + ' hinzufügen';

    this.createUserRoleForm();
  }

  onEdit(userrole: iUserroles){
    this.mode = 'edit';
    this.userRole = userrole;
    const curentUser:string = this.selectedUser != undefined && this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined ? this.selectedUser.FirstName +' '+ this.selectedUser.LastName :'';
    this.userRoleItem = 'Rolen von benutzer: '+ curentUser +' bearbeiten . . .';
    this.createUserRoleForm(userrole);
  }
  onDelete(userrole: iUserroles){
    this.mode = 'delete';
    this.userRole = userrole;
    this.roleSelectEnabled = false;
    const curentUser:string = this.selectedUser != undefined && this.selectedUser.FirstName != undefined && this.selectedUser.LastName != undefined ? this.selectedUser.FirstName +' '+ this.selectedUser.LastName :'';
    this.userRoleItem = 'Alaska benutzer: '+ curentUser +' löschen . . .';
    this.createUserRoleForm(userrole);
  }

  onSubmit(){
    this.formSubmited = true;
    console.log("this form: ", this.userRolesForm);
    let _userRole: iUserroles = this.getFormData();

    if(this.mode == 'add' || this.mode == 'create' || this.mode == 'edit'){
      this.dataService.getData('custom', 'getUserCustomerByUserId', _userRole.UsersId).subscribe(
        customer => {
          let resultCustomer: iCustomers = customer;
           //RolesId == 4 is vertreter
          if(resultCustomer && _userRole.RolesId == 4 && resultCustomer.CustomersGroup != 9992){
            this.dialogService.okDialog("Error", "Der Benutzer (id:"+_userRole.UsersId+") ist der Kundennummer "+resultCustomer.CustomerNumber+" und CustomersGroup: "+resultCustomer.CustomersGroup+" zugeordnet, diese gehört nicht zu Vertretern. " , "danger",'40%');
            return
          }

            if(this.mode == 'add' || this.mode == 'create'){
              this.dataService.PostData('custom',_userRole, 'postuserroles').subscribe(
                result => {
                    // this.userRole = result;
                    this.populateData(this.selectedUser);
                    this.onList();
                }, err => {
                    console.log('error inserting UserRole', err);
                }
              );
            }

            if(this.mode == 'edit'){
              this.dataService.PutData('custom',_userRole, 'putuserroles').subscribe(
                result => {
                    // this.userRole = result;
                    this.populateData(this.selectedUser);
                    this.onList();
                }, err => {
                    console.log('error editing UserRole', err);
                }
              );
            }

        }, err => {
            console.log('error getting users customer', err);
        }
      );

    }




    if(this.mode == 'delete'){
      this.dataService.RemoveData('custom',_userRole.UserrolesId, 'deleteUserRoles',).subscribe(
        result => {
            // this.userRole = result;
            this.populateData(this.selectedUser);
            this.onList();
        }, err => {
            console.log('error deleting UserRole', err);
        }
      );
    }

  }

  getFormData(): iUserroles{
    let _userRole: iUserroles =
    {
      UserrolesId: this.userRole != undefined ? this.userRole.UserrolesId : 0,
      RolesId: +this.userRolesForm.get('RolesId').value,
      UsersId: this.selectedUser != undefined ? this.selectedUser.UsersId : 0
    }
    return _userRole;
  }

  onCancel(){
    this.mode = 'list';
  }

}



  <div class="card m200">
    <div class="card-header">{{cardHeader}}</div>

    <div class="card-body">
      <div *ngIf="mode == 'login'" >


      <form  [formGroup]="loginForm" >
        <div class="row">
          <div class="form-group col-12">
            <mat-form-field appearance="outline" class="w-100" >
              <mat-label>Email</mat-label>
              <input matInput type="email"  formControlName="email" placeholder="email" [errorStateMatcher]="matcher" />
              <mat-error class="error-block" *ngIf="!loginForm.get('email').valid && loginForm.get('email').value != '' && (loginForm.get('email').touched  || formSubmited)"></mat-error>
            </mat-form-field>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Passwort</mat-label>
              <input (keydown.enter)="onSubmit()" matInput type="password" formControlName="password" placeholder="passwort" [errorStateMatcher]="matcher">
              <mat-error class="error-block" *ngIf="!loginForm.get('password').valid && loginForm.get('password').value != '' && (loginForm.get('password').touched  || formSubmited)"></mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
            <div class="col-6 "><div class="form-group"><button [disabled]="!loginForm.valid"  type="button" (keydown)="onSubmit()" (click)="onSubmit()" class="btn btn-primary float-left">    Login     </button></div></div>
            <div class="col-6 "><div class="form-group"><button (click)="onCancel()" class="btn btn-primary float-right">abrechen</button></div></div>
        </div>
        <div class=row>
          <div class="col-12 "><button (click)="onPassWordForget()" class="btn btn-light forget">passwort vergessen</button></div>
        </div>

      </form>
  </div>

  <!-- -------------------------------------------------------------------------------------------------------------- -->

  <div  *ngIf="mode == 'passwordreset'">
      <form    [formGroup]="resetForm" >

          <div class="form-group col-12">

            <mat-form-field class="w-100" appearance="outline">
              <input matInput formControlName="rEmail" type="email" placeholder="email" [errorStateMatcher]="matcher" ngModel email="true">
              <mat-error class="error-block" *ngIf="!resetForm.get('rEmail').valid && resetForm.get('rEmail').value != '' &&  (resetForm.get('rEmail').touched  || formSubmited)"></mat-error>
            </mat-form-field>

          </div>

          <div class="form-group col-12">
            <div class=row>

              <div class="col-md-6 "><div class="form-group"><button (click)="onSubmitPasswordReset()" class="btn btn-primary">zurücksetzen</button></div></div>
              <div class="col-md-6 "><div class="form-group"><button (click)="onCancelPasswordReset()" class="btn btn-primary">abrechen</button></div></div>
            </div>

          </div>
      </form>
  </div>

  </div>

</div>




import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import { iCustomers, iOrders, iShoppingcartitems, iShoppingcarts,iOpenitems } from 'src/app/models/AlaskaModels';
import { jsPDF } from "jspdf";
import html2Canvas  from 'html2canvas';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataService } from '../../Services/data.service';
import { environment } from 'src/environments/environment';

const ELEMENT_DATA: Array<iOpenitems> = [];
@Component({
  selector: 'app-openItemsDialog',
  templateUrl: './openItemsDialog.component.html',
  styleUrls: ['./openItemsDialog.component.scss']
})
export class OpenItemsDialogComponent implements OnInit {

  openItems: iOpenitems[]=[];
  selectedOpenItems: iOpenitems;
  selectedCustomer: iCustomers;
  selectedCustomerName:string;
  selectedShoppingCartsName:string;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatSort) sort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  optionValue:number = 1;
  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 50;


  displayedColumns:string[] = ['OpenItemId','OrderDate','DueDate', 'DebitCredit', 'Text','Amount'];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
              public openItemsDialogRef: MatDialogRef<OpenItemsDialogComponent>,
              private dataService:DataService,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit() {
    console.log('invoice dialog: ' , this.data);
    this.openItems = this.data && this.data.openItems? this.data.openItems: null;
    this.selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    this.datasource = new MatTableDataSource(this.openItems);


  }

  ngAfterViewInit() {
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
    this.matPaginatorIntl.itemsPerPageLabel = 'Artikel pro Seite:';
    this.matPaginatorIntl.firstPageLabel = 'Erste Seite';
    this.matPaginatorIntl.previousPageLabel = 'Vorherige Seite';
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';
  }

  onCancel(){
    this.openItemsDialogRef.close();

  }

  totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
    let totalRowSurcharge: number = 0;
    if( shoppingcartitem.Quantity % 1 != 0){
      let surchargeQuantity = shoppingcartitem.Quantity % 1 ;
      totalRowSurcharge =  shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100;
    }
    return totalRowSurcharge
  }

  totalAmount(): number{
    let soll = 0;
    let haben = 0

    if(this.datasource && this.datasource.data){
      this.datasource.data.forEach(row => {
        soll = row.DebitCredit == 1? soll + row.Amount : soll;
        haben = row.DebitCredit == 2? haben + row.Amount : haben;

      });
    }
    return soll - haben;
  }



}



import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { DataService} from '../../Services/data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { iCustomers, iDynamicorderrecords, iKeyValue, iMaterialgroups, iOrders, iPrintOptions, iShoppingcartitems, iShoppingcarts } from 'src/app/models/AlaskaModels';

import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

const ORDERDATA: Array<iShoppingcartitems> = [];

@Component({
  selector: 'app-printDialog',
  templateUrl: './printDialog.component.html',
  styleUrls: ['./printDialog.component.scss']
})
export class PrintDialogComponent implements OnInit {
  orderOptions: iKeyValue[] = [];
  printOption:iPrintOptions ;

  order: iOrders;
  selectedOrder: iOrders;
  selectedCustomer: iCustomers;
  selectedCustomerName:string = '';
  selectedShoppingCartsName:string = '';
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ORDERDATA);
  orderFormControl:FormControl = new FormControl(null, Validators.required);
  pipe = new DatePipe('de-DE');

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) orderPaginator: MatPaginator;

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  optionValue:string;
  optionValueNumber:number = 0;
  optionName:string;
  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 50;
  itemLength: number = 100;
  ShoppingCartStateId = 0;
  dialogType:string;

  displayedInvoiceColumns:string[] = ['ProductId','DescRow1','DescRow2', 'Quantity', 'Price', 'TotalPrice', 'Picture'];
  displayedColumns: string[] = [] ;


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dataService:DataService,
              public infoInputDialogRef: MatDialogRef<PrintDialogComponent>,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit() {

    console.log('invoice dialog: ' , this.data);
    this.selectedCustomer = this.data  && this.data.customer? this.data.customer: null;
    this.order = this.data  && this.data.order? this.data.order: null;
    this.dialogType = this.data  && this.data.dialogType? this.data.dialogType: '';

    this.selectedOrder = this.data && this.data.order? this.data.order: null;

    this.dataService.selectedCustomerSubject$.subscribe(
      customer => {
        this.selectedCustomer = customer;
      }, err => {console.log('error - selectedCustomer-subscription: ', err);}
    );

    this.selectedCustomerName = this.selectedCustomer? this.selectedCustomer.Name1:this.selectedCustomerName;
    this.selectedShoppingCartsName = this.selectedOrder? this.selectedOrder.ShoppingCarts.Name:this.selectedShoppingCartsName;
    this.date = this.dataService.getDate(new Date() , 'EEEE, dd.MM.yyyy');

    let dDate = this.selectedOrder?this.selectedOrder.DeliveryDate:null;

    this.deliveryDate =  dDate? this.dataService.getDate(dDate , 'EEEE, dd.MM.yyyy'): this.deliveryDate;

    this.displayedColumns = this.displayedInvoiceColumns;

    this.datasource.data = this.order && this.order.ShoppingCarts && this.order.ShoppingCarts.Shoppingcartitems? this.order.ShoppingCarts.Shoppingcartitems: [];
    this.datasource.sort = this.sort;
    this.datasource.paginator =  this.orderPaginator ;
    this.ShoppingCartStateId = this.order && this.order.ShoppingCarts ? this.order.ShoppingCarts.ShoppingCartStateId:this.ShoppingCartStateId;

    this.ShoppingCartStateId = 8
    this.ShoppingCartStateId = this.dialogType == 'freigegeben' || this.dialogType == 'nicht-freigegeben'? 8:this.ShoppingCartStateId;
    this.ShoppingCartStateId = this.dialogType == 'Angebote'? 7:this.ShoppingCartStateId;
    this.ShoppingCartStateId = this.dialogType == 'Vorlagen'? 9:this.ShoppingCartStateId;

    if(this.ShoppingCartStateId == 7){
      this.orderOptions.push({Key: 'Angebot', Value: '1_Angebot'},{Key: 'Angebot ohne Preise', Value: '2_Angebot'},{Key: 'Angebot ohne Bilder', Value: '3_Angebot'});
    }
    if(this.ShoppingCartStateId == 8){
      this.orderOptions.push({Key: 'Bestellauskunft', Value: '4_Bestellauskunft'},{Key: 'Bestellauskunft ohne Preise', Value: '5_Bestellauskunft'},{Key: 'Bestellauskunft ohne Bilder', Value: '6_Bestellauskunft'});
    }
    if(this.ShoppingCartStateId == 9){
      this.orderOptions.push({Key: 'Bestellvorlage', Value: '7_Bestellvorlage'},{Key: 'Bestellvorlage ohne Preise', Value: '8_Bestellvorlage'},{Key: 'Bestellvorlage ohne Bilder', Value: '9_Bestellvorlage'});
    }

    // this.optionValue =  this.getPrintOptionValue()
    //  if(this.optionValue){ setTimeout(() => (this.onSelectionChange(this.optionValue)), 0);}

  }
  getPrintOptionValue():string{
    this.printOption = JSON.parse(localStorage.getItem('printOptions')) ;
    if(this.printOption == undefined){
      this.printOption = {  OrdersatzPdf:'',  OrdersatzExcelAndCsv:'', WarenkorbkPdf:'', AuftrgssamlerPdf:''}
      localStorage.setItem('printOptions', JSON.stringify(this.printOption))
    }
    return this.printOption.AuftrgssamlerPdf;
  }
ordersDisplayOption = '6_Bestellauskunft'
  ngAfterViewInit() {

    this.datasource.sort = this.sort;
    this.datasource.paginator = this.orderPaginator;
    this.matPaginatorIntl.nextPageLabel = 'Nächste Seite';
    this.matPaginatorIntl.lastPageLabel = 'Letzte Seite';
    this.ordersDisplayOption = this.ShoppingCartStateId == 8? '6_Bestellauskunft': this.ordersDisplayOption;
    this.ordersDisplayOption = this.ShoppingCartStateId == 7? '3_Angebot': this.ordersDisplayOption;
    this.ordersDisplayOption = this.ShoppingCartStateId == 9? '9_Bestellvorlage': this.ordersDisplayOption;

    this.optionValue =  this.getPrintOptionValue();
    this.optionValue =  this.optionValue == undefined || this.optionValue == ''?  this.ordersDisplayOption : this.optionValue ;
    if(this.optionValue){ setTimeout(() => (this.onSelectionChange(this.optionValue)), 0);}

    // this.onSelectionChange(defaultOption);


  }

  onSelectionChange(selectedOptionValue:string){
    if(this.optionValue == undefined || this.optionValue == ''){return;}
    this.optionValue = selectedOptionValue;
    this.optionValueNumber = +selectedOptionValue.split('_')[0];
    this.optionName = selectedOptionValue.split('_')[1];
    this.title =  this.optionName;
    this.displayedColumns = this.displayedInvoiceColumns;
    this.pageSize = 50;


    let ofDate:string =this.selectedOrder && this.selectedOrder.CreateDate ? ' vom '+ this.pipe.transform(this.selectedOrder.CreateDate,'dd.MM.yyyy'): '';

    if(this.selectedCustomer != undefined && this.optionValueNumber && (this.optionValueNumber == 1 || this.optionValueNumber == 2 || this.optionValueNumber == 3)){
    }
    if(this.selectedCustomer != undefined && this.optionValueNumber  && (this.optionValueNumber == 4 || this.optionValueNumber == 5 || this.optionValueNumber == 6)){
    }
    if(this.selectedCustomer != undefined && this.optionValueNumber  && (this.optionValueNumber == 7)){

    }
    if(this.optionValueNumber  && (this.optionValueNumber == 2 || this.optionValueNumber == 5 || this.optionValueNumber == 8) ){
      this.displayedColumns = this.displayedInvoiceColumns.filter(x => x != 'Price' && x != 'PosDiscount' && x != 'TotalDiscount' && x != 'Surcharge' && x !='TotalPrice')

    }
    if(this.optionValueNumber  && (this.optionValueNumber == 3 || this.optionValueNumber == 6 || this.optionValueNumber == 9) ){
      this.displayedColumns = this.displayedInvoiceColumns.filter(x => x != 'Picture')

    }

    let prefix = this.dialogType == 'freigegeben'? 'Freigegebene Auftrag' :'';
        prefix = this.dialogType == 'nicht-freigegeben'? 'Nicht freigegebene Auftrag ' : prefix;
        prefix = this.dialogType == 'gelöscht'? 'gelöschte Auftrag ' :prefix;
        prefix = this.dialogType == 'Angebote'? 'Angebote ' :prefix;
        prefix = this.dialogType == 'Vorlagen'? 'Vorlagen ' :prefix;
        prefix = this.dialogType == 'alle'? 'Bestellauskunft ' :prefix;

    this.title = this.selectedCustomer? prefix  + ' für '+ this.selectedCustomer.Name1 + ofDate  :this.title;

    this.printOption.AuftrgssamlerPdf = this.optionValue;
    localStorage.setItem('printOptions', JSON.stringify(this.printOption))

  }

  getNetPrice() {
    return this.datasource.data.map(t => t.Price * t.Quantity).reduce((acc, value) => acc + value, 0);
  }

  getTotalPrice() {
    return this.datasource.data.map(t => t.Price  * t.Quantity * 1.07).reduce((acc, value) => acc + value, 0);
  }

  onCancel(){
    this.infoInputDialogRef.close();
  }

    totalRowDiscount(shoppingcartitem: iShoppingcartitems): number{
    return shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.PosDiscount / 100;
  }

  totalRowPrice(shoppingcartitem: iShoppingcartitems): number{
    return (shoppingcartitem.Price * shoppingcartitem.Quantity) - this.totalRowDiscount(shoppingcartitem) ;
  }

  totalRowSurcharge(shoppingcartitem: iShoppingcartitems): number{
    let totalRowSurcharge: number = 0;
    if( shoppingcartitem.Quantity % 1 != 0){
      let surchargeQuantity = shoppingcartitem.Quantity % 1 ;
      totalRowSurcharge =  shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.Product.QuantityUnitsPartialPackageCharge / 100;
    }
    return totalRowSurcharge
  }

  totalPriceSum(): number{
    let totalPrice: number = 0;
    let totalNatoPrice: number = 0;
    let totalDiscount: number = 0;
    let totalSurCharge: number = 0;
    let lieferkosten: number = 0;

    if(this.datasource && this.datasource.data){
      this.datasource.data.forEach(row => {
          totalPrice = totalPrice + this.totalRowPrice(row);
          totalDiscount = totalDiscount + this.totalRowDiscount(row);
          totalSurCharge = totalSurCharge + this.totalRowSurcharge(row);
      });
    }
    totalNatoPrice = (totalPrice + totalSurCharge - totalDiscount);
    return +totalNatoPrice.toFixed(2);
  }

  getDynamicOrdersHead(dynamicorderrecords: iDynamicorderrecords[]):iDynamicorderrecords[]{
        let  dyn: iDynamicorderrecords[] = [];
          if(dynamicorderrecords){
            dynamicorderrecords.forEach(element => {
              let existHeadDynamicorderrecords = dyn.find(x => x.MaterialGroupId == element.MaterialGroupId);
                      if(existHeadDynamicorderrecords == undefined){
                            dyn.push(element)
                      }

            });
          }
             return dyn;
  }

  getTop3DynRecords(dynamicOrders: iDynamicorderrecords[], dynamicOrder: iDynamicorderrecords):iDynamicorderrecords[]{
    let indexes: number[] = [1,2,3];
    return dynamicOrders.filter(x => x.MaterialGroupId == dynamicOrder.MaterialGroupId && indexes.includes(dynamicOrder.RowIndex));

  }

}

<footer class="card text-white">
  <div class="row">
    <div class="col-4">
      Copyright © 2020 ALASKA Tiefkühlkost GmbH <br>
      ALASKA Tiefkühlkost GmbH <br>
      Kauritzerstr. 29, 04639 Gößnitz<br>
    </div>
    <div class="col-4">
      HRB 201850 Amtsgericht Jena <br>
      GF: Iris Porzig, Gert Hößelbarth <br>
      Steuer-Nr. 16110502854 <br>
      USt-Id-Nr. DE 150510865 <br>
    </div>
    <div class="col-4">
      Tel:      034493/22588 <br>
      Fax:     034493/36532 <br>
      e-mail:    alaska-tkk@t-online.de <br>
      Internet:  www.alaskaonline.de <br>
    </div>
  </div>
  </footer>

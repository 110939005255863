
import {Component, OnInit,ViewChild,ElementRef,Input,AfterViewInit,} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {iOrders, iCustomers, iTitles, iGenders, iRegistrationstypes, iShoppingcartitems, iPositions, iInputOptions, iUsers, iOrderStates, iRoles, iShoppingcarts, iDynamicorderrecords, iProducts, iQuantityunits, iMaterialgroups, iDynamicOrdersParameterBag, iExtendedDynamicOrders, iProductstocks, iSupplierproductorders, iCustomerproductorders, iParameterBag} from '../models/AlaskaModels';
import { InputErrorStateMatcher} from '../models/InputErrorStateMatcher';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataService } from '../Services/data.service';
import { DialogService } from '../Services/dialog.service';
import { MatPaginator } from '@angular/material/paginator';

import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ErrorStateMatcher} from '@angular/material/core';
import { SnackbarService } from '../Services/snackbar.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { getFileSystem } from '@angular/compiler-cli/src/ngtsc/file_system';
import { readFile, stat, writeFile } from 'fs';
import { stringify } from 'querystring';

import { fileURLToPath } from 'url';
import { MatSelectSearchVersion } from 'ngx-mat-select-search';
import * as FileSaver from 'file-saver';
import {HttpClient, HttpParams, HttpRequest, HttpEvent} from '@angular/common/http';
import { IdbService } from '../Services/idb.service';
import { ProductsComponent } from '../products/products.component';
import { Location, formatDate,  DatePipe, registerLocaleData  } from "@angular/common";
import localeDe from '@angular/common/locales/de';
import { ImgDialogComponent } from '../imgDialog/imgDialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { take } from 'rxjs/operators';
import { forEachChild } from 'typescript';
import { Observable, Subscription, fromEvent } from 'rxjs';
registerLocaleData(localeDe, 'de');


const ELEMENT_DATA: Array<iDynamicorderrecords> = [];
const DOR_ELEMENT_DATA: Array<iDynamicorderrecords> = [];
const DOR_HEAD_ELEMENT_DATA: Array<iDynamicorderrecords> = [];
 const ELEMENT_DATA_DYNAMICORDERS: Array<iDynamicorderrecords> = [];

@Component({
  selector: 'app-orderables',

  styleUrls: ['./orderables.component.scss'],
  templateUrl: './orderables.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrderablesComponent implements OnInit {
  // }

  isTableExpanded = false;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  allDetailState:string = 'collapsed';


  formatedDate:string;
  pipe = new DatePipe('de-DE');
  loggedInUser: iUsers;
  loggedInRoleIds: number[] = [];
  selectedOrder: iOrders;
  selectedCustomer: iCustomers;
  item: string = 'Ordersatz';
  deliveryDate: Date;
  idbProducts: iProducts[] = [];
  customerProducts: iProducts[] = [];
  RowIndexes: number[] = [1];
  filterBy0: string = "";
  filterBy1: string = "";
  filterBy2: string = "";
  IMAGE_URL = environment.IMAGE_URL;
  currentMaterialGroupRowIndex: number = 0;
  selectedShoppingCart: iShoppingcarts;

  datasource = new MatTableDataSource(ELEMENT_DATA);
  dorheaddatasource = new MatTableDataSource(DOR_HEAD_ELEMENT_DATA);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) dorPaginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) dorSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) dorHeadPaginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) dorHeadSort: MatSort;

  formSubmited: boolean = false;

  columnsToDisplay: string[] = ['Bezeichnung'];
  dummyColumns:string[]=['a'];
  displayedColumns: string[] = ['Artikel', 'ProcessCode', 'Date', 'Name', 'NextQuantity','Unit','Info','Spec',  'Picture', 'Price', 'ListPrice', 'StockQuantity', 'Quantity',   'Action'];
  displayedColumns_head: string[] = ['Artikel_head', 'ProcessCode_head', 'Date_head', 'Name_head','NextQuantity_head', 'Unit_head','Info_head','Spec_head', 'Picture_head', 'Price_head', 'ListPrice_head', 'StockQuantity_head', 'Quantity_head',   'Action_head'];
  dynamicOrderRecords: iDynamicorderrecords[] = [];
  idbActiveProducts: iProducts[]=[];
  notifiedbProducts: iProducts[]=[];
  productsWithProcessCode98 : iProducts[]=[];
  panelOpenState = true;
  inputOptions: iInputOptions;
  Quantityunits: iQuantityunits[]=[];
  checkedItems: iShoppingcartitems[] = [];
  MaterialGroups:iMaterialgroups[] = [];
  shopingCartItemsArray: iShoppingcartitems[]= [];
  isLoading: boolean = false;
  step = 0;
  customerNumberInput: number;
  // tableHeaderWidth: string = '100% !important;'


  constructor(private dataService: DataService,
              private dialog: MatDialog,
              private formBuilder: FormBuilder,
              private dialogService: DialogService,
              private snackbarService: SnackbarService,
              private idbService: IdbService,
              private http: HttpClient
            ) {}

  setStep(index: number) {
    this.step = index;
  }

  hasRoles(roles: number[]):boolean {
    return this.dataService.hasRoles(roles)
  }

  updateDatasourceFromIdbProducts(){

  }


  ngAfterViewInit(){

  }

  // parameterBagSubject: iParameterBag = {};
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  smallDeviceSize = 1000;
  changeSmallDeviceMode = false;
  changeDeviceMode(){
    this.smallDeviceSize = localStorage.getItem('smallDeviceSize')? +localStorage.getItem('smallDeviceSize') : this.smallDeviceSize;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.changeSmallDeviceMode = this.windowWidth  < this.smallDeviceSize? true : false;
    if( this.changeSmallDeviceMode == true){
      this.displayedColumns= ['Artikel', 'ProcessCode', 'Date', 'Name', 'NextQuantity','Action','Unit','Info','Spec',  'Picture', 'Price', 'ListPrice', 'StockQuantity', 'Quantity'];
      this.displayedColumns_head = ['Artikel_head', 'ProcessCode_head', 'Date_head', 'Name_head','NextQuantity_head', 'Action_head', 'Unit_head','Info_head','Spec_head', 'Picture_head', 'Price_head', 'ListPrice_head', 'StockQuantity_head', 'Quantity_head', 'Action_head'];
    }else{
      this.displayedColumns  = ['Artikel', 'ProcessCode', 'Date', 'Name', 'NextQuantity','Unit','Info','Spec',  'Picture', 'Price', 'ListPrice', 'StockQuantity', 'Quantity',   'Action'];
      this.displayedColumns_head = ['Artikel_head', 'ProcessCode_head', 'Date_head', 'Name_head','NextQuantity_head', 'Unit_head','Info_head','Spec_head', 'Picture_head', 'Price_head', 'ListPrice_head', 'StockQuantity_head', 'Quantity_head',   'Action_head'];
    }
  }

ngOnInit() {
      this.changeDeviceMode();
      this.resizeObservable$ = fromEvent(window, 'resize')
      this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
        this.changeDeviceMode();
      })

    this.step = +this.panelOpenState;
    this.displayedColumns = this.dataService.hasRoles([1,2,3,4]) == false? this.displayedColumns: this.displayedColumns.filter(x => x != 'Available');

    this.selectedCustomer = JSON.parse( localStorage.getItem('selectedCustomer') );
    this.deliveryDate = new Date(localStorage.getItem('deliveryDate'));

    this.dataService.loggedInUserSubject$.subscribe(
      user => {
        this.loggedInUser = user;

      }, err => {
        console.log("error loggedin user: ", err);
      });

      this.dataService.selectedCustomerSubject$.subscribe(
        customer => {
          this.selectedCustomer = customer
          this.selectedCustomer = this.selectedCustomer == undefined? JSON.parse(localStorage.getItem('selectedCustomer')):this.selectedCustomer;


        }, err =>{
            console.log("error selected customer: msg: ", err);
        }
        );

        this.dataService.shoppingCartSourceSubject$.subscribe(
          shoppingcart => {
          }
        );


        this.dataService.dynamicOrderRecordsSourceSubject$.subscribe(
          dynamicOrderRecords => {
            if(dynamicOrderRecords){
              this.dynamicOrderRecords = dynamicOrderRecords;
              this.populateData(this.selectedCustomer);
            }

          }
        );



        this.dataService.productsQuantitySubject$.subscribe(
          product => {

            let dyn: iDynamicorderrecords[] = this.datasource.data.filter(x => x.Product.ProductsId == product.ProductsId );

          }
        );

      this.dataService.deliveryDateSubject$.subscribe(
        deliveryDate => {
          this.deliveryDate = deliveryDate;
          this.deliveryDate =  new Date(this.pipe.transform(this.deliveryDate,'yyyy-MM-dd', 'GMT+0200', 'de-DE'));
        }
      );

      this.dataService.activeProductsSubject$.subscribe(
          products => {
            this.idbActiveProducts = products;
            console.log("orderable this.activeIdbProducts ", this.idbActiveProducts );

          }, err =>{
                console.log("error roderable products ", err);
          }
      );

      this.dataService.productsWithProcessCode98$.subscribe(
          productsWithProcessCode98 => {
              this.productsWithProcessCode98 = productsWithProcessCode98;
          }, err =>{
                console.log("error orderable productsWithProcessCode98 ", err);
          }
      );




        this.dataService.msgSubject$.subscribe(
          msg => {
            if(msg.Name.toLowerCase() == "idbmessage" && msg.Source.toLowerCase() == "setDynamicOrdersCurrentPrice" && msg.Type.toLowerCase() == "products" && msg.Message.toLowerCase() == "setDynamicOrdersCurrentPrice"){

              console.log("orderable this.customerProducts");
            }

            if(msg.Name.toLowerCase() == "applyfilter"){
              this.datasource.filterPredicate = (data: iDynamicorderrecords , filter: string) => (JSON.stringify(data).toLowerCase().indexOf(filter)) != -1;
              this.datasource.filter = msg.Message.trim().toLowerCase();
              setTimeout(() => { this.focusFirstOrderableRow(this.datasource.filteredData);   }, 0);

            }
          if (msg.Name.toLowerCase() == 'shoppingcartquantityedited') {

          }

            if (msg.Name.toLowerCase() == 'applyproductfilter') {
              let filterArray = msg.Message

                .trim()
                .toLowerCase()
                .split(/(\s+)/)
                .filter((e) => e.trim().length > 0);
              let keyType = msg.Type.toLowerCase();
              let action = msg.Action.toLowerCase();
              let filter0 =    filterArray && filterArray[0] != undefined ? filterArray[0] : '';
              let filter1 =    filterArray && filterArray[1] != undefined ? filterArray[1] : '';
              let filter2 =    filterArray && filterArray[2] != undefined ? filterArray[2] : '';
this.filterBy0=keyType != 'enter' || action == 'clear'? '': filter0 ;
this.filterBy1=filter1;
this.filterBy2=filter2;

if(filter0 == '' && filter1 == '' && filter2 == ''){ this.datasource.data.forEach(element => {  element.detailExpandState = 'collapsed';    }); }

              this.datasource.filter = msg.Message.trim().toLowerCase();
              this.datasource.sort = this.dorSort;
              this.datasource.paginator = this.dorPaginator;

              this.datasource.filterPredicate = (data: iDynamicorderrecords, filter: string) => {
                if(filter0 != '' && filter1 == '' && filter2 == ''){

                  return JSON.stringify( data.Product.ProductNumber +' '+ data.Product.DescRow1 +' '+ data.Product.DescRow2 +' '+ data.Product.Description).toLowerCase().indexOf(filter0) != -1;

                }
                if(filter0 != '' && filter1 != '' && filter2 == ''){

                  return (
                    JSON.stringify( data.Product.ProductNumber +' '+ data.Product.DescRow1 +' '+ data.Product.DescRow2 +' '+ data.Product.Description).toLowerCase().indexOf(filter0) != -1 &&
                    JSON.stringify( data.Product.ProductNumber +' '+ data.Product.DescRow1 +' '+ data.Product.DescRow2 +' '+ data.Product.Description).toLowerCase().indexOf(filter1) != -1 );

                }
                if(filter0 != '' && filter1 != '' && filter2 != ''){

                  return (
                    JSON.stringify( data.Product.ProductNumber +' '+ data.Product.DescRow1 +' '+ data.Product.DescRow2 +' '+ data.Product.Description).toLowerCase().indexOf(filter0) != -1 &&
                    JSON.stringify( data.Product.ProductNumber +' '+ data.Product.DescRow1 +' '+ data.Product.DescRow2 +' '+ data.Product.Description).toLowerCase().indexOf(filter1) != -1 &&
                    JSON.stringify( data.Product.ProductNumber +' '+ data.Product.DescRow1 +' '+ data.Product.DescRow2 +' '+ data.Product.Description).toLowerCase().indexOf(filter2) != -1);

                }


              };



              setTimeout(() => {
                              if (  (msg.Type.toLowerCase() == 'enter' ||  msg.Type.toLowerCase() == 'numpadenter' ||  msg.Action.toLowerCase() == 'clear' ) && this.datasource.filter == msg.Message.trim().toLowerCase()      ) {
                                this.focusFirstOrderableRow(this.datasource.filteredData);

                              }
                            }, 0);

            }
          });

          this.populateData(this.selectedCustomer);
  }


  private uploadFiles() {
}

  onCopy2ShoppingCart(dynamicOrderRecord: iDynamicorderrecords){
    if(dynamicOrderRecord && dynamicOrderRecord.Product){
      this.idbService.insertOrUpdateCustomerShoppingCart(this.selectedCustomer, this.deliveryDate,dynamicOrderRecord.Product);
    }
  }

  onCopy2ShoppingCart_x(dynamicOrderRecord: iDynamicorderrecords){

    if(dynamicOrderRecord && dynamicOrderRecord.Product){
      if( dynamicOrderRecord.Product.QuantityInput != 0){ }

        this.idbService.insertOrUpdateCustomerShoppingCart(this.selectedCustomer, this.deliveryDate,dynamicOrderRecord.Product);

    }else{
      console.log('dynamic order product is null or empty', dynamicOrderRecord)
    }

  }


  sortByName(datasource: any[], sortBy:string):any[] {

    return datasource.sort((a, b) => a[sortBy]> b[sortBy]? 1 : a[sortBy] === b[sortBy] ? 0 : -1);

  }

setUpDynamicOrders(dynamicorderrecords: iDynamicorderrecords[]){
  if(dynamicorderrecords){

    this.dynamicOrderRecords = dynamicorderrecords;
            this.datasource.data = [];
            this.dorheaddatasource.data = [];

            this.dynamicOrderRecords = this.dynamicOrderRecords.sort((a, b) =>  a.MaterialGroupId < b.MaterialGroupId ? -1 : 1);

            let  dynamicOrdersLS: iDynamicorderrecords[] = JSON.parse( localStorage.getItem(this.selectedCustomer.CustomersId.toString() +'_dynamicOrders'));
            this.datasource.data = this.dynamicOrderRecords;
            this.datasource.sort = this.sort;
            this.datasource.paginator = this.paginator;

            if(this.datasource.data && this.datasource.data.length > 0 && dynamicOrdersLS == undefined){

              this.getDatasourceHead(this.datasource.data);

                if(this.datasource.filteredData && this.datasource.filteredData.length > 0){
                  setTimeout(() => {  this.focusFirstOrderableRow(this.datasource.filteredData);   }, 0);

                }
            }
  }
}


  populateData(customer: iCustomers){

    this.dorheaddatasource.data = [];
    this.datasource.data = [];

    this.idbService.getIdbPromisDynamicOrderRecords(customer).then(
      (dynamicOrderRecords) => {

        this.dynamicOrderRecords = dynamicOrderRecords;
        if(this.dynamicOrderRecords && this.dynamicOrderRecords.length > 0){


          this.setUpDynamicOrders(dynamicOrderRecords);

          this.selectedShoppingCart = this.idbService.getActiveCustomerShoppingCart(customer);
          if(this.selectedShoppingCart && this.selectedShoppingCart.Shoppingcartitems){
                this.selectedShoppingCart.Shoppingcartitems.forEach(element => {
                  if(this.datasource.data.find(x => x.ProductId == element.ProductId)){
                    this.datasource.data.find(x => x.ProductId == element.ProductId).Product.QuantityInput = element.Product.QuantityInput;
                  }
                });
          }

        }
        else if(this.dynamicOrderRecords && this.dynamicOrderRecords.length == 0){
          this.idbService.createIdbDynamicRecords(customer);
        }
      }
    );

  }

  getDatasourceHead(dataSource: iDynamicorderrecords[]):iDynamicorderrecords[]{

      let  dynamicOrdersHead: iDynamicorderrecords[] = [];
    if(dataSource && dataSource.length > 0 ){

      dataSource.forEach(element => {
        let existHeadDynamicorderrecords = dynamicOrdersHead.find(x => x.MaterialGroupId == element.MaterialGroupId);
                if(existHeadDynamicorderrecords == undefined){
                  dynamicOrdersHead.push(element)
                }
      });
         this.dorheaddatasource.data = dynamicOrdersHead;
    }

    return dynamicOrdersHead;
  }

  getIcon(quantity: number, type: string): string {
    return this.dataService.getIcon(quantity,type);
  }

  bigOrSmall(quantity:number):string{
    return this.dataService.bigOrSmall(quantity);
  }

  getBg(quantity: number, type: string): string {
    return this.dataService.getBg(quantity,type);
  }


  getDynamicRecordByMaterialGroupId(dyn: iDynamicorderrecords, dordatasources: iDynamicorderrecords[]){
    let dordatasourcesfiltered: iDynamicorderrecords[]= [];
    if(dyn && dordatasources){
      dordatasourcesfiltered= dordatasources.filter(x => x.MaterialGroupId == dyn.MaterialGroupId && x.MaterialGroupName == dyn.MaterialGroupName )
    }
    return dordatasourcesfiltered;
  }





  isStaffUser(orderForm: FormGroup): boolean{

return true
  }

  onInputEdit(dynamicOrderrecords: iDynamicorderrecords,selfPrefix:string, parentIndex?:number, rowIndex?:number){

    console.log("edited orderable: ",dynamicOrderrecords);

       dynamicOrderrecords.Quantity = +dynamicOrderrecords.Quantity.toFixed(2);

  }

  onExpansionPanelKlick(materialgroup: iMaterialgroups, dynamicOrder?: iDynamicorderrecords){

  }

  onPreventStep(item: iOrders, $event?: any ){
    if($event.keyCode == 38 || $event.keyCode == 40){ //up or down key
      $event.preventDefault();

      return;  }
  }
accordionId:number = 0;
  onOrderableKeyUp(item: iDynamicorderrecords, selfPrefix:string, parentIndex?:number, $event?: any, rowIndex?: number ) {

    let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 73, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90, 83,   71, 81, 85, 82];
    if( $event && $event.keyCode == 27 || $event.keyCode == 13 || $event.keyCode == 37 || $event.keyCode == 38 || $event.keyCode == 39 || $event.keyCode == 40 )
    {

      if($event.keyCode == 13 && $event.target && $event.target.type  != 'number'){
      }
      if(item && $event.target.localName != "input"){
        rowIndex = this.datasource.filteredData.findIndex(x => x.MaterialGroupId == item.MaterialGroupId);
        this.onOrderableKeyUpEvent(item, selfPrefix, parentIndex, $event, rowIndex);
      }

    }
  }

  onOrderableKeyUpEvent(item?: iDynamicorderrecords, selfPrefix?:string, accordionIndex?:number, $event?: any,dynamicOrdersRowIndex?: number ){

    let code = $event != undefined? $event.code: undefined;
    let focusItem: iMaterialgroups;
    this.currentMaterialGroupRowIndex = this.datasource.filteredData.findIndex(row => row === item);
    let nextIndex: number;
    var materialGroupRow = document.getElementById(selfPrefix + '-' + dynamicOrdersRowIndex.toString());
    let materialGroupId = item? item.MaterialGroupId: undefined;


    if(code == 'Escape'){

      var panel = document.getElementById(selfPrefix + '-' + this.currentMaterialGroupRowIndex.toString());
      if(panel){
        setTimeout(() => panel.click(), 0);
      }
      return
    }else if(code == 'Enter'   || code == 'NumpadEnter'){


          if(materialGroupRow){
            setTimeout(() => materialGroupRow.click(), 0);
          }

        if(materialGroupId != undefined){
            this.goToNextInput('OrderableDetailQuantityInput-' + materialGroupId,0,0);
        }


    }else if(code == 'ArrowDown'){

      if(dynamicOrdersRowIndex != undefined){
        this.goToNextInput('OrderableRowInput',dynamicOrdersRowIndex,1);
        this.goToNextRow('OrderableRow',dynamicOrdersRowIndex,1);
      }
    }
    else if(code == 'ArrowUp'){
      if(dynamicOrdersRowIndex != undefined){
        this.goToNextInput('OrderableRowInput',dynamicOrdersRowIndex, -1);
        this.goToNextRow('OrderableRow',dynamicOrdersRowIndex, -1);
      }
    }
    if(focusItem){

    }

}

getIndexByMaterialGroupsId(materialGroupId: number): number{

  let rowIndex: number = this.dorheaddatasource.filteredData.findIndex(x => x.MaterialGroupId == materialGroupId);

  return rowIndex ? rowIndex: 0;

}


coloredRowId: string = '';
focustedOrderInputId: string = '';
focusedOrderRowId: string = '';
coloredRowIndex: number=0;
focusedRowIndex: number=0;

// focusFirstOrderableDetailRow(dynRecords: iDynamicorderrecords){
//       let detailRow = document.getElementById('OrderableRow-0');
//     if(detailRow)
//     {
//        setTimeout(() => detailRow.click(), 0);
//        this.goToNextInput('OrderableDetailQuantityInput-'+dynRecords.MaterialGroupId.toString(), 0, 0);
//     }
// }

// focusFirstRow(dynRecords: iDynamicorderrecords[]){
//   if(dynRecords != undefined && dynRecords.length > 0)
//   {
//     // this.goToNextInput('OrderableRowInput', 0, 0);
//     // this.goToNextRow('OrderableRow', 0, 0);

//     // setTimeout(() => this.focusFirstOrderableDetailRow(dynRecords[0]), 0);

//     // let activeAccordion = document.getElementById('OrderableRowInput-' + dynRecords[0].MaterialGroupId.toString());
//     // let activeInputRow = document.getElementById('OrderableDetailQuantityInput-'+ dynRecords[0].MaterialGroupId.toString() +'-0');
//     // let activeRow = document.getElementById('OrderableRow-' + dynRecords[0].MaterialGroupId.toString());

//     // if(activeAccordion){activeAccordion.click();}
//     // if(activeInputRow){activeInputRow.focus();}
//     // setTimeout(() =>{if(activeRow){ activeRow.style.backgroundColor = '#ccffcc'}}, 0);


//   }
// }

// onFocusClickedInput(dor: iDynamicorderrecords, prefix?: string, rowIndex?:number){

//   //  this.clearRowBgColor(this.coloredRowIndex);
//   //  this.goToNextInput('OrderableRowInput', rowIndex, 0);
//   //  this.goToNextRow('OrderableRow', rowIndex, 0);

//        // this.dorheaddatasource.data.forEach(element => {
//     //   if(element.detailExpandState = 'expanded'){
//     //     let row = document.getElementById('OrderableRow-' + element.MaterialGroupId.toString());
//     //     setTimeout(() =>{if(row){ row.style.backgroundColor = ''}}, 0);
//     //     element.detailExpandState = 'collapsed'
//     //   }
//     // });

//     // let nextInput = document.getElementById('OrderableDetailQuantityInput-'+ dor.MaterialGroupId.toString() +'-0');
//     // let nexRow = document.getElementById('OrderableRow-' + dor.MaterialGroupId.toString());
//     // setTimeout(() =>{if(nextInput){nextInput.focus();} }, 0);
//     // setTimeout(() =>{if(nexRow){ nexRow.style.backgroundColor = '#ccffcc'}}, 0);

// }

clearRowBgColor(rowIndex?:number){
  let rowId = 'rowSC-' + (rowIndex).toString();
  let row = document.getElementById(rowId);
  if(row){
    setTimeout(() => (row.style.backgroundColor = ''), 0);
  }
}

clearAllRowBgColor(materialGroups: iMaterialgroups[]){
  if(materialGroups){
    materialGroups.forEach(materialGroupElement => {
    var materialGroupRow = document.getElementById('OrderableRow-' + this.getIndexByMaterialGroupsId(materialGroupElement.MaterialGroupId).toString());
    if(materialGroupRow){
      setTimeout(() => (materialGroupRow.style.backgroundColor = ''), 0);
    }
    });
  }
}

 goToNextInput(prefix?: string, rowIndex?:number, nexOrPrevious?: number):boolean{
  let inputId = prefix+'-' + rowIndex.toString();
  let inputNextId = prefix+'-' + (rowIndex + nexOrPrevious).toString();
  let inputPreviousId = prefix+'-' + (rowIndex - 1).toString();
  let success = false;

  let currentInput = document.getElementById(inputId);
  let nextInput = document.getElementById(inputNextId );
  let previousInput = document.getElementById(inputPreviousId );

  if (nextInput) {
    setTimeout(() => nextInput.focus(), 0);
    this.focusedRowIndex = rowIndex + nexOrPrevious;
    success = true;
  }
  // else if(previousInput){
  //   setTimeout(() => previousInput.focus(), 0);
  // }

  return success;
}

goToNextRow(prefix?: string, rowIndex?:number, nexOrPrevious?: number){
  this.coloredRowId = prefix + '-' + rowIndex.toString();
  let rowNextId = prefix + '-' +  (rowIndex + nexOrPrevious).toString();
  let rowPreviousId = prefix + '-' +  (rowIndex - 1).toString();

  let matTable = document.getElementById('matTable');
  let currentRow = document.getElementById(this.coloredRowId);
  let nextRow = document.getElementById(rowNextId);
  let previousRow = document.getElementById(rowPreviousId );

  if (nextRow) {
     setTimeout(() => (currentRow.style.backgroundColor = ''), 0);
    setTimeout(() => (nextRow.style.backgroundColor = '#ccffcc'), 0);
    this.coloredRowIndex = rowIndex + nexOrPrevious;

  }else if(previousRow){
     setTimeout(() => (currentRow.style.backgroundColor = ''), 0);
    setTimeout(() => (previousRow.style.backgroundColor = '#ccffcc'), 0);
    this.coloredRowIndex = rowIndex - 1;
  }
}

focusFirstOrderableRow(dynRecords?: iDynamicorderrecords[]){
  if(dynRecords != undefined && dynRecords.length > 0)
  {

    dynRecords.forEach(element => {  element.detailExpandState = 'collapsed';    });
    dynRecords[0].detailExpandState = 'expanded';
    let activeInputRow = document.getElementById('OrderableDetailQuantityInput-'+ dynRecords[0].MaterialGroupId.toString() +'-0');
    if(activeInputRow){activeInputRow.focus();}


    // let activeAccordion = document.getElementById('OrderableRowInput-' + dynRecords[0].MaterialGroupId.toString());
    // let activeInputRow = document.getElementById('OrderableDetailQuantityInput-'+ dynRecords[0].MaterialGroupId.toString() +'-0');
    // if(activeAccordion && dynRecords[0].detailExpandState  == 'collapsed'){activeAccordion.click();}
    // if(activeInputRow){activeInputRow.focus();}

    // if(activeAccordion){activeAccordion.click();}
    // let activeRow = document.getElementById('OrderableRow-' + dynRecords[0].MaterialGroupId.toString());
    // setTimeout(() =>{if(activeRow){ activeRow.style.backgroundColor = '#ccffcc'}}, 0);

  }
}

focustedOrderableDetailInputId: string = '';
focusedOrderableDetailRowId: string = '';


onFocusOrderableDetailRow(dyn: iDynamicorderrecords, selfPrefix:string,nextPrefix?:string, $event?: any, rowIndex?:number ) {
  var resetFocusedRow = document.getElementById(this.focusedOrderableDetailRowId);
  selfPrefix = selfPrefix == ''? 'OrderableDetailQuantityInput-'+dyn.MaterialGroupId:selfPrefix;
  selfPrefix = nextPrefix != ''? nextPrefix:selfPrefix;
}


onResetOrderebleDetail(){

}

matExpansionColapsed: true;

onFoldUnfold(){
  this.isLoading = true;
  this.panelOpenState = !this.panelOpenState;

  this.setStep(+this.panelOpenState);

  this.isLoading = false;
}

getDynRecordsWithFirstIndex(dynamicOrder: iDynamicorderrecords):iDynamicorderrecords[]{
  let indexes:number[] = [1,2,3];
  return this.datasource.filteredData.filter(x => x.MaterialGroupId == dynamicOrder.MaterialGroupId && x.RowIndex == 1 );
}

setFirstDynamicOrderRecord(dynamicOrders: iDynamicorderrecords[], filterBy0:string, filterBy1?:string, filterBy2?:string):iDynamicorderrecords[]{

  let dyn: iDynamicorderrecords[] ;
  let dyn0: iDynamicorderrecords[] ;
  let dyn1: iDynamicorderrecords[] ;
  let dyn2: iDynamicorderrecords[] ;

  dyn = dynamicOrders.filter(x => x.RowIndex == 1);



  if(dyn && filterBy0  && filterBy0 != '' ){

  }
  if(dyn && filterBy1 && filterBy1 != ''){
    dyn  = dyn.filter(y => y.Description.toLowerCase().includes( filterBy1.toLowerCase()) );
    dyn  = dyn.filter(y => (y.Product.DescRow1.toLowerCase().includes( filterBy1.toLowerCase())) || (y.Product.DescRow2.toLowerCase().includes( filterBy1.toLowerCase())) );
  }
  if(dyn && filterBy2 && filterBy2 != ''){
    dyn  = dyn.filter(y => y.Description.toLowerCase().includes(filterBy2.toLowerCase()) );
    dyn  = dyn.filter(y => (y.Product.DescRow1.toLowerCase().includes( filterBy2.toLowerCase())) || (y.Product.DescRow2.toLowerCase().includes( filterBy2.toLowerCase())) );
  }

this.filterBy0 = '';this.filterBy1 = '';this.filterBy2 = '';
return dyn;

}

getFilteredMaterialGroups(){

}

getStockValue(dynamicOrders: iDynamicorderrecords): number{
  let value: number = 0.00;
  value =  dynamicOrders != null  && dynamicOrders.Product != null ?dynamicOrders.Product.StockValue : 0.00;
  return value;
}
getDispositionStockValue(dynamicOrders: iDynamicorderrecords): number{
  let value: number = 0.00;
  value =  dynamicOrders != null  && dynamicOrders.Product != null ?dynamicOrders.Product.DispositionStockValue : 0.00;
  return value;
}
getPrice(dynamicOrders: iDynamicorderrecords): number{
  let value: number = 0.00;
  value =  dynamicOrders != null  && dynamicOrders.Product != null ?dynamicOrders.Product.Price : 0.00;
  return value;
}
getQuantityInput(dynamicOrders: iDynamicorderrecords): number{
  let value: number = 0.00;
  if(dynamicOrders != null  && dynamicOrders.Product != null){
    value =  dynamicOrders.Product != null ?dynamicOrders.Product.QuantityInput : 0.00;
  }

  return value;
}

onOpenDORDialog(dynamicOrder: iDynamicorderrecords){
  let dymanicOrderRecordsDialog: iDynamicorderrecords[] = [];
  let dr2: iDynamicorderrecords  = this.datasource.data.find(x =>  x.ProductId == dynamicOrder.ProductId && x.RowIndex == 2);
  let dr3: iDynamicorderrecords  = this.datasource.data.find(x =>  x.ProductId == dynamicOrder.ProductId && x.RowIndex == 3);
  if(dr2){dymanicOrderRecordsDialog.push(dr2)}
  if(dr3){dymanicOrderRecordsDialog.push(dr3)}

  this.onOpenInfoDialog(dymanicOrderRecordsDialog);

}

onOpenInfoDialog(dynamicOrders: iDynamicorderrecords[]){
  this.dialogService.dynamicOrdersDialog(dynamicOrders).afterClosed().subscribe(
    info => {
      console.log('dynamicOrders is ', dynamicOrders);
      if(dynamicOrders != undefined){
      }

    }, err => {
      console.log('error dialog ', err);
    }
  );
}

getDORCount(dynamicOrder: iDynamicorderrecords):number{

let dyns: iDynamicorderrecords[] = this.datasource.data.filter(x => x.MaterialGroupId == dynamicOrder.MaterialGroupId && x.ProductId ==  dynamicOrder.ProductId);
return dyns? dyns.length - 1 : 0;
}

onPrintPdf(dialogType: string){
  if(this.datasource.data){
    this.dialogService.PrintOrdersatzDialog(this.selectedCustomer, this.datasource.data, dialogType).afterClosed().subscribe(
      info => {

      }, err => {
        console.log('error dialog ', err);
      }
    );
  }

}


sortDataSource(dn: iDynamicorderrecords[]):iDynamicorderrecords[]{

  let newDn: iDynamicorderrecords[] = dn.sort((a, b) =>  a.Description < b.Description ? -1 : 1);

   return newDn;

}

  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;

    this.datasource.data.forEach((row: any) => {
      row.isExpanded = this.isTableExpanded;
    })
  }

  setAllDetailExpandState(){
    this.dorheaddatasource.data.forEach((dyn: iDynamicorderrecords) => {
      dyn.detailExpandState = dyn.detailExpandState == 'expanded'? 'collapsed':'expanded';
    })
  }
  getRowDetailExpandState(materialGroupElement: iMaterialgroups, rowIndex: number ){

    return 'collapsed';
  }

  onToggleExpandTableRowsClick(dor: iDynamicorderrecords,rowIndex: number ):string{

    let nextInput = document.getElementById('OrderableDetailQuantityInput-'+ dor.MaterialGroupId.toString() +'-0');

    setTimeout(() =>{if(nextInput){nextInput.focus();} }, 0);

    return  dor.detailExpandState = dor.detailExpandState == 'expanded'? 'collapsed':'expanded';

    // this.dorheaddatasource.data.forEach(element => {
    //   if(element.detailExpandState = 'expanded'){
    //     let row = document.getElementById('OrderableRow-' + element.MaterialGroupId.toString());
    //     setTimeout(() =>{if(row){ row.style.backgroundColor = ''}}, 0);
    //     element.detailExpandState = 'collapsed'
    //   }
    // });
    // let nexRow = document.getElementById('OrderableRow-' + dor.MaterialGroupId.toString());
    // setTimeout(() =>{if(nexRow){ nexRow.style.backgroundColor = '#ccffcc'}}, 0);

  }

  toggleEachExpandedTableRows( expandedMaterialGroupElement?: iMaterialgroups, materialGroupElement?: iMaterialgroups,rowIndex?: number  ):string{

    let state:string = this.allDetailState;

    if( rowIndex == undefined){
      this.datasource.data.forEach((row: any) => {
        return state == 'expanded' ? 'collapsed' : 'expanded';
      })
      this.allDetailState = this.allDetailState == 'expanded' ? 'collapsed' : 'expanded';

    }
    else{
       return expandedMaterialGroupElement == materialGroupElement ? 'expanded' : 'collapsed';
    }

  }

  onOpenImgDialogMessage(item: iDynamicorderrecords) {
    let img = this.IMAGE_URL  + item.Product.ProductNumber.toString() + '.jpg';

    this.dialog.open(ImgDialogComponent, {
      data: {
        img: img,
        productNumber: item.Product.ProductNumber,
        productName: item.Product.DescRow1,
        productPrice: item.Product.CustomerPrice,
        productUnit: item.Product.QuantityUnitsShortDesc,
      },
    });
  }

  onOrderableDetailKeyUp( dyn: iDynamicorderrecords, headDyn: iDynamicorderrecords, selfPrefix:string, accordionIndex?:number, $event?: any, rowIndex?:number) {
    if(dyn.Product.ListPrice == 0){// check that the product has price
      this.dialogService.okDialog("Error", "Produkt Listenpreis darf nicht 0.00 sein" , "danger",'40%');
      return;
    }
    dyn.Product= this.dataService.roundUpQuantity(dyn.Product);
    let keyCodes: number[] = [9, 27, 13, 37, 38, 39, 40, 66, 80, 79, 87, 86, 70, 65, 75, 76, 77, 78, 66, 68, 75,90,83,     71, 81, 85, 82];
    if( $event && keyCodes.includes($event.keyCode) )
    {
      if($event.currentTarget && $event.currentTarget.localName == "input"){
        if(dyn.Product.ProcessCode == 98){return;}
        dyn.Quantity = +dyn.Quantity.toFixed(2);
        this.onOrderableDetailKeyUpEvent(dyn, headDyn, selfPrefix, accordionIndex, $event, rowIndex);

      }
    }
  }

  onOrderableDetailKeyUpEvent(  dyn: iDynamicorderrecords, headDyn: iDynamicorderrecords ,selfPrefix: string, parentIndex?:number, $event?: any, rowIndex?: number  ) {
    let activeIdbProduct: iProducts = dyn.Product;
    let code = $event != undefined ? $event.code : undefined;
    let nextIndex: number;
    let inputId = 'OrderableDetailQuantityInput-' + rowIndex.toString();
    let rowId = 'row-' + rowIndex.toString();
    let basketId = 'basket-' + rowIndex.toString();
    let materialGroupId = dyn.MaterialGroupId;
    let accordionId = 'OrderableRow-' + dyn.MaterialGroupId.toString();

    this.dataService.onKeyCombination($event, activeIdbProduct);
    selfPrefix = selfPrefix+'-'+dyn.MaterialGroupId.toString();

    if($event.altKey == true && $event.keyCode == 66) // alt + b
    {
      var materialGroupRow = document.getElementById('OrderableRowInput-' + this.getIndexByMaterialGroupsId(dyn.MaterialGroupId).toString());
      if(materialGroupRow){

        if(this.datasource.filteredData && this.datasource.filteredData.length > 0){
          setTimeout(() => { this.goToNextInput('OrderableRowInput', this.getIndexByMaterialGroupsId(dyn.MaterialGroupId), 0);   }, 0);
          setTimeout(() => { this.goToNextRow('OrderableRow', this.getIndexByMaterialGroupsId(dyn.MaterialGroupId), 0);   }, 0);
          setTimeout(() => { materialGroupRow.click()   }, 0);
        }
      }

    }


    if ((code && code == 'Enter') || code == 'NumpadEnter') {

      activeIdbProduct = this.dataService.roundUpQuantity(activeIdbProduct);  //if unit == dose

      this.onAddBasket(activeIdbProduct);

    } else if (code && code == 'ArrowLeft') {
// do nothing
    } else if (code && code == 'ArrowRight') {
// do nothing
    } if (code && (code == 'ArrowDown' || code == 'Enter' || code == 'NumpadEnter')) {
      let activeAccordion = document.getElementById('OrderableRowInput-' + dyn.MaterialGroupId.toString());
      let nextInputRow = document.getElementById('OrderableDetailQuantityInput-'+ dyn.MaterialGroupId.toString() +'-'+ (rowIndex + 1));

      if(activeAccordion && nextInputRow == null){
        let nextMaterialGroupHeadId:number = this.dorheaddatasource.data.indexOf(headDyn) != -1 ? this.dorheaddatasource.data.indexOf(headDyn) +1 : 0;
        let nextMaterialGroupHead: iDynamicorderrecords = this.dorheaddatasource.data[nextMaterialGroupHeadId]
        let nextAccordion = nextMaterialGroupHead ? document.getElementById('OrderableRowInput-' + nextMaterialGroupHead.MaterialGroupId.toString()): null;
// if(nextMaterialGroupHead.detailExpandState = 'collapsed'){}

        if(nextAccordion){
//close active accordion
        let nextInput = document.getElementById('OrderableDetailQuantityInput-'+ nextMaterialGroupHead.MaterialGroupId.toString() +'-'+rowIndex);

          activeAccordion.click();
//open active accordion
          if(nextMaterialGroupHead.detailExpandState = 'collapsed'){ nextAccordion.click() }

          if(nextInput){setTimeout(() =>{nextInput.focus(); }, 0);}
        }
      }else{
            let nextInput = document.getElementById('OrderableDetailQuantityInput-'+ dyn.MaterialGroupId.toString() +'-'+ (rowIndex + 1));
            setTimeout(() =>{if(nextInput){nextInput.focus();} }, 0);
      }

    } else if (code && code == 'ArrowUp') {
      let activeAccordion = document.getElementById('OrderableRowInput-' + dyn.MaterialGroupId.toString());
      let prevInputRow = document.getElementById('OrderableDetailQuantityInput-'+ dyn.MaterialGroupId.toString() +'-'+ (rowIndex - 1));

      if(activeAccordion && prevInputRow == null){
        let prevMaterialGroupHeadId:number = this.dorheaddatasource.data.indexOf(headDyn) != -1 ? this.dorheaddatasource.data.indexOf(headDyn) - 1 : 0;
        let prevMaterialGroupHead: iDynamicorderrecords = this.dorheaddatasource.data[prevMaterialGroupHeadId]
        let prevAccordion = prevMaterialGroupHead ? document.getElementById('OrderableRowInput-' + prevMaterialGroupHead.MaterialGroupId.toString()): null;
        if(prevAccordion){
          let prevInput = document.getElementById('OrderableDetailQuantityInput-'+ prevMaterialGroupHead.MaterialGroupId.toString() +'-'+rowIndex);
//close active accordion
          activeAccordion.click();
//open active accordion
          if(prevMaterialGroupHead.detailExpandState = 'collapsed'){ prevAccordion.click() }

          setTimeout(() =>{if(prevInput){prevInput.focus();} }, 0);
          this.goToNextInput('OrderableDetailQuantityInput-'+prevMaterialGroupHead.MaterialGroupId.toString(), 0, 0);
        }
      }
      else{
        let nextInput = document.getElementById('OrderableDetailQuantityInput-'+ dyn.MaterialGroupId.toString() +'-'+ (rowIndex - 1));
        setTimeout(() =>{if(nextInput){nextInput.focus();} }, 0);
      }

    }

  }

  setBG(state: string){
    return state == 'expanded'? 'bg-green' : '';
  }

  onAddBasket(product: iProducts) {
    this.deliveryDate = localStorage.getItem('deliveryDate') != undefined ? new Date(localStorage.getItem('deliveryDate')): this.deliveryDate ;

    let shoppingCart: iShoppingcarts= this.idbService.insertOrUpdateCustomerShoppingCart( this.selectedCustomer,this.deliveryDate, product);
    this.dataService.getAndSetShoppingCartInfoBag(shoppingCart);
  }

  onRemoveShoppingCartItemClick(dynamicOrderRecord: iDynamicorderrecords){
    if(dynamicOrderRecord && dynamicOrderRecord.Product){
      dynamicOrderRecord.Product.QuantityInput = 0;
      this.idbService.insertOrUpdateCustomerShoppingCart(this.selectedCustomer, this.deliveryDate,dynamicOrderRecord.Product);
    }
  }

  inputFormat(activeIdbProduct: iProducts): string{
    if(activeIdbProduct && activeIdbProduct.QuantityInput != 0){
      return 'input-group-text inputboxtxtBold bg-green';
    }else{
      return 'input-group-text inputbox';
    }
  }

  onIdbDynamicOrdersAction(addOrRemove:number){
    //0: remove, 1: add
    if(addOrRemove && addOrRemove != 0){
      let customers: iCustomers[] = JSON.parse(localStorage.getItem('customers'));
      let customer: iCustomers = customers? customers.find(x => x.CustomerNumber == this.customerNumberInput.toString()): undefined;

      if(customer){
        if(addOrRemove == 1){
          this.idbService.createIdbDynamicRecords(customer);
        }
        if(addOrRemove == -1){
          this.idbService.removeIdbDynamicRecords(customer);
          this.datasource.data = this.datasource.data.filter(x => x.CustomerNumber != customer.CustomerNumber)
        }
        }else{
          this.snackbarService.snackbarAction( 'Bitte  Kundennummer eingeben. ','ok');
        }
    }
  }

  onDeliveryDateFromApp(newDeliveryDate: Date){
   if(newDeliveryDate){
      this.deliveryDate = newDeliveryDate;
   }
  }

  onSelectedCustomerFromAppControl(customer: iCustomers) {
    this.selectedCustomer = customer;
    this.populateData(this.selectedCustomer);
  }

  onOdataStockUpdate(productNumber: string){
    let productstock: iProductstocks;

    this.dataService.getDataByQuerystring('custom','ProductstockOdataByProductnumber', productNumber).subscribe(
      data => {
        productstock = data;
        let prod: iProducts = this.idbService.idbActiveProducts.find(x => x.ProductNumber == productstock.ProductNumber);
        if(prod){
          prod.StockValue  = productstock.StockValue;
          prod.DispositionStockValue  = productstock.DispositionStockValue;
          prod.OpenOrdersStockValue  = productstock.OpenOrdersStockValue;
          prod.SupplierDeliveryStockValue  = productstock.SupplierDeliveryStockValue;

          let idbActiveProductsIndex = this.idbService.idbActiveProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber) ;
          let idbAllProductsIndex = this.idbService.idbAllProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber);
          let datasourcedataIndex = this.datasource.data.findIndex(x => x.Product.ProductNumber == productstock.ProductNumber);

          this.idbService.idbActiveProducts[idbActiveProductsIndex]= prod;
          this.idbService.idbAllProducts[idbAllProductsIndex]= prod;

          if(datasourcedataIndex >= 0 && this.datasource.data[datasourcedataIndex] != undefined){
            this.datasource.data[datasourcedataIndex].Product = prod;
          }

        }
      }
      ), err =>{
        console.log("Error onOdataStockUpdate", err);
      };
    }

    // onOdataSuplierOrderShow(productNumber: string){
    //   let suplierOrders: iSupplierproductorders[] = [];

    //   this.dataService.getDataByQuerystring('custom','SuplierOrderOdataByProductnumber', productNumber).subscribe(
    //     data => {
    //       suplierOrders = data;
    //       this.dialogService.openSuplierOrdersDialog(suplierOrders, '50%', '50%' );

    //     }
    //     ), err =>{
    //       console.log("Error onOdataStockUpdate", err);
    //     };
    //   }
    //   onOdataCustomerOrderShow(productNumber: string){
    //     let customerNumber = this.selectedCustomer ?this.selectedCustomer.CustomerNumber:'0';
    //     let customerOrders: iCustomerproductorders[] = [];
    //     let productandcustomernumber = productNumber + '_'+ customerNumber;

    //     this.dataService.getDataByQuerystring('custom','CustomerOrderOdataByProductnumber', productandcustomernumber).subscribe(
    //       data => {
    //         customerOrders = data;
    //         this.dialogService.openCustomerOrdersDialog(customerOrders, '60%', '50%' );

    //       }
    //       ), err =>{
    //         console.log("Error onOdataStockUpdate", err);
    //       };
    //     }

    onOdataCustomerOrderShow(productNumber: string){
      this.dataService.onOdataCustomerOrderShow(productNumber);
    }
    onOdataSuplierOrderShow(productNumber: string){
      this.dataService.onOdataSuplierOrderShow(productNumber);
    }

    onSpecTab(url: string){
      if(url && url != '')
        {
            window.open(url);
        }

    }

    onProductInfo(product: iProducts){
      { this.dialogService.okDialog("Zusatzinformation", product.ProductInfo, "success",'40%');  }
    }

    // getWidthClass():string{
    //   let w = '100%'
    //   let widthClass = 'min-width: ' +w+ ' !important;'
    //   let container = document.getElementById('alaska-table-container')
    //   let dorHeadTable = document.getElementById('dorHeadTable')
    //   if(dorHeadTable){ w = dorHeadTable.offsetWidth.toString() +'px'}
    //   widthClass = w+ ' !important;';
    //   this.tableHeaderWidth = widthClass;
    //   return widthClass;
    // }

}





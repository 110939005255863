import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import {iOrders, iCustomers,iUsers, iOrderStates, iRoles,  iDynamicorderrecords, iProducts,  iMaterialgroups, iShoppingcarts} from '../../models/AlaskaModels';
import { InputErrorStateMatcher} from '../../models/InputErrorStateMatcher';

import { DataService } from '../../Services/data.service';
import { JsonpClientBackend } from '@angular/common/http';
import { DatePipe } from '@angular/common';

const ELEMENT_DATA_DYNAMICORDERS: Array<iShoppingcarts> = [];
@Component({
  selector: 'app-shoppingCartDialog',
  templateUrl: './shoppingCartDialog.component.html',
  styleUrls: ['./shoppingCartDialog.component.scss']
})
export class ShoppingCartDialogComponent implements OnInit {
  @ViewChild('inputInfo') inputInfo: string;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ELEMENT_DATA_DYNAMICORDERS);
  displayedColumns: string[] = ['Date','Customernumber', 'Name', 'DeliveryDate', 'SearchCustomer', 'Transfer'];
  selectedCustomer: iCustomers;
  deliveryDate:Date
  pipe = new DatePipe('de-DE');

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public shoppingCartDialogRef: MatDialogRef<ShoppingCartDialogComponent>,
              private formBuilder: FormBuilder,
              private dataService: DataService  ) { }

  ngOnInit() {
    // this.createInfoInputForm();
      this.datasource.data = this.data && this.data.shoppingCarts? this.data.shoppingCarts : this.datasource.data;
      console.log('this.datasource ',this.datasource );
      console.log('this.datasource.data ',this.datasource.data );
      console.log('this.datasource.filteredData ',this.datasource.filteredData );

      this.dataService.deliveryDateSubject$.subscribe(
        deliveryDate => {
         this.deliveryDate = deliveryDate;
       }
     );

      this.dataService.selectedCustomerSubject$.subscribe(
        (selectedCustomer) => {
          this.selectedCustomer = selectedCustomer;
        },
        (err) => {
          console.log('error - selectedCustomer-subscription: ', err);
        }
      );
  }
ngAfterViewInit(){
}

  onCancel(){
    this.shoppingCartDialogRef.close();
  }


  getBg(menge: number, type:string):string{
    if(type == 'DispositionStockValue' || type == 'StockValue'){
      return  menge < 5? 'bg-yelow':'bg-green'
    }
    if(type == 'QuantityInput'){
      return  menge > 0? 'bg-green':''
    }
    if(type == 'btnBasket'){
      return  menge > 0? 'material-icons btn-outline-success':'material-icons btn-outline-primary'
    }
  }

  onSelectShoppingCart(shoppingCart: iShoppingcarts){
    this.dataService.setMsgSourceSubject( 'shoppingcartselected',  '',  '',  '',  '', shoppingCart.Name,  shoppingCart,  0 );

    this.shoppingCartDialogRef.close();
  }
}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iProducts} from '../models/AlaskaModels';
import { DataService } from '../Services/data.service';
import {MatDialog} from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http';

import { MatTableDataSource } from '@angular/material/table';
import { isTemplateExpression } from 'typescript';
import { fstat } from 'fs';
import { stringify } from 'querystring';
import { Subscription } from 'rxjs';


const ELEMENT_DATA: Array<iProducts> = [];

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css']
})
export class DiscountsComponent implements OnInit, OnDestroy {

  productCategoryId: number = 150;
  products: Array<iProducts> = [];
  productRows: iProducts[][] = [];
  productColumns: iProducts[] = [];
  columnsCount: number = 2;
  productsSubscription: Subscription;

  datasource = new MatTableDataSource(ELEMENT_DATA);

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private dialog: MatDialog,
              private http: HttpClient   ) { }

 ngOnDestroy(){
   if(this.productsSubscription){
    this.productsSubscription.unsubscribe;
   }
 }

  ngOnInit() {

    this.productsSubscription = this.dataService.getData('products', 'category', this.productCategoryId).subscribe(
      data => {
        this.products = data;
        this.datasource.data = this.products;
        this.setMultiArray();

      }, err => {
        console.log("descount-error", err);
      }
    );
  }

  setMultiArray(){
    let counter = 0;
    this.products.forEach(product => {

        if(counter < this.columnsCount){
          this.productColumns.push(product); counter++;
        }else{
          this.productRows.push(this.productColumns); counter = 0; this.productColumns = [];
        }
    });
    console.log("productRows",this.productRows);
  }

  onOpenImgDialogMessage(product: iProducts){

  }

  modTrue(index: number):boolean{
    console.log("index", index);
    console.log("mod", index % 2);
    return index % 2 == 0;
  }

  showImage(id: number):string{
    var img = this.http.get('../../assets/images/'+id+'.jpg');
      return img != undefined ?  '../../assets/images/'+id+'.jpg' : '../../assets/img/f1.jpg';
    }
}

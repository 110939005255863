<div class="card">

  <div class="card-header">
    <div class="row">
      <div class="col-12 buttonrow">

        <div class="paginator250px ">
          <select (change)="onPageSizeChange($event)" class="small pgnselect" [(ngModel)]="pageSize"> <option  value=10>10</option> <option  value=20>20</option> <option  value=50>50</option> </select>
          <span class="small">{{getPageVisits()}}</span>
          <button (click)="pgnFirstPage()" class=" arrowxxl" id="productfirstpage"><span>&#171;</span></button>
          <button (click)="pgnBackPage()" class=" arrowxxl"><span>&#8249;</span></button>
          <button (click)="pgnNextPage()" class=" arrowxxl"><span>&#8250;</span></button>
          <button (click)="pgnLastPage()" class=" arrowxxl"><span>&#187;</span></button>
        </div>



      <div class="buttonrow ">
        <mat-form-field appearance="outline">
          <mat-label>Columns</mat-label>
          <mat-select [formControl]="columnsForm" placeholder="Columns"  multiple>
            <mat-option (onSelectionChange)="onSelectColumns($event)" *ngFor="let columns of tableColumns" [value]="columns">{{ columns }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  </div>
    </div>

  </div>



  <div class="card alaska-table-container mat-elevation-z8 table  table-striped" *ngIf="mode=='list'">

   <table mat-table [dataSource]="datasource"   matSort>

  <ng-container matColumnDef="CustomersId">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >CustomersId</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.CustomersId }}</td></ng-container>

  <ng-container matColumnDef="CustomerNumber">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >CustomerNumber</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.CustomerNumber }}</td></ng-container>

  <ng-container matColumnDef="Name1">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Name1</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Name1 }}</td></ng-container>

  <ng-container matColumnDef="Name2">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Name2</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Name2 }}</td></ng-container>

  <ng-container matColumnDef="PriceGroup">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >PriceGroup</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.PriceGroup }}</td></ng-container>

  <ng-container matColumnDef="DiscountGroup">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >DiscountGroup</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.DiscountGroup }}</td></ng-container>

  <ng-container matColumnDef="Description">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Description</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Description }}</td></ng-container>

  <ng-container matColumnDef="ShortDesc">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >ShortDesc</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.ShortDesc }}</td></ng-container>

  <ng-container matColumnDef="BlanketDiscount">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >BlanketDiscount</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.BlanketDiscount }}</td></ng-container>

  <ng-container matColumnDef="NetTargetDay">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >NetTargetDay</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.NetTargetDay }}</td></ng-container>

  <ng-container matColumnDef="CashDiscountTargetDay">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >CashDiscountTargetDay</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.CashDiscountTargetDay }}</td></ng-container>

  <ng-container matColumnDef="CashDiscountRate">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >CashDiscountRate</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.CashDiscountRate }}</td></ng-container>

  <ng-container matColumnDef="BackLogId">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >BackLogId</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.BackLogId }}</td></ng-container>

  <ng-container matColumnDef="Street">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Street</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Street }}</td></ng-container>

  <ng-container matColumnDef="Zip">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Zip</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Zip }}</td></ng-container>

  <ng-container matColumnDef="City">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >City</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.City }}</td></ng-container>

  <ng-container matColumnDef="Phone">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Phone</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Phone }}</td></ng-container>

  <ng-container matColumnDef="Fax">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Fax</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Fax }}</td></ng-container>

  <ng-container matColumnDef="SalesPerson">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >SalesPerson</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.SalesPerson }}</td></ng-container>

  <ng-container matColumnDef="Contact">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Contact</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.Contact }}</td></ng-container>

  <ng-container matColumnDef="CreateDate">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >CreateDate</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.CreateDate }}</td></ng-container>

  <ng-container matColumnDef="ModifyDate">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >ModifyDate</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.ModifyDate }}</td></ng-container>

  <ng-container matColumnDef="actions">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >actions</th>
  <td mat-cell class="" *matCellDef="let row">

  </td></ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true "></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  <tr class="mat-row" *matNoDataRow>
    <!-- <td class="mat-cell" colspan="4">Es wurden keine Daten gefunden, die mit dem Filtertext "{{input.value}}" übereinstimmen </td> -->
  </tr>

  </table>
  <mat-paginator hidePageSize="true"  [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>

  </div>

  </div>

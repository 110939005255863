

<div class="card">
  <div class="card-header">
    <div class="matpagediv" > <mat-paginator [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>


<div class="card  tables alaska-table-container table-striped">
  <table mat-table [dataSource]="datasource" matSort matSortActive="DeliveryDate"  matSortDirection="desc">

 <ng-container matColumnDef="SuppliersId">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >LirferantId</th>
  <td mat-cell class="" *matCellDef="let element">{{ element.SuppliersId }}</td></ng-container>

  <ng-container matColumnDef="ProductNumber">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Produktnummer</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.ProductNumber}}</td></ng-container>

    <ng-container matColumnDef="DescRow1">
      <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Produkt</th>
      <td mat-cell class="" *matCellDef="let element">{{ element.DescRow1}}</td></ng-container>

      <ng-container matColumnDef="AveragePurchasePrice">
        <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >MEK</th>
        <td mat-cell class="" *matCellDef="let element">{{ element.AveragePurchasePrice}}</td></ng-container>

  <ng-container matColumnDef="Quantity">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Menge</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.Quantity }}</td></ng-container>

  <ng-container matColumnDef="SupplierName">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Lieferant</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.SupplierName }}</td></ng-container>

  <ng-container matColumnDef="ShortDesc">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Einh.</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.ShortDesc }}</td></ng-container>

  <ng-container matColumnDef="OrderDate">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Bestelldatum</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.OrderDate | date: 'dd.MM.yyyy '}}</td></ng-container>

  <ng-container matColumnDef="DeliveryDate">
    <th mat-header-cell class="" mat-sort-header *matHeaderCellDef >Lieferdatum</th>
    <td mat-cell class="" *matCellDef="let element">{{ element.DeliveryDate  | date: 'dd.MM.yyyy '}}</td></ng-container>

   <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


   </table>


 </div>






<mat-card class = "card">
  <mat-card-header class="c-danger">
     <mat-card-title> <span class="material-icons btn-outline-danger ">warning</span> {{title}}</mat-card-title>

  </mat-card-header>
  <mat-divider></mat-divider>

  <mat-card-content >
    <p></p>  <p>{{msg}}</p> <p></p>
  </mat-card-content>
  <mat-divider></mat-divider>

  <mat-card-actions>
    <div class=row>
      <div class="col-6"><button class="w100 btn-danger" mat-flat-button (click)="onCancel()" mat-button>{{noLabel}}</button></div>
      <div class="col-6"><button class="w100 btn-primary" mat-flat-button (click)="onSave()" mat-button >{{yesLabel}}</button></div>
    </div>

  </mat-card-actions>
</mat-card>


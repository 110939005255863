
<div class="card">
  <div class="card-header font-weight-bolder mat-primary text-center txt-pink-b">   Toure von Kunde:  {{customer.Name1}}, {{customer.Zip}} {{customer.City}}</div>
    <div class="card-body">


        <table class=" table-striped font-weight-normal">
          <tr>
            <td>Montag</td> <td> {{getGate(1)}} </td>
          </tr>
          <tr>
            <td>Dienstag</td> <td> {{getGate(2)}} </td>
          </tr>
          <tr>
            <td>Mittwoch</td> <td> {{getGate(3)}} </td>
          </tr>
          <tr>
            <td>Donnerstag</td> <td> {{getGate(4)}} </td>
          </tr>
          <tr>
            <td>Freitag</td> <td> {{getGate(5)}} </td>
          </tr>
          <tr>
            <td>Samstag</td> <td> {{getGate(6)}} </td>
          </tr>
          <tr>
            <td>Sonntag</td> <td> {{getGate(7)}} </td>
          </tr>
        </table>


  </div>
</div>

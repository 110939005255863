
import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { DataService} from '../../Services/data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { iCustomers, iDynamicorderrecords, iKeyValue, iMaterialgroups, iOrders, iPrintOptions, iShoppingcartitems, iShoppingcarts } from 'src/app/models/AlaskaModels';

import { environment } from 'src/environments/environment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { stringify } from 'querystring';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';
// import * as jsPDF from 'jspdf';
import { jsPDF } from "jspdf";
import { element } from 'protractor';
// import html2Canvas  from 'html2canvas';

const DYNAMICORDERSDATA: Array<iDynamicorderrecords> = [];
@Component({
  selector: 'app-printOrdersatzDialog',
  templateUrl: './printOrdersatzDialog.component.html',
  styleUrls: ['./printOrdersatzDialog.component.scss']
})
export class PrintOrdersatzDialogComponent implements OnInit {

  ordersatzOptions: iKeyValue[] = [  ];
  optionType:string;
  order: iOrders;
  materialGroups: iMaterialgroups[] = [];
  dynamicOrders: iDynamicorderrecords[] = [];
  selectedOrder: iOrders;
  selectedCustomer: iCustomers;
  selectedCustomerName:string = '';
  selectedShoppingCartsName:string = '';
  inputForm: FormGroup;
  pipe = new DatePipe('de-DE');

  datasource = new MatTableDataSource(DYNAMICORDERSDATA);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('orderContainer',{static: false}) el: ElementRef;

  ordersatzControl:FormControl = new FormControl(null, Validators.required);
  // selectFormControl = new FormControl('', Validators.required);

  footer: string[] = [ 'Price', 'Summe'];
  date:string;
  deliveryDate: string;
  totalSum: number;
  title: string;
  optionValue:string = '';
  optionName:string;
  IMAGE_URL = environment.IMAGE_URL;
  pageSize: number = 50;
  itemLength: number = 1000;
  ShoppingCartStateId = 0;
  options: string[] =  ['letzte Ordersätze', 'alle Ordersätze'];
  pageEvent :any;
  dialogType: string ;
  printOption:iPrintOptions ;

  dynamicOrdersatzCsvColumns:string[] = ['MaterialGroupId', 'ProduktNr', 'Lieferdatum', 'Artikelbezeichnung','DescRow2','Einheit',  'Menge', 'Preis'];
  displayedColumns: string[] = ['WarengruppeId', 'WarengruppeName', 'ProduktNr', 'Lieferdatum', 'Artikelbezeichnung','DescRow2', 'Einheit',  'Menge', 'Preis','Pic'];


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dataService:DataService,
              public infoInputDialogRef: MatDialogRef<PrintOrdersatzDialogComponent>
  ) { }

  ngOnInit() {
    // 7 = Angebot    // 8 = Bestellung    // 9 = Ordersatz

    console.log('invoice dialog: ' , this.data);
    this.selectedCustomer = this.data  && this.data.customer? this.data.customer: null;

    this.dynamicOrders =  this.data  && this.data.dynamicOrders? this.data.dynamicOrders: null;

    this.dialogType =  this.data  && this.data.dialogType? this.data.dialogType: null;

    this.dataService.selectedCustomerSubject$.subscribe(
      customer => {
        this.selectedCustomer = customer;
      }, err => {console.log('error - selectedCustomer-subscription: ', err);}
    );

    this.selectedCustomerName = this.selectedCustomer? this.selectedCustomer.Name1:this.selectedCustomerName;
    this.selectedShoppingCartsName = this.selectedOrder? this.selectedOrder.ShoppingCarts.Name:this.selectedShoppingCartsName;
    this.date = this.dataService.getDate(new Date() , 'EEEE, dd.MM.yyyy');

    let dDate = this.selectedOrder?this.selectedOrder.DeliveryDate:null;

    this.deliveryDate =  dDate? this.dataService.getDate(dDate , 'EEEE, dd.MM.yyyy'): this.deliveryDate;

    this.datasource.data = this.dynamicOrders? this.dynamicOrders: [];

    if(this.dialogType == 'print'){
      this.ordersatzOptions.push(
        {Key: 'Bestellung', Value: 'Bestellung'},
        {Key: 'Mit Preisen und Bildern', Value: 'MitPreisenundBildern'},
        {Key: 'Nur Preise, Keine Bilder', Value: 'NurPreiseKeineBilder'},
        {Key: 'Nur Bilder, Keine Preise', Value: 'NurBilderKeinePreise'}
        );
    }
    if(this.dialogType == 'export'){
      this.ordersatzOptions.push(    {Key: 'export letzte Ordersätze als excel', Value: 'letzteOrdersätzeExcel'}, {Key: 'export alle Ordersätze als excel', Value: 'alleOrdersätzeExcel'},{Key: 'export letzte Ordersätze als csv', Value: 'letzteOrdersätzeCsv'} );
    }

    this.optionValue =  this.getPrintOptionValue(this.dialogType)
    setTimeout(() => (this.onSelectionChange(this.optionValue)), 0);

  }

  ngAfterViewInit() {
    this.datasource.data = this.dynamicOrders? this.dynamicOrders: null;
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;

  }

  // setPrintOptions(){
  //   this.printOption = JSON.parse(localStorage.getItem('printOptions')) ;
  //   if(this.printOption == undefined){
  //     this.printOption = {  OrdersatzPdf:'',  OrdersatzExcelAndCsv:'', WarenkorbkPdf:'', AuftrgssamlerPdf:''}
  //     localStorage.setItem('printOptions', JSON.stringify(this.printOption))
  //   }
  // }
  getSelectedDisplayedColumns(selectedOptionValue:string): string[]{
    let filteredDisplayedColumns: string[] = this.displayedColumns;
    filteredDisplayedColumns = this.dialogType == 'export' ? this.displayedColumns.filter(x => x != 'Pic'): filteredDisplayedColumns;
    filteredDisplayedColumns = selectedOptionValue && selectedOptionValue == 'NurBilderKeinePreise'? this.displayedColumns.filter(x => x != 'Preis'): filteredDisplayedColumns;
    filteredDisplayedColumns = selectedOptionValue && selectedOptionValue == 'NurPreiseKeineBilder'?this.displayedColumns.filter(x => x != 'Pic'): filteredDisplayedColumns;
    // if(removePrice == false){filteredDisplayedColumns.push('Price') }
    // if(removePicture == false){filteredDisplayedColumns.push('Pic') }

    return filteredDisplayedColumns;
  }

  getPrintOptionValue(dialogType: string):string{
    this.printOption = JSON.parse(localStorage.getItem('printOptions')) ;

    if(this.printOption == undefined){
      this.printOption = {  OrdersatzPdf:'',  OrdersatzExcelAndCsv:'', WarenkorbkPdf:'', AuftrgssamlerPdf:''}
      localStorage.setItem('printOptions', JSON.stringify(this.printOption))
    }
    return dialogType == 'print'? this.printOption.OrdersatzPdf: this.printOption.OrdersatzExcelAndCsv;
  }

  onSelectionChange(optionValue:string){
    this.optionValue = optionValue == undefined? '': optionValue;
    this.optionType = '';

    if(this.dialogType == 'print' && optionValue != undefined && optionValue != ''){
      this.optionValue = optionValue.split('_')[1];
      this.optionType = optionValue.split('_')[0];
    }

    this.printOption = JSON.parse(localStorage.getItem('printOptions')) != undefined? JSON.parse(localStorage.getItem('printOptions')) : this.printOption ;
    if(this.printOption == undefined){
      this.printOption = {  OrdersatzPdf:'',  OrdersatzExcelAndCsv:'', WarenkorbkPdf:'', AuftrgssamlerPdf:''}
    }

    let ofDate:string =this.selectedOrder && this.selectedOrder.CreateDate ? ' vom '+ this.pipe.transform(this.selectedOrder.CreateDate,'dd.MM.yyyy'): '';

    if(this.dialogType == 'print' && optionValue && optionValue != ''){

      if(optionValue.split('_')[1] == 'AlleBestellungen'  && this.selectedCustomer != undefined) {
        this.datasource.data = this.dynamicOrders? this.dynamicOrders: null;
        this.title =  this.optionType +' für '+ this.selectedCustomer.Name1 + ofDate  ;
        this.printOption.OrdersatzPdf = optionValue;
      }
      if(optionValue.split('_')[1] == 'LetzteBestellungen' && this.selectedCustomer != undefined ) {
        this.datasource.data =  this.dynamicOrders? this.dynamicOrders.filter(x => x.RowIndex == 1): null;
        this.title =  this.optionType +' für '+ this.selectedCustomer.Name1 + ofDate ;
        this.printOption.OrdersatzPdf = optionValue;
      }
      if(optionValue.split('_')[1] == 'MitPreisenundBildern' && this.selectedCustomer != undefined ) {
        this.datasource.data = this.dynamicOrders? this.dynamicOrders: null;
        this.title =  this.optionType +' für '+ this.selectedCustomer.Name1 + ofDate  ;
        this.printOption.OrdersatzPdf = optionValue;
      }
      if(optionValue.split('_')[1] == 'NurPreiseKeineBilder' && this.selectedCustomer != undefined ) {
        this.datasource.data = this.dynamicOrders? this.dynamicOrders: null;
        this.title =  this.optionType +' für '+ this.selectedCustomer.Name1 + ofDate  ;
        this.printOption.OrdersatzPdf = optionValue;
      }
      if(optionValue.split('_')[1] == 'NurBilderKeinePreise' && this.selectedCustomer != undefined ) {
        this.datasource.data = this.dynamicOrders? this.dynamicOrders: null;
        this.title =  this.optionType +' für '+ this.selectedCustomer.Name1 + ofDate ;
        this.printOption.OrdersatzPdf = optionValue;
      }

      localStorage.setItem('printOptions', JSON.stringify(this.printOption))
    }
    if(this.dialogType == 'export' ){
      this.optionType = 'Export Excel/csv'
      if(this.optionValue == 'letzteOrdersätzeExcel' && this.datasource.data && this.datasource.data.length > 0  && this.selectedCustomer != undefined) {
        this.datasource.data =  this.dynamicOrders? this.dynamicOrders.filter(x => x.RowIndex == 1): null;
        this.onExportExcel(this.dynamicOrders.filter(x => x.RowIndex == 1))
        this.title = this.optionValue && this.optionValue == 'letzteOrdersätzeExcel'? 'letzte Ordersätze-excel für '+ this.selectedCustomer.Name1 + ofDate  :this.title;
        this.printOption.OrdersatzExcelAndCsv = this.optionValue;
      }
      if(this.optionValue == 'alleOrdersätzeExcel' && this.datasource.data && this.datasource.data.length > 0  && this.selectedCustomer != undefined) {
        this.datasource.data = this.dynamicOrders? this.dynamicOrders: null;
        this.onExportExcel(this.dynamicOrders)
        this.title = this.optionValue && this.optionValue == 'alleOrdersätzeExcel'? 'letzte Ordersätze-excel für '+ this.selectedCustomer.Name1 + ofDate  :this.title;
        this.printOption.OrdersatzExcelAndCsv = this.optionValue;
      }

      if(this.optionValue == 'letzteOrdersätzeCsv' && this.datasource.data && this.datasource.data.length > 0  && this.selectedCustomer != undefined) {
        this.datasource.data =  this.dynamicOrders? this.dynamicOrders.filter(x => x.RowIndex == 1): null;
        this.onExportCSV(this.dynamicOrders.filter(x => x.RowIndex == 1))
        this.title = this.optionValue && this.optionValue == 'letzteOrdersätzeCsv'? 'letzte Ordersätze-csv für '+ this.selectedCustomer.Name1 + ofDate  :this.title;
        this.printOption.OrdersatzExcelAndCsv = this.optionValue;
      }
      if(this.optionValue == 'alleOrdersätzeCsv' && this.datasource.data && this.datasource.data.length > 0  && this.selectedCustomer != undefined) {
        this.datasource.data = this.dynamicOrders? this.dynamicOrders: null;
        this.onExportExcel(this.dynamicOrders)
        this.title = this.optionValue && this.optionValue == 'alleOrdersätzeCsv'? 'letzte Ordersätze-csv für '+ this.selectedCustomer.Name1 + ofDate :this.title;
        this.printOption.OrdersatzExcelAndCsv = this.optionValue;
      }

        localStorage.setItem('printOptions', JSON.stringify(this.printOption))
    }




  }

  getNetPrice() {
    return this.datasource.data.map(t => t.Price * t.Quantity).reduce((acc, value) => acc + value, 0);
  }

  getTotalPrice() {
    return this.datasource.data.map(t => t.Price  * t.Quantity * 1.07).reduce((acc, value) => acc + value, 0);
  }

  onCancel(){
    this.infoInputDialogRef.close();
  }

  totalRowDiscount(shoppingcartitem: iShoppingcartitems): number{
    return shoppingcartitem.Price * shoppingcartitem.Quantity * shoppingcartitem.PosDiscount / 100;
  }

  totalRowPrice(dynamicOrders: iDynamicorderrecords): number{
    return (dynamicOrders.Price * dynamicOrders.Quantity) ;
  }


  totalPriceSum(): number{
    let totalPrice: number = 0;
    let totalNatoPrice: number = 0;
    let totalDiscount: number = 0;
    let totalSurCharge: number = 0;
    let lieferkosten: number = 0;

    if(this.datasource && this.datasource.data){
      this.datasource.data.forEach(row => {
          totalPrice = totalPrice + this.totalRowPrice(row);
      });
    }
    totalNatoPrice = (totalPrice + totalSurCharge - totalDiscount);
    return +totalNatoPrice.toFixed(2);
  }

  getDynamicOrdersHead(dynamicorderrecords: iDynamicorderrecords[]):iDynamicorderrecords[]{
        let  dyn: iDynamicorderrecords[] = [];
          if(dynamicorderrecords){
            dynamicorderrecords.forEach(element => {
              let existHeadDynamicorderrecords = dyn.find(x => x.MaterialGroupId == element.MaterialGroupId);
                      if(existHeadDynamicorderrecords == undefined){
                            dyn.push(element)
                      }

            });
          }
             return dyn;
  }

  getTop3DynRecords(dynamicOrders: iDynamicorderrecords[], dynamicOrder: iDynamicorderrecords):iDynamicorderrecords[]{
    let indexes: number[] = [1,2,3];
    return dynamicOrders.filter(x => x.MaterialGroupId == dynamicOrder.MaterialGroupId && indexes.includes(dynamicOrder.RowIndex));

  }

  onPaginateChange(data) {
    this.datasource.filteredData = this.datasource.data.slice(data.pageIndex * data.pageSize, data.pageIndex + 1 * data.pageSize);
  }

  onExportCSV(dynamicOrder: iDynamicorderrecords[]){

  let fileName = "Ordersatz_" + this.dataService.getDate(new Date(Date.now()).toString(),'dd-MM-yyyy_HH-mm-ss') + ".csv";
  var FileSaver = require('file-saver');
  var csvData: string = this.getCsvOrderdata(dynamicOrder);


  const blob = new Blob([csvData],  {type: "text/plain;charset=utf-8"});

    let res = FileSaver.saveAs(blob, fileName);

  }

  onExportExcel(dynamicOrder: iDynamicorderrecords[]){

  let fileName = "Ordersatz_" + this.dataService.getDate(new Date(Date.now()).toString(),'dd-MM-yyyy_HH-mm-ss') + ".xlsx";

  setTimeout(() => {
  let element = document.getElementById("excelTable");
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  XLSX.writeFile(wb, fileName);

  }, 0);

  }


  getTableOrderdata(dynamicOrder: iDynamicorderrecords[]): string{

  let table = "<table> <tr> <td>"+this.dynamicOrdersatzCsvColumns[0]+"</td><td>"+this.dynamicOrdersatzCsvColumns[1]+"</td><td>"+this.dynamicOrdersatzCsvColumns[2]+"<td><td>"+this.dynamicOrdersatzCsvColumns[3]+"</td><td>"+
  this.dynamicOrdersatzCsvColumns[4]+"</td><td>"+this.dynamicOrdersatzCsvColumns[5]+"</td><td>"+this.dynamicOrdersatzCsvColumns[6]+"</td></tr>";

  dynamicOrder.forEach((element) => {

    table = table + "<tr> <td>" + element.MaterialGroupId+"</td><td>"+element.Product.ProductNumber+"</td><td>"+ this.dataService.getDate(new Date(element.DeliveryDate).toString(),'dd-MM-yyyy') +"</td><td>"+element.Description +"</td><td>"+
    element.Product.QuantityUnitsShortDesc+"</td><td>"+element.Quantity.toFixed(2).replace('.',',').toString()+"</td><td>"+element.Price.toFixed(2).replace('.',',').toString() + "</td></tr>";
  })

  table = table + "</table>"

  return  table;

}

getCsvOrderdata(dynamicOrder: iDynamicorderrecords[]): string{
  var encoder = new TextEncoder();
let csvHead = ""+this.dynamicOrdersatzCsvColumns[0]+";"+this.dynamicOrdersatzCsvColumns[1]+";"+this.dynamicOrdersatzCsvColumns[2]+";"+this.dynamicOrdersatzCsvColumns[3]+";"+
this.dynamicOrdersatzCsvColumns[4]+";"+this.dynamicOrdersatzCsvColumns[5]+";"+this.dynamicOrdersatzCsvColumns[6]+"";
let csvBody = "";
dynamicOrder.forEach((element) => {

  csvBody = csvBody + "\n" + element.MaterialGroupId+";"+element.Product.ProductNumber+";"+ this.dataService.getDate(new Date(element.DeliveryDate).toString(),'dd-MM-yyyy') +";"+element.Description +";"+
  element.Product.QuantityUnitsShortDesc+";"+element.Quantity.toFixed(2).replace('.',',').toString()+";"+element.Price.toFixed(2).replace('.',',').toString();
})

let headAndBodyWithoutColon = (csvHead + csvBody).toString().replace("'","")
const encodedHeadAndBodyWithoutColon = encoder.encode(headAndBodyWithoutColon);
var decoder = new TextDecoder();
const decodedHeadAndBodyWithoutColon = decoder.decode(new Uint8Array(encodedHeadAndBodyWithoutColon));

return decodedHeadAndBodyWithoutColon;

}

// public onCreatePdf2(): void {
//   // const htmlWidth = $("#orderContainer").width();
//   // const htmlHeight = $("#orderContainer").height();
//   const htmlWidth2 = document.getElementById('orderContainer').scrollWidth; //offsetWidth
//   let DATA: any = document.getElementById('orderContainer');
//   html2canvas(DATA).then((canvas) => {
//     // let fileWidth = 208;
//     // let fileHeight = (canvas.height * fileWidth) / canvas.width;
//     let fileWidth = 208-15;
//     let fileHeight = ((canvas.height * fileWidth) / canvas.width) - 15;
//     const FILEURI = canvas.toDataURL('image/png');
//     let PDF = new jsPDF('p', 'mm', 'a4');
//     let position = 10;
//     PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
//     PDF.save('angular-demo.pdf');
//   });
// // offsetHeight:2807
// // offsetLeft:210
// // offsetTop:97
// // offsetWidth:1426
// // clientWidth:1426
// // clientHeight:2807
// // childElementCount:2
// // scrollHeight:2807
// // scrollWidth:1426
// }


// onCreatePdf(){

//   // let DATA: any = document.getElementById('orderContainer');
//   // var HTML_Width = $(".canvas_div_pdf").width();
//   // var HTML_Height = $(".canvas_div_pdf").height();
//   var HTML_Width = document.getElementById('orderContainer').clientWidth;
//   var HTML_Height = document.getElementById('orderContainer').clientHeight;

//   let rows: number = this.datasource.data.length;
//   // let pages: number =  rows / 15;
//   let pages: number = Math.ceil(this.paginator.length / this.paginator.pageSize)
//   pages= this.paginator.getNumberOfPages()

//   var top_left_margin = 15;
//   var PDF_Width: number = HTML_Width+(top_left_margin*2);
//   var PDF_Height: number = (PDF_Width*1.5)+(top_left_margin*2);
//   var canvas_image_width = HTML_Width;
//   var canvas_image_height = HTML_Height;

//   var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
//   let footerPages: string[] = [];
//   for (let index = 0; index < this.paginator.getNumberOfPages(); index++) {
//     // const element = array[index];
//     footerPages.push((index + 1) + ' of ' + (this.paginator.getNumberOfPages() - 1))


//   }

//   var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
//   let index: number = 0;

//   for (var i = 1; i <= pages; i++) {
//     let DATA: any = document.getElementById('orderContainer');
//     html2canvas(DATA,{allowTaint:true}).then(function(canvas) {

//       canvas.getContext('2d');
//     var imgData = canvas.toDataURL("image/jpeg", 1.0);

//       pdf.addPage([PDF_Width, PDF_Height]);
//       pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
//       pdf.text('www.alaskaonline.de   alaska-tkk@t-online.de',30, PDF_Height - 10)
//       if(i == pages-1){pdf.save("HTML-Document.pdf");}
//     }
//       )};

// };

//##########################


downloadPDF1(){

  const toBePrintedContent = document.getElementById('orderContainer');
        html2canvas(toBePrintedContent).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'A4');
          // const pdf = new jsPDF('p', 'px', 'A4');
          pdf.setFontSize(20);
          const imgProps = pdf.getImageProperties(imgData);

          let width = pdf.internal.pageSize.getWidth();
          let height = pdf.internal.pageSize.getHeight();
          pdf.addImage(imgData, 'PNG', 10, 20, width,width*(canvas.height / canvas.width) );
          pdf.save("toBePrintedPDFCONTENT.pdf");
        });
      }

downloadPDF2(){
        var data = document.getElementById('orderContainer').innerHTML;
            document.open();
            document.write(data);
            if (window.matchMedia('(min-width: 400px)').matches) {
              window.print();
              window.close();
              document.close();
            } else {
              window.print();
              document.close();
            }
  }

  downloadPDF3(){
    var data = document.getElementById('orderContainer').innerHTML;
    var location = window.location.href;
    // var fileref=document.createElement("link")
    // fileref.setAttribute("rel", "stylesheet")
    // fileref.setAttribute("type", "text/css")
    // fileref.setAttribute("href", "styles.scss") ./printOrdersatzDialog.component.scss <link rel="stylesheet" href="chrome://resources/css/md_colors.css">
    // `<link rel="stylesheet" type="text/css" href="${cssFileName}">`
    // mywindow.document.write('<link rel=\"stylesheet\" media=\"print\" type=\"text/css\" href=\"./printOrdersatzDialog.component.scss\" >');
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
        mywindow.document.write('<head>');
        mywindow.document.write('<title>alaska-coolkauf.de</title>');
        mywindow.document.write(`<link rel="stylesheet" type="text/css" href="styles.scss" >`);
        mywindow.document.write(`<link rel="stylesheet" type="text/css" href="./printOrdersatzDialog.component.scss" >`);
        // mywindow.document.write('<link rel=\"stylesheet\" media=\"print\" type=\"text/css\" href=\"styles.scss\" >');
        mywindow.document.write('</head>');
        mywindow.document.write('<body>');
        mywindow.document.write(data);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); //  for IE >= 10
        mywindow.focus(); //  for IE >= 10*/
        mywindow.print();
        mywindow.close();
        return true;
    }

}




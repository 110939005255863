<div class="card " >
  <div class="row">

      <div  class="col-12 text-primary buttonrow" >
        <span *ngIf="selectedProductCategory">Warengruppe:  {{ selectedProductCategory.ProductcategoriesId +' - '+ selectedProductCategory.Name}}</span>
        <span *ngIf="!selectedProductCategory">Warengruppe:  Alle </span>
      <div   class="buttonrow" >

        <div class="paginator250px float-right">
          <select (change)="onPageSizeChange($event)" class="small pgnselect" [(ngModel)]="pageSize"> <option  value=10>10</option> <option  value=20>20</option> <option  value=50>50</option> </select>
          <span class="small">{{getPageVisits()}}</span>
          <button (click)="pgnFirstPage()" class=" arrowxxl" id="productfirstpage"><span>&#171;</span></button>
          <button (click)="pgnBackPage()" class=" arrowxxl"><span>&#8249;</span></button>
          <button (click)="pgnNextPage()" class=" arrowxxl"><span>&#8250;</span></button>
          <button (click)="pgnLastPage()" class=" arrowxxl"><span>&#187;</span></button>
        </div>

      </div>
    </div>


    </div>
</div>

<div class="card alaska-table-container  " id="">

  <table mat-table class="table-striped "  [dataSource]="datasource"  matSort matSortActive="DescRow1"  matSortDirection="asc">

    <ng-container matColumnDef="Artikel" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>A.Nr</th>
      <td mat-cell *matCellDef="let element" > <span class="">{{element.ProductNumber}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="DescRow1" >
      <th mat-header-cell *matHeaderCellDef  mat-sort-header > Bezeichnung </th>
      <td mat-cell *matCellDef="let element" class="alignLeft"> {{element.DescRow1}}  <br /> {{element.DescRow2}}  </td>
    </ng-container>

    <ng-container matColumnDef="Info" >
      <th mat-header-cell *matHeaderCellDef   > Info </th>
      <td mat-cell *matCellDef="let element" class="alignCenter">  <span *ngIf="element.ProductInfo != undefined && element.ProductInfo  != ''" class="material-icons text-primary ptr md-36" (click)="onProductInfo(element)" matTooltip="Zusatzinformationen">  info  </span></td>
    </ng-container>

    <ng-container matColumnDef="Spec" >
      <th mat-header-cell *matHeaderCellDef   > Spez. </th>
      <td mat-cell *matCellDef="let element" class="alignCenter">  <span *ngIf="element.SpecificationLink != undefined && element.SpecificationLink != ''" class="material-icons text-primary ptr md-36" (click)="onSpecTab(element.SpecificationLink)" matTooltip="Produktspezifikationen">  assignment  </span></td>
    </ng-container>

    <ng-container matColumnDef="StockValue" >

      <th class="txtCenter" mat-header-cell *matHeaderCellDef > Verfügbarkeit </th>
      <td mat-cell *matCellDef="let element">

        <table class="stockTable">
          <tr *ngIf="hasRoles([1,2,3,4]) == true"><td  (click)="onOdataStockUpdate(element.ProductNumber)" [ngClass]="getBg(element.StockValue,'StockValue')"  class=" qtyspan alignRight"> Ist: </td> <td (click)="onOdataStockUpdate(element.ProductNumber)" [ngClass]="getBg(element.StockValue,'StockValue')"  class=" qtyspan alignRight" > {{ element.StockValue | number:'1.2-2':'de' }} </td>  <td (click)="onOdataStockUpdate(element.ProductNumber)" [ngClass]="getBg(element.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> Dispo: </td> <td (click)="onOdataStockUpdate(element.ProductNumber)" [ngClass]="getBg(element.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> {{ element.DispositionStockValue | number:'1.2-2':'de' }} </td> </tr>
          <tr *ngIf="hasRoles([1,2,3,4]) == true"><td (click)="onOdataCustomerOrderShow(element.ProductNumber)" [ngClass]="getBg(element.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight">K-Bestlg.: </td> <td (click)="onOdataCustomerOrderShow(element.ProductNumber)" [ngClass]="getBg(element.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight" > {{ element.OpenOrdersStockValue | number:'1.2-2':'de' }} </td> <td (click)="onOdataSuplierOrderShow(element.ProductNumber)" [ngClass]="getBg(element.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')"  class=" qtyspan alignRight"> L-Bestlg.: </td> <td (click)="onOdataSuplierOrderShow(element.ProductNumber)" [ngClass]="getBg(element.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')"  class=" qtyspan alignRight"> {{ element.SupplierDeliveryStockValue | number:'1.2-2':'de' }} </td> </tr>

          <tr *ngIf="hasRoles([1,2,3,4]) == false"><td > <span [ngClass]="getIcon(element.DispositionStockValue,'stockIconStyle')" matTooltip="{{bigOrSmall(element.DispositionStockValue)}}"   >  {{getIcon(element.DispositionStockValue, 'stockAvailabilityIcon') }}</span>  </td> <td></td> </tr>

          </table>

      </td>
    </ng-container>

    <ng-container matColumnDef="Price">
      <th class="tcenter" mat-header-cell *matHeaderCellDef > Preis(€) </th>
      <td class="tcenter" mat-cell *matCellDef="let element">
        <p *ngIf=" element.PriceType == 'I' || element.PriceType == 'S'" class="m-0 p-0" ><del>{{ element.ListPrice  | number:'1.2-2':'de'}} </del></p>
        <p *ngIf=" element.PriceType == 'L' || element.PriceType == 'B' " class="m-0 p-0 ">{{ element.Price  | number:'1.2-2':'de'}} </p>
        <p *ngIf=" element.PriceType == 'S' " class="m-0 p-0 text-danger bold"  matTooltip="Sonderaktion" >{{ element.Price  | number:'1.2-2':'de'}} </p>
        <p *ngIf=" element.PriceType == 'I' " class="m-0 p-0 txt-pink"  matTooltip="indiv. Preis">{{ element.Price  | number:'1.2-2':'de'}} </p>

        <p *ngIf=" element.PriceType == 'I' " class="m-0 p-0 txt-pink txt ">bis:{{ element.CustomerproductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>
        <p *ngIf=" element.PriceType == 'S' " class="m-0 p-0 text-danger "> bis:{{ element.ProductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="Unit">
      <th mat-header-cell *matHeaderCellDef> Einh. </th>
      <td mat-cell *matCellDef="let element"> {{ element.QuantityUnitsShortDesc }} </td>
    </ng-container>

    <ng-container matColumnDef="Picture">
      <th class="txtCenter" mat-header-cell *matHeaderCellDef> Bilder </th>
      <td mat-cell *matCellDef="let element">

        <a class="btn" (click)="onOpenImgDialogMessage(element)"> <img class="prodImage " src="{{IMAGE_URL}}{{element.ProductNumber}}.jpg" onerror="this.src='../assets/icons/1_leider_noch_kein_Bild.jpg';"  height="60px" id="img"> </a>
      </td>
    </ng-container>


    <ng-container matColumnDef="QunatityInput">
      <th class="txtCenter" mat-header-cell *matHeaderCellDef> Wunschmenge  </th>
      <td mat-cell *matCellDef="let element; let i = index; ">
        <div class="input-group">

 <input onfocus="this.select(); " placeholder="0"

            matInput

            [ngClass]="getBg(element.QuantityInput,'QuantityInput')"
            id="QunatityInput-{{ i }}"
            name="quantityIn"
            type="number"
            min="-1000" max="10000"
            lang="de"
            class="input-group-text  inputbox "
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"

            [(ngModel)]="element.QuantityInput"


            (keydown)="onKeyDownEvent($event)"
            (keyup)="onKeyUpEvent(element,'QunatityInput','',$event, i)"
            (click)="onFocusClickedInput('QunatityInput', i)"

            />

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef> Aktion </th>
      <td mat-cell *matCellDef="let element; let i = index; ">
        <button [disabled]="" (click)="onAddBasket(element, i)" class="mat-icon-button " >  <span [ngClass]="getBg(element.QuantityInput,'btnBasket')" id="basket-{{ element.ProductsId }}" matTooltip="im Warenkorb hinzufügen">  add_shopping_cart </span> </button>
        <button *ngIf="changeSmallDeviceMode == true" (click)="onRemoveShoppingCartItemClick(element)" class="mat-icon-button ">  <i class="material-icons btn-outline-danger" matTooltip="löschen">remove_shopping_cart</i> </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr mat-row class="mat-row"  *matRowDef="let i = index; let row; columns: displayedColumns;"  id="row-{{ i }}" (click)="onFocusClickedInput('row', i)" ></tr>

  </table>
  <mat-paginator hidePageSize="true" [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>

</div>






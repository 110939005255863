import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/Services/data.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { iActivationData } from 'src/app/models/AlaskaModels';
import { SnackbarService } from 'src/app/Services/snackbar.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {
  activationToken: string;
  sourceUrl: string;
  activationForm: FormGroup;
  activationData: iActivationData;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private snackbarService: SnackbarService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activationToken = localStorage.getItem("activationToken");
    this.sourceUrl = this.activatedRoute.snapshot.url.toString();

    console.log("this.sourceUrl: ",this.sourceUrl);
  }

  createActivationForm(){
    this.activationForm = this.formBuilder.group(
      {
        token:      ['', []],
        id:         ['', []],
        returnHost: ['', []]
      }
    )
  }

  onActivation(){
    console.log("onactivationData");

    if(this.sourceUrl == '' || this.sourceUrl == ''){
      this.snackbarService.snackbarAction("No activation data provided.");
    }else{
      let sourceUrlArray: Array<string> = this.sourceUrl.split(',');
      console.log("sourceUrlArray", sourceUrlArray);
      this.activationData = {token: sourceUrlArray[3], id: +sourceUrlArray[2], returnHost: this.sourceUrl+sourceUrlArray[0] };

      console.log("this.activationData", this.activationData);

      this.dataService.PostData("custom", this.activationData,"UserActivation").subscribe(
        data => {
          console.log("data", data);
          this.router.navigate(['/']);

          this.dataService.setMsgSourceSubject( 'gotoproducts',  '',  '',  '',  '', 'gotoproducts',  undefined,  0 );

          this.snackbarService.snackbarAction('Ihr Zugang wurde erfolgreich aktiviert. bitte loggen Sie sich ein ');

        }, err => {
          console.log("err", err);
        }
      );

    }

  }
}

<p>
  activation works!
</p>

<div  class="card m200" >
  <div class="card-header">zugang aktivierung</div>
  <div class="card-body">
    <p></p>
    <p>Herzlichen dank, Sie sind in unsere System registriert, bitte activieren Sie ihre zugang in dem Sie auf dem untere Button klicken.</p>

    <div class="row">
      <div class="col8"></div>
      <div class="col4"><button (click)="onActivation()" class="btn btn-primary" type="button" >Aktivieren</button> </div>
    </div>

  </div>
  </div>



<div class="card">
  <div class="">

  <div *ngIf="mode==0||mode==1||mode==2" >
    <div class="row ">

        <div class="col-12 buttonrow">
          <!-- <mat-paginator [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator> -->
          <div class="paginator250px ">
            <select (change)="onPageSizeChange($event)" class="small pgnselect" [(ngModel)]="pageSize"> <option  value=10>10</option> <option  value=20>20</option> <option  value=50>50</option> </select>
            <span class="small">{{getPageVisits()}}</span>
            <button (click)="pgnFirstPage()" class=" arrowxxl" id="productfirstpage"><span>&#171;</span></button>
            <button (click)="pgnBackPage()" class=" arrowxxl"><span>&#8249;</span></button>
            <button (click)="pgnNextPage()" class=" arrowxxl"><span>&#8250;</span></button>
            <button (click)="pgnLastPage()" class=" arrowxxl"><span>&#187;</span></button>
          </div>

          <div class="buttonrow" *ngIf="isLoading == false ">
            <div class="" >  <button (click)="onOrderClick(selectedShoppingCart,1,false)"   class="mat-icon-button" ><span class="material-icons btn-outline-primary md-24 m-1"  matTooltip="Warenkorb speichern">save</span></button>  </div>
            <div class="" >  <button (click)="onOrderClick(selectedShoppingCart,1,true)"   class="mat-icon-button" [disabled]="isDisabled()" ><span class="material-icons btn-outline-success md-24 m-1" matTooltip="Warenkorb speichern und freigeben">send</span></button>  </div>
            <div class="" *ngIf="hasRoles([1,2,3,4]) ">  <button (click)="onOrderClick(selectedShoppingCart,7, false)"   class="mat-icon-button" [disabled]="isDisabled()" ><span class="material-icons btn-outline-dark md-24 m-1" matTooltip="als angebot speichern">local_atm</span></button>  </div>
            <div class="" >  <button (click)="onOrderClick(selectedShoppingCart,8, false)"   class="mat-icon-button" [disabled]="isDisabled()"><span class="material-icons btn-outline-dark md-24 m-1" matTooltip="als vorlage speichern">create_new_folder</span></button>  </div>
            <div class="" *ngIf="hasRoles([1,2,3,4]) == true ">  <button (click)="onCreateEmptyBasketClick()"   class="mat-icon-button" ><span class="material-icons btn-outline-primary md-24 m-1" matTooltip="Neu Warenkorb erstellen">add_shopping_cart</span></button>  </div>
            <div class="">  <button (click)="onPrintBasketClick()"   class="mat-icon-button" ><span class="material-icons btn-outline-primary md-24 m-1" matTooltip="Warenkorb drucken">print</span></button>  </div>
          </div>
        </div>

    </div>

    <div class="row m-1">
      <div class="col-12" *ngIf="windowWidth > 750">
        <form  [formGroup]="orderForm" >
          <div class="row">
            <div class="col-4">
              <mat-form-field class="w-100 " appearance="outline">
              <input  matInput
              formControlName="OrderInfo"
              placeholder="Zusatzinfo zum Auftrag"
              id="wkorderinfo"
              (focusout)="onFocusOutEvent($event, 'wkorderinfo')"
              [(ngModel)]="inputwkorderinfo"  >
            </mat-form-field>
            </div>
            <div  class="col-4 wkheader">
              <table *ngIf="selectedShoppingCart != undefined && windowWidth > 750">
                <tr> <td class="alignLeft"><span *ngIf="windowWidth > 1020">Aktives</span> WK-ID:</td>           <td class="alignLeft">{{aktiveShoppingCartId}}</td></tr>
                <tr> <td class="alignLeft"><span *ngIf="windowWidth > 1020">Aktives</span> WK-Lieferdatum:</td>  <td class="alignLeft">{{aktiveShoppingCartDeliveryDate | date: 'dd.MM.yyyy' }}</td></tr>
              </table>
            </div>
            <div class="col-4">
              <mat-form-field class="w-100 " appearance="outline">
              <input matInput formControlName="Comment"  placeholder="Kommentar" id="wkcomment"
              (focusout)="onFocusOutEvent($event, 'wkcomment')"
              [(ngModel)]="inputwkcomment" >
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>

      <div class="col-12" *ngIf="windowWidth < 750">
        <form  [formGroup]="orderForm" >
          <div class="row">
            <div class="col-6">
              <mat-form-field class="w-100 " appearance="outline">
              <input  matInput
              formControlName="OrderInfo"
              placeholder="Zusatzinfo zum Auftrag"
              id="wkorderinfo"
              (focusout)="onFocusOutEvent($event, 'wkorderinfo')"
              [(ngModel)]="inputwkorderinfo" >
            </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="w-100 " appearance="outline">
              <input matInput formControlName="Comment"  placeholder="Kommentar" id="wkcomment"
              (focusout)="onFocusOutEvent($event, 'wkcomment')"
              [(ngModel)]="inputwkcomment" >
              </mat-form-field>
            </div>
          </div>
        </form>
        <table *ngIf="selectedShoppingCart != undefined && windowWidth < 750" class="wkheader">
          <tr> <td class="alignLeft">Aktives WK-ID: {{aktiveShoppingCartId}}</td> <td class="alignLeft">Aktives WK-Lieferdatum: {{aktiveShoppingCartDeliveryDate | date: 'dd.MM.yyyy' }}</td> </tr>
        </table>
      </div>
    </div>
  </div>

  </div>
<!-- end card header -->

<div class=" alaska-header-table-container" *ngIf="(mode==0 || mode==1) &&  showShoppingCartList==true"   >
  <table class="table-striped table" mat-table [dataSource]="datasourceList">

    <ng-container matColumnDef="ShoppingcartsId" class=" ">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td (click)="onChangeMode(1, element)" mat-cell *matCellDef="let element; let i = index;"> <span class="" [matBadge]="getShoppingcartItemsCount(element)" matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="false">  {{ element.ShoppingcartsId }}</span>  </td>
    </ng-container>

    <ng-container matColumnDef="Date" class=" ">
      <th mat-header-cell *matHeaderCellDef> Datum </th>
      <td (click)="onChangeMode(1, element)" mat-cell *matCellDef="let element; let i = index;"  >  {{element.CreateDate  | date: 'dd.MM.yyyy'}}

      </td>
    </ng-container>

    <ng-container matColumnDef="CustomerName" class=" ">
      <th mat-header-cell *matHeaderCellDef> Kunde </th>
      <td (click)="onChangeMode(1, element)" mat-cell *matCellDef="let element; let i = index;">  {{element.Customer.Name1 }}  </td>
    </ng-container>

    <ng-container matColumnDef="Customernumber" class=" ">
      <th mat-header-cell *matHeaderCellDef>  Kundenummer</th>
      <td (click)="onChangeMode(1, element)" mat-cell *matCellDef="let element; let i = index;">  {{element.Customer.CustomerNumber}}  </td>
    </ng-container>

    <ng-container matColumnDef="Name" class=" ">
      <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
      <td (click)="onChangeMode(1, element)" mat-cell *matCellDef="let element; let i = index;">{{element.Name}}  </td>
    </ng-container>

    <ng-container matColumnDef="DeliveryDate" class=" ">
      <th  mat-header-cell *matHeaderCellDef> Lieferdatum </th>
      <td (click)="onChangeMode(1, element)"  mat-cell *matCellDef="let element; let i = index;"> {{ element.DeliveryDate  | date: 'dd.MM.yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="Transfer" class=" ">
      <th  mat-header-cell *matHeaderCellDef> <span  *ngIf="hasRoles([1,2,3,4]) == true">an Kunde Übertragen </span>  </th>

      <td mat-cell *matCellDef="let element; let i = index; ">
        <div  *ngIf="hasRoles([1,2,3,4]) == true" class="input-group min-w30">
          <input
          name="SearchCustomerS"
          id="SearchCustomerSC-{{ i }}"
          type="number"
          min="0"
          lang="de"
          class="input-group-text inputbox "
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          [(ngModel)] = "transferCustomerNumber"
            />

        <!-- <div   class="m-2"  *ngIf="hasRoles([1,2,3,4]) == true && isLoading3 == false">  <button  (click)="onTransferBasketClick(element)"   class="mat-icon-button " *ngIf="isLoading3 == false"><span *ngIf="isLoading3 == false" class="material-icons btn-outline-primary md-24 " matTooltip="WK übertragen">loop</span></button> </div> -->
        </div>
        <!-- <div class="m-2" *ngIf="hasRoles([1,2,3,4]) == true && isLoading3 == 0">  <button (click)="onTransferBasketClick(element)"   class="mat-icon-button" ><span class="material-icons btn-outline-primary md-24 m-1" matTooltip="WK übertragen">loop</span></button>  </div> -->
      </td>

    </ng-container>

    <ng-container matColumnDef="Actions" class=" ">
      <th  mat-header-cell *matHeaderCellDef> Bearbeiten</th>
      <td mat-cell *matCellDef="let element; let i = index; ">
        <div class="row"    >
          <div class="col-6" *ngIf="hasRoles([1,2,3,4]) == true ">  <button  (click)=" onTransferBasketClick(element); "   class="mat-icon-button" id="transferBtn" ><span class="material-icons btn-outline-primary md-24 m-1" matTooltip="WK übertragen">loop</span></button>  </div>
          <div class="col-6">  <button (click)="onDeleteBasketClick(element)"  class="mat-icon-button " ><span class="material-icons btn-outline-danger md-24 " matTooltip="WK loschen">delete</span></button> </div>

        </div>


      </td>

    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedShoppingCartListColumns; sticky: true"></tr>

    <tr mat-row   *matRowDef="let row; columns: displayedShoppingCartListColumns; let i = index; "  class=" ptr"  id="shoppingcartselectionrow-{{ i }}" (click)="shoppingcartselectionrow('shoppingcartselectionrow',i, element)"></tr>
    <!-- <tr mat-row   *matRowDef="let row; columns: displayedColumns;"  id="rowSC-{{row.Product.ProductsId}}"></tr> -->
  </table>
</div>


<!-- *ngIf="mode=='detail'" -->
<div  class="alaska-table-container  " >
  <table mat-table class="table-striped"   [dataSource] = datasource matSort   matSortDirection="asc">

        <!-- <ng-container matColumnDef="PosIndex" class="m-1" >
          <th mat-header-cell *matHeaderCellDef  mat-sort-header> PosIndex </th>
          <td mat-cell *matCellDef="let element" > {{element.PosIndex}} </td>
        </ng-container> -->

    <ng-container matColumnDef="Artikel" class="">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> A-Nr </th>
      <td mat-cell *matCellDef="let element"> {{element.Product.ProductNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="DescRow1" class="txtCenter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class=""> Bezeichnung </th>
      <td mat-cell *matCellDef="let element" class="alignLeft"> {{element.Product.DescRow1}}  <br /> {{element.Product.DescRow2}}</td>
    </ng-container>




    <ng-container matColumnDef="Stock" class="">
      <th  mat-header-cell *matHeaderCellDef > Verfügbarkeit </th>
      <td mat-cell *matCellDef="let element">

        <table class="stockTable">

          <tr *ngIf="hasRoles([1,2,3,4]) == true"><td  (click)="onOdataStockUpdate(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.StockValue,'StockValue')"  class=" qtyspan alignRight"> Ist: </td> <td (click)="onOdataStockUpdate(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.StockValue,'StockValue')"  class=" qtyspan alignRight" > {{ element.Product.StockValue | number:'1.2-2':'de' }} </td>  <td (click)="onOdataStockUpdate(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> Dispo: </td> <td (click)="onOdataStockUpdate(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> {{ element.Product.DispositionStockValue | number:'1.2-2':'de' }} </td> </tr>
          <tr *ngIf="hasRoles([1,2,3,4]) == true"><td (click)="onOdataCustomerOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight">K-Bestlg.: </td> <td (click)="onOdataCustomerOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight" > {{ element.Product.OpenOrdersStockValue | number:'1.2-2':'de' }} </td> <td (click)="onOdataSuplierOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')"  class=" qtyspan alignRight"> L-Bestlg.: </td> <td (click)="onOdataSuplierOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')"  class=" qtyspan alignRight"> {{ element.Product.SupplierDeliveryStockValue | number:'1.2-2':'de' }} </td> </tr>


          <tr *ngIf="hasRoles([1,2,3,4]) == false"><td > <span [ngClass]="getIcon(element.Product.DispositionStockValue,'stockIconStyle')" matTooltip="{{bigOrSmall(element.Product.DispositionStockValue)}}"  >  {{getIcon(element.Product.DispositionStockValue, 'stockAvailabilityIcon') }}</span>  </td> <td></td> </tr>
          </table>

      </td>
    </ng-container>


    <ng-container matColumnDef="Unit" class="">
      <th  mat-header-cell *matHeaderCellDef> Einh. </th>
      <td mat-cell *matCellDef="let element"> {{ element.Product.QuantityUnitsShortDesc }} </td>
    </ng-container>

    <ng-container matColumnDef="ProdInfo" >
      <th mat-header-cell *matHeaderCellDef   > Info </th>
      <td mat-cell *matCellDef="let element">  <span *ngIf="element.Product.ProductInfo != undefined && element.Product.ProductInfo  != ''" class="material-icons text-primary ptr md-36" (click)="onProductInfo(element.Product)" matTooltip="Zusatzinformationen">  info  </span></td>
    </ng-container>

    <ng-container matColumnDef="Spec" >
      <th mat-header-cell *matHeaderCellDef  > Spez. </th>
      <td mat-cell *matCellDef="let element">  <span *ngIf="element.Product.SpecificationLink != undefined && element.Product.SpecificationLink != ''" class="material-icons text-primary ptr md-36" (click)="onSpecTab(element.Product.SpecificationLink)" matTooltip="Produktspezifikationen">  assignment  </span></td>
    </ng-container>

    <ng-container matColumnDef="Picture" class="">
      <th  mat-header-cell *matHeaderCellDef> Bild </th>
      <td mat-cell *matCellDef="let element"> <a class="btn" (click)="onOpenImgDialogMessage(element)"> <i class="material-icons btn-outline-primary" matTooltip="Bild anzeigen">photo_camera</i> </a> </td>
    </ng-container>

    <ng-container matColumnDef="Price" class="">
      <th  mat-header-cell *matHeaderCellDef> Preis(€) </th>
        <td class="tcenter" mat-cell *matCellDef="let element">

          <p *ngIf=" element.Product.PriceType == 'I' || element.Product.PriceType == 'S' " class="m-0 p-0 " ><del>{{ element.Product.ListPrice  | number:'1.2-2':'de'}} </del></p>
          <p *ngIf=" element.Product.PriceType == 'V'  " class="m-0 p-0 ">{{ element.Product.ListPrice  | number:'1.2-2':'de'}} </p>
          <p *ngIf=" element.Product.PriceType == 'L' || element.Product.PriceType == 'B' " class="m-0 p-0 ">{{ element.Product.Price  | number:'1.2-2':'de'}} </p>
          <p *ngIf=" element.Product.PriceType == 'S' " class="m-0 p-0 text-danger bold "  matTooltip="Sonderaktion" >{{ element.Product.Price  | number:'1.2-2':'de'}} </p>
          <p *ngIf=" element.Product.PriceType == 'I' " class="m-0 p-0 txt-pink-bold "  matTooltip="indiv. Preis">{{ element.Product.Price  | number:'1.2-2':'de'}} </p>

          <p *ngIf=" element.Product.PriceType == 'I' " class="m-0 p-0 txt-pink-bold txt-xsm">bis:{{ element.Product.CustomerproductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>
          <p *ngIf=" element.Product.PriceType == 'S' " class="m-0 p-0 text-danger txt-xsm"> bis:{{ element.Product.ProductpricesValidTo  | date: 'dd.MM.yyyy'}} </p>

      </td>

    </ng-container>

    <ng-container  matColumnDef="PriceInput" >
      <th mat-header-cell *matHeaderCellDef> V-Preis(€)  </th>

        <td mat-cell *matCellDef="let element; let i = index;">
          <div class="" *ngIf="hasRoles([1,2,3,4]) == true">
            <input onfocus="this.select();"
            id="PriceSC-{{ i }}"
            class="input-group-text inputbox "
            type="number"
            min="0"
            lang="de"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value="{{ element.Price  }}"
            [(ngModel)]="element.Price"
            (keydown)="onPreventStep(element,$event)"
            (keyup)="onInputKeyUp(element,'PriceSC','', $event, i)"
            (click)="onFocusClickedInput('PriceSC', i)"
              />
          </div>

          <div *ngIf="hasRoles([5]) == true">   {{ element.Product.Price  | number}}  </div>
        </td>

    </ng-container>

    <ng-container matColumnDef="PosDiscount">
      <th mat-header-cell *matHeaderCellDef> Rabatt(%)  </th>

        <td  mat-cell *matCellDef="let element; let i = index; ">
          <div class="input-group" >
            <input onfocus="this.select();"
            id="PosDiscountSC-{{i}}"
            type="number"
            min="0"
            lang="de"
            class="input-group-text inputbox "
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value="{{ element.PosDiscount }}"
            [(ngModel)]="element.PosDiscount"
            (keydown)="onPreventStep(element,$event)"
            (keyup)="onInputKeyUp(element,'PosDiscountSC','', $event, i)"
            (click)="onFocusClickedInput('PosDiscountSC', i)"
              />
          </div>

        </td>

    </ng-container>

    <ng-container matColumnDef="TotalDiscount">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Rabatt(%) </th>

        <td class="tcenter" mat-cell *matCellDef="let element" > <span class=" mat-lar ptr "   matTooltip=
          "
          Pauschalrabatt: &nbsp;&nbsp;+{{getTooltipDiscount(element).PauschalRabatt | number : '1.2-2': 'de' }} % &#13;
          Pos.-rabatt:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).PositionRabatt | number:'1.2-2':'de'}} % &#13;
          Mengenrabatt: &nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).MengenRabatt  | number:'1.2-2':'de'}} % &#13;
          Wa.Gr.-rabatt:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).WarenGruppeRabatt  | number:'1.2-2':'de'}} % &#13;
          Rab.Gr.-rabatt: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).RabattGruppeRabatt | number:'1.2-2':'de'}} % &#13;
          Kartonanbruch:  &nbsp;&nbsp;&nbsp;&nbsp;-{{positionRowPartialPackageCharge(element) | number:'1.2-2':'de'}} % &#13;

          Gesamtrabatt: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{getTooltipDiscount(element).Gesamt | number:'1.2-2':'de'}} % &#13;

          "> <span  [ngClass]="totalRowNetDiscount(element) < 0 ? 'txt-pink': (totalRowNetDiscount(element) > 0 ?'text-primary' : '')">{{totalRowNetDiscount(element) | number:'1.2-2':'de'}} </span> </span> </td>

    </ng-container>

    <ng-container matColumnDef="TotalPrice">
      <th class="tcenter " mat-header-cell *matHeaderCellDef> G.Preis(€) </th>
      <td class="tcenter " mat-cell *matCellDef="let element"> {{  totalRowPrice(element) | number:'1.2-2':'de' }} </td>
    </ng-container>

    <ng-container matColumnDef="Info">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Info an ALASKA </th>
      <td class="tcenter" mat-cell *matCellDef="let element"> <span (click)="openInfoDialog(element)" matTooltip="{{ element.PosInfo}}"  class="material-icons btn-outline-primary ptr">attach_file</span> </td>
    </ng-container>

    <ng-container matColumnDef="QuantityInput">
      <th mat-header-cell *matHeaderCellDef> Wunschmenge  </th>
      <td mat-cell *matCellDef="let element; let i = index; ">
        <div class="input-group">
          <input onfocus="this.select();"
          id="QuantityInputSC-{{ i }}"
          type="number"
          min="0"
          lang="de"
          [ngClass]="getBg(element.Product.QuantityInput,'QuantityInput')"
          class="input-group-text inputbox "
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value="{{ element.Product.QuantityInput  }}"
          [(ngModel)]="element.Product.QuantityInput"
          (keydown)="onPreventStep(element,$event)"
          (keyup)="onInputKeyUp(element,'QuantityInputSC','', $event, i)"
          (click)="onFocusClickedInput('QuantityInputSC', i)"

            />

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef>

      <div class="" >
        <button (click)="onChangeMode(0)"   class="mat-icon-button" ><span class="material-icons btn-outline-primary md-24 m-1" matTooltip="Warenkorb auflisten">list</span></button>

      </div> </th>

      <th mat-header-cell *matHeaderCellDef="let element">

        </th>
      <td mat-cell *matCellDef="let element; let i = index; ">
        <button *ngIf="changeSmallDeviceMode == true" (click)="onUpdateShoppingCartItemClick(element,'QuantityInputSC','', $event, i)" class="mat-icon-button">
          <span *ngIf="element.Product.QuantityInput == 0" class="material-icons btn-outline-primary" matTooltip="speichern">shopping_cart</span>
          <span *ngIf="element.Product.QuantityInput != 0" class="material-icons btn-outline-success" matTooltip="speichern">shopping_cart</span>
        </button>

        <!-- <button *ngIf="changeSmallDeviceMode == true" (click)="onUpdateShoppingCartItemClick(element,'QuantityInputSC','', $event, i)" class="mat-icon-button ">  <i class="material-icons btn-outline-danger" matTooltip="speichern">add_shopping_cart</i> </button> -->
        <button (click)="onRemoveShoppingCartItemClick(element)" class="mat-icon-button ">  <i class="material-icons btn-outline-danger" matTooltip="löschen">remove_shopping_cart</i> </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>


    <tr mat-row   *matRowDef="let row;  let i = index; columns: displayedColumns;"  id="rowSC-{{i}}"  ></tr>

  </table>
<div style="float: left;"> <mat-paginator hidePageSize="true"  [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator></div>

</div>

<div class="loading-indicator"  *ngIf="isLoading ==  true">
  <mat-spinner mode="indeterminate" color="accent" ></mat-spinner>

</div>



</div>





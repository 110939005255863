// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-inprogressDialog',
//   templateUrl: './inprogressDialog.component.html',
//   styleUrls: ['./inprogressDialog.component.scss']
// })
// export class InprogressDialogComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-inprogressDialog',
  templateUrl: './inprogressDialog.component.html',
  styleUrls: ['./inprogressDialog.component.scss']
})
export class InprogressDialogComponent implements OnInit {

  title: string;
  type: string;
  msg: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public inProgressDialogRef: MatDialogRef<InprogressDialogComponent>){}

  ngOnInit() {
  this.title = this.data.title;
  this.type = this.data.type; //danger, warning, info
  this.msg = this.data.msg;
  }
ngAfterViewInit(){

}

onOK(){

    return this.inProgressDialogRef.close('ok');
  }

}

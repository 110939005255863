
<div class="card ">

 <div class="card-header">
  <div class="row">
    <div class="col-12 buttonrow">
      <div class="paginator250px ">
        <select (change)="onPageSizeChange($event)" class="small pgnselect" [(ngModel)]="pageSize"> <option  value=10>10</option> <option  value=20>20</option> <option  value=50>50</option> </select>
        <span class="small">{{getPageVisits()}}</span>
        <button (click)="pgnFirstPage()" class=" arrowxxl" id="productfirstpage"><span>&#171;</span></button>
        <button (click)="pgnBackPage()" class=" arrowxxl"><span>&#8249;</span></button>
        <button (click)="pgnNextPage()" class=" arrowxxl"><span>&#8250;</span></button>
        <button (click)="pgnLastPage()" class=" arrowxxl"><span>&#187;</span></button>
      </div>




   <div class="buttonrow ">
    <mat-form-field appearance="outline">
      <mat-label>Anzeigeoptionen</mat-label>
      <mat-select [(ngModel)]="selectedFilter" (ngModelChange)="onSelectFilter($event) ">
        <mat-option    *ngFor="let filter of filterOptions"  [value]="filter"> {{filter}}</mat-option>
      </mat-select>
    </mat-form-field>

     <button (click)="onList()"    class="mat-icon-button "><span class="material-icons btn-outline-primary md-36 m2" matTooltip="Listenansicht">view_list</span></button>
     </div>
  </div>
</div>

<div class=""  >

<div class="card alaska-table-container  " *ngIf="mode=='list'">
 <table class="table-striped" mat-table [dataSource]="datasource" matSort  matSortActive="CreateDate"  matSortDirection="desc" >

<ng-container matColumnDef="OrdersId">
<th mat-header-cell mat-sort-header *matHeaderCellDef (click)="changeSort('OrdersId')">AuftragsId</th>
<td mat-cell  *matCellDef="let element" (click)="onEdit(element)" class="ptr"> <span class="m-2" [matBadge]="getItemsCount(element)" matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="false">  {{ element.OrdersId }}</span> </td></ng-container>

<ng-container matColumnDef="ShoppingCartsId">
<th mat-header-cell class=""  mat-sort-header *matHeaderCellDef (click)="changeSort('ShoppingCartsId')" >WarenkorbId</th>
<td mat-cell  *matCellDef="let element" (click)="onEdit(element)" class="ptr">{{ element.ShoppingCartsId }}</td></ng-container>

<ng-container matColumnDef="Orderdate">
  <th mat-header-cell  mat-sort-header *matHeaderCellDef (click)="changeSort('CreateDate')" >Erf.-Datum</th>
  <td mat-cell  *matCellDef="let element" (click)="onEdit(element)" class="ptr">{{ element.CreateDate | date: 'dd.MM.yyyy'}}</td></ng-container>

<ng-container matColumnDef="DeliveryDate">
  <th mat-header-cell class="" mat-sort-header *matHeaderCellDef (click)="changeSort('DeliveryDate')" >Lieferdatum</th>
  <td mat-cell class="" *matCellDef="let element"  (click)="onEdit(element)" class="ptr"> {{ element.DeliveryDate | date: 'dd.MM.yyyy' }}</td></ng-container>

<ng-container matColumnDef="PreparedBy">
<th mat-header-cell  *matHeaderCellDef mat-sort-header (click)="changeSort('PreparedBy')" >Erstellt-von</th>
<td mat-cell  *matCellDef="let element" (click)="onEdit(element)" class="ptr">{{ element.PreparedBy }}</td></ng-container>

<ng-container matColumnDef="sOrderedBy">
<th mat-header-cell  *matHeaderCellDef mat-sort-header (click)="changeSort('UsersFirstName')" >Beaufragt-von</th>
<td mat-cell  *matCellDef="let element" (click)="onEdit(element)" class="ptr"> {{element.ShoppingCarts.UsersFirstName}} {{element.ShoppingCarts.UsersLastName}} </td></ng-container>

<ng-container matColumnDef="sOrderedFor">
<th mat-header-cell *matHeaderCellDef mat-sort-header (click)="changeSort('CustomerNumber')">Beauftragt-für</th>
<td mat-cell  *matCellDef="let element"  (click)="onEdit(element)" class="ptr"> {{element.ShoppingCarts.CustomersCustomerNumber}}-{{element.ShoppingCarts.CustomersName1}} </td></ng-container>

<ng-container matColumnDef="CustomersId">
<th mat-header-cell class="" mat-sort-header   *matHeaderCellDef  >KundenId</th>
<td mat-cell *matCellDef="let element"  (click)="onEdit(element)" class="ptr"> {{element.ShoppingCarts.CustomerId}} </td></ng-container>

<ng-container matColumnDef="CustomerNumber">
<th mat-header-cell *matHeaderCellDef mat-sort-header (click)="changeSort('CustomerNumber')" >KundenNr</th>
<td mat-cell  *matCellDef="let element"  (click)="onEdit(element)" class="ptr"> {{ element.ShoppingCarts.CustomersCustomerNumber }}</td></ng-container>

<ng-container matColumnDef="sOrderStatesId">
<th mat-header-cell  *matHeaderCellDef >Status</th>
<td mat-cell  *matCellDef="let element"  (click)="onEdit(element)" class="ptr"> {{element.OrderstatesName}} </td></ng-container>

<ng-container matColumnDef="OrderInfo">
  <th mat-header-cell  *matHeaderCellDef >Kunden-info</th>
  <td mat-cell  *matCellDef="let element"  (click)="onEdit(element)" class="ptr"> {{element.ShoppingCarts.OrderInfo}} </td></ng-container>

<ng-container matColumnDef="Comment">
  <th mat-header-cell  *matHeaderCellDef >Kommentar</th>
  <td mat-cell  *matCellDef="let element"  (click)="onEdit(element)" class="ptr"> {{ element.ShoppingCarts.Comment }}</td></ng-container>

<ng-container matColumnDef="Surcharge">
  <th mat-header-cell  *matHeaderCellDef >G.Zuschlag(3%) </th>
  <td mat-cell *matCellDef="let element" class="ptr alignRight"> {{totalOrderSurcharge(element)  | number:'1.2-2':'de'}}</td></ng-container>

<ng-container matColumnDef="TotalPrice">
  <th mat-header-cell  *matHeaderCellDef >G.Preise</th>
  <td mat-cell *matCellDef="let element" class="ptr alignRight"> {{totalOrderPrice(element)  | number:'1.2-2':'de'}}</td></ng-container>

<ng-container matColumnDef="actions">

<th mat-header-cell  *matHeaderCellDef >Aktion</th>
<td mat-cell  *matCellDef="let element">
  <div class="block" >
  <button  (click)="onTransferTemplateOrOfferAsShopingCards(element)" class="mat-icon-button " *ngIf="selectedCustomer != undefined && (selectedFilter == 'Vorlagen' || selectedFilter == 'Angebote')"><span class="material-icons btn-outline-primary md-32 m3" matTooltip="In den aktuell WK Übernehmen">redo</span></button>

  <button *ngIf=" selectedCustomer != undefined && element.Canceled != 1 " (click)="onDeleteOrder(element)" class="mat-icon-button "> <i class="material-icons btn-outline-danger" matTooltip="löschen">delete_forever</i> </button>
  <button *ngIf=" selectedCustomer != undefined  " (click)="onPrintPdf(element)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="PDF speichern / Drucken">print</i> </button>
  <button *ngIf=" selectedCustomer != undefined && element.ShoppingCarts.ShoppingCartStateId != 8 && element.Published != 1 && element.Canceled != 1 && element.Imported == null"  (click)="onEditOrderOrOfferOrTemplateAsShoppingCart(element)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="als Warenkorb bearbeiten und ersetzen">edit</i> </button>
  </div>
</td>

</ng-container>

  <ng-container matColumnDef="Selection">

    <th mat-header-cell class=""  *matHeaderCellDef >Freigeben</th>
    <td mat-cell  *matCellDef="let element"> <button *ngIf=" isLoading == false && element.Published != 1 && element.Canceled != 1 && element.ShoppingCarts.ShoppingCartStateId != 7 && element.ShoppingCarts.ShoppingCartStateId != 8"  (click)="onPublishOrders(element)"    class="mat-icon-button "><span class="material-icons btn-outline-success md-36 m-2" matTooltip="Bestellen">send</span></button>
    </td>
    </ng-container>

    <ng-container matColumnDef="hiddenOrderInput">
      <th mat-header-cell class="hiddenOrderInput"  *matHeaderCellDef ></th>
      <td mat-cell *matCellDef="let element; let x = index;">
          <input class="hiddenOrderInput"
          id="hiddenOrderInput-{{x}}"
          (keyup)="onOrderKeyUp(element,'hiddenOrderInput','', $event, x)"
            />
      </td>
    </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true "></tr>
  <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;"  id="Order_row-{{ i }}"  (keydown)="onPreventStep(row,$event)"  (keyup)="onOrderKeyUp(row,'Order_row','', $event, i)"  (click)="onFocusOrderRow(row,'OrderRow','',$event, i)"  ></tr>

  </table>
  <mat-paginator hidePageSize="true"  [pageSizeOptions]="[50, 10, 20, 100]" showFirstLastButtons></mat-paginator>
</div>

<div *ngIf="mode=='edit'" >

<!-- ----------------------------------------------------------------------- -->

<form  [formGroup]="ordersForm" >
  <div class="row">
    <div class="col-6">
      <mat-form-field class="w-100 " appearance="outline">
      <input matInput formControlName="OrderInfo" placeholder="Zusatzinfo" [(ngModel)]="selectedOrder.ShoppingCarts.OrderInfo">
      </mat-form-field>
    </div>
      <!-- <mat-error class="error-block" *ngIf="!ordersForm.get('OrderInfo').valid && (ordersForm.get('OrderInfo').touched  || formSubmited)">Ungültiger Eintrag</mat-error>  -->
    <div class="col-6">
      <mat-form-field class="w-100 " appearance="outline">
      <input matInput formControlName="Comment"  placeholder="Kommentar" [(ngModel)]="selectedOrder.ShoppingCarts.Comment">
      </mat-form-field>
    </div>

      <!-- <mat-error class="error-block" *ngIf="!ordersForm.get('Comment').valid && (ordersForm.get('Comment').touched  || formSubmited)">Ungültiger Eintrag</mat-error>  -->
  </div>
</form>


<div class="card alaska-detail-table-container mat-elevation-z8 ">
  <table class="table-striped " mat-table [dataSource]="editDatasource">

    <ng-container matColumnDef="OrderDetail_ProductId" class="m-1">
      <th mat-header-cell *matHeaderCellDef> A-Nr </th>
      <td mat-cell *matCellDef="let element"> {{element.Product.ProductNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_DescRow1" class="m-1">
      <th mat-header-cell *matHeaderCellDef> Bezeichnung </th>
      <td mat-cell *matCellDef="let element" class="alignLeft"> {{element.Product.DescRow1}}  <br /> {{element.Product.DescRow2}}</td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_ProdInfo" >
      <th mat-header-cell *matHeaderCellDef  > Info </th>
      <td mat-cell *matCellDef="let element">  <span *ngIf="element.Product.ProductInfo != undefined && element.Product.ProductInfo  != ''" class="material-icons text-primary ptr md-36" (click)="onProductInfo(element.Product)" matTooltip="Zusatzinformationen">  info  </span></td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_Spec" >
      <th mat-header-cell *matHeaderCellDef  > Spez. </th>
      <td mat-cell *matCellDef="let element">  <span *ngIf="element.Product.SpecificationLink != undefined && element.Product.SpecificationLink != ''" class="material-icons text-primary ptr md-36" (click)="onSpecTab(element.Product.SpecificationLink)" matTooltip="Produktspezifikationen">  assignment  </span></td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_StockValue" class="mar-r">
      <th class="tright" mat-header-cell *matHeaderCellDef > Verfügbarkeit </th>

      <td  mat-cell *matCellDef="let element">

        <!-- <table class="stockTable">
          <tr *ngIf="hasRoles([1,2,3,4]) == true"   >

          <td [ngClass]="getBg(element.Product.StockValue,'StockValue')" class=" qtyspan" > Ist:</td><td [ngClass]="getBg(element.Product.StockValue,'StockValue')" class=" qtyspan alignRight"> {{ element.Product.StockValue | number:'1.2-2':'de' }} </td>  <td   [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan"> Dispo: </td><td [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> {{ element.Product.DispositionStockValue | number:'1.2-2':'de' }} </td> </tr>

          <tr *ngIf="hasRoles([1,2,3,4]) == true"><td  [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan">K-Bestlg.: </td> <td  [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight">{{ element.Product.OpenOrdersStockValue | number:'1.2-2':'de' }} </td> <td   [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')" class=" qtyspan"> L-Bestlg.: </td> <td  [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')" class=" qtyspan alignRight">{{ element.Product.SupplierDeliveryStockValue | number:'1.2-2':'de' }} </td> </tr>

          <tr *ngIf="hasRoles([1,2,3,4]) == false"><td > <span [ngClass]="getIcon(element.Product.DispositionStockValue,'stockIconStyle')"  matTooltip="{{bigOrSmall(element.Product.DispositionStockValue)}}"  >  {{getIcon(element.Product.DispositionStockValue, 'stockAvailabilityIcon') }}</span>  </td> <td></td> </tr>
        </table> -->

        <table class="stockTable">
          <tr *ngIf="hasRoles([1,2,3,4]) == true" (click)="onOdataStockUpdate(element.Product)"  >

          <td [ngClass]="getBg(element.Product.StockValue,'StockValue')" class=" qtyspan alignRight" > Ist:</td><td [ngClass]="getBg(element.Product.StockValue,'StockValue')" class=" qtyspan alignRight"> {{ element.Product.StockValue | number:'1.2-2':'de' }} </td>  <td  (click)="onOdataStockUpdate(element.Product)" [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> Dispo: </td><td [ngClass]="getBg(element.Product.DispositionStockValue,'DispositionStockValue')" class=" qtyspan alignRight"> {{ element.Product.DispositionStockValue | number:'1.2-2':'de' }} </td> </tr>

          <tr *ngIf="hasRoles([1,2,3,4]) == true"><td  (click)="onOdataCustomerOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight">K-Bestlg.: </td> <td (click)="onOdataCustomerOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.OpenOrdersStockValue,'OpenOrdersStockValue')" class=" qtyspan alignRight">{{ element.Product.OpenOrdersStockValue | number:'1.2-2':'de' }} </td> <td  (click)="onOdataSuplierOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')" class=" qtyspan alignRight"> L-Bestlg.: </td> <td (click)="onOdataSuplierOrderShow(element.Product.ProductNumber)" [ngClass]="getBg(element.Product.SupplierDeliveryStockValue,'SupplierDeliveryStockValue')" class=" qtyspan alignRight">{{ element.Product.SupplierDeliveryStockValue | number:'1.2-2':'de' }} </td> </tr>

          <tr *ngIf="hasRoles([1,2,3,4]) == false"><td > <span [ngClass]="getIcon(element.Product.DispositionStockValue,'stockIconStyle')"  matTooltip="{{bigOrSmall(element.Product.DispositionStockValue)}}"  >  {{getIcon(element.Product.DispositionStockValue, 'stockAvailabilityIcon') }}</span>  </td> <td></td> </tr>
        </table>


      </td>


    </ng-container>

    <ng-container matColumnDef="OrderDetail_Unit" class="mar-r">
      <th  mat-header-cell *matHeaderCellDef> Einh. </th>
      <td mat-cell *matCellDef="let element"> {{ element.Product.QuantityUnitsShortDesc }} </td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_Price">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Preis(€)</th>
      <td class="tcenter alignRight" mat-cell *matCellDef="let element"> {{element.Price  | number:'1.2-2':'de'}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="OrderDetail_Price" >
      <th mat-header-cell *matHeaderCellDef> Preis(€)  </th>
      <td mat-cell *matCellDef="let element; let x = index;">
        <div class="input-group"  *ngIf="this.hasRoles([1,2,3,4]) == true">
          <input onfocus="this.select();"
          id="OrderDetail_Price-{{x}}"
          type="number"
          min="0"
          lang="de"
          class="input-group-text inputbox "
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value="{{ element.Price | number }}"
          [(ngModel)]="element.Price"

          (keydown)="onPreventStep(element,$event)"
          (keyup)="onOrderDetailKeyUp(element,'OrderDetail_Price','', $event, x)"
          (focusout)="onFocusOutPrice(element, $event)"
          (click)="onFocusOrClickTableRow(element,'OrderDetail_PosDiscount','',$event,x, gettotalDetailRowsCount())"

            />
        </div>

        <div  *ngIf="this.hasRoles([5]) == true">
          {{ element.Price | number}}
        </div>

      </td>
    </ng-container> -->

    <ng-container matColumnDef="OrderDetail_PosDiscount">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Rabatt(%) </th>
      <td class="tcenter alignRight" mat-cell *matCellDef="let element"> {{element.PosDiscount  | number:'1.2-2':'de'}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="OrderDetail_PosDiscount"  >

        <th mat-header-cell *matHeaderCellDef > Rabatt(%)  </th>

        <td mat-cell *matCellDef="let element; let x = index;"  >
          <div class="input-group" *ngIf="this.hasRoles([1,2,3,4]) == true">
            <input onfocus="this.select();"
            id="OrderDetail_PosDiscount-{{x}}"
            type="number"
            min="0"
            lang="de"
            class="input-group-text inputbox "
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value="{{ element.PosDiscount | number}}"
            [(ngModel)]="element.PosDiscount"

            (keydown)="onPreventStep(element,$event)"
            (keyup)="onOrderDetailKeyUp(element,'OrderDetail_PosDiscount','', $event, x)"
            (click)="onFocusOrClickTableRow(element,'OrderDetail_PosDiscount','',$event,x, gettotalDetailRowsCount())"
              />
          </div>
        </td>

    </ng-container> -->

    <ng-container matColumnDef="OrderDetail_TotalDiscount">
      <th class="tcenter alignRight" mat-header-cell *matHeaderCellDef> G.Rabatt(%) </th>

        <td class="tcenter" mat-cell *matCellDef="let element" > <span class=" mat-lar ptr "  matTooltip=
          "
          Pauschalrabatt: &nbsp;&nbsp;+{{getTooltipDiscount(element).PauschalRabatt | number : '1.2-2': 'de' }} % &#13;
          Pos.-rabatt:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).PositionRabatt | number:'1.2-2':'de'}} % &#13;
          Mengenrabatt: &nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).MengenRabatt  | number:'1.2-2':'de'}} % &#13;
          Wa.Gr.-rabatt:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).WarenGruppeRabatt  | number:'1.2-2':'de'}} % &#13;
          Rab.Gr.-rabatt: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+{{getTooltipDiscount(element).RabattGruppeRabatt | number:'1.2-2':'de'}} % &#13;
          Kartonanbruch:  &nbsp;&nbsp;&nbsp;&nbsp;-{{positionRowPartialPackageCharge(element) | number:'1.2-2':'de'}} % &#13;

          Gesamtrabatt: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{getTooltipDiscount(element).Gesamt | number:'1.2-2':'de'}} % &#13;

          "> <span  [ngClass]="totalRowNetDiscount(element) < 0 ? 'txt-pink': (totalRowNetDiscount(element) > 0 ?'text-primary' : '')">{{totalRowNetDiscount(element) | number:'1.2-2':'de'}} </span> </span> </td>

    </ng-container>

    <ng-container matColumnDef="OrderDetail_Surcharge">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Zuschlag(3%) </th>
      <td class="tcenter alignRight" mat-cell *matCellDef="let element"> {{totalRowSurcharge(element)  | number:'1.2-2':'de'}} </td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_TotalPrice">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> G.Preis(€) </th>
      <td class="tcenter alignRight" mat-cell *matCellDef="let element"> {{totalRowPrice(element) | number:'1.2-2':'de' }} </td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_Info">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Info an ALASKA </th>
      <td class="tcenter" mat-cell *matCellDef="let element"> <span (click)="openInfoDialog(element)" matTooltip="{{ element.PosInfo}}"  class="material-icons btn-outline-primary">attach_file</span> </td>
    </ng-container>

    <ng-container matColumnDef="OrderDetail_Quantity">
      <th class="tcenter" mat-header-cell *matHeaderCellDef> Menge </th>
      <td class="tcenter alignRight" mat-cell *matCellDef="let element"> {{ element.Quantity | number:'1.2-2':'de'}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="OrderDetail_QuantityInput">
      <th mat-header-cell *matHeaderCellDef> Wunschmenge  </th>
      <td mat-cell *matCellDef="let element; let x = index;">
        <div class="input-group">
          <input onfocus="this.select();"
          id="OrderDetail_QuantityInput-{{x}}"
          type="number"
          min="0"
          lang="de"
          class="input-group-text inputbox "
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value="{{ element.Quantity | number}}"
          [(ngModel)]="element.Quantity"
          [ngClass]="getBg(element.Product.QuantityInput,'QuantityInput')"
          (keydown)="onPreventStep(element,$event)"
          (keyup)="onOrderDetailKeyUp(element,'OrderDetail_QuantityInput','', $event, x)"
          (click)="onFocusOrClickTableRow(element,'OrderDetail_PosDiscount','',$event,x, gettotalDetailRowsCount())"


            />
        </div>
      </td>
    </ng-container> -->

    <ng-container matColumnDef="OrderDetail_Action">
      <th mat-header-cell *matHeaderCellDef>
        <div class="block" *ngIf="isLoading == false">
          <div class="col-2 block"> <button *ngIf="selectedOrder.Published != 1"  (click)="onEditSave(selectedOrder)" class="mat-icon-button "> <i class="material-icons btn-outline-primary" matTooltip="Äderungen speichern">save</i> </button></div>


          <div class="col-2 block"> <button *ngIf="selectedOrder.Published != 1 && selectedOrder.Canceled != 1 &&  selectedOrder.ShoppingCarts.ShoppingCartStateId != 7 && selectedOrder.ShoppingCarts.ShoppingCartStateId != 8"  (click)="onPublishOrders(selectedOrder)"    class="mat-icon-button "><span class="material-icons btn-outline-success md-24 m-2" matTooltip="Bestellen">send</span></button></div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="isLoading == false && selectedOrder.Canceled != 1" (click)="onDeletePosition(element)" class="mat-icon-button "> <i class="material-icons btn-outline-danger align-self-center" matTooltip="löschen">delete_forever</i> </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumnsDetail; sticky: true"></tr>

    <tr mat-row   *matRowDef="let row; let x = index; columns: displayedColumnsDetail;"  id="OrderDetail_row-{{x}}" (click)="onFocusOrClickTableRow(element,'OrderDetail_PosDiscount','',$event,x, gettotalDetailRowsCount())"></tr>


  </table>
</div>

</div>
<!-- -end of edit---------------------------------------------------------------------------------------------------------------------- -->

</div><!-- cardbody -->
</div> <!-- car -->

<div class="loading-indicator">
  <mat-spinner mode="indeterminate" color="accent" *ngIf="isLoading"></mat-spinner>

</div>

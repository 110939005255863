
<table>
  <tr>
    <td style="width:50%">
      <div *ngIf="dialogType == 'print'"> <mat-paginator [length]="itemLength"  [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons  ></mat-paginator></div>
    </td>
    <td style="width:30%">

      <div *ngIf="dialogType == 'print'">
        <mat-form-field appearance="outline" >
          <mat-label *ngIf="dialogType == 'print'">PDF speichern/druck optionen</mat-label><mat-label *ngIf="dialogType == 'export'" >csv/excel export optionen</mat-label>
          <mat-select [formControl]="ordersatzControl" disableRipple [(ngModel)]="optionValue" (selectionChange)="onSelectionChange($event['value'])">
              <mat-optgroup   label="Angebot"  >
                <mat-option   value="Angebot_AlleBestellungen">Alle Bestellungen</mat-option>
                <mat-option   value="Angebot_LetzteBestellungen">Letzte Bestellungen</mat-option>
                <mat-option   value="Angebot_MitPreisenundBildern">Mit Preisen und Bildern</mat-option>
                <mat-option   value="Angebot_NurPreiseKeineBilder">Nur Preise, Keine Bilder</mat-option>
                <mat-option   value="Angebot_NurBilderKeinePreise">Nur Bilder, Keine Preise</mat-option>
              </mat-optgroup>
              <mat-optgroup   label="Bestellauskunft"  >
                <mat-option   value="Angebot_AlleBestellungen">Alle Bestellungen</mat-option>
                <mat-option   value="Angebot_LetzteBestellungen">Letzte Bestellungen</mat-option>
                <mat-option   value="Bestellauskunft_MitPreisenundBildern">Mit Preisen und Bildern</mat-option>
                <mat-option   value="Bestellauskunft_NurPreiseKeineBilder">Nur Preise, Keine Bilder</mat-option>
                <mat-option   value="Bestellauskunft_NurBilderKeinePreise">Nur Bilder, Keine Preise</mat-option>
              </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="dialogType == 'export'">
        <mat-form-field appearance="outline" >
          <mat-label *ngIf="dialogType == 'print'">PDF speichern/druck optionen</mat-label><mat-label *ngIf="dialogType == 'export'" >csv/excel export optionen</mat-label>
          <mat-select [formControl]="ordersatzControl" disableRipple [(ngModel)]="optionValue" (selectionChange)="onSelectionChange($event['value']['Value'])">
              <mat-option *ngFor="let ordersatz of ordersatzOptions" [value]="ordersatz">
                {{ordersatz.Key}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </td>

    <!-- [printStyle]="  {

      p: { color: '#0c0c0c' },
      table:{width:'100%'},
      th:{ color:'#3a67ec'}
    }" -->
      <!-- styleSheetFile="../../../styles.scss ,./printDialog.component.scss"    styles.css -->
      <!-- styleSheetFile="styles.scss ,app/dialogs/printOrdersatzDialog/printOrdersatzDialog.component.scss" -->

    <td style="width:20%">
      <button *ngIf="dialogType == 'print'"  class="btn m-2" [useExistingCss]="true"   printSectionId="orderContainer" ngxPrint>
        <i class="material-icons btn-outline-primary md-48" matTooltip="{{optionType}} ducken">print</i></button>

      <!-- <button (click)="myDownloadFunction()" class="btn m-2"> <i class="material-icons btn-outline-danger md-48 " matTooltip="abrechen">print</i> </button> -->
      <button (click)="onCancel()" class="btn m-2"> <i class="material-icons btn-outline-danger md-48 " matTooltip="abrechen">cancel</i> </button>
    </td>
  </tr>

</table>

<div id="orderContainer" #orderContainer>

<div class="row" style="margin:10px 20px 10px 20px;">
<div class="col-12" >



  <table style="width: 100%;  margin: 10px;">
    <tbody>
      <tr > <td class="alignLeft"><span class=""><a target="_blank" href="https://alaska-tkk.de">  <img src="../assets/icons/logo_alaska.jpg" data-holder-rendered="true" width="200" />  </a></span></td> <td ></td> <td ></td>  </tr>

      <tr >
        <td class="alignLeft">
          <p *ngIf="selectedCustomer != undefined">{{ selectedCustomer.Name1 }}</p>
          <p *ngIf="selectedCustomer != undefined">{{ selectedCustomer.Name2 }}</p>
          <p *ngIf="selectedCustomer != undefined">{{selectedCustomer.Street}}</p>
          <p *ngIf="selectedCustomer != undefined">{{selectedCustomer.Zip}}, {{selectedCustomer.City}}</p>
        </td>
        <td class="alignRight" colspan="2">
          <p>Kauritzerstr. 29, 04639 G&ouml;&szlig;nitz</p>
          <p>Tel: 034493/22588</p>
          <p>Fax: 034493/36532</p>
          <p *ngIf=" date != undefined" > Datum: {{date}} </p>
          <p *ngIf=" optionValue != '' && deliveryDate != undefined" > Lieferdatum: {{deliveryDate}} </p>
          <p><strong> <span  *ngIf=" optionValue != ''  && totalPriceSum() != undefined && optionValue != 'NurBilderKeinePreise'" ><b>Gesamtbetrag: {{totalPriceSum()}} € </b> </span></strong></p>
        </td>
      </tr>
      <tr>
        <td class="alignLeft" colspan="3">
          <p  *ngIf="title != undefined && optionValue != ''" [optionValue]="optionValue"> {{title}} ( KundenNr.: {{ selectedCustomer.CustomerNumber }} )  </p>
          <p  *ngIf=" selectedCustomer == undefined  " class="text-danger">bitte wählen Sie ein Kunde aus!</p>
          <p  *ngIf="  optionValue == '' && selectedCustomer != undefined "class="text-danger"> bitte wählen Sie ein druckoption aus!</p>
        </td>
      </tr>
    </tbody>
  </table>



















  <div class="col-12" *ngIf="  optionValue != '' && selectedCustomer != undefined  ">

       <table mat-table [dataSource]=datasource     class="  mat-elevation-z8 table-striped"  id="excelTable">

        <ng-container matColumnDef="WarengruppeId">
        <th mat-header-cell class=""  *matHeaderCellDef >Id</th>
        <td mat-cell class="" *matCellDef="let element">{{ element.MaterialGroupId }}</td></ng-container>

        <ng-container matColumnDef="WarengruppeName">
         <th mat-header-cell class=""  *matHeaderCellDef >Warengruppe Name</th>
         <td mat-cell class="" *matCellDef="let element">{{ element.MaterialGroupName }}</td></ng-container>

        <ng-container matColumnDef="ProduktNr">
         <th mat-header-cell class=""  *matHeaderCellDef >ProduktNr</th>
         <td mat-cell class="" *matCellDef="let element">{{ element.Product.ProductNumber }}</td></ng-container>

        <ng-container matColumnDef="Lieferdatum">
        <th mat-header-cell class=""  *matHeaderCellDef >Lieferdatum</th>
        <td mat-cell class="" *matCellDef="let element">{{ element.DeliveryDate  | date: 'dd.MM.yyyy' }}</td></ng-container>

        <ng-container matColumnDef="Artikelbezeichnung">
        <th mat-header-cell class=""  *matHeaderCellDef >Artikelbezeichnung1 </th>
        <td mat-cell class="" *matCellDef="let element">{{ element.Product.Description }}</td></ng-container>

        <ng-container matColumnDef="DescRow2">
          <th mat-header-cell class=""  *matHeaderCellDef >Artikelbezeichnung2 </th>
          <td mat-cell class="" *matCellDef="let element">{{ element.Product.DescRow2 }}</td></ng-container>

        <ng-container matColumnDef="Einheit">
        <th mat-header-cell class="" *matHeaderCellDef >Einheit</th>
        <td mat-cell class="" *matCellDef="let element">{{ element.Product.QuantityUnitsShortDesc }}</td></ng-container>

        <ng-container matColumnDef="Menge">
        <th class="txtRight"  mat-header-cell  *matHeaderCellDef > Menge</th>
        <td class="txtRight" mat-cell *matCellDef="let element">{{ element.Quantity  | number:'1.2-2':'en'}}</td></ng-container>

        <ng-container matColumnDef="Preis" >
        <th class="txtRight" mat-header-cell  *matHeaderCellDef >Preis (€)</th>
        <td class="txtRight" mat-cell  *matCellDef="let element">{{element.Price | number:'1.2-2':'en' }}</td></ng-container>

        <ng-container matColumnDef="Pic" *ngIf="dialogType == 'print'">
          <th class="txtRight" mat-header-cell  *matHeaderCellDef >Bild</th>
          <td class="txtRight" mat-cell  *matCellDef="let element"><img class="prodImage " src="{{IMAGE_URL}}{{element.Product.ProductNumber}}.jpg" onerror="this.src='../assets/icons/logo_alaska_mini.JPG';"  height="60px" id="img"></td></ng-container>


        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true "></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
        <tr  mat-header-row *matHeaderRowDef=" getSelectedDisplayedColumns(optionValue); sticky: true "></tr>
        <tr mat-row *matRowDef="let row; columns: getSelectedDisplayedColumns(optionValue)"></tr>

        </table>


        </div>
</div>




  </div> <!-- //content -->
  <div class="divPrintFooter"> Website: www.alaskaonline.de  <span class="tab100"></span>  Email: alaska-tkk@t-online.de </div>
</div>



import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import {iOrders, iCustomers,iUsers, iOrderStates, iRoles,  iDynamicorderrecords, iProducts,  iMaterialgroups, iProductstocks, iSupplierproductorders, iCustomerproductorders} from '../../models/AlaskaModels';
import { InputErrorStateMatcher} from '../../models/InputErrorStateMatcher';

import { DataService } from '../../Services/data.service';
import { IdbService } from '../../Services/idb.service';
import { DialogService } from '../../Services/dialog.service';

const ELEMENT_DATA_DYNAMICORDERS: Array<iDynamicorderrecords> = [];
@Component({
  selector: 'app-dynamicOrdersDialog',
  templateUrl: './dynamicOrdersDialog.component.html',
  styleUrls: ['./dynamicOrdersDialog.component.scss']
})
export class DynamicOrdersDialogComponent implements OnInit, AfterViewInit  {
  @ViewChild('inputInfo') inputInfo: string;
  inputForm: FormGroup;
  datasource = new MatTableDataSource(ELEMENT_DATA_DYNAMICORDERS);
  displayedColumns: string[] = ['Artikel', 'ProcessCode', 'Date', 'Name', 'ListPrice', 'Available', 'Quanity', 'Price', 'Unit'];

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dynamicOrderDialogRef: MatDialogRef<DynamicOrdersDialogComponent>,
              private dataService: DataService,
              private idbService: IdbService,
              private dialogService: DialogService,
              private formBuilder: FormBuilder  ) { }

  ngOnInit() {

      this.datasource.data = this.data && this.data.dynamicOrders? this.data.dynamicOrders : this.datasource.data;
      console.log('this.datasource ',this.datasource );
      console.log('this.datasource.data ',this.datasource.data );
      console.log('this.datasource.filteredData ',this.datasource.filteredData );

  }
ngAfterViewInit(){

}

  onCancel(){
    this.dynamicOrderDialogRef.close();
  }

  getBg(menge: number, type:string):string{
    if(type == 'DispositionStockValue' || type == 'StockValue'){
      return  menge < 5? 'bg-yelow':'bg-green'
    }
    if(type == 'QuantityInput'){
      return  menge > 0? 'bg-green':''
    }
    if(type == 'btnBasket'){
      return  menge > 0? 'material-icons btn-outline-success':'material-icons btn-outline-primary'
    }
  }
  getIcon(quantity: number, type: string): string {
    return this.dataService.getIcon(quantity,type);
  }

  bigOrSmall(quantity:number):string{
    return this.dataService.bigOrSmall(quantity);
  }

  hasRoles(roles: number[]):boolean {
    return this.dataService.hasRoles(roles)
  }

  getStockValue(dynamicOrders: iDynamicorderrecords): number{
    let value: number = 0.00;
    value =  dynamicOrders != null  && dynamicOrders.Product != null ?dynamicOrders.Product.StockValue : 0.00;
    return value;
  }
  getDispositionStockValue(dynamicOrders: iDynamicorderrecords): number{
    let value: number = 0.00;
    value =  dynamicOrders != null  && dynamicOrders.Product != null ?dynamicOrders.Product.DispositionStockValue : 0.00;
    return value;
  }
  getPrice(dynamicOrders: iDynamicorderrecords): number{
    let value: number = 0.00;
    value =  dynamicOrders != null  && dynamicOrders.Product != null ?dynamicOrders.Product.Price : 0.00;
    return value;
  }
  getQuantityInput(dynamicOrders: iDynamicorderrecords): number{
    let value: number = 0.00;
    value =  dynamicOrders != null  && dynamicOrders.Product != null ?dynamicOrders.Product.QuantityInput : 0.00;
    return value;
  }

  onOdataStockUpdate(productNumber: string){
    let productstock: iProductstocks;

    this.dataService.getDataByQuerystring('custom','ProductstockOdataByProductnumber', productNumber).subscribe(
      data => {
        productstock = data;
        let prod: iProducts = this.idbService.idbActiveProducts.find(x => x.ProductNumber == productstock.ProductNumber);
        if(prod){
          prod.StockValue  = productstock.StockValue;
          prod.DispositionStockValue  = productstock.DispositionStockValue;
          prod.OpenOrdersStockValue  = productstock.OpenOrdersStockValue;
          prod.SupplierDeliveryStockValue  = productstock.SupplierDeliveryStockValue;

          let idbActiveProductsIndex = this.idbService.idbActiveProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber) ;
          let idbAllProductsIndex = this.idbService.idbAllProducts.findIndex(x => x.ProductNumber == productstock.ProductNumber);

          this.idbService.idbActiveProducts[idbActiveProductsIndex]= prod;
          this.idbService.idbAllProducts[idbAllProductsIndex]= prod;

            let top3: iDynamicorderrecords[] = this.datasource.data.filter(x => x.Product.ProductNumber == productstock.ProductNumber);
            if(top3 && top3.length > 0){
              this.datasource.data.filter(x => x.Product.ProductNumber == productstock.ProductNumber).forEach(element => {
                element.Product = prod;
              });
            }



        }
      }
      ), err =>{
        console.log("Error onOdataStockUpdate", err);
      };
    }

    onOdataSuplierOrderShow(productNumber: string){
      let suplierOrders: iSupplierproductorders[] = [];

      this.dataService.getDataByQuerystring('custom','SuplierOrderOdataByProductnumber', productNumber).subscribe(
        data => {
          suplierOrders = data;
          this.dialogService.openSuplierOrdersDialog(suplierOrders, '50%', '50%' );

        }
        ), err =>{
          console.log("Error onOdataStockUpdate", err);
        };
      }

      onOdataCustomerOrderShow(productNumber: string){
        let customer = localStorage.getItem('selectedCustomer')? JSON.parse(localStorage.getItem('selectedCustomer')):undefined;
        let customerNumber = customer? customer.customerNumber: 0;
        let customerOrders: iCustomerproductorders[] = [];
        let productandcustomernumber = productNumber + '_'+ customerNumber;

        this.dataService.getDataByQuerystring('custom','CustomerOrderOdataByProductnumber', productandcustomernumber).subscribe(
          data => {
            customerOrders = data;
            this.dialogService.openCustomerOrdersDialog(customerOrders, '60%', '50%' );

          }
          ), err =>{
            console.log("Error onOdataStockUpdate", err);
          };
        }

}

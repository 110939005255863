
import { Component, OnInit, inject, Inject, ViewChild, AfterViewInit, ElementRef  } from '@angular/core';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { max } from 'rxjs/operators';
import { iCustomers, iOrders, iShoppingcartitems, iShoppingcarts,iOpenitems, iTours, iInputOptions } from 'src/app/models/AlaskaModels';

import { jsPDF } from "jspdf";
import html2Canvas  from 'html2canvas';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { DataService } from '../../Services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-openToursDialog',
  templateUrl: './openToursDialog.component.html',
  styleUrls: ['./openToursDialog.component.scss']
})
export class OpenToursDialogComponent implements OnInit {

  customer: iCustomers;
  tours:string[];
  selectedCustomerName:string;

  DaysOfTheWeek:string[] = ['Montag', 'Donnerstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];



  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
              public openItemsDialogRef: MatDialogRef<OpenToursDialogComponent>,
              private dataService:DataService,
              private matPaginatorIntl: MatPaginatorIntl,
  ) { }

  ngOnInit() {
    console.log('invoice dialog: ' , this.data);
    this.customer = this.data && this.data.customer? this.data.customer: null;
    this.tours = this.customer? this.customer.TourIds.replace(' ','').split(','):undefined;


  }

  ngAfterViewInit() {
  }

  onCancel(){
    this.openItemsDialogRef.close();
  }

getGate(weekDayNr: number):string{
  let tourNr = this.tours ? this.tours.find(x => +x.charAt(0) == weekDayNr): undefined;
  let inputOptions: iInputOptions[] = JSON.parse(localStorage.getItem("inputOptions"));
  if(weekDayNr && tourNr){

  let tours: iTours[]  = inputOptions ? inputOptions['tours']: undefined;
  let tour:iTours = tours? tours.find(x => x.TourNr == +tourNr): undefined;
  return tour? tour.Description : '';
  }
  else{
    return ''
  }
}





}


<div>
  <mat-form-field  appearance="outline" class="w100 ">
    <mat-label>Suche / Filter</mat-label>
    <input onfocus="this.select();" name="filterinput"  matInput type="text" (keyup)="onSendMessage($event)" placeholder="suchtext eingeben!"  [(ngModel)]="filterValue" id="filterandsearchbox-0"
    />
    <button mat-button *ngIf="filterValue" matSuffix mat-icon-button aria-label="search" [ngStyle]="{ color: 'blue' }" (click)="clearValue($event.target.value)"><mat-icon [ngStyle]="{ color: 'blue' }" id="clearproductfilter">clear</mat-icon></button>
    </mat-form-field>
</div>

<!-- (keyup)="onKeyUpEvent('filterandsearchbox-0',$event)" -->

import { Injectable, Component, inject } from '@angular/core';
import {MatDialogModule, MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { ComponentType } from '@angular/cdk/portal';
import { InfoInputDialogComponent } from '../dialogs/infoInputDialog/infoInputDialog.component';
import { InvoiceDialogComponent } from '../dialogs/invoiceDialog/invoiceDialog.component';
import { iCustomerInfo, iCustomers, iDynamicorderrecords, iMaterialgroups, iOpenitems, iOrders, iRegistrations, iRegistrationstypes, iRoles, iShoppingcarts, iUserroles, iUsers,iSupplierproductorders, iAgentInfo, iCustomerproductorders } from '../models/AlaskaModels';
import { PdfDialogComponent } from '../dialogs/pdfDialog/pdfDialog.component';
import { ConformDialogComponent } from '../dialogs/conformDialog/conformDialog.component';
import { OkDialogComponent } from '../dialogs/okDialog/okDialog.component';
import { DynamicOrdersDialogComponent } from '../dialogs/dynamicOrdersDialog/dynamicOrdersDialog.component';
import { ShoppingCartDialogComponent } from '../dialogs/shoppingCartDialog/shoppingCartDialog.component';
import { FooterDialogComponent } from '../dialogs/footerDialog/footerDialog.component';
import { PrintDialogComponent } from '../dialogs/printDialog/printDialog.component';
import { DataService } from './data.service';
import { OpenItemsDialogComponent } from '../dialogs/openItemsDialog/openItemsDialog.component';
import { ProfileDialogComponent } from '../dialogs/ProfileDialog/ProfileDialog.component';
import { PrintOrdersatzDialogComponent } from '../dialogs/printOrdersatzDialog/printOrdersatzDialog.component';
import { PrintShoppingcartDialogComponent } from '../dialogs/printShoppingcartDialog/printShoppingcartDialog.component';
import { OpenToursDialogComponent } from '../dialogs/openToursDialog/openToursDialog.component';
import { CustomerInfoDialogComponent } from '../dialogs/customerInfoDialog/customerInfoDialog.component';
import { SuplierOrdersDialogComponent } from '../dialogs/suplierOrdersDialog/suplierOrdersDialog.component';
import { DeliveryDateDialogComponent } from '../dialogs/deliveryDateDialog/deliveryDateDialog.component';
import { take } from 'rxjs/operators';
import { CustomerOrdersDialogComponent } from '../dialogs/customerOrdersDialog/customerOrdersDialog.component';
import { InprogressDialogComponent } from '../dialogs/inprogressDialog/inprogressDialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {





constructor(private dialog: MatDialog,
  private deliveryDateDialog: MatDialog

  ) { }


openDialog(name: ComponentType<any>){
this.dialog.open(name, {width:'400px', disableClose:true})

}

conformDialog(title: string, msg: string, type: string, noLabel: string, yesLabel:string, width?:string, height?:string): MatDialogRef<ConformDialogComponent>{
  let result: string;
  let w: string = width? width :'400px';
  w = w && window.innerWidth > window.innerHeight ? w :'90%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(ConformDialogComponent, {width:w, maxHeight: h,  disableClose:true, data: {title: title, type:type, msg: msg, noLabel: noLabel, yesLabel: yesLabel}});

  return dialogRef;
}

infoInputDialog(info: string, width?:string, height?:string):MatDialogRef<InfoInputDialogComponent>{
  let w: string = width? width :'30%';
  w = w && window.innerWidth > window.innerHeight ? w :'90%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(InfoInputDialogComponent, {width:w, maxHeight: h,  disableClose:true, data: {info: info}});
  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog infoInputDialog service', err);
    }
  );

  return dialogRef;
}

dynamicOrdersDialog(dynamicOrders: iDynamicorderrecords[], width?:string, height?:string):MatDialogRef<DynamicOrdersDialogComponent>{
  let w: string = width? width :'80%';
  w = w && window.innerWidth > window.innerHeight ? w :'90%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(DynamicOrdersDialogComponent, {width:w, maxHeight: h,  disableClose:false, data: {dynamicOrders: dynamicOrders},restoreFocus: false});
  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog dynamicOrdersDialog service', err);
    }
  );
  dialogRef.updatePosition({ bottom: '50px', left: '50px' });
  return dialogRef;
}

shoppingCartsDialog(shoppingCarts: iShoppingcarts[], width?:string, height?:string):MatDialogRef<ShoppingCartDialogComponent>{
  let w: string = width? width :'80%';
  w = w && window.innerWidth > window.innerHeight ? w :'90%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(ShoppingCartDialogComponent, {width:w, maxHeight: h, disableClose:false, data: {shoppingCarts: shoppingCarts}});
  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog dynamicOrdersDialog service', err);
    }
  );

  return dialogRef;
}

pdfDialog(order: iOrders, width?:string, height?:string):MatDialogRef<PdfDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(PdfDialogComponent, {width:w,   maxHeight: h ,  data: {order: order}});
  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog pdfDialog service', err);
    }
  );

  return dialogRef;
}

invoiceDialog(order: iOrders, width?:string, height?:string):MatDialogRef<InvoiceDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(InvoiceDialogComponent, {width:w,  maxHeight: h ,  data: {order: order}});
  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog invoiceDialog service', err);
    }
  );

  return dialogRef;
}

printDialog(customer: iCustomers, order?: iOrders, dialogType?:string, width?:string, height?:string):MatDialogRef<PrintDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(PrintDialogComponent, {width:w,  maxHeight: h ,
    data: {customer:customer,
      order: order,
      dialogType: dialogType

    }});

  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog invoiceDialog service', err);
    }
  );

  return dialogRef;
}

PrintOrdersatzDialog(customer: iCustomers, dynamicOrders: iDynamicorderrecords[], dialogType:string, width?:string, height?:string):MatDialogRef<PrintOrdersatzDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(PrintOrdersatzDialogComponent, {width:w,  maxHeight: h ,
    data: {
      customer:customer,
      dynamicOrders:dynamicOrders,
      dialogType:dialogType
    }});

  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog invoiceDialog service', err);
    }
  );

  return dialogRef;
}
PrintShoppingcartDialog(customer: iCustomers, shoppingcart: iShoppingcarts,  width?:string, height?:string):MatDialogRef<PrintShoppingcartDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(PrintShoppingcartDialogComponent, {width:w,  maxHeight: h ,
    data: {
      customer:customer,
      shoppingcart:shoppingcart
    }});

  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog invoiceDialog service', err);
    }
  );

  return dialogRef;
}


ProfileDialog(customer: iCustomers, order?: iOrders, user?: iUsers,registrationType?: iRegistrationstypes,role?: iRoles, width?:string, height?:string):MatDialogRef<ProfileDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';

  const dialogRef = this.dialog.open(ProfileDialogComponent, {width:w,  maxHeight: h ,  data: {customer:customer,order: order, user:user,registrationType:registrationType, role: role}});
  dialogRef.beforeClosed().subscribe(
    data => {

    }, err => {
      console.log('error dialog invoiceDialog service', err);
    }
  );

  return dialogRef;
}


okDialog(title: string, msg: string, type: string, width?:string, height?:string):  MatDialogRef<OkDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(OkDialogComponent, {width: w, maxHeight: h ,  data: {title: title, msg: msg, type: type}});
  return  dialogRef;
}

inProgressDialog(title: string, msg: string, type: string, width?:string, height?:string):  MatDialogRef<InprogressDialogComponent>{
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.dialog.open(InprogressDialogComponent, {width: w, maxHeight: h ,  data: {title: title, msg: msg, type: type}});
  return  dialogRef;
}

footerDialog(width?:string, height?:string){
  this.dialog.open(FooterDialogComponent, {width:'100%', maxHeight: window.innerHeight + 'px' ,  data: {}});
}

infoInputDialogMsg(msg: string, width?:string, height?:string){
  let w: string = width? width :'400px';
  w = w && window.innerWidth > window.innerHeight ? w :'90%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  this.dialog.open(InfoInputDialogComponent, {width:w, maxHeight:h, disableClose:true,  data: {message: msg}});
}

openItemsDialog(openItems: iOpenitems[], width?:string, height?:string){
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';

this.dialog.open(OpenItemsDialogComponent, {width:w,  maxHeight: h ,  data: {openItems: openItems}});

}

openToursDialog(customer: iCustomers,  width?:string, height?:string) {
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';

this.dialog.open(OpenToursDialogComponent, {width:w,  maxHeight: h ,  data: {customer: customer}});
}

openCustomerInfoDialog(customerInfos: iCustomerInfo[], agentCustomerInfos: iAgentInfo[], width: string, height: string) {

    let w: string = width? width :'100%';
    w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';

  this.dialog.open(CustomerInfoDialogComponent, {width:w,  maxHeight: h ,  data: {customerInfos:customerInfos, agentCustomerInfos:agentCustomerInfos}});
}

openSuplierOrdersDialog(suplierOrders: iSupplierproductorders[], width: string, height: string) {
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';

this.dialog.open(SuplierOrdersDialogComponent, {width:w,  maxHeight: h ,  data: {suplierOrders: suplierOrders}});
}

openCustomerOrdersDialog(customerOrders: iCustomerproductorders[], width: string, height: string) {
  let w: string = width? width :'100%';
  w = w && window.innerWidth > window.innerHeight ? w :'100%';
  let h: string = height? height : window.innerHeight.toString() + 'px';

this.dialog.open(CustomerOrdersDialogComponent, {width:w,  maxHeight: h ,  data: {customerOrders: customerOrders}});
}

deliverDateDialog(title: string, msg: string, type: string, noLabel: string, yesLabel:string, width?:string, height?:string): MatDialogRef<DeliveryDateDialogComponent>{
  let result: string;
  let w: string = width? width :'400px';
  w = w && window.innerWidth > window.innerHeight ? w :'90%';
  let h: string = height? height : window.innerHeight.toString() + 'px';
  const dialogRef = this.deliveryDateDialog.open(DeliveryDateDialogComponent, {width:w, maxHeight: h,  disableClose:true, data: {title: title, type:type, msg: msg, noLabel: noLabel, yesLabel: yesLabel}});

  return dialogRef;

}



}
